import { useHistory } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

export function useToggleCallLog(open: () => void) {
  const { callLoggingStore } = useStores();

  return () => {
    if (callLoggingStore.isOpenAndMinimized) {
      callLoggingStore.toggleMinimize();
    } else {
      open();
    }
  };
}

export function useToggleCallLogWithNav(patientId: number) {
  const history = useHistory();
  const { callLoggingStore } = useStores();
  const toggleCallLog = useToggleCallLog(callLoggingStore.startCallSession);

  return () => {
    const patientPath = `/patient/${patientId}`;
    if (!history.location.pathname.includes(patientPath)) {
      history.push(patientPath);
    }
    toggleCallLog();
  };
}
