import { toLower } from 'lodash/fp';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Save
  | AnalyticEventAction.Cancel
  | AnalyticEventAction.Replace
  | AnalyticEventAction.Open;

interface Properties {
  tab: string;
  patientId?: number;
  action: Action;
  value?: 'missing fields';
}

export const trackScheduledProtocolModalAnalyticsEvent = ({
  tab,
  patientId,
  action,
  ...rest
}: Properties) => {
  AnalyticsService.trackEvent({
    name: AnalyticEventName.ReportProtocolModal,
    properties: {
      modal_tab: tab,
      patient_id: patientId,
      action: toLower(action),
      ...rest
    }
  });
};
