import { Fragment } from 'react';

import { Box } from '@mui/material';

import { useStores } from 'mobx/hooks/useStores';

import { PathwayTemplatesQuestion } from 'fetchers/responses/pathways-builder.response';

import {
  DEPRESSION_SCREENING_PATHWAY_ID,
  ICAN_PATHWAY_ID,
  PATHWAY_BUILDER_SELECTED_QUESTION_ID_QUERY_PARAM
} from 'views/Pages/PathwayBuilder/PathwayBuilderPage.constants';

import { PathwayBuilderTab } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.types';

import { TableCellParams } from 'components/Table';
import { SimpleTooltip } from 'components/Tooltip';
import { InternalLink } from 'components/UIkit/atoms/Link';
import { Text } from 'components/UIkit/atoms/Text';

export const PathwaysCell = ({ value, row }: TableCellParams<PathwayTemplatesQuestion>) => {
  const { pathwayBuilderStore } = useStores();
  const { pathwaysForQuestions } = pathwayBuilderStore;

  const pathways = pathwaysForQuestions[row.original.id] || [];

  const tooltipContent = pathways.map(({ pathwayId, pathwayName }, index) => {
    const isLastPathwayItem = index === pathways.length - 1;

    const isPointsBased =
      pathwayId === DEPRESSION_SCREENING_PATHWAY_ID || pathwayId === ICAN_PATHWAY_ID;
    if (isPointsBased) {
      return (
        <Fragment key={pathwayId}>
          <Text variant="body2">
            {pathwayName}
            {!isLastPathwayItem && ','}
          </Text>{' '}
        </Fragment>
      );
    }

    return (
      <Fragment key={pathwayId}>
        <InternalLink
          variant="secondary"
          to={`/pathway-builder/${PathwayBuilderTab.Pathways}?id=${pathwayId}&${PATHWAY_BUILDER_SELECTED_QUESTION_ID_QUERY_PARAM}=${row.id}`}
        >
          {pathwayName}
          {!isLastPathwayItem && ','}
        </InternalLink>{' '}
      </Fragment>
    );
  });

  return (
    <Box display="inline-flex">
      <SimpleTooltip title={<Box p={20}>{tooltipContent}</Box>} disabled={!tooltipContent.length}>
        {value}
      </SimpleTooltip>
    </Box>
  );
};
