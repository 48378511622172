// @ts-strict-ignore
import { AnalyticEventName } from 'analytics';
import AnalyticsService from 'analytics/AnalyticsService';
import { ErrorWithUi } from 'errors';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import moment from 'moment';

import { RootStore } from 'mobx/stores';

import careTimerFetcher from 'fetchers/CareTimerFetcher';

import { handleErrorSilently } from 'services/errorHandlingService';

import { CustomSentryCategories, reportBreadcrumb } from 'services/sentryService';

import { SECOND_IN_MILLISECONDS } from 'utils/DateUtils';

import { createMapBy } from 'utils/helpers';

import { getPatientIdFromUrl } from 'utils/urlUtils';

import { CareTimeData } from 'models/CareTime';
import { DurationDoctorDetails, DurationInterval } from 'models/DurationInterval';

import { QuickAddition } from 'models/QuickAddition';

import { IntervalsEditorResult } from 'components/IntervalsEditor/IntervalsEditorModal';

export const STORAGE_SESSION_ACTIVE_KEY: string = 'STORAGE_SESSION_ACTIVE_KEY';

export enum SessionStates {
  Active = 'active',
  Inactive = 'inactive'
}

class CareTimerStore {
  updateTimer: any;

  @observable
  wasInterrupted: boolean;

  @observable
  careTimerData: CareTimeData = {
    careIntervals: [],
    quickAdditions: []
  };

  @observable
  currentPatientId: number;

  @observable
  currentSession: DurationInterval[] = [];

  @observable
  idleDisabled: boolean;

  rootStore: RootStore;

  @computed
  get currentInterval(): DurationInterval {
    if (this.currentSession.length === 0) {
      return null;
    }
    return this.currentSession[this.currentSession.length - 1];
  }

  @computed
  get careTimerMaps() {
    const { careIntervals, quickAdditions } = this.careTimerData;
    return {
      careIntervals: new Map(careIntervals.map((item) => [item.uniqueIdentifier, item])),
      quickAdditions: new Map(quickAdditions.map((item) => [item.uniqueIdentifier, item]))
    };
  }

  listener = (event: StorageEvent) => {
    if (event.key === STORAGE_SESSION_ACTIVE_KEY) {
      AnalyticsService.trackEvent({
        name: AnalyticEventName.DEBUG,
        properties: {
          action: 'stopping for storage event',
          currentSession: this.currentSession,
          careTimerData: this.careTimerData,
          patientId: this.currentPatientId,
          intervalId: this.updateTimer
        }
      });
      const isActive = localStorage.getItem(STORAGE_SESSION_ACTIVE_KEY);
      const interval = this.getOpenIntervalIfExists;
      if (isActive.startsWith(SessionStates.Active) && interval) {
        this.endInterval();
        this.setInterrupted(true);
      }
    }
  };

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setInterrupted = (interrupted: boolean) => {
    this.wasInterrupted = interrupted;
  };
  @action
  startNewSession = (doctorDetails: DurationDoctorDetails, patientId: number) => {
    window.addEventListener('storage', this.listener);
    this.currentPatientId = patientId;
    this.startInterval(doctorDetails);
  };

  endSession = async () => {
    AnalyticsService.trackEvent({
      name: AnalyticEventName.DEBUG,
      properties: {
        action: 'end session',
        currentSession: this.currentSession,
        careTimerData: this.careTimerData,
        patientId: this.currentPatientId,
        intervalId: this.updateTimer
      }
    });
    if (this.isRunning) {
      await this.endInterval();
    }
    runInAction(() => {
      this.currentPatientId = null;
      this.currentSession = [];
    });
    window.removeEventListener('storage', this.listener);
  };

  resumeSession = (doctorDetails: DurationDoctorDetails) => {
    this.setInterrupted(false);
    this.startInterval(doctorDetails);
  };

  @action
  startInterval = (doctorDetails: DurationDoctorDetails) => {
    localStorage.setItem(STORAGE_SESSION_ACTIVE_KEY, SessionStates.Active + Math.random()); // storage event listener not fired on same value, so we add random to force alert other tabs/listeners
    this.currentSession.push(new DurationInterval(doctorDetails, new Date()));
    this.setupPeriodicUpdate();
  };

  endInterval = async () => {
    this.stopPeriodicUpdate();
    this.closeOpenIntervalIfExists();
    await this.updateCurrentInterval();
  };

  validateInterval = async (interval: DurationInterval) => {
    return await careTimerFetcher.validateInterval(this.currentPatientId, interval);
  };

  updateCurrentInterval = async () => {
    // fallback to prevent "Cannot read properties of null" issue - remove when EH-3783 is solved
    if (!this.currentInterval) {
      // stop current interval
      this.stopPeriodicUpdate();
      // report to sentry for further analysis
      const error = new ErrorWithUi('No Current Interval to update');
      handleErrorSilently(error);
      return; // stop the rest of the function from showing error to the user
    } // Remove when EH-3783 solved
    if (this.currentInterval.id) {
      await careTimerFetcher.updateInterval(this.currentPatientId, this.currentInterval);
    } else {
      const intervalId = await careTimerFetcher.createInterval(
        this.currentPatientId,
        this.currentInterval
      );
      // interval might be cleared before the request finished
      if (this.currentInterval) {
        this.currentInterval.id = intervalId;
      }
    }
  };

  getCareTimeData = async () => {
    const careTimeData = await careTimerFetcher.getCareTimeData(this.currentPatientId);
    runInAction(() => {
      this.careTimerData = careTimeData;
    });
  };

  updateCareTimeData = async ({ intervals, deletedIntervals, ...rest }: IntervalsEditorResult) => {
    const careIntervals = [
      ...intervals.map(DurationInterval.toRequest),
      ...deletedIntervals.map(DurationInterval.toDeleteRequest)
    ];

    const quickAdditions = [
      ...rest.quickAdditions.map(QuickAddition.toRequest),
      ...rest.deletedQuickAdditions.map(QuickAddition.toDeleteRequest)
    ];

    await careTimerFetcher.updateCareTime(this.currentPatientId, { careIntervals, quickAdditions });

    runInAction(() => {
      const newSessionIntervals = intervals.filter((interval) => !interval.id);
      const deletedIntervalIds = new Set<number>(deletedIntervals.map((item) => item.id));
      const updatedSession = this.currentSession.filter(
        (interval) => !deletedIntervalIds.has(interval.id)
      );

      // set updated items in updatedSession
      const intervalsMap = createMapBy(intervals, (interval) => interval.id);
      for (let i = 0; i < updatedSession.length; i++) {
        const updatedItemId = updatedSession[i].id;
        if (intervalsMap.has(updatedItemId)) {
          updatedSession[i] = intervalsMap.get(updatedItemId);
        }
      }

      this.currentSession = [...updatedSession, ...newSessionIntervals];
    });
  };

  @computed
  get isRunning() {
    return Boolean(this.getOpenIntervalIfExists);
  }

  @computed
  get shouldEnableIdleTimer() {
    return this.isRunning && !this.idleDisabled;
  }

  @computed
  get totalFormattedCareTime() {
    const seconds = this.currentSession.reduce((total, interval) => {
      const end = interval.endDate ? interval.endDate : new Date();
      return Math.ceil(total + moment.duration(moment(end).diff(interval.startDate)).asSeconds());
    }, 0);
    return moment.utc(seconds * SECOND_IN_MILLISECONDS).format('HH:mm:ss');
  }

  private setupPeriodicUpdate = () => {
    AnalyticsService.trackEvent({
      name: AnalyticEventName.DEBUG,
      properties: {
        action: 'setup periodic update',
        currentSession: this.currentSession,
        careTimerData: this.careTimerData,
        patientId: this.currentPatientId,
        intervalId: this.updateTimer
      }
    });

    this.stopPeriodicUpdate();
    this.updateTimer = setInterval(() => {
      const patientId = getPatientIdFromUrl();
      if (patientId === this.currentPatientId) {
        this.updateCurrentInterval();
        AnalyticsService.trackEvent({
          name: AnalyticEventName.DEBUG,
          properties: {
            action: 'update interval',
            currentSession: this.currentSession,
            careTimerData: this.careTimerData,
            patientId: this.currentPatientId,
            intervalId: this.updateTimer
          }
        });
      } else {
        AnalyticsService.trackEvent({
          name: AnalyticEventName.DEBUG,
          properties: {
            action: 'update ghost interval',
            currentSession: this.currentSession,
            careTimerData: this.careTimerData,
            patientId: this.currentPatientId,
            intervalId: this.updateTimer,
            urlPatientId: patientId
          }
        });
      }
    }, this.rootStore.stores.settingsStore.institutionSettings.careTimerUpdateIntervalMillis);
    reportBreadcrumb({
      level: 'info',
      category: CustomSentryCategories.DEBUG,
      message: 'setupPeriodicUpdate called',
      data: {
        currentSession: this.currentSession,
        updateTimer: this.updateTimer
      }
    }); // Remove when EH-3783 solved
  };

  private stopPeriodicUpdate = () => {
    if (this.updateTimer) {
      clearInterval(this.updateTimer);
      this.updateTimer = null;
    }
  };

  private closeOpenIntervalIfExists = () => {
    const openInterval = this.getOpenIntervalIfExists;
    if (openInterval) {
      runInAction(() => {
        openInterval.endDate = new Date();
      });
    }
  };

  @computed
  get getOpenIntervalIfExists() {
    return this.currentSession?.find((interval) => !interval.endDate);
  }

  @action
  setIdleDisabled = (disabled: boolean) => {
    this.idleDisabled = disabled;
  };
}

export default CareTimerStore;
