import { FC } from 'react';

import { css, styled, Typography } from '@mui/material';
import { components, OptionProps } from 'react-select';

export const PaginatorSelectOption: FC<OptionProps> = (props) => {
  const { isSelected } = props;

  return (
    <StyledOption {...props}>
      <StyledText textAlign="center" variant="body2" isSelected={isSelected}>
        {props.children}
      </StyledText>
    </StyledOption>
  );
};

const StyledOption: FC<OptionProps> = styled(components.Option, {
  shouldForwardProp: () => true
})((props: OptionProps) => {
  const { isFocused } = props;
  const { palette } = props.selectProps.muiTheme!;

  return css`
    display: flex;
    justify-content: center;
    background-color: ${isFocused ? palette.natural.hover : palette.natural.white};
    height: 36px;

    &:hover {
      background-color: ${palette.natural.hover};
    }

    &:active {
      background-color: ${palette.natural.white};
    }
  `;
});

const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    color: ${isSelected ? theme.palette.secondary.main : theme.palette.text.primary};
  `
);
