import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { AnalyticEventAction, AnalyticEventName } from 'analytics';
import AnalyticsService from 'analytics/AnalyticsService';
import { trackActionsMenuAnalyticsEvent } from 'analytics/events/actions-menu';
import { Dialogs } from 'analytics/events/dialog';
import { trackExpandAllDxToggleAnalyticsEvent } from 'analytics/events/expand-all-dx-toggle';
import { trackMultiSelectAnalyticsEvent } from 'analytics/events/multi-select';
import { trackCmSortByAnalyticsEvent } from 'analytics/events/sort-by';
import classNames from 'classnames';

import { isEmpty } from 'lodash/fp';
import capitalize from 'lodash/fp/capitalize';
import { observer } from 'mobx-react';

import { RouteComponentProps } from 'react-router-dom';
import { Row } from 'react-table';
import { useUnmount } from 'react-use';

import { careManagementTestSelectors } from 'tests/models/pages/care-management/care-management-page.selectors';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';
import { CARE_MANAGEMENT_REPORT_FILTERS_LOCAL_STORAGE_KEY } from 'mobx/stores';

import CareManagementFetcher, {
  CareManagementBulkInviteBody
} from 'fetchers/CareManagementFetcher';

import { pluralize } from 'utils/StringUtils';

import { getCurrentSortStringByUrl } from 'utils/urlUtils';

import { API_URLS } from 'constants/apiUrls';
import { CmPatientColumns, CmPatientSortingValues } from 'constants/careManagement.const';
import { FEATURES } from 'constants/features';

import { CmPatient } from 'models/CmPatient';
import { CmEpisodeTypes } from 'models/Episode';

import { useNavPagination } from 'hooks/useNavPagination';

import CareManagementReportFilters from 'views/Filters/CareManagementReportFilters';
import { useGetPersistentFilters } from 'views/Filters/useFilters';

import {
  DiagnosesCell,
  ExpandableCell,
  MonthlyCareHeader,
  NextVisitCell,
  NoData,
  VisitsHeader
} from 'views/Pages/CareManagement/care-management-table-cells';
import { useCareManagementEnrollmentContext } from 'views/Pages/CareManagement/CareManagementEnrollment/CareManagementEnrollmentProvider';
import { CareManagementTabs } from 'views/Pages/CareManagement/CareManagementPage';
import { CM_ROW_ID_SEPARATOR } from 'views/Pages/CareManagement/CareManagementPage.constants';
import DefaultEmptyTableView from 'views/Widgets/DefaultEmptyTableView';

import FixedLoader from 'components/Loaders/FixedLoader';
import Loading from 'components/Loaders/Loading';
import { PagedTable, PaginationLocation, TableCellParams, TableColumn } from 'components/Table';
import { ITooltipOption } from 'components/Tooltip';
import { BulkMenu } from 'components/UIkit/atoms/BulkMenu';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { MonthlyCareTime } from './MonthlyCareTime';

import './CareManagementReport.scss';

export enum WarningTypes {
  OptOutWhenActive = 'optOutWhenActive',
  MarkIneligibleWhenOptedOut = 'markIneligibleWhenOptedOut',
  MarkEligibleOrEnrollWhenIneligible = 'markEligibleOrEnrollWhenIneligible',
  EnrollWhenEnded = 'enrollWhenEnded',
  EnrollWhenOptedOut = 'enrollWhenOptedOut',
  RemoveEnrollWhenOptedOut = 'removeEnrollWhenOptedOut',
  RemoveEnrollWhenIneligible = 'removeEnrollWhenIneligible'
}

export type REASON_TYPE_CM = 'cm_ineligibility' | 'cm_opt_out';

export const getReportSortIdFromTable = (sortId: string) => {
  switch (sortId) {
    case CmPatientColumns.PatientName:
      return CmPatientSortingValues.PatientName;
    case CmPatientColumns.ProviderName:
      return CmPatientSortingValues.ProviderName;
    case CmPatientColumns.Visits:
      return CmPatientSortingValues.Visits;
    case CmPatientColumns.PrimaryInsurance:
      return CmPatientSortingValues.PrimaryInsurance;
    case CmPatientColumns.SecondaryInsurance:
      return CmPatientSortingValues.SecondaryInsurance;
    case CmPatientColumns.MonthlyCareTimeMinutes:
      return CmPatientSortingValues.MonthlyCareTimeMinutes;
    case CmPatientColumns.NextVisit:
      return CmPatientSortingValues.NextVisit;
    case CmPatientColumns.CmStatus:
      return CmPatientSortingValues.CareStatus;
    default:
      throw new Error('Unsupported sort value = ' + sortId);
  }
};

interface BulkInviteConfirmationPopup {
  isOpen: boolean;
  invitationMethod: 'SMS';
  cmEpisodeType: CmEpisodeTypes;
}

const CareManagementReport: FC<RouteComponentProps> = () => {
  const { careManagementReportStore, reasonsStore, settingsStore } = useStores();
  const { currentPageByUrl, recordsPerPageByUrl } = useNavPagination();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [isAllRowsInTableSelected, setIsAllRowsInTableSelected] = useState(false);
  const [bulkInviteConfirmationPopup, setBulkInviteConfirmationPopup] =
    useState<BulkInviteConfirmationPopup>({
      isOpen: false,
      invitationMethod: 'SMS',
      cmEpisodeType: CmEpisodeTypes.ccm
    });
  const [isSendingBulkInvitation, setIsSendingBulkInvitation] = useState(false);

  const [shouldUnselectAllRows, setShouldUnselectAllRows] = useState(false);
  const currentSortStr = getCurrentSortStringByUrl();
  const parsedFilters = useGetPersistentFilters(CARE_MANAGEMENT_REPORT_FILTERS_LOCAL_STORAGE_KEY);
  const { getEnrollmentDropdown, handlePatientClick, isRedirectingToPatientPage } =
    useCareManagementEnrollmentContext();
  const hasPatientLedEnrollmentFeature = settingsStore.hasFeature(FEATURES.PATIENT_LED_ENROLLMENT);

  useEffect(
    function fetchDataOnUrlChange() {
      careManagementReportStore.fetchCareManagementReport();
    },
    [
      currentPageByUrl,
      parsedFilters,
      currentSortStr,
      careManagementReportStore,
      recordsPerPageByUrl
    ]
  );

  useUnmount(() => {
    careManagementReportStore.resetEligible();
    careManagementReportStore.resetPagination();
  });

  const getRowId = useCallback(
    (originalRow: CmPatient) =>
      `${originalRow.patientId || ''}${CM_ROW_ID_SEPARATOR}${originalRow.id || ''}`,
    []
  );

  const closeBulkInviteConfirmationPopup = () => {
    setBulkInviteConfirmationPopup({ ...bulkInviteConfirmationPopup, isOpen: false });
  };
  const onSelectedRowChange = (selectedRowIds: string[], isAllRowsInTableSelected: boolean) => {
    setSelectedRowIds(selectedRowIds);
    setIsAllRowsInTableSelected(isAllRowsInTableSelected);
  };

  const sendBulkInvitation = async () => {
    setIsSendingBulkInvitation(true);
    closeBulkInviteConfirmationPopup();

    const patientsQuery = selectedRowIds.map((id) => {
      const [patientId, emrPatientId] = id.split(CM_ROW_ID_SEPARATOR);

      return {
        patientId: Number(patientId) || null,
        emrPatientId: emrPatientId || null
      };
    });

    const sendBulkCareInvitationQuery: CareManagementBulkInviteBody = {
      type: bulkInviteConfirmationPopup.cmEpisodeType
    };

    //if we are in "select all" mode -> send just the "filters" property
    if (isAllRowsInTableSelected) {
      sendBulkCareInvitationQuery.filters = careManagementReportStore.reportFiltersToQuery;
    }
    //if we are NOT in "select all" mode -> send just "patients" property
    else {
      sendBulkCareInvitationQuery.patients = patientsQuery;
    }

    try {
      const sessionId = await CareManagementFetcher.sendBulkCareInvitation(
        sendBulkCareInvitationQuery
      );

      AnalyticsService.timeEvent(AnalyticEventName.InvitationsToastSummary);

      setShouldUnselectAllRows(true);
      careManagementReportStore.startCheckBulkInviteProgressFlow(sessionId, selectedTableRowsCount);
      setIsSendingBulkInvitation(false);
    } catch (error) {
      setIsSendingBulkInvitation(false);
      throw error;
    }
  };

  const handleRowClick = useCallback(
    (row: Row<CmPatient>) => {
      if (isRedirectingToPatientPage) {
        return;
      }

      row.toggleRowExpanded();
    },
    [isRedirectingToPatientPage]
  );

  const handlePatientNameClicked = (cellParams: TableCellParams<CmPatient>) => {
    if (isRedirectingToPatientPage) {
      return;
    }

    handlePatientClick(cellParams.row.original);
  };

  const columns: TableColumn<CmPatient>[] = useMemo(
    () => [
      {
        Header: 'Patient',
        accessor: CmPatientColumns.PatientName,
        maxWidth: 120,
        removeVerticalPadding: true,
        Cell: (cellParams: TableCellParams<CmPatient>) => {
          const mrn = cellParams.row.original.patientMrn;
          return (
            <div
              className="name-cell"
              onClick={(event) => {
                event.stopPropagation();
                handlePatientNameClicked(cellParams);
              }}
            >
              <div
                className={classNames('patient-link flex-grow-1 d-inline-flex flex-column', {
                  'justify-content-center': !mrn,
                  'justify-content-end': mrn
                })}
              >
                <span className="text-truncate">{cellParams.row.original.patientName}</span>
              </div>
              {mrn && (
                <div className="cm-report-mrn flex-grow-1 d-inline-flex flex-column justify-content-start">
                  <span className="text-truncate">MRN: {mrn}</span>
                </div>
              )}
            </div>
          );
        }
      },
      {
        Header: 'Provider',
        accessor: CmPatientColumns.ProviderName,
        maxWidth: 90,
        Cell: ExpandableCell
      },
      {
        Header: 'Primary Ins.',
        accessor: CmPatientColumns.PrimaryInsurance,
        maxWidth: 120,
        Cell: ExpandableCell
      },
      {
        Header: '2nd Ins.',
        accessor: CmPatientColumns.SecondaryInsurance,
        maxWidth: 110,
        Cell: ExpandableCell
      },
      {
        Header: 'Diagnoses',
        id: CmPatientColumns.Diagnoses,
        accessor: (originalRow: CmPatient) => ({
          cancerDx: originalRow.cancerDiagnostics,
          chronicDx: originalRow.chronicDiagnostics
        }),
        minWidth: 260,
        Cell: DiagnosesCell,
        disableSortBy: true
      },
      {
        Header: <VisitsHeader />,
        accessor: CmPatientColumns.Visits,
        maxWidth: 90
      },
      {
        Header: 'Next Visit',
        accessor: (originalRow: CmPatient) => ({
          nextVisitLocation: originalRow.nextVisitLocation,
          nextVisitDate: originalRow.nextVisitDate,
          nextVisitProvider: originalRow.nextVisitProvider
        }),
        id: CmPatientColumns.NextVisit,
        maxWidth: 110,
        Cell: NextVisitCell
      },
      {
        Header: <MonthlyCareHeader />,
        accessor: CmPatientColumns.MonthlyCareTimeMinutes,
        maxWidth: 130,
        Cell: (cellParams) => {
          const { monthlyCareTime } = cellParams.row.original;
          return monthlyCareTime ? <MonthlyCareTime time={monthlyCareTime} /> : <NoData />;
        }
      },
      {
        Header: 'Enrollment Status',
        accessor: CmPatientColumns.CmStatus,
        maxWidth: 165,
        removeVerticalPadding: true,
        Cell: (cellParams: TableCellParams<CmPatient>) => {
          const { original: cmPatient } = cellParams.row;
          return <>{getEnrollmentDropdown(cmPatient)}</>;
        }
      }
    ],
    // eslint-disable-next-line
    [
      getEnrollmentDropdown,
      handlePatientClick,
      careManagementReportStore.checkBulkInviteProgressInterval
    ]
  );

  const isLoading =
    useNetworkLoading([API_URLS.CARE_MANAGEMENT_REPORT, API_URLS.CARE_MANAGEMENT_FILTER_DATA]) ||
    !careManagementReportStore.eligiblePatients;

  if (!reasonsStore.isInitialized) {
    return <Loading primaryColor />;
  }

  const invitationSelectOptions: ITooltipOption[] = [
    {
      text: 'PCM via SMS',
      onClick: () => {
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          value: 'Send invitation - PCM',
          source: 'patient',
          bulk_action: true
        });

        setBulkInviteConfirmationPopup({
          isOpen: true,
          invitationMethod: 'SMS',
          cmEpisodeType: CmEpisodeTypes.pcm
        });
      }
    },
    {
      text: 'CCM via SMS',
      onClick: () => {
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          value: 'Send invitation - CCM',
          source: 'patient',
          bulk_action: true
        });

        setBulkInviteConfirmationPopup({
          isOpen: true,
          invitationMethod: 'SMS',
          cmEpisodeType: CmEpisodeTypes.ccm
        });
      }
    }
  ];

  const selectedTableRowsCount = isAllRowsInTableSelected
    ? careManagementReportStore.totalEligiblePatientsCount
    : selectedRowIds.length;

  const selectedTableRowsCountSuffix = pluralize('Patient', selectedTableRowsCount);
  const invitationCountPluralized = pluralize('invitation', selectedTableRowsCount);
  const patientPluralized = pluralize('patient', selectedTableRowsCount);

  const bulkInviteConfirmationPopupTitle = `Send ${selectedTableRowsCount} ${
    bulkInviteConfirmationPopup.cmEpisodeType
  } ${capitalize(invitationCountPluralized)} via ${bulkInviteConfirmationPopup.invitationMethod}`;
  const bulkInviteConfirmationPopupDescription = `Are you sure you want to send ${selectedTableRowsCount} ${bulkInviteConfirmationPopup.cmEpisodeType} ${invitationCountPluralized} via ${bulkInviteConfirmationPopup.invitationMethod} to the selected ${patientPluralized}?`;

  return (
    <div
      className="enrollment-manager"
      data-test-hook={careManagementTestSelectors.enrollmentManagerTab.container}
    >
      <CareManagementReportFilters
        clearTableSelection={() => setShouldUnselectAllRows(true)}
        selectedTableRowsCount={selectedTableRowsCount}
      />

      {isLoading && <FixedLoader />}

      <MessageDialog
        id={Dialogs.SendBulkInvitationsViaSms}
        isOpen={bulkInviteConfirmationPopup.isOpen}
        title={bulkInviteConfirmationPopupTitle}
        handleClose={closeBulkInviteConfirmationPopup}
        primaryActionProps={{
          text: 'Send',
          onClick: sendBulkInvitation,
          disabled: isSendingBulkInvitation
        }}
        secondaryActionProps={{ text: 'Cancel', onClick: closeBulkInviteConfirmationPopup }}
      >
        {bulkInviteConfirmationPopupDescription}
      </MessageDialog>

      <BulkMenu
        isOpen={!isEmpty(selectedRowIds)}
        summaryText={`${selectedTableRowsCount} ${selectedTableRowsCountSuffix}`}
        items={[
          {
            placement: 'top',
            textButtonProps: {
              label: 'Send Invitation',
              onClick: () =>
                trackActionsMenuAnalyticsEvent({
                  action: AnalyticEventAction.Click,
                  value: 'Send invitation',
                  source: 'patient',
                  bulk_action: true
                })
            },
            content: {
              type: 'select',
              options: invitationSelectOptions
            }
          }
        ]}
      />

      <PagedTable
        isRowsSelectable={hasPatientLedEnrollmentFeature}
        shouldUnselectAllRows={shouldUnselectAllRows}
        resetShouldUnselectAllRows={() => setShouldUnselectAllRows(false)}
        columns={columns}
        rowData={careManagementReportStore.eligiblePatients?.items || []}
        rowAction={handleRowClick}
        totalPages={careManagementReportStore.totalPages}
        totalItemsCount={careManagementReportStore.totalEligiblePatientsCount}
        isLoading={isLoading}
        paginationLocation={[PaginationLocation.TOP, PaginationLocation.BOTTOM]}
        expandAllOptions={{ expandedText: 'Expand All Dx', collapsedText: 'Expand All Dx' }}
        getRowId={getRowId}
        disableSelect={Boolean(careManagementReportStore.checkBulkInviteProgressInterval)}
        emptyTableView={<DefaultEmptyTableView />}
        onSelectedRowChange={onSelectedRowChange}
        onManualSort={(column, sortParams) =>
          trackCmSortByAnalyticsEvent(column, sortParams, CareManagementTabs.ENROLLMENT_MANAGER)
        }
        onToggleAllRows={(isAllRowsExpanded) => {
          trackExpandAllDxToggleAnalyticsEvent({
            action: isAllRowsExpanded ? AnalyticEventAction.TurnOn : AnalyticEventAction.TurnOff,
            tab: 'Enrollment Manager'
          });
        }}
        onToggleAllSelectedRowsInTable={(isTableSelected) => {
          trackMultiSelectAnalyticsEvent({
            action: isTableSelected
              ? AnalyticEventAction.SelectAllInTable
              : AnalyticEventAction.RemoveAllInTable,
            value: isTableSelected ? careManagementReportStore.totalEligiblePatientsCount : 0
          });
        }}
        onToggleAllSelectedRowsInPage={(isPageSelected) => {
          trackMultiSelectAnalyticsEvent({
            action: isPageSelected
              ? AnalyticEventAction.SelectAllInPage
              : AnalyticEventAction.RemoveAllInPage
          });
        }}
        onToggleSelectedRow={(isRowSelected) => {
          trackMultiSelectAnalyticsEvent({
            action: isRowSelected ? AnalyticEventAction.Add : AnalyticEventAction.Remove
          });
        }}
      />
    </div>
  );
};

export default observer(CareManagementReport);
