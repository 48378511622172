// @ts-strict-ignore
import { FC, KeyboardEvent, ReactNode, useState } from 'react';

import { AnalyticEventAction } from 'analytics';

import { Dialogs } from 'analytics/events/dialog';
import { trackEditTicketModalAnalyticsEvent } from 'analytics/events/edit-ticket-modal';
import { isArray, isEmpty } from 'lodash/fp';

import { observer } from 'mobx-react';

import { useForm } from 'react-hook-form';

import { Col, Row } from 'reactstrap';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';

import { useProvider } from 'mobx/hooks/useProvidersOptions';

import { useStores } from 'mobx/hooks/useStores';

import { formatDate } from 'utils/DateUtils';

import { getNumberAndCountryCodeFromPhone } from 'utils/PhoneUtils';

import { ITicketTypeSelectOptions, TicketSubTypeOption } from 'utils/TicketType.utils';

import { showToast } from 'utils/UserMessageUtils';

import { API_URLS } from 'constants/apiUrls';

import OperatorTicket, { OperatorTicketSource } from 'models/OperatorTicket';

import Patient from 'models/Patient';

import Ticket from 'models/Ticket';
import { TicketTypeKind } from 'models/TicketTypes';

import { useClinicianFullNameWithCredentials } from 'hooks/useClinicianFullNameWithCredentials';

import { OperatorEditTicketsInfoForm } from 'components/Ticket/TicketForms/OperatorEditTicketInfoForm';
import { OperatorTicketContactForm } from 'components/Ticket/TicketForms/OperatorTicketContactForm';
import {
  IOperatorUpdateTicketFormFields,
  PATIENT_IS_CONTACT_VALUE
} from 'components/Ticket/TicketForms/ticket.shared';
import { buildTicketUpdateRequest } from 'components/Ticket/TicketForms/ticketRequestBuilders';
import {
  getUrgencyOptions,
  useTicketLocationsOptions,
  useTicketTypesOptions,
  useTypeActiveOrIncludedFilterFn
} from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

function getInitTicketTypes(options: ITicketTypeSelectOptions[], operTicket: OperatorTicket) {
  // convert selected subTypes to set
  const selectedSubTypeIds = new Set<string>();
  operTicket?.ticketSubTypesIds?.ids.forEach((id) => selectedSubTypeIds.add(id.toString()));

  const option = options.find((o) => {
    // find the relevant option by parentId
    return o.options.find((option) => option.parentId === operTicket.ticketTypeId);
  });

  const ticketTypes: TicketSubTypeOption[] = [];

  Array.from(selectedSubTypeIds).forEach((id) => {
    const ticketTypeOption = option?.options?.find((option) => option.value === id);

    if (ticketTypeOption) {
      ticketTypes.push(ticketTypeOption);
    }
  });

  return ticketTypes;
}

export interface EditTicketModalProps {
  children?: ReactNode;
  isOpen: boolean;
  patient: Patient;
  ticket: Ticket;
  onSubmit: (result: Ticket) => void;
  onCancel: () => void;

  resetDataAfterClose: () => void;
  onDeleteTicket: (ticket: Ticket) => void;
  onEditTicket: (...args: any) => void;
}

export const EditTicketModal: FC<EditTicketModalProps> = ({
  isOpen,
  patient,
  ticket,
  onSubmit,
  onCancel,
  resetDataAfterClose,
  onDeleteTicket,
  onEditTicket
}) => {
  const { ticketsStore, userStore } = useStores();
  const isLoading = useNetworkLoading(API_URLS.UPDATE_TICKET(ticket?.id));
  const ticketContact = patient?.getContactById(ticket?.operatorTicket?.patientContactId);

  const [initialCountryCode, initialPhoneNumber] = getNumberAndCountryCodeFromPhone(
    ticketContact?.phoneNumber || patient?.phone
  );
  const ticketTypesOptions = useTicketTypesOptions({
    // active node OR the currently selected type
    filterFn: useTypeActiveOrIncludedFilterFn(ticket?.operatorTicket)
  });
  const ticketAssignee = ticket?.mainAssign?.doctor;
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const assignedClinicianFullName = useClinicianFullNameWithCredentials(
    ticketAssignee?.firstName,
    ticketAssignee?.lastName,
    ticketAssignee?.credentialId
  );
  const providerId = useProvider(ticket?.operatorTicket?.providerId);
  const locationOptions = useTicketLocationsOptions();
  const ticketTypes = getInitTicketTypes(ticketTypesOptions, ticket?.operatorTicket);
  const methods = useForm<IOperatorUpdateTicketFormFields>();
  const date = formatDate(ticket?.operatorTicket?.createdAt, 'MM/DD/YYYY');
  const title = `Edit Ticket: ${patient?.fullName}, ${date}`;
  let defaultTicketTypes: TicketSubTypeOption[] | TicketSubTypeOption;

  if (!isEmpty(ticketTypes)) {
    const selectedFirstTicketType = ticketTypes[0];
    const isSymptomTicketType = selectedFirstTicketType.parentKind === TicketTypeKind.symptom;

    if (isSymptomTicketType) {
      defaultTicketTypes = ticketTypes; //we assign the entire array because it is the symptoms ticket -> multi select
    } else {
      defaultTicketTypes = ticketTypes[0]; //we assign the only object in the array because it is non symptom ticket -> single select
    }
  }

  const isSymptomsTicket =
    isArray(defaultTicketTypes) || defaultTicketTypes?.parentKind === TicketTypeKind.symptom;

  const urgencyOptions = getUrgencyOptions(isSymptomsTicket);

  const formDefaultValues: Partial<IOperatorUpdateTicketFormFields> = {
    // fill initial form options from exist ticket data
    contactId: ticket?.operatorTicket?.patientContactId
      ? ticket?.operatorTicket?.patientContactId.toString()
      : PATIENT_IS_CONTACT_VALUE,
    phone: initialPhoneNumber,
    countryCode: initialCountryCode,
    callbackHippaAuth: !!ticket?.operatorTicket?.contactHippaAuthDate,
    ticket: {
      urgency: (urgencyOptions as { value: number; label: string }[]).find(
        (urgencyOption) => urgencyOption.value === ticket?.operatorTicket?.urgency
      ),
      parentId: isArray(ticketTypes) ? ticketTypes[0]?.parentId : null,
      notes: ticket?.operatorTicket?.notes,
      providerId: providerId,
      locationId: locationOptions.find((l) => l.value.id === ticket?.operatorTicket?.locationId),
      assignee: {
        value: ticketAssignee?.id,
        label: assignedClinicianFullName
      },
      categoryId: ticket?.operatorTicket?.ticketTypeId?.toString(),
      ticketSubTypeIds: ticket?.operatorTicket?.subTicketTypeIds.map(String),
      ticketTypeSelectorValue: defaultTicketTypes,
      lightweightPathwaysAnswers: [],
      lightweightPathwaySummaries: []
    }
  };

  // provide originalPatientPhone only if patient is the contact
  // patient may have different phone in ticket / patient objects
  const originalPatientPhone = !!ticket?.operatorTicket?.patientContactId
    ? null
    : ticketContact?.phoneNumber;
  const shouldShowDelete =
    (userStore.isManager ||
      ticket?.operatorTicket?.reportingDoctor.id === userStore.currentDoctor.id) &&
    ticket?.operatorTicket?.source === OperatorTicketSource.Operator;

  const submitRequest = async (formData: IOperatorUpdateTicketFormFields) => {
    const reqData = buildTicketUpdateRequest(formData, patient);
    const updatedTicket = await ticketsStore.updateTicket(ticket.id, reqData);
    onEditTicket && onEditTicket(ticket);
    showToast({ message: `Ticket Updated for ${patient.fullName}` });
    trackEditTicketModalAnalyticsEvent({
      action: AnalyticEventAction.Save,
      ticket_id: ticket.id,
      value: `save ${methods.formState.isDirty ? 'with changes' : 'without changes'}`
    });
    onSubmit && onSubmit(updatedTicket);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (isLoading) {
      return;
    }

    if (e.shiftKey && e.key === 'Enter') {
      methods.handleSubmit(submitRequest)(e);
    }
  };

  const handleDeleteClicked = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteTicket = async () => {
    trackEditTicketModalAnalyticsEvent({
      action: AnalyticEventAction.Delete,
      ticket_id: ticket.id
    });
    await ticketsStore.deleteTicket(ticket.id);
    onDeleteTicket && onDeleteTicket(ticket);
    setDeleteDialogOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <FormModal
      size="large"
      isOpen={isOpen}
      title={title}
      defaultValues={formDefaultValues}
      methods={methods}
      resetDataAfterClose={resetDataAfterClose}
      confirmActions={[
        {
          onClick: methods.handleSubmit(submitRequest, () =>
            trackEditTicketModalAnalyticsEvent({
              action: AnalyticEventAction.Save,
              ticket_id: ticket.id,
              value: 'missing fields'
            })
          ),
          text: isLoading ? 'Saving...' : 'Save',
          disabled: isLoading,
          testHook: 'submit-btn'
        }
      ]}
      closeAction={{
        onClick: () => {
          onCancel();
          trackEditTicketModalAnalyticsEvent({
            action: AnalyticEventAction.Cancel,
            ticket_id: ticket.id
          });
        },
        disabled: false
      }}
      secondaryAction={{
        type: 'button',
        isVisible: shouldShowDelete,
        onClick: handleDeleteClicked,
        disabled: isLoading,
        text: 'Delete'
      }}
    >
      <MessageDialog
        isOpen={isDeleteDialogOpen}
        title="Delete this ticket?"
        handleClose={() => setDeleteDialogOpen(false)}
        primaryActionProps={{ text: 'Delete', onClick: handleDeleteTicket }}
        secondaryActionProps={{ text: 'Cancel', onClick: () => setDeleteDialogOpen(false) }}
        id={Dialogs.DeleteTicket}
      >
        Are you sure you want to delete? This action cannot be undone.
      </MessageDialog>

      <div
        className="operator-ticket-form"
        onKeyDown={handleKeyDown}
        data-test-hook="edit-ticket-modal"
      >
        <Row>
          <Col xs={4} sm={4} lg={4}>
            <OperatorTicketContactForm
              patient={patient}
              originalContactHippaAuthDate={ticket?.operatorTicket?.contactHippaAuthDate}
              originalPatientPhone={originalPatientPhone}
              namePrefix="edit"
            />
          </Col>
          <Col xs={8} sm={8} lg={8}>
            <OperatorEditTicketsInfoForm patient={patient} ticket={ticket} />
          </Col>
        </Row>
      </div>
    </FormModal>
  );
};

export default observer(EditTicketModal);
