import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Down | AnalyticEventAction.Up;

interface Properties {
  action: Action;
  value: number;
}

export const trackScrollAnalyticsEvent = ({ action, value }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.Scroll,
    properties: { action, value }
  });
};
