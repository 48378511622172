import { FC, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { styled, css } from '@mui/material/styles';

import { useUpdateEffect } from 'react-use';

import QaFetcher from 'fetchers/QaFetcher';

import { usePatientModel } from 'components/Patient/usePatientModel';
import { Modal } from 'components/UIkit/atoms/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const QaMobileTokenModal: FC<Props> = ({ isOpen, onSubmit, onClose }) => {
  const patient = usePatientModel();
  const [token, setToken] = useState<string | null>(null);

  useUpdateEffect(
    function fetchMobileToken() {
      const doFetch = async () => {
        if (patient?.id && isOpen) {
          const tokenResponse = await QaFetcher.getMobileTokenForPatient(patient.id);
          setToken(tokenResponse);
        }
      };

      doFetch();
    },
    [patient?.id, isOpen]
  );

  const patientNameAndMrn = `${patient?.fullName} ${patient?.mrn ? `, ${patient.mrn}` : ''}`;

  return (
    <Modal
      isOpen={isOpen}
      title="Patient Mobile Token"
      closeAction={{
        disabled: false,
        onClick: onClose
      }}
      confirmActions={[
        {
          onClick: onSubmit,
          text: 'Ok',
          disabled: false
        }
      ]}
    >
      <Grid container flexDirection="column" pb={24}>
        {patient?.id ? (
          <>
            <Typography variant="form-text">
              Mobile API token for patient: <strong>{patientNameAndMrn}</strong>
            </Typography>
            <Grid container mt={24} flexWrap="wrap" flexGrow={1}>
              <StyledTypography variant="helper-text">
                <code>{token}</code>
              </StyledTypography>
            </Grid>
          </>
        ) : (
          <Typography>Access to mobile token must be done on the desired patient's page</Typography>
        )}
      </Grid>
    </Modal>
  );
};

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    width: 80%;
    code {
      font-style: italic;
      font-size: ${theme.fontSizes.medium};
      color: ${theme.palette.text.primary};
    }
  `
);
