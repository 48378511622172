// @ts-strict-ignore
export enum AuditActions {
  ACTION_LOGIN = 'login',
  ACTION_LOGOUT = 'logout',
  ACTION_ASSIGN = 'assign',
  ACTION_PICKUP_TICKET = 'pickup_ticket',
  ACTION_REASSIGN_TICKET = 'reassign_ticket',
  ACTION_CREATE_TICKET = 'create_ticket',
  ACTION_UPDATE_TICKET = 'update_ticket',
  ACTION_RESOLVE_TICKET = 'resolve_ticket',
  ACTION_CLOSE_TICKET = 'close_ticket',
  ACTION_UNASSIGN_TICKET = 'unassign_ticket',
  ACTION_CONNECT_TICKET_TO_CALL = 'connect_ticket_to_call',
  ACTION_CONNECT_TICKET_TO_DRAFT = 'connect_ticket_to_draft',
  ACTION_DISCONNECT_TICKET_FROM_CALL = 'disconnect_ticket_from_call',
  ACTION_UNASSIGN = 'unassign',
  ACTION_EDIT_INFO = 'edit_info',
  ACTION_CREATE_PATIENT = 'create_patient',
  ACTION_ACKNOWLEDGE_REPORT = 'acknowledge_report',
  ACTION_UNDO_ACKNOWLEDGE = 'undo_acknowledge',
  ACTION_SCHEDULE_PROTOCOL = 'schedule_protocol',
  ACTION_REMOVE_PROTOCOL = 'remove_protocol',
  ACTION_REQUEST_REPORT = 'request_report',
  ACTION_SNOOZE_PATIENT = 'snooze_patient',
  ACTION_REPORT_CALL = 'report_call',
  ACTION_CALL_INTERVALS_EDITED = 'edit_call_interval',
  ACTION_PATIENT_OPT_OUT = 'patient_opted_out',
  ACTION_TICKET_STATUS_CHANGE = 'status_change_task_ticket',
  ACTION_TASK_RESCHEDULE = 'reschedule_task_ticket',
  ACTION_UNRESOLVE_TASK = 'unresolve_task_ticket'
}

const HISTORY_ACTIONS_TO_STRING: { [key: string]: string } = {
  [AuditActions.ACTION_PICKUP_TICKET]: 'Picked up',
  [AuditActions.ACTION_REASSIGN_TICKET]: 'Reassigned',
  [AuditActions.ACTION_CREATE_TICKET]: 'Created',
  [AuditActions.ACTION_UPDATE_TICKET]: 'Edited',
  [AuditActions.ACTION_RESOLVE_TICKET]: 'Resolved',
  [AuditActions.ACTION_CLOSE_TICKET]: 'Closed',
  [AuditActions.ACTION_CONNECT_TICKET_TO_CALL]: 'Connected to a logged call',
  [AuditActions.ACTION_CONNECT_TICKET_TO_DRAFT]: 'Connected to a draft call',
  [AuditActions.ACTION_DISCONNECT_TICKET_FROM_CALL]: 'Disconnected from a logged call',
  [AuditActions.ACTION_UNASSIGN_TICKET]: 'Unassigned',
  [AuditActions.ACTION_UNRESOLVE_TASK]: 'Reopened',
  [AuditActions.ACTION_TICKET_STATUS_CHANGE]: 'Status changed from',
  [AuditActions.ACTION_TASK_RESCHEDULE]: 'Due date changed from'
};

export interface Clinician {
  id: number;
  firstName: string;
  lastName: string;
  credentialId: number;
}

export class TicketHistoryAudit {
  id: number;
  performedByClinician: Clinician;
  oldClinician?: Clinician;
  newClinician?: Clinician;
  action: AuditActions;
  actionDetails: any;
  createdAt: Date;

  constructor(data: TicketHistoryAuditAttributes) {
    Object.assign(this, data);
  }

  hasActionStr() {
    return Boolean(HISTORY_ACTIONS_TO_STRING[this.action]);
  }

  getActionStr() {
    return HISTORY_ACTIONS_TO_STRING[this.action];
  }
}

interface TicketHistoryAuditAttributes {
  id: number;
  performedByClinician: Clinician;
  newClinician?: Clinician;
  oldClinician?: Clinician;
  action: AuditActions;
  actionDetails: any;
  createdAt: Date | null;
}
