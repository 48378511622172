// @ts-strict-ignore
import { useMemo } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import PatientProvider from 'models/PatientProvider';

export function useProvidersOptions() {
  const { providersStore } = useStores();
  return useMemo(() => {
    return providersStore.providersForSelect;
  }, [providersStore]);
}

export function useProvider(providerId: string): { label: string; value: PatientProvider } {
  const { providersStore } = useStores();
  let patientProvider;
  if (providerId) {
    patientProvider = {
      label: providersStore.getProviderById(providerId)?.name,
      value: providersStore.getProviderById(providerId)
    };
  }
  return patientProvider;
}
