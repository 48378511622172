import { FC, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { pluralize } from 'utils/StringUtils';

import Patient from 'models/Patient';

import ExpandableRow from 'views/Widgets/ExpandableRow';
import GeneratedCallDetailsView from 'views/Widgets/GeneratedCallDetailsView';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  patient: Patient;
}

export const CallGeneratedSection: FC<Props> = observer(({ patient }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { pathwaysStore, callLoggingStore } = useStores();
  const allAnswers = pathwaysStore.orderedPathwayAnswers;
  const allAnswersLength = allAnswers.length;

  return (
    <ExpandableRow
      isExpanded={isExpanded}
      title={
        <Text>
          <Text variant="h4">Canopy Generated</Text>

          {allAnswersLength > 0 && (
            <Text variant="h4" color="secondary">{` — ${allAnswersLength} ${pluralize(
              'Pathway Response',
              allAnswersLength
            )}
             Added`}</Text>
          )}
        </Text>
      }
      onToggleExpand={() => {
        trackSectionUsageAnalyticsEvent({
          action: isExpanded ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
          value: 'Canopy Generated',
          virtual_page: 'call logger'
        });

        setIsExpanded(!isExpanded);
      }}
    >
      <GeneratedCallDetailsView
        call={null}
        patient={patient}
        primarySymptoms={callLoggingStore.currentCall.callReasons}
        pathwayAnswers={allAnswers}
      />
    </ExpandableRow>
  );
});
