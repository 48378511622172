import { FC } from 'react';

import { useMount } from 'react-use';

const GOOGLE_PLAY_PATH = 'https://play.google.com/store/apps/details?id=expain.getexpain.com&hl=en';
const IOS_PATH = 'https://itunes.apple.com/il/app/expain-health/id1368449357?mt=8';

const DownloadApp: FC = () => {
  useMount(function navigateToAppDownloadPath() {
    const userAgent = window.navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window.location.replace(GOOGLE_PLAY_PATH);
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.location.replace(IOS_PATH);
    }
  });

  return <div />;
};

export default DownloadApp;
