import { ReportProtocolModalTab } from 'tests/models/pages/patient-page/patient-page.types';

export const reportProtocolModalTestSelectors = {
  modal: 'report-protocol-modal',
  submitButton: 'report-protocol-modal-submit-button',
  tab: (tab: ReportProtocolModalTab) => `${tab}-tab`,
  drugSpecificTab: {
    drugSelect: 'Drug',
    drugTypeSelect: 'Type'
  },
  symptomAssessmentTab: {
    automaticFrequencyToggle: 'automatic-frequency-toggle',
    hybridFrequencySelect: 'hybrid-frequency-select'
  },
  oralOncolyticsTab: {
    automaticFrequencyToggle: 'automatic-frequency-toggle',
    automaticFrequencyToggleTooltipIcon: 'automatic-frequency-toggle-tooltip-icon',
    automaticFrequencyToggleTooltipContent: 'automatic-frequency-toggle-tooltip-content',
    regimenSelect: 'Regimen'
  }
};
