import { FC, useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { isEmpty } from 'lodash/fp';

import { observer } from 'mobx-react';

import { useFormContext } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import { SelectOption } from 'models/SelectOption';

import {
  DependentQuestionsBlockType,
  MultipleQuestionFormField,
  PathwayEditorFormFields,
  QuestionFormField,
  CategoryQuestionOption,
  SingleQuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { ISelectOption } from 'components/UIkit/atoms/Dropdown/Select.shared';

interface Props {
  questionIndex: number;
  questionPath:
    | `categories.${number}.questions.${number}`
    | `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}.questions.${number}`;
  updateQuestion: (questionIndex: number, data: QuestionFormField) => void;
  isSub?: boolean;
}

export const QuestionTitleFormSelect: FC<Props> = observer(
  ({ questionIndex, questionPath, updateQuestion, isSub }) => {
    const { getValues, watch, getFieldState, clearErrors } =
      useFormContext<PathwayEditorFormFields>();

    const question = watch(questionPath);

    const getPathwayQuestionUuids = useCallback((): string[] => {
      const categories = getValues('categories');
      const pathwayQuestionUuids: string[] = [];

      categories.forEach((category) =>
        category.questions.forEach((question) => {
          pathwayQuestionUuids.push(question.questionId);

          if (
            question.type === PathwayQuestionTypes.MULTIPLE ||
            question.type === PathwayQuestionTypes.SINGLE
          ) {
            question.dependentQuestionsBlocks?.forEach((dependentQuestionsBlock) =>
              dependentQuestionsBlock.questions.forEach((question) =>
                pathwayQuestionUuids.push(question.questionId)
              )
            );
          }
        })
      );

      return pathwayQuestionUuids;
    }, [getValues]);

    const { pathwayBuilderStore } = useStores();
    const { getQuestionById, getQuestionOptionsForSelect, questions } = pathwayBuilderStore;

    const isAutoFocus = !isSub && question.type === 'NewQuestion';

    const fieldState = getFieldState(`${questionPath}.title`);

    const handleChange = (option: SelectOption<any> | SelectOption<any>[] | null) => {
      const questionFromQuestionsBank = getQuestionById(
        (option as SelectOption<string>).value as string
      );

      const updatedQuestion: QuestionFormField = {
        uuid: question.uuid,
        type: questionFromQuestionsBank.type as any,
        questionId: questionFromQuestionsBank.id,
        title: {
          label: (option as SelectOption<string>).label,
          value: (option as SelectOption<string>).value as string
        },
        isKey: false
      };

      if (
        questionFromQuestionsBank.type === PathwayQuestionTypes.SINGLE ||
        questionFromQuestionsBank.type === PathwayQuestionTypes.MULTIPLE
      ) {
        //update the new question's options
        (
          updatedQuestion as unknown as SingleQuestionFormField | MultipleQuestionFormField
        ).options = getQuestionOptionsForSelect(
          questionFromQuestionsBank.id
        ) as CategoryQuestionOption[];

        //check if the previous question has dependent questions blocks
        //if yes, we want to clear just the triggers, the questions remain as is
        //else, we want to remove all the dependent questions blocks (triggers + questions)
        if (
          !isEmpty(
            (question as SingleQuestionFormField | MultipleQuestionFormField)
              .dependentQuestionsBlocks
          )
        ) {
          const newDependentQuestionsBlocks: DependentQuestionsBlockType[] = (
            question as SingleQuestionFormField | MultipleQuestionFormField
          ).dependentQuestionsBlocks!.map((dependentQuestionBlock) => ({
            triggers: null,
            questions: dependentQuestionBlock.questions,
            uuid: dependentQuestionBlock.uuid
          }));

          (
            updatedQuestion as unknown as SingleQuestionFormField | MultipleQuestionFormField
          ).dependentQuestionsBlocks = newDependentQuestionsBlocks;
        }
      }

      clearErrors(questionPath);
      updateQuestion(questionIndex, updatedQuestion);
    };

    const pathwayQuestionUuids = getPathwayQuestionUuids();
    const optionsForSelect: ISelectOption<string>[] = useMemo(
      () =>
        questions.map((questionFromQuestionsBank) => {
          const option: ISelectOption<string> = {
            label: questionFromQuestionsBank.title,
            value: questionFromQuestionsBank.id
          };

          if (
            pathwayQuestionUuids.includes(questionFromQuestionsBank.id) &&
            questionFromQuestionsBank.id !== question.questionId
          ) {
            option.isDisabled = true;
            option.disabledOptions = {
              tooltipText: 'Question is already in use in this Pathway.',
              isSelected: true
            };
          }

          return option;
        }),
      [pathwayQuestionUuids, questions, question.questionId]
    );

    return (
      <Box flex={1}>
        <FormAutocomplete
          isRequired
          isError={Boolean(fieldState.error)}
          autoFocus={isAutoFocus}
          name={`${questionPath}.title`}
          options={optionsForSelect}
          onChange={handleChange}
          placeholder="Question Title"
        />
      </Box>
    );
  }
);
