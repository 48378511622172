import { FC } from 'react';

import classNames from 'classnames';

import './BaseModal.scss';

interface IBaseModalProps {
  title?: string;
  subtitle?: string;
  isWarning?: boolean;
  centerTitle?: boolean;
}
const BaseModalHeader: FC<IBaseModalProps> = ({ title, subtitle, isWarning, centerTitle }) => {
  const titleClasses = classNames('base-modal-title', { center: centerTitle });
  const subtitleClasses = classNames('base-modal-subtitle', { 'subtitle-warn': isWarning });

  return (
    <div className="base-modal-header">
      {title && <h1 className={titleClasses}>{title}</h1>}
      {subtitle && <p className={subtitleClasses}>{subtitle}</p>}
    </div>
  );
};

export default BaseModalHeader;
