// @ts-strict-ignore
import { action, makeObservable, observable } from 'mobx';

import { computedFn } from 'mobx-utils';

import { RootStore, EpisodeMetadata } from 'mobx/stores';
import { DataMap } from 'mobx/stores/DataMap';

import {
  CreateEndPatientEpisodeReasonReq,
  PatientEndEpisodeReasonsFetcher
} from 'fetchers/PatientEpisodeEndReasonsFetcher';
import PatientEpisodesFetcher, {
  EndEpisodeRequestBody,
  EpisodeCreationAttrs,
  EpisodeUpdateAttrs
} from 'fetchers/PatientEpisodesFetcher';

import { FEATURES } from 'constants/features';

import PatientEpisode from 'models/PatientEpisode';

import { PatientEpisodeEndReason } from 'models/PatientEpisodeEndReason';

class PatientEpisodesStore {
  @observable
  patientEpisodes: DataMap<PatientEpisode> = new DataMap<PatientEpisode>();

  @observable
  episodeEndReasons: DataMap<PatientEpisodeEndReason> = new DataMap<PatientEpisodeEndReason>();
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  async fetchPatientEpisodes(patientId: number) {
    const responseEpisodes = await PatientEpisodesFetcher.fetchPatientEpisodes(patientId);
    this.patientEpisodes.setItems(responseEpisodes);
  }

  @action
  async createPatientEpisode(patientId: number, episodeBody: EpisodeCreationAttrs) {
    return await PatientEpisodesFetcher.createPatientEpisode(patientId, episodeBody);
  }

  @action
  async updatePatientEpisode(patientId: number, episodeBody: EpisodeUpdateAttrs) {
    await PatientEpisodesFetcher.updatePatientEpisode(patientId, episodeBody);
    return this.handleUpdateCreateEpisode(patientId);
  }

  @action
  async endEpisode(patientId: number, episodeId: number, reason: EndEpisodeRequestBody) {
    await PatientEpisodesFetcher.endEpisode(patientId, episodeId, reason);
    return this.handleUpdateCreateEpisode(patientId);
  }

  @action
  async populateEndReasons(): Promise<void> {
    const reasons = await PatientEndEpisodeReasonsFetcher.fetchReasons();
    this.episodeEndReasons.replaceItems(reasons);
  }

  @action
  async createPatientEpisodeEndReason({
    name,
    isActive
  }: CreateEndPatientEpisodeReasonReq): Promise<number> {
    const newReasonId = await PatientEndEpisodeReasonsFetcher.createReason({ name, isActive });
    const newReason = new PatientEpisodeEndReason({
      id: newReasonId,
      name,
      isActive
    });
    this.episodeEndReasons.set(newReasonId, newReason);
    return newReasonId;
  }

  @action
  async updateEpisodeEndReason(id: number, editedName: string) {
    await PatientEndEpisodeReasonsFetcher.updateReason(id, editedName);
    const updatedEpisodeReason = this.episodeEndReasons.get(id);
    updatedEpisodeReason.name = editedName;
  }

  @action
  async deletePatientEpisodeEndReason(id: number) {
    await PatientEndEpisodeReasonsFetcher.deleteReason(id);
    const deletedEpisodeReason = this.episodeEndReasons.get(id);
    deletedEpisodeReason.deletedAt = new Date();
  }

  handleUpdateCreateEpisode(patientId: number) {
    const { patientPageStore, careManagementPatientStore, settingsStore, tasksStore } =
      this.rootStore.stores;
    const hasCareManagement = settingsStore.hasFeature(FEATURES.CARE_MANAGEMENT);
    this.fetchPatientEpisodes(patientId);
    patientPageStore.loadSinglePatientFromServer(patientId);
    if (hasCareManagement) {
      careManagementPatientStore.fetchPatientCmSummary(patientId);
    }
    return tasksStore.fetchTasks();
  }

  episodesForPatient = computedFn((patientId: number) => {
    return this.patientEpisodes.items.filter(
      (episode: PatientEpisode) => episode.patientId === patientId
    );
  });

  getEpisodeTitle = (patientEpisodeId: number, fallbackPatientEpisode?: PatientEpisode) => {
    const storePatientEpisode = this.patientEpisodes.get(patientEpisodeId);
    const patientEpisode = storePatientEpisode || fallbackPatientEpisode;

    if (!patientEpisode) {
      return null;
    }

    const episode: EpisodeMetadata = this.rootStore.stores.constantsStore.getEpisodeById(
      patientEpisode.episodeId
    );

    const episodeRange = patientEpisode.endDate
      ? `(${patientEpisode.formattedStartDate} - ${patientEpisode.formattedEndDate})`
      : '';
    return `${episode?.name} ${episodeRange}`;
  };
}

export default PatientEpisodesStore;
