// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { CM_QUICK_ADDITION } from 'constants/reasons.const';

import EditReasonModal from 'views/Pages/CareManagement/CareManagementReasonModal/CareManagementEditReasonModal';

import { useIntervalContext } from 'components/IntervalsEditor/IntervalsContext';
import { refreshCurrent } from 'components/IntervalsEditor/IntervalsEditor.shared';

interface Props {
  isOpen: boolean;
}

const QuickAdditionsSelectReasonModal: FC<Props> = ({ isOpen }) => {
  const { reasonsStore } = useStores();
  const { quickAdditionsCtx, quickAdditionsEditedReason, setQuickAdditionEditedReason } =
    useIntervalContext();

  const handleSaveEditedReason = () => {
    setQuickAdditionEditedReason(null);
  };

  const handleEditCanceled = () => {
    setQuickAdditionEditedReason(null);
  };

  const handleDeleteEditedReason = async () => {
    try {
      await reasonsStore.deleteReason(CM_QUICK_ADDITION, quickAdditionsEditedReason.id);
    } finally {
      refreshCurrent(quickAdditionsCtx);
      setQuickAdditionEditedReason(null);
    }
  };

  return (
    <EditReasonModal
      title="Edit Quick Addition Reason"
      selectedReasonId={quickAdditionsEditedReason?.id}
      reasonType={CM_QUICK_ADDITION}
      onSave={handleSaveEditedReason}
      onCancel={handleEditCanceled}
      onDelete={handleDeleteEditedReason}
      reasonLabel=""
      isOpen={isOpen}
    />
  );
};

export default observer(QuickAdditionsSelectReasonModal);
