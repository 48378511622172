import { FC, ReactNode, useState } from 'react';

import { Box, CircularProgress, Collapse } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { ToastContentProps } from 'react-toastify/dist/types';

import Icon from 'components/Icons/Icon';
import { Text } from 'components/UIkit/atoms/Text';
import { BaseToastOptions } from 'components/UIkit/atoms/Toast/BaseToast';

export interface PersistToastOptions extends BaseToastOptions {
  additionalContent?: ReactNode;
  isLoading?: boolean;
  onToggleContent?: (isExpanded: boolean) => void;
}

type Props = ToastContentProps<PersistToastOptions>;

export const PersistentToast: FC<Props> = ({ closeToast, data, toastProps }) => (
  <InternalPersistToast toastProps={toastProps} closeToast={closeToast} data={data} />
);

const InternalPersistToast: FC<Props> = ({ closeToast, data }) => {
  const [isContentExpanded, setIsContentExpanded] = useState(false);

  const { message, additionalContent, description, isLoading, onToggleContent } = data!;
  const hasExpandAction = Boolean(additionalContent);

  const toggleContent = () => {
    setIsContentExpanded((prevState) => !prevState);

    if (onToggleContent) {
      onToggleContent(!isContentExpanded);
    }
  };

  return (
    <>
      <Box
        px={16}
        pt={16}
        pb={description ? 0 : 16}
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <Text variant="body3" color="primary">
          {message}
        </Text>

        <Box display="flex" alignItems="center">
          {isLoading ? (
            <Box component="span" display="flex">
              <CircularProgress size={20} />
            </Box>
          ) : (
            <>
              {hasExpandAction && (
                <StyledToastAction onClick={toggleContent}>
                  <Icon.Chevron up={!isContentExpanded} />
                </StyledToastAction>
              )}

              <StyledToastAction onClick={() => closeToast && closeToast()}>
                <Icon.XBase />
              </StyledToastAction>
            </>
          )}
        </Box>
      </Box>

      {description && (
        <Text mt={2} variant="body2" px={16} pb={16} component="div">
          {description}
        </Text>
      )}

      <Collapse in={isContentExpanded}>
        <StyledContent py={8} px={16}>
          <Text variant="body2">{additionalContent}</Text>
        </StyledContent>
      </Collapse>
    </>
  );
};

const StyledToastAction = styled('span')(
  ({ theme }) => css`
    cursor: pointer;
    margin-left: ${theme.spacing(24)};

    svg {
      color: ${theme.palette.text.primary};
    }

    &:hover {
      svg {
        color: ${theme.palette.primary.main};
      }
    }
  `
);

const StyledContent = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.natural.inactiveBackground};
    width: 100%;
  `
);
