import { PathwayOptionOutcome } from 'models/PathwayTemplates';
const outcomeToClass: { [key: string]: string } = {
  [PathwayOptionOutcome.CLINIC]: 'warning',
  [PathwayOptionOutcome.CLINIC_EMERGENT]: 'emergency'
};

const outcomeToString: { [key: string]: string } = {
  [PathwayOptionOutcome.CLINIC]: 'PROVIDER ATTENTION TODAY',
  [PathwayOptionOutcome.CLINIC_EMERGENT]: 'IMMEDIATE PROVIDER ATTENTION'
};

export const getOutcomeClassByOutcome = (outcome: PathwayOptionOutcome): string => {
  return outcomeToClass[outcome] || '';
};

export const getOutcomeTextByString = (outcome: PathwayOptionOutcome): string => {
  return outcomeToString[outcome] || '';
};
