// @ts-strict-ignore
import { FunctionComponent, ReactNode } from 'react';

import { css, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import { callTestSelectors } from 'tests/models/components/call/call.selectors';

import { formatDate } from 'utils/DateUtils';

import Call from 'models/Call';
import Patient from 'models/Patient';

import { useClinicianFullNameWithCredentials } from 'hooks/useClinicianFullNameWithCredentials';

import { useUserPreferences } from 'hooks/useUserPreferences';

import DeprecatedGeneratedCallView from 'views/Widgets/DeprecatedGeneratedCallView';
import GeneratedCallDetailsView from 'views/Widgets/GeneratedCallDetailsView';
import { ItemRow } from 'views/Widgets/ItemRow';

import Icon from 'components/Icons/Icon';

import './CallRow.scss';

interface BaseCallRowProps {
  action?: ReactNode;
  call: Call | Partial<Call>;
  patient: Patient;
}

const CallRow: FunctionComponent<BaseCallRowProps> = observer(({ patient, call, action }) => {
  const { pathwayNames } = call;
  const { userPreferences } = useUserPreferences();
  const { clinician } = call;
  const clinicianFullNameWithCredentials = useClinicianFullNameWithCredentials(
    clinician?.firstName,
    clinician?.lastName,
    clinician?.credentialId
  );

  const callDetails = call.info ? (
    <GeneratedCallDetailsView
      call={call}
      patient={patient}
      primarySymptoms={call.callReasons}
      pathwayAnswers={call.orderedPathwayAnswers}
      isGenerateSmartSummaryPreferenceEnabled={userPreferences.generateSmartSummary}
    />
  ) : (
    <DeprecatedGeneratedCallView call={call} />
  );

  return (
    <ItemRow
      className={classNames('call-row', { 'draft-call-row': call.isDraft })}
      icon={
        call.isDraft ? (
          <Icon.Pencil data-test-hook={callTestSelectors.draftIcon} />
        ) : (
          <Icon.LandPhone />
        )
      }
      content={
        <div className="text-truncate">
          <Typography variant="h4" component="span">
            {/*remove component prop when we change h4 to be a span by default*/}
            Call {call.isDraft ? 'Draft' : 'Logged'} by {clinicianFullNameWithCredentials} •{' '}
            {formatDate(call.createdAt, 'M/D/YYYY')}
          </Typography>

          <StyledTypography variant="form-text">&nbsp;— {call.durationText}</StyledTypography>

          {pathwayNames && (
            <StyledTypography variant="form-text">
              &nbsp;•&nbsp;{pathwayNames.length > 1 ? 'Pathways' : 'Pathway'}:{' '}
              {pathwayNames.join(', ')}
            </StyledTypography>
          )}

          {call.reasonNames?.length > 0 && (
            <StyledTypography variant="form-text">
              &nbsp;•&nbsp;Discussed: {call.reasonNames.join(', ')}
            </StyledTypography>
          )}
        </div>
      }
      action={action}
      collapsibleContent={callDetails}
    />
  );
});

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
  `
);

export default CallRow;
