// @ts-strict-ignore
import { ErrorName, transformErrorName } from 'errors';

import HttpService from 'services/HttpService';

import { getIntervalOverlapErrorMessage } from 'utils/IntervalValidationUtils';

import { API_URLS } from 'constants/apiUrls';

import { CareTimeParser } from 'parsers/CareTimeParser';

import { CareTimeData } from 'models/CareTime';
import { DurationInterval } from 'models/DurationInterval';

import { CareIntervalRequest, CareTimeDataRequest } from './responses/care-time-data.response';

const httpService = new HttpService('care timer');

class CareTimerFetcher {
  static async validateInterval(
    patientId: number,
    interval: DurationInterval
  ): Promise<string | null> {
    try {
      await httpService.post<void>({
        url: API_URLS.VALIDATE_CARE_INTERVAL(patientId),
        data: DurationInterval.toRequest(interval)
      });
    } catch (error) {
      switch (error.name) {
        case ErrorName.OverlappingIntervals:
          const { overlappingPatient } = error.httpFailure.serverData;
          return getIntervalOverlapErrorMessage(overlappingPatient);
        case ErrorName.IntervalOutOfAllowedScope:
          return 'Selection must be from today or yesterday (if today is the first of the month, only today is allowed).';
        default:
          throw error;
      }
    }
  }

  static async getCareTimeData(patientId: number): Promise<CareTimeData> {
    return await httpService.get<CareTimeData>({
      url: API_URLS.CARE_TIME_DATA(patientId),
      transformResponse: CareTimeParser.parseCareTimeData
    });
  }

  static async updateCareTime(patientId: number, data: CareTimeDataRequest) {
    return await httpService.post<void, CareTimeDataRequest>({
      url: API_URLS.CARE_TIME_DATA(patientId),
      data
    });
  }

  static async createInterval(patientId: number, interval: DurationInterval): Promise<number> {
    return await httpService.post<number, CareIntervalRequest>({
      url: API_URLS.CREATE_CARE_INTERVAL(patientId),
      data: DurationInterval.toRequest(interval),
      transformError: transformErrorName(ErrorName.CareIntervalFailed)
    });
  }

  static async updateInterval(patientId: number, interval: DurationInterval) {
    return await httpService.put<void, CareIntervalRequest>({
      url: API_URLS.UPDATE_CARE_INTERVAL(patientId, interval.id),
      data: DurationInterval.toRequest(interval),
      transformError: transformErrorName(ErrorName.CareIntervalFailed)
    });
  }
}

export default CareTimerFetcher;
