import { ErrorName, transformErrorName, transformErrorUiProps } from 'errors';

import HttpService from 'services/HttpService';

import { publicUrlsService } from 'services/publicUrls.service';

import { API_URLS } from 'constants/apiUrls';

import { FeatureIntroCodes } from 'models/Doctor';
import { PhoneNumberCarrierDetails } from 'models/PhoneNumberDetails';

const httpService = new HttpService('user');

export class UserFetcher {
  static async logout(): Promise<{}> {
    return await httpService.get({
      url: API_URLS.LOGOUT,
      transformError: transformErrorUiProps('Failed to logout'),
      overrideBaseURL: publicUrlsService.getUrlWithCurrentProtocol('loginApi')
    });
  }

  static async lookUpPhoneDetails(phoneNumber: string): Promise<PhoneNumberCarrierDetails> {
    return await httpService.get({ url: API_URLS.LOOKUP_PHONE_DETAILS(phoneNumber) });
  }

  static async sendSupportRequest(message: string): Promise<void> {
    return await httpService.post({ url: API_URLS.SEND_SUPPORT_REQUEST, data: { message } });
  }

  static async updateIntroFinished(feature: FeatureIntroCodes): Promise<void> {
    return await httpService.post({
      url: API_URLS.UPDATE_FEATURE_FINISHED,
      transformError: transformErrorName(ErrorName.UpdateIntroFeatureFailed),
      data: { code: feature }
    });
  }
}
