import { FC } from 'react';

import { Box } from '@mui/material';

import { ErrorName, HttpError } from 'errors';
import { observer } from 'mobx-react';

import { useForm } from 'react-hook-form';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';

import { useStores } from 'mobx/hooks/useStores';

import { HIDDEN_ERROR_MESSAGE_TEXT, SERVER_ERROR } from 'utils/TicketType.utils';

import { showToast } from 'utils/UserMessageUtils';

import { API_URLS } from 'constants/apiUrls';

import { StyledError } from 'views/Pages/PracticeManagement/TicketTypes/Modals/shared';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  ticketTypeId: number;
}

export const CreateTicketSubTypeModal: FC<Props> = observer(({ isOpen, onClose, ticketTypeId }) => {
  const { ticketTypesStore } = useStores();
  const isLoading = useNetworkLoading([API_URLS.OPERATOR_TICKET_TYPES]);
  const methods = useForm();
  const { register, handleSubmit, watch, formState, setError } = methods;
  const { errors } = formState;
  const { name } = watch();

  const createTicketSubType = async () => {
    try {
      await ticketTypesStore.createTicketSubType({
        name: name.trim(),
        parentId: ticketTypeId,
        displayNames: []
      });
      showToast({ message: `Ticket Sub-Type "${name}" Added` });
      onClose();
    } catch (error) {
      if (
        error instanceof HttpError &&
        error.name === ErrorName.DuplicateTicketSubTypeOperatorName
      ) {
        setError('name', {
          type: SERVER_ERROR,
          message: error?.ui?.title || 'Something went wrong'
        });
      } else {
        throw error;
      }
    }
  };

  const disableSubmit = isLoading || Object.keys(errors).length > 0;

  return (
    <FormModal
      isOpen={isOpen}
      title="Ticket Sub-Type"
      confirmActions={[
        {
          onClick: handleSubmit(createTicketSubType),
          text: isLoading ? 'Saving...' : 'Save',
          disabled: disableSubmit,
          testHook: 'create-ticket-subtype-btn'
        }
      ]}
      closeAction={{ onClick: onClose, disabled: isLoading }}
      defaultValues={{
        name: ''
      }}
      methods={methods}
    >
      <Box mb={28}>
        <RHFStyledInput
          isRequired
          label="Name for Operators"
          name="name"
          register={register}
          error={Boolean(errors.name)}
          testHook="create-ticket-subtype-modal-input"
        />

        <StyledError isError={Boolean(errors.name?.message)} variant="helper-text">
          {(errors.name?.message as string) || HIDDEN_ERROR_MESSAGE_TEXT}
        </StyledError>
      </Box>
    </FormModal>
  );
});
