import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { ClinicianAlertsParser } from 'parsers/ClinicianAlertsParser';

import ClinicianAlert from 'models/ClinicianAlert';

const httpService = new HttpService('clinician alert', true);

class ClinicianAlertsFetcher {
  static async getAlerts() {
    return await httpService.get<ClinicianAlert[]>({
      url: API_URLS.CLINICIAN_ALERTS_V1,
      transformResponse: ClinicianAlertsParser.parseClinicianAlerts
    });
  }

  static async createAlert(alert: ClinicianAlert) {
    const response = await httpService.post<{ id: number }>({
      url: API_URLS.CLINICIAN_ALERTS,
      data: alert
    });
    return response.id;
  }

  static async removeAlert(id: number) {
    return await httpService.delete({ url: API_URLS.REMOVE_ALERT(id) });
  }

  static async updateAlert(alert: ClinicianAlert) {
    return await httpService.put({ url: API_URLS.UPDATE_ALERT(alert.id), data: alert });
  }
}

export default ClinicianAlertsFetcher;
