import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';

export const NotificationsPagingError: FC = () => (
  <StyledErrorGrid container flexDirection="column" alignItems="center" justifyContent="center">
    <Typography variant="body1">Issue Loading Notifications</Typography>
    <Typography variant="body2">Please refresh / try again in a moment.</Typography>
  </StyledErrorGrid>
);

const StyledErrorGrid = styled(Grid)(
  ({ theme }) => css`
    height: 72px;
    width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: ${theme.palette.error.light};
  `
);
