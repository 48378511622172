import { FC, FocusEvent } from 'react';

import { Box, css, styled } from '@mui/material';

import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import {
  LightweightPathwayQuestionServerStructure,
  LightweightPathwayDetailsServerStructure
} from 'fetchers/responses/pathways.response';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import { DateQuestion } from 'components/LightweightPathway/DateQuestion';
import { IMMEDIATE_ATTENTION } from 'components/LightweightPathway/LightweightPathway.constants';
import {
  DateValue,
  LightweightPathwayDependentQuestionServerStructure,
  LightweightPathwayQuestionAlertText,
  LightweightPathwayQuestionAnswer,
  MultipleValue,
  QuestionActionMeta,
  SingleValue,
  TextValue
} from 'components/LightweightPathway/LightweightPathway.types';
import { getLightweightQuestionAlert } from 'components/LightweightPathway/LightweightPathway.utils';
import { MultipleQuestion } from 'components/LightweightPathway/MultipleQuestion';
import { SingleQuestion } from 'components/LightweightPathway/SingleQuestion';
import { TextQuestion } from 'components/LightweightPathway/TextQuestion';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  question:
    | LightweightPathwayQuestionServerStructure
    | LightweightPathwayDependentQuestionServerStructure;
  pathway: LightweightPathwayDetailsServerStructure;
  index: number;
  answer?: LightweightPathwayQuestionAnswer;
  handleAnswerChanged: (
    newValue: LightweightPathwayQuestionAnswer,
    actionMeta: QuestionActionMeta
  ) => void;
  handleTextQuestionBlur?: (
    event: FocusEvent<HTMLInputElement>,
    currentValue: string,
    valueAfterFocus: string,
    question:
      | LightweightPathwayQuestionServerStructure
      | LightweightPathwayDependentQuestionServerStructure
  ) => void;
  parentQuestionId?: string;
  pathwayIndex: number;
}

export const LightweightPathwayQuestionComponent: FC<Props> = ({
  question,
  pathway,
  index,
  handleTextQuestionBlur,
  answer,
  handleAnswerChanged,
  parentQuestionId,
  pathwayIndex
}) => {
  const alertText = getLightweightQuestionAlert(answer);

  return (
    <StyledContainer
      className="lightweight-pathway-question"
      data-test-hook={sharedTestSelectors.lightweightPathwayQuestionContainer(question.id)}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={8}>
        <Text variant="h4" component="div">
          {question.title}
        </Text>

        <StyledAlertContainer alertText={alertText}>
          <Text color={alertText === IMMEDIATE_ATTENTION ? 'white' : 'primary'} variant="h4">
            {alertText}
          </Text>
        </StyledAlertContainer>
      </Box>

      {question.type === PathwayQuestionTypes.SINGLE && (
        <SingleQuestion
          question={question}
          pathway={pathway}
          index={index}
          answer={answer as LightweightPathwayQuestionAnswer<SingleValue>}
          handleAnswerChanged={handleAnswerChanged}
          parentQuestionId={parentQuestionId}
          pathwayIndex={pathwayIndex}
        />
      )}

      {question.type === PathwayQuestionTypes.MULTIPLE && (
        <MultipleQuestion
          question={question}
          pathway={pathway}
          index={index}
          answer={answer as LightweightPathwayQuestionAnswer<MultipleValue>}
          handleAnswerChanged={handleAnswerChanged}
          parentQuestionId={parentQuestionId}
          pathwayIndex={pathwayIndex}
        />
      )}

      {question.type === PathwayQuestionTypes.DATE && (
        <DateQuestion
          question={question}
          pathway={pathway}
          index={index}
          answer={answer as LightweightPathwayQuestionAnswer<DateValue>}
          handleAnswerChanged={handleAnswerChanged}
          pathwayIndex={pathwayIndex}
        />
      )}

      {question.type === PathwayQuestionTypes.TEXT && (
        <TextQuestion
          question={question}
          pathway={pathway}
          index={index}
          answer={answer as LightweightPathwayQuestionAnswer<TextValue>}
          handleAnswerChanged={handleAnswerChanged}
          handleTextQuestionBlur={handleTextQuestionBlur}
          pathwayIndex={pathwayIndex}
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(20)};
  `
);

const StyledAlertContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'alertText'
})<{
  alertText: LightweightPathwayQuestionAlertText;
}>(
  ({ theme, alertText }) => css`
    opacity: ${alertText === '' ? 0 : 1};
    width: 218px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.borderRadius.xSmall};
    background-color: ${alertText === IMMEDIATE_ATTENTION
      ? theme.palette.error.dark
      : theme.palette.warning.main};
    height: 24px;
    flex-shrink: 0;
    margin-left: ${theme.spacing(28)};
  `
);
