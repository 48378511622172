import { pluralize } from 'utils/StringUtils';

import {
  TicketSummaryItemDisplayName,
  TicketSummaryItemType
} from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton.types';

export const getSummaryItemDisplayName = ({
  count,
  ticketSummaryItemType
}: {
  count: number;
  ticketSummaryItemType: TicketSummaryItemType;
}): Record<TicketSummaryItemType, string> => ({
  pathway: `${count} ${pluralize(typeToName[ticketSummaryItemType], count)}`,
  report: `${count} more ${pluralize(typeToName[ticketSummaryItemType], count)}`,
  comment: `${count} ${pluralize(typeToName[ticketSummaryItemType], count)}`,
  call: `${count} ${pluralize(typeToName[ticketSummaryItemType], count)}`,
  callAttempt: `${count} ${pluralize(typeToName[ticketSummaryItemType], count)}`,
  draft: `${count} ${pluralize(typeToName[ticketSummaryItemType], count)}`
});

const typeToName: Record<TicketSummaryItemType, TicketSummaryItemDisplayName> = {
  pathway: TicketSummaryItemDisplayName.Pathway,
  report: TicketSummaryItemDisplayName.Report,
  comment: TicketSummaryItemDisplayName.Comment,
  call: TicketSummaryItemDisplayName.Call,
  callAttempt: TicketSummaryItemDisplayName.CallAttempt,
  draft: TicketSummaryItemDisplayName.Draft
};
