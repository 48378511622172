// @ts-strict-ignore
import { FC, useState } from 'react';

import { Document, Page } from 'react-pdf';

import Loading from 'components/Loaders/Loading';

import './Privacy.scss';

const Privacy: FC = () => {
  const [numPages, setNumPages] = useState(null);

  const handleDocumentLoaded = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const pages = [];

  for (let i = 1; i <= numPages; i++) {
    pages.push(
      <Page
        className="d-flex flex-column align-items-center justify-content-center"
        width={window.innerWidth}
        key={i}
        pageNumber={i}
      />
    );
  }

  return (
    <div>
      <div className="limited-width justify-content-center">
        <Document
          loading={<Loading primaryColor />}
          className="terms-page-top-margin"
          options={{ isEvalSupported: false }}
          file="https://storage.googleapis.com/expain_documents/Privacy%20Policy.pdf"
          onLoadSuccess={handleDocumentLoaded}
        >
          {pages}
        </Document>
      </div>
    </div>
  );
};

export default Privacy;
