import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Cancel
  | AnalyticEventAction.Open
  | AnalyticEventAction.StartEpisode;

type Value = 'historical episode' | 'missing fields' | null;

interface Properties {
  action: Action;
  patient_id: number;
  value?: Value;
}

export const trackEnrollEpisodeModalAnalyticsEvent = ({
  action,
  patient_id,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.EnrollEpisodeModal,
    properties: { action, patient_id, ...optionalProps }
  });
};
