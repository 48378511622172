// @ts-strict-ignore
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { SECOND_IN_MILLISECONDS } from 'utils/DateUtils';

import { FeatureIntroCodes } from 'models/Doctor';

import { useClinicianFullNameWithCredentials } from 'hooks/useClinicianFullNameWithCredentials';

import Icon from 'components/Icons/Icon';

import { Tooltip, TooltipSelect } from 'components/Tooltip';

import { OutlinedIconSubtextButton } from 'components/UIkit/atoms/Button';

import './CareTimerButton.scss';

const DEFAULT_TIMER_VALUE = '00:00';

function useTimerMenu(onEdit: () => void) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { careTimerStore, userStore } = useStores();
  const fullNameWithCredentials = useClinicianFullNameWithCredentials(
    userStore.currentDoctor.firstName,
    userStore.currentDoctor.lastName,
    userStore.currentDoctor.credentialId
  );
  const closeMenu = () => setIsMenuOpen(false);

  const tooltipController = {
    visible: isMenuOpen,
    onClickOutside: closeMenu
  };

  const menuOptions = useMemo(
    () => [
      {
        text: <span>Resume</span>,
        id: 'resume',
        onClick: () => {
          closeMenu();
          careTimerStore.startInterval({
            id: userStore.currentDoctor.id,
            name: fullNameWithCredentials
          });
        }
      },
      {
        text: (
          <span>
            {!userStore.currentDoctor.hasFeature(FeatureIntroCodes.EditCareIntervals) && (
              <span className="edit-interval-intro">New: </span>
            )}{' '}
            Edit Intervals…
          </span>
        ),
        id: 'edit',
        onClick: () => {
          closeMenu();
          onEdit && onEdit();
        }
      }
    ],
    [careTimerStore, onEdit, userStore, fullNameWithCredentials]
  );

  return { tooltipController, menuOptions, setIsMenuOpen };
}
interface CareTimerButtonProps {
  onEdit?: () => void;
  disabled?: boolean;
}

const CareTimerButton: FC<CareTimerButtonProps> = ({ disabled, onEdit }) => {
  const buttonRef = useRef();
  const { careTimerStore } = useStores();
  const [timerTime, setTimerTime] = useState(DEFAULT_TIMER_VALUE);
  const timerMenu = useTimerMenu(onEdit);

  useEffect(
    function setCareTimerInterval() {
      const interval = setInterval(() => {
        const { currentSession } = careTimerStore;
        setTimerTime(currentSession ? careTimerStore.totalFormattedCareTime : DEFAULT_TIMER_VALUE);
      }, SECOND_IN_MILLISECONDS);

      return () => clearInterval(interval);
    },
    [careTimerStore]
  );

  const handleClicked = () => {
    if (careTimerStore.isRunning) {
      careTimerStore.endInterval();
    } else {
      if (!careTimerStore.wasInterrupted) {
        timerMenu.setIsMenuOpen(true);
      }
    }
  };

  return (
    <>
      <OutlinedIconSubtextButton
        onClick={handleClicked}
        disabled={disabled}
        subText={timerTime}
        ref={buttonRef}
        icon={careTimerStore.isRunning ? <Icon.Pause /> : <Icon.Resume />}
        isActive={timerMenu.tooltipController?.visible}
        mr={16}
      >
        Care Timer
      </OutlinedIconSubtextButton>

      <Tooltip reference={buttonRef} controller={timerMenu.tooltipController}>
        <TooltipSelect options={timerMenu.menuOptions} />
      </Tooltip>
    </>
  );
};

export default observer(CareTimerButton);
