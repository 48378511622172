import { FC, ReactElement } from 'react';

import { css, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useStores } from 'mobx/hooks/useStores';

import { NO_CONTENT_TEXT } from 'utils/TicketType.utils';

import { Testable } from 'utils/TypeUtils';

import { DisplayNameLanguage, TicketTypeCreationAllowance } from 'models/TicketTypes';

import { TicketTypeContent } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeCard/TicketTypeContent';

import { TicketTypeNode } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeNode';

import Icon from 'components/Icons/Icon';
import { Tooltip } from 'components/Tooltip';
import { LabeledCheckbox } from 'components/UIkit/atoms/Checkbox';

interface IconWithId {
  icon: ReactElement;
  id: string;
  tooltipContent: string;
}

interface Props extends Testable {
  ticketType: TicketTypeNode;
  hasPatientRequestFeature: boolean;
  onCreateTicketSubType: () => void;
  onDeleteTicketSubType?: () => void;
  onEditTicketType: () => void;
  toggleTicketTypeAllowance: (checkboxType: TicketTypeCreationAllowance) => void;
  isTicketSubType?: boolean;
}

export const TicketTypeRow: FC<Props> = ({
  ticketType,
  hasPatientRequestFeature,
  onCreateTicketSubType,
  onDeleteTicketSubType = null,
  onEditTicketType,
  toggleTicketTypeAllowance,
  isTicketSubType = false,
  testHook
}) => {
  const { ticketTypesStore } = useStores();
  const isCategoryEnabled = ticketType.isCategoryEnabled;
  const isOperatorsCheckboxChecked = ticketType.isOperatorsCheckboxChecked;
  const isOperatorsCheckboxDisabled = !isCategoryEnabled;
  const isPatientsCheckboxDisabled = !hasPatientRequestFeature || !isOperatorsCheckboxChecked;
  const isPatientsCheckboxChecked =
    hasPatientRequestFeature && ticketType.isPatientsCheckboxChecked;
  const patientsTooltipContent = !hasPatientRequestFeature
    ? 'Patient Requests is not available in your current plan. Please contact your Canopy customer success team for more information.'
    : 'Ticket Types cannot be enabled for patients if they are not enabled for operators.';

  const isPatientsTooltipDisabled = !isPatientsCheckboxDisabled || !isCategoryEnabled;

  const getDisplayNamesForTicket = (id: number) => {
    const englishDisplayName = ticketTypesStore.getDisplayNameByLanguageId(
      id,
      DisplayNameLanguage.English
    );

    const spanishDisplayName = ticketTypesStore.getDisplayNameByLanguageId(
      id,
      DisplayNameLanguage.Spanish
    );

    const defaultEnglishDisplayName = ticketTypesStore.getDisplayNameByLanguageId(
      id,
      DisplayNameLanguage.English,
      true
    );

    const defaultSpanishDisplayName = ticketTypesStore.getDisplayNameByLanguageId(
      id,
      DisplayNameLanguage.Spanish,
      true
    );

    const enDisplayName = englishDisplayName?.name || defaultEnglishDisplayName?.name;
    const enInstructions =
      englishDisplayName?.instructions ||
      defaultEnglishDisplayName?.instructions ||
      NO_CONTENT_TEXT;
    const spDisplayName = spanishDisplayName?.name || defaultSpanishDisplayName?.name;
    const spInstructions =
      spanishDisplayName?.instructions ||
      defaultSpanishDisplayName?.instructions ||
      NO_CONTENT_TEXT;
    return { enDisplayName, enInstructions, spDisplayName, spInstructions };
  };

  let { enDisplayName, enInstructions, spDisplayName, spInstructions } = getDisplayNamesForTicket(
    ticketType.id
  );

  if (isTicketSubType) {
    const isParentPatientCheckboxChecked =
      hasPatientRequestFeature && ticketType.parent?.isPatientsCheckboxChecked;

    if (isParentPatientCheckboxChecked) {
      const { enDisplayName: parentEnDisplayName, spDisplayName: parentSpDisplayName } =
        getDisplayNamesForTicket(ticketType.parent!.id);
      enDisplayName = `${parentEnDisplayName} - ${enDisplayName}`;
      spDisplayName = `${parentSpDisplayName} - ${spDisplayName}`;
    }
  }

  const getIconList = (): IconWithId[] => {
    const CREATE_ICON = {
      icon: (
        <Icon.Plus
          height="24"
          width="24"
          onClick={onCreateTicketSubType}
          data-test-hook={`${testHook}_create`}
        />
      ),
      id: `create-${ticketType.id}`,
      tooltipContent: 'Add Sub-Type'
    };

    const DELETE_ICON = {
      icon: (
        <Icon.Delete
          onClick={onDeleteTicketSubType || (() => null)}
          data-test-hook={`${testHook}_delete`}
        />
      ),
      id: `delete-${ticketType.id}`,
      tooltipContent: 'Delete Sub-Type'
    };

    const EDIT_ICON = {
      icon: <Icon.PencilOutline onClick={onEditTicketType} data-test-hook={`${testHook}_edit`} />,
      id: `edit-${ticketType.id}`,
      tooltipContent: 'Edit'
    };

    //Ticket Type
    if (!isTicketSubType) {
      if (isPatientsCheckboxChecked) {
        return [EDIT_ICON, CREATE_ICON];
      }

      return [CREATE_ICON];
    }

    //Ticket Sub Type (Built-in)
    if (!ticketType.isEditable) {
      if (isPatientsCheckboxChecked) {
        return [EDIT_ICON];
      }

      return [];
    }

    //Ticket Sub Type (added by the user)
    return [EDIT_ICON, DELETE_ICON];
  };

  const iconList = getIconList();

  return (
    <StyledGrid
      container
      direction="column"
      isTicketSubType={isTicketSubType}
      isHoverEnable={isCategoryEnabled}
      data-test-hook={`${testHook}_row`}
    >
      <StyledWrapper container>
        <StyledTypography
          variant={isTicketSubType ? 'form-text' : 'h4'}
          isCategoryEnabled={isCategoryEnabled}
          data-test-hook={testHook}
        >
          {isTicketSubType ? `${ticketType.parent?.name} - ` : ''}
          {ticketType.name}
        </StyledTypography>

        <StyledIconsContainer className="icons-container" hasSeparationLine={iconList.length > 0}>
          {iconList.map(({ icon, id, tooltipContent }) => (
            <StyledTooltip key={id} label={icon} placement="top" interactive={false}>
              <StyledTooltipContent>
                <Typography variant="body2">{tooltipContent}</Typography>
              </StyledTooltipContent>
            </StyledTooltip>
          ))}
        </StyledIconsContainer>

        <LabeledCheckbox
          label="Operators"
          id={`operators-${ticketType.id}`}
          checked={isOperatorsCheckboxChecked}
          onChange={() => toggleTicketTypeAllowance(TicketTypeCreationAllowance.OPERATOR)}
          disabled={isOperatorsCheckboxDisabled}
          testHook={`operators-${ticketType.id}`}
          mr={20}
        />

        <Tooltip
          label={
            <LabeledCheckbox
              label="Patients"
              id={`patients-${ticketType.id}`}
              onChange={() => toggleTicketTypeAllowance(TicketTypeCreationAllowance.PATIENT)}
              disabled={isPatientsCheckboxDisabled}
              checked={isPatientsCheckboxChecked}
              testHook={`patients-${ticketType.id}`}
            />
          }
          disabled={isPatientsTooltipDisabled}
          maxWidth={342}
          placement="top"
        >
          <StyledTooltipContent>
            <Typography variant="body2">{patientsTooltipContent}</Typography>
          </StyledTooltipContent>
        </Tooltip>
      </StyledWrapper>

      {isPatientsCheckboxChecked && (
        <TicketTypeContent
          enDisplayName={enDisplayName}
          enInstructions={enInstructions}
          spDisplayName={spDisplayName}
          spInstructions={spInstructions}
          testHook={`${testHook}_section`}
        />
      )}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isTicketSubType' && prop !== 'isHoverEnable'
})<{ isTicketSubType: boolean; isHoverEnable: boolean }>(
  ({ theme, isTicketSubType, isHoverEnable }) => css`
    border-radius: ${theme.borderRadius.large};
    border: solid 1px ${theme.palette.natural.border};
    background-color: white;
    margin-bottom: ${theme.spacing(20)};
    position: relative;
    margin-left: ${isTicketSubType ? theme.spacing(24) : 0};
    width: ${isTicketSubType ? `calc(100% - ${theme.spacing(24)})` : '100%'};

    ${isHoverEnable &&
    css`
      &:hover {
        border-color: ${theme.palette.primary.main};

        //className need to be removed after we implement this https://www.figma.com/file/vaQnuineafX4UuLgnnGDam/General-Design-System?node-id=601%3A1563
        & div.icons-container {
          display: flex;
        }
      }
    `}

    ${isTicketSubType &&
    css`
      &::before {
        position: absolute;
        content: '';
        left: 40px;
        bottom: calc(100% + 1px);
        width: 6px;
        height: 20px;
        background-color: ${theme.palette.natural.border};
      }
    `};
  `
);

const StyledTooltip = styled(Tooltip)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(28)};
  `
);

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isCategoryEnabled'
})<{ isCategoryEnabled: boolean }>(
  ({ theme, isCategoryEnabled }) => css`
    color: ${!isCategoryEnabled ? theme.palette.text.disabled : 'inherit'};
    flex: 1;
  `
);

const StyledWrapper = styled(Grid)`
  padding: ${({ theme }) => `${theme.spacing(12)} ${theme.spacing(24)}`};
  min-height: 60px;
  font-size: 14px;
  flex: 1;
  align-items: center;
`;

const StyledIconsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasSeparationLine'
})<{ hasSeparationLine: boolean }>(
  ({ theme, hasSeparationLine }) => css`
    display: none;
    margin-right: ${theme.spacing(28)};
    position: relative;

    svg {
      cursor: pointer;

      &:hover {
        color: ${theme.palette.primary.main};
      }
    }

    &::after {
      display: ${hasSeparationLine ? 'block' : 'none'};
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 36px;
      background-color: ${theme.palette.natural.border};
    }
  `
);

const StyledTooltipContent = styled('div')`
  padding: ${({ theme }) => theme.spacing(20)};
`;
