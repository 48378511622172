import { FC } from 'react';

import { Grid } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { useStores } from 'mobx/hooks/useStores';

import { ContactSupportModal } from 'models/ContactSupportModal';

import { useToggle } from 'hooks/useToggle';

import Icon from 'components/Icons/Icon';

export const ContactSupport: FC = () => {
  const { userStore } = useStores();
  const { isOpen, toggle } = useToggle(false);

  return (
    <>
      <StyledContainer
        container
        justifyContent="center"
        alignItems="center"
        title="Contact Support"
        onClick={toggle}
      >
        <Icon.Help />
      </StyledContainer>
      <ContactSupportModal isOpen={isOpen} onClose={toggle} email={userStore.currentDoctor.email} />
    </>
  );
};

const StyledContainer = styled(Grid)(
  ({ theme }) => css`
    min-width: 48px;
    width: 48px;
    height: 48px;
    margin-left: ${theme.spacing(12)};
    cursor: pointer;
    border-radius: ${theme.borderRadius.full};
    &:hover {
      background-color: ${theme.palette.natural.inactiveBackground};
    }
  `
);
