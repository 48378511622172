import { FC, RefObject } from 'react';

import { Box } from '@mui/material';
import { Reorder } from 'framer-motion';

import { observer } from 'mobx-react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { usePathwayEditorQuestion } from 'hooks/usePathwayEditorQuestion';
import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { PathwayAddCategoryButton } from 'views/Pages/PathwayBuilder/PathwayEditorView/Buttons/PathwayAddCategoryButton';
import { PathwayAddDependentQuestionsBlockButton } from 'views/Pages/PathwayBuilder/PathwayEditorView/Buttons/PathwayAddDependentQuestionsBlockButton';
import { PathwayAddQuestionButton } from 'views/Pages/PathwayBuilder/PathwayEditorView/Buttons/PathwayAddQuestionButton';
import { PathwayAddSubQuestionButton } from 'views/Pages/PathwayBuilder/PathwayEditorView/Buttons/PathwayAddSubQuestionButton';

import { DependentQuestionsBlock } from 'views/Pages/PathwayBuilder/PathwayEditorView/DependentQuestionsBlock';
import { KeyQuestionCheckbox } from 'views/Pages/PathwayBuilder/PathwayEditorView/KeyQuestionCheckbox';

import {
  StyledDragIndicatorContainer,
  StyledQuestionCard
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.shared';

import {
  CategoryFormField,
  PathwayEditorFormFields,
  QuestionFormField,
  SingleQuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import {
  getNewCategory,
  getNewDependentQuestionsBlock,
  getNewQuestion
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';
import { PathwayQuestionOptionAlert } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayQuestionOptionAlert';
import { PathwayQuestionTitle } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayQuestionTitle/PathwayQuestionTitle';
import { PathwayEditorBlockActionBar } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/PathwayEditorBlockActionBar';
import { QuestionSymptomsFormSelect } from 'views/Pages/PathwayBuilder/PathwayEditorView/QuestionSymptomsFormSelect';
import { RemoveBlockPopup } from 'views/Pages/PathwayBuilder/PathwayEditorView/RemoveBlockPopup';
import { PathwayQuestionOptionSuggestedPathway } from 'views/Pages/PathwayBuilder/PathwayEditorView/SuggestedPathway/PathwayQuestionOptionSuggestedPathway';
import { PathwayQuestionOption } from 'views/Pages/PathwayBuilder/shared/PathwayQuestionOption';

import { StyledOptionItemContainer } from 'views/Pages/PathwayBuilder/shared/styled';

import Icon from 'components/Icons/Icon';

interface Props {
  question: SingleQuestionFormField & Record<'id', string>;
  path:
    | `categories.${number}.questions.${number}`
    | `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}.questions.${number}`;
  index: number;
  categoryIndex?: number;
  insertQuestion: (questionIndex: number, data: QuestionFormField) => void;
  updateQuestion: (questionIndex: number, data: QuestionFormField) => void;
  moveQuestion: (indexA: number, indexB: number) => void;
  removeQuestion: (index?: number | number[]) => void;
  insertCategory?: (categoryIndex: number, value: CategoryFormField) => void;
  totalQuestions: number;
  isSub?: boolean;
  isAddSubQuestionButtonDisabled: boolean;
  containerUuid?: string;
  containerRef?: RefObject<HTMLDivElement>;
}

export const SingleQuestion: FC<Props> = observer(
  ({
    question,
    path,
    index,
    categoryIndex,
    updateQuestion,
    insertQuestion,
    moveQuestion,
    removeQuestion,
    insertCategory,
    totalQuestions,
    isSub = false,
    isAddSubQuestionButtonDisabled,
    containerUuid,
    containerRef
  }) => {
    const {
      isSelected,
      ref,
      controls,
      showMainQuestionButtons,
      showSubQuestionButtons,
      isRemoveQuestionPopupOpen,
      closeRemoveQuestionPopup,
      onRemoveQuestion,
      isLastQuestion
    } = usePathwayEditorQuestion(path, index, isSub, totalQuestions, containerUuid, containerRef);
    const { selectPathwayEditorBlock } = usePathwayEditorSelection();
    const { control } = useFormContext<PathwayEditorFormFields>();

    const {
      fields: dependentQuestionsBlocks,
      remove: removeDependentQuestionsBlocks,
      append: appendDependentQuestionsBlock
    } = useFieldArray<
      PathwayEditorFormFields,
      `categories.${number}.questions.${number}.dependentQuestionsBlocks`
    >({
      control,
      name: `${path as `categories.${number}.questions.${number}`}.dependentQuestionsBlocks`
    });

    return (
      <>
        <Box mt={20} display="flex" flexDirection="column">
          <Reorder.Item
            key={question.uuid}
            value={question}
            as="div"
            dragListener={false}
            dragControls={controls}
            layout="position"
          >
            <StyledQuestionCard
              isSelected={isSelected}
              ref={ref}
              onClick={(event) => {
                event.stopPropagation();
                selectPathwayEditorBlock(question.uuid);
              }}
            >
              {isSelected && (
                <StyledDragIndicatorContainer onPointerDown={(e) => controls.start(e)}>
                  <Icon.DragIndicator />
                </StyledDragIndicatorContainer>
              )}

              <Box display="flex" mb={20} alignItems="center">
                <PathwayQuestionTitle
                  question={question}
                  questionPath={path}
                  questionIndex={index}
                  updateQuestion={updateQuestion}
                />

                <QuestionSymptomsFormSelect isQuestionSelected={isSelected} questionPath={path} />

                <KeyQuestionCheckbox isQuestionSelected={isSelected} questionPath={path} />
              </Box>

              <>
                {question.options.map((option) => (
                  <StyledOptionItemContainer key={option.optionId}>
                    <PathwayQuestionOption type={question.type} title={option.title} />

                    <Box display="flex" alignItems="center">
                      <PathwayQuestionOptionSuggestedPathway
                        question={question}
                        isQuestionSelected={isSelected}
                        option={option}
                        questionIndex={index}
                        updateQuestion={updateQuestion}
                      />

                      <PathwayQuestionOptionAlert
                        question={question}
                        isQuestionSelected={isSelected}
                        option={option}
                        questionIndex={index}
                        updateQuestion={updateQuestion}
                        questionPath={path}
                      />
                    </Box>
                  </StyledOptionItemContainer>
                ))}
              </>

              {isSelected && (
                <PathwayEditorBlockActionBar
                  index={index}
                  totalItems={totalQuestions}
                  onMove={moveQuestion}
                  onRemove={onRemoveQuestion}
                />
              )}
            </StyledQuestionCard>

            {!isSub && (
              <Box ml={56}>
                {dependentQuestionsBlocks?.map((block, index) => (
                  <DependentQuestionsBlock
                    key={block.uuid}
                    dependentQuestionsBlock={block}
                    path={`${
                      path as `categories.${number}.questions.${number}`
                    }.dependentQuestionsBlocks.${index}`}
                    questionOptions={question.options}
                    index={index}
                    removeDependentQuestionsBlocks={removeDependentQuestionsBlocks}
                  />
                ))}
              </Box>
            )}
          </Reorder.Item>

          {showMainQuestionButtons && (
            <Box display="flex" mt={20}>
              <PathwayAddQuestionButton
                onClick={(questionUuid) => insertQuestion(index + 1, getNewQuestion(questionUuid))}
              />

              <PathwayAddDependentQuestionsBlockButton
                onClick={(dependentQuestionsBlockUuid) =>
                  appendDependentQuestionsBlock(
                    getNewDependentQuestionsBlock(dependentQuestionsBlockUuid)
                  )
                }
              />

              {isLastQuestion && (
                <PathwayAddCategoryButton
                  onClick={(categoryUuid) =>
                    insertCategory!(categoryIndex! + 1, getNewCategory(categoryUuid))
                  }
                />
              )}
            </Box>
          )}

          {showSubQuestionButtons && (
            <PathwayAddSubQuestionButton
              isDisabled={isAddSubQuestionButtonDisabled}
              onClick={(questionUuid) => insertQuestion(index + 1, getNewQuestion(questionUuid))}
            />
          )}
        </Box>

        <RemoveBlockPopup
          blockUuid={question.uuid}
          isOpen={isRemoveQuestionPopupOpen}
          onCancel={closeRemoveQuestionPopup}
          onRemove={() => {
            removeQuestion(index);
            closeRemoveQuestionPopup();
          }}
        />
      </>
    );
  }
);
