import { computed } from 'mobx';

export enum ICallReasonTypes {
  ICD_CODE = 0,
  REMINDER = 2,
  OTHER = 3
}

export default class CallReason {
  type: ICallReasonTypes;

  text: string;

  icdCodeId?: number;

  icdCodeCode?: string;

  constructor(type: ICallReasonTypes, text: string) {
    this.type = type;
    this.text = text;
  }

  @computed
  get isReminder() {
    return this.type === ICallReasonTypes.REMINDER;
  }
}

export interface CallTopicDiscussed {
  name: string;
  id: string;
  parentId: number;
  parentName: string;
}
