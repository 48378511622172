import { FC } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { OutlinedButton } from 'components/UIkit/atoms/Button';

interface Props {
  episodeId: number;
  onCreateClick: (id: number) => void;
  onEditClick: () => void;
}

const EpisodeActions: FC<Props> = ({ episodeId, onCreateClick, onEditClick }) => {
  const { settingsStore } = useStores();

  return (
    <div className="episode-actions">
      {settingsStore.hasFeature(FEATURES.PM_VIEW_AND_EDIT_EPISODES) && (
        <OutlinedButton onClick={onEditClick} mr={12}>
          Edit
        </OutlinedButton>
      )}

      <OutlinedButton onClick={() => onCreateClick(episodeId)}>Add Task</OutlinedButton>
    </div>
  );
};

export default EpisodeActions;
