import { FC, useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { COMMAND_PRIORITY_CRITICAL, INDENT_CONTENT_COMMAND } from 'lexical';

import { isIndentPermitted } from 'components/UIkit/atoms/RichText/Plugins/ListMaxIndentLevelPlugin/ListMaxIndentLevelPlugin.utils';

type Props = Readonly<{
  maxDepth: number | null | undefined;
}>;

export const ListMaxIndentLevelPlugin: FC<Props> = ({ maxDepth }): null => {
  const [editor] = useLexicalComposerContext();

  useEffect(
    function registerIndentCommand() {
      return editor.registerCommand(
        INDENT_CONTENT_COMMAND,
        () => !isIndentPermitted(maxDepth ?? 7),
        COMMAND_PRIORITY_CRITICAL
      );
    },
    [editor, maxDepth]
  );
  return null;
};
