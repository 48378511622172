import { FC, ReactElement, SyntheticEvent, ReactNode } from 'react';

import { styled, Tooltip } from '@mui/material';

interface Props {
  title: NonNullable<ReactNode>;
  children: ReactElement<any, any>;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  onClose?: (event: SyntheticEvent | Event) => void;
  onOpen?: (event: SyntheticEvent) => void;
  disabled?: boolean;
  shouldTruncate?: boolean;
  className?: string;
  disablePortal?: boolean;
  arrow?: boolean;
}

export const SimpleTooltip: FC<Props> = ({
  title,
  children,
  placement = 'bottom',
  onClose,
  onOpen,
  disabled,
  shouldTruncate = true,
  className: wrapperClassName,
  disablePortal = false,
  arrow = true
}) => {
  const Wrapper = shouldTruncate ? StyledTruncate : 'div';
  if (disabled) {
    return <Wrapper className={wrapperClassName}>{children}</Wrapper>;
  }

  const tooltipContent = <StyledTooltipContent>{title}</StyledTooltipContent>;

  return (
    <Tooltip
      title={tooltipContent}
      placement={placement}
      onClose={onClose}
      onOpen={onOpen}
      arrow={arrow}
      PopperProps={{ disablePortal }}
    >
      <Wrapper className={wrapperClassName}>{children}</Wrapper>
    </Tooltip>
  );
};

const StyledTruncate = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledTooltipContent = styled('div')`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
`;
