import { AnalyticEventAction } from 'analytics';
import { trackInvitationsToastSummaryAnalyticsEvent } from 'analytics/events/invitations-toast-summary';

import { toast } from 'react-toastify';

import { CARE_MANAGEMENT_BULK_INVITE_LOCAL_STORAGE_KEY } from 'mobx/stores';

import { CareManagementCheckInviteProgressResponse } from 'fetchers/responses/care-management.response';

import Patient from 'models/Patient';

import { CmBulkInviteToastContent } from 'views/Pages/CareManagement/CmBulkInviteToastContent';

import { BaseToast, BaseToastOptions } from 'components/UIkit/atoms/Toast/BaseToast';

import { PersistentToast, PersistToastOptions } from 'components/UIkit/atoms/Toast/PersistentToast';
import { TOAST_DEFAULT_TIMEOUT_IN_MILLIS } from 'components/UIkit/atoms/Toast/Toast';

export const getBlockedSmsInfoText = (optedOutPatients: Patient[]) => {
  const sections = [];
  if (optedOutPatients.length) {
    sections.push(`SMS Unsubscriptions: \n This occurs when a patient has replied STOP to an SMS message from Canopy. 
         To subscribe again, the patient texts START to the Canopy SMS number: (833) 540-0122. Unsubscribed patients: 
    ${optedOutPatients
      .map((patient) => `${patient.firstName} ${patient.lastName}, MRN: ${patient.mrn || '-'}`)
      .join('\n')}
    `);
  }

  return sections.join('\n\n');
};

export const showRequestReportSummaryToast = (
  successfulReminders: number,
  blockedPatients: Patient[]
) => {
  if (blockedPatients.length > 0) {
    showPersistToast({
      message: getShortRemindedAllMessage(successfulReminders),
      description: `${blockedPatients.length} Patient(s) Unsubscribed from SMS`,
      additionalContent: getBlockedSmsInfoText(blockedPatients)
    });
  } else {
    showToast({ message: getShortRemindedAllMessage(successfulReminders) });
  }
};

export const GENERAL_OPTED_OUT_TITLE = 'Unsubscribed from SMS Messages';

export const GENERAL_OPTED_OUT_DESCRIPTION =
  'This occurs when a patient has ' +
  'replied STOP to an SMS message from Canopy. To subscribe again, the patient texts START to the Canopy SMS ' +
  'number: (833) 540-0122.';

export const getShortRemindedAllMessage = (remindedCount: number) =>
  `${isPlural(remindedCount) ? `${remindedCount} Report Reminders Sent` : 'Report Reminder Sent'}`;

const isPlural = (innumerable: number) => {
  return innumerable > 1 || innumerable === 0;
};

export const showCmBulkInviteInProgressToast = (bulkInvitationCount: number): number | string => {
  return toast(PersistentToast, {
    autoClose: false,
    data: {
      message: `Sending ${bulkInvitationCount} Invitations...`,
      isLoading: true
    }
  });
};

export const updateAndShowCmBulkInviteIsDoneToast = (
  toastId: string | number,
  cmBulkInviteData: CareManagementCheckInviteProgressResponse,
  clearBulkInviteToastId: () => void
) => {
  const { success, fail, incompatible } = cmBulkInviteData;

  let toastMessage = 'Invitations Sent';

  if (success === 0) {
    toastMessage = 'Failed to Send Invitations';
  } else if (fail > 0 || incompatible > 0) {
    toastMessage = `Invitations Sent (${fail + incompatible} Failed)`;
  }

  trackInvitationsToastSummaryAnalyticsEvent({
    action: AnalyticEventAction.Show,
    successful_invitations: success,
    failed_invitations: fail,
    incompatible_invitations: incompatible
  });

  toast.update(toastId, {
    render: PersistentToast,
    autoClose: false,
    onClose: () => {
      trackInvitationsToastSummaryAnalyticsEvent({
        action: AnalyticEventAction.Close,
        successful_invitations: success,
        failed_invitations: fail,
        incompatible_invitations: incompatible
      });
      localStorage.setItem(CARE_MANAGEMENT_BULK_INVITE_LOCAL_STORAGE_KEY, '');
      clearBulkInviteToastId();
    },
    data: {
      message: toastMessage,
      isLoading: false,
      additionalContent: (
        <CmBulkInviteToastContent success={success} fail={fail} incompatible={incompatible} />
      ),
      onToggleContent: (isExpanded: boolean) => {
        trackInvitationsToastSummaryAnalyticsEvent({
          action: isExpanded ? AnalyticEventAction.Expand : AnalyticEventAction.Hide,
          successful_invitations: success,
          failed_invitations: fail,
          incompatible_invitations: incompatible
        });
      }
    }
  });
};

export const showCmBulkInviteIsDoneToast = (
  cmBulkInviteData: CareManagementCheckInviteProgressResponse,
  clearBulkInviteToastId: () => void
): string | number => {
  const { success, fail, incompatible } = cmBulkInviteData;

  let toastMessage = 'Invitations Sent';

  if (success === 0) {
    toastMessage = 'Failed to Send Invitations';
  } else if (fail > 0 || incompatible > 0) {
    toastMessage = `Invitations Sent (${fail + incompatible} Failed)`;
  }

  trackInvitationsToastSummaryAnalyticsEvent({
    action: AnalyticEventAction.Show,
    successful_invitations: success,
    failed_invitations: fail,
    incompatible_invitations: incompatible
  });

  return toast(PersistentToast, {
    autoClose: false,
    onClose: () => {
      trackInvitationsToastSummaryAnalyticsEvent({
        action: AnalyticEventAction.Close,
        successful_invitations: success,
        failed_invitations: fail,
        incompatible_invitations: incompatible
      });
      localStorage.setItem(CARE_MANAGEMENT_BULK_INVITE_LOCAL_STORAGE_KEY, '');
      clearBulkInviteToastId();
    },
    data: {
      message: toastMessage,
      isLoading: false,
      additionalContent: (
        <CmBulkInviteToastContent success={success} fail={fail} incompatible={incompatible} />
      ),
      onToggleContent: (isExpanded: boolean) => {
        trackInvitationsToastSummaryAnalyticsEvent({
          action: isExpanded ? AnalyticEventAction.Expand : AnalyticEventAction.Hide,
          successful_invitations: success,
          failed_invitations: fail,
          incompatible_invitations: incompatible
        });
      }
    }
  });
};

export const showToast = (dataOptions: BaseToastOptions): string | number => {
  return toast(BaseToast, {
    autoClose: TOAST_DEFAULT_TIMEOUT_IN_MILLIS,
    data: {
      ...dataOptions
    }
  });
};

export const showPersistToast = (dataOptions: PersistToastOptions): string | number => {
  const { onClose, ...restDataOptions } = dataOptions;

  return toast(PersistentToast, {
    autoClose: false,
    onClose,
    data: {
      ...restDataOptions
    }
  });
};

export const updatePersistentToast = (toastId: number | string, options: PersistToastOptions) => {
  toast.update(toastId, {
    render: PersistentToast,
    autoClose: false,
    data: {
      ...options
    }
  });
};

export const clearAllToasts = () => {
  toast.dismiss();
};
