import { FC, Fragment, RefObject } from 'react';

import { Box } from '@mui/material';

import { Reorder } from 'framer-motion';
import { isEmpty } from 'lodash/fp';

import { observer } from 'mobx-react';

import { useFormContext } from 'react-hook-form';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import { PathwayAddCategoryButton } from 'views/Pages/PathwayBuilder/PathwayEditorView/Buttons/PathwayAddCategoryButton';

import { PathwayAddQuestionButton } from 'views/Pages/PathwayBuilder/PathwayEditorView/Buttons/PathwayAddQuestionButton';

import {
  CategoryFormField,
  PathwayEditorFormFields,
  QuestionFormField,
  SubQuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import {
  getNewCategory,
  getNewQuestion
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';
import { DateQuestion } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/DateQuestion';

import { MultipleQuestion } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/MultipleQuestion';
import { NewQuestion } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/NewQuestion';
import { SingleQuestion } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/SingleQuestion';
import { TextArrayQuestion } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/TextArrayQuestion';

import { TextQuestion } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/TextQuestion';

interface Props {
  questions: any[];
  onInsertQuestion: (questionIndex: number, data: QuestionFormField | SubQuestionFormField) => void;
  onUpdateQuestion: (questionIndex: number, data: QuestionFormField | SubQuestionFormField) => void;
  onMoveQuestion: (indexA: number, indexB: number) => void;
  onRemoveQuestion: (index?: number | number[]) => void;
  onReplaceQuestions: (value: QuestionFormField | QuestionFormField[]) => void;
  onInsertCategory?: (categoryIndex: number, value: CategoryFormField) => void;
  containerPath:
    | `categories.${number}`
    | `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}`;
  isSub?: boolean;
  isAddSubQuestionButtonDisabled?: boolean;
  containerUuid?: string;
  categoryIndex?: number;
  containerRef?: RefObject<HTMLDivElement>;
}

export const PathwayQuestions: FC<Props> = observer(
  ({
    questions,
    onInsertQuestion,
    onUpdateQuestion,
    onMoveQuestion,
    onRemoveQuestion,
    onReplaceQuestions,
    onInsertCategory,
    containerPath,
    isSub = false,
    isAddSubQuestionButtonDisabled = false,
    containerUuid,
    categoryIndex,
    containerRef
  }) => {
    const { watch } = useFormContext<PathwayEditorFormFields>();
    const currentQuestions = watch(`${containerPath}.questions`);

    //the newOrder is the array with his new order but the "dependentQuestionsBlocks" nested array is not the updated one,
    //so I copy the updated data from the "currentQuestions"
    const onReorder = (newOrder: QuestionFormField[]) => {
      const orderedUpdatedQuestions: QuestionFormField[] = [];

      newOrder.forEach((question) => {
        const updatedQuestion = (currentQuestions as QuestionFormField[]).find(
          (currentQuestion: QuestionFormField) => currentQuestion.uuid === question.uuid
        );

        if (updatedQuestion) {
          orderedUpdatedQuestions.push(updatedQuestion);
        }
      });

      onReplaceQuestions(orderedUpdatedQuestions);
    };

    return (
      <>
        <Reorder.Group axis="y" values={questions} onReorder={onReorder} as="div">
          {questions.map((question, index) => {
            const questionPath:
              | `categories.${number}.questions.${number}`
              | `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}.questions.${number}` = `${containerPath}.questions.${index}`;

            return (
              <Fragment key={question.uuid}>
                {question.type === 'NewQuestion' && (
                  <NewQuestion
                    question={question}
                    index={index}
                    path={questionPath}
                    updateQuestion={onUpdateQuestion}
                    moveQuestion={onMoveQuestion}
                    removeQuestion={onRemoveQuestion}
                    totalQuestions={questions.length}
                    isSub={isSub}
                    containerUuid={containerUuid}
                    containerRef={containerRef}
                  />
                )}

                {question.type === PathwayQuestionTypes.SINGLE && (
                  <SingleQuestion
                    question={question}
                    index={index}
                    path={questionPath}
                    insertQuestion={onInsertQuestion}
                    updateQuestion={onUpdateQuestion}
                    moveQuestion={onMoveQuestion}
                    removeQuestion={onRemoveQuestion}
                    insertCategory={onInsertCategory}
                    totalQuestions={questions.length}
                    isSub={isSub}
                    isAddSubQuestionButtonDisabled={isAddSubQuestionButtonDisabled}
                    containerUuid={containerUuid}
                    categoryIndex={categoryIndex}
                    containerRef={containerRef}
                  />
                )}

                {question.type === PathwayQuestionTypes.MULTIPLE && (
                  <MultipleQuestion
                    question={question}
                    index={index}
                    path={questionPath}
                    insertQuestion={onInsertQuestion}
                    updateQuestion={onUpdateQuestion}
                    moveQuestion={onMoveQuestion}
                    removeQuestion={onRemoveQuestion}
                    insertCategory={onInsertCategory}
                    totalQuestions={questions.length}
                    isSub={isSub}
                    isAddSubQuestionButtonDisabled={isAddSubQuestionButtonDisabled}
                    containerUuid={containerUuid}
                    categoryIndex={categoryIndex}
                    containerRef={containerRef}
                  />
                )}

                {question.type === PathwayQuestionTypes.TEXT && (
                  <TextQuestion
                    question={question}
                    index={index}
                    path={questionPath}
                    updateQuestion={onUpdateQuestion}
                    moveQuestion={onMoveQuestion}
                    removeQuestion={onRemoveQuestion}
                    insertCategory={onInsertCategory}
                    insertQuestion={onInsertQuestion}
                    totalQuestions={questions.length}
                    isSub={isSub}
                    isAddSubQuestionButtonDisabled={isAddSubQuestionButtonDisabled}
                    containerUuid={containerUuid}
                    categoryIndex={categoryIndex}
                    containerRef={containerRef}
                  />
                )}

                {/* currently, we don't have this kind of type except "Cancer Diagnosis" question */}
                {question.type === PathwayQuestionTypes.TEXT_ARRAY && (
                  <TextArrayQuestion
                    question={question}
                    index={index}
                    path={questionPath}
                    updateQuestion={onUpdateQuestion}
                    moveQuestion={onMoveQuestion}
                    removeQuestion={onRemoveQuestion}
                    insertCategory={onInsertCategory}
                    insertQuestion={onInsertQuestion}
                    totalQuestions={questions.length}
                    isSub={isSub}
                    isAddSubQuestionButtonDisabled={isAddSubQuestionButtonDisabled}
                    containerUuid={containerUuid}
                    categoryIndex={categoryIndex}
                    containerRef={containerRef}
                  />
                )}

                {question.type === PathwayQuestionTypes.DATE && (
                  <DateQuestion
                    question={question}
                    index={index}
                    path={questionPath}
                    updateQuestion={onUpdateQuestion}
                    moveQuestion={onMoveQuestion}
                    removeQuestion={onRemoveQuestion}
                    insertCategory={onInsertCategory}
                    insertQuestion={onInsertQuestion}
                    totalQuestions={questions.length}
                    isSub={isSub}
                    isAddSubQuestionButtonDisabled={isAddSubQuestionButtonDisabled}
                    containerUuid={containerUuid}
                    categoryIndex={categoryIndex}
                    containerRef={containerRef}
                  />
                )}
              </Fragment>
            );
          })}
        </Reorder.Group>

        {!isSub && isEmpty(questions) && (
          <Box display="flex" mt={20}>
            <PathwayAddQuestionButton
              onClick={(questionUuid) => onInsertQuestion(0, getNewQuestion(questionUuid))}
            />

            <PathwayAddCategoryButton
              onClick={(categoryUuid) =>
                onInsertCategory!(categoryIndex! + 1, getNewCategory(categoryUuid))
              }
            />
          </Box>
        )}
      </>
    );
  }
);
