// @ts-strict-ignore
import { PathwaySelectionAnswer, PathwayTemplateQuestion } from 'models/PathwayTemplates';

export function isDependentQuestionActive(
  dependantQuestion: PathwayTemplateQuestion,
  parentQuestionAnswers: PathwaySelectionAnswer[]
): boolean {
  const answersIds = parentQuestionAnswers.map((ans) => ans.optionId);
  const parentQuestionAnswersIds = new Set(answersIds);
  return dependantQuestion.dependsOn.some((answerId) => parentQuestionAnswersIds.has(answerId));
}
