import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { LabeledCheckbox } from 'components/UIkit/atoms/Checkbox/LabeledCheckbox';

import './OptOutCheckboxRow.scss';

const OptOutCheckboxRow = (props: {
  disabled?: boolean;
  checked: boolean;
  onChange: () => void;
}) => {
  const { settingsStore } = useStores();

  return (
    <div className="optout-checkbox-wrapper">
      {settingsStore.hasFeature(FEATURES.OPT_OUT_OF_REMOTE_MONITORING_OPTION) && (
        <LabeledCheckbox
          disabled={props.disabled}
          checked={props.checked}
          onChange={props.onChange}
          label="Opt Out of Remote Monitoring"
          id="optOutCheckbox"
        />
      )}
    </div>
  );
};

export default OptOutCheckboxRow;
