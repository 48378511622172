import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Remove | AnalyticEventAction.Add;
type Value = 'from' | 'until';

interface Properties {
  action: Action;
  value: Value;
}

export const trackTaskDateFilterAnalyticsEvent = ({ action, value }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.TaskDateFilter,
    properties: { action, value }
  });
};
