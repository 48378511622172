// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';

import { EpisodeMetadata } from 'mobx/stores';

import Chip from 'components/Chips/Chip';
import { getEpisodeColorIndex } from 'components/Ticket/TicketRow/shared';

const EpisodeTag: FC<{ episode: EpisodeMetadata }> = (props) => {
  const hasEpisode = Boolean(props.episode);
  return (
    <Chip.Item
      className="mb-2"
      borderless={hasEpisode}
      bold={hasEpisode}
      color={hasEpisode ? getEpisodeColorIndex(props.episode) : null}
    >
      {hasEpisode ? props.episode.name : 'Non-Episode Task'}
    </Chip.Item>
  );
};

export default observer(EpisodeTag);
