import { FC, useState } from 'react';

import { Box, css, styled, Typography } from '@mui/material';

import {
  PATHWAY_QUESTION_OPTION_ALERTS_SELECT,
  pathwayQuestionAlertOptions
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.constants';

import {
  PathwayOptionAlert,
  CategoryQuestionOption,
  QuestionOptionAlert
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import {
  alertOptionForSelectMap,
  getPathwayQuestionOptionSelectChevronIconColor
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';

import Icon from 'components/Icons/Icon';
import { DividerPlacement, ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  option: CategoryQuestionOption;
  selectOption: (optionId: string, optionAlert: QuestionOptionAlert) => void;
}

export const PathwayOptionAlertsSelect: FC<Props> = ({ option, selectOption }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const chevronIconColor = getPathwayQuestionOptionSelectChevronIconColor(
    isMenuOpen,
    Boolean(option.alert.value)
  );

  const selectLabel = (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      onClick={() => setIsMenuOpen((prevState) => !prevState)}
      width="290px"
    >
      {option.alert.value ? (
        <StyledAlertContainer
          py={2}
          px={20}
          backgroundColor={alertOptionForSelectMap[option.alert.value].backgroundColor}
        >
          <Text variant="h4">{alertOptionForSelectMap[option.alert.value].label}</Text>
        </StyledAlertContainer>
      ) : (
        <StyledTypography variant="body1" mr={8} isOpen={isMenuOpen}>
          Set Alert
        </StyledTypography>
      )}

      <StyledChevronIcon up={isMenuOpen} color={chevronIconColor} />
    </Box>
  );

  const options: ITooltipOption[] = pathwayQuestionAlertOptions.map((alert) => {
    if (alert === PathwayOptionAlert.NONE) {
      return {
        text: 'No Alert',
        withDivider: true,
        dividerPlacement: DividerPlacement.Bottom,
        isSelected: option.alert.value === PathwayOptionAlert.NONE,
        onClick: () => {
          selectOption(option.optionId, {
            value: PathwayOptionAlert.NONE,
            label: alertOptionForSelectMap[PathwayOptionAlert.NONE].label
          });
          setIsMenuOpen(false);
        }
      };
    }

    return {
      text: (
        <StyledAlertContainer
          py={2}
          px={20}
          backgroundColor={alertOptionForSelectMap[alert].backgroundColor}
        >
          <Text variant="h4">{alertOptionForSelectMap[alert].label}</Text>
        </StyledAlertContainer>
      ),
      isSelected: option.alert.value === alert,
      onClick: () => {
        selectOption(option.optionId, {
          value: alert,
          label: alertOptionForSelectMap[alert].label
        });
        setIsMenuOpen(false);
      }
    };
  });

  return (
    <Tooltip
      controller={{ visible: isMenuOpen, onClickOutside: () => setIsMenuOpen(false) }}
      label={selectLabel}
      trigger={TooltipTrigger.CLICK}
      maxWidth={300}
    >
      <TooltipSelect id={PATHWAY_QUESTION_OPTION_ALERTS_SELECT} options={options} />
    </Tooltip>
  );
};

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isOpen'
})<{ isOpen: boolean }>(
  ({ theme, isOpen }) => css`
    color: ${isOpen ? theme.palette.primary.dark : theme.palette.primary.main};
  `
);

const StyledAlertContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgColor'
})<{ backgroundColor: string }>(
  ({ theme, backgroundColor }) => css`
    background-color: ${backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.borderRadius.xSmall};

    span {
      color: ${theme.palette.natural.white};
    }
  `
);

const StyledChevronIcon = styled(Icon.Chevron, {
  shouldForwardProp: (prop) => prop !== 'color'
})<{ color: string }>(
  ({ theme, color }) => css`
    color: ${color};
    margin-left: ${theme.spacing(4)};
  `
);
