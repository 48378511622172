// @ts-strict-ignore
import { isArray } from 'lodash/fp';

import { RemainingDosesOption } from 'mobx/stores';

import { OralAnswerValueV1, OralQuestionTypeV1 } from 'models/OralReport/OralReportV1';
import { OralAnswerValueV2, OralQuestionTypeV2 } from 'models/OralReport/OralReportV2';

export type OralAnswerValue = boolean | number | string | Date | RemainingDosesOption;

export enum OralReportVersion {
  V1 = 1,
  V2 = 2
}

export interface OralReportRegimenMeta {
  regimen: string;
  isCustom: false;
}

export interface OralReportResponse<
  Value = OralAnswerValueV1 | OralAnswerValueV2,
  ReportItemType = OralQuestionTypeV1 | OralQuestionTypeV2
> extends OralReportRegimenMeta {
  startOfCycle?: string;
  previousReportDate?: string;
  reportItems: OralAnswerStructure<Value, ReportItemType>[];
}

export enum OralQuestionStatus {
  NotAsked = 'notAsked',
  Answered = 'answered',
  IDontKnow = 'iDontKnow'
}

// structure data, represent oral data as sent by the server
export interface OralAnswerStructure<
  Value = OralAnswerValueV1 | OralAnswerValueV2,
  ReportItemType = OralQuestionTypeV1 | OralQuestionTypeV2
> {
  questionType: ReportItemType;
  status: OralQuestionStatus;
  value?: Value;
  customMessage?: string;
  additions?: any;
}

export class ReportQuestionAnswer<
  Value = OralAnswerValueV1 | OralAnswerValueV2,
  QuestionType = OralQuestionTypeV1 | OralQuestionTypeV2
> implements OralAnswerStructure<Value, QuestionType>
{
  questionType: QuestionType;
  status: OralQuestionStatus;
  value?: Value;
  customMessage?: string;
  additions?: any;
  additionsParsed?: any;
  private isOkObject?: { value?: Value[]; customMessageExistenceValue?: boolean } = {};

  constructor(
    answer: OralAnswerStructure<Value, QuestionType>,
    isOk?: {
      value: Value | Value[];
      customMessageExistenceValue?: boolean;
    }
  ) {
    Object.assign(this, answer);

    if (isOk?.value !== undefined) {
      this.isOkObject.value = Array.isArray(isOk?.value) ? isOk?.value : [isOk?.value];
    }

    if (isOk?.customMessageExistenceValue !== undefined) {
      this.isOkObject.customMessageExistenceValue = isOk.customMessageExistenceValue;
    }
  }

  get isAnsweredOrDontKnow(): boolean {
    return this.isAnswered || this.isIDontKnow;
  }

  get isAnswered(): boolean {
    return this.status === OralQuestionStatus.Answered;
  }

  get isIDontKnow(): boolean {
    return this.status === OralQuestionStatus.IDontKnow;
  }

  // if true, means the patient answer fit the med regimen (no special attention required)
  get isOk(): boolean {
    let isOk =
      this.isAnswered &&
      this.isOkObject !== undefined &&
      this.isOkObject.value?.includes(this.value);

    if (isOk && this.isOkObject.customMessageExistenceValue !== undefined) {
      if (this.isOkObject.customMessageExistenceValue) {
        isOk = isOk && Boolean(this.customMessage);
      } else {
        isOk = isOk && !Boolean(this.customMessage);
      }
    }

    return isOk;
  }

  get callbackDenyReasonId(): number {
    // Mobile clients send the additions data differently, remove ambiguity after fixed on mobile clients
    return isArray(this.additions) ? this.additions[0] : this.additions;
  }
}
