import { FocusEvent, useEffect, useRef, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { getEventActionOnTextChange } from 'analytics/events/input-usage';
import {
  PathwayAnswerEventAction,
  trackPathwayAnswerAnalyticsEvent
} from 'analytics/events/pathway-answer';

import { castArray, isArray, isEmpty } from 'lodash/fp';

import { useFormContext } from 'react-hook-form';

import { useDeepCompareEffect } from 'react-use';

import PathwaysFetcher from 'fetchers/PathwaysFetcher';

import {
  LightweightPathwayQuestionServerStructure,
  LightweightPathwayServerStructure
} from 'fetchers/responses/pathways.response';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { TicketLightweightPathwayAnswer } from 'models/OperatorTicket';

import { PathwayOptionOutcome, PathwayQuestionTypes } from 'models/PathwayTemplates';

import { TicketTypeKind } from 'models/TicketTypes';

import { NO_WARNING } from 'views/Patient/PatientMain/PathwayQuestionView';

import {
  lightweightPathwayQuestionsRemoveActions,
  pathwayQuestionTypeToAnalyticsTypeMap
} from 'components/LightweightPathway/LightweightPathway.constants';
import {
  DateQuestionActionMeta,
  DateValue,
  LightweightPathwayDependentFlatQuestion,
  LightweightPathwayDependentQuestionAnswer,
  LightweightPathwayDependentQuestionServerStructure,
  LightweightPathwayMainFlatQuestion,
  LightweightPathwayMainQuestionAnswer,
  LightweightPathwayQuestionAction,
  LightweightPathwayQuestionAnswer,
  LightweightPathwayQuestionOption,
  MultipleQuestionActionMeta,
  MultipleValue,
  QuestionActionMeta,
  SingleQuestionActionMeta,
  SingleValue,
  TextQuestionActionMeta,
  TextValue
} from 'components/LightweightPathway/LightweightPathway.types';
import {
  getLightweightPathwaysFlatQuestions,
  getLightweightQuestionAlert
} from 'components/LightweightPathway/LightweightPathway.utils';
import { LightweightPathwaySummary } from 'components/Ticket/TicketForms/ticket.shared';
import {
  defaultSymptomUrgencyValue,
  questionOutcomeToUrgencyMap,
  urgencyToQuestionOutcomeMap
} from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';

interface UseLightweightPathwaysReturnType {
  lightweightPathways: LightweightPathwayServerStructure[];
  handleAnswerChanged: (
    newQuestionAnswer: LightweightPathwayQuestionAnswer,
    actionMeta: QuestionActionMeta
  ) => void;
  isLoading: boolean;
  handleTextQuestionBlur: (
    event: FocusEvent<HTMLInputElement>,
    currentValue: string,
    valueAfterFocus: string,
    question:
      | LightweightPathwayQuestionServerStructure
      | LightweightPathwayDependentQuestionServerStructure
  ) => void;
}

export const useLightweightPathways = (
  isFeatureEnable: boolean,
  context: 'create ticket' | 'edit ticket',
  selectors: {
    symptomsDropdown: string;
    answers: string;
    urgency: string;
    pathwaySummary: string;
  },
  prefilledQuestionsAnswers: TicketLightweightPathwayAnswer[] = []
): UseLightweightPathwaysReturnType => {
  const [lightweightPathways, setLightweightPathways] = useState<
    LightweightPathwayServerStructure[]
  >([]);
  const allQuestionsArray = useRef<
    (LightweightPathwayMainFlatQuestion | LightweightPathwayDependentFlatQuestion)[]
  >([]);
  const { symptomsDropdown, answers, urgency, pathwaySummary } = selectors;
  const [isLoading, setIsLoading] = useState(false);
  const isPrefilledQuestions = useRef(false);

  const { watch, setValue, unregister, getValues } = useFormContext();
  const [currentTicketTypeSelectorValue, currentAnswers] = watch([symptomsDropdown, answers]);
  const isSymptomsTicket =
    isArray(currentTicketTypeSelectorValue) ||
    currentTicketTypeSelectorValue?.parentKind === TicketTypeKind.symptom;

  useEffect(
    function handleSymptomChanged() {
      async function handleLightweightPathways() {
        if (!currentTicketTypeSelectorValue && !isSymptomsTicket) {
          setLightweightPathways([]);
          unregister(answers);
          return;
        }

        if (isSymptomsTicket && !isEmpty(currentTicketTypeSelectorValue)) {
          try {
            setIsLoading(true);
            const formattedSymptoms = castArray(currentTicketTypeSelectorValue); //when there is 1 symptom the value is an object

            const symptomIds = formattedSymptoms.map((symptom) =>
              Number((symptom as TicketSubTypeOption).value)
            );

            const newLightweightPathways = await PathwaysFetcher.getLightweightPathwaysBySymptomIds(
              symptomIds
            );

            setLightweightPathways(newLightweightPathways);

            allQuestionsArray.current = getLightweightPathwaysFlatQuestions(newLightweightPathways);

            if (!isEmpty(prefilledQuestionsAnswers) && !isPrefilledQuestions.current) {
              //prefilled questions for edit ticket modal
              const formattedPrefilledQuestionsAnswers: LightweightPathwayQuestionAnswer[] = [];

              const getFormattedPrefilledQuestionAnswer = (
                question:
                  | LightweightPathwayMainFlatQuestion
                  | LightweightPathwayDependentFlatQuestion
              ) => {
                const matchedQuestionAnswer = prefilledQuestionsAnswers.find(
                  (questionAnswer) =>
                    questionAnswer.questionId === question.id &&
                    questionAnswer.pathwayId === question.pathway.id
                );

                if (matchedQuestionAnswer) {
                  const prefilledQuestionAnswer: LightweightPathwayQuestionAnswer = {
                    ...question,
                    value: matchedQuestionAnswer.value
                  };

                  if (matchedQuestionAnswer.deniedOptions) {
                    prefilledQuestionAnswer.deniedOptions = matchedQuestionAnswer.deniedOptions;
                  }

                  formattedPrefilledQuestionsAnswers.push(prefilledQuestionAnswer);
                }
              };

              allQuestionsArray.current?.forEach((question) => {
                getFormattedPrefilledQuestionAnswer(question);
              });

              setValue(answers, formattedPrefilledQuestionsAnswers);
              setValue(
                pathwaySummary,
                getLightweightPathwaySummary(formattedPrefilledQuestionsAnswers)
              );
              isPrefilledQuestions.current = true;
            } else {
              generateNewLightweightPathwaysQuestionsAnswers();
            }
          } finally {
            setIsLoading(false);
          }
        }
      }

      if (isFeatureEnable) {
        handleLightweightPathways();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTicketTypeSelectorValue, isSymptomsTicket, unregister, getValues, setValue, answers]
  );

  useDeepCompareEffect(generateNewLightweightPathwaysQuestionsAnswers, [currentAnswers]);

  const getLightweightPathwaySummary = (
    currentAnswers: LightweightPathwayQuestionAnswer[]
  ): LightweightPathwaySummary[] => {
    // Create a summary for each pathway loaded from server. take into account existing answers
    const summaries: LightweightPathwaySummary[] = [];
    const answeredQuestionIds = new Set(currentAnswers.map((answer) => answer.id));
    const allPathways = new Map();

    // Get all pathway ids, including the ones in the additionalPathways field
    for (const question of allQuestionsArray.current) {
      if (!allPathways.has(question.pathway.id)) {
        allPathways.set(question.pathway.id, question.pathway.title);
      }
    }
    // Create a summary for each pathway id
    for (const pathwayId of allPathways.keys()) {
      const questionsForPathway = [];
      for (const question of allQuestionsArray.current) {
        if (question.pathway.id === pathwayId) {
          questionsForPathway.push(question);
        }
      }

      const unAnsweredQuestionIds = questionsForPathway
        .filter((question) => !answeredQuestionIds.has(question.id))
        .map((question) => question.id);

      summaries.push({
        pathwayId,
        pathwayName: allPathways.get(pathwayId),
        activeQuestionsCount: questionsForPathway.length,
        unansweredQuestions: unAnsweredQuestionIds
      });
    }
    return summaries;
  };

  function generateNewLightweightPathwaysQuestionsAnswers() {
    const newAnswers: (
      | LightweightPathwayMainQuestionAnswer
      | LightweightPathwayDependentQuestionAnswer
    )[] = [];

    const visibleQuestions = getAllVisibleFlatQuestions();

    visibleQuestions.forEach((visibleQuestion) => {
      const foundedAnswer = currentAnswers?.find(
        (questionAnswer: LightweightPathwayQuestionAnswer) =>
          questionAnswer.id === visibleQuestion.id
      );

      if (foundedAnswer) {
        newAnswers.push({
          ...visibleQuestion,
          value: foundedAnswer.value
        });
      }
    });

    setValue(answers, newAnswers);
    setValue(pathwaySummary, getLightweightPathwaySummary(newAnswers));
  }

  function getAllParentQuestions() {
    return allQuestionsArray.current.filter((question) => isEmpty(question.dependsOn));
  }

  function getParentQuestion(parentQuestionId: string, pathwayId: string) {
    const allParentQuestions = getAllParentQuestions();
    return allParentQuestions.find(
      (question) => question.id === parentQuestionId && question.pathway.id === pathwayId
    );
  }

  function getAllVisibleFlatQuestions() {
    const visibleQuestions: (
      | LightweightPathwayMainFlatQuestion
      | LightweightPathwayDependentFlatQuestion
    )[] = [];

    allQuestionsArray.current.forEach((question) => {
      if (isEmpty(question.dependsOn)) {
        visibleQuestions.push(question);
      } else {
        const parentQuestion = getParentQuestion(
          (question as LightweightPathwayDependentFlatQuestion).parentQuestionId!,
          question.pathway.id
        );
        const matchedParentQuestionAnswer = currentAnswers?.find(
          (questionAnswer: LightweightPathwayQuestionAnswer) =>
            questionAnswer.pathway.id === parentQuestion?.pathway?.id &&
            questionAnswer.id === parentQuestion?.id &&
            isEmpty((questionAnswer as LightweightPathwayDependentQuestionAnswer).dependsOn)
        );

        if (matchedParentQuestionAnswer) {
          const matchedParentQuestionAnswerValue = castArray(matchedParentQuestionAnswer.value);
          if (
            question.dependsOn!.some((dependsOnOption) =>
              matchedParentQuestionAnswerValue.some((option) => dependsOnOption === option.id)
            )
          ) {
            visibleQuestions.push(question);
          }
        }
      }
    });

    return visibleQuestions;
  }

  function updatePathwaySummariesOnAnswersChanged(
    newQuestionAnswer: LightweightPathwayQuestionAnswer,
    actionMeta: QuestionActionMeta
  ) {
    const currentSummaries = getValues(pathwaySummary);

    const updateSummaryForPathway = (pathwayId: string) => {
      const relevantSummary = currentSummaries.find(
        (summary: LightweightPathwaySummary) => summary.pathwayId === pathwayId
      );
      switch (actionMeta.action) {
        case LightweightPathwayQuestionAction.AddFirstOption:
        case LightweightPathwayQuestionAction.AddFirst:
          relevantSummary.unansweredQuestions = relevantSummary.unansweredQuestions.filter(
            (questionId: string) => questionId !== newQuestionAnswer.id
          );
          break;
        case LightweightPathwayQuestionAction.RemoveLast:
        case LightweightPathwayQuestionAction.RemoveOption:
        case LightweightPathwayQuestionAction.RemoveLastOption:
          if (actionMeta.action !== LightweightPathwayQuestionAction.RemoveOption) {
            relevantSummary.unansweredQuestions.push(newQuestionAnswer.id);
          }
          const dependentQuestions = (
            allQuestionsArray.current.find(
              (question) => question.id === newQuestionAnswer.id
            ) as LightweightPathwayMainFlatQuestion
          )?.dependentQuestions;

          if (dependentQuestions) {
            dependentQuestions.forEach((dependentQuestion) => {
              const answeredOptions = castArray(
                newQuestionAnswer.value as LightweightPathwayQuestionOption
              ).map((option: LightweightPathwayQuestionOption) => option.id);
              if (
                !dependentQuestion.dependsOn.some((dependsOnOptionId: string) =>
                  answeredOptions.includes(dependsOnOptionId)
                ) &&
                !relevantSummary.unansweredQuestions.includes(dependentQuestion.id)
              ) {
                relevantSummary.unansweredQuestions.push(dependentQuestion.id);
              }
            });
          }
          break;
      }
    };
    const originalQuestion = allQuestionsArray.current.find(
      (question) => question.id === newQuestionAnswer.id
    )!;

    updateSummaryForPathway(originalQuestion.pathway.id);
    setValue(pathwaySummary, currentSummaries);
  }

  const handleLightweightPathwayQuestionAnswerChanged = (
    newQuestionAnswer: LightweightPathwayQuestionAnswer,
    actionMeta: QuestionActionMeta
  ) => {
    updatePathwaySummariesOnAnswersChanged(newQuestionAnswer, actionMeta);

    switch (actionMeta.type) {
      case PathwayQuestionTypes.MULTIPLE:
        handleMultipleQuestionAnswerChanged(
          newQuestionAnswer as LightweightPathwayQuestionAnswer<MultipleValue>,
          actionMeta as MultipleQuestionActionMeta
        );
        handleAlertChanged();
        break;

      case PathwayQuestionTypes.SINGLE:
        handleSingleQuestionAnswerChanged(
          newQuestionAnswer as LightweightPathwayQuestionAnswer<SingleValue>,
          actionMeta as SingleQuestionActionMeta
        );
        handleAlertChanged();
        break;

      case PathwayQuestionTypes.TEXT:
        handleTextQuestionAnswerChanged(
          newQuestionAnswer as LightweightPathwayQuestionAnswer<TextValue>,
          actionMeta as TextQuestionActionMeta
        );
        break;

      case PathwayQuestionTypes.DATE:
        handleDateQuestionAnswerChanged(
          newQuestionAnswer as LightweightPathwayQuestionAnswer<DateValue>,
          actionMeta as DateQuestionActionMeta
        );
        break;
    }

    handleAnalytics(newQuestionAnswer, actionMeta);
  };

  const handleSingleQuestionAnswerChanged = (
    newQuestionAnswer: LightweightPathwayQuestionAnswer<SingleValue>,
    actionMeta: SingleQuestionActionMeta
  ) => {
    const currentLightweightPathwaysAnswers = getValues(answers) || [];

    if (actionMeta.action === LightweightPathwayQuestionAction.RemoveLastOption) {
      //remove the question and all dependent questions
      let removedDependentQuestionIds: string[] = [];
      (
        newQuestionAnswer as LightweightPathwayMainQuestionAnswer<SingleValue>
      ).dependentQuestions?.forEach((question) => removedDependentQuestionIds.push(question.id));

      const newCurrentAnswers = currentLightweightPathwaysAnswers.filter(
        (currentQuestionAnswer: LightweightPathwayQuestionAnswer) => {
          if (
            removedDependentQuestionIds.includes(currentQuestionAnswer.id) &&
            (currentQuestionAnswer as LightweightPathwayDependentQuestionAnswer<MultipleValue>)
              ?.parentQuestionId !== newQuestionAnswer.id
          ) {
            return true;
          }

          return (
            currentQuestionAnswer.id !== newQuestionAnswer.id &&
            !removedDependentQuestionIds.includes(currentQuestionAnswer.id)
          );
        }
      );

      setValue(answers, newCurrentAnswers);
    }

    if (actionMeta.action === LightweightPathwayQuestionAction.AddFirstOption) {
      setValue(answers, [...currentLightweightPathwaysAnswers, newQuestionAnswer]);
    }

    if (actionMeta.action === LightweightPathwayQuestionAction.ChangeOption) {
      const currentAnswerIndex = currentLightweightPathwaysAnswers.findIndex(
        (answer: LightweightPathwayQuestionAnswer) => answer.id === newQuestionAnswer.id
      );
      setValue(`${answers}.${currentAnswerIndex}`, newQuestionAnswer);
    }
  };

  const handleMultipleQuestionAnswerChanged = (
    newQuestionAnswer: LightweightPathwayQuestionAnswer<MultipleValue>,
    actionMeta: MultipleQuestionActionMeta
  ) => {
    const currentLightweightPathwaysAnswers = getValues(answers) || [];
    if (actionMeta.action === LightweightPathwayQuestionAction.RemoveLastOption) {
      //remove the question and all dependent questions
      let removedDependentQuestionIds: string[] = [];
      (
        newQuestionAnswer as LightweightPathwayMainQuestionAnswer<MultipleValue>
      ).dependentQuestions?.forEach((question) => removedDependentQuestionIds.push(question.id));

      const newCurrentAnswers = currentLightweightPathwaysAnswers.filter(
        (currentQuestionAnswer: LightweightPathwayQuestionAnswer) => {
          if (
            removedDependentQuestionIds.includes(currentQuestionAnswer.id) &&
            (currentQuestionAnswer as LightweightPathwayDependentQuestionAnswer<MultipleValue>)
              ?.parentQuestionId !== newQuestionAnswer.id
          ) {
            return true;
          }

          return (
            currentQuestionAnswer.id !== newQuestionAnswer.id &&
            !removedDependentQuestionIds.includes(currentQuestionAnswer.id)
          );
        }
      );
      setValue(answers, newCurrentAnswers);
    }

    if (actionMeta.action === LightweightPathwayQuestionAction.AddFirstOption) {
      setValue(answers, [...currentLightweightPathwaysAnswers, newQuestionAnswer]);
    }

    if (
      actionMeta.action === LightweightPathwayQuestionAction.RemoveOption ||
      actionMeta.action === LightweightPathwayQuestionAction.AddNewOption
    ) {
      const currentAnswerIndex = currentLightweightPathwaysAnswers.findIndex(
        (answer: LightweightPathwayQuestionAnswer) =>
          answer.id === newQuestionAnswer.id && answer.pathway.id === newQuestionAnswer.pathway.id
      );

      setValue(`${answers}.${currentAnswerIndex}`, newQuestionAnswer);
    }
  };

  const handleTextQuestionAnswerChanged = (
    newQuestionAnswer: LightweightPathwayQuestionAnswer<TextValue>,
    actionMeta: TextQuestionActionMeta
  ) => {
    const currentLightweightPathwaysAnswers = getValues(answers) || [];

    if (actionMeta.action === LightweightPathwayQuestionAction.AddFirst) {
      setValue(answers, [...currentLightweightPathwaysAnswers, newQuestionAnswer]);
    }

    if (actionMeta.action === LightweightPathwayQuestionAction.Update) {
      const currentAnswerIndex = currentLightweightPathwaysAnswers.findIndex(
        (answer: LightweightPathwayQuestionAnswer) => answer.id === newQuestionAnswer.id
      );
      setValue(`${answers}.${currentAnswerIndex}`, newQuestionAnswer);
    }

    if (actionMeta.action === LightweightPathwayQuestionAction.RemoveLast) {
      setValue(
        answers,
        currentLightweightPathwaysAnswers.filter(
          (answer: LightweightPathwayQuestionAnswer) => answer.id !== newQuestionAnswer.id
        )
      );
    }
  };

  const handleDateQuestionAnswerChanged = (
    newQuestionAnswer: LightweightPathwayQuestionAnswer<DateValue>,
    actionMeta: DateQuestionActionMeta
  ) => {
    const currentLightweightPathwaysAnswers = getValues(answers) || [];
    if (actionMeta.action === LightweightPathwayQuestionAction.AddFirst) {
      setValue(answers, [...currentLightweightPathwaysAnswers, newQuestionAnswer]);
    }

    if (actionMeta.action === LightweightPathwayQuestionAction.Update) {
      const currentAnswerIndex = currentLightweightPathwaysAnswers.findIndex(
        (answer: LightweightPathwayQuestionAnswer) => answer.id === newQuestionAnswer.id
      );
      setValue(`${answers}.${currentAnswerIndex}`, newQuestionAnswer);
    }

    if (actionMeta.action === LightweightPathwayQuestionAction.RemoveLast) {
      setValue(
        answers,
        currentLightweightPathwaysAnswers.filter(
          (answer: LightweightPathwayQuestionAnswer) => answer.id !== newQuestionAnswer.id
        )
      );
    }
  };

  const handleAlertChanged = () => {
    const currentAnswers = getValues(answers);
    let newUrgency = defaultSymptomUrgencyValue;

    currentAnswers.forEach(
      (questionAnswer: LightweightPathwayQuestionAnswer<MultipleValue | SingleValue>) => {
        if (questionAnswer.type === PathwayQuestionTypes.SINGLE) {
          const questionAlert =
            (questionAnswer.value as SingleValue)?.outcome || PathwayOptionOutcome.NONE;

          if (questionAlert > urgencyToQuestionOutcomeMap[newUrgency.value]!) {
            newUrgency = questionOutcomeToUrgencyMap[questionAlert]!;
          }
        }

        if (questionAnswer.type === PathwayQuestionTypes.MULTIPLE) {
          (questionAnswer.value as MultipleValue).forEach((option) => {
            const questionAlert = option.outcome || PathwayOptionOutcome.NONE;

            if (questionAlert > urgencyToQuestionOutcomeMap[newUrgency.value]!) {
              newUrgency = questionOutcomeToUrgencyMap[questionAlert]!;
            }
          });
        }
      }
    );

    if (newUrgency.value !== getValues(urgency)?.value) {
      setValue(urgency, newUrgency);
    }
  };

  const handleAnalytics = (
    newQuestionAnswer: LightweightPathwayQuestionAnswer,
    actionMeta: QuestionActionMeta
  ) => {
    if (newQuestionAnswer.type === PathwayQuestionTypes.TEXT) {
      //we handle "open text" differently
      return;
    }

    const alertLevel = getLightweightQuestionAlert(newQuestionAnswer);
    let action: PathwayAnswerEventAction = AnalyticEventAction.Add;

    if (lightweightPathwayQuestionsRemoveActions.includes(actionMeta.action)) {
      action = AnalyticEventAction.Remove;
    }

    if (actionMeta.action === LightweightPathwayQuestionAction.Update) {
      action = AnalyticEventAction.Update;
    }

    trackPathwayAnswerAnalyticsEvent({
      action: action,
      type: pathwayQuestionTypeToAnalyticsTypeMap[newQuestionAnswer.type],
      alert_level: !alertLevel ? NO_WARNING : alertLevel,
      virtual_page: context,
      tab: 'lightweight questions',
      value: newQuestionAnswer.title
    });
  };

  const handleTextQuestionBlur = (
    _: FocusEvent<HTMLInputElement>,
    currentValue: string,
    valueAfterFocus: string,
    question:
      | LightweightPathwayQuestionServerStructure
      | LightweightPathwayDependentQuestionServerStructure
  ) => {
    const action = getEventActionOnTextChange(valueAfterFocus, currentValue);

    trackPathwayAnswerAnalyticsEvent({
      action: action,
      type: 'open text',
      alert_level: NO_WARNING,
      virtual_page: context,
      tab: 'lightweight questions',
      value: question.title
    });
  };

  return {
    lightweightPathways,
    handleAnswerChanged: handleLightweightPathwayQuestionAnswerChanged,
    isLoading,
    handleTextQuestionBlur
  };
};
