// @ts-strict-ignore
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import { useHistory, useLocation } from 'react-router-dom';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { getDefaultDatesRequestParams } from 'utils/serverFiltersUtils';

import { useClusterTickets } from 'utils/TicketClusteringUtils';

import { FEATURES } from 'constants/features';

import Ticket, { TicketStatus } from 'models/Ticket';

import { DefaultTasksRequestParams } from 'views/Filters/filters.types';

import { PatientPageFilters } from 'views/Filters/PatientPageFilters';

import {
  HIGHLIGHT_TICKET_QUERY_PARAM,
  PATIENT_TABS,
  TAB_QUERY_PARAM
} from 'views/Patient/PatientMain/PatientMainView.constants';

import FixedLoader from 'components/Loaders/FixedLoader';
import { ItemDeletedPopup } from 'components/NotificationCenter/ItemDeletedPopup';
import { usePatientContext } from 'components/Patient/PatientContextProvider';
import TicketsOverview from 'components/Ticket/TicketsContainers/TicketsOverview';

const PATIENT_FILTERS_LOCAL_STORAGE_KEY = 'PATIENT_OPEN_TASKS_FILTERS';

export const usePatientTicketOverview = () => {
  const { ticketFiltersStore, ticketsStore, tasksStore } = useStores();
  const patient = usePatientContext();

  const selectedPatientOpenTickets = ticketsStore.getPatientOpenTickets(patient);
  const ticketsCluster = useClusterTickets({
    tickets: selectedPatientOpenTickets,
    tasks: tasksStore.tasks
  });

  const cluster = {
    ticketsCluster,
    overduePatients:
      patient?.isSymptomReportOverdue || patient?.isOralReportOverdue ? [patient] : []
  };

  const filteredCluster = ticketFiltersStore.applyFiltersOnCluster({
    ticketsCluster: cluster.ticketsCluster,
    overduePatients: cluster.overduePatients
  });

  return {
    patient,
    ...filteredCluster
  };
};

const PatientTicketOverview: FC = () => {
  const { ticketFiltersStore, tasksStore, settingsStore, ticketsStore, uiStore } = useStores();
  const { institutionSettings } = settingsStore;
  const { singlePatientRetrievalInterval } = institutionSettings;
  const { ticketsCluster, overduePatients, patient } = usePatientTicketOverview();
  const isLoading =
    ticketFiltersStore.updatingFilters || !tasksStore.dataReady || tasksStore.updatingTasks;
  const defaultTicketsQuery = useMemo(() => ({ patientId: patient.id }), [patient]);
  const hasPatientFiltersFeature = settingsStore.hasFeature(FEATURES.PATIENT_PAGE_FILTERS);

  const defaultTasksQuery = useMemo<DefaultTasksRequestParams>(
    () => ({
      patientId: patient.id,
      taskStatuses: Ticket.TICKET_ACTIVE_STATUSES,
      includeAssignToMe: true,
      fromDate: getDefaultDatesRequestParams().fromDate
    }),
    [patient]
  );
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const highlightId = Number(params.get(HIGHLIGHT_TICKET_QUERY_PARAM));
  const [ticketRefs, setTicketRefs] = useState<any>({});
  const ticketRefsCallback = useCallback((node: HTMLElement, ticketId: number) => {
    setTicketRefs((prev: Record<number, HTMLElement>) => ({ ...prev, [ticketId]: node }));
  }, []);
  const [isDeletedPopupOpen, setDeletedPopupOpen] = useState(false);

  const highlightElement = ticketRefs[highlightId];

  const handleTicketNotFound = useCallback(async () => {
    const statusResponse = await ticketsStore.ticketCheckStatus(highlightId);
    switch (statusResponse.status) {
      case TicketStatus.OPEN:
        // Should only happen if filters feature flag is on, in which case should show a popup (get design from tom)
        break;
      case TicketStatus.RESOLVED:
        params.set(TAB_QUERY_PARAM, PATIENT_TABS.RESOLVED_TICKETS);
        history.replace({ search: params.toString() });
        break;
      case TicketStatus.DELETED:
        setDeletedPopupOpen(true);
    }
  }, [highlightId, ticketsStore, history, params]);

  useEffect(
    function scrollToTicket() {
      if (ticketsStore.patientTicketsFetched === patient.id && !uiStore.isIntro) {
        if (highlightElement) {
          document.getElementById('app')!.scrollBy({
            top: highlightElement.getBoundingClientRect().top - window.innerHeight / 3,
            behavior: 'smooth'
          });
        } else if (highlightId && !ticketsStore.ticketsMap.get(highlightId)) {
          handleTicketNotFound();
        }
      }
    },
    [
      highlightId,
      patient.id,
      uiStore.isIntro,
      highlightElement,
      ticketsStore.patientTicketsFetched,
      ticketsStore.ticketsMap,
      handleTicketNotFound
    ]
  );
  useMount(function initializeStaticTicketAndTaskCalls() {
    // if patient-page-filters feature flag is off (default) we want to
    // initialize this tab with default queries and start polling
    // otherwise the filter component is responsible for everything
    if (!hasPatientFiltersFeature) {
      ticketFiltersStore.setDefaultTicketQuery(defaultTicketsQuery);
      ticketFiltersStore.setDefaultTaskQuery(defaultTasksQuery);
      ticketsStore.fetchTicketsForSinglePatientPeriodically(patient.id);
      tasksStore.fetchTasksOrDelta(singlePatientRetrievalInterval);
    }
  });

  useUpdateEffect(
    function onPatientChange() {
      if (!hasPatientFiltersFeature) {
        ticketsStore.stopSinglePatientRefresh();
        tasksStore.resetStore();
        ticketFiltersStore.resetFiltersAndDefaultQueryValues();
        ticketFiltersStore.setDefaultTicketQuery(defaultTicketsQuery);
        ticketFiltersStore.setDefaultTaskQuery(defaultTasksQuery);
        ticketsStore.fetchTicketsForSinglePatientPeriodically(patient.id);
        tasksStore.fetchTasksOrDelta(singlePatientRetrievalInterval);
      }
    },
    [patient.id]
  );

  useUnmount(function reset() {
    // see useMount comment
    if (!hasPatientFiltersFeature) {
      ticketsStore.stopSinglePatientRefresh();
      tasksStore.resetStore();
      ticketFiltersStore.resetFiltersAndDefaultQueryValues();
    }
  });

  return (
    <>
      {hasPatientFiltersFeature && (
        <PatientPageFilters
          className="ticket-filters"
          localStorageKey={PATIENT_FILTERS_LOCAL_STORAGE_KEY}
          defaultTasksQuery={defaultTasksQuery}
          defaultTicketsQuery={defaultTicketsQuery}
        />
      )}
      {isLoading && <FixedLoader />}
      {isDeletedPopupOpen && (
        <ItemDeletedPopup isOpen={isDeletedPopupOpen} setIsPopupOpen={setDeletedPopupOpen} />
      )}
      <TicketsOverview
        ticketsCluster={ticketsCluster}
        overduePatients={overduePatients}
        showTasks={ticketFiltersStore.showTasks}
        emptyState={null}
        ticketRefsCallback={ticketRefsCallback}
      />
    </>
  );
};

export default observer(PatientTicketOverview);
