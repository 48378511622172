// @ts-strict-ignore
import { FunctionComponent, useMemo } from 'react';

import { observer } from 'mobx-react';
import moment from 'moment';

import { formatElapsedTime } from 'utils/DateUtils';
import { pluralize } from 'utils/StringUtils';

import Patient from 'models/Patient';

import OverdueActions from 'components/Ticket/TicketRow/OverdueActions';
import { usePatientProviderLocation } from 'components/Ticket/TicketRow/shared';
import TicketRenderer from 'components/Ticket/TicketRow/TicketRenderer';

import TicketRowIcon, { TicketRowIconType } from './TicketRowIcon';

interface Props {
  patient: Patient;
  className?: string;
  toggleSmsModal: (shouldOpenSmsBlockedPopup: boolean, patient: Patient) => void;
  withPatientLink: boolean;
  ticketIndex: number;
  ticketSectionCurrentPage?: number;
}

const MINUTES_TILL_LAST_OVERDUE_NOTIFICATION = 30;

const OverduePatientReportRow: FunctionComponent<Props> = ({
  patient,
  className,
  toggleSmsModal,
  withPatientLink,
  ticketIndex,
  ticketSectionCurrentPage = 0
}) => {
  const providerLocationsText = usePatientProviderLocation(patient);
  const overdueTimeText = useMemo(() => {
    let duration = '';

    if (patient.hasDrugSpecificHourlyProtocol) {
      // TODO: Move logic to server. The server returns the start time of the last SMS reminder sent to the patient, but the overdue time starts 30 minutes before that.
      const actualStartTime = moment(patient.protocolOverdueStartTime)
        .subtract(MINUTES_TILL_LAST_OVERDUE_NOTIFICATION, 'minutes')
        .toDate();
      duration = formatElapsedTime(actualStartTime);
    } else {
      const days = moment.utc().diff(moment(patient.protocolOverdueStartTime), 'days');
      duration = `${days} ${pluralize('Day', days)}`;
    }

    let protocolText = 'Symptom Assessment';

    if (patient.drugSpecific) {
      protocolText = patient.drugSpecific.name;
    } else if (patient.hasOralProtocol) {
      protocolText = `Oral Oncolytics Assessment (${patient.regimenTitle})`;
    }

    return `${duration} Overdue: ${protocolText}`;
  }, [
    patient.hasOralProtocol,
    patient.hasDrugSpecificHourlyProtocol,
    patient.protocolOverdueStartTime,
    patient.regimenTitle,
    patient.drugSpecific
  ]);

  const icon = useMemo(() => {
    if (patient.hasDrugSpecificHourlyProtocol) {
      return <TicketRowIcon type={TicketRowIconType.SYMPTOM_REPORT_IMMEDIATE_ATTENTION} />;
    }
    return null;
  }, [patient.hasDrugSpecificHourlyProtocol]);

  const summary = (
    <div>
      <span className="title-text bold">{overdueTimeText}</span>
      {providerLocationsText && <span className="subtitle-text"> — {providerLocationsText}</span>}
    </div>
  );

  return (
    <TicketRenderer
      dashedBorder
      patient={patient}
      icon={icon}
      classes={{
        container: className
      }}
      actions={
        <OverdueActions
          patient={patient}
          toggleSmsModal={toggleSmsModal}
          ticketIndex={ticketIndex}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
        />
      }
      summary={summary}
      withPatientLink={withPatientLink}
      createdByText={
        <>{patient.mrn && <div className="bold patient-mrn details-row">MRN: {patient.mrn}</div>}</>
      }
    />
  );
};

export default observer(OverduePatientReportRow);
