import { Box, css, styled } from '@mui/material';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    height: 100%;
    width: 90%;
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-top: ${theme.spacing(52)};
  `
);
