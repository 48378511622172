import { FC } from 'react';

import Ticket from 'models/Ticket';

import { usePatientSmsStatus } from 'hooks/usePatientSmsStatus';

import { Text } from 'components/UIkit/atoms/Text';
import { TextProps } from 'components/UIkit/atoms/Text/Text';

interface Props {
  ticket?: Ticket;
  disabled?: boolean;
}

export const SendMessageOption: FC<Props> = ({ ticket, disabled }) => {
  const { canSendSms, messagingConsent, hasMobilePhone } = usePatientSmsStatus(ticket);

  const colorProps: Pick<TextProps, 'color'> | object = disabled ? { color: 'disabled' } : {};

  return (
    <>
      <Text variant="form-text" display="block" {...colorProps}>
        Send Message
      </Text>

      {!messagingConsent && canSendSms && (
        <Text component="div" variant="body2" {...colorProps}>
          Consent Not Provided
        </Text>
      )}

      {!canSendSms && (
        <Text component="div" variant="body2" {...colorProps}>
          {!hasMobilePhone ? 'Cannot SMS Landline' : 'SMS Blocked - Click for Info'}
        </Text>
      )}
    </>
  );
};
