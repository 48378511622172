// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { FeatureIntroCodes } from 'models/Doctor';

import { DurationInterval } from 'models/DurationInterval';

import { useToggle } from 'hooks/useToggle';

import { IntervalContextProvider } from 'components/IntervalsEditor/IntervalsContext';

import IntervalsEditorModal, {
  IntervalsEditorResult
} from 'components/IntervalsEditor/IntervalsEditorModal';

import CareTimerButton from './CareTimerButton';

import './CareTimer.scss';

const CareTimer: FC = () => {
  const { careTimerStore, userStore } = useStores();
  const editor = useToggle(false);

  const onSave = (result: IntervalsEditorResult) => {
    const { careTimerMaps } = careTimerStore;

    // remove unchanged entries
    result.intervals = result.intervals.filter((interval) => {
      const initialInterval = careTimerMaps.careIntervals.get(interval.uniqueIdentifier);
      return !initialInterval || !interval.equals(initialInterval);
    });
    result.quickAdditions = result.quickAdditions.filter((quickAddition) => {
      const initialQuickAdditions = careTimerMaps.quickAdditions.get(
        quickAddition.uniqueIdentifier
      );
      return !initialQuickAdditions || !quickAddition.equals(initialQuickAdditions);
    });

    careTimerStore.updateCareTimeData(result);
    editor.toggle();
  };

  const validateInterval = async (interval: DurationInterval) => {
    if (interval.startDate && interval.endDate) {
      return await careTimerStore.validateInterval(interval);
    }
    return null;
  };

  const openIntervalsEditorModal = async () => {
    await careTimerStore.getCareTimeData();
    editor.toggle();

    if (!userStore.currentDoctor.hasFeature(FeatureIntroCodes.EditCareIntervals)) {
      userStore.updateIntroFinished(FeatureIntroCodes.EditCareIntervals);
    }
  };

  return (
    <div className="care-timer-container">
      <IntervalContextProvider
        initialIntervals={careTimerStore.careTimerData.careIntervals}
        initialQuickAdditions={careTimerStore.careTimerData.quickAdditions}
        externalValidationAction={validateInterval}
      >
        <IntervalsEditorModal
          isOpen={editor.isOpen}
          isQuickAddition
          title="Care Timer Intervals"
          subtitle="Intervals are editable one day backward, until the end of the calendar month. "
          onSave={onSave}
          onClose={editor.toggle}
        />
      </IntervalContextProvider>
      <CareTimerButton onEdit={openIntervalsEditorModal} />
    </div>
  );
};

export default observer(CareTimer);
