import { isNumber } from 'lodash/fp';

import QuestionnaireAnswer from 'models/QuestionnaireAnswer';
import { isNumericReportedCause, NumericReportedCause } from 'models/ReportedCause';

const MINIMAL_FEVER_THRESHOLD = 99;

interface UseFeverReturnType {
  isFever: boolean;
  temperatureCause: NumericReportedCause | undefined;
}

export const useIsFever = (report: QuestionnaireAnswer): UseFeverReturnType => {
  const numericCauses = report.answer?.causes?.filter((cause) => isNumericReportedCause(cause));
  const temperatureCause = numericCauses && (numericCauses[0] as NumericReportedCause);
  let isFever = false;

  if (
    temperatureCause &&
    isNumber(temperatureCause?.value) &&
    temperatureCause?.value >= MINIMAL_FEVER_THRESHOLD
  ) {
    isFever = true;
  }

  return {
    isFever,
    temperatureCause
  };
};
