import { useStores } from 'mobx/hooks/useStores';

import { showToast } from 'utils/UserMessageUtils';

export const useDeleteTicketTypeOrSubType = (ticketSubTypeId: number, onAction: () => void) => {
  const { ticketTypesStore } = useStores();
  const ticketSubType = ticketTypesStore.getOperatorTicketTypeById(ticketSubTypeId);

  const deleteTicketSubType = async () => {
    try {
      await ticketTypesStore.deleteTicketSubType(ticketSubType?.id);
      showToast({ message: `Ticket Sub-Type "${ticketSubType?.name}" Deleted` });
    } finally {
      onAction();
    }
  };

  return { deleteTicketSubType, ticketSubType };
};
