import { FC, ReactNode } from 'react';

import { Link as MuiLink } from '@mui/material';

import { StyledLink } from 'components/UIkit/atoms/Link/StyledLink';

import { getTypographyVariant } from 'components/UIkit/atoms/Link/utils';

interface Props {
  children?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary';
  href: string;
}

export const ExternalLink: FC<Props> = ({
  href,
  size = 'medium',
  variant = 'primary',
  children
}) => (
  <StyledLink variant={getTypographyVariant(size)} color={variant}>
    <MuiLink href={href} underline="hover" target="_blank">
      {children}
    </MuiLink>
  </StyledLink>
);
