import debounce from 'debounce-promise';

import { useStores } from 'mobx/hooks/useStores';

import { CliniciansFetcher } from 'fetchers/CliniciansFetcher';

import { getClinicianFullName, getNameWithCredentials } from 'utils/ClinicianCredentialUtils';

import { FEATURES } from 'constants/features';

import { ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY } from 'components/UIkit/atoms/Dropdown/Select.shared';

import { SuggestionDataItem } from 'components/UIkit/atoms/Input/ReactMentions';

export function useAsyncMentionOptions(): (
  query: string,
  callback: (data: SuggestionDataItem[]) => void
) => Promise<void> {
  const { constantsStore, settingsStore } = useStores();

  return debounce(async (query: string, callback: (data: SuggestionDataItem[]) => void) => {
    if (!settingsStore.hasFeature(FEATURES.NOTIFICATIONS)) {
      callback([]);
      return;
    }

    const cliniciansResponse = await CliniciansFetcher.searchClinicians({
      searchTerm: query
    });
    const options = cliniciansResponse.map((searchedClinician) => {
      const { credentialId, id } = searchedClinician;
      const credentialText = constantsStore.getClinicianCredentialById(credentialId)?.text;
      const fullName = getClinicianFullName(searchedClinician);
      const label = getNameWithCredentials(fullName, credentialText || null);

      return { id, display: label };
    });

    callback(options);
  }, ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY);
}
