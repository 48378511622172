import { HeaderGroup } from 'react-table';

import { UrlSortParams } from 'utils/urlUtils';

import {
  CareManagementTabNames,
  CareManagementTabs,
  cmTabsToNames
} from 'views/Pages/CareManagement/CareManagementPage';
import { cmAccessorsToNames } from 'views/Pages/CareManagement/CareManagementPage.utils';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

export type SortByAnalyticsAction =
  | AnalyticEventAction.Descending
  | AnalyticEventAction.Ascending
  | AnalyticEventAction.NoSort;

export type SortByAnalyticsValue =
  | 'Patient'
  | 'Provider'
  | 'Primary Ins.'
  | '2nd Ins.'
  | 'Diagnoses'
  | 'Visits'
  | 'Next Visit'
  | 'Monthly Care Time'
  | 'CM Status'
  | 'Care Time'
  | 'Episode'
  | 'Name'
  | 'MRN'
  | 'DOB'
  | 'Sex'
  | 'Invited'
  | 'Providers';

interface Properties {
  action: SortByAnalyticsAction;
  value: SortByAnalyticsValue;
  tab?: CareManagementTabNames;
}

export const trackSortByAnalyticsEvent = (
  column: HeaderGroup<any>,
  sortParams: UrlSortParams,
  { value, tab }: Omit<Properties, 'action'>
) => {
  let action: SortByAnalyticsAction = AnalyticEventAction.Descending;

  if (!sortParams) {
    action = AnalyticEventAction.NoSort;
  }

  if (sortParams?.sortAsc) {
    action = AnalyticEventAction.Ascending;
  }

  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.SortBy,
    properties: {
      action,
      value,
      tab
    }
  });
};

export const trackCmSortByAnalyticsEvent = (
  column: HeaderGroup<any>,
  sortParams: UrlSortParams,
  tab: CareManagementTabs
) => {
  trackSortByAnalyticsEvent(column, sortParams, {
    value: cmAccessorsToNames[column.id],
    tab: cmTabsToNames[tab]
  });
};

const activationAccessorsToNames: { [key: string]: SortByAnalyticsValue } = {
  name: 'Patient',
  mrn: 'MRN',
  dateOfBirth: 'DOB',
  sex: 'Sex',
  invited: 'Invited',
  provider: 'Providers'
};

export const trackActivationSortByAnalyticsEvent = (
  column: HeaderGroup<any>,
  sortParams: UrlSortParams
) => {
  trackSortByAnalyticsEvent(column, sortParams, {
    value: activationAccessorsToNames[column.id]
  });
};
