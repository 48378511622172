import { ErrorName } from './Error.utils';
import { ErrorWithUi } from './ErrorWithUi';

export class UnauthorizedError extends ErrorWithUi {
  readonly name = ErrorName.Unauthorized;
  constructor(message: string) {
    super(message);
    Object.freeze(this); // prevent modification for UnauthorizedError
  }
}
