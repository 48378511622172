// @ts-strict-ignore
import { MutableRefObject, ReactElement } from 'react';

import { Tooltip, Placement } from 'components/Tooltip';
import { DateTimePickerTooltipContent } from 'components/Tooltip/DatePickerTooltipContent';
import { TooltipController, TooltipTrigger } from 'components/Tooltip/Tooltip.types';

import './DatepickerTooltip.scss';

interface DatePickerTooltipProps {
  onSelect: (date: Date) => void;
  label?: ReactElement;
  date?: Date;
  min?: Date;
  max?: Date;
  placement?: Placement;
  controller?: TooltipController;
  disabled?: boolean;
  reference?: MutableRefObject<Element>;
}
export const DatePickerTooltip = ({
  onSelect,
  label,
  date,
  min,
  max,
  placement = 'auto',
  controller,
  disabled,
  reference
}: DatePickerTooltipProps) => (
  <Tooltip
    placement={placement}
    label={label}
    controller={controller}
    disabled={disabled}
    reference={reference}
    trigger={TooltipTrigger.CLICK}
  >
    <DateTimePickerTooltipContent onSelect={onSelect} min={min} max={max} date={date} />
  </Tooltip>
);
