import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TicketTypeTooltipRow } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeLabelTooltip/TicketTypeTooltipRow';

import Icon from 'components/Icons/Icon';
import { Tooltip } from 'components/Tooltip';
import { Text } from 'components/UIkit/atoms/Text';

export const TicketTypeLabelTooltip = () => (
  <Grid container alignItems="center" justifyContent="flex-end">
    <Text variant="body1" color="disabled" mr={4}>
      Enable Ticket Types
    </Text>
    <Tooltip label={<Icon.Info />} maxWidth={342} placement="top">
      <StyledGrid>
        <TicketTypeTooltipRow
          title="Category Toggles"
          description="enable or disable entire
                    Ticket Categories."
        />

        <TicketTypeTooltipRow
          title="'Operators' checkbox"
          description="allows Switchboard
                    Operators to create a given Ticket Type."
        />

        <TicketTypeTooltipRow
          title="'Patients' checkbox"
          description="allows patients to create a given Ticket Type via the Canopy mobile app."
        />
      </StyledGrid>
    </Tooltip>
  </Grid>
);

const StyledGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(20)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
