import { CallResolutionResponse } from 'fetchers/CallsFetcher';
import { TicketResolutionResponse } from 'fetchers/TicketsFetcher';

export const BULK_PARTIAL_RESOLVE_TITLE = 'Bulk Resolve Issues';
export type TicketResolveResponseType = TicketResolutionResponse | CallResolutionResponse;

export const getTicketResolutionResultMessages = (
  resolveTicketsResult: TicketResolveResponseType
): { title: string; description?: string } => {
  const noErrors =
    !resolveTicketsResult.generalErrorTicketIds.length &&
    !resolveTicketsResult.pdfFailedTicketIds.length;

  if (noErrors) {
    return { title: getResolvedItemsMessage(resolveTicketsResult) };
  } else {
    const partialResolvedDescription = bulkPartialResolveDescription(resolveTicketsResult);
    return {
      title: BULK_PARTIAL_RESOLVE_TITLE,
      description: partialResolvedDescription
    };
  }
};

export const bulkPartialResolveDescription = (resolveTicketsResult: TicketResolveResponseType) => {
  const { resolvedTicketIds, generalErrorTicketIds, pdfFailedTicketIds } = resolveTicketsResult;

  const message = [];
  if (resolvedTicketIds.length) {
    message.push(getResolvedItemsMessage(resolveTicketsResult));
  }
  if (generalErrorTicketIds.length) {
    message.push(`${generalErrorTicketIds.length} Ticket(s) - General Error`);
  }
  if (pdfFailedTicketIds.length) {
    message.push(`${pdfFailedTicketIds.length} Ticket(s) PDF Failed to Generate for EMR`);
  }

  return message.join(' • ');
};

export const getResolvedItemsMessage = (
  resolveTicketsResult: TicketResolveResponseType
): string => {
  const { resolvedTicketIds, pdfSucceededTicketIds } = resolveTicketsResult;
  const commentMsg = resolveTicketsResult.createdCommentsCount ? 'with Comment' : '';
  const pdfSent = pdfSucceededTicketIds.length > 0;
  if (resolvedTicketIds.length) {
    return `${resolvedTicketIds.length} Item(s) Resolved ${
      pdfSent ? '& PDF Sent to EMR' : ''
    } ${commentMsg}`;
  }
  return '';
};

export const TICKET_MISSING_TYPE = (id: number) => `Missing type for ticket ${id}`;
