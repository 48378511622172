import { FC } from 'react';

import { observer } from 'mobx-react';

import { formatDate } from 'utils/DateUtils';

import { OralReportV2 } from 'models/OralReport/OralReportV2';

import { useOralReportCycle } from 'hooks/useOralReportCycle';

import LabelDatePicker from 'components/DateTimePicker/LabelDateTimePicker';
import { OralReportCycleProps } from 'components/Ticket/TicketRow/OralReport/OralReport.shared';

export const OralReportCycleV2: FC<OralReportCycleProps<OralReportV2>> = observer(
  ({ reportId, oral, patient }) => {
    const { startOfCycleDate, startOfCycle } = oral;
    const { selectedDate, handleDateSelect } = useOralReportCycle(
      patient,
      reportId,
      startOfCycleDate
    );

    if (startOfCycle?.isAnswered) {
      return <span>{formatDate(startOfCycle.value!, 'MMMM D, YYYY')}</span>;
    }

    return (
      <LabelDatePicker date={selectedDate} onSelect={handleDateSelect} placeholder="Unknown" />
    );
  }
);
