import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

const httpService = new HttpService('Analytics');

export class AnalyticsFetcher {
  static async fetchThoughtSpotToken() {
    return await httpService.get({ url: API_URLS.THOUGHT_SPOT_INTEGRATION });
  }
}

export default AnalyticsFetcher;
