// @ts-strict-ignore
import { makeAutoObservable, observable, runInAction } from 'mobx';

import { RootStore } from 'mobx/stores';

import CareManagementFetcher from 'fetchers/CareManagementFetcher';

import { CmPatient } from 'models/CmPatient';

export class CareManagementPatientStore {
  rootStore: RootStore;

  @observable
  patientCmSummary: CmPatient;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  async fetchPatientCmSummary(patientId: number) {
    const cmPatient = await CareManagementFetcher.fetchCareManagementPatientData(patientId);
    runInAction(() => {
      this.patientCmSummary = cmPatient;
    });
  }
}

export default CareManagementPatientStore;
