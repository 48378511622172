import { useMemo } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { NO_CREDENTIALS_ID } from 'constants/clinicanCredentials.const';

import { SelectOption } from 'models/SelectOption';

import { sortOptionsByLocale } from 'views/Widgets/StyledSelect';

export function useClinicianCredentialsOptions(): SelectOption<number>[] {
  const { constantsStore } = useStores();
  const { items } = constantsStore.clinicianCredential;

  return useMemo(() => {
    const options = items
      .map((credential) => ({
        value: credential.id,
        label: credential.text
      }))
      .sort(sortOptionsByLocale);

    options.unshift({
      value: NO_CREDENTIALS_ID,
      label: 'None'
    });

    return options;
  }, [items]);
}
