// @ts-strict-ignore
import { FC, useState } from 'react';

import classNames from 'classnames';
import { DateTimePicker } from 'react-widgets';

import { dayAndHourTimeFormat } from 'utils/DateUtils';

import './DateTimePicker.scss';

interface Props {
  date: Date;
  onChange: (value: Date) => void;
  openUp?: boolean;
}

// see: // https://jquense.github.io/react-widgets/api/DateTimePicker
const ComboDateTimePicker: FC<Props> = ({ date, onChange, openUp }) => {
  const [open, setIsOpen] = useState<DateTimePicker.Open>(false);
  const borderPrimary = date === null;
  const containerClasses = classNames('combo-picker-container w-100', {
    'combo-border-primary': borderPrimary
  });

  return (
    <DateTimePicker
      onToggle={(openType) => {
        setIsOpen(openType);
      }}
      dropUp={openUp}
      onSelect={() => {
        setIsOpen(false);
      }}
      open={open}
      inputProps={{
        readOnly: true,
        onClick: (e: Event) => {
          e.preventDefault();
          if (!open) {
            setIsOpen('time');
          }
        }
      }}
      onChange={onChange}
      step={1}
      value={date}
      format={dayAndHourTimeFormat}
      containerClassName={containerClasses}
    />
  );
};

export default ComboDateTimePicker;
