// @ts-strict-ignore
import { transformErrorUiProps } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

export interface CreateUpdateTaskRoleParams {
  name: string;
}

const httpService = new HttpService('task role', true);

export class TaskRolesFetcher {
  static async fetchTaskRoles() {
    return await httpService.get({ url: API_URLS.TASKS_ROLE });
  }

  static async createTaskRole(data: CreateUpdateTaskRoleParams) {
    return await httpService.post({ url: API_URLS.TASKS_ROLE, data });
  }

  static async updateTaskRole(id: number, data: CreateUpdateTaskRoleParams) {
    return await httpService.put({ url: API_URLS.UPDATE_TASK_ROLE(id), data });
  }

  static async deleteTaskRole(id: number) {
    return await httpService.delete({
      url: API_URLS.DELETE_TASK_ROLE(id),
      transformError: transformErrorUiProps(
        null,
        'Make sure that it is not being used in active tasks'
      )
    });
  }
}
