import { FC } from 'react';

import { Box } from '@mui/material';

import { css, styled } from '@mui/material/styles';

import { pluralize } from 'utils/StringUtils';

import Icon from 'components/Icons/Icon';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  success: number;
  fail: number;
  incompatible: number;
}

export const CmBulkInviteToastContent: FC<Props> = ({ success, fail, incompatible }) => (
  <>
    {success > 0 && (
      <StyledListItemContainer display="flex" alignItems="baseline">
        <StyledSuccessIcon>
          <Icon.CheckMark />
        </StyledSuccessIcon>

        <Text variant="body2" ml={8}>
          {success} {pluralize('invitation', success)} sent{' '}
          <Text variant="body3">successfully</Text>
        </Text>
      </StyledListItemContainer>
    )}

    {fail > 0 && (
      <StyledListItemContainer display="flex" alignItems="baseline">
        <StyledFailedIcon>
          <Icon.XBase />
        </StyledFailedIcon>

        <Text variant="body2" ml={8}>
          {fail} {pluralize('invitation', fail)} <Text variant="body3">failed</Text>
        </Text>
      </StyledListItemContainer>
    )}

    {incompatible > 0 && (
      <StyledListItemContainer display="flex" alignItems="baseline">
        <StyledIncompatibleIcon>
          <Icon.Unchecked />
        </StyledIncompatibleIcon>

        <Text variant="body2" ml={8}>
          {incompatible} {pluralize('invitation', incompatible)} couldn’t be sent due to{' '}
          <Text variant="body3">incompatible Enrollment Status</Text>
        </Text>
      </StyledListItemContainer>
    )}
  </>
);

const StyledListItemContainer = styled(Box)(
  ({ theme }) => css`
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(8)};
    }
  `
);

const StyledSuccessIcon = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
  `
);

const StyledFailedIcon = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.error.dark};
  `
);

const StyledIncompatibleIcon = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
  `
);
