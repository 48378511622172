// @ts-strict-ignore
import { AnalyticsService } from 'analytics';

import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { CARE_MANAGEMENT_BULK_INVITE_LOCAL_STORAGE_KEY, RootStore } from 'mobx/stores';

import { CliniciansFetcher } from 'fetchers/CliniciansFetcher';
import { UserFetcher } from 'fetchers/UserFetcher';

import { PendoService } from 'services/PendoService';

import { PermissionService } from 'services/PermissionService';

import { setSentryUser } from 'services/sentryService';

import { UserType } from 'utils/AccountUtils';

import { FEATURES } from 'constants/features';

import Doctor, { FeatureIntroCodes } from 'models/Doctor';
import { PhoneNumberCarrierDetails } from 'models/PhoneNumberDetails';

export class UserStore {
  @observable
  currentDoctor: Doctor;

  phoneTypeRegistry = new Map();

  @observable
  isAuthenticated = false;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @computed
  get isAdmin() {
    return this.currentDoctor?.isAdmin;
  }

  @computed
  get isManager() {
    return PermissionService.isManager(this.currentDoctor);
  }

  @computed
  get currentClinicianId() {
    return this.currentDoctor?.id;
  }

  @action
  logout = async () => {
    const { careTimerStore } = this.rootStore.stores;
    if (careTimerStore.currentSession?.length) {
      await careTimerStore.endSession();
    }
    await UserFetcher.logout();
    AnalyticsService.reset();
  };

  async updateDoctorAccount(
    doctorId: number,
    firstName: string,
    lastName: string,
    credentialId: number,
    email: string,
    phone: string,
    role: UserType,
    teams: number[]
  ) {
    const details = { doctorId, firstName, lastName, credentialId, email, phone, role, teams };
    await CliniciansFetcher.updatePersonalDetails(details);
  }

  analyticsSetup(doctor: Doctor) {
    const isTestInstitution = this.rootStore.stores.departmentsStore.root?.isAutomation;

    if (isTestInstitution) {
      AnalyticsService.optOut();
    }

    AnalyticsService.identify(String(doctor.userId));

    AnalyticsService.setProfileProperties({
      institution_id: this.rootStore.stores.departmentsStore.rootDepartmentId,
      institution_name: this.rootStore.stores.departmentsStore.root.name,
      is_test_institution: this.rootStore.stores.settingsStore.institutionSettings.isTest
    });
  }

  @action
  async fetchCurrentClinicianProfile() {
    const currentDoctor = await CliniciansFetcher.fetchCurrentClinicianProfile();

    runInAction(() => {
      this.currentDoctor = currentDoctor;
    });

    return currentDoctor;
  }

  async sendSupportRequest(message: string) {
    await UserFetcher.sendSupportRequest(message);
  }
  @action
  async setupAfterAuthentication(callback?: () => void) {
    const currentDoctor = await this.fetchCurrentClinicianProfile();
    await this.rootStore.stores.constantsStore.systemLoadInit();

    setSentryUser(currentDoctor.id);
    if (this.rootStore.stores.settingsStore.hasFeature(FEATURES.PENDO)) {
      PendoService.initPendo(
        this.rootStore.stores.departmentsStore.rootDepartmentId,
        currentDoctor.id
      );
    }

    this.analyticsSetup(currentDoctor);

    const careManagementBulkInviteSessionId = localStorage.getItem(
      CARE_MANAGEMENT_BULK_INVITE_LOCAL_STORAGE_KEY
    );

    if (careManagementBulkInviteSessionId) {
      this.rootStore.stores.careManagementReportStore.startRecoverCheckBulkInviteProgressFlow(
        careManagementBulkInviteSessionId
      );
    }

    if (callback) {
      callback();
    }

    runInAction(() => {
      this.isAuthenticated = true;
    });
  }

  async lookUpPhoneDetails(phoneNumber: any): Promise<PhoneNumberCarrierDetails> {
    let phoneDetails = this.phoneTypeRegistry.has(phoneNumber)
      ? this.phoneTypeRegistry.get(phoneNumber)
      : null;
    if (!phoneDetails) {
      phoneDetails = await UserFetcher.lookUpPhoneDetails(phoneNumber);
      if (phoneDetails.type) {
        this.phoneTypeRegistry.set(phoneNumber, phoneDetails);
      }
    }
    return phoneDetails;
  }

  async updateIntroFinished(feature: FeatureIntroCodes) {
    await UserFetcher.updateIntroFinished(feature);
    this.fetchCurrentClinicianProfile();
  }
}

export default UserStore;
