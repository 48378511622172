import { UserType } from 'utils/AccountUtils';

import { ClinicianUserStatus } from 'models/Doctor';
import { PhoneType } from 'models/PhoneNumberDetails';

export enum PracticeManagerUsersSortBy {
  Name = 'name',
  Phone = 'phone',
  Email = 'email',
  UserType = 'type',
  UserStatus = 'userStatus',
  Date = 'joinDate'
}

export interface PracticeManagerUsersBody {
  searchTerm?: string;
  credentialIds?: number[];
  userStatuses?: ClinicianUserStatus[];
  userTypes?: UserType[];
  recordsPerPage: number;
  pageNumber: number;
  sortBy?: PracticeManagerUsersSortBy;
  sortAsc?: boolean;
}

export type PracticeManagerUsersFilters = Pick<
  PracticeManagerUsersBody,
  'searchTerm' | 'userStatuses' | 'userTypes' | 'credentialIds'
>;

export type PracticeManagerUsersSortParams = Pick<PracticeManagerUsersBody, 'sortBy' | 'sortAsc'>;

export interface PracticeUserRow {
  id: number;
  name: { firstName: string; lastName: string; credentialId?: number };
  phone: string;
  email: string;
  type: string;
  userStatus: ClinicianUserStatus;
  joinDate: Date;
}

export interface PracticeManagerClinician {
  id: number;
  role: UserType;
  userStatus: ClinicianUserStatus;
  createdAt: Date;
  credentialId?: number;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  phoneType: PhoneType;
}

export interface PracticeManagerUsersParsedResponse {
  items: PracticeUserRow[];
  totalPages: number;
  totalItems: number;
}
