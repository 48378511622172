// @ts-strict-ignore
import { castArray } from 'lodash/fp';
import { observable, action, makeObservable } from 'mobx';

class NetworkLabelService {
  @observable
  loadingMap = observable.map<string, number>();

  isLoading = (labels: string | string[]) => {
    const loadingLabels = castArray(labels);
    return loadingLabels.some((label) => this.loadingMap.get(label) > 0);
  };

  constructor() {
    makeObservable(this);
  }

  @action
  startNetwork(labels: string | string[]) {
    const loadingLabels = castArray(labels);
    loadingLabels.forEach((label) => {
      const loadingLabelCounter = this.loadingMap.get(label);
      loadingLabelCounter
        ? this.loadingMap.set(label, loadingLabelCounter + 1)
        : this.loadingMap.set(label, 1);
    });
  }

  @action
  endNetwork(labels: string | string[]) {
    const loadingLabels = castArray(labels);
    loadingLabels.forEach((label) => {
      const loadingLabelCounter = this.loadingMap.get(label);
      loadingLabelCounter
        ? this.loadingMap.set(label, loadingLabelCounter - 1)
        : this.loadingMap.set(label, 0);
    });
  }
}

const networkLabelService = new NetworkLabelService();

export default networkLabelService;
