// @ts-strict-ignore
import { AlertsStore, DataMap } from 'mobx/stores';

import { AlertUrgency } from 'models/ClinicianAlert';
import ClinicianAlertCondition from 'models/Conditions/ClinicianAlertCondition';
import { SymptomTicketUrgency, SymptomTicketUrgencyText } from 'models/OperatorTicket';
import {
  CauseSeverity,
  isNumericReportedCause,
  isSeverityReportedCause,
  ReportedCause
} from 'models/ReportedCause';
import { SymptomType } from 'models/TicketTypes';

import {
  ReportTableCellData,
  ReportTableCellDataColor,
  ReportTableIcon,
  ReportTableSymptomSection,
  TableReportItem
} from './ReportTable.model';

// cell with static content and color
const NO_SYMPTOM_CELL_DATA = {
  color: ReportTableCellDataColor.gray
} as const;

const symptomUrgencyToTextMap: { [key in SymptomTicketUrgency]: SymptomTicketUrgencyText } = {
  [SymptomTicketUrgency.NurseReview]: SymptomTicketUrgencyText.NurseReview,
  [SymptomTicketUrgency.AttentionToday]: SymptomTicketUrgencyText.AttentionToday,
  [SymptomTicketUrgency.ImmediateAttention]: SymptomTicketUrgencyText.ImmediateAttention
};

const symptomUrgencyToColorMap: { [key in SymptomTicketUrgency]: ReportTableCellDataColor } = {
  [SymptomTicketUrgency.NurseReview]: ReportTableCellDataColor.orange,
  [SymptomTicketUrgency.AttentionToday]: ReportTableCellDataColor.red,
  [SymptomTicketUrgency.ImmediateAttention]: ReportTableCellDataColor.darkRed
};

// convert severity/normalized-distress level to color
export const cellColorByLevel = (
  cause: ReportedCause,
  alertsStore: AlertsStore
): ReportTableCellDataColor => {
  if (isSeverityReportedCause(cause)) {
    const isInRange =
      CauseSeverity.Mild <= cause.severity && cause.severity <= CauseSeverity.Unbearable;
    if (isInRange) {
      return cause.severity;
    } else {
      return ReportTableCellDataColor.darkRed; //highest severity
    }
  } else if (isNumericReportedCause(cause)) {
    if (cause.value === 'iDontKnow') {
      return ReportTableCellDataColor.orange;
    }
    const alertLevel = alertsStore.getAlertLevelForNumericCause(cause);
    switch (alertLevel) {
      case AlertUrgency.CriticalAlert:
        return ReportTableCellDataColor.red;
      case AlertUrgency.UrgentAlert:
        return ReportTableCellDataColor.orange;
      default:
        return ReportTableCellDataColor.green;
    }
  } else {
    throw new Error(`Unknown cause type ${JSON.stringify(cause)}`);
  }
};

const cellTooltipByColor = (color: ReportTableCellDataColor): string => {
  // verify in range
  if (CauseSeverity.Mild <= color && color <= CauseSeverity.Unbearable) {
    return ClinicianAlertCondition.getSeverityString(color);
  } else {
    return null;
  }
};

function getSymptomCellData(
  report: TableReportItem,
  alertsStore: AlertsStore,
  cause?: ReportedCause
): ReportTableCellData {
  if (!cause) {
    return NO_SYMPTOM_CELL_DATA;
  }
  let cellData: ReportTableCellData;

  if (report.isOperatorReport) {
    const urgencyLevel =
      symptomUrgencyToTextMap[report.operatorReportUrgency as SymptomTicketUrgency];
    const color = symptomUrgencyToColorMap[report.operatorReportUrgency as SymptomTicketUrgency];
    cellData = {
      color,
      iconContent: ReportTableIcon.phone,
      tooltip: `Reported via Triage Call as "${urgencyLevel}"`
    };
  } else {
    cellData = {
      color: cellColorByLevel(cause, alertsStore)
    };
    if (isNumericReportedCause(cause)) {
      cellData.content = cause.value === 'iDontKnow' ? '?' : cause.value;
    } else {
      cellData.tooltip = cellTooltipByColor(cellData.color);
    }
  }
  return cellData;
}

export function addSymptomsReportData(
  symptomsSection: ReportTableSymptomSection,
  numericValuesSection: ReportTableSymptomSection,
  report: TableReportItem,
  symptomsMap: DataMap<SymptomType>,
  alertsStore: AlertsStore
) {
  // convert current report causes to map
  const reportCauses: { [key: string]: ReportedCause } = {};
  report.answer?.causes?.forEach(
    (cause) => (reportCauses[symptomsMap.get(cause.causeId)?.name] = cause)
  );

  // fill current questionnaire causes
  Object.keys(symptomsSection).forEach((causeName: string) => {
    const cause = reportCauses[causeName];
    const cellData = getSymptomCellData(report, alertsStore, cause);
    symptomsSection[causeName].push(cellData);
  });
  Object.keys(numericValuesSection).forEach((causeName: string) => {
    const cause = reportCauses[causeName];
    const cellData = getSymptomCellData(report, alertsStore, cause);
    numericValuesSection[causeName].push(cellData);
  });
}
