// @ts-strict-ignore

import { KeyboardEvent, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { Dialogs } from 'analytics/events/dialog';
import { trackMessagingConsentAnalyticsEvent } from 'analytics/events/messaging-consent';

import { History } from 'history';

import { isNil } from 'lodash/fp';
import { observer } from 'mobx-react';

import { FormProvider, useForm } from 'react-hook-form';

import { Col, Row } from 'reactstrap';

import { createTicketsTestSelectors } from 'tests/models/pages/create-ticket/create-tickets-page.selectors';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useProvider } from 'mobx/hooks/useProvidersOptions';
import { useStores } from 'mobx/hooks/useStores';

import { PatientCommunicationFetcher } from 'fetchers/PatientCommunicationFetcher';
import { ICreateTicketResult } from 'fetchers/TicketsFetcher';

import { getTimeInSecondsFromDate } from 'utils/DateUtils';
import { getNumberAndCountryCodeFromPhone } from 'utils/PhoneUtils';

import { showToast } from 'utils/UserMessageUtils';

import { API_URLS } from 'constants/apiUrls';
import { FEATURES } from 'constants/features';

import Patient from 'models/Patient';
import Ticket from 'models/Ticket';

import { MessagingConsentFormLabeledCheckbox } from 'components/Ticket/TicketForms/MessagingConsentFormLabeledCheckbox';
import {
  IOperatorCreateTicketsFormFields,
  PATIENT_IS_CONTACT_VALUE
} from 'components/Ticket/TicketForms/ticket.shared';
import { defaultNonSymptomUrgencyValue } from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';
import { FilledButton, OutlinedButton } from 'components/UIkit/atoms/Button';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { OperatorTicketsInfoForm } from './OperatorCreateTicketsInfoForm';
import { OperatorTicketContactForm } from './OperatorTicketContactForm';

import { buildTicketsCreateRequest } from './ticketRequestBuilders';

import './OperatorTicketForm.scss';

interface IOperatorCreateTicketFormProps {
  patient: Patient;
  onSubmitSuccess?: (result: Ticket[]) => void;
  history: History;
  createFlowStartTime?: Date;
}

export const TICKET_TYPES_MAIN_DROPDOWN_KEY = 'ticketTypes';
export const TICKET_TYPES_SPECIFIC_DROPDOWN_KEY = 'ticketTypeSelectorValue';

export const OperatorCreateTicketForm = observer(
  ({ patient, onSubmitSuccess, history, createFlowStartTime }: IOperatorCreateTicketFormProps) => {
    const { ticketsStore, locationsStore, settingsStore } = useStores();
    const isLoading = useNetworkLoading(API_URLS.TICKETS);
    const [initialCountryCode, initialPhoneNumber] = getNumberAndCountryCodeFromPhone(
      patient.phone
    );
    const patientProvider = useProvider(patient?.providerId);
    const isPatientMessagingFeatureEnabled = settingsStore.hasFeature(
      FEATURES.ACTIONS_SEND_PATIENT_SMS
    );
    const isMessagingConsentDefined = !isNil(patient.messagingConsent);

    const [isClearWarningDialogOpen, setIsClearWarningDialogOpen] = useState(false);
    const methods = useForm<IOperatorCreateTicketsFormFields>({
      defaultValues: {
        contactId: PATIENT_IS_CONTACT_VALUE,
        phone: initialPhoneNumber,
        countryCode: initialCountryCode,
        callbackHippaAuth: false,
        ticketTypes: null,
        ...(isPatientMessagingFeatureEnabled &&
          isMessagingConsentDefined && {
            messagingConsent: patient.hasMessagingConsent
          }),
        tickets: [
          {
            urgency: defaultNonSymptomUrgencyValue,
            notes: '',
            assignee: null,
            categoryId: '',
            ticketSubTypeIds: [],
            parentId: null,
            locationId: patient?.location
              ? {
                  label: locationsStore.getLocationById(patient?.location.id)?.name,
                  value: patient.location
                }
              : null,
            providerId: patientProvider?.value ? patientProvider : null
          }
        ]
      }
    });

    const hasMessagingConsentChanged = (newConsent: boolean) => {
      return newConsent !== patient.hasMessagingConsent;
    };

    const submitRequest = async (
      formData: IOperatorCreateTicketsFormFields,
      { manage = false } = {}
    ) => {
      const reqData = buildTicketsCreateRequest(formData, patient);

      reqData.creationTimeInSeconds = getTimeInSecondsFromDate(createFlowStartTime);

      const createTicketsRes: ICreateTicketResult = await ticketsStore.createTickets(reqData);

      if (
        isPatientMessagingFeatureEnabled &&
        !isNil(formData.messagingConsent) &&
        hasMessagingConsentChanged(formData.messagingConsent)
      ) {
        await PatientCommunicationFetcher.updateMessagingConsent(
          patient.id,
          formData.messagingConsent
        );

        trackMessagingConsentAnalyticsEvent({
          action: formData.messagingConsent
            ? AnalyticEventAction.Consent
            : AnalyticEventAction.NonConsent,
          patientId: patient.id
        });
      }

      const ticketsText = createTicketsRes.tickets.length > 1 ? 'Tickets' : 'Ticket';
      showToast({
        message: `${createTicketsRes.tickets.length} ${ticketsText} Created for ${patient.fullName}`
      });

      if (manage) {
        history.push(`/patient/${createTicketsRes.patient.id}`);
      }
      onSubmitSuccess && onSubmitSuccess(createTicketsRes.tickets);
    };

    const onSubmit = async (data: IOperatorCreateTicketsFormFields) => {
      trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.Save });
      await submitRequest(data);
    };

    const onSubmitAndManage = async (data: IOperatorCreateTicketsFormFields) => {
      trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.SaveAndManage });
      await submitRequest(data, { manage: true });
    };

    const handleKeyDown = async (e: KeyboardEvent<HTMLDivElement>) => {
      if (isLoading) {
        return;
      }

      if (e.shiftKey && e.key === 'Enter') {
        // need to manually trigger validations here so form doesn't submit without them
        await methods.trigger();
        methods.handleSubmit(onSubmit)();
      }
    };

    return (
      <>
        <MessageDialog
          id={Dialogs.ClearAllFields}
          isOpen={isClearWarningDialogOpen}
          title="Clear All Fields?"
          handleClose={() => setIsClearWarningDialogOpen(false)}
          primaryActionProps={{
            text: 'Delete',
            onClick: () => {
              methods.reset();
              setIsClearWarningDialogOpen(false);
            }
          }}
          secondaryActionProps={{
            text: 'Cancel',
            onClick: () => setIsClearWarningDialogOpen(false)
          }}
        >
          All details you have entered will be erased.
        </MessageDialog>

        <FormProvider {...methods}>
          <div
            className="operator-ticket-form"
            onKeyDown={handleKeyDown}
            data-test-hook="create-tickets-form"
          >
            <Row>
              <Col xs={4} sm={4} lg={4}>
                <OperatorTicketContactForm patient={patient} />
              </Col>
              <Col xs={8} sm={8} lg={8}>
                <OperatorTicketsInfoForm patient={patient} />
                {isPatientMessagingFeatureEnabled && <MessagingConsentFormLabeledCheckbox />}
              </Col>
            </Row>
            <Row>
              <Col className="submit-section">
                <OutlinedButton
                  onClick={() => {
                    trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.Clear });
                    setIsClearWarningDialogOpen(true);
                  }}
                  size="medium"
                >
                  Clear
                </OutlinedButton>

                <FilledButton
                  onClick={() => {
                    methods.handleSubmit(onSubmitAndManage, () =>
                      trackActionButtonAnalyticsEvent({
                        action: AnalyticEventAction.SaveAndManage,
                        value: 'missing fields'
                      })
                    )();
                  }}
                  disabled={isLoading}
                  ml={8}
                >
                  {isLoading ? 'Saving...' : 'Save & Manage'}
                </FilledButton>

                <FilledButton
                  onClick={() => {
                    methods.handleSubmit(onSubmit, () =>
                      trackActionButtonAnalyticsEvent({
                        action: AnalyticEventAction.Save,
                        value: 'missing fields'
                      })
                    )();
                  }}
                  disabled={isLoading}
                  ml={8}
                  testHook={createTicketsTestSelectors.saveButton}
                >
                  {isLoading ? 'Saving...' : 'Save'}
                </FilledButton>
              </Col>
            </Row>
          </div>
        </FormProvider>
      </>
    );
  }
);
