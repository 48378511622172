import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Remove | AnalyticEventAction.Select | AnalyticEventAction.Click;

type Value = 'send to EMR' | 'copy to clipboard' | 'generate smart summary';

interface Properties {
  action: Action;
  virtual_page: 'call logger';
  value?: Value;
}

export const trackSubmitCallSideMenuAnalyticsEvent = ({
  action,
  virtual_page,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.SubmitCallSideMenu,
    properties: { action, virtual_page, ...optionalProps }
  });
};
