import { useState } from 'react';

import { useRefCallback } from 'hooks/useRefCallback';

interface UseVerticalOverflowDetectorReturnType {
  ref: (node: HTMLElement) => void;
  isActive: boolean;
}

// https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
export const useVerticalOverflowDetector = (): UseVerticalOverflowDetectorReturnType => {
  const [isActive, setIsActive] = useState(false);

  const { ref } = useRefCallback((node: HTMLElement) =>
    setIsActive(node.clientHeight < node.scrollHeight)
  );

  return { ref, isActive };
};
