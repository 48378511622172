// @ts-strict-ignore
import { useMemo } from 'react';

import { MissingEntity } from 'errors';

import { useStores } from 'mobx/hooks/useStores';

import { DataMap } from 'mobx/stores';

import { handleErrorSilently } from 'services/errorHandlingService';

import { isSameDay } from 'utils/DateUtils';

import Call from 'models/Call';

import MissedDosesReasons from 'models/MissedDosesReasons';
import Patient from 'models/Patient';

import QuestionnaireAnswer, { ReportType } from 'models/QuestionnaireAnswer';
import SeverityReportedCause from 'models/ReportedCause';
import Ticket from 'models/Ticket';

import { TableReportItem, StartOfCycle } from './ReportTable.model';

function normalizeOperatorTickets(symptomTickets: Ticket[]): TableReportItem[] {
  return symptomTickets.map((ticket) => {
    const causes = ticket.operatorTicket.ticketSubTypesIds.ids.map(
      (id) => new SeverityReportedCause(id)
    );

    return {
      type: ReportType.Distress,
      createdAt: new Date(ticket.operatorTicket.createdAt),
      isOperatorReport: true,
      operatorReportUrgency: ticket.operatorTicket.urgency,
      answer: {
        causes,
        distressLevel: null
      },
      callIds: ticket.callIds
    };
  });
}

// missed doses - convert reasons id to reason text
export function parseMissedDosesReasons(
  reasonsIds: number[],
  missedDosesReasonsMap: DataMap<MissedDosesReasons>
): string[] {
  const reasonsText: string[] = [];
  reasonsIds.forEach((reasonId: number) => {
    const reason = missedDosesReasonsMap.get(reasonId);
    if (reason) {
      reasonsText.push(reason.text);
    } else {
      handleErrorSilently(
        new MissingEntity(`Missed doses reason (id:${reasonId}) not found in store`)
      );
    }
  });
  return reasonsText;
}

function normalizePatientAnswers(
  patientAnswers: QuestionnaireAnswer[],
  missedDosesReasonsMap: DataMap<MissedDosesReasons>,
  calls: Call[]
): TableReportItem[] {
  return patientAnswers.map((questionnaire) => {
    if (questionnaire.answer?.oral?.missedDoses?.additions?.length > 0) {
      const { missedDoses } = questionnaire.answer?.oral;
      missedDoses.additionsParsed = parseMissedDosesReasons(
        missedDoses.additions,
        missedDosesReasonsMap
      );
    }

    const callIds: number[] = [];

    calls.forEach((call) => {
      if (Array.from(call.ticketIds).includes(questionnaire.ticketId)) {
        callIds.push(call.id);
      }
    });

    return {
      type: questionnaire.type,
      createdAt: new Date(questionnaire.createdAt),
      isOperatorReport: false,
      answer: questionnaire.answer,
      callIds
    };
  });
}

// separate report for a new cycle should be added if "firstPill" is not same day as report.createdAt
function normalizeNewCycleReports(patientReports: TableReportItem[]): TableReportItem[] {
  const newCycleReports: TableReportItem[] = [];

  patientReports.forEach((report) => {
    if (report.type === ReportType.Oral) {
      const startOfCycleDate = report.answer.oral.newCycleDate;
      // if not same day as report days - add start of cycle as a separate report
      if (startOfCycleDate && !isSameDay(startOfCycleDate, report.createdAt)) {
        newCycleReports.push({
          type: StartOfCycle,
          createdAt: startOfCycleDate,
          isOperatorReport: false,
          answer: report.answer,
          callIds: report.callIds
        });
      }
    }
  });
  return newCycleReports;
}

export function useReportNormalization(
  symptomTickets: Ticket[],
  patientAnswers: QuestionnaireAnswer[],
  patient: Patient
): TableReportItem[] {
  const { constantsStore } = useStores();
  return useMemo(() => {
    // convert all to TableReportItem
    const operatorSymptomReports = normalizeOperatorTickets(symptomTickets);
    const patientReports = normalizePatientAnswers(
      patientAnswers,
      constantsStore.missedDosesReasonsMap,
      patient.calls
    );
    const startCycleReports = normalizeNewCycleReports(patientReports);

    return [...operatorSymptomReports, ...patientReports, ...startCycleReports];
  }, [symptomTickets, patientAnswers, constantsStore, patient.calls]);
}
