// @ts-strict-ignore

import {
  FC,
  JSXElementConstructor,
  MutableRefObject,
  ReactElement,
  ReactNode,
  SyntheticEvent
} from 'react';

import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { Placement } from 'tippy.js';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import { Testable } from 'utils/TypeUtils';

import { sameWidthPopperModifier } from 'components/Tooltip/Tooltip.constants';
import {
  PopperModifier,
  TooltipController,
  TooltipEvents,
  TooltipTrigger
} from 'components/Tooltip/Tooltip.types';

import './Tooltip.scss';

export interface TooltipProps extends TooltipEvents, Testable {
  children: ReactNode;
  label?: ReactElement<any, string | JSXElementConstructor<any>>;
  disabled?: boolean;
  placement?: Placement;
  trigger?: TooltipTrigger;
  maxWidth?: number | string;
  maxHeight?: string;
  controller?: TooltipController;
  reference?: MutableRefObject<Element | null>;
  className?: string;
  arrow?: boolean;
  zIndex?: number;
  interactive?: boolean;
  offset?: [number, number];
  appendTo?: 'parent' | Element | ((ref: Element) => Element);
  shouldTruncate?: boolean;
  hasSameWidthModifier?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({
  label,
  disabled,
  placement = 'bottom',
  trigger = TooltipTrigger.MOUSE_ENTER,
  maxWidth = 250,
  controller,
  reference,
  children,
  onShow,
  onHide,
  className = '',
  arrow = true,
  zIndex = 9999, //default z-index of Tippy
  interactive = true,
  offset = [0, 10],
  appendTo = document.body,
  shouldTruncate = true,
  hasSameWidthModifier = false,
  testHook
}) => {
  const events: TooltipEvents = {};
  if (onShow) {
    events.onShow = onShow;
  }
  if (onHide) {
    events.onHide = onHide;
  }

  const labelWrapperClick = (event: SyntheticEvent) => {
    if (trigger === TooltipTrigger.MOUSE_ENTER) {
      return;
    }

    event.stopPropagation();
  };

  const contentWrapperClick = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  // see: https://github.com/atomiks/tippyjs-react#visible-boolean-controlled-mode
  const controlledModeProps = controller || {};
  if (controlledModeProps === controller) {
    // trigger should not be specified in controlled mode
    trigger = undefined;
  }

  disabled = disabled && !!children; // disable tooltip if no content available
  const cursorPointer = !disabled && trigger === TooltipTrigger.CLICK;

  const modifiers: PopperModifier[] = [];

  if (hasSameWidthModifier) {
    modifiers.push(sameWidthPopperModifier);
  }

  return (
    <Tippy
      interactive={interactive}
      theme="light"
      animation="shift-away"
      appendTo={appendTo}
      maxWidth={hasSameWidthModifier ? 'none' : maxWidth}
      placement={placement}
      disabled={!!disabled}
      trigger={trigger}
      reference={reference}
      {...events}
      {...controlledModeProps}
      content={
        <div data-test-hook={testHook} onClick={contentWrapperClick}>
          {children}
        </div>
      }
      arrow={arrow}
      zIndex={zIndex}
      offset={offset}
      className={className}
      popperOptions={{
        modifiers
      }}
    >
      {label && (
        <div
          onClick={labelWrapperClick}
          className={classNames({
            'text-truncate': shouldTruncate,
            'cursor-pointer': cursorPointer,
            [className]: Boolean(className)
          })}
        >
          {label}
        </div>
      )}
    </Tippy>
  );
};
