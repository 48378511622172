import { FC, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackOpenTextInputUsageAnalyticsEvent } from 'analytics/events/input-usage';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import ExpandableRow from 'views/Widgets/ExpandableRow';

interface Props {
  generateSmartSummaryPreference: boolean;
}

export const CallMyNotesSection: FC<Props> = observer(({ generateSmartSummaryPreference }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [myNotesValueOnFocus, setMyNotesValueOnFocus] = useState('');
  const { callLoggingStore, settingsStore } = useStores();

  const myNotesTextareaPlaceholder = `Start typing your notes here. Pathway responses are automatically inserted into the Canopy Generated section above. ${
    generateSmartSummaryPreference && settingsStore.hasFeature(FEATURES.CALL_LOGGER_SMART_SUMMARY)
      ? 'Smart Summary is enabled, and will automatically summarize Pathway responses into a readable format for provider review.'
      : ''
  }`;

  return (
    <ExpandableRow
      isExpanded={isExpanded}
      title="My Notes"
      onToggleExpand={() => {
        trackSectionUsageAnalyticsEvent({
          action: isExpanded ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
          value: 'My Notes',
          virtual_page: 'call logger'
        });

        setIsExpanded(!isExpanded);
      }}
    >
      <textarea
        placeholder={myNotesTextareaPlaceholder}
        className="note-text-area"
        onFocus={() => {
          setMyNotesValueOnFocus(callLoggingStore.currentCall.note || '');
        }}
        onBlur={() => {
          trackOpenTextInputUsageAnalyticsEvent(
            callLoggingStore.currentCall.note || '',
            myNotesValueOnFocus,
            'My Note Section',
            { virtual_page: 'call logger' }
          );
        }}
        onChange={(event) => callLoggingStore.updateCurrentCall({ note: event.target.value })}
        value={callLoggingStore.currentCall.note}
      />
    </ExpandableRow>
  );
});
