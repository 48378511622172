import { FC, useCallback, useMemo, useRef, useState } from 'react';

import {
  trackDropdownFilterUsageAnalyticsEvent,
  trackPreselectedFilterUsageAnalyticsEvent,
  trackSearchByNameOrMrnFilterUsageAnalyticsEvent
} from 'analytics/events/filter-usage';

import debounce from 'debounce-promise';

import { observer } from 'mobx-react';

import { ActionMeta } from 'react-select';

import { useMount } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';
import {
  CARE_MANAGEMENT_REPORT_FILTERS_LOCAL_STORAGE_KEY,
  CareManagementReportFiltersType,
  InsuranceTypeParam
} from 'mobx/stores';

import { InsuranceResponseType } from 'fetchers/responses/care-management.response';

import { CareManagementInsurance } from 'constants/careManagement.const';

import { FEATURES } from 'constants/features';

import { ActiveTxDateRange } from 'models/ActiveTxDateRange';

import { useResetPageInUrl } from 'hooks/useNavPagination';

import SearchBar from 'views/Dashboard/SearchBar';
import CmProvidersFilter from 'views/Filters/CmProvidersFilter';
import { FilterName } from 'views/Filters/filters.types';
import { ClearCmTableSelectionPopup } from 'views/Modals/ClearCmTableSelectionPopup';
import {
  DEBOUNCE_DELAY,
  useDebouncedCmReportData
} from 'views/Pages/CareManagement/CareManagement.hooks';
import { CareManagementTabs, cmTabsToNames } from 'views/Pages/CareManagement/CareManagementPage';
import {
  enrollmentManagerFiltersToNames,
  ValueOf
} from 'views/Pages/CareManagement/CareManagementPage.utils';
import { ISelectOption } from 'views/Widgets/StyledSelect';
import { getSelectedByAction } from 'views/Widgets/StyledSelect.util';

import { CmFilterMultiAutocomplete } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/CmFilterMultiAutocomplete/CmFilterMultiAutocomplete';

import { MultipleSelectFilterFieldProps } from './FilterFields';

import { useSetPersistFilters } from './useFilters';

import './PageFilters.scss';

export interface CmMultipleSelectFilterFieldProps<T> extends MultipleSelectFilterFieldProps<T> {
  selectedTableRowsCount: number;
}

const CmStatusesFilterField = observer(
  ({ value, onChange, selectedTableRowsCount }: CmMultipleSelectFilterFieldProps<number>) => {
    const { careManagementReportStore } = useStores();
    return (
      <CmFilterMultiAutocomplete
        label="Enrollment Status"
        options={careManagementReportStore.cmStatusesForSelect(false)}
        value={value}
        sortAlphabetically={false}
        onChange={onChange}
        selectedTableRowsCount={selectedTableRowsCount}
      />
    );
  }
);

const AntineoplasticFilterField = observer(
  ({ value, onChange, selectedTableRowsCount }: CmMultipleSelectFilterFieldProps<number>) => {
    const { careManagementReportStore } = useStores();
    return (
      <CmFilterMultiAutocomplete
        label="Eligibility Score"
        options={careManagementReportStore.antineoplasticForSelect}
        value={value}
        sortAlphabetically={false}
        onChange={onChange}
        selectedTableRowsCount={selectedTableRowsCount}
      />
    );
  }
);

const activeTxOptions = [
  { value: ActiveTxDateRange.lastMonth, label: 'Last Month' },
  { value: ActiveTxDateRange.past2to6Months, label: 'Past 2 to 6 Months' },
  { value: ActiveTxDateRange.past7to12Months, label: 'Past 7 to 12 Months' }
];

const ActiveTxFilterField: FC<CmMultipleSelectFilterFieldProps<ActiveTxDateRange>> = observer(
  ({ value, onChange, selectedTableRowsCount }) => {
    return (
      <CmFilterMultiAutocomplete
        label="Active Tx"
        options={activeTxOptions}
        value={value}
        sortAlphabetically={false}
        onChange={onChange}
        selectedTableRowsCount={selectedTableRowsCount}
      />
    );
  }
);

type InsuranceOptions = ISelectOption<InsuranceResponseType>[];
type InsuranceActionMeta = ActionMeta<ISelectOption<InsuranceResponseType>>;

function isAnyOrNoneOption(option: ISelectOption<InsuranceResponseType>) {
  if (option?.value?.id) {
    const { id } = option.value;
    return id === CareManagementInsurance.Any || id === CareManagementInsurance.None;
  }
  return false;
}

const InsuranceFilterField = observer(
  ({
    value,
    onChange,
    label,
    options,
    testHook,
    selectedTableRowsCount
  }: CmMultipleSelectFilterFieldProps<InsuranceResponseType> & {
    label: string;
    options: InsuranceOptions;
  }) => {
    const handleChange = (selectedOptions: InsuranceOptions, action: InsuranceActionMeta) => {
      const lastSelectedOption = getSelectedByAction(action);

      if (isAnyOrNoneOption(lastSelectedOption)) {
        selectedOptions = [lastSelectedOption];
      } else if (selectedOptions) {
        selectedOptions = selectedOptions.filter((option) => !isAnyOrNoneOption(option));
      }

      onChange(selectedOptions, action);
    };

    return (
      <CmFilterMultiAutocomplete
        label={label}
        options={options}
        value={value}
        testHook={testHook}
        onChange={handleChange}
        sortAlphabetically={false}
        selectedTableRowsCount={selectedTableRowsCount}
      />
    );
  }
);

const RegionsFilterField = observer(
  ({ value, onChange, selectedTableRowsCount }: CmMultipleSelectFilterFieldProps<number>) => {
    const { locationsStore } = useStores();
    const options = locationsStore.regionsForSelect;

    return (
      <CmFilterMultiAutocomplete
        label="Location of Next Visit"
        options={options}
        value={value}
        onChange={onChange}
        sortAlphabetically={false}
        selectedTableRowsCount={selectedTableRowsCount}
        isCheckboxesMultiAutocomplete
        testHook="location-of-next-visit-filter"
      />
    );
  }
);

const NextVisitLocationFilterField = observer(
  ({ value, onChange, selectedTableRowsCount }: CmMultipleSelectFilterFieldProps<number>) => {
    const { careManagementReportStore } = useStores();

    return (
      <CmFilterMultiAutocomplete
        label="Location of Next Visit"
        options={careManagementReportStore.nextVisitLocationsForSelect}
        value={value}
        onChange={onChange}
        sortAlphabetically={false}
        selectedTableRowsCount={selectedTableRowsCount}
      />
    );
  }
);

const useCareManagementReportFilters = () => {
  const resetPageInUrl = useResetPageInUrl();
  const { careManagementReportStore } = useStores();
  const fetchCmReportData = useDebouncedCmReportData();

  const handleFiltersChanged = useCallback(
    (filters: CareManagementReportFiltersType) => {
      careManagementReportStore.setReportFilters(filters);
      resetPageInUrl();
      fetchCmReportData();
    },
    [careManagementReportStore, resetPageInUrl, fetchCmReportData]
  );

  const { updateFiltersByKey } = useSetPersistFilters<CareManagementReportFiltersType>(
    careManagementReportStore.reportFilters,
    handleFiltersChanged,
    {
      localStorageKey: CARE_MANAGEMENT_REPORT_FILTERS_LOCAL_STORAGE_KEY,
      excludeFieldKeys: ['nameOrMrn']
    }
  );

  return {
    updateFiltersByKey
  };
};

interface Props {
  clearTableSelection: () => void;
  selectedTableRowsCount: number;
}

const CareManagementReportFilters: FC<Props> = ({
  clearTableSelection,
  selectedTableRowsCount
}) => {
  const [isClearSelectionPopupOpen, setIsClearSelectionPopupOpen] = useState(false);
  const searchBarInputRef = useRef<HTMLInputElement>(null);
  const { careManagementReportStore, settingsStore } = useStores();
  const { updateFiltersByKey } = useCareManagementReportFilters();
  const { reportFilters } = careManagementReportStore;
  const eligibilityScoreFilter = settingsStore.hasFeature(FEATURES.ELIGIBILITY_SCORE_FILTER);
  const activeTxFilter = settingsStore.hasFeature(FEATURES.ACTIVE_TX_FILTER);
  const hasRegionsFilterFeature = settingsStore.hasFeature(FEATURES.REGIONS);
  const LocationsFilterComponent = hasRegionsFilterFeature
    ? RegionsFilterField
    : NextVisitLocationFilterField;

  useMount(function trackAnalyticsEvent() {
    trackPreselectedFilterUsageAnalyticsEvent(reportFilters, enrollmentManagerFiltersToNames);
  });

  const onTrackSearchAnalyticsEvent = useMemo(() => {
    return debounce(
      (searchQuery: string) =>
        trackSearchByNameOrMrnFilterUsageAnalyticsEvent(
          searchQuery,
          cmTabsToNames[CareManagementTabs.ENROLLMENT_MANAGER]
        ),
      DEBOUNCE_DELAY
    );
  }, []);

  const onDropdownFilterChange = (
    values: ValueOf<CareManagementReportFiltersType>,
    filterKey: keyof CareManagementReportFiltersType,
    filterName: FilterName,
    actionMeta: ActionMeta<any>
  ) => {
    trackDropdownFilterUsageAnalyticsEvent(
      actionMeta,
      filterName,
      cmTabsToNames[CareManagementTabs.ENROLLMENT_MANAGER]
    );

    updateFiltersByKey(filterKey)(values);

    if (selectedTableRowsCount) {
      clearTableSelection();
    }
  };

  const onClearTablesSelection = () => {
    clearTableSelection();
    setIsClearSelectionPopupOpen(false);
    searchBarInputRef.current?.focus(); //after clearing the table selection we want to focus again the search bar
  };

  const onSearchBarFocus = () => {
    if (selectedTableRowsCount > 0 && !isClearSelectionPopupOpen) {
      searchBarInputRef.current?.blur(); //while popup is open we don't want the user to search
      setIsClearSelectionPopupOpen(true);
    }
  };

  return (
    <div className="page-filters">
      <ClearCmTableSelectionPopup
        isOpen={isClearSelectionPopupOpen}
        onClose={() => setIsClearSelectionPopupOpen(false)}
        onSave={onClearTablesSelection}
        selectedTableRowsCount={selectedTableRowsCount}
      />

      <SearchBar
        className="search"
        placeholder="Search by Name or MRN"
        searchValue={reportFilters.nameOrMrn}
        inputRef={searchBarInputRef}
        onSearchChanged={(value) => {
          onTrackSearchAnalyticsEvent(value);
          updateFiltersByKey('nameOrMrn')(value);
        }}
        onFocus={onSearchBarFocus}
        testHook="search-by-name-or-mrn"
      />

      <CmProvidersFilter
        testHook="providers-filter"
        value={reportFilters.providers}
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(values, 'providers', FilterName.PrimaryProvider, actionMeta)
        }
        selectedTableRowsCount={selectedTableRowsCount}
      />

      <InsuranceFilterField
        label="Primary Insurance"
        testHook="primary-insurance-filter"
        options={careManagementReportStore.insuranceFilters(InsuranceTypeParam.Primary)}
        value={reportFilters.primaryInsurances}
        selectedTableRowsCount={selectedTableRowsCount}
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(
            values,
            'primaryInsurances',
            FilterName.PrimaryInsurance,
            actionMeta
          )
        }
      />

      <InsuranceFilterField
        label="Secondary Insurance"
        testHook="secondary-insurance-filter"
        selectedTableRowsCount={selectedTableRowsCount}
        options={careManagementReportStore.insuranceFilters(InsuranceTypeParam.Secondary)}
        value={reportFilters.secondaryInsurances}
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(
            values,
            'secondaryInsurances',
            FilterName.SecondaryInsurance,
            actionMeta
          )
        }
      />

      <LocationsFilterComponent
        testHook="next-visits-filter"
        selectedTableRowsCount={selectedTableRowsCount}
        value={reportFilters.nextVisitLocations}
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(
            values,
            'nextVisitLocations',
            FilterName.LocationOfNextVisit,
            actionMeta
          )
        }
      />

      {eligibilityScoreFilter && (
        <AntineoplasticFilterField
          testHook="recent-antineoplastic-administrations-filter"
          value={reportFilters.antineoplasticAdmins}
          selectedTableRowsCount={selectedTableRowsCount}
          onChange={(values, actionMeta) => {
            onDropdownFilterChange(
              values,
              'antineoplasticAdmins',
              FilterName.EligibilityScore,
              actionMeta
            );
          }}
        />
      )}

      {activeTxFilter && (
        <ActiveTxFilterField
          testHook="active-tx-filter"
          value={reportFilters.activeTx}
          selectedTableRowsCount={selectedTableRowsCount}
          onChange={(values, actionMeta) => {
            onDropdownFilterChange(values, 'activeTx', FilterName.ActiveTx, actionMeta);
          }}
        />
      )}

      <CmStatusesFilterField
        testHook="cm-status-filter"
        value={reportFilters.cmStatuses}
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(values, 'cmStatuses', FilterName.EnrollmentStatus, actionMeta)
        }
        selectedTableRowsCount={selectedTableRowsCount}
      />
    </div>
  );
};

export default observer(CareManagementReportFilters);
