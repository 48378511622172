import { ErrorName } from 'errors';

import { useForm } from 'react-hook-form';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';

import { useStores } from 'mobx/hooks/useStores';

import { SERVER_ERROR } from 'utils/TicketType.utils';

import { showToast } from 'utils/UserMessageUtils';

import { API_URLS } from 'constants/apiUrls';
import { FEATURES } from 'constants/features';

import {
  DisplayNameLanguage,
  TicketTypeCreationAllowance,
  TicketTypeDisplayName
} from 'models/TicketTypes';

interface FormFields {
  name: string;
  enDisplayName: string;
  spDisplayName: string;
  enInstructions: string;
  spInstructions: string;
}

export const useEditTicketTypeOrSubType = (
  id: number,
  onClose: () => void,
  isTicketSubType: boolean = false
) => {
  const { ticketTypesStore, settingsStore } = useStores();
  const isLoading = useNetworkLoading([API_URLS.OPERATOR_TICKET_TYPES]);
  const ticketTypeOrSubType = ticketTypesStore.getOperatorTicketTypeById(id);

  const englishDisplayName = ticketTypesStore.getDisplayNameByLanguageId(
    id,
    DisplayNameLanguage.English
  );

  const spanishDisplayName = ticketTypesStore.getDisplayNameByLanguageId(
    id,
    DisplayNameLanguage.Spanish
  );

  const defaultEnglishDisplayName = ticketTypesStore.getDisplayNameByLanguageId(
    id,
    DisplayNameLanguage.English,
    true
  );

  const defaultSpanishDisplayName = ticketTypesStore.getDisplayNameByLanguageId(
    id,
    DisplayNameLanguage.Spanish,
    true
  );

  const methods = useForm<FormFields>();
  const { watch, formState, setError } = methods;

  const { enDisplayName, spDisplayName, enInstructions, spInstructions, name } = watch();
  const { isDirty, isValid, errors } = formState;

  const isSubmitDisabled =
    isLoading ||
    !isDirty ||
    !isValid ||
    (enDisplayName === englishDisplayName?.name &&
      spDisplayName === spanishDisplayName?.name &&
      name === ticketTypeOrSubType?.name);

  const parentId = ticketTypeOrSubType?.parent?.id || null;
  const isBuiltInTicketSubType = !ticketTypeOrSubType?.isEditable;

  const isInstructionsSectionVisible =
    (englishDisplayName?.instructions ||
      defaultEnglishDisplayName?.instructions ||
      spanishDisplayName?.instructions ||
      defaultSpanishDisplayName?.instructions) &&
    ticketTypeOrSubType.displaySettings?.includes(TicketTypeCreationAllowance.PATIENT);

  const isPatientsCheckboxDisabled =
    !settingsStore.hasFeature(FEATURES.PATIENT_REQUESTS) ||
    !ticketTypeOrSubType?.isOperatorsCheckboxChecked;

  const isDisplayNamesSectionVisible =
    ticketTypeOrSubType?.displaySettings?.includes(TicketTypeCreationAllowance.PATIENT) &&
    !isPatientsCheckboxDisabled;

  const shouldOpenUpdateDisplayNamesPopup =
    isDisplayNamesSectionVisible &&
    (!enDisplayName?.trim() || !spDisplayName?.trim()) &&
    name !== ticketTypeOrSubType?.name;

  const formDefaultValues = {
    name: ticketTypeOrSubType?.name?.trim(),
    enDisplayName: englishDisplayName?.name?.trim() || '',
    spDisplayName: spanishDisplayName?.name?.trim() || '',
    enInstructions: englishDisplayName?.instructions || defaultEnglishDisplayName?.instructions,
    spInstructions: spanishDisplayName?.instructions || defaultSpanishDisplayName?.instructions
  };

  const editTicketTypeOrSubType = async () => {
    const displayNames: TicketTypeDisplayName[] = [];

    if (enDisplayName) {
      displayNames.push({
        name: enDisplayName.trim(),
        instructions: enInstructions,
        languageId: DisplayNameLanguage.English,
        isDefault: false
      });
    }

    if (spDisplayName) {
      displayNames.push({
        name: spDisplayName.trim(),
        instructions: spInstructions,
        languageId: DisplayNameLanguage.Spanish,
        isDefault: false
      });
    }

    try {
      await ticketTypesStore.updateTicketTypeOrSubType(ticketTypeOrSubType.id, {
        name: name.trim(),
        parentId,
        displayNames
      });
      showToast({ message: `Ticket ${isTicketSubType ? 'Sub-' : ''}Type "${name}" Updated` });
      onClose();
    } catch (error: any) {
      if (error.name === ErrorName.DuplicateTicketSubTypeOperatorName) {
        setError('name', {
          type: SERVER_ERROR,
          message: error.ui?.title || 'Something went wrong'
        });
      } else if (error.name === ErrorName.DuplicateTicketTypeDisplayNameForPatientApp) {
        setError(
          error.httpFailure.serverData.languageId === DisplayNameLanguage.English
            ? 'enDisplayName'
            : 'spDisplayName',
          {
            type: SERVER_ERROR,
            message: error.ui?.title || 'Something went wrong'
          }
        );
      } else {
        throw error;
      }
    }
  };

  return {
    methods,
    watch,
    isSubmitDisabled,
    errors,
    ticketTypeOrSubType,
    editTicketTypeOrSubType,
    isInstructionsSectionVisible,
    isDisplayNamesSectionVisible,
    isBuiltInTicketSubType,
    isLoading,
    defaultEnglishDisplayName,
    defaultSpanishDisplayName,
    englishDisplayName,
    spanishDisplayName,
    shouldOpenUpdateDisplayNamesPopup,
    formDefaultValues
  };
};
