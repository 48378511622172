import { useStores } from 'mobx/hooks/useStores';

export const useClinicianFullNameWithCredentials = (
  firstName?: string,
  lastName?: string,
  credentialId?: number
): string => {
  const { constantsStore } = useStores();
  const { clinicianCredential } = constantsStore;

  if (!firstName && !lastName) {
    return '';
  }

  const fullName = `${firstName} ${lastName}`;

  if (!credentialId) {
    return fullName;
  }
  const credential = clinicianCredential.get(credentialId);
  const credentialText = credential ? credential.text : null;
  return credential ? `${fullName}, ${credentialText}` : fullName;
};
