import { FC, useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackFilterUsageAnalyticsEvent } from 'analytics/events/filter-usage';

import debounce from 'debounce-promise';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { isSubStringCaseInsensitive } from 'utils/StringUtils';

import PatientLocation from 'models/PatientLocation';

import SearchBar from 'views/Dashboard/SearchBar';
import { FilterName } from 'views/Filters/filters.types';

import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';

import { VisibilityToggleCell } from 'components/Patient/Table/VisibilityToggleCell';
import { defaultStringPropertyCompare, TableColumn, Table } from 'components/Table';

import './ManagePracticeLocationsPage.scss';

interface Row {
  id: number;
  name: string;
  visible: boolean;
}

export const ManagePracticeLocationsPage: FC = observer(() => {
  const [searchTerm, setSearchTerm] = useState('');
  const { locationsStore } = useStores();
  const handleLocationVisibilityToggle = (toggleValue: boolean, locationId: number) => {
    const location = locationsStore.getLocationById(locationId);
    const update = location.copyWith({ visible: toggleValue });
    locationsStore.updateLocation(update);
  };

  const columns: TableColumn<Row>[] = [
    {
      Header: 'Location',
      accessor: 'name',
      sortType: defaultStringPropertyCompare('name')
    },
    {
      Header: '',
      accessor: 'visible',
      disableSortBy: true,
      Cell: (cellParams) => (
        <VisibilityToggleCell
          onChange={(e) =>
            handleLocationVisibilityToggle(e.target.checked, cellParams.row.original.id)
          }
          isVisible={cellParams.row.original.visible}
        />
      )
    }
  ];

  const onTrackSearchAnalyticsEvent = useMemo(() => {
    return debounce((searchQuery) => {
      trackFilterUsageAnalyticsEvent({
        action: !searchQuery ? AnalyticEventAction.Clear : AnalyticEventAction.SearchedByLocations,
        value: FilterName.SearchLocations
      });
    }, DEBOUNCE_DELAY);
  }, []);

  const filteredLocations = locationsStore.locations.items.filter((location: PatientLocation) =>
    isSubStringCaseInsensitive(location.name, searchTerm)
  );
  const rows = filteredLocations.map((location: PatientLocation) => {
    return {
      id: location.id,
      name: location.name,
      visible: location.visible
    };
  });
  return (
    <div className="practice-locations-page">
      <SearchBar
        searchValue={searchTerm}
        onSearchChanged={(searchTerm) => {
          onTrackSearchAnalyticsEvent(searchTerm);
          setSearchTerm(searchTerm);
        }}
        placeholder="Search Locations"
        className="searchbar"
      />
      <Table columns={columns} rowData={rows} />
    </div>
  );
});
