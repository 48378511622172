import {
  FC,
  ForwardRefExoticComponent,
  ForwardedRef,
  ReactNode,
  forwardRef,
  RefAttributes
} from 'react';

import { Typography } from '@mui/material';

import { StyledOutlined, ButtonProps } from 'components/UIkit/atoms/Button/StyledButtons';
import { withSpacing, WithSpacingProps } from 'components/UIkit/theme/spacing';

interface InternalProps extends ButtonProps {
  children: ReactNode;
  color?: 'error';
  size?: 'small' | 'medium';
  isActive?: boolean;
  fullWidth?: boolean;
  forwardedRef?: ForwardedRef<HTMLButtonElement>;
}

type ExternalProps = Omit<InternalProps, 'forwardedRef'> & WithSpacingProps;

const Outlined: FC<InternalProps> = ({
  onClick,
  disabled,
  color,
  size = 'small',
  isActive = false,
  fullWidth = false,
  testHook,
  children,
  forwardedRef,
  id
}) => (
  <StyledOutlined
    onClick={onClick}
    disabled={disabled}
    color={color}
    size={size}
    ref={forwardedRef}
    data-test-hook={testHook}
    isActive={isActive}
    fullWidth={fullWidth}
    id={id}
  >
    <Typography variant="button-medium-outline">{children}</Typography>
  </StyledOutlined>
);

const OutlinedWithSpacing = withSpacing(Outlined);

export const OutlinedButton: FC<ExternalProps> = forwardRef<HTMLButtonElement, ExternalProps>(
  (props, ref) => <OutlinedWithSpacing {...props} forwardedRef={ref} />
) as ForwardRefExoticComponent<ExternalProps & RefAttributes<HTMLButtonElement>>;
