import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Save
  | AnalyticEventAction.Cancel
  | AnalyticEventAction.UpdateInterval
  | AnalyticEventAction.RemoveInterval
  | AnalyticEventAction.AddInterval
  | AnalyticEventAction.Open;

interface Properties {
  action: Action;
  virtual_page: 'call logger';
  value?: 'missing fields';
}

export const trackEditTimerModalAnalyticsEvent = ({
  action,
  virtual_page,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.EditTimerModal,
    properties: { action, virtual_page, ...optionalProps }
  });
};
