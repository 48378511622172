import { PathwayAnswerEventType } from 'analytics/events/pathway-answer';

import { PathwayOptionOutcome, PathwayQuestionTypes } from 'models/PathwayTemplates';

import {
  LightweightPathwayQuestionAction,
  LightweightPathwayQuestionAlertText
} from 'components/LightweightPathway/LightweightPathway.types';

export const IMMEDIATE_ATTENTION = 'IMMEDIATE ATTENTION';
export const ATTENTION_TODAY = 'ATTENTION TODAY';

export const pathwayQuestionTypeToAnalyticsTypeMap: Record<
  PathwayQuestionTypes,
  PathwayAnswerEventType
> = {
  [PathwayQuestionTypes.SINGLE]: 'radio',
  [PathwayQuestionTypes.MULTIPLE]: 'multiselection',
  [PathwayQuestionTypes.TEXT]: 'open text',
  [PathwayQuestionTypes.DATE]: 'date',
  [PathwayQuestionTypes.TEXT_ARRAY]: 'text array'
};

export const pathwayAlertToText: Partial<
  Record<PathwayOptionOutcome, LightweightPathwayQuestionAlertText>
> = {
  [PathwayOptionOutcome.CLINIC]: ATTENTION_TODAY,
  [PathwayOptionOutcome.CLINIC_EMERGENT]: IMMEDIATE_ATTENTION,
  [PathwayOptionOutcome.NONE]: ''
};

export const lightweightPathwayQuestionsRemoveActions: LightweightPathwayQuestionAction[] = [
  LightweightPathwayQuestionAction.RemoveLast,
  LightweightPathwayQuestionAction.RemoveLastOption,
  LightweightPathwayQuestionAction.RemoveOption
];
