import React from 'react';

import { Dialogs } from 'analytics/events/dialog';
import { ErrorWithUi } from 'errors';

import { useStores } from 'mobx/hooks/useStores';

import { DialogActionButtonProps, MessageDialog } from 'components/UIkit/atoms/Dialog';

export const GeneralErrorPopup = () => {
  const { uiStore } = useStores();
  const { error, errorDescription, clearError } = uiStore;
  const errorTitle = error instanceof ErrorWithUi ? error.ui.title : error?.message;

  const closeButtonProps = { text: 'OK', onClick: clearError };
  const secondaryActionProps: DialogActionButtonProps = uiStore.action ? closeButtonProps : null;
  const primaryActionProps: DialogActionButtonProps = uiStore.action
    ? { text: uiStore.action.actionText, onClick: uiStore.action.actionCallback }
    : closeButtonProps;

  return (
    <MessageDialog
      id={Dialogs.GeneralError}
      isOpen={!!error}
      title={errorTitle}
      handleClose={clearError}
      primaryActionProps={primaryActionProps}
      secondaryActionProps={secondaryActionProps}
    >
      {errorDescription?.map((str, index) => (
        <div key={index}>{str}</div>
      ))}
    </MessageDialog>
  );
};
