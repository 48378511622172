import { ErrorName, transformErrorName } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

export enum AuditAction {
  PATIENT_PAGE_VIEWED = 'patient_page_view',
  REFRESH_DATA_BUTTON_CLICKED = 'refresh_data_button_clicked'
}
interface Audit {
  action: AuditAction;
  actionDetails: object | null;
}

interface AuditPageView {
  pageName: string;
  tabName: string;
  queryParams: Record<string, string>;
}

const httpService = new HttpService('audit');

export default class AuditService {
  static async addAudit(audit: Audit): Promise<boolean> {
    return await httpService.post({
      url: API_URLS.LOG_AUDIT,
      data: { logs: [audit] },
      transformError: transformErrorName(ErrorName.AuditFailed)
    });
  }

  static async addPageViewAudit(data: AuditPageView): Promise<void> {
    return await httpService.post({
      url: API_URLS.AUDIT_PAGE_VIEW,
      data,
      transformError: (error) => {
        error.name = ErrorName.AuditFailed;
        error.ui.title = 'Audit Error - page view';
        return error;
      }
    });
  }
}
