import { useMemo } from 'react';

import debounce from 'debounce-promise';

import { useStores } from 'mobx/hooks/useStores';

import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';

import {
  WorkQueueOpenItemsSectionName,
  WorkQueueSections,
  WorkQueueSectionStatusData
} from 'views/WorkQueue/WorkQueue.types';

import { PaginatorProps } from 'components/Paginator';

//paginator & refresh button values
//omit totalPages because WorkQueueSections[T] has this property
interface UiReturnValueType extends Omit<PaginatorProps, 'totalPages'>, WorkQueueSectionStatusData {
  isRefreshButtonVisible: boolean;
  onRefreshButtonClick: () => void;
  isSectionPageLoading: boolean;
}

type UseWqSectionReturnValueType<T extends WorkQueueOpenItemsSectionName> = WorkQueueSections[T] &
  UiReturnValueType;

export function useWqOpenItemsSection<T extends WorkQueueOpenItemsSectionName>(
  sectionName: T
): UseWqSectionReturnValueType<T> {
  const { workQueueStore } = useStores();
  const { sectionStatusDataMap } = workQueueStore;
  const currentPage = workQueueStore.sectionCurrentPageMap[sectionName] + 1;

  const isRefreshButtonVisible =
    Boolean(sectionStatusDataMap[sectionName].newItemsCount) && currentPage > 1;

  const debouncedSectionPageChange = useMemo(
    () => debounce(() => workQueueStore.handleSectionPageChange(sectionName), DEBOUNCE_DELAY),
    [workQueueStore, sectionName]
  );

  const onPageChange = (currentPage: number) => {
    if (currentPage - 1 === workQueueStore.sectionCurrentPageMap[sectionName]) {
      return;
    }

    workQueueStore.setSectionCurrentPage(sectionName, currentPage - 1);
    workQueueStore.setCurrentLoadingSections(sectionName);

    debouncedSectionPageChange();
  };

  const onRefreshButtonClick = () => {
    workQueueStore.setSectionCurrentPage(sectionName, 0);
    workQueueStore.handleSectionPageChange(sectionName);
  };

  return {
    ...workQueueStore.sectionDataMap[sectionName],
    ...workQueueStore.sectionStatusDataMap[sectionName as WorkQueueOpenItemsSectionName],
    onPageChange,
    currentPage,
    isRefreshButtonVisible,
    onRefreshButtonClick,
    isSectionPageLoading: workQueueStore.currentLoadingSections.has(sectionName), //fetching section page after changing page manually
    testHookPrefix: `${sectionName}-paginator`
  };
}
