// @ts-strict-ignore
import { useState } from 'react';

import Icon from 'components/Icons/Icon';
import { Tooltip, TooltipSelect } from 'components/Tooltip';
import { TextIconButton } from 'components/UIkit/atoms/Button';

import './FinancialAssistanceSelector.scss';

interface IFinancialAssistanceSelectorProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export const FinancialAssistanceSelector = ({
  value,
  onChange,
  disabled
}: IFinancialAssistanceSelectorProps) => {
  const [isFinancialAssistanceOpen, setIsFinancialAssistanceOpen] = useState(false);

  const onClickDo = (newValue: boolean) => {
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  const options = [
    {
      text: (
        <span>
          <b>with</b> financial assistance
        </span>
      ),
      value: true,
      isSelected: value === true,
      onClick: () => {
        onClickDo(true);
        setIsFinancialAssistanceOpen(false);
      }
    },
    {
      text: (
        <span>
          <b>without</b> financial assistance
        </span>
      ),
      value: false,
      isSelected: value === false,
      onClick: () => {
        onClickDo(false);
        setIsFinancialAssistanceOpen(false);
      }
    }
  ];
  return (
    <div className="financial-assistance-selector">
      <Tooltip
        disabled={disabled}
        label={
          <TextIconButton
            onClick={() => setIsFinancialAssistanceOpen((prevState) => !prevState)}
            icon={<Icon.Chevron />}
            iconPlacement="right"
            size="large"
          >
            {`${value ? ' with' : ' without'} financial assistance`}&nbsp;
          </TextIconButton>
        }
        controller={{
          visible: isFinancialAssistanceOpen,
          onClickOutside: () => setIsFinancialAssistanceOpen(false)
        }}
      >
        <TooltipSelect options={options} />
      </Tooltip>
    </div>
  );
};
