import {
  PathwayOptionAlert,
  PathwayType,
  QuestionOptionAlert,
  QuestionOptionSuggestedPathway,
  SuggestedPathwayUrgency
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

export const PATHWAY_ADD_QUESTION_BUTTON_ID = 'pathway-add-question-button';
export const PATHWAY_ADD_SUB_QUESTION_BUTTON_ID = 'pathway-add-sub-question-button';
export const PATHWAY_QUESTION_OPTION_ALERTS_SELECT = 'pathway-question-option-alerts-select';
export const PATHWAY_QUESTION_OPTION_SUGGESTED_SELECT = 'pathway-question-option-suggested-select';
export const PATHWAY_QUESTION_OPTION_SUGGESTED_URGENCY_SELECT =
  'pathway-question-option-suggested-urgency-select';
export const REMOVE_PATHWAY_QUESTION_POPUP_ID = 'remove-pathway-question-popup';
export const REMOVE_PATHWAY_CATEGORY_POPUP_ID = 'remove-pathway-category-popup';
export const PATHWAY_CONTAINER_BLOCK_ACTION_BAR_ID = 'pathway-container-block-action-bar';
export const INTRAVENOUS_THERAPY_OPTION_ID = '8972b969-05d4-4e42-8698-f87921e209c1';
export const ORAL_THERAPY_OPTION_ID = '82d9bce4-bf31-4b0b-9fb3-74a2f3144c07';
export const IMMUNOTHERAPY_OPTION_ID = '4a0af766-f5cf-48da-92d4-d234c8e20fbb';
export const RADIATION_THERAPY_OPTION_ID = '9cb5a187-a5c0-4de3-a105-174a479d4f16';
export const HOSPICE_CARE_OPTION_ID = '4db7e5a0-6005-4019-9980-2298ae26ad17';
export const OBSERVATION_ONLY_OPTION_ID = '81be9892-2598-4e0f-a891-44a9208754d9';

export const pathwayQuestionAlertOptions: PathwayOptionAlert[] = [
  PathwayOptionAlert.NONE,
  PathwayOptionAlert.CLINIC,
  PathwayOptionAlert.CLINIC_EMERGENT
];

export const defaultPathwayQuestionOptionSuggestedPathway: QuestionOptionSuggestedPathway = {
  pathway: { value: '', label: '' },
  urgency: SuggestedPathwayUrgency.Low
};

export const defaultPathwayQuestionOptionAlert: QuestionOptionAlert = {
  value: PathwayOptionAlert.NONE,
  label: ''
};

export const pathwayTypeOptions = [
  { label: 'Symptom', value: PathwayType.Symptom },
  { label: 'Care Management', value: PathwayType.CareManagement }
];
