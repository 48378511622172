import { FC, ReactNode } from 'react';

import { getBadgeForTicket } from 'utils/TicketBadgeUtils';

import { SymptomTicketUrgency, OperatorTicketUrgency } from 'models/OperatorTicket';
import { TicketClass } from 'models/Ticket';

import { TicketTypeKind } from 'models/TicketTypes';

import Icon from 'components/Icons/Icon';

import { SimpleTooltip } from 'components/Tooltip';

import './TicketRowIcon.scss';

export enum TicketRowIconType {
  OPERATOR_STANDARD = 'operator-standard',
  OPERATOR_HIGH = 'operator-high',
  SYMPTOM_REPORT_NURSE_REVIEW = 'symptom-nurse-review',
  SYMPTOM_REPORT_ATTENTION_TODAY = 'symptom-attention-today',
  SYMPTOM_REPORT_IMMEDIATE_ATTENTION = 'symptom-immediate-attention',
  TASK = 'task',
  MILD = 'mild'
}

const iconsMap: Record<TicketRowIconType, ReactNode> = {
  [TicketRowIconType.TASK]: <Icon.CalendarEmpty />,
  [TicketRowIconType.OPERATOR_STANDARD]: getBadgeForTicket(
    TicketClass.operator,
    TicketTypeKind.other,
    OperatorTicketUrgency.Standard
  ),
  [TicketRowIconType.OPERATOR_HIGH]: getBadgeForTicket(
    TicketClass.operator,
    TicketTypeKind.other,
    OperatorTicketUrgency.High
  ),
  [TicketRowIconType.SYMPTOM_REPORT_IMMEDIATE_ATTENTION]: getBadgeForTicket(
    TicketClass.operator,
    TicketTypeKind.symptom,
    SymptomTicketUrgency.ImmediateAttention
  ),
  [TicketRowIconType.SYMPTOM_REPORT_NURSE_REVIEW]: getBadgeForTicket(
    TicketClass.operator,
    TicketTypeKind.symptom,
    SymptomTicketUrgency.NurseReview
  ),
  [TicketRowIconType.SYMPTOM_REPORT_ATTENTION_TODAY]: getBadgeForTicket(
    TicketClass.operator,
    TicketTypeKind.symptom,
    SymptomTicketUrgency.AttentionToday
  ),
  [TicketRowIconType.MILD]: getBadgeForTicket(TicketClass.patient, undefined, null)
};

interface Props {
  children?: ReactNode;
  type?: TicketRowIconType;
  header?: string;
}

const TicketRowIcon: FC<Props> = ({ type, header, children }) => {
  const icon = (
    <div className="ticket-row-icon">
      {type && iconsMap[type]}
      {children ? <div className="ticket-row-icon-content">{children}</div> : null}
    </div>
  );

  function getTooltipText() {
    switch (type) {
      case TicketRowIconType.OPERATOR_STANDARD:
        return 'Standard';
      case TicketRowIconType.OPERATOR_HIGH:
        return 'High';
      case TicketRowIconType.SYMPTOM_REPORT_NURSE_REVIEW:
        return 'Nurse Review';
      case TicketRowIconType.SYMPTOM_REPORT_ATTENTION_TODAY:
        return 'Attention Today';
      case TicketRowIconType.SYMPTOM_REPORT_IMMEDIATE_ATTENTION:
        return 'Immediate Attention';
    }
  }

  const tooltipText = getTooltipText();
  const iconElement = !tooltipText ? (
    icon
  ) : (
    <SimpleTooltip placement="bottom-start" title={<div className="p-3">{tooltipText}</div>}>
      {icon}
    </SimpleTooltip>
  );
  return (
    <div className="ticket-row-icon-container">
      {header && <span>{header}</span>}
      {iconElement}
    </div>
  );
};

export default TicketRowIcon;
