// @ts-strict-ignore
import { css, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { observer } from 'mobx-react';

import Patient from 'models/Patient';
import ScheduledProtocol from 'models/ScheduledProtocol';

import { ProtocolDetails } from 'views/Widgets/ProtocolDetails';
import { IProtocolOption, protocolOptions } from 'views/Widgets/ReportPotocolInput';

import Icon from 'components/Icons/Icon';

import { Tooltip, TooltipSelect, ITooltipOption } from 'components/Tooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';

import './PatientProtocolPickerCell.scss';

interface IPatientProtocolPickerCell {
  patient: Patient;
  scheduledProtocol: ScheduledProtocol;
  error: boolean;
  shakyError: boolean;
  hasMobile: boolean;
  onProtocolSelected: (protocol: IProtocolOption, patient: Patient) => void;
}

export const PatientProtocolPickerCell = observer(
  ({
    patient,
    scheduledProtocol,
    error,
    shakyError,
    hasMobile,
    onProtocolSelected
  }: IPatientProtocolPickerCell) => {
    const getLabel = () => {
      return !scheduledProtocol ? (
        <StyledTypography variant="body1">
          No Protocol <StyledChevronIcon />
        </StyledTypography>
      ) : (
        <ProtocolDetails protocol={scheduledProtocol} />
      );
    };

    const isSelected = (selectedValue: IProtocolOption) => {
      if (!scheduledProtocol) {
        return false;
      }
      return scheduledProtocol.type === selectedValue.type;
    };

    const options = protocolOptions
      .filter((option) => !option.requiresMobile || hasMobile)
      .map(
        (option: IProtocolOption): ITooltipOption => ({
          text: option.label,
          isSelected: isSelected(option),
          onClick: () => onProtocolSelected(option, patient)
        })
      );

    return (
      <StyledGrid container justifyContent="end" hasShakyError={shakyError} hasError={error}>
        <div className="report-protocol-select-container">
          <Tooltip trigger={TooltipTrigger.CLICK} label={getLabel()}>
            <TooltipSelect options={options} />
          </Tooltip>
        </div>
      </StyledGrid>
    );
  }
);

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'hasShakyError' && prop !== 'hasError'
})<{ hasShakyError: boolean; hasError: boolean }>(
  ({ theme, hasShakyError, hasError }) => css`
    color: ${hasError ? theme.palette.error.dark : theme.palette.primary.main};

    ${hasShakyError &&
    css`
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
    `}
  `
);

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    &:hover {
      color: ${theme.palette.primary.dark};
    }
  `
);

const StyledChevronIcon = styled(Icon.Chevron)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(4)};
  `
);
