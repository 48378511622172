// @ts-strict-ignore
import {
  CareIntervalResponse,
  CareTimeDataResponse,
  QuickAdditionResponse
} from 'fetchers/responses/care-time-data.response';

import { CareTimeData } from 'models/CareTime';

import { DurationInterval } from 'models/DurationInterval';
import { QuickAddition } from 'models/QuickAddition';

export class CareTimeParser {
  static parseCareTimeData = (careTimeData: CareTimeDataResponse): CareTimeData => ({
    careIntervals: CareTimeParser.parseCareIntervals(careTimeData.careIntervals),
    quickAdditions: CareTimeParser.parseQuickAdditions(careTimeData.quickAdditions)
  });

  static parseCareIntervals = (careIntervals: CareIntervalResponse[]): DurationInterval[] =>
    careIntervals.map(
      (careInterval) =>
        new DurationInterval(
          { id: careInterval.doctorId },
          new Date(careInterval.startedAt),
          new Date(careInterval.endedAt),
          careInterval.id
        )
    );

  static parseQuickAdditions = (quickAdditions: QuickAdditionResponse[]): QuickAddition[] =>
    quickAdditions.map((quickAddition) => new QuickAddition(quickAddition));
}
