import { useLocalStorage } from 'react-use';

import { UserPreferences } from 'models/UserModel';

export const USER_PREFERENCES_LOCAL_STORAGE_KEY = 'userPreferences';

const defaultUserPreferences: UserPreferences = {
  copyNotesToClipboard: false,
  generateSmartSummary: true
};

export const useUserPreferences = () => {
  const [userPreferences, setUserPreferences] = useLocalStorage<UserPreferences>(
    USER_PREFERENCES_LOCAL_STORAGE_KEY,
    defaultUserPreferences
  );

  return { userPreferences, setUserPreferences };
};
