// @ts-strict-ignore

import React, { FC, useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { pluralize } from 'utils/StringUtils';

import { API_URLS } from 'constants/apiUrls';
import { FEATURES } from 'constants/features';

import 'views/Filters/PageFilters.scss';
import {
  CreateEditEpisodeModal,
  NEW_EPISODE_ID
} from 'views/Pages/PracticeManagement/Episodes/CreateEditEpisodeModal';
import EditCreateRoleModal from 'views/Pages/PracticeManagement/Episodes/EditCreateRoleModal';
import EditTaskTemplateModal from 'views/Pages/PracticeManagement/Episodes/EditTaskTemplateModal';

import { NoResultForFilter } from 'views/Pages/PracticeManagement/PracticeManagerPage.shared';
import CollapsibleSection from 'views/Widgets/CollapsibleSection';

import Icon from 'components/Icons/Icon';
import FixedLoader from 'components/Loaders/FixedLoader';
import Loading from 'components/Loaders/Loading';
import { CardRow } from 'components/Surfaces/Card';
import { OutlinedIconButton } from 'components/UIkit/atoms/Button';

import CreateTaskTemplateModal from './CreateTaskTemplateModal';
import EpisodeActions from './EpisodeActions';
import EpisodeManagementFilters from './EpisodeManagementFilters';
import TaskTemplateRow from './TaskTemplateRow';

import './ManagePracticeEpisodesPage.scss';

interface EpisodeTaskTemplatesProps {
  episodeId: number;
  onEditClick: (id: number) => void;
}

const EpisodeTaskTemplates: FC<EpisodeTaskTemplatesProps> = observer(
  ({ episodeId, onEditClick }) => {
    const { episodeManagementStore } = useStores();
    const { getEpisodeTaskTemplates } = episodeManagementStore;
    const taskTemplates = getEpisodeTaskTemplates(episodeId);
    const isLoading = episodeManagementStore.isFetchingEpisode(episodeId);
    if (isLoading) {
      return (
        <div className="templates-loader">
          <Loading />
        </div>
      );
    }

    if (taskTemplates.length === 0 && !isLoading) {
      return (
        <div className="tasks-empty-state mt-4">
          This episode does not contain any tasks yet. Click <i>Add Task</i> above to create the
          first one.
        </div>
      );
    }

    return (
      <div>
        {taskTemplates.map(({ id }) => (
          <CardRow key={id}>
            <TaskTemplateRow taskTemplateId={id} onEdit={onEditClick} />
          </CardRow>
        ))}
      </div>
    );
  }
);

const EpisodeEmptyState: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className="no-results" data-test-hook="episodesEmptyState">
      No Episodes •
      <span onClick={onClick} className="enroll-episode-trigger">
        &nbsp;Create a New Episode
      </span>
    </div>
  );
};

const ManagePracticeEpisodesPage: FC = () => {
  const {
    episodeManagementStore: {
      fetchAndInitFilteredEpisodes,
      episodesByNameFilter,
      searchSingleEpisode,
      toggleEpisodeIds,
      isInEpisodeIds,
      allEpisodes,
      resetStore,
      isFilterQueryInProgress,
      deleteTaskRole
    },
    settingsStore
  } = useStores();
  const isEpisodesLoading = useNetworkLoading(API_URLS.EPISODES);

  const [editedEpisodeId, setEditedEpisodeId] = useState<number>(null);
  const [editedRoleId, setEditedRoleId] = useState<number>(null);
  const [isEditTaskTemplateModalOpen, setIsEditTaskTemplateModalOpen] = useState(false);
  const [isCreateTaskTemplateModalOpen, setIsCreateTaskTemplateModalOpen] = useState(false);
  const [isEditCreateRoleModalOpen, setIsEditCreateRoleModalOpen] = useState(false);
  const [taskTemplateId, setTaskTemplateId] = useState<number | null>(null);
  const [selectedEpisodeId, setSelectedEpisodeId] = useState<number | null>(null);
  const [isCreateEditEpisodeModalOpen, setIsCreateEditEpisodeModalOpen] = useState(false);

  const onCreateEpisodeClick = () => {
    setEditedEpisodeId(NEW_EPISODE_ID);
    setIsCreateEditEpisodeModalOpen(true);
  };

  useEffect(
    function loadEpisodes() {
      const fetchAllEpisodes = async () => {
        await fetchAndInitFilteredEpisodes();
      };

      fetchAllEpisodes();

      return () => {
        resetStore();
      };
    },
    [fetchAndInitFilteredEpisodes, resetStore]
  );

  const handleEpisodeExpanded = (episodeId: number) => () => {
    toggleEpisodeIds(episodeId);
    return searchSingleEpisode(episodeId);
  };

  const handleEditClicked = (id: number) => {
    setIsEditTaskTemplateModalOpen(true);
    setTaskTemplateId(id);
  };

  const handleCreateClicked = (episodeId: number) => {
    setIsCreateTaskTemplateModalOpen(true);
    setSelectedEpisodeId(episodeId);
  };

  const renderLoadingOrEmptyState = () => {
    if (isEpisodesLoading) {
      return <FixedLoader />;
    }

    if (allEpisodes.length === 0) {
      return <EpisodeEmptyState onClick={onCreateEpisodeClick} />;
    }

    if (episodesByNameFilter.length === 0) {
      return <NoResultForFilter />;
    }
  };
  const onDeleteRole = async (id: number) => {
    await deleteTaskRole(id);
    setIsEditCreateRoleModalOpen(false);
  };

  const openEditCreateRoleModal = (editedRoleId: number) => {
    setIsEditCreateRoleModalOpen(true);
    setEditedRoleId(editedRoleId);
  };

  return (
    <div className="practice-manager-episodes-page">
      <CreateTaskTemplateModal
        isOpen={isCreateTaskTemplateModalOpen}
        isInternalModalOpen={isEditCreateRoleModalOpen}
        episodeId={selectedEpisodeId}
        onCancel={() => setIsCreateTaskTemplateModalOpen(false)}
        onSubmit={() => setIsCreateTaskTemplateModalOpen(false)}
        onEditCreateRole={openEditCreateRoleModal}
      />

      <EditCreateRoleModal
        roleId={editedRoleId}
        onClose={() => setIsEditCreateRoleModalOpen(false)}
        onDelete={onDeleteRole}
        isOpen={isEditCreateRoleModalOpen}
        resetDataAfterClose={() => setEditedRoleId(null)}
      />

      <EditTaskTemplateModal
        isOpen={isEditTaskTemplateModalOpen}
        isInternalModalOpen={isEditCreateRoleModalOpen}
        taskTemplateId={taskTemplateId}
        onEditCreateRole={openEditCreateRoleModal}
        onCancel={() => setIsEditTaskTemplateModalOpen(false)}
        onSubmit={() => setIsEditTaskTemplateModalOpen(false)}
      />

      <CreateEditEpisodeModal
        episodeId={editedEpisodeId}
        isOpen={isCreateEditEpisodeModalOpen}
        onClose={() => setIsCreateEditEpisodeModalOpen(false)}
        resetDataAfterClose={() => setEditedEpisodeId(null)}
      />

      {settingsStore.hasFeature(FEATURES.PM_CREATE_EPISODES) && (
        <div className="top-tab-section">
          <OutlinedIconButton
            onClick={onCreateEpisodeClick}
            icon={<Icon.Plus />}
            variant="secondary"
          >
            New Episode
          </OutlinedIconButton>
        </div>
      )}

      <EpisodeManagementFilters />
      {isFilterQueryInProgress && <FixedLoader background />}
      {renderLoadingOrEmptyState()}
      {episodesByNameFilter.map(({ id, duration, name }) => {
        return (
          <CollapsibleSection
            className="episode-row"
            name={name}
            trigger={`${name} (${duration} ${pluralize('Month', duration)})`}
            onExpanded={handleEpisodeExpanded(id)}
            onCollapsed={() => toggleEpisodeIds(id)}
            trailing={
              <EpisodeActions
                onEditClick={() => {
                  setEditedEpisodeId(id);
                  setIsCreateEditEpisodeModalOpen(true);
                }}
                episodeId={id}
                onCreateClick={handleCreateClicked}
              />
            }
            initiallyOpen={isInEpisodeIds(id)}
            key={id}
            testHook={`collapsibleEpisodeRow_${id}`}
          >
            <EpisodeTaskTemplates episodeId={id} onEditClick={handleEditClicked} />
          </CollapsibleSection>
        );
      })}
    </div>
  );
};

export default observer(ManagePracticeEpisodesPage);
