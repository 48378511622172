import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import DepartmentsParser from 'parsers/DepartmentsParser';

import Department, { BasicDepartmentInfo } from 'models/Department';

const httpService = new HttpService('Departments');

class DepartmentsFetcher {
  static async fetchDepartments() {
    return await httpService.get<Department[]>({
      url: API_URLS.DEPARTMENTS,
      transformResponse: DepartmentsParser.parseDepartments
    });
  }

  static async fetchInstitutions() {
    return await httpService.get<BasicDepartmentInfo[]>({
      url: API_URLS.INSTITUTIONS
    });
  }
}

export default DepartmentsFetcher;
