// @ts-strict-ignore
import { observable, makeObservable } from 'mobx';

import {
  NumericReportedCauseFromServer,
  ReportedCauseFromServer,
  SeverityReportedCauseFromServer
} from 'parsers/ParseServerResponseService';

export enum CauseSeverity {
  Mild = 1,
  Moderate = 2,
  Severe = 3,
  Unbearable = 4
}

export enum ReportCauseChange {
  NEW = 'NEW',
  UP = 'UP',
  DOWN = 'DOWN',
  NO_CHANGE = 'NO_CHANGE',
  FIRST_REPORT = 'FIRST_REPORT'
}

export const SeveritiesText = {
  [CauseSeverity.Mild]: 'Mild',
  [CauseSeverity.Moderate]: 'Moderate',
  [CauseSeverity.Severe]: 'Severe',
  [CauseSeverity.Unbearable]: 'Unbearable'
};

// Type guard for SeverityReportedCauseFromServer
export function isSeverityReportedCause(
  cause: ReportedCauseFromServer
): cause is SeverityReportedCauseFromServer {
  return (cause as SeverityReportedCauseFromServer).severity !== undefined;
}

// Type guard for NumericReportedCauseFromServer
export function isNumericReportedCause(
  cause: ReportedCauseFromServer
): cause is NumericReportedCauseFromServer {
  return (cause as NumericReportedCauseFromServer).value !== undefined;
}

export type ReportedCause = SeverityReportedCause | NumericReportedCause;

abstract class BaseReportedCause {
  @observable causeId: number;

  @observable change?: ReportCauseChange;

  protected constructor(id: number) {
    makeObservable(this);
    this.causeId = id;
  }
}

export default class SeverityReportedCause extends BaseReportedCause {
  @observable severity: number;

  constructor(id: number, severity: number = null) {
    super(id);
    makeObservable(this);
    this.severity = severity;
  }

  static getSeverityText(severity: CauseSeverity) {
    return SeveritiesText[severity];
  }
}

export class NumericReportedCause extends BaseReportedCause {
  @observable value: number | 'iDontKnow';

  constructor(id: number, value: number | 'iDontKnow' = null) {
    super(id);
    makeObservable(this);
    this.value = value;
  }
}
