// @ts-strict-ignore
import { FC, SyntheticEvent, useMemo } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackTaskDateFilterAnalyticsEvent } from 'analytics/events/task-date-filter';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { isToday, startOfToday } from 'utils/DateUtils';

import IconButton from 'components/Buttons/IconButton';
import LabelDatePicker from 'components/DateTimePicker/LabelDateTimePicker';
import Icon from 'components/Icons/Icon';

import './TicketsSectionTasksDueFilters.scss';

interface Props {
  onFromDateChanged: (date: Date | null) => void;
  onToDateChanged: (date: Date | null) => void;
}

const ClearFilterButton: FC<{ onClick: () => any }> = ({ onClick }) => (
  <div className="ml-1 d-flex align-self-center">
    <IconButton onClick={onClick}>
      <Icon.Cancel />
    </IconButton>
  </div>
);

const TicketsSectionTasksDueFilters: FC<Props> = ({ onFromDateChanged, onToDateChanged }) => {
  const { ticketFiltersStore } = useStores();
  const { filters } = ticketFiltersStore;

  const clearFromDate = () => {
    trackTaskDateFilterAnalyticsEvent({
      action: AnalyticEventAction.Remove,
      value: 'from'
    });

    ticketFiltersStore.setQueryFromDate(null);
    onFromDateChanged(null);
  };

  const clearToDate = () => {
    trackTaskDateFilterAnalyticsEvent({ action: AnalyticEventAction.Remove, value: 'until' });

    // end date is mandatory, so clear actually reset back to "today"
    const today = startOfToday();
    ticketFiltersStore.setQueryToDate(today);
    onToDateChanged(today);
  };

  const showClearEndDate = useMemo(
    () => filters.toDate && !isToday(filters.toDate),
    [filters.toDate]
  );

  return (
    <div className="d-flex flex-column">
      <div className="d-flex">
        <span className="task-date-filters-non-clickable">From Due Date:</span>
        <div className="ml-2 d-flex">
          <LabelDatePicker
            date={filters.fromDate}
            onSelect={(date) => {
              trackTaskDateFilterAnalyticsEvent({ action: AnalyticEventAction.Add, value: 'from' });
              onFromDateChanged(date);
            }}
            placeholder="Any"
            labelSize="large"
          />
          {filters.fromDate && <ClearFilterButton onClick={clearFromDate} />}
        </div>
      </div>
      <div
        className="d-flex align-items-center"
        onClick={(e: SyntheticEvent) => e.stopPropagation()}
      >
        <span className="task-date-filters-non-clickable">Until Due Date:</span>

        <div className="ml-2 d-flex">
          <LabelDatePicker
            date={filters.toDate}
            onSelect={(date) => {
              trackTaskDateFilterAnalyticsEvent({
                action: AnalyticEventAction.Add,
                value: 'until'
              });
              onToDateChanged(date);
            }}
            labelSize="large"
          />
        </div>
        {showClearEndDate && <ClearFilterButton onClick={clearToDate} />}
      </div>
    </div>
  );
};

export default observer(TicketsSectionTasksDueFilters);
