// @ts-strict-ignore
import { FC } from 'react';

import { styled, css } from '@mui/material';

import classNames from 'classnames';

import { Question } from 'views/Widgets/icons/Question';

import Icon from 'components/Icons/Icon';
import { SimpleTooltip } from 'components/Tooltip';

import { ReportTableCellData, ReportTableIcon, ReportTableRowData } from './ReportTable.model';

import './ReportsTable.scss';

const getCellIcon = (icon: ReportTableIcon) => {
  switch (icon) {
    case ReportTableIcon.phone:
      return <StyledLandPhoneIcon width="20" height="20" />;
    case ReportTableIcon.pill:
      return <Icon.Pill />;
    case ReportTableIcon.question:
      return <Question />;
    case ReportTableIcon.check:
      return (
        <div className="black">
          <Icon.CheckMark />
        </div>
      );
    case ReportTableIcon.x:
      return (
        <div className="black">
          <Icon.XThin />
        </div>
      );
    default:
      return 'icon';
  }
};

const StyledLandPhoneIcon = styled(Icon.LandPhone)(
  ({ theme }) => css`
    color: ${theme.palette.natural.white};
  `
);

export enum TdShape {
  Circle,
  Rectangle
}

interface ReportsTdProps {
  data: ReportTableCellData;
  shape: TdShape;
}

export const ReportsTd: FC<ReportsTdProps> = ({ data, shape }) => {
  if (!data) {
    return <td />;
  }

  const content = (
    <div
      className={classNames('content', `color-${data.color}`, {
        rectangle: shape === TdShape.Rectangle
      })}
    >
      {typeof data.iconContent === 'number' && getCellIcon(data.iconContent)}
      {data.content}
    </div>
  );

  return (
    <td className={classNames('td-severity innerCell position-relative')}>
      {data.tooltip ? (
        <SimpleTooltip placement="top" title={<div className="p-3">{data.tooltip}</div>}>
          {content}
        </SimpleTooltip>
      ) : (
        content
      )}
    </td>
  );
};

export const SectionHeaderTr: FC<{ text: string; colSpan: number }> = ({ text, colSpan }) => (
  <tr className="section-header">
    <td>
      <div className="mr-3">{text}</div>
      <div className="separator" />
    </td>
    <td colSpan={colSpan}>
      <div className="separator" />
    </td>
  </tr>
);

interface ReportsTrProps {
  text: string;
  data: ReportTableRowData;
  cellShape?: TdShape;
}

export const ReportsTr: FC<ReportsTrProps> = ({ text, data, cellShape = TdShape.Circle }) => (
  <tr>
    <td>{text}</td>
    {data.map((cellData, index) => (
      <ReportsTd data={cellData} key={index} shape={cellShape} />
    ))}
  </tr>
);
