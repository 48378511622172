// @ts-strict-ignore
import { FC } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { useDeleteTicketTypeOrSubType } from 'views/Pages/PracticeManagement/TicketTypes/useDeleteTicketTypeOrSubType';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  ticketSubTypeId: number;
  onAction: () => void;
  onCancel: () => void;
  isOpen: boolean;
  isChildModal?: boolean;
}

export const DeleteTicketSubTypePopup: FC<Props> = ({
  ticketSubTypeId,
  onAction,
  onCancel,
  isOpen,
  isChildModal = false
}) => {
  const { deleteTicketSubType, ticketSubType } = useDeleteTicketTypeOrSubType(
    ticketSubTypeId,
    onAction
  );

  return (
    <MessageDialog
      id={Dialogs.DeleteTicketSubType}
      isOpen={isOpen}
      title={`Delete ${ticketSubType?.name ? `"${ticketSubType?.name}"?` : ''}`}
      handleClose={onCancel}
      testHook={`${ticketSubTypeId}${isChildModal ? 'child_modal' : ''}`}
      primaryActionProps={{ text: 'Delete', onClick: deleteTicketSubType }}
      secondaryActionProps={{ text: 'Cancel', onClick: onCancel }}
    >
      It will no longer be possible to create new tickets with this ticket type. Tickets that have
      already been created with this ticket type will not be deleted.
    </MessageDialog>
  );
};
