import { FC, useMemo, useState } from 'react';

import { Box, css, styled } from '@mui/material';
import {
  trackDropdownFilterUsageAnalyticsEvent,
  trackSearchByNameOrMrnFilterUsageAnalyticsEvent
} from 'analytics/events/filter-usage';

import debounce from 'debounce-promise';

import { ActionMeta } from 'react-select';

import {
  TICKET_TYPE_CALLBACK_ID,
  TICKET_TYPE_EPISODES_ID,
  TICKET_TYPE_NON_EPISODES_ID,
  TICKET_TYPE_ORAL_ONCO_REPORT_ID,
  TICKET_TYPE_SYMPTOM_ASSESSMENT_REPORT_ID
} from 'constants/itemTypes.const';

import SearchBar from 'views/Dashboard/SearchBar';
import {
  CliniciansFilterField,
  EpisodesFilterField,
  LocationsFilterField,
  ProvidersFilterField,
  ResolvedDateFilterField,
  TagsFilterField,
  TicketTypeFilterField
} from 'views/Filters/FilterFields';
import { FilterName, ResolvedTicketsFiltersType } from 'views/Filters/filters.types';
import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';

import { ValueOf } from 'views/Pages/CareManagement/CareManagementPage.utils';
import { WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

interface Props {
  filters: ResolvedTicketsFiltersType;
  updateFiltersByKey: (key: keyof ResolvedTicketsFiltersType) => (value: any) => void;
}

export const WorkQueueResolvedItemsFilters: FC<Props> = ({ filters, updateFiltersByKey }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const onDropdownFilterChange = (
    values: ValueOf<ResolvedTicketsFiltersType>,
    filterKey: keyof ResolvedTicketsFiltersType,
    filterName: FilterName,
    actionMeta: ActionMeta<any>
  ) => {
    trackDropdownFilterUsageAnalyticsEvent(actionMeta, filterName, WorkQueueTabName.ResolvedItems);
    updateFiltersByKey(filterKey)(values);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((searchQuery: string) => {
        updateFiltersByKey('nameOrMrn')(searchQuery);
        trackSearchByNameOrMrnFilterUsageAnalyticsEvent(
          searchQuery,
          WorkQueueTabName.ResolvedItems
        );
      }, DEBOUNCE_DELAY),
    [updateFiltersByKey]
  );

  return (
    <StyledContainer>
      <SearchBar
        className="search"
        placeholder="Search by Name or MRN"
        searchValue={searchTerm}
        onSearchChanged={(value) => {
          setSearchTerm(value);
          debouncedSearch(value);
        }}
        testHook="search-by-name-or-mrn"
      />

      <ResolvedDateFilterField
        value={filters.resolvedDate!}
        onChange={(option, actionMeta) => {
          if (option?.value === filters.resolvedDate?.value) {
            return;
          }

          onDropdownFilterChange(option, 'resolvedDate', FilterName.ResolvedDate, actionMeta);
        }}
      />

      <CliniciansFilterField
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(values, 'resolvedBy', FilterName.ResolvedBy, actionMeta)
        }
        value={filters.resolvedBy!}
        label="Resolved By"
        withUnassignedOption={false}
      />

      <TicketTypeFilterField
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(values, 'ticketType', FilterName.ItemTypes, actionMeta)
        }
        value={filters.ticketType!}
        itemCategoryIdsOrder={[
          TICKET_TYPE_ORAL_ONCO_REPORT_ID,
          TICKET_TYPE_SYMPTOM_ASSESSMENT_REPORT_ID,
          TICKET_TYPE_CALLBACK_ID,
          TICKET_TYPE_EPISODES_ID,
          TICKET_TYPE_NON_EPISODES_ID
        ]}
      />

      <TagsFilterField
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(values, 'patientTags', FilterName.PatientTags, actionMeta)
        }
        value={filters.patientTags!}
      />

      <ProvidersFilterField
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(values, 'providers', FilterName.Providers, actionMeta)
        }
        value={filters.providers!}
      />

      <LocationsFilterField
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(values, 'locations', FilterName.Locations, actionMeta)
        }
        value={filters.locations!}
      />

      <EpisodesFilterField
        value={filters!.episodeIds!}
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(values, 'episodeIds', FilterName.Episodes, actionMeta)
        }
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: ${theme.spacing(20)};

    > * {
      flex: 1 1 calc(25% - 20px);
    }

    .styled-select .styled-select__multi-value__label {
      // keep muli-select filters in a single line. see https://expain.atlassian.net/browse/EH-3722
      max-width: 118px;
    }
  `
);
