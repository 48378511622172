import { EllipsisLoader } from 'views/Widgets/EllipsisLoader';

import './HorizontalLoader.scss';

const HorizontalLoader = () => (
  <div className="horizontal-loader">
    <EllipsisLoader />
  </div>
);

export default HorizontalLoader;
