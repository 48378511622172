import {
  TEST_PATHWAY_ID,
  TEST_PATHWAY_OPTION_ID_IN_MULTIPLE_QUESTION
} from 'tests/models/pages/patient-page/patient-page.constants';
import { PatientPageTabs } from 'tests/models/pages/patient-page/patient-page.types';

import { IncludeSummaryInEmrNote } from 'models/Call';

export const patientPageTestSelectors = {
  tab: (tab: PatientPageTabs) => `${tab}-tab`,
  actionsButton: 'patient-actions-button',
  editPatientButton: 'edit-patient-button',
  actions: {
    logCallOption: 'log-call-option',
    noPathwayOption: 'no-pathway-option',
    pathwayOption: (pathwayId: string = TEST_PATHWAY_ID) => `${pathwayId}-option`
  },
  pathwayView: {
    pathwayAnswerInMultipleQuestion: (
      pathwayMultipleQuestionOptionId: string = TEST_PATHWAY_OPTION_ID_IN_MULTIPLE_QUESTION
    ) => `${pathwayMultipleQuestionOptionId}_label`
  },
  callLogger: {
    container: 'callLogger',
    submitButton: 'call-logger-submit-button',
    finishCallButton: 'call-logger-finish-call-button',
    saveDraftButton: 'save-draft-button',
    deleteCallButton: 'call-logger-delete-call-button',
    saveDraftMenu: {
      container: 'save-draft-menu',
      patientDidntAnswerOption: 'patient-didnt-answer-option',
      saveDraftOption: 'save-draft-option'
    },
    submitCallOptionsButton: 'submit-call-options-button',
    submitCallOptionsMenu: {
      sendToEmrCheckbox: 'send-to-emr-checkbox',
      generateSmartSummaryCheckbox: 'generate-smart-summary-checkbox',
      copyNotesToClipboardCheckbox: 'copy-notes-to-clipboard-checkbox'
    },
    smartSummary: {
      container: 'call-logger-smart-summary-container',
      regenerateSmartSummaryButton: 'regenerate-smart-summary-button',
      includeSummaryInEmrNoteRadioButton: (value: IncludeSummaryInEmrNote) =>
        `include-summary-in-emr-note-radio-button=${value}`,
      summaryTextarea: 'summary-textarea'
    },
    deleteCallDialog: {
      container: 'delete-call-dialog'
    },
    homeCareInstructionsSection: {
      container: 'homecare-instructions-section',
      header: 'homecare-instructions-section-header',
      textarea: 'homecare-instructions-section-textarea',
      messagingConsentCheckbox: 'homecare-instructions-section-messaging-consent-checkbox',
      phoneNumberInput: 'homecare-instructions-section-phone-number-input',
      sendButton: 'homecare-instructions-section-send-button',
      draftNotSentDialog: 'homecare-instructions-section-draft-not-sent-dialog'
    }
  }
};
