// @ts-strict-ignore

import {
  OralAnswerStructure,
  OralReportRegimenMeta,
  OralReportResponse,
  ReportQuestionAnswer
} from 'models/OralReport/OralReport.shared';

export type OralAnswerValueV1 = boolean | number | string | Date;

export enum OralQuestionTypeV1 {
  medReceived = 'medReceived',
  firstPill = 'firstPill',
  regimenAsPrescribed = 'regimenAsPrescribed',
  changesInOtherMeds = 'changesInOtherMeds',
  missedDoses = 'missedDoses',
  pillsLeft = 'pillsLeft'
}

// represent entire oral report
export class OralReportV1 implements OralReportRegimenMeta {
  startOfCycle: Date;
  regimen: string;
  isCustom: false;
  firstPill?: ReportQuestionAnswer<Date, OralQuestionTypeV1>;
  medReceived?: ReportQuestionAnswer<boolean, OralQuestionTypeV1>;
  regimenAsPrescribed?: ReportQuestionAnswer<boolean, OralQuestionTypeV1>;
  changesInOtherMeds?: ReportQuestionAnswer<boolean, OralQuestionTypeV1>;
  missedDoses?: ReportQuestionAnswer<number, OralQuestionTypeV1>;
  pillsLeft?: ReportQuestionAnswer<number, OralQuestionTypeV1>;
  previousReportDate?: Date;

  constructor(
    oralReportUnparsedResponse: OralReportResponse<OralAnswerValueV1, OralQuestionTypeV1>
  ) {
    Object.assign(this, oralReportUnparsedResponse);
    this.startOfCycle = oralReportUnparsedResponse?.startOfCycle
      ? new Date(oralReportUnparsedResponse.startOfCycle)
      : null;
    this.previousReportDate = oralReportUnparsedResponse?.previousReportDate
      ? new Date(oralReportUnparsedResponse.previousReportDate)
      : null;

    oralReportUnparsedResponse.reportItems.forEach((answer) => {
      switch (answer.questionType) {
        case OralQuestionTypeV1.firstPill:
          answer.value = new Date(answer.value as string);
          this.firstPill = new ReportQuestionAnswer<Date, OralQuestionTypeV1>(
            answer as OralAnswerStructure<Date, OralQuestionTypeV1>
          );
          break;

        case OralQuestionTypeV1.medReceived:
          this.medReceived = new ReportQuestionAnswer<boolean, OralQuestionTypeV1>(
            answer as OralAnswerStructure<boolean, OralQuestionTypeV1>,
            { value: true }
          );
          break;

        case OralQuestionTypeV1.regimenAsPrescribed:
          this.regimenAsPrescribed = new ReportQuestionAnswer<boolean, OralQuestionTypeV1>(
            answer as OralAnswerStructure<boolean, OralQuestionTypeV1>,
            { value: true }
          );
          break;

        case OralQuestionTypeV1.changesInOtherMeds:
          this.changesInOtherMeds = new ReportQuestionAnswer<boolean, OralQuestionTypeV1>(
            answer as OralAnswerStructure<boolean, OralQuestionTypeV1>,
            { value: false }
          );
          break;

        case OralQuestionTypeV1.missedDoses:
          this.missedDoses = new ReportQuestionAnswer<number, OralQuestionTypeV1>(
            answer as OralAnswerStructure<number, OralQuestionTypeV1>,
            { value: 0 }
          );
          break;

        case OralQuestionTypeV1.pillsLeft:
          this.pillsLeft = new ReportQuestionAnswer<number, OralQuestionTypeV1>(
            answer as OralAnswerStructure<number, OralQuestionTypeV1>
          );
          break;
      }
    });
  }

  get newCycleDate() {
    // if patient did not provide first pill date - use startOfCycle meta (can be update by a nurse)
    return this.firstPill?.isAnswered ? this.firstPill.value : this.startOfCycle;
  }

  set cycle(date: Date) {
    this.startOfCycle = date;
  }
}
