// @ts-strict-ignore
import { ErrorName, HttpError } from 'errors';

import { ErrorActions, handleError } from 'services/errorHandlingService';
import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { PatientEpisodesParser } from 'parsers/PatientEpisodesParser';

import PatientEpisode from 'models/PatientEpisode';

export interface EpisodeCreationAttrs {
  diagnosis: string;
  episodeId: number;
  episodeNumber: number;
  locationId: number;
  treatment: string;
  notes: string;
  ownerId: number;
  providerId: string;
  startDate: string;
  endDate: string;
  isHistorical?: boolean;
}

export interface EpisodeUpdateAttrs extends Omit<EpisodeCreationAttrs, 'isHistorical'> {
  id: number;
  error?: boolean;
}

export interface EndEpisodeRequestBody {
  reasonId: number;
  details?: string;
  endDate?: string;
}

const httpService = new HttpService('PatientEpisodes');

const handleActiveCmError = (error: HttpError) => {
  error.ui.title = 'Already Enrolled in Care Management Episode';
  error.ui.description =
    'This patient is already enrolled in an active Care Management episode. Please end it before starting another one.';
  handleError(error, [ErrorActions.displayWithPopUp]);
};

class PatientEpisodesFetcher {
  static async fetchPatientEpisodes(id: number) {
    return await httpService.get<PatientEpisode[]>({
      url: API_URLS.PATIENT_EPISODES(id),
      transformResponse: PatientEpisodesParser.parsePatientEpisodes
    });
  }

  static async createPatientEpisode(id: number, episodeBody: EpisodeCreationAttrs) {
    try {
      return await httpService.post({
        url: API_URLS.PATIENT_EPISODES(id),
        data: episodeBody
      });
    } catch (error) {
      if (error.name === ErrorName.EnrollInActiveCm) {
        handleActiveCmError(error);
        return;
      }
      throw error;
    }
  }

  static async updatePatientEpisode(id: number, episodeBody: EpisodeUpdateAttrs): Promise<any> {
    await httpService.put({
      url: API_URLS.UPDATE_PATIENT_EPISODE(id, episodeBody.id),
      data: episodeBody
    });
  }

  static async endEpisode(patientId: number, episodeId: number, data: EndEpisodeRequestBody) {
    return await httpService.put({
      url: API_URLS.END_PATIENT_EPISODE(patientId, episodeId),
      data
    });
  }
}

export default PatientEpisodesFetcher;
