import { FC } from 'react';

import Select from 'react-select';

import AsyncSelect from 'react-select/async';

import { StyledSelectContainer } from 'components/UIkit/atoms/Dropdown/Select/StyledSelectContainer';

import {
  useCommonReactSelectProps,
  getSelectContainerProps,
  AsyncBaseSelectProps,
  BaseSelectProps,
  ReactSelectInternalProps
} from './Select.shared';
import { createAddOption, sortOptionsByLocale } from './SelectUtils';

// Base select component are for internal use only (e.i. by Autocomplete/Select components, never directly)
export const BaseSelect: FC<BaseSelectProps & ReactSelectInternalProps> = ({
  sortAlphabetically = true,
  value,
  ...props
}) => {
  let options =
    sortAlphabetically && props.options ? props.options.sort(sortOptionsByLocale) : props.options;

  if (props.addText) {
    options = [...options, createAddOption(props.addText)];
  }

  return (
    <StyledSelectContainer {...getSelectContainerProps(props)}>
      <Select {...useCommonReactSelectProps(props)} options={options} value={value} />
    </StyledSelectContainer>
  );
};

export const AsyncBaseSelect: FC<AsyncBaseSelectProps & ReactSelectInternalProps> = ({
  value,
  loadOptions,
  defaultOptions = false,
  ...props
}) => (
  <StyledSelectContainer {...getSelectContainerProps(props)}>
    <AsyncSelect
      {...useCommonReactSelectProps(props)}
      value={value}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions}
    />
  </StyledSelectContainer>
);
