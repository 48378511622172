import { PaletteOptions } from '@mui/material';

// Canopy colors - https://www.figma.com/file/vaQnuineafX4UuLgnnGDam/New-General-Design-System?node-id=0%3A1
// Color names inspired by material colors https://material.io/design/color/the-color-system.html#tools-for-picking-colors
type HEX = `#${string}`;

type NaturalKeys =
  | 'white'
  | 'black'
  | 'inactiveBackground'
  | 'contrast'
  | 'contrastDark'
  | 'hover'
  | 'pressed'
  | 'shadow'
  | 'border';
type Natural = Record<NaturalKeys, HEX>;

const naturalColors: Natural = {
  white: '#fff',
  black: '#000',
  inactiveBackground: '#F3F3F3',
  contrast: '#FBFBF9',
  contrastDark: '#F6F6F1',
  hover: '#EFF1F2',
  pressed: '#BFBFBF',
  shadow: '#E0E2E4',
  border: '#E3E3E3'
};

type TagKeys =
  | 'highRisk'
  | 'tag1'
  | 'tag2'
  | 'tag3'
  | 'tag4'
  | 'tag5'
  | 'tag6'
  | 'tag7'
  | 'tag8'
  | 'tag9'
  | 'tag10';
type Tags = Record<TagKeys, HEX>;

const tagColors: Tags = {
  highRisk: '#FFC2B5',
  tag1: '#B4E08A',
  tag2: '#FDD158',
  tag3: '#4D60C9',
  tag4: '#FFAC7E',
  tag5: '#37AE9A',
  tag6: '#CE569E',
  tag7: '#496F5D',
  tag8: '#317ACE',
  tag9: '#542344',
  tag10: '#895CD3'
};

// see https://mui.com/material-ui/customization/palette/#adding-new-colors
declare module '@mui/material/styles' {
  interface Palette {
    tag: Tags;
    natural: Natural;
  }

  export interface PaletteColor {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
    alternate: string;
  }

  interface PaletteOptions {
    tag: Tags;
    natural: Natural;
  }

  interface SimplePaletteColorOptions {
    light?: string;
    main: string;
    dark?: string;
    contrastText?: string;
    alternate?: string;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    error: string;
    white: string;
    dark: string;
  }
}

// https://mui.com/material-ui/customization/palette/
export const palette: PaletteOptions = {
  primary: {
    light: '#AFDCBE',
    main: '#007C66',
    dark: '#1C614C',
    contrastText: '#E8F3F1'
  },
  secondary: {
    main: '#4D60C9',
    dark: '#1A2B82',
    light: '#DEE2FD',
    alternate: '#F5F7FF'
  },
  text: {
    primary: '#1E233E',
    secondary: '#676A7B',
    disabled: '#00000060',
    error: '#E30000',
    white: '#fff',
    dark: '#1A2B82'
  },
  error: {
    main: '#F75F32',
    dark: '#E30000',
    light: '#FFE8E3',
    contrastText: '#781E0E'
  },
  warning: {
    main: '#F89636',
    dark: '#D5792C',
    contrastText: '#781E0E'
  },
  success: {
    main: '#80CB6E',
    dark: '#14A452'
  },
  natural: naturalColors,
  tag: tagColors
};
