import { useCallback, useRef } from 'react';

interface UseRefCallbackReturnType {
  ref: (node: HTMLElement) => void;
}

// https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
export function useRefCallback(callback?: (node: HTMLElement) => void): UseRefCallbackReturnType {
  const ref = useRef<HTMLElement>();

  const setRef = useCallback(
    (node: HTMLElement) => {
      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
      }

      if (node) {
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        callback && callback(node);
      }

      // Save a reference to the node
      ref.current = node;
    },
    [callback]
  );

  return { ref: setRef };
}
