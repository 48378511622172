import { useEffect, useState } from 'react';

import { compact, flow, orderBy } from 'lodash/fp';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { API_LABELS } from 'constants/apiUrls';

import Call from 'models/Call';

import Ticket from 'models/Ticket';

import { usePatientModel } from 'components/Patient/usePatientModel';

const getSortedCallsForTicket = (ticket: Ticket, calls: Call[]): Call[] => {
  if (!ticket.hasOwnProperty('callCount')) {
    console.error('Could not find callIds on ticket', ticket.id, ticket.callCount);
    return [];
  }

  return flow([
    compact,
    (calls) => calls.filter((call: Call) => call.ticketIds.has(ticket.id)),
    orderBy(['updatedAt'], ['desc'])
  ])(calls);
};

export function useTicketCalls(ticket: Ticket, shouldFetch: boolean) {
  const { ticketsStore } = useStores();
  const patient = usePatientModel(ticket.patientId);

  const [calls, setCalls] = useState<Call[]>([]);
  const isLoadingCalls = useNetworkLoading(API_LABELS.CALLS([ticket.id]));

  useEffect(
    function fetchCalls() {
      const fetchTicketCalls = async () => {
        const calls = await ticketsStore.fetchTicketsCalls([ticket.id]);

        calls.forEach((call) => {
          patient?.callsMap.set(call.id, call);
        });
        setCalls(getSortedCallsForTicket(ticket, calls));
      };
      if (shouldFetch && ticket.callsAndDraftsCount) {
        fetchTicketCalls();
      }
    },
    [shouldFetch, ticketsStore, patient?.callsMap, ticket.callsAndDraftsCount, ticket]
  );

  return { calls, isLoadingCalls: !calls.length && isLoadingCalls };
}
