// From MUI
const MUI_TRIGGERED_BY_ESCAPE_KEY_DOWN = 'escapeKeyDown';
// From MUI
const MUI_TRIGGERED_BY_BACKDROP_CLICK = 'backdropClick';
const TRIGGERED_BY_CLOSE_ICON_CLICK = 'closeIconClick';
const TRIGGERED_BY_CLOSE_BUTTON_CLICK = 'closeButtonClick';

export enum DialogCloseReason {
  EscapeKeyDown = MUI_TRIGGERED_BY_ESCAPE_KEY_DOWN,
  BackdropClick = MUI_TRIGGERED_BY_BACKDROP_CLICK,
  CloseIconClick = TRIGGERED_BY_CLOSE_ICON_CLICK,
  CloseButtonClick = TRIGGERED_BY_CLOSE_BUTTON_CLICK
}
