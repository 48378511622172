// @ts-strict-ignore
import { castArray } from 'lodash';
import { observable, makeObservable } from 'mobx';
import moment from 'moment';

import {
  DAY_IN_MILLISECONDS,
  DAYS_OF_WEEK_ARRAY,
  formatDate,
  HOUR_IN_MILLISECONDS,
  isAfterEpoch,
  MONTH_IN_MILLISECONDS,
  WEEK_IN_MILLISECONDS
} from 'utils/DateUtils';
import ObjectCopier from 'utils/ObjectCopier';

export enum OralProtocolReportFrequencies {
  REGULAR = 'regular',
  LOW = 'low'
}

export enum OralFillLocations {
  IN_HOUSE = 'in-house',
  EXTERNAL = 'external'
}

export enum ProtocolType {
  mobile = 'mobile',
  phone = 'phone',
  chatbot = 'chatbot'
}

export enum ProtocolName {
  symptom = 'Symptom',
  covid = 'COVID-19',
  oralOncolytics = 'Oral',
  chooseLater = 'chooseLater',
  drugSpecific = 'DrugSpecific'
}

export enum ProtocolRecurrence {
  HOURLY = 'Hourly',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly'
}

export enum ProtocolOperationMode {
  Manual = 'manual',
  Automatic = 'automatic'
}

export enum ProtocolAutomationMode {
  Full = 'full',
  Weekly = 'weekly',
  Monthly = 'monthly'
}

export default class ScheduledProtocol extends ObjectCopier {
  static PROTOCOL_CODE_LINEAR = 0;
  static PROTOCOL_CODE_MULTIPLE_WEEKLY = 1;
  static PROTOCOL_CODE_MULTIPLE_HOURLY = 2;

  static END_TYPE_OCCURRENCES = 1;

  static END_TYPE_DATE = 2;

  static END_TYPE_NEVER = 3;

  static END_TYPE_DURATION = 4;

  static OFF_HOURS_DAYS = [0, 6];

  static OPEN_TIME = 8;
  static CLOSE_TIME = 17;

  @observable id: number;

  @observable name: ProtocolName;

  @observable type: ProtocolType;

  @observable code: number;

  @observable timeZone: string;

  @observable patientId: number;

  @observable lastReportIntervalAt: Date | null;

  @observable info: any;

  @observable reportProtocolTemplateId: number | null;

  @observable operationMode: ProtocolOperationMode;

  @observable automationMode: ProtocolAutomationMode;

  constructor() {
    super();
    makeObservable(this);
  }

  static getProtocolDisplayName = (type: ProtocolType) => {
    switch (type) {
      case 'mobile':
        return 'App / Web';
      case 'phone':
        return 'Report Line';
    }
  };
  isOralAutomaticProtocol = () => {
    return (
      this.name === ProtocolName.oralOncolytics &&
      this.automationMode === ProtocolAutomationMode.Full &&
      this.operationMode === ProtocolOperationMode.Automatic
    );
  };
  isFullyAutomated = () => {
    return (
      this.operationMode === ProtocolOperationMode.Automatic &&
      this.automationMode === ProtocolAutomationMode.Full
    );
  };
  isEnded = () => {
    if (this.info.endType === ScheduledProtocol.END_TYPE_DATE) {
      return moment(this.info.expirationDate).isBefore(moment());
    }
    if (this.info.endType === ScheduledProtocol.END_TYPE_OCCURRENCES) {
      return this.info.occurrences <= 0;
    }
    return false;
  };
  getShortString = () => {
    if (this.name === ProtocolName.chooseLater) {
      return null;
    }

    if (this.operationMode === ProtocolOperationMode.Automatic) {
      return this.getProtocolTypeString();
    }

    return `${this.getProtocolTypeString()}:  ${this.getFrequencyUnit()}`;
  };

  getProtocolWithString = () => {
    const displayOperationMode =
      this.operationMode === ProtocolOperationMode.Automatic ? ': Automatic' : '';

    let displayProtocolName = '';
    if (this.name === ProtocolName.oralOncolytics) {
      displayProtocolName = ' (Oral Oncolytics)';
    } else if (this.name === ProtocolName.drugSpecific) {
      displayProtocolName = ' (Drug-Specific)';
    }

    return `${displayProtocolName}${displayOperationMode}`;
  };

  getFrequencyUnit = () => {
    if (this.name === ProtocolName.symptom || this.name === ProtocolName.drugSpecific) {
      const timeBetweenReports = this.info.timeBetweenReports;

      if (timeBetweenReports % MONTH_IN_MILLISECONDS === 0) {
        return ProtocolRecurrence.MONTHLY;
      }

      if (
        timeBetweenReports % WEEK_IN_MILLISECONDS === 0 &&
        this.code === ScheduledProtocol.PROTOCOL_CODE_MULTIPLE_WEEKLY
      ) {
        return ProtocolRecurrence.WEEKLY;
      }

      if (timeBetweenReports % DAY_IN_MILLISECONDS === 0) {
        return ProtocolRecurrence.DAILY;
      }

      if (timeBetweenReports % HOUR_IN_MILLISECONDS === 0) {
        return ProtocolRecurrence.HOURLY;
      }
    }

    if (this.name === ProtocolName.oralOncolytics) {
      return ProtocolRecurrence.WEEKLY;
    }

    return null;
  };

  getProtocolTypeString = () =>
    `${ScheduledProtocol.getProtocolDisplayName(this.type)}${this.getProtocolWithString()}`;

  getProtocolTimeSummary = () => {
    if (!!this.info) {
      const endDateString = this.getEndDateString();
      return `${this.getTimeBetweenReportsString()} Start: ${formatDate(
        this.info.startingDate,
        'MM/DD/YYYY'
      )}${endDateString ? `, ${endDateString}` : ''}`;
    }
    return '';
  };

  getEndDateString = () => {
    if (this.info.endType === ScheduledProtocol.END_TYPE_DATE) {
      return this.info.expirationDate && isAfterEpoch(this.info.expirationDate)
        ? `End: ${formatDate(this.info.expirationDate, 'MM/DD/YYYY')}`
        : '';
    }

    if (this.info.endType === ScheduledProtocol.END_TYPE_OCCURRENCES) {
      return `End: After ${this.info.occurrences} Occurrences`;
    }
    return '';
  };

  getIntervalAsMultiplication = () => {
    const frequencyUnit = this.getFrequencyUnit();

    switch (frequencyUnit) {
      case ProtocolRecurrence.HOURLY:
        return {
          multiplier: this.info.timeBetweenReports / HOUR_IN_MILLISECONDS,
          timeUnit: HOUR_IN_MILLISECONDS
        };
      case ProtocolRecurrence.DAILY:
        return {
          multiplier: this.info.timeBetweenReports / DAY_IN_MILLISECONDS,
          timeUnit: DAY_IN_MILLISECONDS
        };
      case ProtocolRecurrence.WEEKLY:
        return {
          multiplier: this.info.timeBetweenReports / WEEK_IN_MILLISECONDS,
          timeUnit: WEEK_IN_MILLISECONDS
        };
      case ProtocolRecurrence.MONTHLY:
        return {
          multiplier: this.info.timeBetweenReports / MONTH_IN_MILLISECONDS,
          timeUnit: MONTH_IN_MILLISECONDS
        };
      default:
        throw new Error(`Unkown frequency ${frequencyUnit}`);
    }
  };

  getTimeBetweenReportsString = () => {
    const { multiplier, timeUnit } = this.getIntervalAsMultiplication();
    switch (timeUnit) {
      case MONTH_IN_MILLISECONDS:
        return `Every ${multiplier > 1 ? `${multiplier} Months` : 'Month'},`;
      case WEEK_IN_MILLISECONDS:
        const daysOfWeek = castArray(this.info.daysOfWeek)
          .slice()
          .sort()
          .map((day: number) => DAYS_OF_WEEK_ARRAY[day]);
        const daysOfWeekString = daysOfWeek.length ? ` (${daysOfWeek.join(', ')})` : '';
        return `Every ${multiplier > 1 ? `${multiplier} Weeks` : 'Week'}${daysOfWeekString},`;
      case DAY_IN_MILLISECONDS:
        return `Every ${multiplier > 1 ? `${multiplier} Days` : 'Day'},`;
      case HOUR_IN_MILLISECONDS:
        return `Every ${multiplier > 1 ? `${multiplier} Hours` : 'Hour'},`;
      default:
        return '';
    }
  };

  static isInDaysOff = (daysOfWeek: number[], startingDate: string) => {
    if (daysOfWeek) {
      return (
        daysOfWeek.filter((selectedDay: number) =>
          ScheduledProtocol.OFF_HOURS_DAYS.includes(selectedDay)
        ).length > 0
      );
    }
    return ScheduledProtocol.OFF_HOURS_DAYS.includes(moment(startingDate).day());
  };

  static isInOffTime = (startingDate: string) => {
    const selectedHour = moment(startingDate).hour();
    return (
      selectedHour < ScheduledProtocol.OPEN_TIME || selectedHour > ScheduledProtocol.CLOSE_TIME
    );
  };

  static isOutsideBusinessHours = (daysOfWeek: number[], startingDate: string): boolean => {
    return (
      ScheduledProtocol.isInDaysOff(daysOfWeek, startingDate) ||
      ScheduledProtocol.isInOffTime(startingDate)
    );
  };
}
