import { FC } from 'react';

export const AddCircleIcon: FC<{ disabled: boolean }> = ({ disabled }) => {
  const color = disabled ? '#7A7A7A' : '#007C66';
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1.01758" width="18" height="18" rx="9" fill={color} stroke={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5753 9.42522L10.5751 5.94137C10.5696 5.6247 10.3114 5.37086 9.99463 5.37086C9.67791 5.37086 9.41962 5.6247 9.41412 5.94137L9.41428 9.42518L5.93048 9.42501C5.6138 9.43052 5.35997 9.6888 5.35997 10.0055C5.35997 10.3222 5.6138 10.5805 5.93048 10.586L9.41432 10.5862L9.41453 14.0701C9.41088 14.2799 9.52072 14.4753 9.70183 14.5813C9.88294 14.6873 10.1071 14.6873 10.2882 14.5813C10.4694 14.4753 10.5792 14.2799 10.5756 14.0701L10.5754 10.5863L14.0592 10.5864C14.269 10.5901 14.4644 10.4803 14.5704 10.2991C14.6764 10.118 14.6764 9.89384 14.5704 9.71273C14.4644 9.53162 14.269 9.42178 14.0592 9.42542L10.5753 9.42522Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};
