import { FC, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { Slide, toast, ToastContainer, ToastItem } from 'react-toastify';

import { useStores } from 'mobx/hooks/useStores';

import { theme } from 'components/UIkit/theme';

export const TOAST_DEFAULT_TIMEOUT_IN_MILLIS = 4500;

export const Toast: FC = () => {
  const { uiStore } = useStores();

  useEffect(
    function handleToastChanged() {
      const unsubscribe = toast.onChange((payload: ToastItem) => {
        switch (payload.status) {
          case 'added':
            break;
          case 'updated':
            break;
          case 'removed':
            break;
        }
      });

      return () => {
        unsubscribe();
      };
    },
    [uiStore]
  );

  return (
    <StyledToast
      position="bottom-right"
      hideProgressBar
      newestOnTop
      closeButton={false}
      closeOnClick={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      transition={Slide}
    />
  );
};

const StyledToast = styled(ToastContainer)`
  &.Toastify__toast-container {
    min-width: 277px;
    border-radius: ${theme.borderRadius.large};
    font-size: 14px;
    padding: 0;
    bottom: ${theme.spacing(28)};
    right: ${theme.spacing(28)};
    z-index: 1300;
  }

  .Toastify__toast {
    padding: 0;
    border-radius: ${theme.borderRadius.large};
    font-weight: 700;
    font-size: 14px;
    height: fit-content;
    min-height: 48px;
    margin-bottom: ${theme.spacing(12)};
    background-color: ${theme.palette.natural.white};
    border: 1px solid ${theme.palette.natural.inactiveBackground};
    box-shadow: 0 1px 4px ${theme.palette.natural.shadow};
  }

  .Toastify__toast-body {
    border-radius: ${theme.borderRadius.large};
    font-weight: 700;
    font-size: 14px;
    padding: 0;
    margin: 0;
    box-shadow: none;
    background-color: ${theme.palette.natural.white};

    > div {
      height: 100%;
    }
  }
`;
