import { PageMocksOverrides } from 'tests/models/pages/base-page/base-page.types';

import { CallResolutionResponse, GenerateSmartSummaryResponse } from 'fetchers/CallsFetcher';
import { SendHomeCareInstructionsResponse } from 'fetchers/PatientCommunicationFetcher';

export interface PatientPageOpenConfig {
  mocksOverrides?: PageMocksOverrides<PatientPageMocksOverrides>;
  patientId?: number;
  params?: {
    tab?: PatientPageTabs;
    extra?: string;
  };
}

export interface PatientPageMocksOverrides {
  patient?: any;
  clinicianAlerts?: any;
  auditPageView?: any;
  quickAdditionReasons?: any;
  patientDxCodes?: any;
  tickets?: any;
  tasks?: any;
  cmFilterData?: any;
  cmIneligibilityReasons?: any;
  cmOptOutReasons?: any;
  cmEndedReasons?: any;
  patientEpisodes?: any;
  cmPatientData?: any;
  smartSummary?: GenerateSmartSummaryResponse;
  createDraftCallResponse?: any;
  ticketComment?: any;
  saveCallResponse?: CallResolutionResponse;
  sendHomeCareInstructions?: SendHomeCareInstructionsResponse;
}

export enum PatientPageTabs {
  OpenItems = 'open_tickets',
  ResolvedTickets = 'resolved_tickets',
  Calls = 'calls',
  EpisodesAndTasks = 'episodes_and_tasks'
}

export enum ReportProtocolModalTab {
  Symptom = 'symptom-assessment',
  Oral = 'oral-oncolytics',
  DrugSpecific = 'drug-specific'
}
