import { FC } from 'react';

import { css, styled } from '@mui/material';
import { components, ControlProps } from 'react-select';

export const PaginatorSelectControl: FC<ControlProps> = (props) => (
  <StyledControl {...props}>{props.children}</StyledControl>
);

const StyledControl: FC<ControlProps> = styled(components.Control, {
  shouldForwardProp: () => true
})((props: ControlProps) => {
  const { isFocused } = props;
  const { palette } = props.selectProps.muiTheme!;

  return css`
    min-height: 0;
    height: 100%;
    width: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;

    .react-select__value-container {
      color: ${isFocused ? palette.text.primary : palette.natural.white};
      background-color: ${isFocused ? palette.natural.white : palette.secondary.main};
    }

    .react-select__single-value,
    .react-select__input-container {
      color: ${isFocused ? palette.text.primary : palette.natural.white};
      background-color: ${isFocused ? palette.secondary.light : palette.secondary.main};
    }
  `;
});
