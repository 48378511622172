import { createContext, FC, ReactNode } from 'react';

interface Props {
  pathwayId: string;
  children: ReactNode;
}

export const PathwayProviderContext = createContext<{ pathwayId: string } | null>(null);

export const PathwayProvider: FC<Props> = ({ pathwayId, children }) => (
  <PathwayProviderContext.Provider value={{ pathwayId }}>
    {children}
  </PathwayProviderContext.Provider>
);
