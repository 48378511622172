// @ts-strict-ignore
import React, { ChangeEvent, FC, useState } from 'react';

import Box from '@mui/material/Box';
import { styled, css } from '@mui/material/styles';
import classNames from 'classnames';

import Icon from 'components/Icons/Icon';
import { Highlighter } from 'components/Tooltip/Highlighter';
import { DividerPlacement, TooltipSelectProps } from 'components/Tooltip/TooltipSelect';
import { Text } from 'components/UIkit/atoms/Text';

export interface TooltipAsyncSelectProps extends TooltipSelectProps {
  onSearchChange: (value?: string) => void;
  isLoading: boolean;
}

export const TooltipAsyncSelect: FC<TooltipAsyncSelectProps> = ({
  options,
  title,
  onSearchChange,
  onSearchBlur,
  children,
  searchPlaceholder,
  searchValue: controlledSearchValue,
  isLoading
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [valueAfterFocus, setValueAfterFocus] = useState('');
  const [isInputEmpty, setIsInputEmpty] = useState(true);

  const isLoadingTextVisible = isLoading && isInputEmpty;

  const handleSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    await onSearchChange(value);
    setIsInputEmpty(!value);
  };

  const optionContainerClassName = classNames(
    'options-container',
    { 'with-header': title || onSearchChange },
    { 'with-footer': children }
  );

  return (
    <div className="tooltip-select">
      {title && <div className="tooltip-select-title mb-2">{title}</div>}

      {onSearchChange && (
        <div className="dropdown-search py-2 px-3">
          <StyledSearchIcon />

          <input
            type="text"
            name="name"
            placeholder={searchPlaceholder}
            className="search-input"
            onFocus={() => {
              setValueAfterFocus(searchValue);
            }}
            onBlur={(e) => {
              onSearchBlur && onSearchBlur(e, searchValue, valueAfterFocus);
            }}
            autoComplete="off"
            onChange={handleSearchChange}
            value={searchValue}
          />

          <StyledLoadingContainer isVisible={isLoading}>
            <StyledLoadingDot />
            <StyledLoadingDot />
            <StyledLoadingDot />
          </StyledLoadingContainer>
        </div>
      )}

      <div className={optionContainerClassName}>
        {isLoadingTextVisible ? (
          <Text
            textAlign="center"
            display="inline-block"
            variant="body2"
            color="disabled"
            my={8}
            width="100%"
          >
            Loading...
          </Text>
        ) : (
          <>
            {options.map(
              (
                {
                  id,
                  onClick,
                  testHook,
                  isSelected,
                  disabled,
                  text,
                  extraText,
                  isVisible = true,
                  withDivider,
                  groupHeader,
                  dividerPlacement,
                  readonly,
                  hasHighlightOnText = false
                },
                index
              ) => {
                if (!isVisible) {
                  return null;
                }

                return (
                  <React.Fragment key={`option_${id || index}`}>
                    {groupHeader && (
                      <Box py={4}>
                        <Text px={8} variant="body2" color="disabled">
                          {groupHeader.toUpperCase()}
                        </Text>
                      </Box>
                    )}

                    <div
                      onClick={!disabled ? onClick : null}
                      data-test-hook={testHook}
                      className={classNames('tooltip-select-option', {
                        selected: isSelected,
                        disabled: disabled,
                        readonly,
                        'with-divider': withDivider,
                        'divider-placement-top': dividerPlacement === DividerPlacement.Top,
                        'divider-placement-bottom': dividerPlacement === DividerPlacement.Bottom
                      })}
                    >
                      <span className="checkmark">
                        <Icon.CheckMark />
                      </span>

                      {hasHighlightOnText ? (
                        <Highlighter
                          searchValue={controlledSearchValue}
                          textToHighlight={text as string}
                          className="mr-1 w-100"
                        />
                      ) : (
                        <span className="mr-1 w-100">{text}</span>
                      )}

                      {extraText && !isSelected && (
                        <span className="extra ml-auto">{extraText}</span>
                      )}
                    </div>
                  </React.Fragment>
                );
              }
            )}
          </>
        )}
      </div>
      {children}
    </div>
  );
};

const StyledSearchIcon = styled(Icon.Search)`
  flex-shrink: 0;
`;

const StyledLoadingContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVisible'
})<{ isVisible: boolean }>(
  ({ isVisible }) => css`
    visibility: ${isVisible ? 'visible' : 'hidden'};
    padding: 4px;
    color: hsl(0, 0%, 80%);
    display: flex;
    transition: color 150ms;
    align-self: center;
    font-size: 4px;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
  `
);

const StyledLoadingDot = styled('span')`
  animation: animate 1s ease-in-out 0ms infinite;
  background-color: currentColor;
  border-radius: 4px;
  display: inline-block;
  height: 4px;
  vertical-align: top;
  width: 4px;

  &:nth-of-type(2) {
    animation-delay: 160ms;
  }

  &:nth-of-type(3) {
    animation-delay: 320ms;
  }

  &:not(:last-of-type) {
    margin-right: 4px;
  }

  @keyframes animate {
    0% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }

    80% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
`;
