import { TicketTypesStore } from 'mobx/stores';

import { formatDate, LONG_DATE_FORMAT } from 'utils/DateUtils';

import { extractItemTypesFilterQuery } from 'utils/serverFiltersUtils';

import Patient from 'models/Patient';

import {
  TicketSortOrder,
  DEFAULT_WQ_ASSIGNED_TO_ME_RECORDS_PER_PAGE,
  DEFAULT_WQ_RECORDS_PER_PAGE
} from 'models/Settings';
import Ticket, { TicketClass, TicketStatus } from 'models/Ticket';

import { SearchFiltersType } from 'views/Filters/filters.types';

import {
  WorkQueueOpenItemsSectionName,
  WorkQueueRequestFilters,
  WorkQueueRequestSection,
  WorkQueueSectionFilters,
  WorkQueueSectionName
} from 'views/WorkQueue/WorkQueue.types';

export const buildServerSectionsOnFirstPageQuery = ({
  sections,
  ticketSortOrder,
  recordsPerPage = DEFAULT_WQ_RECORDS_PER_PAGE,
  assignedToMeRecordsPerPage = DEFAULT_WQ_ASSIGNED_TO_ME_RECORDS_PER_PAGE
}: {
  sections: Exclude<WorkQueueSectionName, WorkQueueSectionName.ResolvedItems>[];
  ticketSortOrder: TicketSortOrder;
  recordsPerPage: number;
  assignedToMeRecordsPerPage: number;
}): WorkQueueRequestSection[] =>
  sections.map((sectionName) =>
    buildServerSectionQuery({
      sectionName,
      pageNumber: 0,
      ticketSortOrder,
      recordsPerPage,
      assignedToMeRecordsPerPage
    })
  );

export const buildServerSectionQuery = ({
  sectionName,
  pageNumber,
  ticketSortOrder,
  recordsPerPage = DEFAULT_WQ_RECORDS_PER_PAGE,
  assignedToMeRecordsPerPage = DEFAULT_WQ_ASSIGNED_TO_ME_RECORDS_PER_PAGE,
  filters
}: {
  sectionName: WorkQueueSectionName;
  pageNumber: number;
  ticketSortOrder: TicketSortOrder;
  recordsPerPage: number;
  assignedToMeRecordsPerPage?: number;
  filters?: WorkQueueSectionFilters;
}): WorkQueueRequestSection => ({
  recordsPerPage:
    sectionName === WorkQueueSectionName.AssignedToMe ? assignedToMeRecordsPerPage : recordsPerPage,
  pageNumber,
  name: sectionName,
  sort: ticketSortOrder,
  filters
});

export const getSectionToFetchByType = (
  ticket: Ticket,
  patient?: Patient
): WorkQueueOpenItemsSectionName | null => {
  const isPatientReportTicket = ticket.class === TicketClass.patient;
  const isUrgentReportTicket = isPatientReportTicket && patient?.hasUrgentReports;
  const isOtherPatientReportTicket = isPatientReportTicket && !patient?.hasUrgentReports;

  if (ticket.isTask) {
    return WorkQueueSectionName.TasksDue;
  }

  if (ticket.isCallbackRequestTicket || ticket.isGeneralOperatorTicket) {
    return WorkQueueSectionName.TicketsAndCallbackRequests;
  }

  if (ticket.isSymptomOperatorTicket || isUrgentReportTicket) {
    return WorkQueueSectionName.UrgentPatientReports;
  }

  if (isOtherPatientReportTicket) {
    return WorkQueueSectionName.OtherPatientReports;
  }

  return null;
};

export const extractWqQueryFromFilters = (
  filters: SearchFiltersType,
  ticketTypesStore: TicketTypesStore
): Partial<WorkQueueRequestFilters> => {
  const { fromDate, toDate } = filters;

  return {
    // General
    nameOrMrn: filters.searchTerm,
    assignees: filters.assignees?.map((option) => option.value as number),
    providers: filters.providers?.map((option) => option.value?.id as string),
    locations: filters.locations?.map((option) => option.value as number),
    patientTags: filters.patientTags?.map((option) => option.value as number),
    // Tasks
    episodes: filters.episodeIds?.map((option) => option.value as number),
    roles: filters.role?.map((option) => option.value as number),
    owners: filters.owners?.map((option) => option.value as number),
    taskStatuses: filters.status?.map((option) => option.value as TicketStatus),
    taskSearchTerm: filters.taskSearchTerm,
    lastNameFirstLetter: filters.lastNameFirstLetter?.map((option) => option.value as string),
    fromDate: fromDate ? formatDate(fromDate, LONG_DATE_FORMAT) : null,
    toDate: toDate ? formatDate(toDate, LONG_DATE_FORMAT) : null,
    ...extractItemTypesFilterQuery(ticketTypesStore, filters.ticketType!)
  };
};
