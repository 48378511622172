import { FC, InputHTMLAttributes, ReactNode } from 'react';

import classNames from 'classnames';

import { Testable } from 'utils/TypeUtils';

import './StyledToggleButton.scss';

export enum LabelPosition {
  LEFT,
  RIGHT
}

interface StyledToggleButtonProps extends Testable, InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode;
  labelPosition?: LabelPosition;
}

const StyledToggleButton: FC<StyledToggleButtonProps> = ({
  className,
  label,
  labelPosition = LabelPosition.LEFT,
  testHook,
  ...props
}) => (
  <div className={classNames('styled-toggle-button', className)}>
    {label && labelPosition === LabelPosition.LEFT && (
      <label className="styled-toggle-button-label">{label}</label>
    )}
    <input
      type="checkbox"
      className="styled-toggle-button-input"
      {...props}
      data-test-hook={testHook}
    />
    {label && labelPosition === LabelPosition.RIGHT && (
      <label className="styled-toggle-button-label right">{label}</label>
    )}
  </div>
);

export default StyledToggleButton;
