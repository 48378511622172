// @ts-strict-ignore
import classNames from 'classnames';
import { HeaderGroup } from 'react-table';

import { UrlSortParams } from 'utils/urlUtils';

import Icon from 'components/Icons/Icon';
import { getColumnHeaderSortProps } from 'components/Table/Table.utils';

import './TableHeader.scss';

interface TableHeaderProps<D extends {}> {
  column: HeaderGroup<D>;
  style: object;
  manualSort?: boolean;
  setSortInUrl?: (params: UrlSortParams) => void;
  onManualSort?: (column: HeaderGroup<any>, sortParams: UrlSortParams) => void;
}

const TableHeader = <D extends {}>({
  column,
  manualSort,
  setSortInUrl,
  style,
  onManualSort
}: TableHeaderProps<D>) => {
  return (
    <div
      {...column.getHeaderProps()}
      {...getColumnHeaderSortProps(column, manualSort, setSortInUrl, onManualSort)}
      style={style}
      data-test-hook={`table-header-${column.id}`}
      className={classNames('header-col', { 'cursor-pointer': column.canSort })}
      title=""
    >
      {column.render('Header')}
      {column.canSort && (
        <div
          className={classNames('ic-arrow', {
            selected: column.isSorted,
            up: !column.isSortedDesc
          })}
        >
          <Icon.Chevron />
        </div>
      )}
    </div>
  );
};

export default TableHeader;
