import { useCallback } from 'react';

import debounce from 'debounce-promise';

import { useStores } from 'mobx/hooks/useStores';
import CareManagementReportStore from 'mobx/stores/CareManagementReportStore';

export const DEBOUNCE_DELAY = 300;

const debouncedCmReportData = debounce((careManagementReportStore: CareManagementReportStore) => {
  careManagementReportStore.fetchCareManagementReport();
}, DEBOUNCE_DELAY);

const debouncedCmEnrolledData = debounce((careManagementReportStore: CareManagementReportStore) => {
  careManagementReportStore.fetchCareManagementEnrolled();
}, DEBOUNCE_DELAY);

export const useDebouncedCmReportData = () => {
  const { careManagementReportStore } = useStores();
  return useCallback(
    () => debouncedCmReportData(careManagementReportStore),
    [careManagementReportStore]
  );
};

export const useDebouncedCmEnrolledData = () => {
  const { careManagementReportStore } = useStores();
  return useCallback(
    () => debouncedCmEnrolledData(careManagementReportStore),
    [careManagementReportStore]
  );
};
