// @ts-strict-ignore
import { useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';

import { observer } from 'mobx-react';

import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { parseDateForInputField } from 'utils/DateUtils';

import { showToast } from 'utils/UserMessageUtils';

import Patient from 'models/Patient';
import PatientEpisode from 'models/PatientEpisode';

import {
  CreateEditPatientEpisodeFormFields,
  EpisodeEnrollFormFields,
  useActiveEpisodesOptions,
  useNewEpisodeNumber
} from 'views/Pages/EpisodesManagement/CreateEditPatientEpisodeFormFields';
import { getEpisodeEndDate } from 'views/Pages/EpisodesManagement/EpisodeRulesetHandlers';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

// eslint-disable-next-line import/order
import { trackEnrollEpisodeModalAnalyticsEvent } from 'analytics/events/enroll-episode-modal';

import './EpisodeModalForm.scss';

interface IEditTicketModalProps {
  isOpen: boolean;
  patient: Patient;
  onSubmit: () => void;
  onCancel: () => void;
  episodeId?: number;
  resetDataAfterClose?: () => void;
}

export const EnrollEpisodeModal = observer(
  ({
    isOpen,
    patient,
    onSubmit,
    onCancel,
    episodeId,
    resetDataAfterClose
  }: IEditTicketModalProps) => {
    const title = `${patient?.fullName}: Enroll in Episode `;
    const { patientEpisodesStore, constantsStore } = useStores();
    const [isLoading, setIsLoading] = useState(false);
    const options = useActiveEpisodesOptions();
    const { getSuggestedEpisodeNumber } = useNewEpisodeNumber(patient?.id);
    const episode = useMemo(
      () => options.find((option) => option.value === episodeId) || null,
      [options, episodeId]
    );
    const episodeNumber = getSuggestedEpisodeNumber(episodeId);

    const methods = useForm();

    const onSave = async (values: EpisodeEnrollFormFields) => {
      const isHistorical = methods.getValues('isHistorical');

      trackEnrollEpisodeModalAnalyticsEvent({
        action: AnalyticEventAction.StartEpisode,
        patient_id: patient.id,
        value: isHistorical ? 'historical episode' : null
      });

      setIsLoading(true);
      try {
        const createdEpisodeId = await patientEpisodesStore.createPatientEpisode(patient.id, {
          episodeId: values.episode.value,
          episodeNumber: values.episodeNumber,
          diagnosis: values.diagnosis,
          locationId: values.location?.value.id || null,
          treatment: values.treatment,
          notes: values.notes,
          ownerId: values.owner?.value || null,
          providerId: values.provider?.value.id || null,
          startDate: values.startDate,
          isHistorical: values.isHistorical,
          endDate: values.endDate
        });

        if (!createdEpisodeId) {
          return;
        }
        methods.reset();
        // we don't always have the patient episode model available in store when showing toast, therefore we pass a fallback to title
        showToast({
          message: `${
            patient.fullName
          } Enrolled Successfully in ${patientEpisodesStore.getEpisodeTitle(
            createdEpisodeId,
            new PatientEpisode({
              episodeId: values.episode.value,
              startDate: values.startDate,
              endDate: values.endDate
            })
          )}`
        });

        onSubmit();
      } finally {
        setIsLoading(false);
      }
    };

    const episodeTemplate = constantsStore.getEpisodeById(episodeId);
    const startDate = new Date();
    const startDateString = parseDateForInputField(startDate);
    let endDate: Date | string = null;

    if (episodeTemplate) {
      endDate = parseDateForInputField(getEpisodeEndDate(startDate, episodeTemplate));
    }

    const formDefaultValues = {
      startDate: startDateString,
      isHistorical: false,
      episode,
      episodeNumber,
      endDate
    };

    const closeModal = () => {
      trackEnrollEpisodeModalAnalyticsEvent({
        action: AnalyticEventAction.Cancel,
        patient_id: patient.id
      });

      onCancel();
    };

    return (
      <FormModal
        methods={methods}
        defaultValues={formDefaultValues}
        isOpen={isOpen}
        title={title}
        confirmActions={[
          {
            onClick: methods.handleSubmit(onSave, () =>
              trackEnrollEpisodeModalAnalyticsEvent({
                action: AnalyticEventAction.StartEpisode,
                patient_id: patient.id,
                value: 'missing fields'
              })
            ),
            text: isLoading ? 'Saving...' : 'Start Episode',
            disabled: isLoading
          }
        ]}
        closeAction={{
          onClick: closeModal,
          disabled: false
        }}
        secondaryAction={{
          type: 'subtext-labeled-checkbox',
          id: 'isHistoricalCheckbox',
          label: 'Historical Episode',
          name: 'isHistorical',
          subText: 'Will Not Create Tasks'
        }}
        resetDataAfterClose={resetDataAfterClose}
      >
        <CreateEditPatientEpisodeFormFields patient={patient} />
      </FormModal>
    );
  }
);
