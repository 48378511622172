import { createTheme } from '@mui/material';

import { spacingSizeArray } from 'components/UIkit/theme/spacing';

import { palette } from './colors';
import { components } from './components';
import { extraThemeOptions } from './extraThemeOptions';
import { typography } from './typography';

// these props can be access from theme (ex: theme.borderRadius.small)

// the following declaration make extend Theme, so we can use extraThemeOptions in our components
// see https://mui.com/material-ui/customization/theming/#custom-variables

export const theme = createTheme(
  {
    palette,
    components,
    typography,
    spacing: (value: number | string) => {
      if (value === 'auto') {
        return 'auto';
      }

      if (spacingSizeArray.includes(value as number)) {
        return value;
      }

      throw new Error(`Spacing with ${value} value is not from the spacing system`);
    }
  },
  extraThemeOptions
);
