export const getTypographyVariant = (size: 'small' | 'medium' | 'large') => {
  if (size === 'small') {
    return 'subtext';
  }

  if (size === 'medium') {
    return 'body1';
  }

  return 'h4';
};
