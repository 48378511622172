import React, { ForwardedRef, forwardRef } from 'react';

import { Grid } from '@mui/material';
import { css, styled } from '@mui/material/styles';

export const NotificationLoader = forwardRef((props, ref: ForwardedRef<any>) => {
  return (
    <div ref={ref}>
      <StyledGrid container p={12} wrap="nowrap" justifyContent="space-between">
        <Grid container flexDirection="column" item gap={16}>
          <StyledContentPlaceholder variant="content-full" />
          <StyledContentPlaceholder variant="content" />
        </Grid>
        <StyledContentPlaceholder variant="timestamp" />
      </StyledGrid>
      <StyledGrid container p={12} wrap="nowrap" justifyContent="space-between">
        <Grid container flexDirection="column" item gap={16}>
          <StyledContentPlaceholder variant="content-full" />
          <StyledContentPlaceholder variant="content" />
        </Grid>
        <StyledContentPlaceholder variant="timestamp" />
      </StyledGrid>
    </div>
  );
});

const StyledGrid = styled(Grid)`
  height: 92px;
`;

const StyledContentPlaceholder = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant'
})<{ variant?: 'content-full' | 'content' | 'timestamp' }>(({ theme, variant }) => {
  let selectedVariantStyles = css`
    width: 375px;
    height: 18px;
  `;

  if (variant === 'content') {
    selectedVariantStyles = css`
      width: 296px;
      height: 18px;
    `;
  }

  if (variant === 'timestamp') {
    selectedVariantStyles = css`
      width: 55px;
      height: 10px;
    `;
  }

  return css`
    border-radius: 23px;
    ${selectedVariantStyles}
    background: linear-gradient(to right, ${theme.palette.natural
      .inactiveBackground} 8%, #ebebeb 18%, ${theme.palette.natural.inactiveBackground} 33%);
    background-size: 800px 104px;
    animation: shimmer 3s ease-in-out infinite;

    @keyframes shimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }
  `;
});
