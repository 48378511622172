import { styled, css } from '@mui/material/styles';

export const Backdrop = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'zIndex' && prop !== 'isInvisible'
})<{ isOpen: boolean; zIndex?: number; isInvisible?: boolean }>(
  ({ isOpen, zIndex = 800, isInvisible = true }) => css`
    display: ${isOpen ? 'block' : 'none'};
    min-width: 100%;
    min-height: 100vh;
    background-color: ${isInvisible ? 'transparent' : 'rgba(0, 0, 0, 0.5)'};
    position: absolute;
    z-index: ${zIndex};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `
);
