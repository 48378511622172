import { observer } from 'mobx-react';

import { Tooltip } from 'components/Tooltip';

export const PatientProviderCell = observer(({ providerText }: { providerText: string }) => {
  return (
    <div className="text-truncate">
      <Tooltip disabled={!providerText} label={<span>{providerText}</span>}>
        <div className="p-3">
          <div>{providerText}</div>
        </div>
      </Tooltip>
    </div>
  );
});
