import { FC } from 'react';

import { Box, css, styled } from '@mui/material';

import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { TicketSummaryItemType } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton.types';
import { getSummaryItemDisplayName } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton.utils';

import { TextButton } from 'components/UIkit/atoms/Button';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  items?: Partial<Record<TicketSummaryItemType, number>>;
  onClick: () => void;
  isExpanded: boolean;
  order?: TicketSummaryItemType[];
}

export const TicketSummaryButton: FC<Props> = ({
  items = {},
  onClick,
  isExpanded,
  order = ['pathway', 'report', 'comment', 'draft', 'callAttempt', 'call']
}) => {
  const hasAtLeastOneItem = Object.values(items).some((item) => item > 0);

  return (
    <TextButton
      onClick={onClick}
      variant="secondary"
      mt={16}
      testHook={sharedTestSelectors.ticketShowMoreButton}
    >
      <Box display="flex" alignItems="center">
        <Text variant="body2" display="flex" width="70px" color="dark">
          {isExpanded ? 'show less' : 'show more'}
          {hasAtLeastOneItem ? ':' : ''}
        </Text>

        {order.map((ticketSummaryItemType) => {
          const count = items[ticketSummaryItemType] || 0;

          if (count && count > 0) {
            return (
              <StyledItem key={ticketSummaryItemType}>
                <span>
                  {
                    getSummaryItemDisplayName({ count, ticketSummaryItemType })[
                      ticketSummaryItemType
                    ]
                  }
                </span>
              </StyledItem>
            );
          }

          return null;
        })}
      </Box>
    </TextButton>
  );
};

const StyledItem = styled('span')(
  ({ theme }) => css`
    position: relative;

    &:not(:first-of-type) {
      margin-left: ${theme.spacing(12)};
    }

    &:not(:last-of-type) {
      padding-right: ${theme.spacing(12)};

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 20px;
        width: 1px;
        background-color: ${theme.palette.natural.border};
      }
    }
  `
);
