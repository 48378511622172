// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';

import { ActionMeta } from 'react-select';

import { useProvider, useProvidersOptions } from 'mobx/hooks/useProvidersOptions';

import Patient from 'models/Patient';

import PatientProvider from 'models/PatientProvider';

import { FormAutocomplete, FormAutocompleteInlineLabel } from 'components/UIkit/atoms/Dropdown';

// TODO: move this component  from tickets
interface IProps {
  fieldName: string;
  patient: Patient;
  isRequired?: boolean;
  isInline?: boolean;
  onChange?: (value: any, action: ActionMeta<any>, eventKey: string | null) => void;
}

const PatientProviderFormField: FC<IProps> = ({
  fieldName,
  patient,
  isRequired = false,
  isInline = false,
  onChange
}) => {
  const providersOptions = useProvidersOptions();
  const patientProvider = useProvider(patient?.providerId);

  // FormAutocompleteInlineLabel will removed after ticket type re-design
  const Autocomplete = isInline ? FormAutocompleteInlineLabel : FormAutocomplete;

  return (
    <Autocomplete
      isClearable={false}
      label="Provider"
      isRequired={isRequired}
      name={fieldName}
      options={providersOptions}
      getOptionLabel={(option: { value: PatientProvider; label: string }) =>
        option.value?.id === patient.providerId ? `Primary (${option.label})` : option.label
      }
      getOptionValue={(option: any) => option.value.id}
      defaultValue={patientProvider?.value ? patientProvider : null} // for some old emr patients their provider no longer exists in the system
      onChange={onChange}
    />
  );
};

export default observer(PatientProviderFormField);
