// @ts-strict-ignore
import { action, observable, makeObservable } from 'mobx';

import ObjectCopier from 'utils/ObjectCopier';

export default class PatientLocation extends ObjectCopier {
  id: number;

  @observable
  name: string;

  @observable
  visible: boolean;

  constructor(location?: any) {
    super();
    makeObservable(this);
    Object.assign(this, location);
  }

  @action
  setVisibility(visibility: boolean) {
    this.visible = visibility;
  }
}
