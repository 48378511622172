import { FC } from 'react';

import { Box, Grid } from '@mui/material';
import { Reorder } from 'framer-motion';

import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { MIN_OPTION_COUNT } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.constants';
import {
  StyledRemoveIcon,
  StyledVerticalSeparator
} from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.shared';
import { QuestionForm } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.types';

import StyledToggleButton, { LabelPosition } from 'views/Widgets/StyledToggleButton';

import Icon from 'components/Icons/Icon';
import { TextIconButton } from 'components/UIkit/atoms/Button';
import { FormRichText } from 'components/UIkit/atoms/RichText/FormRichText';
import { Text } from 'components/UIkit/atoms/Text';

export const SingleQuestionOptionsForm: FC = () => {
  const { control, formState, getValues, trigger } = useFormContext<QuestionForm>();
  const { errors } = formState;
  const {
    fields: options,
    remove: removeOption,
    replace: replaceOptions,
    append: addOption,
    update: updateOption
  } = useFieldArray<QuestionForm, `options`, 'rhfId'>({
    control,
    name: 'options',
    rules: {
      minLength: MIN_OPTION_COUNT,
      required: true
    },
    keyName: 'rhfId'
  });

  const onRemoveOption = (index: number) => {
    removeOption(index);
  };

  const onAddOption = () => {
    addOption({
      id: uuid(),
      title: ''
    });
  };

  const onReorder = (reorderedOptions: FieldArrayWithId<QuestionForm, 'options', 'rhfId'>[]) => {
    const currentOptions = getValues('options');
    const newOptions: FieldArrayWithId<QuestionForm, 'options', 'rhfId'>[] = reorderedOptions.map(
      (option) => ({
        ...option,
        title: currentOptions?.find((currentOption) => currentOption.id === option.id)?.title || ''
      })
    );
    replaceOptions(newOptions);
    trigger('options');
  };

  const handleToggleChange = (index: number, checked: boolean) => {
    const currentOptions = getValues('options');
    const currentOption = currentOptions![index];

    updateOption(index, {
      ...currentOption,
      isHomeCareInstructions: checked
    });
  };

  return (
    <>
      <Reorder.Group values={options} onReorder={onReorder} axis="y" as="div">
        {options.map((option, index) => {
          const showRemoveIcon = options.length > MIN_OPTION_COUNT;
          const fieldName = `options.${index}.title`;
          const error = errors.options ? errors.options[index]?.title : null;

          return (
            <Reorder.Item key={option.id} value={option} as="div" layout="position">
              <Grid container alignItems="center" justifyContent="space-between" mb={12} gap={8}>
                <Icon.RadioButton />
                <Grid item flex={1}>
                  <FormRichText
                    name={fieldName}
                    placeholder="Response"
                    isError={Boolean(error)}
                    variant="outlined"
                    isRequired
                  />
                </Grid>
                <StyledToggleButton
                  checked={option.isHomeCareInstructions}
                  label={
                    <Text variant="body3" mr={2}>
                      Homecare Instructions
                    </Text>
                  }
                  labelPosition={LabelPosition.RIGHT}
                  onChange={(event) => {
                    handleToggleChange(index, event.target.checked);
                  }}
                />
                <StyledVerticalSeparator />
                {showRemoveIcon && <StyledRemoveIcon onClick={() => onRemoveOption(index)} />}
              </Grid>
            </Reorder.Item>
          );
        })}
      </Reorder.Group>
      <Box pt={12}>
        <TextIconButton icon={<Icon.Plus />} onClick={onAddOption} variant="secondary">
          Add Response
        </TextIconButton>
      </Box>
    </>
  );
};
