// @ts-strict-ignore
import { computed, observable } from 'mobx';

import { shortDateStringWithoutTimeZone } from 'utils/DateUtils';
import { getNumberAsOrdinal } from 'utils/StringUtils';

import { TicketClinician } from 'models/Ticket';

import { PatientEpisodeDefaultEndReasons } from './PatientEpisodeEndReason';

export class PatientEpisode {
  id: number;
  episodeId: number;
  @observable
  episodeNumber: number;
  patientId: number;
  @observable
  startDate: string;
  @observable
  endDate: string;
  owner: TicketClinician;
  diagnosis: string;
  treatment: string;
  providerId: number;
  locationId: number;
  isHistorical: boolean;
  notes: string;
  @observable
  isEnded: boolean;
  @observable
  endedAt: string;
  endedBy: TicketClinician;
  @observable
  endReasonId: number;
  endReasonDetails: string;

  constructor(episode: any) {
    Object.assign(this, episode);
  }

  @computed
  get formattedStartDate(): string {
    return shortDateStringWithoutTimeZone(this.startDate);
  }

  @computed
  get formattedEndedDate(): string {
    return shortDateStringWithoutTimeZone(this.endedAt);
  }

  @computed
  get formattedEndDate(): string {
    if (!this.endDate) {
      return 'Unknown';
    }
    return shortDateStringWithoutTimeZone(this.endDate);
  }

  @computed
  get dateRange(): string {
    return `${this.formattedStartDate} - ${this.formattedEndDate}`;
  }

  @computed
  get isExpired() {
    return !this.endedAt && this.endDate && new Date(this.endDate) < new Date();
  }

  @computed
  get isMarkedAsError() {
    return this.isEnded && this.endReasonId === PatientEpisodeDefaultEndReasons.MARK_AS_ERROR_ID;
  }

  @computed
  get formattedEpisodeNumber(): string {
    if (!this.episodeNumber) {
      return '';
    }
    return getNumberAsOrdinal(this.episodeNumber);
  }
}

export default PatientEpisode;
