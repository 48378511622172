import { useStores } from 'mobx/hooks/useStores';

import { ISelectOption } from 'views/Widgets/StyledSelect';

export enum UserType {
  STANDARD = 'Standard',
  CHIEF_NURSE = 'Chief Nurse',
  ADMIN = 'Admin'
}

export const userTypeToOption = {
  [UserType.STANDARD]: UserType.STANDARD,
  [UserType.CHIEF_NURSE]: 'Manager',
  [UserType.ADMIN]: UserType.ADMIN
};

export const USER_TYPE_OPTIONS: ISelectOption<UserType>[] = Object.values(UserType).map(
  (status) => {
    return {
      label: userTypeToOption[status],
      value: status
    };
  }
);

export function useUserTypeOptions(): ISelectOption<UserType>[] {
  const { userStore } = useStores();
  const role = userStore.currentDoctor?.role || UserType.STANDARD;
  return USER_TYPE_OPTIONS.map((option) => {
    const isDisabled =
      (option.value === UserType.ADMIN && role !== UserType.ADMIN) ||
      (option.value === UserType.CHIEF_NURSE && role === UserType.STANDARD);
    return {
      ...option,
      isDisabled
    };
  });
}
