import { ISelectOption, SelectOnChangeHandler } from './Select.shared';

export interface FormProps {
  name: string;
  isRequired?: boolean;
  defaultValue?: any;
  options: Array<ISelectOption<any>>;
  validate?: (value: any) => boolean | string;
  onChange?: SelectOnChangeHandler;
  onEditClick?: (options?: ISelectOption<any>) => void;
  treatAddAsNormalOption?: boolean; // normally, "Add" option will not be set to form when selected (e.i. invoke popup but keep previous value). when treatAddAsNormalOption=true, Add option will be treated like any other option
}

// temporary solution until we replace nested error in ticket creation form
export function getErrorByName(errors: any, name: string) {
  // if nested error (ex: tickets[1_9_9].providerId for ticket type, or taskTicket.assignee for task type)
  const isNestedError = name.includes('[') || name.includes(']') || name.includes('.');

  if (!isNestedError) {
    return Boolean(errors[name]);
  } else {
    const keys = name.split(/[,.[\]]/).filter((str) => str.length > 0);
    let error: any = errors;
    keys.forEach((key) => {
      if (error) {
        error = error[key];
      }
    });
    return Boolean(error);
  }
}
