import { FC } from 'react';

import { styled } from '@mui/material';

import { observer } from 'mobx-react';

interface Props {
  isSelected: boolean;
  selectPathwayEditorBlock: () => void;
}

//this component is for transition from "view mode" to "edit mode"
export const BlockModeSeparator: FC<Props> = observer(
  ({ isSelected, selectPathwayEditorBlock }) => (
    <>
      {!isSelected && (
        <StyledContainer
          onClick={(event) => {
            event.stopPropagation();
            selectPathwayEditorBlock();
          }}
        />
      )}
    </>
  )
);

const StyledContainer = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
`;
