import { FC, useCallback, useMemo } from 'react';

import {
  trackDropdownFilterUsageAnalyticsEvent,
  trackPreselectedFilterUsageAnalyticsEvent,
  trackSearchByNameOrMrnFilterUsageAnalyticsEvent
} from 'analytics/events/filter-usage';

import debounce from 'debounce-promise';

import { observer } from 'mobx-react';
import { ActionMeta } from 'react-select';

import { useMount } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import {
  CARE_MANAGEMENT_ENROLLED_PATIENTS_FILTERS_LOCAL_STORAGE_KEY,
  CareManagementEnrolledFiltersType
} from 'mobx/stores';

import { SelectOption } from 'models/SelectOption';

import { useResetPageInUrl } from 'hooks/useNavPagination';

import SearchBar from 'views/Dashboard/SearchBar';
import CmEnrolledProvidersFilter from 'views/Filters/CmEnrolledProvidersFilter';
import { FilterName } from 'views/Filters/filters.types';
import { useSetPersistFilters } from 'views/Filters/useFilters';
import {
  DEBOUNCE_DELAY,
  useDebouncedCmEnrolledData
} from 'views/Pages/CareManagement/CareManagement.hooks';
import { CareManagementTabs, cmTabsToNames } from 'views/Pages/CareManagement/CareManagementPage';
import {
  enrolledPatientsFiltersToNames,
  ValueOf
} from 'views/Pages/CareManagement/CareManagementPage.utils';

import { MultiAutocomplete } from 'components/UIkit/atoms/Dropdown';

import './PageFilters.scss';

const useCareManagementEnrolledFilters = () => {
  const resetPageInUrl = useResetPageInUrl();
  const { careManagementReportStore } = useStores();
  const fetchCmEnrolledData = useDebouncedCmEnrolledData();

  const handleFiltersChanged = useCallback(
    (filters: CareManagementEnrolledFiltersType) => {
      careManagementReportStore.setEnrolledFilters(filters);
      resetPageInUrl();
      fetchCmEnrolledData();
    },
    [careManagementReportStore, resetPageInUrl, fetchCmEnrolledData]
  );

  const { updateFiltersByKey } = useSetPersistFilters<CareManagementEnrolledFiltersType>(
    careManagementReportStore.enrolledFilters,
    handleFiltersChanged,
    {
      localStorageKey: CARE_MANAGEMENT_ENROLLED_PATIENTS_FILTERS_LOCAL_STORAGE_KEY,
      excludeFieldKeys: ['nameOrMrn']
    }
  );

  return {
    updateFiltersByKey
  };
};

const CmEnrolledPatientsFilters: FC = () => {
  const { careManagementReportStore } = useStores();
  const { updateFiltersByKey } = useCareManagementEnrolledFilters();
  const { enrolledFilters } = careManagementReportStore;

  useMount(function trackAnalyticsEvent() {
    trackPreselectedFilterUsageAnalyticsEvent(enrolledFilters, enrolledPatientsFiltersToNames);
  });

  const onTrackSearchAnalyticsEvent = useMemo(() => {
    return debounce(
      (searchQuery: string) =>
        trackSearchByNameOrMrnFilterUsageAnalyticsEvent(
          searchQuery,
          cmTabsToNames[CareManagementTabs.ENROLLED_PATIENTS]
        ),
      DEBOUNCE_DELAY
    );
  }, []);

  const onDropdownFilterChange = (
    values: ValueOf<CareManagementEnrolledFiltersType>,
    filterKey: keyof CareManagementEnrolledFiltersType,
    filterName: FilterName,
    actionMeta: ActionMeta<any>
  ) => {
    trackDropdownFilterUsageAnalyticsEvent(
      actionMeta,
      filterName,
      cmTabsToNames[CareManagementTabs.ENROLLED_PATIENTS]
    );

    updateFiltersByKey(filterKey)(values);
  };

  return (
    <div className="page-filters">
      <SearchBar
        className="search"
        placeholder="Search by Name or MRN"
        searchValue={enrolledFilters.nameOrMrn}
        onSearchChanged={(value) => {
          onTrackSearchAnalyticsEvent(value);
          updateFiltersByKey('nameOrMrn')(value);
        }}
      />

      <CmEnrolledProvidersFilter
        value={enrolledFilters.providers}
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(values, 'providers', FilterName.Providers, actionMeta)
        }
      />

      <MultiAutocomplete
        label="Episodes"
        testHook="enroll-episode-filters"
        options={careManagementReportStore.cmStatusesForSelect(true)}
        value={enrolledFilters.episodes}
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(
            values as SelectOption<number>[],
            'episodes',
            FilterName.Episodes,
            actionMeta
          )
        }
      />
    </div>
  );
};

export default observer(CmEnrolledPatientsFilters);
