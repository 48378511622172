export class OptOutReason {
  id: number;
  name: string;
  institutionId: number;

  constructor(id: number, name: string, institutionId: number) {
    this.id = id;
    this.name = name;
    this.institutionId = institutionId;
  }

  static fromJson(json: any) {
    return new OptOutReason(json.id, json.name, json.institution_id);
  }
}
