// @ts-strict-ignore
import { FC, useEffect, useState } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackPathwayMenuAnalyticsEvent } from 'analytics/events/pathway-menu';
import { trackTabNavigationAnalyticsEvent } from 'analytics/events/tab-navigation';

import { observer } from 'mobx-react';

import { useDeepCompareEffect, useUnmount, useUpdateEffect } from 'react-use';

import useSet from 'react-use/lib/useSet';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { DxCode } from 'models/Conditions/SpecificCauseCondition';

import {
  PathwayDiagnosisAlertTypes,
  PathwayOptionOutcome,
  PathwayQuestionTypes,
  PathwaySearchResult
} from 'models/PathwayTemplates';
import Patient from 'models/Patient';

import ToggleBar from 'views/Widgets/ToggleBar';

import Icon from 'components/Icons/Icon';
import { MultipleValue } from 'components/LightweightPathway/LightweightPathway.types';
import CallAndPathwaysDropDown from 'components/Ticket/TicketRow/CallAndPathwaysDropDown';

import { PathwayQuestionsList } from './PathwayQuestionsList';
import PathwayTableOfContentView from './PathwayTableOfContentView';

import './PathwayView.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  patientId: number;
}

const ALL_QUESTIONS = 1;
const KEY_QUESTIONS = 2;

const toggleBarOptions = [
  { value: ALL_QUESTIONS, label: 'All Questions' },
  { value: KEY_QUESTIONS, label: 'Key Questions' }
];

const useDxAlertDetection = (patient: Patient) => {
  const [dxAlertsSet, dxAlertSetActions] = useSet(new Set());
  const { pathwaysStore, alertsStore, constantsStore } = useStores();
  const pathwayId = pathwaysStore.currentPathwayInfo?.id || null;

  useUpdateEffect(
    function detectDxAlert() {
      if (!pathwayId) {
        return;
      }
      if (!dxAlertsSet.has(pathwayId)) {
        // we have a question that might trigger dx alert for pathway
        const dxAlertQuestion = pathwaysStore.getCurrentDxAlertQuestion(pathwayId);
        const currentAnswer = pathwaysStore.getAnswerForQuestion(pathwayId, dxAlertQuestion?.id);

        if (dxAlertQuestion && !currentAnswer) {
          // check the symptoms that correspond to current pathway using causes constants(pathwayId)
          const symptomsForPathway = constantsStore.getSymptomsByPathwayId(pathwayId);

          const codesFromAlertsMap: Map<string, DxCode> = new Map();

          // find corresponding alerts dx codes from symptoms found
          symptomsForPathway.forEach((symptom) => {
            const alertingDxCodes = alertsStore.getDxAlertCodesForSymptom(
              symptom.id,
              patient.dxCodeIds
            );
            if (alertingDxCodes.length) {
              alertingDxCodes.forEach(
                (codeItem) =>
                  !codesFromAlertsMap.has(codeItem.code) &&
                  codesFromAlertsMap.set(codeItem.code, codeItem)
              );
            }
          });

          if (patient.dxCodes?.length) {
            // get the dx values for the pre-population

            const cancerDiagnosisAnswers = patient.dxCodes.map((codeItem) => {
              let alert = {};

              if (codesFromAlertsMap.has(codeItem.code)) {
                alert = {
                  outcome: PathwayOptionOutcome.CLINIC,
                  alertType: PathwayDiagnosisAlertTypes.Dx
                };
              }
              return {
                id: codeItem.code,
                value: codeItem.code,
                label: codeItem.text || codeItem.code,
                title: `From EMR: ${codeItem.text || codeItem.code}`,
                ...alert
              };
            });
            pathwaysStore.answerQuestion(dxAlertQuestion.id, cancerDiagnosisAnswers);
          }
        }
        dxAlertSetActions.add(pathwayId);
      }
    },
    [alertsStore, constantsStore, dxAlertSetActions, dxAlertsSet, pathwayId, pathwaysStore, patient]
  );
};

const PathwaysView: FC<Props> = (props) => {
  const { pathwaysStore, settingsStore, patientPageStore, callLoggingStore, ticketsStore } =
    useStores();
  const { currentPathwayInfo } = pathwaysStore;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isContentViewOpen, setContentViewOpen] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [keyQuestionsToggleState, setKeyQuestionsToggleState] = useState(ALL_QUESTIONS);
  const [prefilledPathwaysIds, setPrefilledPathwaysIds] = useState<string[]>([]);
  const { patient } = patientPageStore;
  const { isOpen, setIsOpen } = props;

  useEffect(
    function openViewIfPathwayChosen() {
      if (currentPathwayInfo) {
        setIsOpen(true);
      }
    },
    [currentPathwayInfo, setIsOpen]
  );

  useEffect(
    function openDropdownOnEmptyPathway() {
      if (isOpen && !currentPathwayInfo) {
        setTimeout(() => {
          setDropdownOpen(true);
        }, 500); // run after open transition
      }
    },
    [isOpen, currentPathwayInfo]
  );

  useUnmount(function clearPathwaysAnswers() {
    pathwaysStore.clearAnswers();
  });

  useDeepCompareEffect(
    function prefillLightweightPathwaysQuestions() {
      if (
        pathwaysStore.currentPathwayInfo?.id &&
        settingsStore.hasFeature(FEATURES.LIGHTWEIGHT_PATHWAYS) &&
        !prefilledPathwaysIds.includes(pathwaysStore.currentPathwayInfo.id)
      ) {
        if (pathwaysStore.currentPathwayAnsweredQuestions.length > 0) {
          const ticket =
            callLoggingStore.preselectedTicketIds &&
            callLoggingStore.preselectedTicketIds.length === 1 &&
            ticketsStore.ticketsMap.get(callLoggingStore.preselectedTicketIds[0]);

          if (ticket && ticket.operatorTicket?.pathwayAnswers?.length > 0) {
            setPrefilledPathwaysIds((prevState) => [
              ...prevState,
              pathwaysStore.currentPathwayInfo.id
            ]);

            const answeredQuestionIds: string[] = pathwaysStore.currentPathwayAnsweredQuestions.map(
              (answer) => answer.questionId
            );
            ticket.operatorTicket.pathwayAnswers.forEach((questionAnswer) => {
              if (answeredQuestionIds.includes(questionAnswer.questionId)) {
                return;
              }

              const questionForPathway = pathwaysStore.getQuestionForPathway(
                pathwaysStore.currentPathwayInfo.id,
                questionAnswer.questionId
              );

              if (questionForPathway) {
                answeredQuestionIds.push(questionForPathway.id);
                let formattedValue = questionAnswer.value;

                if (questionAnswer.questionType === PathwayQuestionTypes.MULTIPLE) {
                  formattedValue = (questionAnswer.value as MultipleValue).map((option) => ({
                    ...option,
                    optionId: option.id
                  }));
                }

                if (questionAnswer.questionType === PathwayQuestionTypes.SINGLE) {
                  formattedValue = { ...questionAnswer.value, optionId: questionAnswer.value.id };
                }

                pathwaysStore.answerQuestion(questionAnswer.questionId, formattedValue, true);
              }
            });
          }
        }
      }
    },
    [pathwaysStore.currentPathwayAnsweredQuestions]
  );

  useDxAlertDetection(patient);

  const handlePathwaySelected = (selectedPathway: PathwaySearchResult) => {
    pathwaysStore.setCurrentPathwayTemplate(selectedPathway);
    setDropdownOpen(false);
  };

  const handleClosePathwayBtnClicked = () => {
    trackPathwayMenuAnalyticsEvent({
      action: AnalyticEventAction.Select,
      value: 'closed pathway',
      virtual_page: 'call logger'
    });
    props.setIsOpen(false);
    setDropdownOpen(false);
  };

  const handleSideTabClicked = () => {
    props.setIsOpen(!props.isOpen);
  };

  const toggleDropdown = () => {
    trackPathwayMenuAnalyticsEvent({
      action: AnalyticEventAction.Click,
      tab: keyQuestionsToggleState === KEY_QUESTIONS ? 'Key Questions' : 'All Questions',
      virtual_page: 'call logger'
    });
    setDropdownOpen((prevValue) => !prevValue);
  };

  return (
    <div
      className={`pathway-view-container ${props.isOpen ? 'open' : 'closed'}`}
      data-test-hook="pathwayView"
      onClick={props.isOpen ? null : handleSideTabClicked}
    >
      {settingsStore.hasFeature(FEATURES.ACTIONS_LOG_CALLS_PATHWAYS) && (
        <div className="side-tab">
          <span className="side-tab-icon">
            <Icon.DoubleArrow left />
          </span>
          <span>START PATHWAY</span>
        </div>
      )}
      <div className="pathways-content-box">
        <div className="header-container">
          <CallAndPathwaysDropDown
            showSelected
            patientId={patient.id}
            onSelect={handlePathwaySelected}
            tooltipController={{
              visible: isDropdownOpen,
              onClickOutside: toggleDropdown
            }}
            label={
              <div className="header" onClick={toggleDropdown}>
                {currentPathwayInfo ? `Pathway: ${currentPathwayInfo.name}` : 'Select Pathway'}
                <Icon.Chevron className="ml-2" />
              </div>
            }
            virtualPage="call logger"
            tab={keyQuestionsToggleState === ALL_QUESTIONS ? 'All Questions' : 'Key Questions'}
          >
            <div className="pathway-tooltip-close-btn p-2" onClick={handleClosePathwayBtnClicked}>
              Close Pathway
            </div>
          </CallAndPathwaysDropDown>
          {pathwaysStore.isCurrentPathwayWithKeyQuestions && (
            <div className="key-questions-toggle-container">
              <ToggleBar
                id="questionsToggle"
                options={toggleBarOptions}
                size="small"
                isSquared
                itemClassName="square-toggle-item"
                selected={keyQuestionsToggleState}
                onOptionSelected={(option) => {
                  trackTabNavigationAnalyticsEvent({
                    action: AnalyticEventAction.TabSelect,
                    value: option === ALL_QUESTIONS ? 'All Questions' : 'Key Questions',
                    virtual_page: 'call logger'
                  });

                  setKeyQuestionsToggleState(option);
                }}
              />
            </div>
          )}
        </div>
        {currentPathwayInfo && (
          <div className="pathways-content">
            <div
              className={`table-of-content-section ${
                isContentViewOpen && currentPathwayInfo ? 'open' : 'closed'
              }`}
            >
              <PathwayTableOfContentView
                pathway={pathwaysStore.getPathwayTemplateById(currentPathwayInfo.id)}
                filterKeyQuestions={keyQuestionsToggleState === KEY_QUESTIONS}
                onQuestionClicked={(questionId: string) => {
                  setSelectedQuestionId(questionId);
                }}
              />
              <div
                className="sections-divider"
                onClick={() => setContentViewOpen((isOpen) => !isOpen)}
              >
                <div className="expand-btn" />
              </div>
            </div>
            <PathwayQuestionsList
              pathway={pathwaysStore.getPathwayTemplateById(currentPathwayInfo.id)}
              selectedQuestionId={selectedQuestionId}
              onCollapseClicked={handleClosePathwayBtnClicked}
              filterKeyQuestions={keyQuestionsToggleState === KEY_QUESTIONS}
              handlePathwaySelected={handlePathwaySelected}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(PathwaysView);
