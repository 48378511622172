// @ts-strict-ignore
import {
  CareManagementEnrolledResponse,
  CareManagementReportResponse
} from 'fetchers/responses/care-management.response';

import { CmPatient, EnrolledPatient } from 'models/CmPatient';
export interface ParsedCareManagementReport {
  eligiblePatients: CmPatient[];
  totalPages: number;
  currentPage: number;
  totalEligiblePatientsCount: number;
}

export interface ParsedCareManagementEnrolled {
  enrolledPatients: EnrolledPatient[];
  totalPages: number;
  currentPage: number;
}

export class CareManagementParser {
  static parseCareManagementReport(
    response: CareManagementReportResponse
  ): ParsedCareManagementReport {
    const eligiblePatients = response.cmPatients.map((cmPatient) => new CmPatient(cmPatient));

    return {
      eligiblePatients,
      totalPages: response.totalPages,
      currentPage: response.currentPage,
      totalEligiblePatientsCount: response.totalCmPatientsCount
    };
  }
  static parseCareManagementEnrolled(
    response: CareManagementEnrolledResponse
  ): ParsedCareManagementEnrolled {
    return {
      enrolledPatients: response.enrolledPatients.map((enrolledPatient) => ({
        id: enrolledPatient.patientId,
        ...enrolledPatient
      })),
      totalPages: response.totalPages,
      currentPage: response.currentPage
    };
  }
}

export default CareManagementParser;
