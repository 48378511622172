import { colors } from './_colors';

export const SearchCircle = ({ active }: { active?: boolean }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26">
      <g fill="none" fillRule="evenodd">
        <g fill={active ? colors.primaryMain : colors.textPrimary} fillRule="nonzero">
          <g>
            <g>
              <path
                d="M12.526.102c6.862 0 12.423 5.561 12.423 12.424 0 6.862-5.561 12.423-12.423 12.423-6.863 0-12.424-5.561-12.424-12.423C.102 5.663 5.663.102 12.526.102zm-.766 6.122c-3.087 0-5.587 2.5-5.587 5.587 0 3.087 2.5 5.587 5.587 5.587.944 0 1.862-.255 2.704-.714l.23-.128 2.347 2.347c.204.179.459.28.765.28l.13-.005c.254-.025.482-.122.635-.275.205-.204.307-.46.332-.714 0-.28-.127-.536-.306-.74l-2.321-2.321.153-.256c.612-.918.918-1.964.918-3.06 0-3.088-2.5-5.588-5.587-5.588zm0 1.837c2.067 0 3.725 1.684 3.725 3.725 0 2.04-1.658 3.724-3.725 3.724-2.04 0-3.724-1.658-3.724-3.724 0-2.041 1.658-3.725 3.724-3.725z"
                transform="translate(-413 -466) translate(0 446.784) translate(128 9.5) translate(285 10)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
