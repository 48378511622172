// @ts-strict-ignore
import { FC, ReactNode } from 'react';

import { Box, css, styled } from '@mui/material';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { ExtendedTicket } from 'utils/TicketClusteringUtils';

import { CollapsibleSection } from 'views/Widgets/CollapsibleSection';

import { useSectionExpand } from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

import { Section } from './TicketsContainers.constants';

import TicketsList from './TicketsList';

import './TicketsSection.scss';

interface IProps {
  section: Section | string;
  tickets: ExtendedTicket[];
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
  header?: string | JSX.Element;
  extraActions?: JSX.Element;
  emptyState?: ReactNode;
  shouldHighlight?: boolean;
}

// base ticket section (used by section variants: TicketsSectionAssign, TicketsSectionUrgent etc.)
const TicketsSection: FC<IProps> = ({
  section,
  tickets,
  ticketRefsCallback,
  header,
  extraActions,
  shouldHighlight = false,
  emptyState
}) => {
  const [isExpanded, setIsExpanded] = useSectionExpand(section);

  if (!header) {
    const count = tickets.reduce((count, ticketObj) => {
      if (
        ticketObj.ticket.isOperatorTicket ||
        ticketObj.ticket.isTask ||
        ticketObj.ticket.isCallbackRequestTicket
      ) {
        return count + 1;
      }
      return count + ticketObj.reports.length;
    }, 0);

    header = `${section} (${count})`;
  }

  return (
    <div className={classNames('report-section', { highlight: shouldHighlight })}>
      <CollapsibleSection
        name={section}
        trigger={
          <div className="report-section-header" data-test-hook={`${section}_header`}>
            {header}
          </div>
        }
        onTrigger={setIsExpanded}
        initiallyOpen={isExpanded}
      >
        <StyledWrapper>{extraActions && extraActions}</StyledWrapper>

        <TicketsList
          tickets={tickets}
          emptyState={emptyState}
          ticketRefsCallback={ticketRefsCallback}
        />
      </CollapsibleSection>
    </div>
  );
};

const StyledWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${theme.spacing(20)};
  `
);

export default observer(TicketsSection);
