// @ts-strict-ignore
import { FunctionComponent } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import Patient from 'models/Patient';
import QuestionnaireAnswer, {
  IReportedInputQuestion,
  IReportedYesNoQuestion
} from 'models/QuestionnaireAnswer';
import SeverityReportedCause from 'models/ReportedCause';

import { getDeltaForCause } from 'components/Ticket/TicketRow/shared';

interface Props {
  report: QuestionnaireAnswer;
  patient: Patient;
}

const CovidReport: FunctionComponent<Props> = ({ report }) => {
  const { constantsStore } = useStores();
  let covidCauses = [];
  let inputQuestions = [];
  let positiveYesNoQuestions = [];
  let emptyCovidReport = false;

  positiveYesNoQuestions = report.answer.yesNoQuestions.filter(
    (question: IReportedYesNoQuestion) => question.value === true
  );
  covidCauses = report.answer.causes;
  inputQuestions = report.answer.inputQuestions;
  if (
    positiveYesNoQuestions.length === 0 &&
    covidCauses.length === 0 &&
    inputQuestions.length === 0
  ) {
    emptyCovidReport = true;
  }

  return (
    <div>
      {emptyCovidReport && <span>No Known Exposure or Symptoms</span>}

      {(covidCauses.length > 0 ||
        positiveYesNoQuestions.length > 0 ||
        inputQuestions.length > 0) && (
        <span>
          <b>COVID-19: </b>
        </span>
      )}

      {inputQuestions.length > 0 && (
        <span>
          {inputQuestions.map(
            (question: IReportedInputQuestion, j: number) =>
              `${question.questionText}: ${
                question.answer === 0 ? 'Temp Unknown' : question.answer
              }${
                j !== inputQuestions.length - 1 ||
                positiveYesNoQuestions.length > 0 ||
                covidCauses.length > 0
                  ? ', '
                  : ''
              }`
          )}
        </span>
      )}

      {covidCauses.length > 0 && (
        <div>
          {covidCauses
            .map((cause: SeverityReportedCause) => {
              const causeName: string = constantsStore.getCauseNameById(cause.causeId);

              return `${SeverityReportedCause.getSeverityText(
                cause.severity
              )} ${causeName}${getDeltaForCause(cause)}`;
            })
            .join(', ') + (positiveYesNoQuestions.length > 0 ? ', ' : '')}
        </div>
      )}

      {positiveYesNoQuestions.length > 0 && (
        <div>
          {positiveYesNoQuestions
            .map((question: IReportedYesNoQuestion) => `${question.questionText}`)
            .join(', ')}
        </div>
      )}
    </div>
  );
};

export default CovidReport;
