import React, { FC } from 'react';

import classNames from 'classnames';

import { formatDate } from 'utils/DateUtils';

import { CmPatient } from 'models/CmPatient';

import { TableCellParams } from 'components/Table';

import TooltipTableHeader from 'components/Table/TooltipHeader';

import './care-management-table-cells.scss';

export const VisitsHeader: FC = () => (
  <TooltipTableHeader
    tooltipText="Visits in the clinic over the past 12 months."
    headerText="Visits"
  />
);

export const MonthlyCareHeader: FC = () => (
  <TooltipTableHeader
    tooltipText="Monthly Care is the max monthly care time accumulated on each patient by qualified users in the last 3 months."
    headerText="Monthly Care"
  />
);

export const NoData = () => <>-</>;

export const MultilineExpandableCell = (cellParams: TableCellParams<CmPatient>) => {
  return <BaseExpandableCell {...cellParams} multiLine />;
};

export const ExpandableCell = (cellParams: TableCellParams<CmPatient>) => {
  return <BaseExpandableCell {...cellParams} />;
};

const BaseExpandableCell = (params: TableCellParams<CmPatient> & { multiLine?: boolean }) => {
  let cellData = params.value;
  if (Array.isArray(params.value)) {
    cellData = params.value.join(',');
  }
  if (!cellData) {
    return <NoData />;
  }
  const expanded = params.row.isExpanded;
  return (
    <div
      className={classNames('expandable-cell', {
        'text-truncate': !expanded
      })}
    >
      <span
        className={classNames({
          'multi-line': params.multiLine && !expanded
        })}
      >
        {cellData}
      </span>
    </div>
  );
};

const generateList = (list: string[]) => {
  return (
    <ul>
      {list.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

export const DiagnosesCell = (cellParams: TableCellParams<CmPatient>) => {
  const { cancerDx, chronicDx } = cellParams.value;
  if (!cancerDx && !chronicDx) {
    return <span className="no-diagnosis">No Diagnosis</span>;
  }

  const noDiagnosis = <span className="no-diagnosis">None</span>;
  let cancerDxData = noDiagnosis;
  let chronicDxData = noDiagnosis;
  const { isExpanded } = cellParams.row;

  if (cancerDx) {
    cancerDxData = isExpanded ? generateList(cancerDx) : cancerDx.join(',');
  }

  if (chronicDx) {
    chronicDxData = isExpanded ? generateList(chronicDx) : chronicDx.join(',');
  }

  const dxTypeClasses = [
    'dx-type-title',
    {
      'mb-2': isExpanded
    }
  ];

  return (
    <div className="d-flex flex-column diagnoses-cell">
      <span
        className={classNames({
          'text-truncate': !isExpanded || !Boolean(cancerDx),
          'mb-3': isExpanded,
          'flex-column d-flex': isExpanded && Boolean(cancerDx)
        })}
      >
        <span className={classNames(...dxTypeClasses)}>Cancer: </span>
        <span>{cancerDxData}</span>
      </span>
      <span
        className={classNames({
          'text-truncate': !isExpanded || !Boolean(chronicDx),
          'mb-3': isExpanded,
          'd-flex flex-column': isExpanded && Boolean(chronicDx)
        })}
      >
        <span className={classNames(...dxTypeClasses)}>Chronic: </span>
        <span>{chronicDxData}</span>
      </span>
    </div>
  );
};

export const NextVisitCell = (cellParams: TableCellParams<CmPatient>) => {
  const {
    value,
    row: { isExpanded }
  } = cellParams;
  const { nextVisitLocation, nextVisitDate, nextVisitProvider } = value;
  const noLocationAndProvider = !nextVisitLocation && !nextVisitProvider;

  if (!nextVisitDate && noLocationAndProvider) {
    return <NoData />;
  }

  const renderLocationProvider = () => {
    if (!nextVisitProvider && !nextVisitLocation) {
      return null;
    }

    if (nextVisitProvider && !nextVisitLocation) {
      return <span>{nextVisitProvider}</span>;
    }

    if (!nextVisitProvider && nextVisitLocation) {
      return <span>{nextVisitLocation}</span>;
    }

    return (
      <>
        <span>{nextVisitProvider}</span>
        <span> at {nextVisitLocation}</span>
      </>
    );
  };

  const itemClasses = classNames({
    'text-truncate': !isExpanded
  });

  return (
    <div className="d-flex flex-column">
      {nextVisitDate && (
        <span className={itemClasses}>{formatDate(nextVisitDate, 'MM/DD/YYYY')}</span>
      )}
      <span className={itemClasses}>{renderLocationProvider()}</span>
    </div>
  );
};
