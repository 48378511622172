import { FC } from 'react';

import { Box, css, styled } from '@mui/material';

import { QuestionTitle } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayQuestionTitle/QuestionTitle';

import { StyledTextareaPresentation } from 'views/Pages/PathwayBuilder/shared/styled';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  title: string;
}

export const CancerDiagnosisQuestion: FC<Props> = ({ title }) => (
  <StyledQuestionCard mt={20}>
    <QuestionTitle title={title} isDisabled />

    <StyledTextareaPresentation>
      <Text color="disabled" variant="form-text">
        Automated text from EMR
      </Text>
    </StyledTextareaPresentation>
  </StyledQuestionCard>
);

const StyledQuestionCard = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    border-radius: ${theme.borderRadius.large};
    padding: ${theme.spacing(20)};
    background-color: ${theme.palette.natural.white};
    position: relative;
    border: 1px solid ${theme.palette.natural.border};
  `
);
