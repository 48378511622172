import { FC } from 'react';

import './DefaultEmptyTableView.scss';

const DefaultEmptyTableView: FC = () => {
  return (
    <div className="empty-results-message font-2xl font-weight-bold text-align-center mt-5">
      No Results
    </div>
  );
};

export default DefaultEmptyTableView;
