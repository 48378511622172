import { FC } from 'react';

import { Grid } from '@mui/material';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { FeatureIntroCodes } from 'models/Doctor';

import { FilledButton } from 'components/UIkit/atoms/Button';

import './CareTimerIntroModal.scss';

interface CareTimerTutorialProps {
  onFinished: () => void;
}

const CareTimerTutorial: FC<CareTimerTutorialProps> = ({ onFinished }) => {
  const { userStore, uiStore } = useStores();
  const handleIntroAcknowledged = async () => {
    await userStore.updateIntroFinished(FeatureIntroCodes.CareTimer);
    uiStore.setIntro(false);
    onFinished();
  };
  return (
    <div className="care-timer-intro-modal">
      <span className="explenation-row">
        <b>• Documentation Timer is now Care Timer, and it has a new home.</b>
      </span>
      <span className="explenation-row">
        • Care Timer automatically turns on when you visit a Patient Page, and turns off when you
        leave.
      </span>
      <span className="explenation-row mb-3">
        • Click once to pause, click again to edit / resume.
      </span>

      <Grid container justifyContent="flex-end">
        <FilledButton onClick={handleIntroAcknowledged}>Got it</FilledButton>
      </Grid>
    </div>
  );
};

export default observer(CareTimerTutorial);
