// @ts-strict-ignore

import { FC, useState } from 'react';

import { Dialogs } from 'analytics/events/dialog';
import { observer } from 'mobx-react';

import { useForm } from 'react-hook-form';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { showToast } from 'utils/UserMessageUtils';

import { API_URLS } from 'constants/apiUrls';

import { useRoleOptions } from 'hooks/useRole';

import TaskTemplateModalFields from 'views/Pages/PracticeManagement/Episodes/TaskTemplateModalFields';
import { useEpisodeDurationSelectOptions } from 'views/Pages/PracticeManagement/Episodes/useEpisodeDuration';
import { ISelectOption } from 'views/Widgets/StyledSelect';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  isInternalModalOpen: boolean;
  taskTemplateId: number;
  onSubmit: () => void;
  onCancel: () => void;
  onEditCreateRole: (roleId: number) => void;
}

interface EditTaskTemplateFormFields {
  id: number;
  name: string;
  role: ISelectOption<number> | null;
  monthNumber: ISelectOption<number> | null;
  description: string;
  isBillable: boolean | null;
}

const EditTaskTemplateModal: FC<Props> = ({
  isOpen,
  isInternalModalOpen,
  taskTemplateId,
  onCancel,
  onSubmit,
  onEditCreateRole
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const {
    episodeManagementStore: {
      currentFilteredEpisodesMap,
      taskTemplatesMap,
      updateTaskTemplate,
      deleteTaskTemplate
    }
  } = useStores();
  const taskTemplate = taskTemplatesMap.get(taskTemplateId);
  const episode = currentFilteredEpisodesMap.get(taskTemplate?.episodeId);
  const monthOptions = useEpisodeDurationSelectOptions(episode);
  const roleOptions = useRoleOptions();
  const isLoading = useNetworkLoading(API_URLS.UPDATE_TASK_TEMPLATE(taskTemplateId));
  const methods = useForm<EditTaskTemplateFormFields>();

  const { handleSubmit } = methods;

  const prepareBodyForRequest = (taskTemplateForm: EditTaskTemplateFormFields) => {
    return {
      name: taskTemplateForm.name,
      roleId: taskTemplateForm.role.value,
      monthNumber: taskTemplateForm.monthNumber.value,
      description: taskTemplateForm.description,
      episodeId: taskTemplate.episodeId,
      isBillable: taskTemplateForm.isBillable
    };
  };

  const handleSaveClicked = async (formData: EditTaskTemplateFormFields) => {
    const body = prepareBodyForRequest(formData);
    await updateTaskTemplate(taskTemplateId, body);
    showToast({ message: 'Task Updated' });
    onSubmit();
  };

  const handleDeleteClicked = () => {
    setIsDeleteOpen(true);
  };

  const handleDelete = async () => {
    onCancel();
    await deleteTaskTemplate(taskTemplateId);
    showToast({ message: 'Task Deleted' });
    setIsDeleteOpen(false);
  };

  const title = `Edit ${episode?.name} Task`;

  const formDefaultValues = {
    id: taskTemplateId,
    name: taskTemplate?.name,
    role: roleOptions.find((option) => option.value === taskTemplate?.roleId),
    monthNumber: monthOptions.find((option) => option.value === taskTemplate?.monthNumber),
    description: taskTemplate?.description,
    isBillable: episode?.isCareManagement ? taskTemplate?.isBillable : null
  };

  return (
    <FormModal
      methods={methods}
      defaultValues={formDefaultValues}
      isOpen={isOpen}
      isInternalModalOpen={isInternalModalOpen}
      title={title}
      subtitle="Editing task templates will not apply to patients already enrolled in this episode (except ‘Required for Billing’)."
      confirmActions={[
        {
          onClick: handleSubmit(handleSaveClicked),
          text: isLoading ? 'Saving...' : 'Save',
          disabled: isLoading
        }
      ]}
      closeAction={{ onClick: onCancel, disabled: false }}
      secondaryAction={{
        onClick: handleDeleteClicked,
        text: 'Delete',
        disabled: isLoading,
        type: 'button'
      }}
    >
      <MessageDialog
        id={Dialogs.DeleteTask}
        isOpen={isDeleteOpen}
        title="Delete Task"
        handleClose={() => setIsDeleteOpen(false)}
        primaryActionProps={{ text: 'Delete Task', onClick: handleDelete }}
        secondaryActionProps={{ text: 'Cancel', onClick: () => setIsDeleteOpen(false) }}
      >
        This action cannot be undone. Tasks are not deleted for patients who are already enrolled in
        this episode.
      </MessageDialog>

      <TaskTemplateModalFields
        monthOptions={monthOptions}
        onEditCreateRole={onEditCreateRole}
        episode={episode}
      />
    </FormModal>
  );
};

export default observer(EditTaskTemplateModal);
