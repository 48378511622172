import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { getCurrentPageByUrl, getRecordsPerPageByUrl, getURLSearchParams } from 'utils/urlUtils';

import { FIRST_PAGE, PAGE_PARAM } from 'constants/urlParams.const';

function useSetPageInUrl() {
  const history = useHistory();

  return useCallback(
    (page: number) => {
      const currentPage = getCurrentPageByUrl();

      if (page === currentPage) {
        return;
      }
      const queryParams = getURLSearchParams();
      if (page === FIRST_PAGE) {
        queryParams.delete(PAGE_PARAM);
      } else {
        queryParams.set(PAGE_PARAM, (page + 1).toString()); // page  = url  page-value -1
      }
      history.replace({
        pathname: history.location.pathname,
        search: queryParams.toString()
      });
    },
    [history]
  );
}

export function useResetPageInUrl() {
  const setPage = useSetPageInUrl();
  return () => setPage(FIRST_PAGE);
}

// page value start from 0, while url page value starts from 1
export function useNavPagination() {
  const currentPageByUrl = getCurrentPageByUrl();
  const recordsPerPageByUrl = getRecordsPerPageByUrl();
  const setPageInUrl = useSetPageInUrl();

  return {
    currentPageByUrl,
    setPageInUrl,
    recordsPerPageByUrl
  };
}
