import { ButtonHTMLAttributes, forwardRef } from 'react';

import classNames from 'classnames';

import './TextButton.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  // custom props can be added here
}

const TextButton = forwardRef<HTMLButtonElement, IProps>((props, ref) => {
  const { disabled, onClick, children, className, title } = props;
  const buttonClasses = classNames('text-button', className, { disabled });
  return (
    <button ref={ref} className={buttonClasses} disabled={disabled} onClick={onClick} title={title}>
      {children}
    </button>
  );
});

export default TextButton;
