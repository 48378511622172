import Episode from 'models/Episode';

export const useEpisodeDurationSelectOptions = (episode: Episode) => {
  return Array.from(Array(episode?.duration))
    .map((e, i) => i + 1)
    .map((monthNumber, index) => ({
      label: `Month ${monthNumber}${index === 0 ? ' (Start of Episode)' : ''}`,
      value: monthNumber
    }));
};

export const useEpisodeDurationTooltipOptions = (
  episode: Episode,
  onClicked: (monthNumber: number) => void
) => {
  const options = useEpisodeDurationSelectOptions(episode);
  return options.map((option) => {
    return {
      text: option.label,
      id: String(option.value),
      onClick: () => onClicked(option.value)
    };
  });
};
