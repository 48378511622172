import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useQuery } from './useQuery';

export const TOGGLE_PARAM = 'edit';

type UseToggleReturnType = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  toggle: () => void;
};

export const useToggle = (
  initialState: boolean,
  onToggle?: (isOpen: boolean) => void
): UseToggleReturnType => {
  const [isOpen, setIsOpen] = useState(initialState);
  const toggle = () => {
    if (onToggle) {
      onToggle(!isOpen);
    }
    setIsOpen((currentState) => !currentState);
  };

  return { isOpen, setIsOpen, toggle };
};

export function useToggleWithRoute() {
  const history = useHistory();
  const queryParams = useQuery<{ TOGGLE_PARAM: boolean }>();
  const toggleOpenByRoute = queryParams.get(TOGGLE_PARAM) === 'true';
  const toggle = useToggle(toggleOpenByRoute);

  useEffect(function openToggleByRoute() {
    if (toggleOpenByRoute) {
      queryParams.delete(TOGGLE_PARAM);

      history.replace({
        pathname: history.location.pathname,
        search: queryParams.toString()
      });

      if (!toggle.isOpen) {
        toggle.toggle();
      }
    }
  });

  return toggle;
}
