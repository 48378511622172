// @ts-strict-ignore

import { FC } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackEditTimerModalAnalyticsEvent } from 'analytics/events/edit-timer-modal';

import { observer } from 'mobx-react';

import { useUpdateEffect } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { DurationInterval } from 'models/DurationInterval';

import { QuickAddition } from 'models/QuickAddition';

import DefaultModal from 'views/Modals/BaseModal/DefaultModal';

import Icon from 'components/Icons/Icon';
import QuickAdditionsSelectReasonModal from 'components/IntervalsEditor/QuickAdditionsSelectReasonModal';
import { Separator } from 'components/Separator';

import { Tooltip } from 'components/Tooltip';

import { useIntervalContext } from './IntervalsContext';
import { IntervalsEditor } from './IntervalsEditor';
import { getDeleted, resetBackToInitial } from './IntervalsEditor.shared';
import { QuickAdditionsEditor } from './QuickAdditionsEditor';

export interface IntervalsEditorResult {
  intervals: DurationInterval[];
  deletedIntervals: DurationInterval[];
  quickAdditions?: QuickAddition[];
  deletedQuickAdditions?: QuickAddition[];
}

interface Props {
  isOpen: boolean;
  title: string;
  subtitle?: string;
  isQuickAddition?: boolean;
  virtualPage?: 'call logger';
  onSave: (result: IntervalsEditorResult) => void;
  onClose: () => void;
}

const IntervalsEditorModal: FC<Props> = ({
  isOpen,
  title,
  isQuickAddition,
  virtualPage,
  onSave,
  onClose,
  subtitle
}) => {
  const {
    isValid,
    intervalCtx,
    quickAdditionsCtx,
    quickAdditionsEditedReason,
    setLastEditedInterval,
    setQuickAdditionIdsWithoutReasons
  } = useIntervalContext();

  const { userStore } = useStores();
  useUpdateEffect(() => {
    if (isOpen) {
      // if modal is reopened - reset items back to initialIntervals;
      resetBackToInitial(intervalCtx);
      resetBackToInitial(quickAdditionsCtx);
      trackEditTimerModalAnalyticsEvent({
        action: AnalyticEventAction.Open,
        virtual_page: 'call logger'
      });
    }
  }, [isOpen]);

  const handleOnSave = () => {
    if (isValid) {
      const quickAdditionsWithoutReason = quickAdditionsCtx.current?.filter(
        (quickAddition) => !quickAddition.reason
      );

      if (quickAdditionsWithoutReason?.length > 0) {
        trackEditTimerModalAnalyticsEvent({
          action: AnalyticEventAction.Save,
          virtual_page: virtualPage,
          value: 'missing fields'
        });
        setQuickAdditionIdsWithoutReasons(
          quickAdditionsWithoutReason.map((quickAddition) => quickAddition.uniqueIdentifier)
        );
        return;
      }

      onSave({
        intervals: intervalCtx.current,
        deletedIntervals: getDeleted(intervalCtx),
        quickAdditions: quickAdditionsCtx.current,
        deletedQuickAdditions: getDeleted(quickAdditionsCtx)
      });
      setQuickAdditionIdsWithoutReasons([]);
      setLastEditedInterval(null);
      trackEditTimerModalAnalyticsEvent({
        action: AnalyticEventAction.Save,
        virtual_page: virtualPage
      });
    }
  };

  return (
    <>
      <QuickAdditionsSelectReasonModal isOpen={Boolean(quickAdditionsEditedReason)} />

      {!quickAdditionsEditedReason && (
        <DefaultModal
          title={title}
          subtitle={subtitle}
          isOpen={isOpen}
          positiveBtnText="Save"
          onPositiveAction={handleOnSave}
          onNegativeAction={() => {
            onClose();
            trackEditTimerModalAnalyticsEvent({
              action: AnalyticEventAction.Cancel,
              virtual_page: virtualPage
            });
          }}
          positiveDisabled={!isValid}
          unmountOnClose
        >
          {isOpen && <IntervalsEditor virtualPage={virtualPage} doctor={userStore.currentDoctor} />}
          {isOpen && isQuickAddition && (
            <>
              <div className="position-relative">
                <div className="quick-addition-seperator-container">
                  <span className="quick-addition-text">Quick Additions</span>
                  <Tooltip label={<Icon.Info />}>
                    <div className="p-3">
                      Quick Additions make it easier to add to today’s Care Timer duration, without
                      specifying Start Time and End Time.
                    </div>
                  </Tooltip>
                </div>
                <Separator />
              </div>
              <QuickAdditionsEditor />
            </>
          )}
        </DefaultModal>
      )}
    </>
  );
};

export default observer(IntervalsEditorModal);
