import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { ProvidersParser } from 'parsers/ProvidersParser';

import PatientProvider from 'models/PatientProvider';

const httpService = new HttpService('Providers');

class ProvidersFetcher {
  static async getPatientProviders(): Promise<PatientProvider[]> {
    return await httpService.get({
      url: API_URLS.PROVIDERS,
      transformResponse: ProvidersParser.parseProviders
    });
  }
}
export default ProvidersFetcher;
