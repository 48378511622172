import { FC, useEffect, useMemo } from 'react';

import { AnalyticEventAction } from 'analytics';

import {
  trackDropdownFilterUsageAnalyticsEvent,
  trackFilterUsageAnalyticsEvent
} from 'analytics/events/filter-usage';

import debounce from 'debounce-promise';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { getActiveEpisodeNumbers } from 'utils/EpisodesUtils';

import Episode from 'models/Episode';

import { SelectOption } from 'models/SelectOption';

import SearchBar from 'views/Dashboard/SearchBar';
import { ManageEpisodesFilterField } from 'views/Filters/FilterFields';
import { FilterName } from 'views/Filters/filters.types';
import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';

const EpisodeManagementFilters: FC = () => {
  const {
    episodeManagementStore: {
      episodesQuery,
      searchAndSetEpisodes,
      setSearchTerm,
      setEpisodeNameFilter,
      episodeNameFilter
    }
  } = useStores();

  const { searchTerm } = episodesQuery;

  const debouncedSearchEpisodes = useMemo(
    () => debounce(searchAndSetEpisodes, 400),
    [searchAndSetEpisodes]
  );

  useEffect(
    function searchEffect() {
      // do not run search on initial value
      if (searchTerm === null) {
        return;
      }

      debouncedSearchEpisodes();
    },
    [debouncedSearchEpisodes, searchTerm]
  );

  const onTrackSearchAnalyticsEvent = useMemo(() => {
    return debounce((searchQuery) => {
      trackFilterUsageAnalyticsEvent({
        action: !searchQuery ? AnalyticEventAction.Clear : AnalyticEventAction.SearchedByTasks,
        value: FilterName.SearchByTask
      });
    }, DEBOUNCE_DELAY);
  }, []);

  return (
    <div className="page-filters episode-management-filters">
      <ManageEpisodesFilterField
        value={episodeNameFilter}
        onChange={(values, actionMeta) => {
          trackDropdownFilterUsageAnalyticsEvent(actionMeta, FilterName.Episodes);
          setEpisodeNameFilter(values);
        }}
      />

      <SearchBar
        className="search"
        placeholder="Search Tasks"
        searchValue={episodesQuery.searchTerm || ''}
        onSearchChanged={(searchTerm) => {
          onTrackSearchAnalyticsEvent(searchTerm);
          setSearchTerm(searchTerm);
        }}
        testHook="practiceManagerTasksFilter"
      />
    </div>
  );
};

export default observer(EpisodeManagementFilters);

export const useEpisodesManagementOptions = (): SelectOption<number>[] => {
  const { episodeManagementStore } = useStores();
  return episodeManagementStore.allEpisodesMap.items.map((episode: Episode) => ({
    value: episode.id,
    label: episode.name
  }));
};

export const useEpisodesNumberOptions = (patientId: number): SelectOption<number>[] => {
  const { patientEpisodesStore } = useStores();
  return getActiveEpisodeNumbers(patientEpisodesStore.episodesForPatient(patientId)).map(
    (episodeNumber: number) => ({
      value: episodeNumber,
      label: episodeNumber.toString()
    })
  );
};
