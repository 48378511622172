import { UrlSortParams } from 'utils/urlUtils';

export enum CmPatientColumns {
  PatientName = 'patientName',
  ProviderName = 'providerName',
  Visits = 'visits',
  PrimaryInsurance = 'primaryInsurance',
  SecondaryInsurance = 'secondaryInsurance',
  MonthlyCareTimeMinutes = 'monthlyCareTimeMinutes',
  NextVisit = 'nextVisit',
  Diagnoses = 'diagnoses',
  CmStatus = 'cmStatus'
}

export enum CmPatientSortingValues {
  PatientName = 'name',
  ProviderName = 'provider',
  Visits = 'visits',
  PrimaryInsurance = 'primaryInsurance',
  SecondaryInsurance = 'secondaryInsurance',
  MonthlyCareTimeMinutes = 'monthlyCareTimeMinutes',
  NextVisit = 'nextVisitDate',
  CareStatus = 'careStatus'
}

export enum CmEnrolledPatientSortingValues {
  PatientName = 'name',
  ProviderName = 'provider',
  Visits = 'visits',
  Episode = 'episode',
  CareTimer = 'careTime'
}

export const defaultEnrolledCmSorting: UrlSortParams = {
  sortBy: CmEnrolledPatientSortingValues.CareTimer,
  sortAsc: false
};

export const defaultCmSorting: UrlSortParams = {
  sortBy: CmPatientColumns.Visits,
  sortAsc: false
};

// corresponds to server enum
export enum CareManagementInsurance {
  Any = -1,
  None = -2
}
