import { FC } from 'react';

import { Box, styled } from '@mui/material';
import { css } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { showToast } from 'utils/UserMessageUtils';

import StyledInput from 'views/Widgets/StyledInput';

import FormTextAreaField from 'components/Forms/FormTextAreaField';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  isOpen: boolean;
  email: string;
  onClose: () => void;
}

interface ContactSupportFields {
  message: string;
}
export const ContactSupportModal: FC<Props> = observer(({ isOpen, email, onClose }) => {
  const methods = useForm<ContactSupportFields>();
  const isLoading = methods.formState.isSubmitting;
  const { userStore } = useStores();
  const onSaveClicked = async (formData: ContactSupportFields) => {
    try {
      await userStore.sendSupportRequest(formData.message);
      showToast({ message: 'Email Sent to Canopy Support' });
    } finally {
      onClose();
    }
  };
  return (
    <FormModal
      size="small"
      defaultValues={null}
      methods={methods}
      isOpen={isOpen}
      title="Contact Support"
      confirmActions={[
        {
          onClick: methods.handleSubmit(onSaveClicked),
          text: isLoading ? 'Sending...' : 'Send',
          disabled: isLoading
        }
      ]}
      closeAction={{ onClick: onClose, disabled: false }}
    >
      <Text variant="body2">
        Your message will be sent as an email to Canopy. You can expect a response to your email
        address.
      </Text>
      <Box mb={20} mt={20}>
        <StyledInput value={email} label="Email Address" disabled />
      </Box>
      <Box mb={40}>
        <Text variant="h3">How can we help?</Text>
        <StyledFormTextarea
          isRequired
          name="message"
          placeholder=""
          error={methods.formState.errors.message}
        />
        <Text variant="helper-text">* Please do not send PHI through this form. </Text>
      </Box>
    </FormModal>
  );
});

const StyledFormTextarea = styled(FormTextAreaField)(
  ({ theme }) => css`
    .input-area {
      textarea {
        min-height: 178px;
      }
    }
  `
);
