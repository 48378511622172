// @ts-strict-ignore
import { SyntheticEvent, useState } from 'react';

import { Grid } from '@mui/material';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import { observer } from 'mobx-react';

import PatientsFetcher from 'fetchers/PatientsFetcher';

import Patient from 'models/Patient';

import ScheduledProtocol from 'models/ScheduledProtocol';

import { FilledButton } from 'components/UIkit/atoms/Button';

interface IPatientsActivationCellProps {
  scheduledProtocol: ScheduledProtocol;
  patient: Patient;
  onActivateInvalid?: () => void;
  onActivateSuccess: () => void;
}

export const PatientsActivationCell = observer(
  ({
    scheduledProtocol,
    patient,
    onActivateInvalid,
    onActivateSuccess
  }: IPatientsActivationCellProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const isDisabled = !scheduledProtocol || isLoading;

    const handleActivateCLick = async (event: SyntheticEvent) => {
      event.stopPropagation();

      trackActionButtonAnalyticsEvent({
        action: !scheduledProtocol
          ? AnalyticEventAction.DisabledActivate
          : AnalyticEventAction.Activate,
        patient_id: patient.id
      });

      if (!scheduledProtocol) {
        onActivateInvalid();
        return;
      }

      setIsLoading(true);

      await PatientsFetcher.activatePatient(patient.id);
      onActivateSuccess();
      setIsLoading(false);
    };

    return (
      <Grid container justifyContent="end" onClick={handleActivateCLick}>
        <FilledButton onClick={null} disabled={isDisabled}>
          {isLoading ? 'Activating...' : 'Activate'}
        </FilledButton>
      </Grid>
    );
  }
);
