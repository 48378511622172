import { FC, RefObject } from 'react';

import { Box } from '@mui/material';
import { Reorder } from 'framer-motion';

import { observer } from 'mobx-react';

import { usePathwayEditorQuestion } from 'hooks/usePathwayEditorQuestion';
import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { PathwayAddCategoryButton } from 'views/Pages/PathwayBuilder/PathwayEditorView/Buttons/PathwayAddCategoryButton';
import { PathwayAddQuestionButton } from 'views/Pages/PathwayBuilder/PathwayEditorView/Buttons/PathwayAddQuestionButton';
import { PathwayAddSubQuestionButton } from 'views/Pages/PathwayBuilder/PathwayEditorView/Buttons/PathwayAddSubQuestionButton';
import { KeyQuestionCheckbox } from 'views/Pages/PathwayBuilder/PathwayEditorView/KeyQuestionCheckbox';

import {
  StyledDragIndicatorContainer,
  StyledQuestionCard
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.shared';

import {
  CategoryFormField,
  DateQuestionFormField,
  QuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import {
  getNewCategory,
  getNewQuestion
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';
import { PathwayQuestionTitle } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayQuestionTitle/PathwayQuestionTitle';
import { PathwayEditorBlockActionBar } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/PathwayEditorBlockActionBar';
import { QuestionSymptomsFormSelect } from 'views/Pages/PathwayBuilder/PathwayEditorView/QuestionSymptomsFormSelect';
import { RemoveBlockPopup } from 'views/Pages/PathwayBuilder/PathwayEditorView/RemoveBlockPopup';

import { StyledInputPresentation } from 'views/Pages/PathwayBuilder/shared/styled';

import Icon from 'components/Icons/Icon';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  question: DateQuestionFormField & Record<'id', string>;
  index: number;
  categoryIndex?: number;
  path:
    | `categories.${number}.questions.${number}`
    | `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}.questions.${number}`;
  updateQuestion: (questionIndex: number, data: QuestionFormField) => void;
  moveQuestion: (indexA: number, indexB: number) => void;
  insertQuestion: (questionIndex: number, data: QuestionFormField) => void;
  isSub?: boolean;
  removeQuestion: (index?: number | number[]) => void;
  insertCategory?: (categoryIndex: number, value: CategoryFormField) => void;
  totalQuestions: number;
  isAddSubQuestionButtonDisabled: boolean;
  containerUuid?: string;
  containerRef?: RefObject<HTMLDivElement>;
}

export const DateQuestion: FC<Props> = observer(
  ({
    question,
    index,
    categoryIndex,
    path,
    updateQuestion,
    moveQuestion,
    removeQuestion,
    insertQuestion,
    insertCategory,
    totalQuestions,
    isSub = false,
    isAddSubQuestionButtonDisabled,
    containerUuid,
    containerRef
  }) => {
    const {
      isSelected,
      ref,
      controls,
      showMainQuestionButtons,
      showSubQuestionButtons,
      isRemoveQuestionPopupOpen,
      closeRemoveQuestionPopup,
      onRemoveQuestion,
      isLastQuestion
    } = usePathwayEditorQuestion(path, index, isSub, totalQuestions, containerUuid, containerRef);
    const { selectPathwayEditorBlock } = usePathwayEditorSelection();

    return (
      <>
        <Box mt={20} display="flex" flexDirection="column">
          <Reorder.Item
            key={question.uuid}
            value={question}
            as="div"
            dragListener={false}
            dragControls={controls}
            layout="position"
          >
            <StyledQuestionCard
              isSelected={isSelected}
              ref={ref}
              onClick={(event) => {
                event.stopPropagation();
                selectPathwayEditorBlock(question.uuid);
              }}
            >
              {isSelected && (
                <StyledDragIndicatorContainer onPointerDown={(e) => controls.start(e)}>
                  <Icon.DragIndicator />
                </StyledDragIndicatorContainer>
              )}

              <Box display="flex" mb={20} alignItems="center">
                <PathwayQuestionTitle
                  question={question}
                  questionPath={path}
                  questionIndex={index}
                  updateQuestion={updateQuestion}
                />

                <QuestionSymptomsFormSelect isQuestionSelected={isSelected} questionPath={path} />

                <KeyQuestionCheckbox isQuestionSelected={isSelected} questionPath={path} />
              </Box>

              <StyledInputPresentation>
                <Text color="disabled" variant="form-text">
                  dd/mm/yyyy
                </Text>

                <Icon.Calendar />
              </StyledInputPresentation>

              {isSelected && (
                <PathwayEditorBlockActionBar
                  index={index}
                  totalItems={totalQuestions}
                  onMove={moveQuestion}
                  onRemove={onRemoveQuestion}
                />
              )}
            </StyledQuestionCard>
          </Reorder.Item>

          {showMainQuestionButtons && (
            <Box display="flex" mt={20}>
              <PathwayAddQuestionButton
                onClick={(questionUuid) => insertQuestion(index + 1, getNewQuestion(questionUuid))}
              />

              {isLastQuestion && (
                <PathwayAddCategoryButton
                  onClick={(categoryUuid) =>
                    insertCategory!(categoryIndex! + 1, getNewCategory(categoryUuid))
                  }
                />
              )}
            </Box>
          )}

          {showSubQuestionButtons && (
            <PathwayAddSubQuestionButton
              isDisabled={isAddSubQuestionButtonDisabled}
              onClick={(questionUuid) => insertQuestion(index + 1, getNewQuestion(questionUuid))}
            />
          )}
        </Box>

        <RemoveBlockPopup
          blockUuid={question.uuid}
          isOpen={isRemoveQuestionPopupOpen}
          onCancel={closeRemoveQuestionPopup}
          onRemove={() => {
            removeQuestion(index);
            closeRemoveQuestionPopup();
          }}
        />
      </>
    );
  }
);
