import { FC } from 'react';

import { css, styled } from '@mui/material';
import { components, ContainerProps } from 'react-select';

export const PaginatorSelectContainer: FC<ContainerProps> = ({ children, ...props }) => (
  <StyledContainer {...props}>{children}</StyledContainer>
);

const StyledContainer: FC<ContainerProps> = styled(components.SelectContainer, {
  shouldForwardProp: () => true
})((props: ContainerProps) => {
  const { isFocused } = props;
  const { palette, borderRadius, spacing } = props.selectProps.muiTheme!;

  return css`
    height: 24px;
    min-width: 24px;
    padding: ${spacing(2)};
    background-color: ${isFocused ? palette.natural.white : palette.secondary.main};
    border-radius: ${borderRadius.xSmall};
    border: ${isFocused ? `1px solid ${palette.secondary.dark}` : '1px solid transparent'};
    cursor: pointer;

    &:hover {
      border: 1px solid ${palette.secondary.dark};
    }
  `;
});
