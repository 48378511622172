import { FC, useCallback } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import Doctor from 'models/Doctor';

import { MessageDialog, MessageDialogProps } from 'components/UIkit/atoms/Dialog';

interface DeactivateProps extends Omit<MessageDialogProps, 'id' | 'title' | 'children'> {
  clinician: Doctor;
}

export const DeactivatePopup: FC<DeactivateProps> = ({ clinician, ...popupProps }) => {
  const getContentProps = useCallback(() => {
    if (!clinician) {
      return {
        title: '',
        children: ''
      };
    }

    return {
      title: `Deactivate User ${clinician.fullName}?`,
      children: (
        <div className="deactivate-subtitle">
          Any tickets currently assigned to {clinician.fullName} will return to the Work Queue as
          unassigned tickets. This user’s comment history and calls will still be visible.
        </div>
      )
    };
  }, [clinician]);

  return (
    <MessageDialog
      isOpen={popupProps.isOpen}
      id={Dialogs.DeactivateUser}
      handleClose={popupProps.handleClose}
      {...popupProps}
      {...getContentProps()}
    />
  );
};
