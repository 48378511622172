import { FC, RefObject } from 'react';

import { Box } from '@mui/material';
import { Reorder } from 'framer-motion';

import { observer } from 'mobx-react';

import { usePathwayEditorQuestion } from 'hooks/usePathwayEditorQuestion';
import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { KeyQuestionCheckbox } from 'views/Pages/PathwayBuilder/PathwayEditorView/KeyQuestionCheckbox';

import {
  StyledDragIndicatorContainer,
  StyledQuestionCard
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.shared';

import {
  NewQuestionFormField,
  QuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import { QuestionTitleFormSelect } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayQuestionTitle/QuestionTitleFormSelect';
import { PathwayEditorBlockActionBar } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/PathwayEditorBlockActionBar';
import { QuestionSymptomsFormSelect } from 'views/Pages/PathwayBuilder/PathwayEditorView/QuestionSymptomsFormSelect';

import { RemoveBlockPopup } from 'views/Pages/PathwayBuilder/PathwayEditorView/RemoveBlockPopup';

import Icon from 'components/Icons/Icon';

interface Props {
  question: NewQuestionFormField & Record<'id', string>;
  path:
    | `categories.${number}.questions.${number}`
    | `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}.questions.${number}`;
  index: number;
  updateQuestion: (questionIndex: number, data: QuestionFormField) => void;
  moveQuestion: (indexA: number, indexB: number) => void;
  removeQuestion: (index?: number | number[]) => void;
  totalQuestions: number;
  isSub?: boolean;
  containerUuid?: string;
  containerRef?: RefObject<HTMLDivElement>;
}

export const NewQuestion: FC<Props> = observer(
  ({
    question,
    path,
    index,
    updateQuestion,
    moveQuestion,
    removeQuestion,
    totalQuestions,
    isSub = false,
    containerUuid,
    containerRef
  }) => {
    const {
      isSelected,
      ref,
      controls,
      isRemoveQuestionPopupOpen,
      closeRemoveQuestionPopup,
      onRemoveQuestion
    } = usePathwayEditorQuestion(path, index, isSub, totalQuestions, containerUuid, containerRef);

    const { selectPathwayEditorBlock } = usePathwayEditorSelection();

    const isRemoveQuestionActionHidden =
      question.type === 'NewQuestion' && index === 0 && totalQuestions === 1;

    return (
      <>
        <Box mt={20} display="flex" flexDirection="column">
          <Reorder.Item
            key={question.uuid}
            value={question}
            as="div"
            ref={ref}
            dragListener={false}
            dragControls={controls}
            layout="position"
          >
            <StyledQuestionCard
              isSelected={isSelected}
              onClick={(event) => {
                event.stopPropagation();
                selectPathwayEditorBlock(question.uuid);
              }}
            >
              {isSelected && (
                <StyledDragIndicatorContainer onPointerDown={(e) => controls.start(e)}>
                  <Icon.DragIndicator />
                </StyledDragIndicatorContainer>
              )}

              <Box display="flex" alignItems="center">
                <QuestionTitleFormSelect
                  questionIndex={index}
                  questionPath={path}
                  updateQuestion={updateQuestion}
                  isSub={isSub}
                />

                <QuestionSymptomsFormSelect isQuestionSelected={isSelected} questionPath={path} />

                <KeyQuestionCheckbox isQuestionSelected={isSelected} questionPath={path} />
              </Box>

              {isSelected && (
                <PathwayEditorBlockActionBar
                  index={index}
                  totalItems={totalQuestions}
                  withoutRemoveAction={isRemoveQuestionActionHidden}
                  onMove={moveQuestion}
                  onRemove={onRemoveQuestion}
                />
              )}
            </StyledQuestionCard>
          </Reorder.Item>
        </Box>

        <RemoveBlockPopup
          blockUuid={question.uuid}
          isOpen={isRemoveQuestionPopupOpen}
          onCancel={closeRemoveQuestionPopup}
          onRemove={() => {
            removeQuestion(index);
            closeRemoveQuestionPopup();
          }}
        />
      </>
    );
  }
);
