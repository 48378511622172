// @ts-strict-ignore
import React, { FC, useEffect, useMemo } from 'react';

import { trackCmSortByAnalyticsEvent } from 'analytics/events/sort-by';

import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { useUnmount } from 'react-use';

import { careManagementTestSelectors } from 'tests/models/pages/care-management/care-management-page.selectors';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';
import { CARE_MANAGEMENT_ENROLLED_PATIENTS_FILTERS_LOCAL_STORAGE_KEY } from 'mobx/stores';

import { getCurrentSortStringByUrl } from 'utils/urlUtils';

import { API_URLS } from 'constants/apiUrls';
import { CmEnrolledPatientSortingValues } from 'constants/careManagement.const';

import { EnrolledPatient } from 'models/CmPatient';

import { useNavPagination } from 'hooks/useNavPagination';

import CmEnrolledPatientsFilters from 'views/Filters/CmEnrolledPatientsFilters';
import { useGetPersistentFilters } from 'views/Filters/useFilters';
import { VisitsHeader } from 'views/Pages/CareManagement/care-management-table-cells';
import { CareManagementTabs } from 'views/Pages/CareManagement/CareManagementPage';
import { CmEnrolledPatientColumns } from 'views/Pages/CareManagement/CareManagementPage.utils';
import CmNameCell from 'views/Pages/CareManagement/CmNameCell';
import DefaultEmptyTableView from 'views/Widgets/DefaultEmptyTableView';

import FixedLoader from 'components/Loaders/FixedLoader';
import { TextCell } from 'components/Patient/Table/TextCell';
import { PagedTable, PaginationLocation, TableCellParams, TableColumn } from 'components/Table';

import TooltipTableHeader from 'components/Table/TooltipHeader';

import './CareManagementEnrolledPatients.scss';

export const getEnrolledSortIdFromTable = (sortId: string) => {
  switch (sortId) {
    case CmEnrolledPatientColumns.PatientName:
      return CmEnrolledPatientSortingValues.PatientName;
    case CmEnrolledPatientColumns.ProviderName:
      return CmEnrolledPatientSortingValues.ProviderName;
    case CmEnrolledPatientColumns.Visits:
      return CmEnrolledPatientSortingValues.Visits;
    case CmEnrolledPatientColumns.Episode:
      return CmEnrolledPatientSortingValues.Episode;
    case CmEnrolledPatientColumns.CareTime:
      return CmEnrolledPatientSortingValues.CareTimer;
    default:
      throw new Error('Unsupported sort value = ' + sortId);
  }
};

const CareManagementEnrolledPatients: FC<RouteComponentProps> = () => {
  const { currentPageByUrl, recordsPerPageByUrl } = useNavPagination();
  const currentSortStr = getCurrentSortStringByUrl();
  const { careManagementReportStore } = useStores();
  const parsedFilters = useGetPersistentFilters(
    CARE_MANAGEMENT_ENROLLED_PATIENTS_FILTERS_LOCAL_STORAGE_KEY
  );

  useEffect(
    function fetchEnrolledData() {
      careManagementReportStore.fetchCareManagementEnrolled();
    },
    [
      currentPageByUrl,
      currentSortStr,
      parsedFilters,
      careManagementReportStore,
      recordsPerPageByUrl
    ]
  );

  useUnmount(() => {
    careManagementReportStore.resetEnrolled();
    careManagementReportStore.resetPagination();
  });

  const columns: TableColumn<EnrolledPatient>[] = useMemo(
    () => [
      {
        Header: 'Patient',
        accessor: CmEnrolledPatientColumns.PatientName,
        width: 150,
        Cell: (cellParams: TableCellParams<EnrolledPatient>) => {
          const mrn = cellParams.row.original.patientMrn;
          return (
            <CmNameCell
              name={cellParams.row.original.patientName}
              mrn={mrn}
              patientId={cellParams.row.original.patientId}
            />
          );
        }
      },
      {
        Header: 'Provider',
        accessor: CmEnrolledPatientColumns.ProviderName,
        width: 150
      },
      {
        Header: 'Episode',
        accessor: CmEnrolledPatientColumns.Episode,
        width: 150,
        Cell: (cellParams: TableCellParams<EnrolledPatient>) => (
          <TextCell
            text={careManagementReportStore.episodeById(cellParams.row.original.episodeId)?.name}
          />
        )
      },
      {
        Header: (
          <TooltipTableHeader
            headerText="Care Time"
            tooltipText="Care Time represents time spent on the patient, including calls logged, for this calendar month (in hours and minutes)."
          />
        ),
        accessor: CmEnrolledPatientColumns.CareTime,
        width: 150
      },
      {
        Header: <VisitsHeader />,
        accessor: CmEnrolledPatientColumns.Visits,
        width: 170
      }
    ],
    [careManagementReportStore]
  );
  const isLoading =
    useNetworkLoading([API_URLS.CARE_MANAGEMENT_ENROLLED, API_URLS.CARE_MANAGEMENT_FILTER_DATA]) ||
    !careManagementReportStore.enrolledPatients;

  return (
    <div
      className="enrolled-patients"
      data-test-hook={careManagementTestSelectors.enrolledPatientsTab.container}
    >
      <CmEnrolledPatientsFilters />
      {isLoading && <FixedLoader />}
      <PagedTable
        columns={columns}
        rowData={careManagementReportStore.enrolledPatients?.items || []}
        rowLink={(patient: EnrolledPatient) =>
          `/patient/${patient.patientId}?tab=episodes_and_tasks`
        }
        totalPages={careManagementReportStore.totalPages}
        isLoading={isLoading}
        paginationLocation={[PaginationLocation.TOP, PaginationLocation.BOTTOM]}
        emptyTableView={<DefaultEmptyTableView />}
        onManualSort={(column, sortParams) =>
          trackCmSortByAnalyticsEvent(column, sortParams, CareManagementTabs.ENROLLED_PATIENTS)
        }
      />
    </div>
  );
};

export default observer(CareManagementEnrolledPatients);
