import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Add
  | AnalyticEventAction.Remove
  | AnalyticEventAction.RemoveAllInPage
  | AnalyticEventAction.SelectAllInPage
  | AnalyticEventAction.SelectAllInTable
  | AnalyticEventAction.RemoveAllInTable;

interface Properties {
  action: Action;
  value?: string | number;
}

export const trackMultiSelectAnalyticsEvent = ({ action, value }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.Multiselect,
    properties: { action, value }
  });
};
