import { useCallback } from 'react';

import { ErrorName } from 'errors';

import { useStores } from 'mobx/hooks/useStores';

import Ticket from 'models/Ticket';

import { useTicketActionCallbacks } from 'components/Ticket/TicketsContainers/useTicketActionCallbacks';

interface UseAssignTicketReturn {
  (ticket: Ticket, doctorId: number | null): Promise<void>;
}

export const useTicketAssign = (): UseAssignTicketReturn => {
  const { ticketsStore } = useStores();
  const { onAssign } = useTicketActionCallbacks();

  return useCallback(
    async function assignTicket(ticket: Ticket, doctorId: number | null) {
      try {
        await ticketsStore.updateTicketAssign(ticket, doctorId);
        onAssign && onAssign(ticket, doctorId);
      } catch (error: any) {
        if (error.name === ErrorName.TicketDoublePickup) {
          onAssign && onAssign(ticket, doctorId);
        }
        throw error;
      }
    },
    [onAssign, ticketsStore]
  );
};
