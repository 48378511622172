import { AnalyticEventName } from 'analytics';

import mixpanel from 'mixpanel-browser';

import { isProd } from 'utils/EnvUtils';

import {
  getPageName,
  getPatientIdFromUrl,
  getQueryParamFromUrl,
  getTabName,
  PatientPageTab,
  tabsPerPage,
  urlPages,
  urlPageToPageName
} from 'utils/urlUtils';

import { ENV, isCanary, MIXPANEL_TOKEN } from 'constants/config';

export interface AnalyticsEventProperties {
  [key: string]: any;
}

export interface AnalyticsEvent<T extends object> {
  name: AnalyticEventName;
  properties?: T;
}

class AnalyticsService {
  constructor() {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: !isProd,
      ignore_dnt: !isProd
    });

    this.setGlobalProperties({
      platform: 'portal',
      environment: ENV,
      release_group: isCanary ? 'canary' : 'ga'
    });
  }

  private addPerPageProperties(properties: AnalyticsEventProperties) {
    properties.page_name = getPageName();

    switch (properties.page_name) {
      case urlPageToPageName[urlPages.PATIENT_PAGE]:
        if (!properties.patient_id) {
          properties.patient_id = getPatientIdFromUrl();
        }
        if (!properties.tab) {
          const tab = getQueryParamFromUrl('tab') as PatientPageTab;
          properties.tab = tabsPerPage[urlPages.PATIENT_PAGE][tab];
        }
        break;
      case urlPageToPageName[urlPages.CARE_MANAGEMENT]:
      case urlPageToPageName[urlPages.PRACTICE_MANAGER]:
        // extract second url segment
        properties.tab = getTabName();
        break;
    }
  }

  public trackEvent<T extends object>(event: AnalyticsEvent<T>) {
    const properties = event.properties || {};
    this.addPerPageProperties(properties);
    mixpanel.track(event.name, properties);
  }

  identify(id: string): void {
    mixpanel.identify(id);
  }

  setGlobalProperties(properties: { [p: string]: any }): void {
    mixpanel.register(properties);
  }

  setProfileProperties(properties: { [p: string]: any }): void {
    mixpanel.people.set(properties);
  }

  reset() {
    mixpanel.reset();
  }

  optOut() {
    mixpanel.opt_out_tracking();
  }

  timeEvent(eventName: AnalyticEventName) {
    mixpanel.time_event(eventName);
  }
}
const analyticsService = new AnalyticsService();
export default analyticsService;
