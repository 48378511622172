import { HOUR_IN_MILLISECONDS } from 'utils/DateUtils';

import { DrugSpecificType } from 'models/DrugSpecific';
import ScheduledProtocol from 'models/ScheduledProtocol';

import { defaultEndOptions } from 'views/Widgets/EndProtocolOptions';
import { intervalOptions } from 'views/Widgets/SymptomProtocolFields';

export const specificDrugToLabelMap: Record<DrugSpecificType, string> = {
  [DrugSpecificType.Hourly]: 'Hourly',
  [DrugSpecificType.WeeklyCRS]: 'Weekly (CRS)',
  [DrugSpecificType.WeeklyInfection]: 'Weekly (Infection)'
};

export const defaultDrugSpecificIntervalOptions = [
  ...intervalOptions,
  { value: HOUR_IN_MILLISECONDS, label: 'hour(s)' }
];

export const drugSpecificEndOptions = [
  ...defaultEndOptions,
  { value: ScheduledProtocol.END_TYPE_DURATION, label: 'Duration' }
];
