const DEBOUNCE_SIZE = 100;

class DebounceService {
  private fallbackInvocationMap;

  constructor() {
    this.fallbackInvocationMap = new Map<string, number>();
  }

  updateTimestamp(token?: string) {
    if (token) {
      this.fallbackInvocationMap.set(token, Date.now());
    }
  }

  isMinimalTimePassed(token?: string): boolean {
    let isMinTimePassed = true;
    const lastInvocationTimestamp = token && this.fallbackInvocationMap.get(token);
    if (lastInvocationTimestamp) {
      isMinTimePassed = Date.now() > lastInvocationTimestamp + DEBOUNCE_SIZE;
    }
    return isMinTimePassed;
  }
}

export const debounceService = new DebounceService();
