import { css } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Text } from 'components/UIkit/atoms/Text';

export const StyledLink = styled(Text, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'width'
})<{
  color?: 'primary' | 'secondary';
}>(
  ({ theme, color, width }) => css`
    display: inline-block;
    vertical-align: text-bottom;
    color: ${color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.dark};
    width: ${width};

    a {
      color: ${color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.dark};

      &:hover {
        color: ${color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.dark};
      }
    }
  `
);
