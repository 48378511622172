import { FC } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { Testable } from 'utils/TypeUtils';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props extends Testable {
  isOpen: boolean;
  onAction: () => void;
  onCancel: () => void;
}

export const DisableTicketCategoryPopup: FC<Props> = ({ isOpen, onAction, onCancel, testHook }) => (
  <MessageDialog
    id={Dialogs.DisableTicketCategory}
    isOpen={isOpen}
    title="Disable Ticket Category for Operators and Patients? "
    testHook={testHook}
    handleClose={onCancel}
    primaryActionProps={{ text: 'Disable Category', onClick: onAction }}
    secondaryActionProps={{ text: 'Cancel', onClick: onCancel }}
  >
    <div>
      Disabling this entire category will no longer allow the creation of ticket types within this
      category, for operators and patients.
    </div>
  </MessageDialog>
);
