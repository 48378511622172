import { ChangeEvent, FC } from 'react';

import { Box, css, styled } from '@mui/material';
import {
  trackDateInputUsageAnalyticsEvent,
  trackDropdownInputUsageAnalyticsEvent,
  trackNumberInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';

import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { ActionMeta } from 'react-select';

import { UnitType } from 'models/DrugSpecific';

import ScheduledProtocol from 'models/ScheduledProtocol';

import { SelectOption } from 'models/SelectOption';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';

type EndOptions = {
  value:
    | typeof ScheduledProtocol.END_TYPE_NEVER
    | typeof ScheduledProtocol.END_TYPE_DATE
    | typeof ScheduledProtocol.END_TYPE_OCCURRENCES;
  label: string;
}[];

interface EndProtocolOptionsProps {
  minEndDate: string;
  endOptions?: EndOptions;
  endDateDurationUnitOptions?: { value: UnitType; label: string }[];
  isSelectedEndOption?: boolean;
  isDurationUnitFieldsDisabled?: boolean;
}

export const defaultEndOptions: EndOptions = [
  { value: ScheduledProtocol.END_TYPE_NEVER, label: 'Never' },
  { value: ScheduledProtocol.END_TYPE_DATE, label: 'On Date' },
  { value: ScheduledProtocol.END_TYPE_OCCURRENCES, label: 'After Number of Report Requests' }
];

export const defaultEndDateDurationUnitOptions: { value: UnitType; label: string }[] = [
  { value: UnitType.Hour, label: 'hours' },
  { value: UnitType.Day, label: 'days' }
];

//currently for testing
export const endDateDurationUnitOptionsWithMinutes: { value: UnitType; label: string }[] = [
  ...defaultEndDateDurationUnitOptions,
  { value: UnitType.Minute, label: 'minutes' }
];

const EndProtocolOptions: FC<EndProtocolOptionsProps> = ({
  minEndDate,
  endOptions = defaultEndOptions,
  endDateDurationUnitOptions = defaultEndDateDurationUnitOptions,
  isSelectedEndOption = false,
  isDurationUnitFieldsDisabled = false
}) => {
  const methods = useFormContext();
  const [
    selectedEndOption,
    selectedEndDate,
    endDateDurationUnit,
    endDateDuration,
    numberOfOccurrences
  ] = methods.watch([
    'selectedEndOption',
    'selectedEndDate',
    'endDateDurationUnit',
    'endDateDuration',
    'numberOfOccurrences'
  ]);

  return (
    <StyledContainer>
      <Box flex={1}>
        <FormAutocomplete
          sortAlphabetically={false}
          isClearable={false}
          isRequired
          onChange={(
            option: SelectOption<number>,
            actionMeta: ActionMeta<SelectOption<number>>,
            eventKey: string
          ) => {
            trackDropdownInputUsageAnalyticsEvent(
              actionMeta,
              'End of Protocol',
              eventKey === 'Enter',
              Boolean(selectedEndOption),
              { virtual_page: 'report protocol modal' }
            );
            if (option.value !== ScheduledProtocol.END_TYPE_DATE) {
              methods.setValue('selectedEndDate', null);
            }
          }}
          options={endOptions}
          label="End of Protocol"
          name="selectedEndOption"
          isDisabled={isSelectedEndOption}
        />
      </Box>

      {selectedEndOption?.value !== ScheduledProtocol.END_TYPE_OCCURRENCES &&
        selectedEndOption?.value !== ScheduledProtocol.END_TYPE_DURATION && (
          <Box flex={1}>
            <RHFStyledInput
              type="date"
              min={minEndDate}
              disabled={selectedEndOption?.value === ScheduledProtocol.END_TYPE_NEVER}
              label="End Date"
              onChange={(event) =>
                trackDateInputUsageAnalyticsEvent(
                  (event as ChangeEvent<HTMLInputElement>).target.value,
                  selectedEndDate,
                  'End Date'
                )
              }
              validate={() => {
                return (
                  selectedEndOption?.value !== ScheduledProtocol.END_TYPE_DATE ||
                  Boolean(methods.getValues('selectedEndDate'))
                );
              }}
              error={Boolean(
                methods.formState.errors.selectedEndDate &&
                  selectedEndOption?.value === ScheduledProtocol.END_TYPE_DATE
              )}
              register={methods.register}
              name="selectedEndDate"
            />
          </Box>
        )}

      {selectedEndOption?.value === ScheduledProtocol.END_TYPE_OCCURRENCES && (
        <Box flex={1}>
          <RHFStyledInput
            type="number"
            min={1}
            label="Report Requests"
            error={Boolean(methods.formState.errors.numberOfOccurrences)}
            isRequired={selectedEndOption?.value === ScheduledProtocol.END_TYPE_OCCURRENCES}
            register={methods.register}
            name="numberOfOccurrences"
            onChange={(event) =>
              trackNumberInputUsageAnalyticsEvent(
                (event as ChangeEvent<HTMLInputElement>).target.value,
                numberOfOccurrences,
                'Report Requests'
              )
            }
          />
        </Box>
      )}

      {selectedEndOption?.value === ScheduledProtocol.END_TYPE_DURATION && (
        <>
          <Box mt={20} flex={1}>
            <RHFStyledInput
              rounded
              disabled={isDurationUnitFieldsDisabled}
              type="number"
              min={1}
              error={Boolean(methods.formState.errors.endDateDuration)}
              isRequired={selectedEndOption?.value === ScheduledProtocol.END_TYPE_DURATION}
              register={methods.register}
              name="endDateDuration"
              onChange={(event) =>
                trackNumberInputUsageAnalyticsEvent(
                  (event as ChangeEvent<HTMLInputElement>).target.value,
                  endDateDuration,
                  'Duration'
                )
              }
            />
          </Box>

          <Box mt={20} flex={1}>
            <FormAutocomplete
              sortAlphabetically={false}
              isClearable={false}
              isRequired
              options={endDateDurationUnitOptions}
              name="endDateDurationUnit"
              isDisabled={isDurationUnitFieldsDisabled}
              onChange={(value, actionMeta, eventKey) =>
                trackDropdownInputUsageAnalyticsEvent(
                  actionMeta,
                  'Duration Unit',
                  eventKey === 'Enter',
                  Boolean(endDateDurationUnit?.value),
                  { virtual_page: 'report protocol modal' }
                )
              }
            />
          </Box>
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    margin: ${theme.spacing(20, 0)};
    display: flex;
    align-items: center;
    gap: ${theme.spacing(20)};
    width: 100%;
  `
);

export default observer(EndProtocolOptions);
