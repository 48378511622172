// @ts-strict-ignore
import { isStringInPhrase } from 'utils/StringUtils';

import { ISelectOption } from 'views/Widgets/StyledSelect';

export enum EpisodeRuleset {
  default = 'default',
  ocm = 'ocm'
}

export enum EpisodeTypes {
  ocm = 'OCM',
  regular = 'Regular'
}

export enum CmEpisodeTypes {
  ccm = 'CCM',
  pcm = 'PCM'
}

export type EpisodeOption = EpisodeTypes | CmEpisodeTypes;

export const episodeSelectionOptions = (withCCM: boolean): ISelectOption<EpisodeOption>[] => {
  const options: { label: string; value: EpisodeTypes | CmEpisodeTypes }[] = [
    { label: 'Standard (Not OCM, CCM, or PCM)', value: EpisodeTypes.regular }
  ];
  if (withCCM) {
    options.push({ label: CmEpisodeTypes.ccm, value: CmEpisodeTypes.ccm });
    options.push({ label: CmEpisodeTypes.pcm, value: CmEpisodeTypes.pcm });
  }
  options.push({ label: EpisodeTypes.ocm, value: EpisodeTypes.ocm });
  return options;
};

export const DEFAULT_SELECTION_OPTION = {
  label: EpisodeTypes.regular,
  value: EpisodeTypes.regular
};

export const getEpisodeSelectionOption = (
  ruleset: EpisodeRuleset,
  type: EpisodeOption,
  withCCM = false
): ISelectOption<EpisodeOption> => {
  const options = episodeSelectionOptions(withCCM);
  const selectedOption = options.find((option) => option.value === type);

  return selectedOption || DEFAULT_SELECTION_OPTION;
};

export const getRuleSetOptionByName = (name: string) => {
  if (isNameOCM(name)) {
    return getEpisodeSelectionOption(EpisodeRuleset.ocm, null);
  }
};

const isNameOCM = (name: string) => {
  return ['OCM', 'O.C.M.', 'O.C.M', 'Oncology Care Model'].some((ocmName) =>
    isStringInPhrase(ocmName, name)
  );
};

export class Episode {
  id: number;
  name: string;
  duration: number;
  ruleset: EpisodeRuleset = EpisodeRuleset.default;
  type: EpisodeOption = EpisodeTypes.regular;
  createdAt: string;
  updatedAt: string;
  static MAX_DURATION = 24;

  constructor(episode: any) {
    Object.assign(this, episode);
  }

  get isCareManagement() {
    return [CmEpisodeTypes.ccm, CmEpisodeTypes.pcm].includes(this.type as CmEpisodeTypes);
  }
}

export default Episode;
