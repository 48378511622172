import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';

interface IOpenCloseArrowProps {
  isOpen: boolean;
  onArrowClicked?: () => void;
}

const OpenCloseArrow = (props: IOpenCloseArrowProps) => (
  <IconButton onClick={props.onArrowClicked}>
    <Icon.CollapseArrow className="mr-3" right={!props.isOpen} />
  </IconButton>
);

export default OpenCloseArrow;
