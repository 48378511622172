// @ts-strict-ignore
import { FC } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { useController, useFormContext } from 'react-hook-form';

import { Episode } from 'models/Episode';

import { SelectOption } from 'models/SelectOption';
import { MAX_TASK_NAME_LENGTH } from 'models/TaskTicket';

import EditableRolesDropdown from 'views/Pages/PracticeManagement/Episodes/EditableRolesDropdown';
import StyledFieldsForm from 'views/Widgets/StyledFieldsForm';
import { RHFStyledInput } from 'views/Widgets/StyledInput';

import StyledToggleButton, { LabelPosition } from 'views/Widgets/StyledToggleButton';

import FormTextAreaField from 'components/Forms/FormTextAreaField';
import Icon from 'components/Icons/Icon';
import { Tooltip } from 'components/Tooltip';
import { FormAutocompleteInlineLabel } from 'components/UIkit/atoms/Dropdown';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  monthOptions?: SelectOption<number>[];
  onEditCreateRole: (roleId: number) => void;
  episode: Episode;
}

const TaskTemplateModalFields: FC<Props> = ({ monthOptions, onEditCreateRole, episode }) => {
  const { register, formState, control } = useFormContext();
  const { errors } = formState;

  const { field } = useController({ control, name: 'isBillable' });

  return (
    <StyledContainer>
      <Box mb={24} display="flex" justifyContent="space-between" alignItems="center">
        <StyledTitleInput
          name="name"
          error={Boolean(errors.name)}
          register={register}
          maxLength={MAX_TASK_NAME_LENGTH}
          isRequired
          label="Task Title"
        />
        {episode?.isCareManagement && (
          <StyledBillableToggle
            checked={field.value}
            label={
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Text variant="body3" mr={2}>
                  Required for Billing
                </Text>
                <Tooltip label={<Icon.Info />} maxWidth={342}>
                  <Box p={20}>
                    This metric is referenced in the ‘Enrolled Patients’ tab of the ‘Care
                    Management’ page, as well as in the Canopy Billing Report. <br /> <br />
                    Note: Toggling this applies to patients already enrolled in this episode.
                  </Box>
                </Tooltip>
              </Box>
            }
            labelPosition={LabelPosition.LEFT}
            onChange={field.onChange}
          />
        )}
      </Box>
      <StyledFieldsForm
        extraFields={[
          <EditableRolesDropdown key="editable-roles" onEditCreateRole={onEditCreateRole} />,
          <FormAutocompleteInlineLabel
            isRequired
            key="monthNumber"
            name="monthNumber"
            options={monthOptions}
            label="Due"
            sortAlphabetically={false}
          />
        ]}
        mainField={
          <FormTextAreaField
            placeholder="Add optional notes here…"
            name="description"
            label="Task Description"
          />
        }
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  .styled-fields-wrapper {
    margin-bottom: 40px;
  }
`;

const StyledTitleInput = styled(RHFStyledInput)`
  flex: 1;
`;

const StyledBillableToggle = styled(StyledToggleButton)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(20)};
    margin-top: ${theme.spacing(20)};
  `
);

export default TaskTemplateModalFields;
