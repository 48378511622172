import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import {
  PathwayBuilderTab,
  PathwayBuilderTabName
} from 'views/Pages/PathwayBuilder/PathwayBuilderPage.types';

export const PATHWAY_BUILDER_TABS: { value: PathwayBuilderTab; label: PathwayBuilderTabName }[] = [
  { value: PathwayBuilderTab.Pathways, label: PathwayBuilderTabName.Pathways },
  { value: PathwayBuilderTab.Questions, label: PathwayBuilderTabName.Questions }
];

export const PATHWAY_BUILDER_ID_QUERY_PARAM = 'id';
export const PATHWAY_BUILDER_SELECTED_QUESTION_ID_QUERY_PARAM = 'selectedQuestionId';
export const ACTIVE_PATHWAY_TEMPLATES_LOCAL_STORAGE_KEY = 'active-pathway-templates';
export const CHANGED_PATHWAY_IDS_LOCAL_STORAGE_KEY = 'changed-pathway-ids';
export const CHANGED_QUESTIONS_IDS_LOCAL_STORAGE_KEY = 'changed-question-ids';
export const DEPRESSION_SCREENING_PATHWAY_ID = 'a27fd88d-e2b7-4020-9ee5-b5133cb76d2f';

export const ICAN_PATHWAY_ID = '89e516d1-1d19-4d73-959d-e103d306f5d6';

export const questionTypesWithOptions = [
  PathwayQuestionTypes.MULTIPLE,
  PathwayQuestionTypes.SINGLE
];
