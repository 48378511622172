import { FC } from 'react';

import { v4 as uuid } from 'uuid';

import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { OutlinedButton } from 'components/UIkit/atoms/Button';

interface Props {
  onClick: (dependentQuestionsBlockUuid: string) => void;
}

export const PathwayAddDependentQuestionsBlockButton: FC<Props> = ({ onClick }) => {
  const { selectPathwayEditorBlock } = usePathwayEditorSelection();

  const onInsertQuestion = () => {
    const dependentQuestionsBlockUuid = uuid();
    onClick(dependentQuestionsBlockUuid);
    selectPathwayEditorBlock(dependentQuestionsBlockUuid);
  };

  return (
    <OutlinedButton id="pathway-add-sub-question-button" onClick={onInsertQuestion} mr={12}>
      Add Sub-Question
    </OutlinedButton>
  );
};
