import { FC } from 'react';

import { styled } from '@mui/material';
import { components, ValueContainerProps } from 'react-select';

export const PaginatorSelectValueContainer: FC<ValueContainerProps> = ({ children, ...props }) => (
  <StyledValueContainer {...props}>{children}</StyledValueContainer>
);

const StyledValueContainer: FC<ValueContainerProps> = styled(components.ValueContainer, {
  shouldForwardProp: () => true
})`
  height: 100%;
  margin: 0;
  padding: 0;
  width: auto;
  align-content: center;
`;
