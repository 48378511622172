import { FC, useState } from 'react';

import { ClearIndicatorProps, components } from 'react-select';

import { ClearCmTableSelectionPopup } from 'views/Modals/ClearCmTableSelectionPopup';

interface CmClearIndicatorProps extends ClearIndicatorProps {
  selectedTableRowsCount: number;
}

export const CmFilterClearIndicator: FC<CmClearIndicatorProps> = (props) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);

  return (
    <div onMouseDown={(event) => event.stopPropagation()}>
      <ClearCmTableSelectionPopup
        isOpen={showWarning}
        onClose={() => setShowWarning(false)}
        onSave={props.innerProps.onMouseDown as any}
        selectedTableRowsCount={props.selectedTableRowsCount}
      />

      <components.ClearIndicator
        {...props}
        innerProps={{
          ...props.innerProps,
          onMouseDown: () => setShowWarning(true)
        }}
      />
    </div>
  );
};
