import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import ParseTicketTypeResponseService from 'parsers/ParseTicketTypeResponseService';

import { TicketTypeCreationAllowance, TicketTypeDisplayName } from 'models/TicketTypes';

export interface TicketTypeCreateUpdateAttrs {
  name: string;
  parentId: number | null;
  displayNames: TicketTypeDisplayName[];
}

export type LanguageCode = 'en-US' | 'es-ES';

const httpService = new HttpService('ticket type');

class TicketTypesFetcher {
  async fetchOperatorTicketTypes() {
    return await httpService.get({
      url: API_URLS.OPERATOR_TICKET_TYPES,
      transformResponse: ParseTicketTypeResponseService.parseOperatorTicketTypes
    });
  }

  async createTicketSubType(data: TicketTypeCreateUpdateAttrs) {
    return await httpService.post({
      url: API_URLS.OPERATOR_TICKET_TYPES,
      data
    });
  }

  async updateTicketTypeOrSubType(id: number, data: TicketTypeCreateUpdateAttrs) {
    return await httpService.put({
      url: API_URLS.OPERATOR_TICKET_SUBTYPE(id),
      data
    });
  }

  async deleteTicketSubType(id: number) {
    return await httpService.delete({
      url: API_URLS.OPERATOR_TICKET_SUBTYPE(id)
    });
  }

  async editAllowance(id: number, displaySettings: TicketTypeCreationAllowance[]) {
    return await httpService.put({
      url: API_URLS.OPERATOR_TICKET_EDIT_ALLOWANCE(id),
      data: { displaySettings }
    });
  }
}

const ticketTypesFetcher = new TicketTypesFetcher();

export default ticketTypesFetcher;
