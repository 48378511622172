import { FC } from 'react';

import { css, styled } from '@mui/material';
import { components, InputProps, ValueContainerProps } from 'react-select';

export const PaginatorSelectInput: FC<InputProps> = (props) => <StyledInput {...props} />;

const StyledInput: FC<InputProps> = styled(components.Input, {
  shouldForwardProp: () => true
})((props: ValueContainerProps) => {
  const { palette } = props.selectProps.muiTheme!;

  return css`
    margin: 0;
    color: ${palette.text.primary};
    height: 100%;
    width: fit-content;
    min-width: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    input {
      cursor: pointer;
    }
  `;
});
