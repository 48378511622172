import { ChangeEvent, useState } from 'react';

// input hook with onChange that can be called programmatically
export function useInput(initialValue = '', callback?: Function) {
  const [value, setValue] = useState(initialValue);
  // can be called dynamically, like: onChange('Hi')
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
    const newValue = typeof e === 'string' ? e : e.target.value;
    setValue(newValue);
    callback && callback(newValue); // invoke callback if provided
  };
  return { onChange, value };
}

// checkbox hook with onChange that can be called programmatically
export function useCheckbox(initialValue = false, callback?: Function) {
  const [checked, setChecked] = useState(initialValue);
  const onChange = (e: ChangeEvent<HTMLInputElement> | boolean) => {
    const newValue = typeof e === 'boolean' ? e : e.target.checked;
    setChecked(newValue);
    callback && callback(newValue); // invoke callback if provided
  };
  return { onChange, checked };
}
