import { CmQueryType } from 'mobx/stores';

import {
  CareManagementCheckInviteProgressResponse,
  CareManagementFilterDataResponse,
  CareManagementSendBulkCareInvitationSessionId,
  CmStatusEnum
} from 'fetchers/responses/care-management.response';

import HttpService from 'services/HttpService';

import { sanitizeFiltersOrQuery } from 'utils/serverFiltersUtils';

import { API_URLS } from 'constants/apiUrls';

import CareManagementParser, {
  ParsedCareManagementEnrolled,
  ParsedCareManagementReport
} from 'parsers/CareManagementParser';

import { CmPatient } from 'models/CmPatient';
import { CmEpisodeTypes } from 'models/Episode';

const httpService = new HttpService('care management');

interface CareManagementReportQuery {
  pageNumber: number;
  recordsPerPage: number;
}

export interface StatusUpdateBody {
  status: CmStatusEnum;
  reasonId?: number;
  notes?: string;
}

export interface CareManagementBulkInviteBody {
  type: CmEpisodeTypes;
  patients?: { patientId: number | null; emrPatientId: string | null }[];
  filters?: CmQueryType | {};
}

export class CareManagementFetcher {
  static async fetchCareManagementReport(query: CareManagementReportQuery) {
    return await httpService.post<ParsedCareManagementReport>({
      url: API_URLS.CARE_MANAGEMENT_REPORT,
      data: sanitizeFiltersOrQuery(query),
      transformResponse: CareManagementParser.parseCareManagementReport
    });
  }
  static async fetchCareManagementEnrolled(query: CareManagementReportQuery) {
    return await httpService.post<ParsedCareManagementEnrolled>({
      url: API_URLS.CARE_MANAGEMENT_ENROLLED,
      data: sanitizeFiltersOrQuery(query),
      transformResponse: CareManagementParser.parseCareManagementEnrolled
    });
  }

  static async fetchCareManagementFilterData() {
    return await httpService.get<CareManagementFilterDataResponse>({
      url: API_URLS.CARE_MANAGEMENT_FILTER_DATA
    });
  }

  static async fetchCareManagementPatientData(patientId: number) {
    return await httpService.get<any>({
      url: API_URLS.CARE_MANAGEMENT_PATIENT_DATA(patientId),
      transformResponse: (response) => (response ? new CmPatient(response) : null)
    });
  }

  static async updatePatientStatus(patientId: number, statusUpdateBody: StatusUpdateBody) {
    return await httpService.put({
      url: API_URLS.CARE_MANAGEMENT_UPDATE_STATUS(patientId),
      data: statusUpdateBody
    });
  }

  static async sendBulkCareInvitation(
    query: CareManagementBulkInviteBody
  ): Promise<CareManagementSendBulkCareInvitationSessionId> {
    return await httpService.post<
      CareManagementSendBulkCareInvitationSessionId,
      CareManagementBulkInviteBody
    >({
      url: API_URLS.CARE_MANAGEMENT_SEND_BULK_INVITATION,
      data: query
    });
  }

  static async checkInviteProgress(
    sessionId: string
  ): Promise<CareManagementCheckInviteProgressResponse> {
    return await httpService.get<CareManagementCheckInviteProgressResponse>({
      url: API_URLS.CARE_MANAGEMENT_CHECK_INVITE_PROGRESS(sessionId)
    });
  }
}

export default CareManagementFetcher;
