import { ErrorName } from './Error.utils';
import { ErrorWithUi } from './ErrorWithUi';

const NoInternet = 'No Internet Connection';
export class NoNetworkError extends ErrorWithUi {
  readonly name = ErrorName.NoNetwork;

  constructor() {
    super(NoInternet, NoInternet, 'Please check your connection and try again');
  }
}
