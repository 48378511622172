import { useCallback, useMemo, useState } from 'react';

import { chunk } from 'lodash/fp';

type Updater = (prevState: number) => number;

export function useLocalPagination<T>(items: T[], pageSize: number) {
  const [pageNumber, setPageNumber] = useState(0);

  const { currentPage, numberOfPages } = useMemo(() => {
    const pagedItems = chunk(pageSize, items);
    return { currentPage: pagedItems[pageNumber], numberOfPages: pagedItems.length };
  }, [items, pageNumber, pageSize]);

  const isFirst = useMemo(() => pageNumber === 0, [pageNumber]);
  const isLast = useMemo(() => pageNumber === numberOfPages - 1, [numberOfPages, pageNumber]);

  const setPage = useCallback(
    (updater: Updater) => {
      const newPageNumber = updater(pageNumber);
      if ((isFirst && newPageNumber < 0) || (isLast && newPageNumber > numberOfPages)) {
        return;
      }

      setPageNumber(newPageNumber);
    },
    [isFirst, isLast, numberOfPages, pageNumber]
  );

  return {
    currentPage,
    isFirst,
    isLast,
    setPageNumber: setPage,
    numberOfPages,
    pageNumber
  };
}
