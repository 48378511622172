import { FC } from 'react';

import {
  Autocomplete,
  AutocompleteProps
} from 'components/UIkit/atoms/Dropdown/Select/Autocomplete/Autocomplete';
import { PaginatorSelectContainer } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/PaginatorSelect/PaginatorSelectContainer';
import { PaginatorSelectControl } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/PaginatorSelect/PaginatorSelectControl';
import { PaginatorSelectInput } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/PaginatorSelect/PaginatorSelectInput';
import { PaginatorSelectMenu } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/PaginatorSelect/PaginatorSelectMenu';
import { PaginatorSelectMenuList } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/PaginatorSelect/PaginatorSelectMenuList';
import { PaginatorSelectOption } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/PaginatorSelect/PaginatorSelectOption';
import { PaginatorSelectSingleValue } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/PaginatorSelect/PaginatorSelectSingleValue';
import { PaginatorSelectValueContainer } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/PaginatorSelect/PaginatorSelectValueContainer';

import { SelectComponents } from 'components/UIkit/atoms/Dropdown/Select.shared';

export const PaginatorSelect: FC<AutocompleteProps> = (props) => {
  const components: SelectComponents = {
    IndicatorsContainer: () => null,
    SelectContainer: PaginatorSelectContainer,
    Control: PaginatorSelectControl,
    SingleValue: PaginatorSelectSingleValue,
    Option: PaginatorSelectOption,
    ValueContainer: PaginatorSelectValueContainer,
    Input: PaginatorSelectInput,
    Menu: PaginatorSelectMenu,
    MenuList: PaginatorSelectMenuList,
    NoOptionsMessage: () => null
  };

  return (
    <Autocomplete
      components={components}
      blurInputOnSelect
      classNamePrefix="react-select"
      sortAlphabetically={false}
      {...props}
    />
  );
};
