import { FC } from 'react';

import { css, styled } from '@mui/material/styles';

import { FormLabeledCheckbox } from 'components/UIkit/atoms/Checkbox';

export const MessagingConsentFormLabeledCheckbox: FC = () => (
  <StyledContainer>
    <FormLabeledCheckbox
      name="messagingConsent"
      label="Patient consents to receiving updates about tickets via text message."
    />
  </StyledContainer>
);

const StyledContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    border: 1px solid ${theme.palette.natural.border};
    border-radius: ${theme.borderRadius.medium};
    transition: border-color 0.2s ease-in-out;
    background-color: ${theme.palette.natural.white};
    padding: ${theme.spacing(12)};
    color: ${theme.palette.secondary.dark};
  `
);
