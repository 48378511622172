import React, { FC } from 'react';

interface Props {
  disabled: boolean;
}

const AddCircleOutline: FC<Props> = ({ disabled }) => {
  const color = disabled ? '#7A7A7A' : '#007C66';

  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="0.521973" width="17" height="17" rx="8.5" stroke={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0437 8.46241L10.0435 5.17211C10.0383 4.87303 9.79433 4.6333 9.4952 4.6333C9.19607 4.6333 8.95214 4.87303 8.94694 5.17211L8.94709 8.46238L5.65683 8.46222C5.35775 8.46742 5.11802 8.71135 5.11802 9.01048C5.11802 9.30961 5.35775 9.55354 5.65683 9.55874L8.94713 9.55894L8.94733 12.8492C8.94388 13.0474 9.04762 13.232 9.21867 13.332C9.38972 13.4321 9.60146 13.4321 9.7725 13.332C9.94355 13.232 10.0473 13.0474 10.0438 12.8492L10.0437 9.55897L13.334 9.55913C13.5321 9.56257 13.7167 9.45884 13.8168 9.28779C13.9168 9.11674 13.9168 8.905 13.8168 8.73395C13.7167 8.5629 13.5321 8.45916 13.334 8.46261L10.0437 8.46241Z"
        fill="#007C66"
        stroke={color}
      />
    </svg>
  );
};

export default AddCircleOutline;
