import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import classNames from 'classnames';

import { Testable } from 'utils/TypeUtils';

import './IconButton.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement>, Testable {
  children: ReactNode;
  // custom props can be added here
}

const IconButton: FC<IProps> = ({ disabled, onClick, children, className, testHook }) => {
  return (
    <button
      className={classNames('icon-button', className)}
      disabled={disabled}
      onClick={onClick}
      data-test-hook={testHook}
    >
      {children}
    </button>
  );
};

export default IconButton;
