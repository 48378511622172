import { Box, css, styled } from '@mui/material';

export const StyledSectionActionsContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${theme.spacing(20)};
  `
);

export const StyledPaginatorAndRefreshButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 32px;
`;
