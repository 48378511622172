// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react';

const useEllipsis = (isVisible?: boolean) => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(null);
  const ref = useRef(null);

  useEffect(
    function checkEllipsis() {
      setIsEllipsisActive(ref.current?.offsetWidth < ref.current?.scrollWidth);
    },
    [isVisible]
  );

  return { isEllipsisActive, ref };
};

export default useEllipsis;
