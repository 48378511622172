import { ReactNode } from 'react';

import { Box, css, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useToggle } from 'hooks/useToggle';

import Card from 'components/Surfaces/Card';
import { TextButton } from 'components/UIkit/atoms/Button';

interface ItemRowProps {
  icon?: ReactNode;
  content?: ReactNode;
  collapsibleContent?: ReactNode;
  isExpanded?: boolean;
  action?: ReactNode;
  className?: string;
  withToggleTextButton?: boolean;
}
export const ItemRow = ({
  icon,
  content,
  collapsibleContent,
  isExpanded,
  action,
  className,
  withToggleTextButton = true
}: ItemRowProps) => {
  const { isOpen, setIsOpen } = useToggle(false);
  const isInternalToggler = typeof isExpanded === 'undefined' && collapsibleContent;

  const expandHandler = () => isInternalToggler && setIsOpen((currentlyOpen) => !currentlyOpen);

  return (
    <Card>
      <StyledRow container direction="column" p={20} className={`item-row ${className}`}>
        <Grid container alignItems="center" className="item-row-content-wrapper">
          <StyledIconContainer container alignItems="center" className="item-icon-container">
            {icon}
          </StyledIconContainer>

          <StyledContent pr={20} className="item-content">
            {content}

            {withToggleTextButton && (
              <TextButton onClick={expandHandler} variant="secondary" mt={4}>
                {isOpen ? 'hide' : 'show'} details
              </TextButton>
            )}
          </StyledContent>
          {action && (
            <Box ml="auto" className="item-row-action">
              {action}
            </Box>
          )}
        </Grid>

        {(isOpen || isExpanded) && (
          <StyledExpandedView className="item-expanded-view-container">
            {collapsibleContent}
          </StyledExpandedView>
        )}
      </StyledRow>
    </Card>
  );
};

const StyledRow = styled(Grid)(
  ({ theme }) => css`
    width: 100%;
    min-height: 75px;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(20)};
    }
  `
);

const StyledIconContainer = styled(Grid)`
  width: 74px;
`;

const StyledContent = styled(Box)`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledExpandedView = styled(Box)(
  ({ theme }) => css`
    &:not(:empty) {
      margin-top: ${theme.spacing(20)};
      padding-left: ${theme.spacing(76)};
    }
  `
);
