// @ts-strict-ignore
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { DataMap } from 'mobx/stores/DataMap';

import ProvidersFetcher from 'fetchers/ProvidersFetcher';

import { isSubStringCaseInsensitive } from 'utils/StringUtils';

import PatientProvider, { CurrentIndicatorTypes } from 'models/PatientProvider';

import { ISelectOption } from 'views/Widgets/StyledSelect';

import { RootStore } from './rootStore';

export const SELECTED_PROVIDERS_STORAGE_KEY = '_selectedProviders';

export default class ProvidersStore {
  @observable
  providers: DataMap<PatientProvider> = new DataMap<PatientProvider>();

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  async fetchPatientProviders() {
    const fetchedProviders = await ProvidersFetcher.getPatientProviders();
    runInAction(() => {
      this.providers.setItems(fetchedProviders);
    });
  }

  @computed
  get visibleProviders() {
    return this.providers.items.filter(
      (provider) => provider.currentIndicator === CurrentIndicatorTypes.Y
    );
  }

  providersByQuery(query: string) {
    return this.providers.items.filter((provider: PatientProvider) =>
      isSubStringCaseInsensitive(provider.name, query)
    );
  }

  getProviderById = (providerId: string | number): PatientProvider => {
    return this.providers.get(providerId);
  };

  @computed
  get providersForSelect(): ISelectOption<PatientProvider>[] {
    return this.visibleProviders.map((provider: PatientProvider) => ({
      value: provider,
      label: provider.name
    }));
  }
}
