import { FC } from 'react';

import { DropdownInputName } from 'analytics/events/input-usage';
import { useFormContext } from 'react-hook-form';

import { ActionMeta } from 'react-select';

import { useProvidersOptions } from 'mobx/hooks/useProvidersOptions';
import { useStores } from 'mobx/hooks/useStores';

import { Tooltip } from 'components/Tooltip';
import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';

interface Props {
  disabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  name?: string;
  label?: string;
  trackAnalyticsEvent?: (
    actionMeta: ActionMeta<any>,
    inputName: DropdownInputName,
    isKeyboardSource: boolean,
    hasPreviousValue: boolean
  ) => void;
}

export const PatientProviderAutocomplete: FC<Props> = ({
  disabled,
  isRequired,
  placeholder,
  name = 'provider',
  label = 'Primary Provider',
  trackAnalyticsEvent
}) => {
  const { settingsStore } = useStores();
  const { providerFieldEditable } = settingsStore.institutionSettings;
  const providersOptions = useProvidersOptions().map((option) => ({
    ...option,
    value: option.value
  }));

  const { watch } = useFormContext();
  const provider = watch(name);

  const providerSelect = (
    <FormAutocomplete
      label={label}
      name={name}
      isRequired={isRequired && providerFieldEditable}
      isDisabled={disabled || providersOptions.length === 0 || !providerFieldEditable}
      options={providersOptions}
      placeholder={placeholder}
      getOptionValue={(option: any) => option.value.id}
      onChange={(value, actionMeta, eventKey) =>
        trackAnalyticsEvent &&
        trackAnalyticsEvent(actionMeta, 'Primary Provider', eventKey === 'Enter', Boolean(provider))
      }
    />
  );
  return providerFieldEditable ? (
    providerSelect
  ) : (
    <Tooltip disabled={providerFieldEditable} label={providerSelect}>
      <div className="p-3">The primary provider is the one on OncoEMR</div>
    </Tooltip>
  );
};
