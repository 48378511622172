import { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import { useStores } from 'mobx/hooks/useStores';

import QaFetcher from 'fetchers/QaFetcher';

import { usePatientModel } from 'components/Patient/usePatientModel';
import { Modal } from 'components/UIkit/atoms/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const QaPatientDeceasedStatusModal: FC<Props> = ({ isOpen, onSubmit, onClose }) => {
  const { patientPageStore } = useStores();
  const patient = usePatientModel();
  let deceasedActionText = 'N/A';

  if (patient?.id && !patient?.isDeceased) {
    deceasedActionText = 'Set Deceased';
  }

  const handleToggleDeceased = async () => {
    await QaFetcher.setPatientDeceasedStatus(patient!.id);
    await patientPageStore.loadSinglePatientFromServer(patient!.id);
    onSubmit();
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Patient Deceased Status"
      closeAction={{
        disabled: false,
        onClick: onClose
      }}
      confirmActions={[
        {
          onClick: handleToggleDeceased,
          text: deceasedActionText,
          disabled: !patient || patient.isDeceased
        }
      ]}
    >
      <Grid container flexDirection="column" pb={24}>
        {patient?.id ? (
          <>
            <Typography variant="form-text">
              Status: <strong>{patient.isDeceased ? 'Deceased' : 'Not Deceased'}</strong>
            </Typography>
          </>
        ) : (
          <Typography>
            Changing Deceased status must be done on the desired patient's page
          </Typography>
        )}
      </Grid>
    </Modal>
  );
};
