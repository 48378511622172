import { TippyProps } from '@tippyjs/react';
import { Instance } from 'tippy.js';

export type PopperModifier = Exclude<TippyProps['popperOptions']['modifiers'], undefined>[number];

// see examples at: https://atomiks.github.io/tippyjs/v6/all-props/#trigger
export enum TooltipTrigger {
  CLICK = 'click',
  MOUSE_ENTER = 'mouseenter'
}

export interface TooltipEvents {
  onShow?(instance: Instance<any>): void | false;
  onHide?(instance: Instance<any>): void | false;
  onMount?(instance: Instance<any>): void;
}

export interface TooltipController {
  visible: boolean;
  onClickOutside: () => void;
}
