import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.OptOut | AnalyticEventAction.OptIn;
type Value = 'save with changes' | 'save without changes' | 'missing fields';

interface Properties {
  action: Action;
  value?: Value;
  patient_id?: string;
}

export const trackOptOutRemoteMonitoringAnalyticsEvent = ({
  action,
  ...optionalProperties
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.OptOutRemoteMonitoring,
    properties: { action, ...optionalProperties }
  });
};
