// @ts-strict-ignore
import { castArray, orderBy, xor } from 'lodash/fp';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';

import { RootStore } from 'mobx/stores';
import { DataMap } from 'mobx/stores/DataMap';

import {
  EpisodesFetcher,
  CreateEpisodeAttrs,
  SearchEpisodesQuery,
  UpdateEpisodeAttrs
} from 'fetchers/EpisodesFetcher';

import { TaskRolesFetcher, CreateUpdateTaskRoleParams } from 'fetchers/TaskRolesFetcher';
import TaskTemplatesFetcher, {
  CreateTaskTemplateAttrs,
  DuplicateTaskTemplateAttrs,
  UpdateTaskTemplateAttrs
} from 'fetchers/TaskTemplatesFetcher';

import Episode from 'models/Episode';
import { SelectOption } from 'models/SelectOption';
import TaskTemplate from 'models/TaskTemplate';

class EpisodeManagementStore {
  rootStore: RootStore;

  @observable
  allEpisodesMap = new DataMap<Episode>();

  @observable
  currentFilteredEpisodesMap = new DataMap<Episode>();

  @observable
  taskTemplatesMap = new DataMap<TaskTemplate>();

  @observable
  private searchTerm: string = null;

  @observable
  selectedEpisodeIds: number[] = null;

  @observable
  episodeNameFilter: SelectOption<number>[] = null;

  @observable
  private searchInProgress: boolean = false;

  @observable
  fetchingEpisodesIds: Set<number> = new Set<number>();

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  fetchEpisodes = async () => {
    const episodes = await EpisodesFetcher.fetchEpisodes();
    this.allEpisodesMap.setItems(episodes);
    return episodes;
  };

  fetchAndInitFilteredEpisodes = async () => {
    const episodes = await this.fetchEpisodes();
    this.currentFilteredEpisodesMap.setItems(episodes);
  };

  @action
  searchEpisodes = async (query?: SearchEpisodesQuery) => {
    try {
      runInAction(() => {
        this.searchInProgress = true;
      });

      const { episodes, taskTemplates } = await EpisodesFetcher.searchEpisodes(
        query || this.episodesQuery
      );
      return {
        episodes,
        taskTemplates
      };
    } finally {
      runInAction(() => {
        this.searchInProgress = false;
      });
    }
  };

  searchAndSetEpisodes = async () => {
    const { episodes, taskTemplates } = await this.searchEpisodes();

    this.currentFilteredEpisodesMap.setItems(episodes);
    this.taskTemplatesMap.setItems(taskTemplates);
  };

  @action
  searchSingleEpisode = async (episodeId: number) => {
    try {
      this.fetchingEpisodesIds.add(episodeId);
      const { taskTemplates } = await this.searchEpisodes({
        ...this.episodesQuery,
        episodeIds: [episodeId]
      });
      this.taskTemplatesMap.replaceItems(taskTemplates);
    } finally {
      this.fetchingEpisodesIds.delete(episodeId);
    }
  };

  refreshEpisodes = () => {
    this.fetchEpisodes();
    this.searchAndSetEpisodes();
    this.rootStore.stores.constantsStore.fetchInstitutionMetadata();
  };

  createEpisode = async (body: CreateEpisodeAttrs) => {
    const newEpisodeId = await EpisodesFetcher.createEpisode(body);
    this.toggleEpisodeIds(newEpisodeId);
    this.refreshEpisodes();
  };

  updateEpisode = async (id: number, body: UpdateEpisodeAttrs) => {
    await EpisodesFetcher.updateEpisode(id, body);
    this.refreshEpisodes();
  };

  deleteEpisode = async (id: number) => {
    await EpisodesFetcher.deleteEpisode(id);
    this.refreshEpisodes();
  };

  createTaskTemplate = async (body: CreateTaskTemplateAttrs) => {
    await TaskTemplatesFetcher.createTaskTemplate(body);
    this.searchAndSetEpisodes();
  };

  updateTaskTemplate = async (id: number, body: UpdateTaskTemplateAttrs) => {
    await TaskTemplatesFetcher.updateTaskTemplate(id, body);
    this.searchAndSetEpisodes();
  };

  deleteTaskTemplate = async (id: number) => {
    await TaskTemplatesFetcher.deleteTaskTemplate(id);
    this.taskTemplatesMap.delete(id);
  };

  duplicateTaskTemplate = async (
    taskTemplateId: number,
    duplicateParams: DuplicateTaskTemplateAttrs
  ) => {
    await TaskTemplatesFetcher.duplicateTaskTemplate(taskTemplateId, duplicateParams);
    this.searchAndSetEpisodes();
  };

  fetchAndSetRoles = async () => {
    const roles = await TaskRolesFetcher.fetchTaskRoles();
    this.rootStore.stores.constantsStore.taskRoles.setItems(roles);
  };

  createTaskRole = async (params: CreateUpdateTaskRoleParams) => {
    await TaskRolesFetcher.createTaskRole(params);
    this.fetchAndSetRoles();
  };

  updateTaskRole = async (id: number, params: CreateUpdateTaskRoleParams) => {
    await TaskRolesFetcher.updateTaskRole(id, params);
    this.fetchAndSetRoles();
  };

  deleteTaskRole = async (id: number) => {
    await TaskRolesFetcher.deleteTaskRole(id);
    this.fetchAndSetRoles();
  };

  @action
  setSearchTerm = (searchTerm: string) => {
    this.searchTerm = searchTerm;
  };

  @action
  toggleEpisodeIds = (episodeId: number | number[]) => {
    const newIds = castArray(episodeId);
    // for whichever id/s is sent it will either insert or remove it from the array
    // used for saving collapse expand state and for queries
    this.selectedEpisodeIds = xor(this.selectedEpisodeIds, newIds);
  };

  @action
  setEpisodeNameFilter = (episodes: any[]) => {
    this.episodeNameFilter = episodes;
  };

  @action
  resetStore = () => {
    this.searchTerm = null;
    this.episodeNameFilter = null;
    this.selectedEpisodeIds = null;
    this.currentFilteredEpisodesMap = new DataMap<Episode>();
    this.taskTemplatesMap = new DataMap<TaskTemplate>();
  };

  @computed
  get episodesQuery() {
    return {
      searchTerm: this.searchTerm,
      episodeIds: this.selectedEpisodeIds
    };
  }

  @computed
  get currentFilteredEpisodes() {
    return orderBy(['createdAt'], ['desc'], this.currentFilteredEpisodesMap.items);
  }

  @computed
  get allEpisodes() {
    return this.allEpisodesMap.items;
  }

  getEpisodeById = computedFn((id: number) => {
    return this.allEpisodesMap.get(id);
  });
  @computed
  get taskTemplates() {
    return this.taskTemplatesMap.items;
  }

  @computed
  get episodesByNameFilter() {
    return this.currentFilteredEpisodes.filter((episode) => {
      if (!this.selectedEpisodeFilters.length) {
        return true;
      }
      return this.selectedEpisodeFilters.includes(episode.id);
    });
  }

  @computed
  get selectedEpisodeFilters() {
    return this.episodeNameFilter?.map((filterOption) => filterOption.value) || [];
  }

  @computed
  get isFilterQueryInProgress() {
    return this.searchInProgress && this.fetchingEpisodesIds.size === 0;
  }

  getEpisodeTaskTemplates = computedFn<(episodeId: number) => TaskTemplate[]>(
    (episodeId: number) => {
      return this.taskTemplates.filter((taskTemplate) => taskTemplate.episodeId === episodeId);
    }
  );

  isInEpisodeIds = computedFn((id: number) => {
    return Boolean(this.selectedEpisodeIds?.includes(id));
  });

  isFetchingEpisode = computedFn((episodeId: number): boolean => {
    return this.fetchingEpisodesIds.has(episodeId);
  });
}

export default EpisodeManagementStore;
