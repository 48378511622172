import { FC, useCallback, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';

import { Dialogs } from 'analytics/events/dialog';
import { FormProvider, useForm } from 'react-hook-form';
import { Row } from 'react-table';

import { useClickAway } from 'react-use';

import { pathwayBuilderTestSelectors } from 'tests/models/pages/pathway-builder/pathway-builder-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { PathwayTemplatesQuestion } from 'fetchers/responses/pathways-builder.response';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import { defaultQuestionTypeOption } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.constants';

import { QuestionForm } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.types';

import { MultipleQuestionOptionsForm } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionForms/MultipleQuestionOptionsForm';
import { SingleQuestionOptionsForm } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionForms/SingleQuestionOptionsForm';

import { StyledSeparator } from 'views/Pages/PathwayBuilder/shared/styled';

import { FormInputField } from 'components/Forms';
import { OutlinedButton, FilledButton } from 'components/UIkit/atoms/Button';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';
import { FormSelect } from 'components/UIkit/atoms/Dropdown';

interface QuestionRowEditComponentProps {
  row: Row<PathwayTemplatesQuestion>;
  onRowEditSubmit: (row: QuestionForm, id: string) => void;
  onRowEditCancel: () => void;
}

export const QuestionRowEditComponent: FC<QuestionRowEditComponentProps> = ({
  row,
  onRowEditSubmit,
  onRowEditCancel
}) => {
  const { pathwayBuilderStore } = useStores();
  const [isSaveChangesWarningOpen, setIsSaveChangesWarningOpen] = useState<boolean>(false);
  const rowRef = useRef<HTMLDivElement>(null);
  const { optionsForPathwayTypeSelect } = pathwayBuilderStore;
  const { title, type, options } = row.original;

  const submitQuestion = (formValues: QuestionForm) => {
    onRowEditSubmit(formValues, row.original.id);
  };

  const methods = useForm<QuestionForm>({
    defaultValues: {
      title,
      type:
        optionsForPathwayTypeSelect.find((option) => option.value === type) ||
        defaultQuestionTypeOption,
      options
    }
  });

  const { handleSubmit, watch, formState } = methods;
  const { isDirty } = formState;
  const watchedQuestionType = watch('type');

  const handleClickAway = useCallback(() => {
    if (isDirty) {
      setIsSaveChangesWarningOpen(true);
      return;
    }
    onRowEditCancel();
  }, [isDirty, onRowEditCancel]);

  useClickAway(rowRef, handleClickAway);

  return (
    <Box p={20} flex={1} ref={rowRef}>
      <MessageDialog
        id={Dialogs.DismissChangesPathwayQuestion}
        isOpen={isSaveChangesWarningOpen}
        title="Dismiss Changes to Question"
        handleClose={() => setIsSaveChangesWarningOpen(false)}
        primaryActionProps={{
          text: 'Dismiss Changes',
          onClick: () => {
            onRowEditCancel();
            setIsSaveChangesWarningOpen(false);
          }
        }}
        secondaryActionProps={{ text: 'Cancel', onClick: () => setIsSaveChangesWarningOpen(false) }}
      >
        You are currently editing a question. If you proceed, all changes will be lost.
        <br />
        Are you sure you wish to proceed?
      </MessageDialog>
      <FormProvider {...methods}>
        <Grid container flexDirection="column">
          <Grid item display="flex" justifyContent="space-between">
            <Grid container gap={24}>
              <Grid item flex={4}>
                <FormInputField
                  placeholder="Question Title"
                  label=""
                  name="title"
                  isRequired
                  testHook={pathwayBuilderTestSelectors.tabs.questions.titleInput}
                />
              </Grid>
              <Grid item flex={1}>
                <FormSelect
                  name="type"
                  options={optionsForPathwayTypeSelect}
                  isRequired
                  testHook={pathwayBuilderTestSelectors.tabs.questions.typeSelect}
                />
              </Grid>
            </Grid>
          </Grid>
          {(watchedQuestionType.value as PathwayQuestionTypes) ===
            PathwayQuestionTypes.MULTIPLE && (
            <Grid item mt={36}>
              <MultipleQuestionOptionsForm />
            </Grid>
          )}
          {(watchedQuestionType.value as PathwayQuestionTypes) === PathwayQuestionTypes.SINGLE && (
            <Grid item mt={36}>
              <SingleQuestionOptionsForm />
            </Grid>
          )}
          <Grid item>
            <StyledSeparator />
            <Grid container justifyContent="flex-end" gap={16}>
              <OutlinedButton onClick={onRowEditCancel} size="medium">
                Cancel
              </OutlinedButton>
              <FilledButton
                disabled={!isDirty}
                onClick={handleSubmit(submitQuestion)}
                mr={4}
                testHook={pathwayBuilderTestSelectors.tabs.questions.saveQuestionButton}
              >
                Save
              </FilledButton>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
};
