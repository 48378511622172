// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';

import Patient from 'models/Patient';

import { useToggle } from 'hooks/useToggle';

import SmsBlockedPopup from 'views/Modals/SmsBlockedPopup';

import { CollapsibleSection } from 'views/Widgets/CollapsibleSection';

import { CardRow } from 'components/Surfaces/Card';
import OverduePatientReportRow from 'components/Ticket/TicketRow/OverduePatientReportRow';
import { useIsInPatientRoute } from 'components/Ticket/TicketsContainers/useIsInPatientRoute';

import { Section } from './TicketsContainers.constants';
import { useSmsModal } from './TicketsContainers.shared';
import { useSectionExpand } from './useTicketOverviewContext';

interface IProps {
  overduePatients: Patient[];
}

const TicketsSectionOverdue: FC<IProps> = ({ overduePatients }) => {
  const [smsModal, toggleSmsModal] = useSmsModal();
  const smsBlockedPopup = useToggle(false);
  const [isExpanded, setIsExpanded] = useSectionExpand(Section.Overdue);
  const isOnPatientPage = useIsInPatientRoute();

  return (
    <div className="report-section">
      {smsModal}
      <SmsBlockedPopup isOpen={smsBlockedPopup.isOpen} onCancelClicked={smsBlockedPopup.toggle} />

      <CollapsibleSection
        name={Section.Overdue}
        trigger={
          <div className="report-section-header">
            {`${Section.Overdue} (${overduePatients.length})`}
          </div>
        }
        onTrigger={setIsExpanded}
        initiallyOpen={isExpanded}
      >
        <div className="tickets-list">
          {overduePatients.map((patient, index) => (
            <CardRow key={patient.id}>
              <OverduePatientReportRow
                patient={patient}
                toggleSmsModal={(shouldOpenSmsBlockedPopup, patient) =>
                  shouldOpenSmsBlockedPopup ? smsBlockedPopup.toggle() : toggleSmsModal(patient)
                }
                withPatientLink={!isOnPatientPage}
                ticketIndex={index}
              />
            </CardRow>
          ))}
        </div>
      </CollapsibleSection>
    </div>
  );
};

export default observer(TicketsSectionOverdue);
