import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { components } from 'react-select';

import { addDecimalToICDCodeIfNeeded } from 'utils/IcdCodesUtils';

import { ICallReasonTypes } from 'models/CallReason';

import {
  BaseAsyncSelectFormProps,
  FormAsyncBaseSelect
} from 'components/UIkit/atoms/Dropdown/Select/Autocomplete/AsyncAutocomplete';
import {
  DefaultMenuOption,
  StyledMenuOption
} from 'components/UIkit/atoms/Dropdown/Select/ReactSelectOptionComponent';
import { SelectComponents } from 'components/UIkit/atoms/Dropdown/Select.shared';

const MAX_CALL_REASONS_ALLOWED = 6;

const getMaxItemsMenu = (message: string, maxItems: number) => {
  return (props: any) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < maxItems ? (
          props.children
        ) : (
          <Box p={8} display="flex" justifyContent="center">
            {message}
          </Box>
        )}
      </components.Menu>
    );
  };
};

const CustomCallReasonOption = (props: any) => {
  if (props.data.value.type === ICallReasonTypes.ICD_CODE) {
    return (
      <StyledMenuOption {...props}>
        <Box display="flex" pl={20}>
          <Box minWidth="90px">
            <Typography variant="h4">
              {addDecimalToICDCodeIfNeeded(props.data.value.icdCodeCode)}
            </Typography>
          </Box>
          <Typography variant="form-text">{props.data.value.text}</Typography>
        </Box>
      </StyledMenuOption>
    );
  }
  return <DefaultMenuOption {...props}>{props.children}</DefaultMenuOption>;
};

const IcdCodesMenu = getMaxItemsMenu(
  `${MAX_CALL_REASONS_ALLOWED} Codes Max`,
  MAX_CALL_REASONS_ALLOWED
);

interface FormIcdCodesAsyncMultiAutocompleteProps
  extends Omit<BaseAsyncSelectFormProps, 'defaultOptions'> {}

export const FormIcdCodesAsyncMultiAutocomplete: FC<FormIcdCodesAsyncMultiAutocompleteProps> = (
  props
) => {
  const components: SelectComponents = { Option: CustomCallReasonOption, Menu: IcdCodesMenu };

  return <FormAsyncBaseSelect isMulti defaultOptions components={components} {...props} />;
};
