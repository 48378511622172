import { useCallback } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import Patient from 'models/Patient';

import { PATIENT_TABS } from 'views/Patient/PatientMain/PatientMainView.constants';

export function useRefreshSinglePatientData(patient: Patient, selectedTab: string) {
  const { patientPageStore, ticketsStore, tasksStore } = useStores();

  return useCallback(async () => {
    switch (selectedTab) {
      case PATIENT_TABS.RESOLVED_TICKETS:
        await Promise.all([
          patientPageStore.loadSinglePatientFromServer(patient.id),
          ticketsStore.searchTicketsForResolvedTicketsTab(patient.id)
        ]);
        break;
      case PATIENT_TABS.CALLS:
        await patientPageStore.loadSinglePatientFromServer(patient.id);
        break;
      default:
        await Promise.all([
          patientPageStore.loadPatientAndTickets(patient.id),
          tasksStore.fetchTasks()
        ]);
        break;
    }
  }, [patient, selectedTab, patientPageStore, ticketsStore, tasksStore]);
}
