import { transformErrorUiProps } from 'errors';

import { Region } from 'fetchers/responses/regions.response';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import ParseServerResponseService from 'parsers/ParseServerResponseService';

import PatientLocation from 'models/PatientLocation';

const httpService = new HttpService('location', true);

export class LocationsFetcher {
  static async getPatientLocations(): Promise<PatientLocation[]> {
    return await httpService.get({
      url: API_URLS.LOCATIONS,
      transformResponse: (items) => items.map(ParseServerResponseService.parseLocation)
    });
  }

  static async updateLocation(location: PatientLocation): Promise<void> {
    return await httpService.put({
      url: API_URLS.UPDATE_LOCATION(location.id),
      data: { location }
    });
  }

  static async getRegions(): Promise<Region[]> {
    return await httpService.get({
      url: API_URLS.REGIONS,
      transformError: transformErrorUiProps('Failed to fetch regions')
    });
  }
}
