// @ts-strict-ignore
import { FC, useEffect, useMemo } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackShowMoreAnalyticsEvent } from 'analytics/events/show-more';

import { observer } from 'mobx-react';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';

import { useStores } from 'mobx/hooks/useStores';

import { getDateMonthString } from 'utils/DateUtils';

import { API_LABELS } from 'constants/apiUrls';

import ClusteredTaskTicket from 'models/ClusteredTaskTicket';

import Ticket from 'models/Ticket';

import { LazyCardStack } from 'views/Widgets/CardStack';

import Chip from 'components/Chips/Chip';
import { usePatientModel } from 'components/Patient/usePatientModel';

import { CardRow } from 'components/Surfaces/Card';
import EpisodeTag from 'components/Ticket/TicketRow/EpisodeTag';
import TaskTicketRow, { TaskRowDueDateIcon } from 'components/Ticket/TicketRow/TaskTicketRow';
import TicketRenderer from 'components/Ticket/TicketRow/TicketRenderer';
import { TextButton } from 'components/UIkit/atoms/Button';

interface TaskTicketItemProps {
  clusteredTask: ClusteredTaskTicket;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
}

export const ClusteredTasksItem = observer(
  ({ clusteredTask, ticketIndex, ticketSectionCurrentPage }: TaskTicketItemProps) => {
    if (clusteredTask.singleTask) {
      return (
        <CardRow>
          <TaskTicketRow
            ticket={clusteredTask.singleTask}
            ticketIndex={ticketIndex}
            withPatientLink
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />
        </CardRow>
      );
    } else {
      return (
        <ClusteredTasksRow
          clusteredTask={clusteredTask}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
        />
      );
    }
  }
);

const ClusteredTasksRow: FC<{
  clusteredTask: ClusteredTaskTicket;
  ticketSectionCurrentPage: number;
}> = observer(({ clusteredTask, ticketSectionCurrentPage }) => {
  const { tasksStore } = useStores();
  const isOpen = tasksStore.patientIdsToFetch.has(clusteredTask.patientId);
  const items = tasksStore.tasksForPatientCluster(clusteredTask.patientId);

  const isLoadingTasks = useNetworkLoading(API_LABELS.TASKS_BY_PATIENT(clusteredTask.patientId));

  useEffect(
    function onExpandChangedEffect() {
      isOpen
        ? tasksStore.setTasksForPatientCluster(clusteredTask.patientId)
        : tasksStore.clearTasksForPatientCluster(clusteredTask.patientId);
    },
    [clusteredTask.patientId, isOpen, tasksStore]
  );

  const onToggle = () => {
    const isOpen = tasksStore.patientIdsToFetch.has(clusteredTask.patientId);
    trackShowMoreAnalyticsEvent({
      action: isOpen ? AnalyticEventAction.Hide : AnalyticEventAction.Show,
      value: 'task',
      page_number: ticketSectionCurrentPage + 1,
      type: null
    });
    isOpen
      ? tasksStore.patientIdsToFetch.delete(clusteredTask.patientId)
      : tasksStore.patientIdsToFetch.add(clusteredTask.patientId);
  };

  const itemsToRender = useMemo(
    () =>
      items?.map((taskTicket: Ticket, index) => (
        <TaskTicketRow
          key={taskTicket.id}
          ticket={taskTicket}
          className="no-border"
          withPatientLink
          ticketIndex={index}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
        />
      )),
    [items, ticketSectionCurrentPage]
  );

  return (
    <LazyCardStack
      isOpen={isOpen}
      classes={{
        root: 'ticket-row',
        closed: 'report-row-closed'
      }}
      trigger={
        <ClusteredSummaryRow
          isExpanded={isOpen}
          onToggle={onToggle}
          isLoading={isLoadingTasks}
          clusteredTask={clusteredTask}
        />
      }
      items={itemsToRender}
    />
  );
});

const ClusteredSummaryRow = ({
  clusteredTask,
  onToggle,
  isExpanded,
  isLoading
}: {
  clusteredTask: ClusteredTaskTicket;
  onToggle: () => void;
  isExpanded: boolean;
  isLoading?: boolean;
}) => {
  const { constantsStore } = useStores();
  const { monthNumbers: months, episodeIds, taskCount } = clusteredTask;

  const patient = usePatientModel(clusteredTask.patientId);
  const toggleButtonText = `${isExpanded ? 'hide' : 'show'} ${taskCount} tasks`;

  const episodesChips = useMemo(
    () =>
      episodeIds.sort().map((episodeId) => {
        const episode = episodeId !== -1 ? constantsStore.getEpisodeById(episodeId) : null;
        return <EpisodeTag key={episodeId} episode={episode} />;
      }),
    [constantsStore, episodeIds]
  );

  const monthsChip = useMemo(() => {
    if (!months.length) {
      return null;
    }

    return <Chip.Item className="mb-2">Months: {months.sort().join(', ')}</Chip.Item>;
  }, [months]);
  return (
    <TicketRenderer
      patient={patient}
      isLoading={isLoading}
      withPatientLink
      icon={
        <TaskRowDueDateIcon header={getDateMonthString(clusteredTask.dueDateMin).toUpperCase()}>
          {clusteredTask.dueDateByTypeAsNumber('dueDateMin')}
        </TaskRowDueDateIcon>
      }
      classes={{ container: 'ticket-row no-border' }}
      summary={
        <>
          <div className="title-text">
            {taskCount} Tasks Due from {clusteredTask.formattedDateByType('dueDateMin')} to{' '}
            {clusteredTask.formattedDateByType('dueDateMax')}
          </div>
          <Chip.List classes="mt-1">
            {episodesChips}
            {monthsChip}
          </Chip.List>

          <TextButton onClick={onToggle} variant="secondary" mt={8}>
            {toggleButtonText}
          </TextButton>
        </>
      }
    />
  );
};
