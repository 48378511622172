import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import {
  CM_INELIGIBILITY,
  CM_OPT_OUT,
  CM_QUICK_ADDITION,
  EPISODE_END,
  REASON_TYPE
} from 'constants/reasons.const';

import { Reason } from 'models/Reason';

const httpService = new HttpService('Reasons');

export class ReasonsFetcher {
  private static fetchReasons(type: REASON_TYPE) {
    return httpService.get<Reason[]>({
      url: API_URLS.REASONS_BY_TYPE(type)
    });
  }

  private static createReason(type: REASON_TYPE, title: string, isActive: boolean) {
    return httpService.post<number>({
      url: API_URLS.REASONS_BY_TYPE(type),
      data: { title, isActive }
    });
  }

  static fetchCareManagementIneligibilityReasons = async () =>
    await ReasonsFetcher.fetchReasons(CM_INELIGIBILITY);

  static fetchCareManagementOptOutReasons = async () =>
    await ReasonsFetcher.fetchReasons(CM_OPT_OUT);

  static fetchEpisodeEndReasons = async () => await ReasonsFetcher.fetchReasons(EPISODE_END);

  static fetchQuickAdditionReasons = async () =>
    await ReasonsFetcher.fetchReasons(CM_QUICK_ADDITION);

  static createCMReason = async (reasonType: REASON_TYPE, title: string, isActive: boolean) =>
    await ReasonsFetcher.createReason(reasonType, title, isActive);

  static async updateReason(id: number, title: string, type: REASON_TYPE) {
    return await httpService.put<number>({
      url: API_URLS.REASON_BY_ID(id, type),
      data: { title, isActive: true }
    });
  }

  static async deleteReason(id: number, type: REASON_TYPE) {
    return await httpService.delete({
      url: API_URLS.REASON_BY_ID(id, type)
    });
  }
}

export default ReasonsFetcher;
