// @ts-strict-ignore
import { FC, forwardRef, ReactNode } from 'react';

import classNames from 'classnames';

import './Chip.scss';

const COLOR_PALLET_SIZE = 10;

interface ChipItemProps {
  color?: number;
  borderless?: boolean;
  bold?: boolean;
  bgColorHex?: string;
  children?: ReactNode;
  className?: string;
}
const ChipItem = forwardRef<HTMLElement, ChipItemProps>(
  ({ children, color, borderless, bold, bgColorHex, className }, ref) => {
    color = 0 < color && color <= COLOR_PALLET_SIZE ? color : 0;
    const classes = classNames('chip', className, {
      'chip-default': !color,
      [`chip-color${color}`]: color,
      borderless,
      bold
    });
    return (
      <span ref={ref} style={bgColorHex ? { backgroundColor: bgColorHex } : {}} className={classes}>
        {children}
      </span>
    );
  }
);

interface ChipListProps {
  children: ReactNode;
  classes?: string;
}

const ChipList: FC<ChipListProps> = ({ children, classes }) => {
  return <div className={classNames('chip-list', classes)}>{children}</div>;
};

const Chip = {
  Item: ChipItem,
  List: ChipList
};

export default Chip;
