// @ts-strict-ignore

import { useMemo } from 'react';

import { observer } from 'mobx-react';

import { useFormContext, useWatch } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { TicketTypesStore } from 'mobx/stores';

import Patient from 'models/Patient';
import Ticket from 'models/Ticket';
import { TicketTypeKind } from 'models/TicketTypes';

import OperatorEditTicketInfoFormItem from 'components/Ticket/TicketForms/OperatorEditTicketInfoFormItem';
import { TicketFormField } from 'components/Ticket/TicketForms/ticket.shared';

export const EDITED_TICKET_FIELD_NAME = 'ticket';

export function isSymptomTicket(ticketType: TicketFormField, ticketTypesStore: TicketTypesStore) {
  const categoryId = ticketType?.categoryId;
  const type = categoryId ? ticketTypesStore.getTicketType(categoryId) : null;
  return type?.kind === TicketTypeKind.symptom;
}

export const OperatorEditTicketsInfoForm = observer(
  ({ patient, ticket }: { patient: Patient; ticket: Ticket }) => {
    const selectedTicketType = useWatch({ name: EDITED_TICKET_FIELD_NAME });

    const { formState } = useFormContext();
    const { errors } = formState;
    const { ticketTypesStore } = useStores();

    // when symptom type is selected - used multi select (otherwise - use single select)
    const isMulti = useMemo(() => {
      if (!selectedTicketType?.categoryId) {
        return false;
      }
      return isSymptomTicket(selectedTicketType, ticketTypesStore);
    }, [ticketTypesStore, selectedTicketType]);

    return (
      <div
        className="tickets-info-section"
        data-test-hook={`${isMulti ? 'multi' : 'single'}EditTicketTypesSection`}
      >
        <OperatorEditTicketInfoFormItem
          errors={errors}
          patient={patient}
          ticketSource={ticket?.operatorTicket?.source}
          categoryId={String(selectedTicketType.categoryId)}
          ticketSubTypeIds={selectedTicketType.ticketSubTypeIds}
          testHook="editOperatorTicketInfoItem"
          ticketLightweightPathwaysAnswers={ticket.operatorTicket.pathwayAnswers}
        />
      </div>
    );
  }
);
