import { FC, ReactNode, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { fromNow } from 'utils/DateUtils';

import Icon from 'components/Icons/Icon';
import { ClinicianNotification } from 'components/NotificationCenter/types';

import { Tooltip, TooltipSelect } from 'components/Tooltip';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  notification: ClinicianNotification;
  onClick: (notification: ClinicianNotification) => void;
  summary: string;
  badge: ReactNode;
  title: ReactNode;
  removeNotification: (notificationId: number) => void;
  markAsReadNotification: (notificationId: number) => void;
  markAsUnreadNotification: (notificationId: number) => void;
}

export const Notification: FC<Props> = ({
  notification,
  onClick,
  summary,
  badge,
  title,
  removeNotification,
  markAsReadNotification,
  markAsUnreadNotification
}) => {
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
  const { isRead, createdAt } = notification;

  return (
    <StyledContainer
      isRead={isRead}
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => {
        setIsRowHovered(false);
        setIsOptionsMenuOpen(false);
      }}
      isHovered={isRowHovered}
      onClick={() => onClick(notification)}
    >
      <Grid container mb={12} wrap="nowrap" justifyContent="space-between">
        <Typography component="div" variant="body2" width="85%">
          {title}
        </Typography>

        {!isRowHovered && (
          <StyledTimestamp variant="body2" isRead={isRead} ml={24}>
            {fromNow(createdAt, { shortest: true })}
          </StyledTimestamp>
        )}

        {isRowHovered && (
          <StyledTooltip
            appendTo="parent"
            label={
              <Icon.EllipsisOptions
                onClick={() => setIsOptionsMenuOpen((prevState) => !prevState)}
              />
            }
            controller={{
              onClickOutside: () => setIsOptionsMenuOpen(false),
              visible: isOptionsMenuOpen
            }}
            placement="bottom-start"
            offset={[0, 0]}
          >
            <TooltipSelect
              options={[
                {
                  text: notification.isRead ? 'Mark as Unread' : 'Mark as Read',
                  onClick: () => {
                    setIsOptionsMenuOpen(false);
                    notification.isRead
                      ? markAsUnreadNotification(notification.id)
                      : markAsReadNotification(notification.id);
                  }
                },
                {
                  text: 'Delete',
                  onClick: () => {
                    setIsOptionsMenuOpen(false);
                    removeNotification(notification.id);
                  }
                }
              ]}
            />
          </StyledTooltip>
        )}
      </Grid>

      <StyledGrid container pl={8} alignItems="center">
        {badge}
        <Text variant="body2" ml={8} isEllipsis title={summary}>
          {summary}
        </Text>
      </StyledGrid>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isRead' && prop !== 'isHovered'
})<{ isRead: boolean; isHovered: boolean }>(
  ({ theme, isRead, isHovered }) => css`
    min-height: 72px;
    background-color: ${isRead ? theme.palette.natural.white : theme.palette.secondary.alternate};
    width: 100%;
    border-bottom: 0.5px solid ${theme.palette.natural.border};
    padding: ${theme.spacing(12)};

    ${isHovered &&
    css`
      background-color: ${isRead ? theme.palette.natural.hover : theme.palette.secondary.light};
      cursor: pointer;
    `}
  `
);

const StyledTimestamp = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isRead'
})<{ isRead: boolean }>(
  ({ theme, isRead }) => css`
    white-space: nowrap;
    color: ${isRead ? theme.palette.text.disabled : theme.palette.secondary.main};
  `
);

const StyledTooltip = styled(Tooltip)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
  `
);

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    width: calc(100% - 80px);

    .exclamation-dark {
      color: ${theme.palette.text.primary};
    }

    .exclamation-red {
      color: ${theme.palette.error.dark};
    }

    .circle-badge {
      width: 6px;
      height: 6px;
      min-width: 6px;
      border-radius: 50%;
    }

    .exclamation-red,
    .exclamation-dark {
      svg {
        height: 14px;
      }
    }

    .mild {
      background-color: ${theme.palette.success.main};
    }

    .standard-operator,
    .callback {
      background-color: ${theme.palette.text.primary};
    }

    .high-symptom {
      background-color: ${theme.palette.error.main};
    }

    svg {
      max-height: 20px;
      max-width: 20px;
    }
  `
);
