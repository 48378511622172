import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Open
  | AnalyticEventAction.Delete
  | AnalyticEventAction.Cancel
  | AnalyticEventAction.Save;
type Value = 'save with changes' | 'save without changes' | 'missing fields';

interface Properties {
  action: Action;
  ticket_id: number;
  value?: Value;
}

export const trackEditTicketModalAnalyticsEvent = ({
  action,
  ticket_id,
  ...optionalProperties
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.EditTicketModal,
    properties: { action, ticket_id, ...optionalProperties }
  });
};
