import {
  LightweightPathwayQuestionServerStructure,
  LightweightPathwayDetailsServerStructure
} from 'fetchers/responses/pathways.response';

import {
  PathwayOptionAction,
  PathwayOptionOutcome,
  PathwayQuestionTypes
} from 'models/PathwayTemplates';

import {
  ATTENTION_TODAY,
  IMMEDIATE_ATTENTION
} from 'components/LightweightPathway/LightweightPathway.constants';

export type QuestionActionMeta =
  | SingleQuestionActionMeta
  | MultipleQuestionActionMeta
  | TextQuestionActionMeta
  | DateQuestionActionMeta;

interface BaseQuestionActionMeta {
  optionId?: string;
  questionId: string;
  type: PathwayQuestionTypes;
}

export enum LightweightPathwayQuestionAction {
  RemoveLastOption = 'remove last option',
  AddFirstOption = 'add first option',
  ChangeOption = 'change option',
  RemoveOption = 'remove option',
  AddNewOption = 'add new option',
  AddFirst = 'add first',
  RemoveLast = 'remove last',
  Update = 'update'
}

export interface SingleQuestionActionMeta extends BaseQuestionActionMeta {
  action:
    | LightweightPathwayQuestionAction.RemoveLastOption
    | LightweightPathwayQuestionAction.AddFirstOption
    | LightweightPathwayQuestionAction.ChangeOption;
}

export interface MultipleQuestionActionMeta extends BaseQuestionActionMeta {
  action:
    | LightweightPathwayQuestionAction.RemoveOption
    | LightweightPathwayQuestionAction.AddFirstOption
    | LightweightPathwayQuestionAction.AddNewOption
    | LightweightPathwayQuestionAction.RemoveLastOption;
}

export interface TextQuestionActionMeta extends BaseQuestionActionMeta {
  action:
    | LightweightPathwayQuestionAction.AddFirst
    | LightweightPathwayQuestionAction.RemoveLast
    | LightweightPathwayQuestionAction.Update;
}

export interface DateQuestionActionMeta extends BaseQuestionActionMeta {
  action:
    | LightweightPathwayQuestionAction.AddFirst
    | LightweightPathwayQuestionAction.Update
    | LightweightPathwayQuestionAction.RemoveLast;
}

export type LightweightPathwayQuestionAnswer<Value = PathwayAnswerValue> =
  | LightweightPathwayMainQuestionAnswer<Value>
  | LightweightPathwayDependentQuestionAnswer<Value>;

export interface LightweightPathwayMainQuestionAnswer<Value = PathwayAnswerValue>
  extends LightweightPathwayQuestionServerStructure {
  pathway: LightweightPathwayDetailsServerStructure & { index?: number };
  index?: number;
  deniedOptions?: { id: string; questionId: string; title: string }[];
  value: Value;
}

export interface LightweightPathwayDependentQuestionAnswer<Value = PathwayAnswerValue>
  extends LightweightPathwayDependentQuestionServerStructure {
  pathway: LightweightPathwayDetailsServerStructure & { index?: number };
  parentQuestionId: string;
  index?: number;
  deniedOptions?: { id: string; questionId: string; title: string }[];
  value: Value;
}

export interface LightweightPathwayDependentQuestionServerStructure
  extends Omit<LightweightPathwayQuestionServerStructure, 'dependentQuestions'> {
  dependsOn: string[];
}

export type MultipleValue = (LightweightPathwayQuestionOption & { index: number })[];
export type SingleValue = LightweightPathwayQuestionOption;
export type DateValue = string;
export type TextValue = string;
export type PathwayAnswerValue = MultipleValue | SingleValue | DateValue | TextValue;

export interface LightweightPathwayQuestionOption {
  id: string;
  title: string;
  action?: PathwayOptionAction;
  outcome?: PathwayOptionOutcome;
}

export type LightweightPathwayQuestionAlertText =
  | typeof IMMEDIATE_ATTENTION
  | typeof ATTENTION_TODAY
  | '';

export interface LightweightPathwayQuestionBaseProps<
  Answer = PathwayAnswerValue,
  ActionMeta = QuestionActionMeta
> {
  question:
    | LightweightPathwayQuestionServerStructure
    | LightweightPathwayDependentQuestionServerStructure;
  pathway: LightweightPathwayDetailsServerStructure;
  index: number;
  answer?: LightweightPathwayQuestionAnswer<Answer>;
  handleAnswerChanged: (
    newValue: LightweightPathwayQuestionAnswer<Answer>,
    actionMeta: ActionMeta
  ) => void;
  parentQuestionId?: string;
  pathwayIndex: number;
}

export interface OrderedLightweightPathwayDetails extends LightweightPathwayDetailsServerStructure {
  index: number;
}

export interface LightweightPathwayMainFlatQuestion
  extends LightweightPathwayQuestionServerStructure {
  pathway: OrderedLightweightPathwayDetails;
  dependsOn?: string[];
  index: number;
  dependentQuestions?: LightweightPathwayDependentFlatQuestion[];
}

export interface LightweightPathwayDependentFlatQuestion
  extends LightweightPathwayDependentQuestionServerStructure {
  pathway: LightweightPathwayDetailsServerStructure;
  parentQuestionId: string;
  index: number;
}
