import { FC } from 'react';

import { Box, css, styled, Typography } from '@mui/material';

import { SearchedPatient } from 'fetchers/PatientsFetcher';

import HorizontalLoader from 'views/Widgets/HorizontalLoader';

import Icon from 'components/Icons/Icon';
import { StyledSearchResultsContainer } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.shared';
import { getNewEmptyPatient } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.utils';
import { SearchedPatientRow } from 'components/UIkit/atoms/AdvancedSearchBar/SearchedPatientRow';

import { TextIconButton } from 'components/UIkit/atoms/Button';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  results: SearchedPatient[] | null;
  onPatientClicked: (
    patient: SearchedPatient,
    options?: { isRecentPatient?: boolean; isNewPatient?: boolean; isKeyboardSource?: boolean }
  ) => void;
  activeIndex: number;
  isLoading: boolean;
  isAnimationDisabled: boolean;
  openAdvancedSearch: () => void;
  searchTerm: string;
  hasMoreResults: boolean;
  hasAddNewPatientOption?: boolean;
  isAddNewPatientOptionActive: boolean;
  maxHeight: string;
}

export const PatientsResultsMenu: FC<Props> = ({
  results,
  onPatientClicked,
  activeIndex,
  isLoading,
  isAnimationDisabled,
  openAdvancedSearch,
  searchTerm,
  hasMoreResults,
  hasAddNewPatientOption = false,
  isAddNewPatientOptionActive,
  maxHeight
}) => {
  const onNewPatientClick = () => {
    let patientFirstName = '';
    let patientLastName = '';

    const lastSpaceIndex = searchTerm.lastIndexOf(' ');
    if (lastSpaceIndex === -1) {
      patientFirstName = searchTerm;
    } else {
      patientFirstName = searchTerm.substring(0, lastSpaceIndex);
      patientLastName = searchTerm.substring(lastSpaceIndex + 1, searchTerm.length);
    }

    const newEmptyPatient: SearchedPatient = getNewEmptyPatient(patientFirstName, patientLastName);
    onPatientClicked(newEmptyPatient, { isNewPatient: true });
  };

  const noResults = results?.length === 0;
  const isHintVisible = noResults || (results && results.length > 0);

  const hintText = hasMoreResults
    ? 'Too Many Results to Display, Refine with Advanced Search'
    : 'Refine with Advanced Search';

  return (
    <>
      {isLoading && <HorizontalLoader />}

      <StyledSearchResultsContainer className="header-search-results" maxHeight={maxHeight}>
        {results?.map((patient, index) => (
          <SearchedPatientRow
            key={patient.patientId || patient.emrPatientId}
            patient={patient}
            onPatientClicked={onPatientClicked}
            isActive={index === activeIndex}
            isAnimationDisabled={isAnimationDisabled}
            searchTerm={searchTerm}
          />
        ))}

        {noResults && (
          <Box display="flex" justifyContent="center" py={8}>
            <Text variant="body2" color="disabled">
              No Results
            </Text>
          </Box>
        )}

        {hasAddNewPatientOption && searchTerm && (
          <StyledNewPatientOption
            className="option"
            onClick={onNewPatientClick}
            data-test-hook="add-new-patient-btn"
            isSelected={isAddNewPatientOptionActive}
          >
            <StyledCirclePlusIcon />
            <StyledNewPatientText variant="form-text">{`Add New Patient: "${searchTerm}"`}</StyledNewPatientText>
          </StyledNewPatientOption>
        )}
      </StyledSearchResultsContainer>

      {isHintVisible && (
        <>
          <StyledSeparator />

          <StyledHintContainer>
            <TextIconButton
              icon={<Icon.Settings width={16} />}
              onClick={() => openAdvancedSearch()}
            >
              {hintText}
            </TextIconButton>
          </StyledHintContainer>
        </>
      )}
    </>
  );
};

const StyledNewPatientOption = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    padding: ${theme.spacing(8, 16)};
    background-color: ${isSelected ? theme.palette.natural.hover : theme.palette.natural.white};
    cursor: pointer;

    span {
      color: ${theme.palette.primary.main};
    }

    svg {
      color: ${theme.palette.primary.main};

      path {
        stroke: ${theme.palette.natural.white};
      }
    }

    &:hover {
      background-color: ${theme.palette.natural.hover};
    }
  `
);

const StyledCirclePlusIcon = styled(Icon.CirclePlus)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(8)};
    color: ${theme.palette.primary.main};
  `
);

const StyledNewPatientText = styled(Typography)`
  margin: 0;
`;

const StyledSeparator = styled('hr')`
  width: 100%;
  margin: 0;
`;

const StyledHintContainer = styled(Box)(
  ({ theme }) => css`
    box-shadow: 0 3px 4px 0 #e0e2e4;
    padding: ${theme.spacing(8, 16)};
    display: flex;
    align-items: center;
  `
);
