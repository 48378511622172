import {
  ActivePathwayTemplatesResponse,
  PathwayTemplates
} from 'fetchers/responses/pathways-builder.response';

import { getTodayFormattedDate } from 'utils/DateUtils';
import { pluralize } from 'utils/StringUtils';

import { ACTIVE_PATHWAY_TEMPLATES_LOCAL_STORAGE_KEY } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.constants';

export const persistPathwayTemplatesWithLocalStorage = (pathwayTemplates: PathwayTemplates) => {
  const updatedAt = getTodayFormattedDate('YYYY-MM-DDTHH:mm:ss.sssZ');
  const activePathwayTemplates: ActivePathwayTemplatesResponse = { pathwayTemplates, updatedAt };
  localStorage.setItem(
    ACTIVE_PATHWAY_TEMPLATES_LOCAL_STORAGE_KEY,
    JSON.stringify(activePathwayTemplates)
  );
};

export const getDownloadJsonButtonSubText = (
  changedQuestionIds: string[] | undefined,
  changedPathwayIds: string[] | undefined
) => {
  const hasChangedPathways = changedPathwayIds && changedPathwayIds?.length > 0;
  const hasChangedQuestions = changedQuestionIds && changedQuestionIds?.length > 0;

  let text = '';

  if (hasChangedQuestions) {
    text = `${changedQuestionIds.length} ${pluralize('Question', changedQuestionIds.length)}`;
  }

  if (hasChangedQuestions && hasChangedPathways) {
    text = `${text} •`;
  }

  if (hasChangedPathways) {
    text = `${text} ${changedPathwayIds.length} ${pluralize('Pathway', changedPathwayIds.length)}`;
  }

  return text;
};
