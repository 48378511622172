import { ChangeEvent, FC, FocusEvent } from 'react';

import { isEmpty } from 'lodash/fp';

import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { LightweightPathwayQuestionServerStructure } from 'fetchers/responses/pathways.response';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import StyledInput from 'views/Widgets/StyledInput';

import {
  LightweightPathwayDependentQuestionServerStructure,
  LightweightPathwayQuestionAction,
  LightweightPathwayQuestionAnswer,
  LightweightPathwayQuestionBaseProps,
  TextQuestionActionMeta,
  TextValue
} from 'components/LightweightPathway/LightweightPathway.types';

interface Props extends LightweightPathwayQuestionBaseProps<TextValue, TextQuestionActionMeta> {
  handleTextQuestionBlur?: (
    event: FocusEvent<HTMLInputElement>,
    currentValue: string,
    valueAfterFocus: string,
    question:
      | LightweightPathwayQuestionServerStructure
      | LightweightPathwayDependentQuestionServerStructure
  ) => void;
}

export const TextQuestion: FC<Props> = ({
  question,
  pathway,
  index,
  answer,
  handleAnswerChanged,
  handleTextQuestionBlur,
  parentQuestionId,
  pathwayIndex
}) => {
  const onChange = (
    event: ChangeEvent<HTMLInputElement>,
    question: LightweightPathwayQuestionServerStructure
  ) => {
    let newAnswer = { ...(answer || {}) } as LightweightPathwayQuestionAnswer<TextValue>;

    let actionMeta: TextQuestionActionMeta = {
      action: LightweightPathwayQuestionAction.AddFirst,
      questionId: question.id,
      type: PathwayQuestionTypes.TEXT
    };

    if (!isEmpty(newAnswer)) {
      newAnswer.value = event.target.value;
      actionMeta = {
        ...actionMeta,
        action: newAnswer.value
          ? LightweightPathwayQuestionAction.Update
          : LightweightPathwayQuestionAction.RemoveLast
      };
    } else {
      newAnswer = {
        ...question,
        pathway: { ...pathway, index: pathwayIndex },
        parentQuestionId,
        index,
        value: event.target.value
      };
    }

    handleAnswerChanged(newAnswer, actionMeta);
  };

  return (
    <StyledInput
      type="textarea"
      placeholder="Type response here..."
      onChange={(event) => onChange(event, question)}
      value={answer?.value || ''}
      testHook={sharedTestSelectors.lightweightPathwayTextQuestionInput(question.id)}
      onBlur={(event, currentValue, valueAfterFocus) =>
        handleTextQuestionBlur &&
        handleTextQuestionBlur(event, currentValue, valueAfterFocus, question)
      }
    />
  );
};
