import { FC } from 'react';

import {
  CareManagementMultiAutocompleteProps,
  MultiAutocomplete
} from 'components/UIkit/atoms/Dropdown/Select/Autocomplete/Autocomplete';
import { CheckboxesMultiAutocomplete } from 'components/UIkit/atoms/Dropdown/Select/Autocomplete/CheckboxesMultiAutocomplete';
import { CustomSelectCheckboxOption } from 'components/UIkit/atoms/Dropdown/Select/CustomOptions/CustomSelectCheckboxOption';
import { CmFilterClearIndicator } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/CmFilterMultiAutocomplete/CmFilterClearIndicator';
import { CmFilterMultiValueRemove } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/CmFilterMultiAutocomplete/CmFilterMultiValueRemove';
import { CmFilterOption } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/CmFilterMultiAutocomplete/CmFilterOption';
import { DefaultMenuOption } from 'components/UIkit/atoms/Dropdown/Select/ReactSelectOptionComponent';
import { SelectComponents } from 'components/UIkit/atoms/Dropdown/Select.shared';

export const CmFilterMultiAutocomplete: FC<CareManagementMultiAutocompleteProps> = (props) => {
  const OptionComponent = props.isCheckboxesMultiAutocomplete
    ? CustomSelectCheckboxOption
    : DefaultMenuOption;
  const MultiAutocompleteComponent = props.isCheckboxesMultiAutocomplete
    ? CheckboxesMultiAutocomplete
    : MultiAutocomplete;

  let components: SelectComponents = {
    Option: OptionComponent
  };

  if (props.selectedTableRowsCount > 0) {
    components = {
      MultiValueRemove: (filterMultiValueRemoveProps) => (
        <CmFilterMultiValueRemove
          {...filterMultiValueRemoveProps}
          selectedTableRowsCount={props.selectedTableRowsCount}
        />
      ),
      ClearIndicator: (clearIndicatorProps) => (
        <CmFilterClearIndicator
          {...clearIndicatorProps}
          selectedTableRowsCount={props.selectedTableRowsCount}
        />
      ),
      Option: (filterOptionProps) => (
        <CmFilterOption
          {...filterOptionProps}
          selectedTableRowsCount={props.selectedTableRowsCount}
          component={OptionComponent}
        />
      )
    };
  }

  return <MultiAutocompleteComponent {...props} components={components} />;
};
