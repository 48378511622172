import { ComponentType, useMemo } from 'react';

import classNames from 'classnames';
import {
  HeaderGroup,
  useFlexLayout,
  useRowState,
  useSortBy,
  useTable,
  useExpanded,
  Row
} from 'react-table';

import { UrlSortParams } from 'utils/urlUtils';

import { BaseTableOptions, SortOptions } from './Table.shared';
import TableBodyContainer from './TableBodyContainer';
import TableCell from './TableCell';
import TableContent from './TableContent';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

interface TableOptions<D extends object = NonNullable<unknown>> extends BaseTableOptions<D> {
  itemSize?: number;
  listClasses?: string;
  disableRowRule?: (original: D) => boolean;
  dashedRowRule?: (original: D) => boolean;
  onManualSort?: (column: HeaderGroup<any>, sortParams: UrlSortParams) => void;
  RowEditComponent?: ComponentType<{ row: Row<D> }>;
  editedRowId?: string | null;
  getRowId?: (originalRow: D, relativeIndex: number, parent?: Row<D>) => string;
}

export const Table = <D extends NonNullable<unknown>>({
  columns,
  rowData,
  onManualSort,
  rowAction,
  rowLink,
  disableRowRule,
  dashedRowRule,
  hideHeaders,
  emptyTableView,
  RowEditComponent,
  editedRowId,
  getRowId
}: TableOptions<D>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns,
      data: rowData,
      initialState: {
        // @ts-ignore
        // this will be ignored if manualSort is true
        sortBy: useMemo(
          () =>
            columns
              .filter((column) => column.defaultSort)
              .map((column) => ({
                id: column.accessor,
                desc: column.defaultSort === SortOptions.desc
              })),
          [columns]
        )
      },
      autoResetSortBy: false,
      ...(getRowId && { getRowId })
    },
    useSortBy,
    useFlexLayout,
    useRowState,
    useExpanded
  );

  const content = useMemo(
    () =>
      rows.map((row) => {
        prepareRow(row);

        const rowContent = (
          <>
            {row.cells.map((cell) => (
              <TableCell key={cell.column.id} {...cell} />
            ))}
          </>
        );

        const rowClassNames = classNames({
          dashed: dashedRowRule ? dashedRowRule(row.original) : false,
          disable: disableRowRule ? disableRowRule(row.original) : false,
          'cursor-pointer':
            (!!rowAction || !!rowLink) && (!disableRowRule || !disableRowRule(row.original))
        });

        const isEditMode = editedRowId === row.id;

        return (
          <TableRow
            key={row.id}
            row={row}
            rowLink={rowLink}
            rowAction={rowAction}
            rowClasses={rowClassNames}
            rowContent={rowContent}
            isEditMode={isEditMode}
            RowEditComponent={RowEditComponent}
          />
        );
      }),
    [
      prepareRow,
      rowLink,
      rowAction,
      rows,
      disableRowRule,
      dashedRowRule,
      RowEditComponent,
      editedRowId
    ]
  );

  return (
    <TableContent {...getTableProps()}>
      <div>
        {!hideHeaders &&
          headerGroups.map((headerGroup) => {
            const { key, ...restHeaderProps } = headerGroup.getHeaderGroupProps();
            return (
              <div key={key} {...restHeaderProps}>
                {headerGroup.headers.map((column) => {
                  return (
                    column.isVisible && (
                      <TableHeader
                        key={column.id}
                        column={column}
                        style={{
                          flex: `${column.totalWidth || 1} 0 auto`,
                          width: column.totalWidth || 1
                        }}
                        manualSort={Boolean(onManualSort)}
                        onManualSort={onManualSort}
                      />
                    )
                  );
                })}
              </div>
            );
          })}
      </div>
      <div {...getTableBodyProps()}>
        <TableBodyContainer
          content={content}
          hasData={Boolean(rows.length)}
          emptyTableView={emptyTableView}
        />
      </div>
    </TableContent>
  );
};
