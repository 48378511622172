import React, { FC, useEffect, useState } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { FeatureIntroCodes } from 'models/Doctor';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { Text } from 'components/UIkit/atoms/Text';

const BaseFeatureIntroPopup: FC = () => {
  const { userStore, uiStore } = useStores();
  const [isOpen, setIsOpen] = useState(
    !userStore.currentDoctor?.hasFeature(FeatureIntroCodes.CallLoggerSmartSummary)
  );

  useEffect(
    function showFeatureIntro() {
      if (isOpen) {
        uiStore.setIntro(true);
      }
    },
    [isOpen, uiStore]
  );

  const onCancelClicked = () => {
    uiStore.setIntro(false);
    setIsOpen(false);
    userStore.updateIntroFinished(FeatureIntroCodes.CallLoggerSmartSummary);
  };

  return (
    <MessageDialog
      id={Dialogs.CallLoggerSmartSummary}
      isOpen={isOpen}
      title="Introducing: Smart Summary"
      handleClose={() => setIsOpen(false)}
      primaryActionProps={{ text: 'Got It', onClick: onCancelClicked }}
    >
      <Text variant="form-text">
        Smart Summary is designed to simplify and improve your documentation when using Pathways.
        <br />
        <br />
        When you finish a call, it will generate a summary of Pathway responses that can be easily
        reviewed by healthcare providers, decreasing the need for manual documentation.
        <br />
        <br />
        It is important to note: Smart Summary utilizes cutting-edge technology, and it is therefore
        important to review the summary before submission, to ensure accuracy.
      </Text>
    </MessageDialog>
  );
};

export const FeatureIntroPopup = observer(BaseFeatureIntroPopup);
