// @ts-strict-ignore
import { NationalNumber, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';

import { PhoneType, PhoneTypeStrings } from 'models/PhoneNumberDetails';

export const phoneTypeSupportsSMS = (phoneNumType: PhoneTypeStrings): boolean =>
  phoneNumType === PhoneType.mobile || phoneNumType === PhoneType.voip;

interface PhoneTypeOption {
  value: PhoneType;
  label: string;
}
export const phoneTypeOptions: PhoneTypeOption[] = [
  {
    label: 'Mobile',
    value: PhoneType.mobile
  },
  {
    label: 'Landline',
    value: PhoneType.landline
  },
  {
    label: 'VoIP',
    value: PhoneType.voip
  }
];

const supportedCountryCodes = ['+1', '+972'];

export const getNumberAndCountryCodeFromPhone = (number: string): [string, NationalNumber] => {
  if (!number || supportedCountryCodes.some((code) => number === code)) {
    return ['+1', null];
  }

  const phoneNumberString = parsePhoneNumberFromString(number);
  return [`+${phoneNumberString.countryCallingCode}`, phoneNumberString.nationalNumber];
};

export const getFormattedPhoneNumber = (phone: string) => {
  if (phone) {
    const phoneNumber: PhoneNumber = parsePhoneNumberFromString(phone);
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
  }
  return '';
};
