import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  PathwayQuestionOption,
  PathwayTemplatesQuestion
} from 'fetchers/responses/pathways-builder.response';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import { PathwayQuestionOption as PathwayQuestionOptionComponent } from 'views/Pages/PathwayBuilder/shared/PathwayQuestionOption';

import {
  StyledInputPresentation,
  StyledOptionItemContainer,
  StyledTextareaPresentation
} from 'views/Pages/PathwayBuilder/shared/styled';

import Icon from 'components/Icons/Icon';

import { TableCellParams } from 'components/Table';

import { Text } from 'components/UIkit/atoms/Text';

export const QuestionTitleCell = (cellParams: TableCellParams<PathwayTemplatesQuestion>) => {
  const { value: title, row } = cellParams;
  const { options } = row.original;

  const getQuestionExpandedContentByType = (question: PathwayTemplatesQuestion) => {
    switch (question.type) {
      case PathwayQuestionTypes.TEXT_ARRAY:
        return null;
      case PathwayQuestionTypes.TEXT:
        return (
          <StyledContainer>
            <StyledTextareaPresentation>
              <Text color="disabled" variant="form-text">
                Type response here...
              </Text>
            </StyledTextareaPresentation>
          </StyledContainer>
        );
      case PathwayQuestionTypes.SINGLE:
      case PathwayQuestionTypes.MULTIPLE:
        return (
          <>
            {options?.map((option: PathwayQuestionOption) => (
              <StyledOptionItemContainer key={option.id}>
                <PathwayQuestionOptionComponent
                  title={option.title}
                  type={
                    question.type as PathwayQuestionTypes.SINGLE | PathwayQuestionTypes.MULTIPLE
                  }
                />
              </StyledOptionItemContainer>
            ))}
          </>
        );
      case PathwayQuestionTypes.DATE:
        return (
          <StyledContainer>
            <StyledInputPresentation>
              <Text color="disabled" variant="form-text">
                dd/mm/yyyy
              </Text>

              <Icon.Calendar />
            </StyledInputPresentation>
          </StyledContainer>
        );
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Text variant="form-text" isEllipsis>
        {title}
      </Text>
      {row.isExpanded && <Box mt={20}>{getQuestionExpandedContentByType(row.original)}</Box>}
    </Box>
  );
};

const StyledContainer = styled(Box)`
  max-width: 250px;
`;
