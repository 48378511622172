import { FC } from 'react';

import { BaseSelect } from 'components/UIkit/atoms/Dropdown/BaseSelect';
import { InlineLabelComponents } from 'components/UIkit/atoms/Dropdown/Select/ReactSelectComponents';
import {
  BaseSelectProps,
  ReactSelectInternalProps
} from 'components/UIkit/atoms/Dropdown/Select.shared';

import { FormProps } from 'components/UIkit/atoms/Dropdown/withForm.shared';
import { withFormSelect } from 'components/UIkit/atoms/Dropdown/withFormSelect';

// === standard (e.i. Not form) components ===//
export const Select: FC<BaseSelectProps> = (props) => (
  <BaseSelect isSearchable={false} {...props} />
);
// === form components ===//
// since react-form-hook is setting the value, it can be omitted
interface FormBaseSelectProps extends Omit<BaseSelectProps, 'value'>, FormProps {}
const FormBaseSelect = withFormSelect<FormBaseSelectProps & ReactSelectInternalProps>(BaseSelect);

export const FormSelect: FC<FormBaseSelectProps> = (props) => (
  <FormBaseSelect isSearchable={false} {...props} />
);

// === to be removed after ticket type re-design ===//
export const FormSelectInlineLabel: FC<FormBaseSelectProps> = (props) => (
  <FormBaseSelect isSearchable={false} {...props} components={InlineLabelComponents} />
);
