import { FC } from 'react';

import { observer } from 'mobx-react';

import { useFormContext } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { taskFieldName } from 'views/Modals/EditTaskModal';

import { FormAutocompleteInlineLabel } from 'components/UIkit/atoms/Dropdown';

interface IProps {
  fieldName: string;
  validate?: (value: any) => boolean;
  onChange?: (value: any) => void;
}

const TicketInfoFormRole: FC<IProps> = ({ fieldName, validate, onChange }) => {
  const { constantsStore } = useStores();
  const { watch } = useFormContext();
  const role = watch(`${taskFieldName}.role`);

  return (
    <FormAutocompleteInlineLabel
      label="Role"
      sortAlphabetically={false}
      name={fieldName}
      onChange={onChange}
      options={constantsStore.rolesForSelect}
      validate={validate}
      isClearable={Boolean(role?.value)}
    />
  );
};

export default observer(TicketInfoFormRole);
