// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { textWithLineBreaks } from 'utils/StringUtils';

import SanitizeHtml from 'views/Widgets/SanitizeHtml';

import Chip from 'components/Chips/Chip';
import { getEpisodeColorIndex } from 'components/Ticket/TicketRow/shared';
import TicketRenderer, { TicketRendererClasses } from 'components/Ticket/TicketRow/TicketRenderer';
import TicketRowIcon, { TicketRowIconType } from 'components/Ticket/TicketRow/TicketRowIcon';

import TaskTemplateActions from './TaskTemplateActions';

interface Props {
  taskTemplateId: number;
  onEdit: (id: number) => void;
}

const TaskTemplateRow: FC<Props> = ({ taskTemplateId, onEdit }) => {
  const { episodeManagementStore, constantsStore } = useStores();
  const { taskTemplatesMap, currentFilteredEpisodesMap } = episodeManagementStore;
  const taskTemplate = taskTemplatesMap.get(taskTemplateId) || null;
  const { name, description, episodeId, roleId, monthNumber } = taskTemplate || {};
  const episode = currentFilteredEpisodesMap.get(episodeId) || null;
  const role = constantsStore.getRoleByIdWithAllAndNoRoles(roleId);

  if (!taskTemplate) {
    return null;
  }

  const summary = (
    <div>
      <div className="title-text">{name}</div>
      <Chip.List>
        {episode && (
          <Chip.Item borderless bold color={getEpisodeColorIndex(episode)}>
            {episode.name}
          </Chip.Item>
        )}
        {role && <Chip.Item>For: {role.name}</Chip.Item>}
      </Chip.List>
      <div>{description && <SanitizeHtml html={textWithLineBreaks(description)} />}</div>
    </div>
  );

  const classes: TicketRendererClasses = {
    container: 'task-template-row',
    patientDetails: 'task-template-details'
  };

  return (
    <TicketRenderer
      icon={
        <TicketRowIcon header="MONTH" type={TicketRowIconType.TASK}>
          {monthNumber}
        </TicketRowIcon>
      }
      classes={classes}
      actions={<TaskTemplateActions taskTemplateId={taskTemplateId} onEdit={onEdit} />}
      summary={summary}
    />
  );
};

export default observer(TaskTemplateRow);
