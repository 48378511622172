import { FC, HTMLAttributes } from 'react';

import classNames from 'classnames';

import './StyledContainer.scss';

const StyledContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
  <div {...props} className={classNames(className, 'styled-container')}>
    {children}
  </div>
);

export default StyledContainer;
