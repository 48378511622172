// @ts-strict-ignore
import { FC, useMemo } from 'react';

import classNames from 'classnames';
import { Col, Row } from 'reactstrap';

import { useStores } from 'mobx/hooks/useStores';

import { dayAndHourTimeFormat, formatDate } from 'utils/DateUtils';

import { QUICK_ADDITION_MAX_DURATION, QuickAddition } from 'models/QuickAddition';
import { Reason } from 'models/Reason';

import { SelectOption } from 'models/SelectOption';

import AddCircleOutline from 'views/Widgets/icons/AddCircleOutline';
import { RemoveCircleIcon } from 'views/Widgets/icons/RemoveCircle';

import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';

import { Autocomplete } from 'components/UIkit/atoms/Dropdown';

import { useIntervalContext } from './IntervalsContext';
import { refreshCurrent } from './IntervalsEditor.shared';

const QUICK_ADDITION_DURATION_STEP = 5;

interface Props {
  quickAddition: QuickAddition;
  isNew: boolean;
  handleReasonChanged: (newReasonId: number, quickAddition: QuickAddition) => void;
}

export const QuickAdditionsEditorRow: FC<Props> = ({
  quickAddition,
  isNew,
  handleReasonChanged
}) => {
  const { reasonsStore, userStore } = useStores();
  const { quickAdditionsCtx, quickAdditionIdsWithoutReason, setQuickAdditionEditedReason } =
    useIntervalContext();
  const reduceDisabled = quickAddition.duration === 0;
  const increaseDisabled = quickAddition.duration === QUICK_ADDITION_MAX_DURATION;
  const reasons = reasonsStore.getReasonsForQuickAddition(quickAddition);
  const reasonOptions = useMemo(
    () =>
      reasons.map((reason) => ({
        value: reason.id,
        label: reason.title,
        isEditable: reason.isEditable && userStore.isManager
      })),
    [reasons, userStore.isManager]
  );
  const currentReasonOption = reasonOptions.find(
    (option) => option.value === quickAddition.reason?.id
  );

  const increaseDuration = () => {
    if (quickAddition.duration < QUICK_ADDITION_MAX_DURATION) {
      quickAddition.duration += QUICK_ADDITION_DURATION_STEP;
      quickAdditionsCtx.setCurrent([...quickAdditionsCtx.current]);
    }
  };

  const reduceDuration = () => {
    if (quickAddition.duration > 0) {
      quickAddition.duration -= QUICK_ADDITION_DURATION_STEP;
      refreshCurrent(quickAdditionsCtx);
    }
  };

  const handleQuickAdditionRemoved = () => {
    quickAdditionsCtx.setCurrent(
      quickAdditionsCtx.current.filter(
        (currentAddition) => currentAddition.uniqueIdentifier !== quickAddition.uniqueIdentifier
      )
    );
  };

  const handleEditReasonClicked = (reason: Reason) => {
    setQuickAdditionEditedReason(reason);
  };

  const onEditClick = (reasonOption: SelectOption<number>) => {
    const reason = reasons.find((reason) => reason.id === reasonOption.value);
    handleEditReasonClicked(reason);
  };

  return (
    <Row
      className={classNames('entry-editor-row', {
        'is-new': isNew
      })}
      key={quickAddition.uniqueIdentifier}
    >
      <Col lg={3} className="d-flex justify-content-center align-items-center">
        <IconButton className="addition-btn" disabled={reduceDisabled} onClick={reduceDuration}>
          <RemoveCircleIcon disabled={reduceDisabled} />
        </IconButton>
        <b className="quick-addition-time">+ {quickAddition.duration} Min</b>
        <IconButton disabled={increaseDisabled} onClick={increaseDuration} className="addition-btn">
          <AddCircleOutline disabled={increaseDisabled} />
        </IconButton>
      </Col>
      <Col lg={3} className="addition-duration">
        {quickAddition.loggedTime ? formatDate(quickAddition.loggedTime, dayAndHourTimeFormat) : ''}
      </Col>
      <Col lg={5} className="addition-reason">
        <Autocomplete
          value={currentReasonOption}
          options={reasonOptions}
          onEditClick={onEditClick}
          addText={userStore.isManager && 'Reason'}
          onChange={(option: SelectOption<number>) =>
            handleReasonChanged(option.value, quickAddition)
          }
          placeholder="Select Reason"
          isError={quickAdditionIdsWithoutReason.includes(quickAddition.uniqueIdentifier)}
          isClearable={false}
          isNarrow
        />
      </Col>
      <Col lg={1} className="remove-interval-btn">
        <IconButton onClick={handleQuickAdditionRemoved}>
          <Icon.XPurple />
        </IconButton>
      </Col>
    </Row>
  );
};
