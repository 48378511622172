// @ts-strict-ignore
import { useState } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';

import { inject, observer } from 'mobx-react';

import useSet from 'mobx/customHooks/useSet';
import { PathwayStore } from 'mobx/stores';

import { isSubStringCaseInsensitive } from 'utils/StringUtils';

import {
  PathwayQuestionCategory,
  PathwayTemplate,
  PathwayTemplateQuestion
} from 'models/PathwayTemplates';

import SearchBar from 'views/Dashboard/SearchBar';
import ExpandableRow from 'views/Widgets/ExpandableRow';

import './PathwayTableOfContentView.scss';

export interface IPathwayTableOfContentViewProps {
  pathway: PathwayTemplate;
  pathwaysStore?: PathwayStore;
  onQuestionClicked: (questionId: string) => void;
  filterKeyQuestions: boolean;
}

const CHARS_LENGTH_TO_START_FILTER = 3;

export default inject('pathwaysStore')(
  observer((props: IPathwayTableOfContentViewProps) => {
    const [searchValue, setSearchValue] = useState('');
    const [expandedRowsSet, setUtils] = useSet(
      new Set(props.pathway.categories.map((category, i) => i))
    );
    const highlightedQuestionId = props.pathwaysStore.highlightedQuestionId;
    function handleRowExpanded(i: number) {
      setUtils.toggle(i);
    }

    const getFinalQuestionsList = function (category: PathwayQuestionCategory) {
      let questions = props.filterKeyQuestions
        ? category.questions.filter((question) => question.isKey)
        : category.questions;
      // filter out questions dependant on other questions and questions filtered out by search
      questions = questions.filter(
        (question: PathwayTemplateQuestion) =>
          !question.dependsOn &&
          (searchValue.length < CHARS_LENGTH_TO_START_FILTER ||
            isSubStringCaseInsensitive(
              props.pathwaysStore.getBaseQuestionById(question.id).title,
              searchValue
            ))
      );
      return questions;
    };
    const isQuestionHighlighted = (question: PathwayTemplateQuestion) => {
      return question.id === highlightedQuestionId;
    };
    return (
      <div className="pathway-table-of-content">
        <SearchBar
          className="pathway-content-search"
          searchValue={searchValue}
          placeholder="Search Question Index"
          onSearchChanged={(searchTerm) => setSearchValue(searchTerm)}
        />
        {props.pathwaysStore
          .activeCategoriesForPathway(props.pathway)
          .map((category: PathwayQuestionCategory, i) => {
            const questions = getFinalQuestionsList(category);
            return (
              questions &&
              questions.length > 0 && (
                <div key={category.id} className="category-content">
                  <ExpandableRow
                    isExpanded={expandedRowsSet.has(i)}
                    onToggleExpand={() => {
                      trackSectionUsageAnalyticsEvent({
                        action: expandedRowsSet.has(i)
                          ? AnalyticEventAction.Collapse
                          : AnalyticEventAction.Expand,
                        value: props.pathway.categories[i].title,
                        virtual_page: 'call logger',
                        tab: props.filterKeyQuestions ? 'Key Questions' : 'All Questions'
                      });

                      handleRowExpanded(i);
                    }}
                    title={category.title}
                  >
                    <div className="questions-container">
                      {questions.map((question: PathwayTemplateQuestion) => {
                        return (
                          <p
                            className={`question ${
                              isQuestionHighlighted(question) ? 'bold active' : ''
                            }`}
                            key={question.id}
                            onClick={() => props.onQuestionClicked(question.id)}
                          >
                            {props.pathwaysStore.pathwaysData.questions[question.id].title}
                          </p>
                        );
                      })}
                    </div>
                  </ExpandableRow>
                </div>
              )
            );
          })}
      </div>
    );
  })
);
