import { FC } from 'react';

import sanitizeHtml from 'sanitize-html';

interface Props {
  html: string;
  className?: string;
  wrapperTag?: keyof JSX.IntrinsicElements;
}

const defaultOptions = {
  allowedTags: ['br', 'b', 'i', 'em', 'strong', 'ul', 'li', 'ol', 'p', 'span']
};

export function removeHtmlTags(input: string) {
  const pattern = new RegExp(`<\\/?(?:${defaultOptions.allowedTags.join('|')})\\b[^>]*>`, 'gi');
  return input.replace(pattern, '');
}

const sanitize = (dirty: string) => ({
  __html: sanitizeHtml(dirty, defaultOptions)
});

const SanitizeHtml: FC<Props> = ({ html, className, wrapperTag = 'div' }) => {
  const HtmlTag = wrapperTag;
  return <HtmlTag className={className} dangerouslySetInnerHTML={sanitize(html)} />;
};

export default SanitizeHtml;
