import { FC } from 'react';

import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes } from 'lexical';
import { useMount } from 'react-use';

interface Props {
  value?: string;
}

export const ImportFromHtmlPlugin: FC<Props> = ({ value }) => {
  const [editor] = useLexicalComposerContext();

  useMount(function importFromHtmlString() {
    if (value) {
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(value, 'text/html');
        const nodes = $generateNodesFromDOM(editor, dom);

        $insertNodes(nodes);
        requestAnimationFrame(() => {
          editor.blur();
        });
      });
    }
  });
  return null;
};
