// @ts-strict-ignore

import { FC } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { pluralize } from 'utils/StringUtils';

import { removeUrlParam } from 'utils/urlUtils';

import { FEATURES } from 'constants/features';

import PatientEpisode from 'models/PatientEpisode';

import { useFeature } from 'hooks/useFeature';
import { useToggle } from 'hooks/useToggle';

import { CareManagementEnrollmentProvider } from 'views/Pages/CareManagement/CareManagementEnrollment/CareManagementEnrollmentProvider';
import { CareManagementRow } from 'views/Pages/CareManagement/CareManagementRow';
import { useManageEpisodesContext } from 'views/Pages/EpisodesManagement/episode-context';
import { PatientEpisodeRow } from 'views/Pages/EpisodesManagement/PatientEpisodeRow';
import { HIGHLIGHT_TICKET_QUERY_PARAM } from 'views/Patient/PatientMain/PatientMainView.constants';
import CardStack from 'views/Widgets/CardStack';
import CollapsibleSection from 'views/Widgets/CollapsibleSection';

import Icon from 'components/Icons/Icon';
import { CardRow } from 'components/Surfaces/Card';
import { OutlinedButton, OutlinedIconButton, TextButton } from 'components/UIkit/atoms/Button';

import { useEpisodes } from './use-episodes.hooks';

function getTogglerText(isExpanded: boolean, numOfEpisodes: number) {
  const episodesExist = numOfEpisodes > 0;
  const episodesValue = episodesExist ? `, ${numOfEpisodes}` : '';
  const episodesText = episodesExist ? ` ${pluralize('episode', numOfEpisodes)}` : '';
  return `${isExpanded ? 'hide' : 'show'} details${episodesValue}${episodesText}`;
}

interface Props {
  cmEpisodes: PatientEpisode[];
}

export const CmEpisodes: FC<Props> = ({ cmEpisodes }) => {
  const { isOpen: isExpanded, toggle } = useToggle(false);

  return (
    <CardStack isOpen={isExpanded} numberOfStackedItems={cmEpisodes.length}>
      <CareManagementRow isExpanded={isExpanded}>
        <TextButton
          onClick={(event) => {
            event.stopPropagation();
            toggle();
          }}
          variant="secondary"
          mt={4}
        >
          {getTogglerText(isExpanded, cmEpisodes.length)}
        </TextButton>
      </CareManagementRow>

      {cmEpisodes.map((episode) => (
        <PatientEpisodeRow
          key={episode.id}
          episode={episode}
          action={<PatientEpisodeActions episode={episode} />}
        />
      ))}
    </CardStack>
  );
};

export const EpisodesSection: FC<{ patientId: number; bottomSpace: boolean }> = ({
  patientId,
  bottomSpace
}) => {
  const { patientEpisodesStore } = useStores();
  const history = useHistory();
  const location = useLocation();
  const { openEnrollEpisode } = useManageEpisodesContext();
  const { cmEpisodes, nonCmEpisodes } = useEpisodes(patientId);
  const hasCareManagement = useFeature(FEATURES.CARE_MANAGEMENT);

  const showCmEpisodes = cmEpisodes.length > 0 && hasCareManagement;

  const onUpdateStatus = () => {
    patientEpisodesStore.handleUpdateCreateEpisode(patientId);
  };

  const classes = classNames('episodes-list-section', {
    'episodes-list-bottom-space': bottomSpace
  });

  return (
    <div className={classes}>
      <CollapsibleSection
        name="Episodes"
        trigger={<div className="section-title">Episodes</div>}
        trailing={
          <div className="episodes-tasks-management">
            <OutlinedIconButton
              icon={<Icon.Plus />}
              onClick={() => {
                removeUrlParam(history, location.search, HIGHLIGHT_TICKET_QUERY_PARAM);
                openEnrollEpisode();
              }}
              variant="secondary"
            >
              Enroll in Episode
            </OutlinedIconButton>
          </div>
        }
      >
        <div className="tickets-list">
          {showCmEpisodes && (
            <CareManagementEnrollmentProvider onUpdateStatus={onUpdateStatus}>
              <CmEpisodes cmEpisodes={cmEpisodes} />
            </CareManagementEnrollmentProvider>
          )}
          {nonCmEpisodes.map((episode: PatientEpisode) => (
            <CardRow key={episode.id}>
              <PatientEpisodeRow
                episode={episode}
                action={<PatientEpisodeActions episode={episode} />}
              />
            </CardRow>
          ))}
        </div>
      </CollapsibleSection>
    </div>
  );
};

const PatientEpisodeActions = ({ episode }: { episode: PatientEpisode }) => {
  const { editEpisode, endEpisode, openEditPatientEpisodeModal, openEndPatientEpisodeModal } =
    useManageEpisodesContext();
  const history = useHistory();
  const location = useLocation();

  const onEditClick = (episode: PatientEpisode) => {
    trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.EditEpisode });
    editEpisode(episode);
    openEditPatientEpisodeModal();
    removeUrlParam(history, location.search, HIGHLIGHT_TICKET_QUERY_PARAM);
  };

  const onEndClick = (episode: PatientEpisode) => {
    trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.EndEpisode });
    endEpisode(episode);
    openEndPatientEpisodeModal();
    removeUrlParam(history, location.search, HIGHLIGHT_TICKET_QUERY_PARAM);
  };

  return (
    <>
      <OutlinedButton
        onClick={() => onEndClick(episode)}
        testHook="patientEpisodeActions"
        mr={12}
        size="medium"
        display="inline-block"
      >
        End
      </OutlinedButton>

      <OutlinedButton
        onClick={() => onEditClick(episode)}
        testHook="patientEpisodeActions"
        size="medium"
        display="inline-block"
      >
        Edit
      </OutlinedButton>
    </>
  );
};
