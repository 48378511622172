import { FC } from 'react';

import { css } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
  text?: string;
}

export const Placeholder: FC<Props> = ({ text }) => {
  return <StyledPlaceholder>{text}</StyledPlaceholder>;
};

const StyledPlaceholder = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
    position: absolute;
    top: ${theme.spacing(8)};
    left: ${theme.spacing(12)};
    display: flex;
    align-items: center;
    pointer-events: none;
  `
);
