import { MissingEntity } from 'errors';

import { useStores } from 'mobx/hooks/useStores';

import { handleError } from 'services/errorHandlingService';

import { FEATURES } from 'constants/features';

import { CmEpisodeTypes } from 'models/Episode';

import { useFeature } from 'hooks/useFeature';

export function useEpisodes(patientId: number) {
  const { patientEpisodesStore, constantsStore } = useStores();
  const patientEpisodes = patientEpisodesStore.episodesForPatient(patientId);
  const hasCareManagement = useFeature(FEATURES.CARE_MANAGEMENT);

  const isCmEpisode = (episodeId: number) => {
    const episode = constantsStore.getEpisodeById(episodeId);

    if (!episode) {
      handleError(new MissingEntity(`episode with id ${episodeId} doesn't exist`));
      return false;
    }

    return episode.type === CmEpisodeTypes.ccm || episode.type === CmEpisodeTypes.pcm;
  };

  const cmEpisodes = patientEpisodes.filter((patientEpisode) =>
    isCmEpisode(patientEpisode.episodeId)
  );
  const nonCmEpisodes = patientEpisodes.filter(
    (patientEpisode) => !isCmEpisode(patientEpisode.episodeId)
  );
  const hasEpisodes = hasCareManagement ? patientEpisodes.length > 0 : nonCmEpisodes.length > 0;

  return {
    cmEpisodes,
    nonCmEpisodes,
    hasEpisodes
  };
}
