import { FC } from 'react';

import { DropdownInputName } from 'analytics/events/input-usage';
import { useFormContext } from 'react-hook-form';

import { ActionMeta } from 'react-select';

import { useStores } from 'mobx/hooks/useStores';

import PatientLocation from 'models/PatientLocation';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';

interface Props {
  disable?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  name?: string;
  label?: string;
  trackAnalyticsEvent?: (
    actionMeta: ActionMeta<any>,
    inputName: DropdownInputName,
    isKeyboardSource: boolean,
    hasPreviousValue: boolean
  ) => void;
}

export const PatientLocationAutocomplete: FC<Props> = ({
  disable,
  isRequired,
  placeholder,
  label = 'Primary Location',
  name = 'location',
  trackAnalyticsEvent
}) => {
  const { locationsStore } = useStores();
  const { watch } = useFormContext();
  const location = watch(name);

  return (
    <FormAutocomplete
      label={label}
      options={locationsStore.visibleLocations.map((location: PatientLocation) => ({
        value: location,
        label: location.name
      }))}
      name={name}
      isRequired={isRequired}
      isDisabled={disable || locationsStore.visibleLocations.length === 0}
      placeholder={placeholder}
      getOptionValue={(option: any) => option.value.id}
      onChange={(value, actionMeta, eventKey) =>
        trackAnalyticsEvent &&
        trackAnalyticsEvent(actionMeta, 'Primary Location', eventKey === 'Enter', Boolean(location))
      }
    />
  );
};
