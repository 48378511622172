import { useStores } from 'mobx/hooks/useStores';

import { usePatientContext } from 'components/Patient/PatientContextProvider';

export const usePatientModel = (patientId?: number) => {
  const { workQueueStore } = useStores();
  const patientsMap = workQueueStore.patientsMap;

  const patient = usePatientContext();

  if (patient) {
    return patient;
  }

  if (patientId && patientsMap) {
    return workQueueStore.getPatientModel(patientId);
  }
};
