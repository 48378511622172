import HttpService from 'services/HttpService';

import { sanitizeFiltersOrQuery } from 'utils/serverFiltersUtils';

import { API_URLS } from 'constants/apiUrls';

import { TicketsParser } from 'parsers/TicketsParser';

import ClusteredTaskTicket from 'models/ClusteredTaskTicket';
import Ticket, { TicketStatus } from 'models/Ticket';

import { TasksQueryRequestParams } from 'views/Filters/filters.types';

export interface TaskCreationAttrs {
  patientId: number;
  patientEpisodeId?: number;
  name: string;
  dueDate: Date | string;
  description?: string;
  roleId: number;
  assigneeId: number;
}

export interface TaskUpdateAttrs {
  id: number;
  ticketId: number;
  name?: string;
  dueDate?: Date | string;
  description?: string;
  roleId?: number;
  assigneeId?: number;
  delete?: boolean;
  status?: TicketStatus;
}

const httpService = new HttpService('Tasks');

class TasksFetcher {
  static async fetchTasks(
    tasksQueryRequestParams: TasksQueryRequestParams,
    networkLabel?: string | string[]
  ) {
    const data = sanitizeFiltersOrQuery(tasksQueryRequestParams);
    return await httpService.post<Ticket[]>({
      url: API_URLS.TASKS_QUERY,
      data,
      networkLabel,
      transformResponse: TicketsParser.parseTaskTickets
    });
  }

  static async createTask(taskBody: TaskCreationAttrs) {
    return await httpService.post({ url: API_URLS.TASKS, data: taskBody });
  }

  static async updateTask(taskBody: TaskUpdateAttrs) {
    return await httpService.put({
      url: API_URLS.UPDATE_TASK(taskBody.id),
      data: taskBody
    });
  }

  static async reopenTask(taskId: number) {
    return await httpService.post({
      url: API_URLS.REOPEN_TASK(taskId)
    });
  }

  static async rescheduleTasks(taskTicketIds: number[], dueDate: string) {
    return await httpService.put({
      url: API_URLS.RESCHEDULE_TASKS,
      data: {
        taskTicketIds,
        dueDate
      }
    });
  }

  static async updateTasksStatus(taskTicketIds: number[], status: TicketStatus) {
    return await httpService.put({
      url: API_URLS.CHANGE_TASKS_STATUS,
      data: {
        taskTicketIds,
        status
      }
    });
  }

  static async fetchClusteredTasks(
    tasksQueryRequestParams: TasksQueryRequestParams,
    networkLabel?: string
  ) {
    const data = sanitizeFiltersOrQuery(tasksQueryRequestParams);
    return await httpService.post<{
      clusteredTasks: ClusteredTaskTicket[];
      tasks: Ticket[];
    }>({
      url: API_URLS.CLUSTERED_TASKS,
      data,
      networkLabel,
      transformResponse: TicketsParser.parseClusteredResponse
    });
  }
}

export default TasksFetcher;
