export const messageDialogSelectors = {
  submitButton: (containerTestHook?: string) =>
    `${
      containerTestHook ? `${containerTestHook}-primary-button` : 'message-dialog-primary-button'
    }`,
  cancelButton: (containerTestHook?: string) =>
    `${
      containerTestHook
        ? `${containerTestHook}-secondary-button`
        : 'message-dialog-secondary-button'
    }`
};
