// @ts-strict-ignore
import { action, makeObservable, observable } from 'mobx';

import { formatDate, removeTimeZone } from 'utils/DateUtils';

import { TicketClinician } from 'models/Ticket';
export const MAX_TASK_NAME_LENGTH = 255;

export class TaskTicket {
  id: number;
  episodeId: number;
  patientEpisodeId: number;
  name: string;
  description: string;
  owner: TicketClinician;

  @observable
  roleId: number;

  dueDate: Date;
  monthNumber: number;
  notes: string;

  constructor(unparsedTask: any) {
    Object.assign(this, {
      ...unparsedTask,
      // TODO: server returns a date with time (this is only date),
      //  it gets messed up on certain hours.
      //  this should be removed once fixed on the server
      dueDate: new Date(removeTimeZone(unparsedTask.dueDate))
    });
    makeObservable(this);
  }

  get dueDateAsNumber() {
    return this.dueDate.getDate();
  }

  get isNoneEpisode(): boolean {
    return !Boolean(this.episodeId);
  }

  get formattedDueDate() {
    return formatDate(this.dueDate, 'M/D/YYYY');
  }

  @action
  setRole(roleId: number) {
    this.roleId = roleId;
  }
}

export default TaskTicket;
