// @ts-strict-ignore
import { FC, useState } from 'react';

import { shortenedFullName } from 'utils/ClinicianCredentialUtils';
import { fromNow } from 'utils/DateUtils';

import { Testable } from 'utils/TypeUtils';

import { TicketClinician } from 'models/Ticket';
import TicketAssign from 'models/TicketAssign';

import ReassignDropDown from 'components/Ticket/TicketRow/ReassignDropDown';
import { TextButton } from 'components/UIkit/atoms/Button';

interface Props extends Testable {
  ticketDoctor: TicketClinician | null;
  mainAssign: TicketAssign;
  handleDoctorSelected: (clinicianId: number) => void;
  ticketId: number;
  ticketIndex?: number;
  ticketSectionCurrentPage?: number;
}

const TicketReassignAction: FC<Props> = ({
  ticketDoctor,
  mainAssign,
  handleDoctorSelected,
  testHook,
  ticketId,
  ticketIndex,
  ticketSectionCurrentPage = 0
}) => {
  const [isReassignOpen, setIsReassignOpen] = useState(false);

  const onDoctorSelected = (clinicianId: number) => {
    setIsReassignOpen(false);
    handleDoctorSelected(clinicianId);
  };

  if (!ticketDoctor) {
    return null;
  }
  return (
    <div data-test-hook={testHook} className="d-flex flex-column align-items-end position-relative">
      <div>
        <span className="bold reassign-text">{shortenedFullName(ticketDoctor)}&nbsp;</span>
        <span className="reassign-text">{fromNow(mainAssign.assignDate)}</span>
      </div>
      <ReassignDropDown
        label={
          <TextButton
            onClick={() => setIsReassignOpen((prevIsOpen) => !prevIsOpen)}
            variant="secondary"
            isActive={isReassignOpen}
            display="block"
          >
            Reassign
          </TextButton>
        }
        tooltipController={{
          visible: isReassignOpen,
          onClickOutside: () => setIsReassignOpen(false)
        }}
        onDoctorSelected={onDoctorSelected}
        showUnassignOption
        closeTooltip={() => setIsReassignOpen(false)}
        ticketIds={[ticketId]}
        ticketIndex={ticketIndex}
        ticketSectionCurrentPage={ticketSectionCurrentPage}
        selectedDoctor={ticketDoctor}
      />
    </div>
  );
};

export default TicketReassignAction;
