// @ts-strict-ignore
import { FC, useRef, useState } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackActionsMenuAnalyticsEvent } from 'analytics/events/actions-menu';

import { observer } from 'mobx-react';

import { useHistory } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { PathwayBasicInfo } from 'models/PathwayTemplates';

import Ticket from 'models/Ticket';

import { useDoctorsAndAssignees } from 'hooks/useDoctor';
import { usePatientSmsStatus } from 'hooks/usePatientSmsStatus';
import { useTicketAssign } from 'hooks/useTicketAssign';
import { useTicketResolve } from 'hooks/useTicketResolve';

import { useToggleCallLog } from 'hooks/useToggleCallLog';

import { usePatientModel } from 'components/Patient/usePatientModel';

import CallAndPathwaysDropDown from 'components/Ticket/TicketRow/CallAndPathwaysDropDown';
import ReassignDropDown from 'components/Ticket/TicketRow/ReassignDropDown';
import { useTicketActionCallbacks } from 'components/Ticket/TicketsContainers/useTicketActionCallbacks';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { DatePickerTooltip } from 'components/Tooltip/DatepickerTooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';
import { OutlinedButton } from 'components/UIkit/atoms/Button';

import { getTicketActionOptions, TicketActionHandlers } from './TicketActions.utils';

interface Props {
  ticket: Ticket;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
  onSendPatientSmsClicked?: (shouldOpenSmsBlockedPopup: boolean) => void;
}

const TicketActionsMenu: FC<Props> = ({
  ticket,
  ticketIndex,
  ticketSectionCurrentPage = 0,
  onSendPatientSmsClicked
}) => {
  const tooltipRef = useRef();
  const history = useHistory();
  const patientModel = usePatientModel(ticket.patientId);
  const { callLoggingStore, tasksStore, settingsStore } = useStores();
  const { openEditTicketModal, openEditTaskModal, openResolveAndCommentModal } =
    useTicketOverviewContext();
  const { onRescheduleTask } = useTicketActionCallbacks();
  const assignTicket = useTicketAssign();
  const ticketResolve = useTicketResolve();
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
  const [isPathwaysDropdownOpen, setIsPathwaysDropdownOpen] = useState(false);
  const [isReassignOpen, setIsReassignOpen] = useState(false);
  const [isDatepickerOpen, setDatepickerOpen] = useState(false);
  const { shouldOpenSmsBlockedPopup, isSmsOptionDisabled } = usePatientSmsStatus(ticket);

  const handleLogCallClicked = useToggleCallLog(() => {
    trackActionsMenuAnalyticsEvent({
      action: AnalyticEventAction.Select,
      value: 'Log Call',
      ticket_id: ticket.id,
      source: ticket.isTask ? 'task' : 'ticket',
      item_index: ticketIndex + 1,
      page_number: ticketSectionCurrentPage + 1
    });

    if (settingsStore.hasFeature(FEATURES.ACTIONS_LOG_CALLS_PATHWAYS)) {
      setIsPathwaysDropdownOpen(true);
      setIsActionsMenuOpen(false);
      return;
    }

    startCall(null);
  });

  const { handleDoctorSelected, mainAssign, currentDoctor, ticketDoctor } =
    useDoctorsAndAssignees(ticket);
  const { id: doctorId } = currentDoctor;

  const isActionsButtonActive =
    isActionsMenuOpen || isReassignOpen || isPathwaysDropdownOpen || isDatepickerOpen;

  const handleActionsButtonClicked = () => {
    trackActionsMenuAnalyticsEvent({
      action: AnalyticEventAction.Click,
      ticket_id: ticket.id,
      source: ticket.isTask ? 'task' : 'ticket',
      item_index: ticketIndex + 1,
      page_number: ticketSectionCurrentPage + 1
    });
    setIsActionsMenuOpen((prevState) => !prevState);
    setIsReassignOpen(false);
    setIsPathwaysDropdownOpen(false);
    setDatepickerOpen(false);
  };

  const resolveTicket = async () => {
    trackActionsMenuAnalyticsEvent({
      action: AnalyticEventAction.Select,
      ticket_id: ticket.id,
      value: 'Resolve',
      source: ticket.isTask ? 'task' : 'ticket',
      item_index: ticketIndex + 1,
      page_number: ticketSectionCurrentPage + 1
    });
    setIsActionsMenuOpen(false);
    await ticketResolve([ticket.id], undefined);
  };

  const actionHandlers: TicketActionHandlers = {
    resolve: resolveTicket,
    reassign: () => {
      trackActionsMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        ticket_id: ticket.id,
        value: 'Reassign',
        source: ticket.isTask ? 'task' : 'ticket',
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });
      setIsReassignOpen(true);
      setIsActionsMenuOpen(false);
    },
    resolveAndComment: () => {
      trackActionsMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        ticket_id: ticket.id,
        value: 'Resolve And Comment',
        source: ticket.isTask ? 'task' : 'ticket',
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });
      openResolveAndCommentModal([ticket.id]);
      setIsActionsMenuOpen(false);
    },
    logCall: handleLogCallClicked,
    rescheduleTask: () => {
      trackActionsMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        ticket_id: ticket.id,
        value: 'Reschedule Task',
        source: ticket.isTask ? 'task' : 'ticket',
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });
      setDatepickerOpen(true);
      setIsActionsMenuOpen(false);
    },
    editTask: () => {
      trackActionsMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        ticket_id: ticket.id,
        value: 'Edit Task',
        source: ticket.isTask ? 'task' : 'ticket',
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });
      openEditTaskModal(patientModel, ticket);
      setIsActionsMenuOpen(false);
    },
    editOperatorTicket: () => {
      trackActionsMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        ticket_id: ticket.id,
        value: 'Edit Operator Ticket',
        source: ticket.isTask ? 'task' : 'ticket',
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });
      openEditTicketModal(patientModel, ticket);
      setIsActionsMenuOpen(false);
    },
    pickUp: async () => {
      trackActionsMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        ticket_id: ticket.id,
        value: 'Pick up',
        source: ticket.isTask ? 'task' : 'ticket',
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });
      setIsActionsMenuOpen(false);
      await assignTicket(ticket, doctorId);
    },
    sendPatientSms: () => {
      trackActionsMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        ticket_id: ticket.id,
        value: 'Send Message',
        source: ticket.isTask ? 'task' : 'ticket',
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });
      onSendPatientSmsClicked(shouldOpenSmsBlockedPopup);
      setIsActionsMenuOpen(false);
    }
  };

  const shouldShowSendMessage = settingsStore.hasFeature(FEATURES.ACTIONS_SEND_PATIENT_SMS);

  let actionTooltipOptions: ITooltipOption[] = getTicketActionOptions(
    actionHandlers,
    ticket,
    Boolean(mainAssign),
    {
      shouldShowSendMessage,
      isSmsOptionDisabled
    }
  );

  const handleReschedule = async (date?: Date) => {
    await tasksStore.bulkReschedule([ticket], date);
    onRescheduleTask && onRescheduleTask(ticket);
    setDatepickerOpen(false);
  };

  const startCall = (selectedPathway: PathwayBasicInfo) => {
    setIsPathwaysDropdownOpen(false);

    callLoggingStore.startCallSession({
      selectedPathway,
      forTicketIds: [ticket.id]
    });

    if (!history.location.pathname.includes('patient')) {
      history.push(`/patient/${ticket.patientId}`);
    }
  };

  return (
    <>
      <OutlinedButton
        onClick={handleActionsButtonClicked}
        ref={tooltipRef}
        testHook={`assignedActions${ticket.id}`}
        isActive={isActionsButtonActive}
      >
        Actions
      </OutlinedButton>

      <Tooltip
        trigger={TooltipTrigger.CLICK}
        controller={{
          visible: isActionsMenuOpen,
          onClickOutside: () => setIsActionsMenuOpen(false)
        }}
        reference={tooltipRef}
      >
        <TooltipSelect options={actionTooltipOptions} />
      </Tooltip>

      <ReassignDropDown
        onDoctorSelected={handleDoctorSelected}
        tooltipReference={tooltipRef}
        tooltipController={{
          visible: isReassignOpen,
          onClickOutside: () => setIsReassignOpen(false)
        }}
        showUnassignOption={Boolean(mainAssign)}
        closeTooltip={() => setIsReassignOpen(false)}
        ticketIds={[ticket.id]}
        ticketIndex={ticketIndex}
        ticketSectionCurrentPage={ticketSectionCurrentPage}
        selectedDoctor={ticketDoctor}
      />

      <CallAndPathwaysDropDown
        ticket={ticket}
        ticketIds={[ticket.id]}
        tooltipReference={tooltipRef}
        tooltipController={{
          visible: isPathwaysDropdownOpen,
          onClickOutside: () => setIsPathwaysDropdownOpen(false)
        }}
        onSelect={startCall}
        patientId={ticket.patientId}
        allowNoPathway
        ticketIndex={ticketIndex}
        ticketSectionCurrentPage={ticketSectionCurrentPage}
      />
      <DatePickerTooltip
        onSelect={handleReschedule}
        reference={tooltipRef}
        date={ticket.taskTicket?.dueDate ? ticket.taskTicket.dueDate : null}
        placement="bottom"
        controller={{
          visible: isDatepickerOpen,
          onClickOutside: () => setDatepickerOpen(false)
        }}
      />
    </>
  );
};

export default observer(TicketActionsMenu);
