import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.SingleReport;
type Value = 'blocked' | undefined;

interface Properties {
  action: Action;
  type: 'sms' | 'ivr';
  patient_id: number;
  value?: Value;
}

export const trackRequestReportAnalyticsEvent = ({
  action,
  type,
  patient_id,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.RequestReport,
    properties: { action, type, patient_id, ...optionalProps }
  });
};
