// @ts-strict-ignore
import { castArray, groupBy } from 'lodash/fp';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import moment from 'moment';

import { RootStore } from 'mobx/stores';
import { DataMap } from 'mobx/stores/DataMap';

import taskFetcher, { TaskCreationAttrs, TaskUpdateAttrs } from 'fetchers/TasksFetcher';

import { handleErrorSilently } from 'services/errorHandlingService';

import { formatDate, getDateSorter, LONG_DATE_FORMAT } from 'utils/DateUtils';

import { extractTaskQueryFromFilters, mergeWithQueryDefaults } from 'utils/serverFiltersUtils';

import { showToast } from 'utils/UserMessageUtils';

import { API_LABELS } from 'constants/apiUrls';

import ClusteredTaskTicket from 'models/ClusteredTaskTicket';
import Ticket, { TicketClass, TicketStatus } from 'models/Ticket';

import { TasksQueryRequestParams } from 'views/Filters/filters.types';
import { WorkQueueRequestFilters } from 'views/WorkQueue/WorkQueue.types';

const INCLUDE_NON_EPISODE_TASKS_IN_QUERY = -1;
export const TIME_RANGE_VIEW_PREFERENCE_KEY = 'TIME_RANGE_VIEW_PREFERENCE_KEY';

export const defaultTasksDateSort = (dateA: Date, dateB: Date) => {
  if (dateA.getTime() === dateB.getTime()) {
    return 0;
  }
  return moment(dateA).isAfter(moment(dateB)) ? 1 : -1;
};

interface FetchTasksConfig {
  isInterval: boolean;
  shouldClearTasks: boolean;
}

class TasksStore {
  rootStore: RootStore;

  @observable
  ticketsMap: DataMap<Ticket>;

  @observable
  singleTasksByPatientClusterMap = new Map<number, DataMap<Ticket>>();

  @observable
  clusteredTasksMap = new DataMap<ClusteredTaskTicket>();

  @observable
  dataReady: boolean = false;

  @observable
  updatingTasks: boolean = false;

  @observable
  lastRetrievedTimestamp: number = null;

  @observable
  allTasksFetchedForPatientId: number;

  refreshInterval: any;

  @observable
  viewByMonths: boolean;

  @observable
  patientIdsToFetch = new Set<number>();

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.ticketsMap = rootStore.stores.ticketsStore.ticketsMap;
  }

  derivedQuery = (useTimestamp: boolean): TasksQueryRequestParams => {
    const { ticketFiltersStore } = this.rootStore.stores;
    const query = {
      ...ticketFiltersStore.filters,
      ...(this.viewByMonths && { fromDate: null, toDate: null }),
      episodeTasks: ticketFiltersStore.episodeTasksFilterValue
    };

    // TODO: this logic needs to move to server
    if (this.shouldAddNonEpisodeTasksToQuery) {
      query.episodeIds = query.episodeIds.concat({
        value: INCLUDE_NON_EPISODE_TASKS_IN_QUERY,
        label: ''
      });
    }

    const queryFromFilters = extractTaskQueryFromFilters(query);

    const finalQuery = mergeWithQueryDefaults(
      queryFromFilters,
      ticketFiltersStore.defaultTasksQueryValues
    );

    return {
      ...finalQuery,
      ...(useTimestamp && { lastRetrievedTimestamp: this.lastRetrievedTimestamp })
    };
  };

  @computed
  get shouldAddNonEpisodeTasksToQuery() {
    const { ticketFiltersStore } = this.rootStore.stores;
    // We want to also return none episodes tasks if episodes were selected in filters
    // and None episodes filter type is selected (ticketType filter)
    // or episode type filter is not selected (ticketType filter)
    return (
      ticketFiltersStore.filters.episodeIds?.length &&
      (ticketFiltersStore.isNonEpisodeFilterSelected ||
        !ticketFiltersStore.isEpisodeTasksFilterSelected)
    );
  }

  @computed
  get tasks(): Ticket[] {
    return this.ticketsMap.items
      .filter((ticket: Ticket) => ticket.class === TicketClass.task)
      .sort(getDateSorter('taskTicket.dueDate', true));
  }

  tasksForPatientCluster = (patientId: number) => {
    const cluster = this.singleTasksByPatientClusterMap.get(patientId);
    if (!cluster?.items) {
      return [];
    }

    return cluster.items.filter(
      (taskTicket) =>
        patientId === taskTicket.patientId &&
        !taskTicket.isAssignedToDoctor(this.rootStore.stores.userStore.currentDoctor.id)
    );
  };

  fetchTasksOrDelta(intervalTime?: number) {
    if (!this.refreshInterval) {
      const { taskRetrievalInterval } = this.rootStore.stores.settingsStore.institutionSettings;
      this.refreshInterval = setInterval(
        () =>
          this.fetchTasks({ isInterval: true, shouldClearTasks: false }).catch(handleErrorSilently),
        intervalTime || taskRetrievalInterval
      );
    }
    return this.fetchTasks();
  }

  @action
  async fetchTasks(
    { isInterval, shouldClearTasks }: FetchTasksConfig = {
      isInterval: false,
      shouldClearTasks: true
    }
  ) {
    const requestTimestamp = Date.now();
    const tasksQueryRequestParams = this.derivedQuery(isInterval);
    try {
      runInAction(() => {
        this.updatingTasks = !isInterval;
      });
      const tasks = await taskFetcher.fetchTasks(
        tasksQueryRequestParams,
        API_LABELS.TASKS_QUERY(isInterval)
      );

      runInAction(() => {
        this.lastRetrievedTimestamp = requestTimestamp;
        if (!this.dataReady) {
          this.dataReady = true;
        }
      });
      if (shouldClearTasks) {
        this.clearCurrentTasks();
      }
      this.addTasks(tasks);
    } finally {
      runInAction(() => {
        this.allTasksFetchedForPatientId =
          !tasksQueryRequestParams.fromDate &&
          !tasksQueryRequestParams.toDate &&
          tasksQueryRequestParams.patientId;
        this.updatingTasks = false;
      });
    }
  }

  resetAndFetchTasks() {
    this.clearTimestamp();
    this.fetchTasks();
  }

  @action
  private addTasks(taskTickets: Ticket[]) {
    taskTickets.forEach((taskTicket: Ticket) => {
      const currTicket = this.ticketsMap.get(taskTicket.id);
      // because comments are not returned on tasks - we need to make sure not to override them
      taskTicket.comments = currTicket?.comments || [];
    });
    this.ticketsMap.replaceItems(taskTickets);
  }

  @action
  async fetchTasksForPatientCluster(patientId: number | number[], networkLabel?: string) {
    // TODO: move from tasks store and used the wq filters
    const patientIds = castArray(patientId);
    const query = this.derivedQuery(false);
    const { taskSearchTerm } = query as WorkQueueRequestFilters;

    const tasksResponse = await taskFetcher.fetchTasks(
      {
        patientIds,
        ...query,
        // filters are now in WQ format need to convert to task format. should refactor
        ...(taskSearchTerm && { searchTerm: taskSearchTerm }),
        includeAssignToMe: false
      },
      [API_LABELS.TASKS_BY_PATIENT(patientId), networkLabel]
    );

    if (!tasksResponse) {
      return;
    }

    const tasksGroupedByPatientId = groupBy('patientId', tasksResponse);

    runInAction(() => {
      Object.keys(tasksGroupedByPatientId).forEach((patientId) => {
        const tasksForCurrentPatient = tasksGroupedByPatientId[patientId];
        tasksForCurrentPatient?.forEach((responseTaskTicket: Ticket) => {
          const existingTaskTicket = this.singleTasksByPatientClusterMap
            .get(Number(patientId))
            ?.get(responseTaskTicket.id);
          // because comments are not returned on tasks - we need to make sure not to override them
          responseTaskTicket.comments = existingTaskTicket?.comments || [];
        });
        const newTasksMap = new DataMap<Ticket>();
        // Important. using setItems here
        // overrides the data (hence removing resolved tickets)
        newTasksMap.setItems(tasksForCurrentPatient);
        this.singleTasksByPatientClusterMap.set(Number(patientId), newTasksMap);
      });
    });
  }

  @action
  setTasksForPatientCluster(patientId: number) {
    // TODO: move from tasks store
    this.patientIdsToFetch.add(patientId);
    this.fetchTasksForPatientCluster(patientId);
  }

  @action
  clearTasksForPatientCluster(patientId: number) {
    this.patientIdsToFetch.delete(patientId);
    // TODO: clean tickets map usage and move from tasks store
    this.singleTasksByPatientClusterMap.delete(patientId);
  }

  async createTask(taskBody: TaskCreationAttrs) {
    await taskFetcher.createTask(taskBody);
  }

  updateTask(tasksBody: TaskUpdateAttrs) {
    return taskFetcher.updateTask(tasksBody);
  }

  async reopenTask(ticket: Ticket) {
    await taskFetcher.reopenTask(ticket.id);
    ticket.setTicketStatus(TicketStatus.OPEN);
    return;
  }

  @action
  clearCurrentTasks() {
    this.tasks.forEach((taskTicket: Ticket) => {
      this.ticketsMap.delete(taskTicket.id);
    });
  }

  @action
  clearCurrentClusteredTasks() {
    this.clusteredTasksMap.clear();
  }

  @action
  clearData() {
    this.clearCurrentTasks();
    this.clearCurrentClusteredTasks();
    this.dataReady = false;
    this.allTasksFetchedForPatientId = null;
  }

  @action
  stopRefresh() {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
  }

  @action
  resetStore() {
    this.clearData();
    this.stopRefresh();
    this.clearTimestamp();
  }

  @action
  clearTimestamp() {
    this.lastRetrievedTimestamp = null;
  }

  @action
  toggleViewByMonths = () => {
    this.viewByMonths = !this.viewByMonths;
    localStorage.setItem(TIME_RANGE_VIEW_PREFERENCE_KEY, String(this.viewByMonths));
    this.fetchTasks();
  };

  @action
  resetViewByMonths() {
    this.viewByMonths = null;
  }

  @action
  initViewByMonthsFromStorage(forceMonths: boolean) {
    if (forceMonths) {
      localStorage.setItem(TIME_RANGE_VIEW_PREFERENCE_KEY, String(true));
    }
    this.viewByMonths = JSON.parse(localStorage.getItem(TIME_RANGE_VIEW_PREFERENCE_KEY));
  }

  async bulkReschedule(tickets: Ticket[], newDate: Date) {
    const taskIds = tickets.map((ticket) => ticket.taskTicket.id);
    await taskFetcher.rescheduleTasks(taskIds, formatDate(newDate, LONG_DATE_FORMAT));
    if (tickets.length > 1) {
      showToast({ message: `${tickets.length} Items Rescheduled` });
    }
  }

  async bulkStatusChange(tickets: Ticket[], newStatus: TicketStatus) {
    const taskIds = tickets.map((ticket) => ticket.taskTicket.id);
    await taskFetcher.updateTasksStatus(taskIds, newStatus);
    if (tickets.length > 1) {
      showToast({ message: `${tickets.length} Items' Status Updated` });
    }
  }
}

export default TasksStore;
