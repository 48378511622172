import { CSSObjectWithLabel, StylesConfig } from 'react-select';

import { theme } from 'components/UIkit/theme';

const { palette, borderRadius } = theme;
const selectLineHeight = 1.88;

export const reactSelectStyle: StylesConfig<any, any> = {
  menuPortal: (provided, props) =>
    ({
      ...provided,
      zIndex: 1300
    } as any),
  indicatorsContainer: (provided, props) =>
    ({
      ...provided,
      padding: theme.spacing(8)
    } as CSSObjectWithLabel),
  control: (provided, state) => {
    const { isNarrow, label, menuIsOpen, isFocused } = state.selectProps;
    const borderColor = menuIsOpen || isFocused ? palette.primary.main : palette.natural.border;

    return {
      ...provided,
      borderColor,
      boxShadow: null,
      borderRadius: `${label ? 0 : borderRadius.large} ${borderRadius.large} ${
        borderRadius.large
      } ${borderRadius.large}`,
      minHeight: isNarrow ? 0 : 45,
      ':hover': { borderColor }
    } as any;
  },
  dropdownIndicator: () => ({ padding: theme.spacing(4) }),
  clearIndicator: () => ({ padding: theme.spacing(4) }),
  menu: (provided) =>
    ({
      ...provided,
      zIndex: 2
    } as any),
  placeholder: (provided) =>
    ({
      ...provided,
      color: palette.text.disabled
    } as any),
  valueContainer: (provided) =>
    ({
      ...provided,
      textAlign: 'left',
      lineHeight: selectLineHeight
    } as any),
  // select with groups
  groupHeading: (provided) =>
    ({
      ...provided,
      fontSize: '1rem',
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      margin: 0
    } as any),
  // multi value
  multiValue: (provided) =>
    ({
      ...provided,
      lineHeight: selectLineHeight,
      borderRadius: borderRadius.large,
      paddingRight: theme.spacing(8),
      backgroundColor: palette.primary.contrastText,
      flexDirection: 'row-reverse'
    } as any),
  multiValueRemove: (provided) =>
    ({
      ...provided,
      height: 32,

      ':hover': {
        borderRadius: `${borderRadius.large} 0 0 ${borderRadius.large}`,
        backgroundColor: palette.primary.main,
        color: palette.natural.white
      }
    } as any)
};
