import { FC } from 'react';

import { css, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
  title: string;
  description: string;
}

export const TicketTypeTooltipRow: FC<Props> = ({ title, description }) => (
  <StyledBox>
    <Typography variant="body3">{title} </Typography>
    <Typography variant="body2">{description}</Typography>
  </StyledBox>
);

const StyledBox = styled('div')(
  ({ theme }) => css`
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(12)};
    }
  `
);
