// @ts-strict-ignore
import Doctor from 'models/Doctor';
import { LoginWithDoctorId } from 'models/Login';
import UserModel from 'models/UserModel';

export class UserParser {
  static parseUser(unparsedUser: any) {
    return new UserModel(
      unparsedUser.id,
      // TODO: change || when no more v1 left (snake case)
      unparsedUser.first_name || unparsedUser.firstName,
      // TODO: change || when no more v1 left (snake case)
      unparsedUser.last_name || unparsedUser.lastName,
      unparsedUser.email,
      unparsedUser.password,
      unparsedUser.gender,
      unparsedUser.picture,
      unparsedUser.phone,
      // TODO: change || when no more v1 left (snake case)
      unparsedUser.phone_type || unparsedUser.phoneType,
      unparsedUser.sms_opted_out_at
    );
  }

  static parseDoctor(unparsedDoctor: any): Doctor {
    const userModel = UserParser.parseUser(unparsedDoctor.user);
    const doctor = new Doctor(userModel);
    doctor.id = unparsedDoctor.id;
    doctor.credentialId = unparsedDoctor.credentialId;
    doctor.role = unparsedDoctor.role;
    doctor.createdAt = unparsedDoctor.createdAt;
    doctor.userStatus = unparsedDoctor.userStatus;
    doctor.featureIntros = unparsedDoctor.featureIntros || [];

    if (unparsedDoctor.doctors_to_departments) {
      doctor.departmentIds = unparsedDoctor.doctors_to_departments.map(
        (item: any) => item.department_id
      );
    }

    return doctor;
  }

  static parseLoginResponse(response: any): LoginWithDoctorId {
    return {
      userId: response.userId,
      userPhone: response.userPhone,
      should2FA: response.should2FA,
      doctorId: response.should2FA ? null : response.doctor.id
    };
  }

  static parseVerify2FACodeResponse(response: { codeValid: boolean; doctor: any }) {
    return {
      ...response,
      doctorId: response.doctor.id
    };
  }
}
