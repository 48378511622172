export const pathwayBuilderTestSelectors = {
  page: 'pathway-builder-page',
  tabs: {
    pathways: {
      newPathwayButton: 'new-pathway-button'
    },
    questions: {
      newQuestionButton: 'new-question-button',
      titleInput: 'question-title-input',
      saveQuestionButton: 'save-question-button',
      typeSelect: 'question-type-select'
    }
  },
  pathwayEditor: {
    header: {
      nameInput: 'pathway-name-input',
      savePathwayButton: 'save-pathway-button'
    }
  }
};
