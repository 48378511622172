// @ts-strict-ignore
import { FC } from 'react';

import { chain } from 'lodash';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import Ticket from 'models/Ticket';

import TicketsBulkActionMenu from 'components/Ticket/BulkActionMenu/TicketsBulkActionMenu';
import TicketsSectionTasksDue from 'components/Ticket/TicketsContainers/TicketsSectionTasksDue';

const getMonthTitle = (month: number) => `Month ${month} Tasks`;

interface Props {
  tasks: Ticket[];
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
}

const TasksSectionViewManager: FC<Props> = ({ tasks, ticketRefsCallback }) => {
  const { tasksStore } = useStores();
  let firstSection = tasks;
  let firstSectionTitle;
  let otherSections;

  if (tasksStore.viewByMonths) {
    const sections = chain(tasks)
      .groupBy((task: Ticket) => task.taskTicket.monthNumber)
      .map((value: Ticket[], key: string) => ({
        month: Number(key),
        tasks: value
      }))
      .sort((a, b) => {
        const monthA = isNaN(a.month) ? -1 : a.month;
        const monthB = isNaN(b.month) ? -1 : b.month;
        return monthA - monthB;
      })
      .value();

    const [firstSectionGroup, ...otherSectionsGroups] = sections;
    firstSection = firstSectionGroup?.tasks || tasks;
    firstSectionTitle = isNaN(firstSectionGroup?.month)
      ? 'Non-Episode Tasks'
      : getMonthTitle(firstSectionGroup?.month);
    otherSections = otherSectionsGroups;
  }

  return (
    <>
      <TicketsSectionTasksDue
        tasks={firstSection}
        sectionTitle={firstSectionTitle}
        withFilters={!tasksStore.viewByMonths}
        timeViewToggle
        ticketRefsCallback={ticketRefsCallback}
      />

      {otherSections?.map((otherSection, i) => (
        <TicketsSectionTasksDue
          key={`${otherSection.month}_${i}`}
          tasks={otherSection.tasks}
          sectionTitle={getMonthTitle(otherSection.month)}
          ticketRefsCallback={ticketRefsCallback}
        />
      ))}
      <TicketsBulkActionMenu />
    </>
  );
};

export default observer(TasksSectionViewManager);
