import { useState } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import Patient from 'models/Patient';

export const useOralReportCycle = (
  patient: Patient,
  reportId: number,
  defaultSelectedDate: Date
) => {
  const [selectedDate, setSelectedDate] = useState<Date>(defaultSelectedDate);
  const { patientPageStore } = useStores();

  const handleDateSelect = async (date: Date) => {
    await patientPageStore.updateStartOfCycle(reportId, date);
    const questionnaireAnswer = patient.questionnairesAnswers.find(
      (questionnaire) => questionnaire.id === reportId
    );

    questionnaireAnswer!.answer.oral!.cycle = date;
    setSelectedDate(date);
  };

  return {
    selectedDate,
    handleDateSelect
  };
};
