// @ts-strict-ignore
import { FC } from 'react';

import classNames from 'classnames';

import { getFormattedPhoneNumber } from 'utils/PhoneUtils';

import { IPatientContact } from 'models/Patient';

import Ticket from 'models/Ticket';

import { usePatientModel } from 'components/Patient/usePatientModel';

interface Props {
  ticket: Ticket;
}

interface ItemProps {
  children: string;
  title: string;
  forceSingleLine?: boolean;
}

const TicketContactItem: FC<ItemProps> = ({ title, forceSingleLine, children }) => (
  <div className="contact-item d-flex flex-column">
    <span className="contact-item-header">{title}</span>
    <span
      className={classNames('contact-item-data', {
        'text-nowrap': forceSingleLine
      })}
    >
      {children}
    </span>
  </div>
);

const TicketContact: FC<Props> = ({ ticket }) => {
  const patient = usePatientModel(ticket.patientId);

  let contactInfo: IPatientContact = {
    relationship: null,
    name: 'Patient',
    phoneNumber: patient?.phone
  };

  const hipaaAuthDate = ticket.operatorTicket?.contactHippaAuthDate || null;

  if (ticket.patientHasContact) {
    const patientContact = patient.getContactById(ticket.operatorTicket.patientContactId);

    if (patientContact) {
      contactInfo.phoneNumber = patientContact.phoneNumber;
      contactInfo.name = patientContact.name;
      contactInfo.relationship = patientContact.relationship;
    }
  }

  const { name, relationship, phoneNumber } = contactInfo;

  return (
    <div className="ticket-contact d-flex">
      <TicketContactItem title="Callback Contact">{name}</TicketContactItem>

      {relationship && (
        <TicketContactItem forceSingleLine title="Relationship">
          {relationship}
        </TicketContactItem>
      )}

      {phoneNumber && (
        <TicketContactItem forceSingleLine title="Number">
          {getFormattedPhoneNumber(phoneNumber)}
        </TicketContactItem>
      )}

      {ticket.patientHasContact && (
        <TicketContactItem forceSingleLine title="HIPAA">
          {Boolean(hipaaAuthDate) ? 'Yes' : 'No'}
        </TicketContactItem>
      )}
    </div>
  );
};

export default TicketContact;
