import { FC, MouseEvent, useState } from 'react';

import { Box } from '@mui/material';

import { Tooltip } from 'components/Tooltip';
import { TooltipController, TooltipTrigger } from 'components/Tooltip/Tooltip.types';
import { StyledBulkMenuItem } from 'components/UIkit/atoms/BulkMenu/BulkMenu.shared';
import { BulkItem } from 'components/UIkit/atoms/BulkMenu/BulkMenu.types';
import { BulkMenuContent } from 'components/UIkit/atoms/BulkMenu/BulkMenuContent';
import { TextButton } from 'components/UIkit/atoms/Button';

interface Props {
  item: BulkItem;
}

export const BulkMenuItem: FC<Props> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { isDisabled, disabledText, placement, textButtonProps, controller, content } = item;

  const internalController: TooltipController = {
    visible: isOpen,
    onClickOutside: () => setIsOpen(false)
  };

  const bulkMenuController: TooltipController = controller || internalController;

  const onTextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setIsOpen((prevState) => !prevState);

    if (textButtonProps.onClick) {
      textButtonProps.onClick(event);
    }
  };

  return (
    <StyledBulkMenuItem>
      <Tooltip
        disabled={!isDisabled}
        label={
          <Tooltip
            trigger={TooltipTrigger.CLICK}
            controller={bulkMenuController}
            disabled={isDisabled}
            placement={placement}
            label={
              <TextButton
                size="large"
                {...textButtonProps}
                isActive={controller?.visible || isOpen}
                onClick={onTextButtonClick}
              >
                {textButtonProps.label}
              </TextButton>
            }
          >
            <BulkMenuContent content={content} close={() => setIsOpen(false)} />
          </Tooltip>
        }
      >
        {disabledText && <Box p={12}>{disabledText}</Box>}
      </Tooltip>
    </StyledBulkMenuItem>
  );
};
