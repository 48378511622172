import {
  EventHandler,
  FC,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  KeyboardEvent
} from 'react';

import { Checkbox as MuiCheckbox, css } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Testable } from 'utils/TypeUtils';

import Icon from 'components/Icons/Icon';

export interface CheckboxProps extends Testable {
  disabled?: boolean;
  error?: boolean;
  id?: string;
  name?: string;
  variant?: 'primary' | 'secondary';
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: EventHandler<any>;
  onKeyDown?: (event: KeyboardEvent<HTMLButtonElement>) => void;
  readonly?: boolean;
  ref?: ForwardedRef<any>;
}

export const Checkbox: FC<CheckboxProps> = forwardRef(
  (
    {
      error,
      name,
      variant = 'primary',
      disabled,
      id,
      onKeyDown,
      onChange,
      checked,
      indeterminate,
      readonly,
      testHook
    },
    ref
  ) => (
    <MuiCheckbox
      data-test-hook={testHook}
      id={id}
      name={name}
      readOnly={readonly}
      disabled={disabled}
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
      ref={ref}
      icon={<StyledContainer variant={variant} />}
      checkedIcon={
        <StyledContainer variant={variant}>
          <Icon.CheckMark />
        </StyledContainer>
      }
      indeterminateIcon={
        <StyledContainer variant={variant}>
          <Icon.CheckIndeterminate />
        </StyledContainer>
      }
      color={error ? 'error' : 'primary'}
      size="small"
      onKeyDown={onKeyDown}
    />
  )
) as ForwardRefExoticComponent<CheckboxProps>;

const StyledContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant'
})<{ variant: 'primary' | 'secondary' }>(
  ({ theme, variant }) => css`
    position: relative;
    width: 20px;
    height: 20px;
    background-color: ${theme.palette.natural.white};
    border: solid 1px
      ${variant === 'primary' ? theme.palette.primary.main : theme.palette.natural.border};
    border-radius: ${theme.borderRadius.small};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${theme.palette.natural.white};
    }
  `
);
