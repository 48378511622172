import { FC, useEffect } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import classNames from 'classnames';

import { observer } from 'mobx-react';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { API_URLS } from 'constants/apiUrls';

import Ticket from 'models/Ticket';

import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

import TicketComments from './TicketComments';
import TicketContact from './TicketContact';

import './TicketCommentsAndContact.scss';

interface IProps {
  ticket: Ticket;
  showContact?: boolean;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
}

interface LazyCommentsProps {
  ticket: Ticket;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
}

const LazyComments: FC<LazyCommentsProps> = observer(
  ({ ticket, ticketIndex, ticketSectionCurrentPage }) => {
    const { ticketsStore } = useStores();
    const isLoading = useNetworkLoading(API_URLS.GET_COMMENTS(ticket.id, 'ticket'));
    const { tab } = useTicketOverviewContext();

    useEffect(
      function fetchTicketComments() {
        ticketsStore.fetchCommentsForTicket(ticket);
      },
      [ticketsStore, ticket]
    );

    return (
      <>
        {isLoading && <span>Loading comments...</span>}
        <TicketComments
          ticket={ticket}
          comments={ticket.comments}
          onSubmit={() =>
            trackActionButtonAnalyticsEvent({
              action: AnalyticEventAction.Comment,
              ticket_id: ticket.id,
              patient_id: ticket.patientId,
              tab,
              item_index: ticketIndex + 1,
              page_number: ticketSectionCurrentPage + 1
            })
          }
        />
      </>
    );
  }
);

const TicketCommentsAndContact: FC<IProps> = ({
  ticket,
  showContact = true,
  ticketIndex,
  ticketSectionCurrentPage
}) => {
  const classes = classNames('tickets-comments-and-contact d-flex flex-column', {
    'comments-only': !showContact
  });

  return (
    <div className={classes}>
      {showContact && <TicketContact ticket={ticket} />}
      <LazyComments
        ticket={ticket}
        ticketIndex={ticketIndex}
        ticketSectionCurrentPage={ticketSectionCurrentPage}
      />
    </div>
  );
};

export default TicketCommentsAndContact;
