import { FC, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { Dialogs } from 'analytics/events/dialog';
import { observer } from 'mobx-react';

import { Row } from 'react-table';

import { pathwayBuilderTestSelectors } from 'tests/models/pages/pathway-builder/pathway-builder-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { PathwayTemplatesQuestion } from 'fetchers/responses/pathways-builder.response';

import { isSubStringCaseInsensitive, pluralize } from 'utils/StringUtils';
import { showToast } from 'utils/UserMessageUtils';

import { QuestionBankFilters } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankFilters';
import {
  defaultEditQuestionPopupState,
  NEW_QUESTION_ID,
  QuestionBankColumns
} from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.constants';
import {
  EditPathwayQuestionPopupState,
  QuestionForm
} from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.types';
import {
  getBlankNewQuestion,
  sortWithNewQuestion
} from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.utils';
import { QuestionRowEditComponent } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionRowEditComponent';
import { HomeCareInstructionsCell } from 'views/Pages/PathwayBuilder/QuestionBankTabView/table-cells/HomeCareInstructionsCell';
import { PathwaysCell } from 'views/Pages/PathwayBuilder/QuestionBankTabView/table-cells/PathwaysCell';

import { QuestionTitleCell } from 'views/Pages/PathwayBuilder/QuestionBankTabView/table-cells/QuestionTitleCell';

import { QuestionTypeCell } from 'views/Pages/PathwayBuilder/QuestionBankTabView/table-cells/QuestionTypeCell';

import { RowActionsCell } from 'views/Pages/PathwayBuilder/QuestionBankTabView/table-cells/RowActionsCell';
import { CANCER_DIAGNOSIS_QUESTION_ID } from 'views/Patient/PatientMain/PathwaysView.constants';

import DefaultEmptyTableView from 'views/Widgets/DefaultEmptyTableView';

import Icon from 'components/Icons/Icon';
import {
  defaultStringPropertyCompare,
  Table,
  TableCellParams,
  TableColumn
} from 'components/Table';
import { OutlinedIconButton } from 'components/UIkit/atoms/Button';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  handleQuestionSaved: (questionId: string) => void;
}

export const QuestionBankTabView: FC<Props> = observer(({ handleQuestionSaved }) => {
  const { pathwayBuilderStore } = useStores();
  const {
    pathways,
    questions,
    questionBankFilters,
    questionCountInPathways,
    addNewQuestion,
    updateQuestion
  } = pathwayBuilderStore;
  const [editedRowId, setEditedRowId] = useState<string | null>(null);
  const [editQuestionPopupState, setEditQuestionPopupState] =
    useState<EditPathwayQuestionPopupState>(defaultEditQuestionPopupState);

  const onSetEditedRowId = (questionId: string) => {
    const pathwayCount = questionCountInPathways[questionId];
    if (pathwayCount) {
      setEditQuestionPopupState({
        pathwayCount,
        questionId
      });
      return;
    }

    setEditedRowId(questionId);
  };

  const columns: TableColumn<PathwayTemplatesQuestion>[] = [
    {
      Header: 'Question',
      accessor: QuestionBankColumns.Title,
      Cell: QuestionTitleCell,
      width: 5,
      sortType: sortWithNewQuestion(defaultStringPropertyCompare('title'))
    },
    {
      Header: 'Type',
      accessor: QuestionBankColumns.Type,
      Cell: QuestionTypeCell,
      width: 1,
      sortType: sortWithNewQuestion(defaultStringPropertyCompare('type'))
    },
    {
      Header: 'Active Pathways',
      id: 'activePathways',
      accessor: (row) => questionCountInPathways[row.id] || 0,
      Cell: PathwaysCell,
      width: 1,
      sortType: sortWithNewQuestion(
        (rowA: Row<PathwayTemplatesQuestion>, rowB: Row<PathwayTemplatesQuestion>) => {
          return rowA.values.activePathways > rowB.values.activePathways ? 1 : -1;
        }
      )
    },
    {
      Header: 'Home Care Instructions',
      id: 'homeCareInstructions',
      accessor: (row) => row.options?.some((option) => option.isHomeCareInstructions) || false,
      Cell: HomeCareInstructionsCell,
      width: 1,
      sortType: sortWithNewQuestion(
        (rowA: Row<PathwayTemplatesQuestion>, rowB: Row<PathwayTemplatesQuestion>) => {
          // First compare by boolean value (true comes before false)
          const booleanA = rowA.values.homeCareInstructions;
          const booleanB = rowB.values.homeCareInstructions;
          if (booleanA !== booleanB) {
            return booleanB ? 1 : -1;
          }
          // If boolean values are equal, sort by title
          return rowA.original.title.localeCompare(rowB.original.title);
        }
      )
    },
    {
      Header: '',
      id: QuestionBankColumns.Actions,
      Cell: (cellParams: TableCellParams<PathwayTemplatesQuestion>) => (
        <RowActionsCell {...cellParams} setEditedRowId={onSetEditedRowId} />
      ),
      width: 1
    }
  ];

  const handleRowClick = useCallback(
    (row: Row<PathwayTemplatesQuestion>) => row.toggleRowExpanded(),
    []
  );

  const onRowEditSubmit = (editedQuestion: QuestionForm, questionId: string) => {
    setEditedRowId(null);
    let updatedQuestionId: string = questionId;
    // check if this is a new question
    if (questionId === NEW_QUESTION_ID) {
      updatedQuestionId = addNewQuestion(editedQuestion) as string;
    } else {
      updateQuestion(editedQuestion, questionId);
    }

    showToast({
      message: 'Question Saved',
      description:
        'Changes are saved locally - do not delete browser data. Only you can see these changes, on this device.'
    });
    handleQuestionSaved(updatedQuestionId);
  };

  const idGetter = useCallback((row: PathwayTemplatesQuestion) => row.id, []);

  const filteredQuestions = useMemo(() => {
    const filteredPathwayTemplateQuestions = questions.filter((question) => {
      if (question.id === CANCER_DIAGNOSIS_QUESTION_ID) {
        return false;
      }

      if (
        questionBankFilters.searchTerm &&
        !isSubStringCaseInsensitive(question.title, questionBankFilters.searchTerm) &&
        !isSubStringCaseInsensitive(question.id, questionBankFilters.searchTerm)
      ) {
        return false;
      }

      if (
        questionBankFilters.types &&
        !questionBankFilters.types.map((type) => type.value).includes(question.type)
      ) {
        return false;
      }

      if (questionBankFilters.pathways) {
        return questionBankFilters.pathways?.some((pathwayFilter) => {
          const currentFilterPathway = pathways.find(
            (pathway) => pathway.id === pathwayFilter.value
          );
          return currentFilterPathway?.categories.some((category) =>
            category.questions.some((questionInCategory) => questionInCategory.id === question.id)
          );
        });
      }

      return true;
    });

    const newQuestion = editedRowId === NEW_QUESTION_ID ? getBlankNewQuestion() : null;

    return newQuestion
      ? [newQuestion, ...filteredPathwayTemplateQuestions]
      : filteredPathwayTemplateQuestions;
  }, [
    editedRowId,
    pathways,
    questionBankFilters.pathways,
    questionBankFilters.searchTerm,
    questionBankFilters.types,
    questions
  ]);

  return (
    <>
      <MessageDialog
        id={Dialogs.EditPathwayQuestion}
        isOpen={Boolean(editQuestionPopupState.pathwayCount)}
        title={`Edit ${editQuestionPopupState.pathwayCount} ${pluralize(
          'Pathway',
          editQuestionPopupState.pathwayCount
        )}?`}
        handleClose={() => setEditQuestionPopupState(defaultEditQuestionPopupState)}
        primaryActionProps={{
          text: 'Edit Question',
          onClick: () => {
            setEditQuestionPopupState(defaultEditQuestionPopupState);
            setEditedRowId(editQuestionPopupState.questionId);
          }
        }}
        secondaryActionProps={{
          text: 'Cancel',
          onClick: () => setEditQuestionPopupState(defaultEditQuestionPopupState)
        }}
      >
        <Box width="380px">
          This question is used in {editQuestionPopupState.pathwayCount}{' '}
          {pluralize('pathway', editQuestionPopupState.pathwayCount || 0)}. Any changes
          <br />
          will apply to all of its instances.
          <br />
          <br />
          Are you sure you wish to proceed?
        </Box>
      </MessageDialog>

      <QuestionBankFilters />

      <OutlinedIconButton
        icon={<Icon.Plus />}
        onClick={() => {
          setEditedRowId(NEW_QUESTION_ID);
        }}
        my={20}
        variant="secondary"
        disabled={NEW_QUESTION_ID === editedRowId}
        testHook={pathwayBuilderTestSelectors.tabs.questions.newQuestionButton}
      >
        New Question
      </OutlinedIconButton>

      <Table
        columns={columns}
        rowData={filteredQuestions}
        rowAction={handleRowClick}
        getRowId={idGetter}
        RowEditComponent={({ row }: { row: Row<PathwayTemplatesQuestion> }) => (
          <QuestionRowEditComponent
            row={row}
            onRowEditSubmit={onRowEditSubmit}
            onRowEditCancel={() => {
              setEditedRowId(null);
            }}
          />
        )}
        editedRowId={editedRowId}
        emptyTableView={<DefaultEmptyTableView />}
      />
    </>
  );
});
