import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import {
  trackInputUsageAnalyticsEvent,
  trackOpenTextInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';

import { observer } from 'mobx-react';

import { FormProvider, useFormContext } from 'react-hook-form';

import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';

import { IncludeSummaryInEmrNote } from 'models/Call';

import { CallLoggingFooterFormFields } from 'views/Widgets/CallLogging/CallLoggingFooter';

import FormTextAreaField from 'components/Forms/FormTextAreaField';
import Icon from 'components/Icons/Icon';
import { SimpleTooltip } from 'components/Tooltip';
import { FormRadioGroup } from 'components/UIkit/atoms/RadioButton';
import { RadioButtonExternalProps } from 'components/UIkit/atoms/RadioButton/RadioButton';
import { Text } from 'components/UIkit/atoms/Text';

export const disabledSummaryText = 'Smart Summary Disabled…';
const SUMMARY_TEXTAREA_ID = 'summary-textarea';

interface Props {
  generateSmartSummary: () => void;
  isGeneratingSmartSummary: boolean;
  isFailedToGenerateSmartSummaryError: boolean;
  lastDisplayedSummary: string;
  setLastDisplayedSummary: Dispatch<SetStateAction<string>>;
}

const CallLoggingSummary: FC<Props> = ({
  generateSmartSummary,
  isGeneratingSmartSummary,
  isFailedToGenerateSmartSummaryError,
  lastDisplayedSummary,
  setLastDisplayedSummary
}) => {
  const [shouldNotIncludeSummaryInEmrNote, setShouldNotIncludeSummaryInEmrNote] = useState(false);
  const methods = useFormContext<CallLoggingFooterFormFields>();
  const { formState, watch, setValue, getValues, clearErrors } = methods;
  const { errors } = formState;

  const [summary, includeSummaryInEmrNote] = watch(['summary', 'includeSummaryInEmrNote']);

  const isSummaryTextareaDisabled =
    shouldNotIncludeSummaryInEmrNote ||
    isGeneratingSmartSummary ||
    isFailedToGenerateSmartSummaryError;

  const isRegenerateSmartSummaryDisabled =
    shouldNotIncludeSummaryInEmrNote || isGeneratingSmartSummary;

  const isRadioButtonsGroupDisabled =
    isGeneratingSmartSummary || isFailedToGenerateSmartSummaryError;

  useEffect(
    function summaryTextareaAutoResize() {
      const summaryTextareaEl = document.getElementById('summary-textarea');

      if (summaryTextareaEl) {
        summaryTextareaEl.style.height = 'auto';
        const scrollHeight = summaryTextareaEl.scrollHeight;
        summaryTextareaEl.style.height = `${scrollHeight + 2}px`; // +2 in order to disable the auto scroll
      }
    },
    [summary]
  );

  useEffect(
    function handleIncludeSummaryInEmrNoteChanged() {
      clearErrors();

      if (
        includeSummaryInEmrNote === IncludeSummaryInEmrNote.No ||
        includeSummaryInEmrNote === IncludeSummaryInEmrNote.DontOfferAgain
      ) {
        //flow:
        //- user generates smart summary
        //- user choose "No"/"Don't Offer Again"
        //- user choose "Yes"
        //we want to show him the last generated smart summary
        const currentSummary = getValues('summary');

        if (currentSummary !== disabledSummaryText) {
          setLastDisplayedSummary(currentSummary);
        }

        //we want to disable the summary textarea
        setValue('summary', disabledSummaryText);
        setShouldNotIncludeSummaryInEmrNote(true);
        return;
      }

      if (includeSummaryInEmrNote === IncludeSummaryInEmrNote.Yes) {
        if (Boolean(lastDisplayedSummary) || getValues('summary') === disabledSummaryText) {
          //restore the last generated summary
          setValue('summary', lastDisplayedSummary);
        }
      }

      setShouldNotIncludeSummaryInEmrNote(false);
    },
    [
      includeSummaryInEmrNote,
      clearErrors,
      getValues,
      lastDisplayedSummary,
      setValue,
      setLastDisplayedSummary
    ]
  );

  const includeSummaryInEmrNoteRadioButtonOptions: RadioButtonExternalProps[] = [
    {
      id: IncludeSummaryInEmrNote.Yes,
      value: IncludeSummaryInEmrNote.Yes,
      label: 'Yes',
      mr: 24,
      testHook: patientPageTestSelectors.callLogger.smartSummary.includeSummaryInEmrNoteRadioButton(
        IncludeSummaryInEmrNote.Yes
      )
    },
    {
      id: IncludeSummaryInEmrNote.No,
      value: IncludeSummaryInEmrNote.No,
      label: 'No',
      mr: 24,
      testHook: patientPageTestSelectors.callLogger.smartSummary.includeSummaryInEmrNoteRadioButton(
        IncludeSummaryInEmrNote.No
      )
    },
    {
      id: IncludeSummaryInEmrNote.DontOfferAgain,
      value: IncludeSummaryInEmrNote.DontOfferAgain,
      label: "Don't Offer Again",
      testHook: patientPageTestSelectors.callLogger.smartSummary.includeSummaryInEmrNoteRadioButton(
        IncludeSummaryInEmrNote.DontOfferAgain
      )
    }
  ];

  const getRadioButtonsGroupLabelColor = () => {
    if (isRadioButtonsGroupDisabled) {
      return 'disabled';
    }

    if (errors.includeSummaryInEmrNote) {
      return 'error';
    }

    return 'primary';
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      data-test-hook={patientPageTestSelectors.callLogger.smartSummary.container}
    >
      <FormProvider {...methods}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={20}>
          <Text variant="h4" color={getRadioButtonsGroupLabelColor()}>
            Include Smart Summary in EMR Note?
          </Text>

          <FormRadioGroup
            isRow
            required
            name="includeSummaryInEmrNote"
            options={includeSummaryInEmrNoteRadioButtonOptions}
            disabled={isRadioButtonsGroupDisabled}
            onChange={(event) =>
              trackInputUsageAnalyticsEvent({
                action: AnalyticEventAction.Update,
                value: 'Include smart summary',
                type: 'radio',
                virtual_page: 'call logger',
                selected_value: event.target.value
              })
            }
          />
        </Box>

        <Box position="relative">
          <StyledSummaryTextarea
            id={SUMMARY_TEXTAREA_ID}
            name="summary"
            hint="This content is automatically generated and may be inaccurate."
            placeholder=""
            isRequired
            error={errors.summary}
            disabled={isSummaryTextareaDisabled}
            onBlur={(event, currentValue, valueAfterFocus) => {
              trackOpenTextInputUsageAnalyticsEvent(
                currentValue,
                valueAfterFocus,
                'My Smart Summary',
                { virtual_page: 'call logger' }
              );
            }}
            testHook={patientPageTestSelectors.callLogger.smartSummary.summaryTextarea}
          />

          <StyledTooltip
            title={<Box p={20}>Regenerate Smart Summary</Box>}
            placement="top"
            disabled={isRegenerateSmartSummaryDisabled}
          >
            <StyledGenerateSmartSummaryButtonContainer
              isError={Boolean(errors.summary)}
              isDisabled={isRegenerateSmartSummaryDisabled}
              onClick={shouldNotIncludeSummaryInEmrNote ? () => {} : generateSmartSummary}
              data-test-hook="regenerate-smart-summary-button"
            >
              <Icon.Refresh />
            </StyledGenerateSmartSummaryButtonContainer>
          </StyledTooltip>
        </Box>
      </FormProvider>
    </Box>
  );
};

const StyledSummaryTextarea = styled(FormTextAreaField)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(20)};

    .input-area {
      textarea {
        border-radius: ${theme.borderRadius.large};
        min-height: 130px;
        max-height: 380px;
      }

      .hint {
        color: ${theme.palette.text.error};
        opacity: 1;
      }
    }
  `
);

const StyledGenerateSmartSummaryButtonContainer = styled(Box)<{
  isError?: boolean;
  isDisabled?: boolean;
}>(
  ({ theme, isError, isDisabled }) => css`
    color: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
    border-radius: ${theme.borderRadius.full};
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.natural.white};

    cursor: pointer;

    ${isError &&
    css`
      color: ${theme.palette.error.dark};
      border-color: ${theme.palette.error.dark};
    `}

    ${isDisabled &&
    css`
      background-color: ${theme.palette.natural.inactiveBackground};
      border-color: ${theme.palette.natural.border};
      color: ${theme.palette.text.disabled};
      cursor: default;
    `}
  `
);

const StyledTooltip = styled(SimpleTooltip)`
  position: absolute;
  right: 12px;
  bottom: 29px;
`;

export default observer(CallLoggingSummary);
