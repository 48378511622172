import { FC } from 'react';

import { isEmpty } from 'lodash/fp';

import { useFormContext } from 'react-hook-form';

import { StyledNoPointerEventsContainer } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.shared';

import {
  CategoryQuestionOption,
  PathwayEditorFormFields,
  PathwayOptionAlert
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import { FormLabeledCheckbox } from 'components/UIkit/atoms/Checkbox';

interface Props {
  questionPath:
    | `categories.${number}.questions.${number}`
    | `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}.questions.${number}`;
  isQuestionSelected: boolean;
}

export const KeyQuestionCheckbox: FC<Props> = ({ isQuestionSelected, questionPath }) => {
  const { watch } = useFormContext<PathwayEditorFormFields>();
  let isDisabled = false;

  //this will work just for questions with options (single or multiple)
  // @ts-ignore
  const currentOptions = watch(`${questionPath}.options`) as CategoryQuestionOption[];

  if (!isEmpty(currentOptions)) {
    isDisabled = currentOptions.some(
      (option) =>
        option.alert.value === PathwayOptionAlert.CLINIC ||
        option.alert.value === PathwayOptionAlert.CLINIC_EMERGENT
    );
  }

  return (
    <StyledNoPointerEventsContainer ml={20} isSelected={isQuestionSelected}>
      <FormLabeledCheckbox
        label="Key Question"
        name={`${questionPath}.isKey`}
        disabled={!isQuestionSelected || isDisabled}
      />
    </StyledNoPointerEventsContainer>
  );
};
