import { styled } from '@mui/material/styles';
import capitalize from 'lodash/fp/capitalize';

import { Link } from 'react-router-dom';

import { ClinicianUserStatus } from 'models/Doctor';

import { PracticeUserRow } from 'views/Pages/PracticeManagement/ManagePracticeUsersPage/ManagePracticeUsersPage.types';

import { TableCellParams } from 'components/Table';
import { TextButton } from 'components/UIkit/atoms/Button';

interface UserStatusCellParams extends TableCellParams<PracticeUserRow> {
  activate: (id: number) => void;
  getIsActivating: (id: number) => boolean;
}

export const UserStatusCell = ({
  activate,
  getIsActivating,
  ...cellParams
}: UserStatusCellParams) => {
  const capitalizedValue = capitalize(cellParams.value);
  switch (cellParams.value) {
    case ClinicianUserStatus.ACTIVE:
      return <>{capitalizedValue}</>;
    case ClinicianUserStatus.INACTIVE:
      return (
        <UserStatusCellContainer>
          {capitalizedValue} •&nbsp;
          <TextButton
            variant="secondary"
            onClick={(event) => {
              event.stopPropagation();
              activate(cellParams.row.original.id);
            }}
            disabled={getIsActivating(cellParams.row.original.id)}
          >
            Activate
          </TextButton>
        </UserStatusCellContainer>
      );
    case ClinicianUserStatus.INVITED:
      return (
        <span>
          {capitalizedValue} •{' '}
          <Link to="/" className="link">
            Resend Invite
          </Link>
        </span>
      );

    default:
      console.warn('Unknown clinician user status');
      return <>Unknown</>;
  }
};

const UserStatusCellContainer = styled('div')`
  display: flex;
`;
