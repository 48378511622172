import { AxiosRequestConfig } from 'axios';

import { ErrorWithUi, HttpError } from 'errors';
import qs from 'qs';

export enum HTTP_METHODS {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}

const CRUD_METHODS = [HTTP_METHODS.GET, HTTP_METHODS.POST, HTTP_METHODS.PUT, HTTP_METHODS.DELETE];

export function isCrud(method: string) {
  method = method.toUpperCase();
  return CRUD_METHODS.includes(method as HTTP_METHODS);
}

type AxiosRequestParams = Pick<AxiosRequestConfig, 'url' | 'transformResponse' | 'method'>;

export interface RequestParams extends AxiosRequestParams {
  url: string; // make url required
  networkLabel?: string | string[];
  cancellationKey?: string;
  transformError?: (error: HttpError) => ErrorWithUi;
  overrideBaseURL?: string;
}

export type RequestConfig = Pick<RequestParams, 'cancellationKey' | 'networkLabel'>;

export interface RequestParamsWithoutMethod extends Omit<RequestParams, 'method'> {}

export type Query = Record<string | number, any>;

export interface GetParams extends RequestParamsWithoutMethod {
  query?: Query;
}

export interface RequestParamsWithData<Data = any> extends RequestParamsWithoutMethod {
  data?: Data;
}
// allow all possible params
export type AllRequestParams = RequestParams & GetParams & RequestParamsWithData;

export const getUrlWithQuery = (url: string, query: Query) => {
  const stringQuery = qs.stringify(query, { addQueryPrefix: true, skipNulls: true });

  return `${url}${stringQuery}`;
};

export const hasInternet = () => window.navigator.onLine;
