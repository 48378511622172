import { FC } from 'react';

import { styled, css } from '@mui/material/styles';

import { useFormContext, get } from 'react-hook-form';

import { Testable } from 'utils/TypeUtils';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

export type InputType = 'text' | 'number' | 'search' | 'email' | 'password';

interface Props extends Testable {
  name: string;
  error?: any;
  label: string;
  placeholder?: string | null;
  isRequired?: boolean;
  maxLength?: number;
  minLength?: number;
  validate?: (value: any) => boolean | string;
  type?: InputType;
  isRounded?: boolean;
}

export const FormInputField: FC<Props> = ({
  isRequired,
  maxLength,
  minLength,
  name,
  label,
  placeholder = 'Enter text...',
  validate,
  type = 'text',
  testHook
}) => {
  const { register, formState } = useFormContext();
  const error = Boolean(get(formState.errors, name));

  return (
    <StyledInput
      type={type}
      isRequired={isRequired}
      minLength={minLength}
      maxLength={maxLength}
      placeholder={placeholder}
      label={label}
      name={name}
      register={register}
      error={error}
      validate={validate}
      testHook={testHook}
    />
  );
};

const StyledInput = styled(RHFStyledInput)<{ label?: string }>(
  ({ theme, label }) =>
    !label &&
    css`
      & .input-area input,
      & .input-area textarea {
        border-radius: ${theme.borderRadius.large};
      }
    `
);
