import Episode from 'models/Episode';

import TaskTemplate from 'models/TaskTemplate';

export class EpisodesParser {
  static parseEpisodes(unparsedEpisodes: any) {
    return unparsedEpisodes.map((unparsedEpisode: any) => new Episode(unparsedEpisode));
  }

  static parseTaskTemplates(unparsedTaskTemplates: any) {
    return unparsedTaskTemplates.map(EpisodesParser.parseTaskTemplate);
  }

  static parseTaskTemplate(unparsedTaskTemplate: any) {
    return new TaskTemplate(unparsedTaskTemplate);
  }

  static parseEpisodesSearch(response: any) {
    return {
      episodes: EpisodesParser.parseEpisodes(response.episodes),
      taskTemplates: EpisodesParser.parseTaskTemplates(response.taskTemplates)
    };
  }
}
