import { FC, FocusEvent, useCallback, useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical';

import { TOOLBAR_IDS } from 'components/UIkit/atoms/RichText/RichText.constants';

interface Props {
  onFocus: (event: FocusEvent) => void;
  onBlur: (event: FocusEvent) => void;
}

export const OnFocusBlurListenerPlugin: FC<Props> = ({ onFocus, onBlur }) => {
  const [editor] = useLexicalComposerContext();

  const blurListener = useCallback(
    (event: FocusEvent) => {
      const elementId = (event.relatedTarget as HTMLButtonElement)?.id;
      if (TOOLBAR_IDS.includes(elementId)) {
        return false;
      }
      onBlur(event);
      return false;
    },
    [onBlur]
  );

  const focusListener = useCallback(
    (event: FocusEvent) => {
      onFocus(event);
      return false;
    },
    [onFocus]
  );

  useEffect(
    function registerBlur() {
      return editor.registerCommand<FocusEvent>(BLUR_COMMAND, blurListener, COMMAND_PRIORITY_LOW);
    },
    [blurListener, editor, onBlur]
  );

  useEffect(
    function registerFocus() {
      return editor.registerCommand<FocusEvent>(FOCUS_COMMAND, focusListener, COMMAND_PRIORITY_LOW);
    },
    [editor, focusListener, onFocus]
  );

  return null;
};
