import { createContext, FC, ReactNode, useContext } from 'react';

import { observer } from 'mobx-react';

import { useUnmount } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import Patient from 'models/Patient';

interface Props {
  children?: ReactNode;
}

type ContextType = {
  patient: Patient | null;
};

export const PatientContext = createContext<ContextType | null>(null);

export const PatientContextProvider: FC<Props> = observer(({ children }) => {
  const { patientPageStore } = useStores();

  useUnmount(function unloadPatient() {
    patientPageStore.resetStore();
  });

  return (
    <PatientContext.Provider
      value={{
        patient: patientPageStore.patient
      }}
    >
      {children}
    </PatientContext.Provider>
  );
});

export const usePatientContext = () => {
  const context = useContext(PatientContext);

  if (!context) {
    return null;
  }

  return context.patient;
};
