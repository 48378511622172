import { FC, ReactElement } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { TicketsCluster } from 'utils/TicketClusteringUtils';

import { FEATURES } from 'constants/features';

import Patient from 'models/Patient';

import TicketsBulkActionMenu from 'components/Ticket/BulkActionMenu/TicketsBulkActionMenu';

import TicketsSectionAssigned from './TicketsSectionAssigned';
import TicketsSectionCallback from './TicketsSectionCallback';
import TicketsSectionNonUrgent from './TicketsSectionNonUrgent';
import TicketsSectionOverdue from './TicketsSectionOverdue';
import TicketsSectionTasksDue from './TicketsSectionTasksDue';

import TicketsSectionUrgent from './TicketsSectionUrgent';

import './TicketsOverview.scss';

interface Props {
  ticketsCluster: TicketsCluster;
  overduePatients: Patient[];
  emptyState: ReactElement;
  showTasks?: boolean;
  showCallbackRequests?: boolean;
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
  showBulkMenu?: boolean;
}

export const NoTickets: FC = () => (
  <div className="tickets-empty-container" data-test-hook="noTickets">
    <p className="empty-title font-2xl font-weight-bold">No Results</p>
  </div>
);

const TicketsOverview: FC<Props> = ({
  ticketsCluster = {},
  overduePatients = [],
  showTasks = true,
  showCallbackRequests = true,
  emptyState,
  ticketRefsCallback,
  showBulkMenu = true
}) => {
  const { settingsStore } = useStores();

  const {
    assignedTickets = [],
    urgentSymptomsTickets = [],
    otherSymptomTickets = [],
    operatorTickets = [],
    tasks = [],
    callbackRequestTickets = []
  } = ticketsCluster;

  const noTickets =
    urgentSymptomsTickets.length === 0 &&
    otherSymptomTickets.length === 0 &&
    assignedTickets.length === 0 &&
    overduePatients.length === 0 &&
    operatorTickets.length === 0 &&
    callbackRequestTickets.length === 0 &&
    !showTasks;

  if (noTickets) {
    return emptyState;
  }

  const showTicketsAndCallbackRequests =
    (operatorTickets.length > 0 || (callbackRequestTickets.length > 0 && showCallbackRequests)) &&
    settingsStore.hasFeature(FEATURES.TICKETS_AND_CALLBACK_REQUESTS);

  const showUrgentSymptomReports =
    urgentSymptomsTickets.length > 0 && settingsStore.hasFeature(FEATURES.URGENT_PATIENT_REPORTS);
  const showTasksDue = showTasks && settingsStore.hasFeature(FEATURES.TASKS_DUE);
  const showOtherSymptomReports =
    otherSymptomTickets.length > 0 && settingsStore.hasFeature(FEATURES.OTHER_PATIENTS_REPORTS);
  const showOverdueReports =
    overduePatients.length > 0 && settingsStore.hasFeature(FEATURES.OVERDUE_REPORTS);

  return (
    <>
      {/* Assigned To Me */}
      {assignedTickets.length > 0 && (
        <TicketsSectionAssigned ticketRefsCallback={ticketRefsCallback} tickets={assignedTickets} />
      )}

      {/* Urgent Symptom Reports */}
      {showUrgentSymptomReports && (
        <TicketsSectionUrgent
          tickets={urgentSymptomsTickets}
          ticketRefsCallback={ticketRefsCallback}
        />
      )}

      {/* Tickets & Callback Requests */}
      {showTicketsAndCallbackRequests && (
        <TicketsSectionCallback
          ticketRefsCallback={ticketRefsCallback}
          operatorTickets={operatorTickets}
          callbackRequestTickets={callbackRequestTickets}
        />
      )}

      {/* Task Due - should depend on showTasks prop (filters regarding/disregarding tasks are present) */}
      {showTasksDue && <TicketsSectionTasksDue tasks={tasks} withFilters />}

      {/* Other Symptom Reports */}
      {showOtherSymptomReports && (
        <TicketsSectionNonUrgent
          ticketRefsCallback={ticketRefsCallback}
          tickets={otherSymptomTickets}
        />
      )}

      {/* Overdue Symptom Reports */}
      {showOverdueReports && <TicketsSectionOverdue overduePatients={overduePatients} />}
      {showBulkMenu && <TicketsBulkActionMenu />}
    </>
  );
};

export default observer(TicketsOverview);
