import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

export interface TagCreateAttributes {
  name: string;
  bgColor: string;
}

export interface TagUpdateAttributes {
  id: number;
  name: string;
  bgColor: string;
}

const httpService = new HttpService('Tag', true);

export class TagsFetcher {
  static createTag = async (data: TagCreateAttributes): Promise<number> =>
    await httpService.post({ url: API_URLS.PATIENT_TAGS, data });

  static updateTag = async (data: TagUpdateAttributes): Promise<void> =>
    await httpService.put({ url: API_URLS.UPDATE_TAG(data.id), data });

  static deleteTag = async (tagId: number): Promise<void> =>
    await httpService.delete({ url: API_URLS.DELETE_TAG(tagId) });
}
