import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';
import { SelectOption } from 'models/SelectOption';

import SearchBar from 'views/Dashboard/SearchBar';

import { MultiAutocomplete } from 'components/UIkit/atoms/Dropdown';

export const QuestionBankFilters = observer(() => {
  const { pathwayBuilderStore } = useStores();
  const {
    updateQuestionBankFilters,
    optionsForPathwayTypeSelect,
    optionsForPathwayNameSelect,
    questionBankFilters
  } = pathwayBuilderStore;

  return (
    <StyledContainer>
      <SearchBar
        placeholder="Name or UUID"
        searchValue={questionBankFilters.searchTerm}
        onSearchChanged={(value) => updateQuestionBankFilters({ searchTerm: value })}
      />
      <MultiAutocomplete
        label="Pathway"
        options={optionsForPathwayNameSelect}
        value={questionBankFilters.pathways}
        onChange={(pathwayIds) =>
          updateQuestionBankFilters({ pathways: pathwayIds as SelectOption<string>[] | null })
        }
      />
      <MultiAutocomplete
        label="Type"
        options={optionsForPathwayTypeSelect}
        value={questionBankFilters.types}
        onChange={(types) =>
          updateQuestionBankFilters({ types: types as SelectOption<PathwayQuestionTypes>[] | null })
        }
      />
    </StyledContainer>
  );
});

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    column-gap: ${theme.spacing(16)};

    > * {
      flex: 1;
    }
  `
);
