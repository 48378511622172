import { Box, css, styled } from '@mui/material';

import Icon from 'components/Icons/Icon';

export const StyledRemoveIcon = styled(Icon.XThin)`
  cursor: pointer;
`;

export const StyledVerticalSeparator = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'height'
})<{ height?: number }>(
  ({ theme, height }) => css`
    height: ${height || 24}px;
    gap: ${theme.spacing(8)};
    width: 1px;
    background-color: ${theme.palette.natural.border};
  `
);

export const StyledOptionCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'hasError' && prop !== 'disabled'
})<{ isSelected?: boolean; hasError?: boolean; disabled?: boolean }>(
  ({ theme, isSelected, hasError, disabled }) => css`
    width: 100%;
    border-radius: ${theme.borderRadius.medium};
    padding: ${isSelected ? 0 : theme.spacing(8)};
    background-color: ${theme.palette.natural.white};
    position: relative;
    border: 1px solid ${theme.palette.natural.border};
    overflow: hidden;

    ${isSelected &&
    !hasError &&
    !disabled &&
    css`
      border-color: ${theme.palette.primary.main};
      &:hover {
        border-color: ${theme.palette.primary.main};
      }
    `}

    ${hasError &&
    css`
      border-color: ${theme.palette.error.dark};
      &:hover {
        border-color: ${theme.palette.error.dark};
      }
    `}

    ${disabled &&
    css`
      border: none;
    `}
  `
);
