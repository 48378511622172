import { FC, useCallback } from 'react';

import { observer } from 'mobx-react';

import Ticket from 'models/Ticket';

import { useDoctorsAndAssignees } from 'hooks/useDoctor';

import StyledToggleButton from 'views/Widgets/StyledToggleButton';

import TicketReassignAction from 'components/Ticket/TicketRow/TicketReassignAction';

interface Props {
  connectedTicketIds: Set<number>;
  connectTicketToCall: (key: number) => void;
  disconnectTicketFromCall: (key: number) => void;
  ticket: Ticket;
  assignCallback: () => void;
}

const ConnectedTicketActions: FC<Props> = ({
  connectedTicketIds,
  connectTicketToCall,
  disconnectTicketFromCall,
  ticket,
  assignCallback
}) => {
  const { mainAssign, isCurrentDoctorAssigned, ticketDoctor, handleDoctorSelected } =
    useDoctorsAndAssignees(ticket);

  const onDoctorSelected = useCallback(
    async (clinicianId: number) => {
      await handleDoctorSelected(clinicianId);
      assignCallback();
    },
    [assignCallback, handleDoctorSelected]
  );

  if (!isCurrentDoctorAssigned && mainAssign) {
    return (
      <TicketReassignAction
        mainAssign={mainAssign}
        ticketDoctor={ticketDoctor}
        handleDoctorSelected={onDoctorSelected}
        ticketId={ticket?.id}
      />
    );
  }

  const onConnectToggle = () => {
    const id = ticket?.id;
    if (!id) {
      return;
    }
    connectedTicketIds.has(id) ? disconnectTicketFromCall(id) : connectTicketToCall(id);
  };

  const isConnected = connectedTicketIds.has(ticket?.id);

  return (
    <StyledToggleButton
      className="connected-toggle-container"
      label="Connect to Call"
      checked={isConnected}
      onChange={onConnectToggle}
    />
  );
};

export default observer(ConnectedTicketActions);
