import {
  ConditionType,
  ConditionTypeName,
  PopulationType,
  AlertUrgency
} from 'models/ClinicianAlert';
import ClinicianAlertCondition from 'models/Conditions/ClinicianAlertCondition';
import { SymptomTicketUrgencyText } from 'models/OperatorTicket';
import { CauseSeverity } from 'models/ReportedCause';

import { SelectOption } from 'models/SelectOption';

import { ExclusiveToggleOption } from 'components/UIkit/atoms/Button/Toggle/ExclusiveToggleButton';

export const urgencyOptions: SelectOption<AlertUrgency>[] = [
  {
    value: AlertUrgency.Alert,
    label: SymptomTicketUrgencyText.NurseReview,
    testHook: 'standard-option'
  },
  {
    value: AlertUrgency.UrgentAlert,
    label: SymptomTicketUrgencyText.AttentionToday
  },
  {
    value: AlertUrgency.CriticalAlert,
    label: SymptomTicketUrgencyText.ImmediateAttention
  }
];

export const populationTypeOptions: SelectOption<PopulationType>[] = [
  { value: PopulationType.All, label: 'All Patients', testHook: 'all-patients-option' },
  {
    value: PopulationType.SpecificPatients,
    label: 'Specific Patients'
  }
];

export const conditionTypeOptions: SelectOption<ConditionType>[] = [
  {
    value: ConditionType.AnyCause,
    label: ConditionTypeName.AnyCause,
    testHook: 'any-issues-or-above-threshold-option'
  },
  {
    value: ConditionType.SpecificCause,
    label: ConditionTypeName.SpecificCause
  },
  { value: ConditionType.Distress, label: ConditionTypeName.Distress }
];

export const severityOptions: ExclusiveToggleOption[] = [
  CauseSeverity.Mild,
  CauseSeverity.Moderate,
  CauseSeverity.Severe,
  CauseSeverity.Unbearable
].map((severity) => ({
  value: severity,
  label: ClinicianAlertCondition.getSeverityString(severity),
  testHook: ClinicianAlertCondition.getSeverityString(severity).toLowerCase()
}));
