import { FC } from 'react';

import { Box } from '@mui/material';

import { SearchedPatient } from 'fetchers/PatientsFetcher';

import { StyledSearchResultsContainer } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.shared';

import { SearchedPatientRow } from 'components/UIkit/atoms/AdvancedSearchBar/SearchedPatientRow';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  patients: SearchedPatient[];
  onPatientClicked: (
    patient: SearchedPatient,
    options?: { isRecentPatient?: boolean; isNewPatient?: boolean; isKeyboardSource?: boolean }
  ) => void;
  activeIndex: number;
  isAnimationDisabled: boolean;
}

export const RecentPatientsMenu: FC<Props> = ({
  patients,
  onPatientClicked,
  activeIndex,
  isAnimationDisabled
}) => (
  <StyledSearchResultsContainer className="header-search-results">
    <Box ml={8} my={8}>
      <Text variant="tab" color="disabled">
        Recently Viewed
      </Text>
    </Box>

    {patients.map((recentPatient, index) => (
      <SearchedPatientRow
        key={recentPatient.patientId || recentPatient.emrPatientId}
        patient={recentPatient}
        onPatientClicked={(patient: SearchedPatient) =>
          onPatientClicked(patient, { isRecentPatient: true })
        }
        isActive={index === activeIndex}
        isAnimationDisabled={isAnimationDisabled}
      />
    ))}
  </StyledSearchResultsContainer>
);
