// @ts-strict-ignore
import { sortBy, orderBy } from 'lodash/fp';

import { isSubStringCaseInsensitive } from 'utils/StringUtils';

import { TicketTypeNode } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeNode';
import { TicketTypeTree } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeTree';

export type TicketSubTypeOption = {
  label: string;
  labelFull?: string;
  parentId: number;
  parentName: string;
  parentKind?: string;
  value: string;
};

export interface ITicketTypeSelectOptions {
  id?: number;
  label: string;
  kind: string;
  options: TicketSubTypeOption[];
}

export type TicketTypeFilterFn = (node: TicketTypeNode) => boolean;

// Note: filter is from up to bottom
// if filterFn return true for a node but not for it's parent - it will not be included in the results
export function filterTypeTreeRecursively(
  nodes: TicketTypeNode[],
  parent: TicketTypeNode | null,
  filterFn: TicketTypeFilterFn
): TicketTypeNode[] {
  const filteredNodes: TicketTypeNode[] = [];

  nodes.forEach((node: TicketTypeNode) => {
    if (filterFn(node)) {
      const newNode = TicketTypeNode.createFromNode(node);
      newNode.parent = parent;
      newNode.children = filterTypeTreeRecursively(node.children, node, filterFn);
      filteredNodes.push(newNode);
    }
  });
  return filteredNodes;
}

export function treeScan(node: TicketTypeNode, scanFn: (node: TicketTypeNode) => any) {
  scanFn(node);
  node.children.forEach((child) => treeScan(child, scanFn));
}

export function getGroupedTicketTypesForSelect(
  ticketTypesTree: TicketTypeTree,
  filterFn: TicketTypeFilterFn,
  showFullNameForNonSymptomTicketTypes: boolean
): ITicketTypeSelectOptions[] {
  const res: ITicketTypeSelectOptions[] = ticketTypesTree.categories.map(
    (category: TicketTypeNode) => {
      const options: TicketSubTypeOption[] = [];

      if (!filterFn(category)) {
        return {
          id: category.id,
          label: category.name,
          kind: category.kind,
          options: []
        };
      }

      category.children.forEach((ticketType: TicketTypeNode) => {
        if (filterFn(ticketType)) {
          const showFullName =
            showFullNameForNonSymptomTicketTypes && ticketType.kind !== 'symptom';

          options.push({
            label: ticketType.name,
            labelFull: showFullName ? ticketType.fullName : ticketType.typeName,
            parentId: ticketType.parent.id,
            parentName: ticketType.parent.name,
            parentKind: ticketType.parent.kind,
            value: ticketType.id.toString()
          });
        }

        ticketType.children.forEach((ticketSubType) => {
          if (filterFn(ticketSubType)) {
            options.push({
              label: ticketSubType.typeName,
              labelFull: ticketSubType.fullName,
              parentId: ticketSubType.parent.id,
              parentName: ticketSubType.parent.name,
              parentKind: ticketSubType.parent.kind,
              value: ticketSubType.id.toString()
            });
          }
        });
      });

      return {
        id: category.id,
        label: category.name,
        kind: category.kind,
        options
      };
    }
  );

  // Sort sub options and keep other at the end!
  res.forEach((option: ITicketTypeSelectOptions) => {
    option.options = sortBy(
      (option: TicketSubTypeOption) => (option.label.toLowerCase() === 'other' ? 1 : 0),
      orderBy(['label'], ['asc'], option.options)
    );
  });
  return res;
}

export const INSTRUCTIONS_TEXT_AREA_MAX_LENGTH = 500;
export const NO_CONTENT_TEXT = 'None';
export const SERVER_ERROR = 'server';
export const HIDDEN_ERROR_MESSAGE_TEXT = 'Error Message';
export const DISPLAY_NAME_MAX_LENGTH = 65;

export const getFilterFn = (searchTerm: string) => {
  return (node: TicketTypeNode) => {
    const isCategory = node.parent === null;
    const isSubType = Boolean(node?.parent?.parent);
    const matchFilter = isSubStringCaseInsensitive(
      isSubType ? `${node?.parent?.name} - ${node.name}` : node.name,
      searchTerm
    );

    const isChildMatchFilter = Boolean(
      node.children.find(
        (child) =>
          isSubStringCaseInsensitive(`${child?.parent?.name} - ${child.name}`, searchTerm) &&
          !child.isDeleted
      )
    );

    return isCategory || matchFilter || isChildMatchFilter;
  };
};

export const getFilteredCategories = (ticketTypesTree: TicketTypeTree, searchTerm: string) => {
  // If user searches for a ticket with sub-types, only show sub-types that match the query.
  // If query matches a sub-type, show the parent type, even if search doesn’t match it
  const filterFn = getFilterFn(searchTerm);
  const categories = filterTypeTreeRecursively(ticketTypesTree.operatorCategories, null, filterFn);
  return categories.filter((category) => category.children.length > 0);
};
