import { FC } from 'react';

import { Box } from '@mui/material';

import { UseFieldArrayUpdate } from 'react-hook-form';

import {
  CategoryQuestionOption,
  MultipleQuestionFormField,
  PathwayEditorFormFields,
  QuestionOptionSuggestedPathway,
  SingleQuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import { PathwayQuestionOptionSuggestedSelect } from 'views/Pages/PathwayBuilder/PathwayEditorView/SuggestedPathway/PathwayQuestionOptionSuggestedSelect';
import { PathwayQuestionOptionSuggestedUrgency } from 'views/Pages/PathwayBuilder/PathwayEditorView/SuggestedPathway/PathwayQuestionOptionSuggestedUrgency';

interface Props {
  option: CategoryQuestionOption;
  question: MultipleQuestionFormField | SingleQuestionFormField;
  questionIndex: number;
  updateQuestion: UseFieldArrayUpdate<PathwayEditorFormFields, `categories.${number}.questions`>;
  isQuestionSelected: boolean;
}

export const PathwayQuestionOptionSuggestedPathway: FC<Props> = ({
  question,
  updateQuestion,
  questionIndex,
  isQuestionSelected,
  option
}) => {
  const selectOption = (
    optionId: string,
    optionSuggestedPathway: QuestionOptionSuggestedPathway
  ) => {
    const newOptions = [...question.options];
    const option = newOptions.find((option) => option.optionId === optionId)!;
    option.suggestedPathway = optionSuggestedPathway;

    updateQuestion(questionIndex, {
      ...question,
      options: newOptions
    });
  };

  if (isQuestionSelected) {
    return <PathwayQuestionOptionSuggestedSelect option={option} selectOption={selectOption} />;
  }

  if (Boolean(option.suggestedPathway.pathway.value)) {
    return (
      <Box width="256px" display="flex" justifyContent="flex-end">
        <PathwayQuestionOptionSuggestedUrgency
          urgency={option.suggestedPathway.urgency}
          pathwayName={option.suggestedPathway.pathway.label}
        />
      </Box>
    );
  }

  return null;
};
