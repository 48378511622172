import { FC } from 'react';

import { Typography } from '@mui/material';

import { ButtonProps, StyledFilled } from 'components/UIkit/atoms/Button/StyledButtons';
import { withSpacing } from 'components/UIkit/theme/spacing';
interface Props extends ButtonProps {
  fullWidth?: boolean;
}

const Filled: FC<Props> = ({ onClick, disabled, fullWidth = false, testHook, children, id }) => (
  <StyledFilled
    onClick={onClick}
    disabled={disabled}
    fullWidth={fullWidth}
    data-test-hook={testHook}
    id={id}
  >
    <Typography variant="button-medium">{children}</Typography>
  </StyledFilled>
);

export const FilledButton = withSpacing(Filled);
