import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Select;

type Value = 'list item' | 'unassign';

interface Properties {
  action: Action;
  value: Value;
  ticket_ids: number[];
  item_index?: number;
  page_number?: number;
  bulk_action?: boolean;
}

export const trackReassignMenuAnalyticsEvent = ({
  action,
  value,
  ticket_ids,
  bulk_action = false,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.ReassignMenu,
    properties: { action, value, ticket_ids, bulk_action, ...optionalProps }
  });
};
