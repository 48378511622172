import { FC } from 'react';

import { styled } from '@mui/material/styles';

import { observer } from 'mobx-react';

import { useFormContext } from 'react-hook-form';

import { timeOptions } from 'utils/DateUtils';

import RFHWeekdaySelector from 'views/Widgets/RFHWeekdaySelector';
import { RHFStyledInput } from 'views/Widgets/StyledInput';
import { intervalOptions } from 'views/Widgets/SymptomProtocolFields';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';

import './ProtocolIntervalFields.scss';

interface ProtocolIntervalFieldsProps {
  showDaySelector: boolean;
  forceWeekly?: boolean;
  forceSingleDaySelection?: boolean;
}

const ProtocolIntervalFields: FC<ProtocolIntervalFieldsProps> = (
  props: ProtocolIntervalFieldsProps
) => {
  const methods = useFormContext();

  return (
    <Container>
      <div className="frequency-fields-container block">
        <span>Report every</span>
        {!props.forceWeekly && (
          <div className="frequency-multiplier-container">
            <RHFStyledInput
              type="number"
              min={1}
              rounded
              isRequired
              error={Boolean(methods.formState.errors.frequencyMultiplier)}
              name="frequencyMultiplier"
              register={methods.register}
            />
          </div>
        )}
        <div className="frequency-container">
          <FormAutocomplete
            sortAlphabetically={false}
            isClearable={false}
            isDisabled={props.forceWeekly}
            options={intervalOptions}
            name="selectedFrequency"
            isRequired
          />
        </div>
        <div>at</div>
        <div className="start-time-container">
          <FormAutocomplete
            sortAlphabetically={false}
            isClearable={false}
            options={timeOptions}
            name="selectedStartHour"
          />
        </div>
      </div>
      {props.showDaySelector && (
        <RFHWeekdaySelector
          control={methods.control}
          error={Boolean(methods.formState.errors.weekdays)}
          className="block"
          name="weekdays"
          forceSingleDaySelection={props.forceSingleDaySelection}
        />
      )}
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export default observer(ProtocolIntervalFields);
