import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Select;
type Value = '14 Days' | '30 days' | 'All Reports';

interface Properties {
  action: Action;
  value: Value;
}

export const trackEPROTimeBucketsAnalyticsEvent = ({ action, value }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.EPROPTimeBuckets,
    properties: { action, value }
  });
};
