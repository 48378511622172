import { FC, Fragment } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';

import { observer } from 'mobx-react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useStores } from 'mobx/hooks/useStores';

import { useWqOpenItemsSection } from 'hooks/useWqOpenItemsSection';

import { WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

import { WorkQueueItemType, WorkQueueSectionName } from 'views/WorkQueue/WorkQueue.types';

import WqCollapsibleSection from 'views/WorkQueue/WorkQueueOpenItemsView/WqCollapsibleSection';

import { WqSectionControl } from 'views/WorkQueue/WorkQueueOpenItemsView/WqSectionControl';

import { WqTicketPlaceholder } from 'views/WorkQueue/WqTicketPlaceholder';

import { CardRow } from 'components/Surfaces/Card';
import CallbackRequestTicketRow from 'components/Ticket/TicketRow/CallbackRequestTicketRow';
import OperatorTicketRow from 'components/Ticket/TicketRow/OperatorTicketRow';
import SymptomOperatorTicketRow from 'components/Ticket/TicketRow/SymptomOperatorTicketRow';
import TaskTicketRow from 'components/Ticket/TicketRow/TaskTicketRow';
import TicketReportRow from 'components/Ticket/TicketRow/TicketReportRow';

import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';

const WqAssignedToMeSection: FC = () => {
  const { workQueueStore } = useStores();
  const { items, totalItems, totalPages, currentPage, isSectionPageLoading } =
    useWqOpenItemsSection(WorkQueueSectionName.AssignedToMe);

  return (
    <div className="report-section highlight">
      <WqCollapsibleSection
        sectionName={Section.Assigned}
        totalItems={totalItems}
        onTrigger={(isOpen) =>
          trackSectionUsageAnalyticsEvent({
            action: isOpen ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
            value: Section.Assigned,
            page_number: currentPage,
            tab: WorkQueueTabName.OpenItems
          })
        }
        testHook="assigned-to-me-section"
      >
        {totalPages > 1 && <WqSectionControl sectionName={WorkQueueSectionName.AssignedToMe} />}

        <div className="tickets-list">
          <TransitionGroup>
            {items.map(({ itemType, itemData }, index) => {
              const patient = workQueueStore.getPatientModel(itemData.patientId);

              return (
                <CSSTransition
                  key={itemData.id}
                  classNames="connect-ticket-transition"
                  timeout={600}
                >
                  <Fragment>
                    {isSectionPageLoading ? (
                      <WqTicketPlaceholder />
                    ) : (
                      <>
                        {itemType === WorkQueueItemType.OperatorTicket &&
                          !itemData.isSymptomOperatorTicket && (
                            <OperatorTicketRow
                              ticket={itemData}
                              withPatientLink
                              ticketIndex={index}
                              ticketSectionCurrentPage={currentPage - 1}
                            />
                          )}

                        {itemType === WorkQueueItemType.OperatorTicket &&
                          itemData.isSymptomOperatorTicket && (
                            <SymptomOperatorTicketRow
                              ticket={itemData}
                              withPatientLink
                              ticketIndex={index}
                              ticketSectionCurrentPage={currentPage - 1}
                            />
                          )}

                        {itemType === WorkQueueItemType.PatientTicket && (
                          <TicketReportRow
                            ticket={itemData}
                            reports={patient!.reportsSortedBySeverity}
                            withPatientLink
                            ticketIndex={index}
                            ticketSectionCurrentPage={currentPage - 1}
                          />
                        )}

                        {itemType === WorkQueueItemType.TaskTicket && (
                          <CardRow>
                            <TaskTicketRow
                              ticket={itemData}
                              withPatientLink
                              ticketIndex={index}
                              ticketSectionCurrentPage={currentPage - 1}
                            />
                          </CardRow>
                        )}

                        {itemType === WorkQueueItemType.CallbackRequestTicket && (
                          <CallbackRequestTicketRow
                            ticket={itemData}
                            withPatientLink
                            ticketIndex={index}
                            ticketSectionCurrentPage={currentPage - 1}
                          />
                        )}
                      </>
                    )}
                  </Fragment>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </div>

        {totalPages > 1 && (
          <WqSectionControl sectionName={WorkQueueSectionName.AssignedToMe} isBottom />
        )}
      </WqCollapsibleSection>
    </div>
  );
};

export default observer(WqAssignedToMeSection);
