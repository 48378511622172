import {
  ATTENTION_TODAY,
  IMMEDIATE_ATTENTION,
  NO_WARNING
} from 'views/Patient/PatientMain/PathwayQuestionView';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

export type PathwayAnswerEventAction =
  | AnalyticEventAction.Add
  | AnalyticEventAction.Remove
  | AnalyticEventAction.Update;

export type PathwayAnswerEventType =
  | 'open text'
  | 'text array'
  | 'date'
  | 'multiselection'
  | 'radio';

type Tab = 'Key Questions' | 'All Questions' | 'lightweight questions';

export type PathwayAnswerAlertLevel =
  | typeof NO_WARNING
  | typeof ATTENTION_TODAY
  | typeof IMMEDIATE_ATTENTION;

interface Properties {
  action: PathwayAnswerEventAction;
  virtual_page: 'pathway view' | 'create ticket' | 'edit ticket';
  tab: Tab;
  value: string;
  type: PathwayAnswerEventType;
  alert_level: PathwayAnswerAlertLevel;
}

export const trackPathwayAnswerAnalyticsEvent = ({
  action,
  value,
  virtual_page,
  tab,
  type,
  alert_level
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.PathwayAnswer,
    properties: { action, value, virtual_page, tab, type, alert_level }
  });
};
