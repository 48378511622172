// @ts-strict-ignore
import { FC, useRef } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackStatusMenuAnalyticsEvent } from 'analytics/events/status-menu';

import classNames from 'classnames';

import { useHistory } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { CmStatusEnum } from 'fetchers/responses/care-management.response';

import { FEATURES } from 'constants/features';

import { CmPatient, CmStatus } from 'models/CmPatient';

import TextButton from 'components/Buttons/TextButton';
import Icon from 'components/Icons/Icon';
import { DividerPlacement, ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';

import './EnrollmentStatusDropDown.scss';

interface CmEnrollmentDropDownHandlers {
  checkIfCanMarkEligible: (
    patient: CmPatient,
    eligibilityStatus: CmStatusEnum.EligibleForCcm | CmStatusEnum.EligibleForPcm
  ) => void;
  checkIfCanMarkIneligible: (patient: CmPatient) => void;
  checkIfCanOptOut: (patient: CmPatient) => void;
  checkIfCanEnroll: (patient: CmPatient, episodeId: number) => void;
  handlePatientClick: (patient: CmPatient) => Promise<void>;
  checkIfCanRemoveEnroll: (patient: CmPatient) => void;
}
interface Props {
  patient: CmPatient;
  handlers: CmEnrollmentDropDownHandlers;
}

export const EnrollmentStatusDropDown: FC<Props> = ({ patient, handlers }) => {
  const { cmStatus } = patient;
  const tooltipRef = useRef();
  const { careManagementReportStore, settingsStore } = useStores();
  const { cmStatuses } = careManagementReportStore;
  const history = useHistory();
  const isCareManagementPage = history.location.pathname.includes('/care-management');
  const hasCmSuggestedFeature = settingsStore.hasFeature(FEATURES.CM_SUGGESTED_STATUSES);

  const optOutOption = {
    id: 'optOutCm',
    text: (
      <>
        <div>Opt Out of Care Management</div>
        {cmStatus.reason && cmStatus.isOptedOut && (
          <>
            <div className="option-subtitle">{cmStatus.reasonText}</div>
            {cmStatus.notes && <div className="option-subtitle">{cmStatus.notes}</div>}
          </>
        )}
      </>
    ),
    disabled: false,
    isSelected: cmStatus.isOptedOut,
    onClick: () => {
      trackStatusMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        patient_id: patient.patientId,
        value: 'Opt Out of Care Management'
      });

      if (!cmStatus.isOptedOut) {
        handlers.checkIfCanOptOut(patient);
      }
    }
  };

  const notEnrolledOption = {
    id: 'notEnrolled',
    text: 'Not Enrolled',
    disabled: false,
    isSelected: cmStatus.isNone,
    onClick: () => {
      trackStatusMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        patient_id: patient.patientId,
        value: 'Not Enrolled'
      });

      if (!cmStatus.isNone) {
        handlers.checkIfCanRemoveEnroll(patient);
      }
    }
  };

  const markAsIneligibleOption = {
    id: 'markIneligible',
    text: (
      <>
        <div>Ineligible</div>
        {cmStatus.reason && cmStatus.isIneligible && (
          <>
            <div className="option-subtitle">{cmStatus.reasonText}</div>
            {cmStatus.notes && <div className="option-subtitle">{cmStatus.notes}</div>}
          </>
        )}
      </>
    ),
    disabled: false,
    isSelected: cmStatus.isIneligible,
    onClick: () => {
      trackStatusMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        patient_id: patient.patientId,
        value: 'Ineligible'
      });

      if (!cmStatus.isIneligible) {
        handlers.checkIfCanMarkIneligible(patient);
      }
    }
  };

  const manageEpisodesOption = {
    id: 'manageEpisodes',
    text: 'Manage Active Episode...',
    withDivider: cmStatus.isActive,
    dividerPlacement: DividerPlacement.Bottom,
    onClick: isCareManagementPage
      ? () => {
          trackStatusMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            patient_id: patient.patientId,
            value: 'Manage Active Episode'
          });
          handlers.handlePatientClick(patient);
        }
      : null,
    disabled: !isCareManagementPage
  };

  let enrollOptions: ITooltipOption[] = cmStatuses.items
    .filter((status) => !CmStatus.NonEpisodeIds.includes(status.id))
    .filter((episodeId) => !episodeId.isDeleted)
    .map((status, index) => ({
      id: `${status.id}_${index}`,
      text: `Enroll: ${status.name}`,
      disabled: false,
      isSelected: status.id === cmStatus.episodeId && cmStatus.isActive,
      withDivider: index === 0 && !cmStatus.isEndedManually,
      dividerPlacement: DividerPlacement.Top,
      onClick: () => {
        trackStatusMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          patient_id: patient.patientId,
          value: `Enroll: ${status.name}`
        });

        if (status.id !== cmStatus.episodeId || !cmStatus.isActive) {
          handlers.checkIfCanEnroll(patient, status.id);
        }
      }
    }));

  if (cmStatus.isEndedManually && cmStatus.reason) {
    const episodeName = careManagementReportStore.episodeName(cmStatus);
    enrollOptions = [
      {
        id: 'endedManuallyReason',
        isSelected: true,
        disabled: true,
        text: (
          <div className="d-flex flex-column">
            <span>
              {episodeName} {cmStatus.reasonText && 'End Reason: '}
            </span>
            {cmStatus.reasonText && <span className="option-subtitle">{cmStatus.reasonText}</span>}
            {cmStatus.notes && <span className="option-subtitle">{cmStatus.notes}</span>}
          </div>
        ),
        withDivider: true,
        dividerPlacement: DividerPlacement.Top
      },
      ...enrollOptions
    ];
  }

  const confirmEligibilityOptions = [
    {
      id: 'confirmEligibilityCcm',
      text: 'Eligible: CCM',
      disabled: false,
      isSelected: cmStatus.isEligibleForCcm,
      extraText:
        cmStatus.status === CmStatusEnum.SuggestedCcm && hasCmSuggestedFeature ? 'Suggested' : '',
      onClick: () => {
        trackStatusMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          patient_id: patient.patientId,
          value: 'Eligible: CCM'
        });

        if (!cmStatus.isEligibleForCcm) {
          handlers.checkIfCanMarkEligible(patient, CmStatusEnum.EligibleForCcm);
        }
      }
    },
    {
      id: 'confirmEligibilityPcm',
      text: 'Eligible: PCM',
      disabled: false,
      isSelected: cmStatus.isEligibleForPcm,
      extraText:
        cmStatus.status === CmStatusEnum.SuggestedPcm && hasCmSuggestedFeature ? 'Suggested' : '',
      onClick: () => {
        trackStatusMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          patient_id: patient.patientId,
          value: 'Eligible: PCM'
        });

        if (!cmStatus.isEligibleForPcm) {
          handlers.checkIfCanMarkEligible(patient, CmStatusEnum.EligibleForPcm);
        }
      }
    }
  ];

  //No PHI, IE, No medical information: no symptoms (sx), diagnoses (dx), drugs (rx), administrations (tx), etc
  //because we track mixpanel event on select item
  let options = [
    notEnrolledOption,
    markAsIneligibleOption,
    ...confirmEligibilityOptions,
    optOutOption,
    ...enrollOptions
  ];

  if (cmStatus.isActive) {
    options = [manageEpisodesOption, optOutOption];
  }

  if (cmStatus.isOptedOut) {
    options = [notEnrolledOption, markAsIneligibleOption, optOutOption, ...enrollOptions];
  }

  const { statusText, extraText } = careManagementReportStore.statusText(cmStatus);

  const textButtonClasses = classNames(
    'enrollment-status-button',
    'd-flex',
    'justify-content-end',
    {
      dark: !cmStatus.isNone && !cmStatus.hasSuggestions,
      selected: !cmStatus.isNone && !cmStatus.hasSuggestions
    }
  );

  return (
    <>
      <TextButton
        className={textButtonClasses}
        title={statusText}
        ref={tooltipRef}
        onClick={(e) => {
          e.stopPropagation();
          trackStatusMenuAnalyticsEvent({
            action: AnalyticEventAction.Click,
            patient_id: patient.patientId
          });
        }}
      >
        <div className="d-flex flex-column overflow-hidden">
          <span className="status text-truncate">{statusText}</span>
          {extraText && (
            <span
              className={classNames('extra', {
                light: cmStatus.hasSuggestions
              })}
            >
              {extraText}
            </span>
          )}
        </div>
        <div className="icon-wrapper">
          <Icon.Chevron />
        </div>
      </TextButton>

      <Tooltip placement="bottom-end" trigger={TooltipTrigger.CLICK} reference={tooltipRef}>
        <TooltipSelect options={options} />
      </Tooltip>
    </>
  );
};
