import { MutableRefObject, RefObject, useRef, useState } from 'react';

import { DragControls, useDragControls } from 'framer-motion';

import { useFormContext } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { useClickAway, useMount, useUpdateEffect } from 'react-use';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { PATHWAY_BUILDER_SELECTED_QUESTION_ID_QUERY_PARAM } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.constants';

import {
  PATHWAY_ADD_QUESTION_BUTTON_ID,
  PATHWAY_ADD_SUB_QUESTION_BUTTON_ID,
  PATHWAY_QUESTION_OPTION_ALERTS_SELECT,
  PATHWAY_QUESTION_OPTION_SUGGESTED_SELECT,
  PATHWAY_QUESTION_OPTION_SUGGESTED_URGENCY_SELECT,
  REMOVE_PATHWAY_QUESTION_POPUP_ID
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.constants';
import {
  MultipleQuestionFormField,
  PathwayEditorFormFields,
  SingleQuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import { getPathwayContainerBlockActionsBar } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';

interface UsePathwayEditorQuestionReturnValue {
  isSelected: boolean;
  controls: DragControls;
  ref: MutableRefObject<null>;
  showMainQuestionButtons: boolean;
  showSubQuestionButtons: boolean;
  closeRemoveQuestionPopup: () => void;
  isRemoveQuestionPopupOpen: boolean;
  onRemoveQuestion: () => void;
  isLastQuestion: boolean;
}

export const usePathwayEditorQuestion = (
  path:
    | `categories.${number}.questions.${number}`
    | `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}.questions.${number}`,
  index: number,
  isSub: boolean,
  totalQuestions: number,
  containerUuid?: string,
  containerRef?: RefObject<HTMLDivElement>
): UsePathwayEditorQuestionReturnValue => {
  const { trigger, formState, watch } = useFormContext<PathwayEditorFormFields>();
  const question = watch(path);

  const { uuid, questionId } = question;

  const [isRemoveQuestionPopupOpen, setIsRemoveQuestionPopupOpen] = useState(false);
  const controls = useDragControls();
  const questionRef = useRef(null);
  const {
    isSelected,
    resetSelectedPathwayEditorBlock,
    selectPathwayEditorBlock,
    selectedPathwayEditorBlockUuid
  } = usePathwayEditorSelection(uuid);

  let isChildBlockSelected = false;

  if (
    question.type === PathwayQuestionTypes.SINGLE ||
    question.type === PathwayQuestionTypes.MULTIPLE
  ) {
    isChildBlockSelected = Boolean(
      (
        question as MultipleQuestionFormField | SingleQuestionFormField
      ).dependentQuestionsBlocks?.find(
        (dependentQuestionsBlock) => dependentQuestionsBlock.uuid === selectedPathwayEditorBlockUuid
      ) ||
        (
          question as MultipleQuestionFormField | SingleQuestionFormField
        ).dependentQuestionsBlocks?.find((dependentQuestionsBlock) =>
          dependentQuestionsBlock.questions?.find(
            (subQuestion) => subQuestion.uuid === selectedPathwayEditorBlockUuid
          )
        )
    );
  }

  const isLastQuestion = index === totalQuestions - 1;
  const showMainQuestionButtons = !isSub && (isSelected || isLastQuestion || isChildBlockSelected);
  const showSubQuestionButtons = isSub && isLastQuestion;
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const initialSelectedQuestionId = searchParams.get(
    PATHWAY_BUILDER_SELECTED_QUESTION_ID_QUERY_PARAM
  );

  useClickAway(questionRef, (event) => {
    if (!isSelected) {
      return;
    }

    let shouldAllowClick = false;

    const questionOptionAlertsSelect = document.getElementById(
      PATHWAY_QUESTION_OPTION_ALERTS_SELECT
    ) as HTMLElement;

    const questionOptionSuggestedSelect = document.getElementById(
      PATHWAY_QUESTION_OPTION_SUGGESTED_SELECT
    ) as HTMLElement;

    const questionOptionSuggestedUrgencySelect = document.getElementById(
      PATHWAY_QUESTION_OPTION_SUGGESTED_URGENCY_SELECT
    ) as HTMLElement;

    const removeQuestionPopup = document.getElementById(
      `${REMOVE_PATHWAY_QUESTION_POPUP_ID}-${uuid}`
    ) as HTMLElement;

    const clickedElement = event.target as HTMLElement;
    const clickedParentElement = clickedElement.parentElement as HTMLElement;

    //all these elements are outside the question card so when we click them we don't want to treat it as a "click outside"
    if (
      questionOptionAlertsSelect?.contains(clickedElement) ||
      questionOptionSuggestedSelect?.contains(clickedElement) ||
      questionOptionSuggestedUrgencySelect?.contains(clickedElement) ||
      removeQuestionPopup?.contains(clickedElement) ||
      clickedElement.id === PATHWAY_ADD_QUESTION_BUTTON_ID ||
      clickedParentElement?.id === PATHWAY_ADD_QUESTION_BUTTON_ID ||
      clickedElement.id === PATHWAY_ADD_SUB_QUESTION_BUTTON_ID ||
      clickedParentElement?.id === PATHWAY_ADD_SUB_QUESTION_BUTTON_ID
    ) {
      shouldAllowClick = true;
    }

    //cases just for sub questions
    if (isSub && containerUuid && !shouldAllowClick) {
      //when sub question is selected (also its container - the dependent questions block is selected) and the user clicks on the container's actions bar
      const actionsBar = document.getElementById(
        getPathwayContainerBlockActionsBar(containerUuid)
      ) as HTMLElement;

      //when sub question is selected (also its container - the dependent questions block is selected) and the user clicks on the remove container popup
      const removeContainerBlockPopup = document.getElementById(
        `${REMOVE_PATHWAY_QUESTION_POPUP_ID}-${containerUuid}`
      ) as HTMLElement;

      if (
        actionsBar?.contains(clickedElement) ||
        removeContainerBlockPopup?.contains(clickedElement) ||
        containerRef?.current?.contains(clickedElement)
      ) {
        shouldAllowClick = true;
      }
    }

    if (!shouldAllowClick) {
      trigger(path);
      trigger(`${path as `categories.${number}.questions.${number}`}.dependentQuestionsBlocks`);
      resetSelectedPathwayEditorBlock();

      //if we click outside the sub question but inside the dependent questions block (container block)
      //we want to make the container selected
      if (clickedElement.id === containerUuid) {
        selectPathwayEditorBlock(containerUuid);
      }
    }
  });

  useMount(function scrollToQuestion() {
    if (initialSelectedQuestionId === questionId && formState.submitCount === 0) {
      selectPathwayEditorBlock(uuid);

      if (questionRef.current) {
        (questionRef.current as HTMLElement).scrollIntoView({ block: 'center' });
      }
    }
  });

  useUpdateEffect(
    //after dragging or removing question
    function handleQuestionPositionChanged() {
      trigger(`${path}.title`);
      trigger(`${path as `categories.${number}.questions.${number}`}.dependentQuestionsBlocks`);
    },
    [index, path, trigger]
  );

  const closeRemoveQuestionPopup = () => setIsRemoveQuestionPopupOpen(false);

  const onRemoveQuestion = () => setIsRemoveQuestionPopupOpen(true);

  return {
    isSelected,
    controls,
    ref: questionRef,
    showMainQuestionButtons,
    showSubQuestionButtons,
    closeRemoveQuestionPopup,
    isRemoveQuestionPopupOpen,
    onRemoveQuestion,
    isLastQuestion
  };
};
