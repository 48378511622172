// @ts-strict-ignore

import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';

import { AnalyticEventAction } from 'analytics';

import { Dialogs } from 'analytics/events/dialog';
import { trackEditTicketModalAnalyticsEvent } from 'analytics/events/edit-ticket-modal';

import { useStores } from 'mobx/hooks/useStores';

import Patient from 'models/Patient';
import { ProtocolName, ProtocolType } from 'models/ScheduledProtocol';
import Ticket from 'models/Ticket';

import { useToggle } from 'hooks/useToggle';

import EditTaskModal from 'views/Modals/EditTaskModal';
import EditTicketModal from 'views/Modals/EditTicketModal';
import SendSmsPersonalRequest from 'views/Modals/SendSmsPersonalRequest';

import { ResolveAndCommentModal } from 'components/Ticket/ResolveAndCommentModal';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';

type UseEditModalReturns = [ReactElement, (patient: Patient, ticket?: Ticket) => void];

export function useEditTaskModal({
  onEditTask,
  onDeleteTask
}: {
  onEditTask: (...args: any) => any;
  onDeleteTask: (...args: any) => any;
}): UseEditModalReturns {
  const [isEditTaskModalOpened, setIsEditTaskModalOpened] = useState(false);
  const [patient, setPatient] = useState<Patient>();
  const [ticket, setTicket] = useState<Ticket>();

  const openEditTaskTicketModal = (patient: Patient, ticket?: Ticket) => {
    setIsEditTaskModalOpened(true);
    setPatient(patient);
    if (ticket) {
      setTicket(ticket);
    }
  };

  const closeEditTaskTicketModal = () => {
    setIsEditTaskModalOpened(false);
  };

  const modal = (
    <EditTaskModal
      onDeleteTask={onDeleteTask}
      onEditTask={onEditTask}
      isOpen={isEditTaskModalOpened}
      onCancel={closeEditTaskTicketModal}
      onSubmit={closeEditTaskTicketModal}
      resetDataAfterClose={() => {
        setPatient(null);
        setTicket(null);
      }}
      patient={patient}
      ticket={ticket}
    />
  );
  return [modal, openEditTaskTicketModal];
}

export function useEditModal({
  onEditTicket,
  onDeleteTicket
}: {
  onEditTicket: (...args: any) => any;
  onDeleteTicket: (...args: any) => any;
}): UseEditModalReturns {
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [patient, setPatient] = useState<Patient>();
  const [ticket, setTicket] = useState<Ticket>();

  const openEditTicketModal = (patient: Patient, ticket: Ticket) => {
    trackEditTicketModalAnalyticsEvent({ action: AnalyticEventAction.Open, ticket_id: ticket.id });
    setIsEditModalOpened(true);
    setPatient(patient);
    setTicket(ticket);
  };

  const closeEditTicketModal = () => {
    setIsEditModalOpened(false);
  };

  const modal = useMemo(
    () => (
      <EditTicketModal
        onDeleteTicket={onDeleteTicket}
        onEditTicket={onEditTicket}
        isOpen={isEditModalOpened}
        onCancel={closeEditTicketModal}
        onSubmit={closeEditTicketModal}
        resetDataAfterClose={() => {
          setTicket(null);
          setPatient(null);
        }}
        patient={patient}
        ticket={ticket}
      />
    ),
    [onDeleteTicket, onEditTicket, isEditModalOpened, patient, ticket]
  );

  return [modal, openEditTicketModal];
}

type UseSmsModalReturnType = [ReactElement, (patient: Patient) => void];
export const useSmsModal = (): UseSmsModalReturnType => {
  const { userStore, departmentsStore, patientPageStore } = useStores();
  const [patient, setPatient] = useState<Patient>();
  const [smsMessageText, setSmsMessageText] = useState('');
  const [isSendingSms, setIsSendingSms] = useState(false);
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
  const { actionCallbacks } = useTicketOverviewContext();
  const { onRequestReport } = actionCallbacks;

  const getRequestDetails = useCallback(
    () => ({
      patientId: patient?.id,
      message: smsMessageText,
      reportType: ProtocolType.mobile,
      reportName: ProtocolName.symptom
    }),
    [patient?.id, smsMessageText]
  );

  const handleSendRequest = useCallback(() => {
    async function makeSendReportRequest() {
      try {
        setIsSendingSms(true);
        await patientPageStore!.sendReportRequest(patient, getRequestDetails);
        onRequestReport && onRequestReport(patient);
      } finally {
        setIsSendingSms(false);
        setIsSmsModalOpen(false);
      }
    }

    makeSendReportRequest();
  }, [getRequestDetails, patient, patientPageStore, onRequestReport]);

  const toggleSmsModal = (patient: Patient) => {
    setPatient(patient);
    setSmsMessageText(
      patient.getDefaultReportReminderText(
        userStore.currentDoctor.firstName,
        departmentsStore!.root.name
      )
    );
    setIsSmsModalOpen((currentlyOpen) => !currentlyOpen);
  };

  const modal = useMemo(
    () => (
      <SendSmsPersonalRequest
        onSendClicked={handleSendRequest}
        onMessageChanged={setSmsMessageText}
        onCancelClicked={() => setIsSmsModalOpen(false)}
        isSending={isSendingSms}
        isOpen={isSmsModalOpen}
        message={smsMessageText}
        title={`SMS to ${patient?.fullName}`}
      />
    ),
    [handleSendRequest, isSendingSms, isSmsModalOpen, smsMessageText, patient]
  );

  return [modal, toggleSmsModal];
};

type UseTicketResolveAndCommentModalReturns = [ReactElement, (ticketIds: number[]) => void];
export function useTicketResolveAndCommentModal(): UseTicketResolveAndCommentModalReturns {
  const { isOpen, setIsOpen } = useToggle(false);
  const [ticketIds, setTicketIds] = useState<number[]>([]);

  const openModal = (ticketIds: number[]) => {
    setTicketIds(ticketIds);
    setIsOpen(true);
  };

  const closeModal = useCallback(() => {
    setTicketIds([]);
    setIsOpen(false);
  }, [setIsOpen]);

  const modal = useMemo(() => {
    let modalNode = null;
    if (ticketIds) {
      modalNode = (
        <ResolveAndCommentModal isOpen={isOpen} onClose={closeModal} ticketIds={ticketIds} />
      );
    }
    return modalNode;
  }, [closeModal, isOpen, ticketIds]);

  return [modal, openModal];
}

export type DisconnectPopupConfig = {
  ticketIds: number[];
  callback: (...args: any) => any;
  excludedCallIds?: number[];
};

type UseDisconnectPopupReturn = [JSX.Element, (disconnectConfig: DisconnectPopupConfig) => any];
export const useDisconnectDraftPopup = (): UseDisconnectPopupReturn => {
  const { callsStore } = useStores();
  const [currentTicketIds, setTicketIds] = useState<number[]>(null);
  const [isDisconnectDraftPopupOpen, setIsDisconnectDraftPopupOpen] = useState(false);
  const [currentExcludedCallIds, setCurrentExcludedCallIds] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const callbackAfterDisconnect = useRef(null);

  const openDisconnectDraftPopup = ({
    ticketIds,
    callback,
    excludedCallIds
  }: DisconnectPopupConfig) => {
    setTicketIds(ticketIds);
    setIsDisconnectDraftPopupOpen(true);
    setCurrentExcludedCallIds(excludedCallIds);
    callbackAfterDisconnect.current = callback;
  };

  const disconnectDraft = useCallback(async () => {
    try {
      // when submitting a resumed draft we do not want to disconnect the current edited draft,
      // this is when excludedCallIds should be used, it only matters when
      // there is more than one ticket/draft when submitting a call
      setIsLoading(true);
      await callsStore.disconnectDraftsFromTickets(currentTicketIds, currentExcludedCallIds);
      setIsDisconnectDraftPopupOpen(false);
      callbackAfterDisconnect.current && callbackAfterDisconnect.current();
    } finally {
      resetPopup();
    }
  }, [callsStore, currentExcludedCallIds, currentTicketIds]);

  const resetPopup = () => {
    setIsLoading(false);
    setIsDisconnectDraftPopupOpen(false);
    setTicketIds(null);
    setCurrentExcludedCallIds(null);
    callbackAfterDisconnect.current = null;
  };

  const disconnectDraftPopup = useMemo(
    () =>
      currentTicketIds ? (
        <MessageDialog
          id={Dialogs.DisconnectDraftAndResolve}
          isOpen={isDisconnectDraftPopupOpen}
          title="Disconnect Call Draft from Ticket & Resolve?"
          secondaryActionProps={{ text: 'Cancel', onClick: resetPopup }}
          handleClose={resetPopup}
          primaryActionProps={{
            text: isLoading ? 'Disconnecting...' : 'Disconnect & Resolve',
            onClick: () => {
              !isLoading && disconnectDraft();
            }
          }}
        >
          The ticket(s) you are attempting to resolve is connected to at least one Call Draft. Press
          “Disconnect & Resolve” to disconnect the Call Draft and proceed. The Call Draft will not
          be erased, and can be found in the Patient Page. To resolve the ticket(s) with this Call
          Draft, the user who created it must first submit the call.
        </MessageDialog>
      ) : null,
    [currentTicketIds, isDisconnectDraftPopupOpen, isLoading, disconnectDraft]
  );

  return [disconnectDraftPopup, openDisconnectDraftPopup];
};
