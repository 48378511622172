// @ts-strict-ignore
import { FC } from 'react';

import { trackDropdownFilterUsageAnalyticsEvent } from 'analytics/events/filter-usage';
import { ActionMeta } from 'react-select';

import {
  DefaultTasksRequestParams,
  FilterName,
  TasksFiltersType
} from 'views/Filters/filters.types';
import { TasksFilters } from 'views/Filters/TasksFilters';
import { useTasksServerPersistentFilters } from 'views/Filters/useFilters';
import { ValueOf } from 'views/Pages/CareManagement/CareManagementPage.utils';
import { PATIENT_EPISODES_AND_TASKS_FILTERS_LOCAL_STORAGE_KEY } from 'views/Pages/EpisodesManagement/PatientEpisodesAndTasks';

interface Props {
  patientId: number;
  defaultQuery: DefaultTasksRequestParams;
}

export const EpisodeAndTasksFilters: FC<Props> = ({ patientId, defaultQuery }) => {
  const { filters, updateFiltersByKey } = useTasksServerPersistentFilters(
    defaultQuery,
    PATIENT_EPISODES_AND_TASKS_FILTERS_LOCAL_STORAGE_KEY
  );

  const onDropdownFilterChange = (
    values: ValueOf<TasksFiltersType>,
    filterKey: keyof TasksFiltersType,
    filterName: FilterName,
    actionMeta: ActionMeta<any>
  ) => {
    trackDropdownFilterUsageAnalyticsEvent(actionMeta, filterName);
    updateFiltersByKey(filterKey)(values);
  };

  return (
    <TasksFilters
      showEpisodeNumberFilter
      patientId={patientId}
      filters={filters}
      updateFiltersByKey={updateFiltersByKey}
      onDropdownFilterChange={onDropdownFilterChange}
    />
  );
};
