export const isEditorHtmlPureString = (htmlString: string) => {
  const parser = new DOMParser();

  try {
    const doc = parser.parseFromString(htmlString, 'text/html');

    const spanElements = doc.body.querySelectorAll('span[style="white-space: pre-wrap;"]');

    const hasOneSpanWithStyle = spanElements.length === 1;
    const contentMatches = spanElements[0]?.textContent?.trim() === doc.body.textContent?.trim();
    const hasNoTextIndent = !/text-indent/i.test(doc.body.innerHTML);

    return hasOneSpanWithStyle && contentMatches && hasNoTextIndent;
  } catch (error) {
    return false;
  }
};
