import { forwardRef } from 'react';

import { css, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { observer } from 'mobx-react';

import ScheduledProtocol from 'models/ScheduledProtocol';

import Icon from 'components/Icons/Icon';

import { Text } from 'components/UIkit/atoms/Text';

interface IProtoclDetailsProps {
  protocol: ScheduledProtocol;
}

export const ProtocolDetailsBase = forwardRef<HTMLDivElement, IProtoclDetailsProps>(
  ({ protocol }, ref) => {
    const protocolTypeString = protocol.getProtocolTypeString();
    const protocolTimeSummary = protocol.getProtocolTimeSummary();

    return (
      <StyledContainer container alignItems="center" justifyContent="flex-end">
        <StyledGrid ref={ref} container direction="column" alignItems="flex-end">
          <StyledText
            title={protocolTypeString}
            variant="body1"
            isEllipsis
            color="primary"
            width="100%"
          >
            {protocolTypeString}
          </StyledText>

          <Text
            title={protocolTimeSummary}
            variant="body2"
            isEllipsis
            color="disabled"
            width="100%"
          >
            {protocolTimeSummary}
          </Text>
        </StyledGrid>
        <StyledChevronIcon />
      </StyledContainer>
    );
  }
);

const StyledContainer = styled(Grid)(
  ({ theme }) => css`
    &:hover {
      span,
      svg {
        color: ${theme.palette.primary.dark};
      }
    }
  `
);

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    white-space: nowrap;
    width: 80%;
    margin-right: ${theme.spacing(8)};
  `
);

const StyledText = styled(Text)(
  () => css`
    text-align: right;
  `
);

const StyledChevronIcon = styled(Icon.Chevron)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
  `
);

export const ProtocolDetails = observer(ProtocolDetailsBase);
