import { FC, ReactNode } from 'react';

import { differenceWith } from 'lodash';
import { Col, Row } from 'reactstrap';

import { DurationInterval } from 'models/DurationInterval';
import { QuickAddition } from 'models/QuickAddition';

import './IntervalsEditor.scss';

export type IntervalErrors = {
  errorByUuid: Map<string, string[]>;
  isOverlapped: Set<string>;
};

export type ExternalValidation = (interval: DurationInterval) => Promise<string | null>;
export type IntervalEditorEntry = DurationInterval | QuickAddition;

export type IntervalEditorCtx<T extends IntervalEditorEntry = IntervalEditorEntry> = {
  initial: T[]; // provided by the component using the editor
  current: T[]; // current state
  setCurrent: (arg: T[]) => void;
  isValid: boolean;
};

export const resetBackToInitial = (ctx: IntervalEditorCtx) => {
  if (ctx.current !== ctx.initial) {
    ctx.setCurrent(ctx.initial);
  }
};

export const refreshCurrent = (ctx: IntervalEditorCtx) => {
  ctx.setCurrent([...ctx.current]);
};

function entriesComparer<T extends IntervalEditorEntry = any>(entryA: T, entryB: T) {
  return entryA.uniqueIdentifier === entryB.uniqueIdentifier;
}

export function getDeleted<T extends IntervalEditorEntry>(ctx: IntervalEditorCtx<T>): T[] {
  return differenceWith<T, T>(ctx.initial, ctx.current, entriesComparer);
}

interface Props {
  children?: ReactNode;
}

export const EntriesEditorWrapper: FC<Props> = ({ children }) => (
  <div className="entries-editor">{children}</div>
);

export const EntriesEditorHeaderRow: FC<Props> = ({ children }) => (
  <Row className="entry-editor-header-row">{children}</Row>
);

interface HeaderCellProps {
  children: ReactNode;
  colSize: number;
}

export const EntriesEditorHeaderCell: FC<HeaderCellProps> = ({ children, colSize }) => (
  <Col lg={colSize}>
    <div className="entry-header">{children}</div>
  </Col>
);
