export enum DrugSpecificType {
  Hourly = 'Hourly',
  WeeklyCRS = 'WeeklyCrs',
  WeeklyInfection = 'WeeklyInfection'
}

export interface DrugSpecificReportProtocolTemplate {
  id: number;
  type: DrugSpecificType;
  timeBetweenReportsInMilliseconds: number;
  duration: number;
  durationUnitType: UnitType;
  endType: number;
}

export interface DrugSpecific {
  id: number;
  name: string;
  reportProtocolTemplates: DrugSpecificReportProtocolTemplate[];
}

export enum UnitType {
  Month = 'month',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute'
}
