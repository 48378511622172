import { useRef } from 'react';

import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Down | AnalyticEventAction.Up;

interface Properties {
  action: Action;
  value: string;
}

export const trackSectionHeaderViewAnalyticsEvent = ({ action, value }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.SectionHeaderView,
    properties: { action, value }
  });
};

export const useTrackSectionHeaderViewEvent = (sectionName: string) => {
  const { ref, entry, inView } = useInView({
    initialInView: true
  });

  const previousY = useRef(0);
  const { pathname } = useLocation();

  useUpdateEffect(
    function trackSectionHeaderViewEvent() {
      if (inView && pathname.includes('work-queue')) {
        if (entry) {
          let scrollDirection = AnalyticEventAction.Up;

          if (entry.boundingClientRect.y > previousY.current) {
            scrollDirection = AnalyticEventAction.Down;
          }

          trackSectionHeaderViewAnalyticsEvent({ action: scrollDirection, value: sectionName });
          previousY.current = entry!.boundingClientRect.y;
        }
      }
    },
    [inView]
  );

  return { ref };
};
