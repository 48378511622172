import './EllipsisLoader.scss';

export const EllipsisLoader = () => (
  <div className="lds-ellipsis">
    <div />
    <div />
    <div />
    <div />
  </div>
);
