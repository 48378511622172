import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.TurnOn | AnalyticEventAction.TurnOff;

interface Properties {
  action: Action;
  tab: 'Enrollment Manager';
}

export const trackExpandAllDxToggleAnalyticsEvent = ({ action, tab }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.ExpandAllDxToggle,
    properties: { action, tab }
  });
};
