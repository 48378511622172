// @ts-strict-ignore

import { FC, Fragment, useMemo, useState } from 'react';

import { css } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackFilterUsageAnalyticsEvent } from 'analytics/events/filter-usage';

import debounce from 'debounce-promise';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { getFilteredCategories } from 'utils/TicketType.utils';

import { FEATURES } from 'constants/features';

import { TicketTypeCreationAllowance } from 'models/TicketTypes';

import SearchBar from 'views/Dashboard/SearchBar';
import { FilterName } from 'views/Filters/filters.types';
import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';
import { NoResultForFilter } from 'views/Pages/PracticeManagement/PracticeManagerPage.shared';
import { CreateTicketSubTypeModal } from 'views/Pages/PracticeManagement/TicketTypes/Modals/CreateTicketSubTypeModal';
import { DeleteTicketSubTypePopup } from 'views/Pages/PracticeManagement/TicketTypes/Modals/DeleteTicketSubTypePopup';
import { DisableTicketCategoryPopup } from 'views/Pages/PracticeManagement/TicketTypes/Modals/DisableTicketCategoryPopup';
import { EditTicketSubTypeModal } from 'views/Pages/PracticeManagement/TicketTypes/Modals/EditTicketSubTypeModal';
import { EditTicketTypeModal } from 'views/Pages/PracticeManagement/TicketTypes/Modals/EditTicketTypeModal';
import { TicketTypeRow } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeCard/TicketTypeRow';
import { TicketTypeLabelTooltip } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeLabelTooltip/TicketTypeLabelTooltip';
import CollapsibleSection from 'views/Widgets/CollapsibleSection';
import StyledToggleButton from 'views/Widgets/StyledToggleButton';

const ManagePracticeTicketTypesPage: FC = () => {
  const [isCreateTicketSubTypeModalOpen, setIsCreateTicketSubTypeModalOpen] = useState(false);
  const [isEditTicketTypeModalOpen, setIsEditTicketTypeModalOpen] = useState(false);
  const [isEditTicketSubTypeModalOpen, setIsEditTicketSubTypeModalOpen] = useState(false);
  const [isDeleteTicketSubTypePopupOpen, setIsDeleteTicketSubTypePopupOpen] = useState(false);
  const [isDisableTicketCategoryPopupOpen, setIsDisableTicketCategoryPopupOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [ticketTypeId, setTicketTypeId] = useState(null);
  const [ticketSubTypeId, setTicketSubTypeId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { ticketTypesStore, settingsStore } = useStores();

  const openCreateTicketSubTypeModal = (ticketTypeId: number) => {
    setIsCreateTicketSubTypeModalOpen(true);
    setTicketTypeId(ticketTypeId);
  };

  const closeCreateTicketSubTypeModal = () => {
    setIsCreateTicketSubTypeModalOpen(false);
    setTicketTypeId(null);
  };

  const openEditTicketTypeModal = (ticketTypeId: number) => {
    setIsEditTicketTypeModalOpen(true);
    setTicketTypeId(ticketTypeId);
  };

  const openEditTicketSubTypeModal = (ticketSubTypeId: number) => {
    setIsEditTicketSubTypeModalOpen(true);
    setTicketSubTypeId(ticketSubTypeId);
  };

  const openDeleteTicketSubTypeModal = (subTypeId: number) => {
    setIsDeleteTicketSubTypePopupOpen(true);
    setTicketSubTypeId(subTypeId);
  };

  const closeDeleteTicketSubTypeModal = () => {
    setIsDeleteTicketSubTypePopupOpen(false);
    setTicketSubTypeId(null);
  };

  const toggleCategory = (categoryId: number, isCategoryEnabled: boolean) => {
    if (isCategoryEnabled) {
      openDisableTicketCategoryPopup(categoryId);
      return;
    }

    ticketTypesStore.toggleCategoryAllowance(categoryId);
  };

  const openDisableTicketCategoryPopup = (categoryId: number) => {
    setIsDisableTicketCategoryPopupOpen(true);
    setCategoryId(categoryId);
  };

  const closeDisableTicketCategoryPopup = () => {
    setIsDisableTicketCategoryPopupOpen(false);
    setTicketSubTypeId(null);
  };

  // symptom tickets are not editable
  const categories = useMemo(
    () =>
      !searchTerm
        ? ticketTypesStore.ticketTypesTree.operatorCategories
        : getFilteredCategories(ticketTypesStore.ticketTypesTree, searchTerm),
    [searchTerm, ticketTypesStore.ticketTypesTree]
  );

  const onTrackSearchAnalyticsEvent = useMemo(() => {
    return debounce((searchQuery) => {
      trackFilterUsageAnalyticsEvent({
        action: !searchQuery ? AnalyticEventAction.Clear : AnalyticEventAction.SearchedByTicketType,
        value: FilterName.SearchByTicketType
      });
    }, DEBOUNCE_DELAY);
  }, []);

  return (
    <>
      <CreateTicketSubTypeModal
        isOpen={isCreateTicketSubTypeModalOpen}
        ticketTypeId={ticketTypeId}
        onClose={closeCreateTicketSubTypeModal}
      />

      <EditTicketTypeModal
        ticketTypeId={ticketTypeId}
        onClose={() => setIsEditTicketTypeModalOpen(false)}
        isOpen={isEditTicketTypeModalOpen}
        resetDataAfterClose={() => setTicketTypeId(null)}
      />

      <EditTicketSubTypeModal
        ticketSubTypeId={ticketSubTypeId}
        onClose={() => setIsEditTicketSubTypeModalOpen(false)}
        isOpen={isEditTicketSubTypeModalOpen}
        resetDataAfterClose={() => setTicketSubTypeId(null)}
      />

      <DeleteTicketSubTypePopup
        isOpen={isDeleteTicketSubTypePopupOpen}
        ticketSubTypeId={ticketSubTypeId}
        onAction={closeDeleteTicketSubTypeModal}
        onCancel={closeDeleteTicketSubTypeModal}
      />

      <DisableTicketCategoryPopup
        isOpen={isDisableTicketCategoryPopupOpen}
        onAction={async () => {
          await ticketTypesStore.toggleCategoryAllowance(categoryId);
          closeDisableTicketCategoryPopup();
        }}
        onCancel={closeDisableTicketCategoryPopup}
        testHook={`${categoryId}`}
      />

      <StyledSearchBar
        searchValue={searchTerm}
        onSearchChanged={(searchTerm) => {
          onTrackSearchAnalyticsEvent(searchTerm);
          setSearchTerm(searchTerm);
        }}
        placeholder="Search by Ticket Type"
        testHook="ticketTypeFilter"
      />

      <TicketTypeLabelTooltip />

      <StyledContentContainer>
        {categories.length === 0 && <NoResultForFilter />}
        {categories.map((category) => {
          const isCategoryEnabled = category.displaySettings?.length > 0;

          return (
            <StyledCollapsibleSection
              key={category.id}
              name={category.name}
              trailing={
                <StyledToggleButton
                  className="category-allow-new-tickets"
                  onChange={() => toggleCategory(category.id, isCategoryEnabled)}
                  checked={isCategoryEnabled}
                  testHook={`${category.id}-toggle-button`}
                />
              }
              trigger={category.name}
              initiallyOpen
              testHook={`collapsibleCategoryTypesRow_${category.id}`}
              isCategoryEnabled={isCategoryEnabled}
            >
              {category.children.map((ticketType) => (
                <Fragment key={ticketType.id}>
                  <TicketTypeRow
                    ticketType={ticketType}
                    hasPatientRequestFeature={settingsStore.hasFeature(FEATURES.PATIENT_REQUESTS)}
                    onCreateTicketSubType={() => openCreateTicketSubTypeModal(ticketType.id)}
                    onEditTicketType={() => openEditTicketTypeModal(ticketType.id)}
                    toggleTicketTypeAllowance={(allowance: TicketTypeCreationAllowance) =>
                      ticketTypesStore.toggleTicketTypeOrSubTypeAllowance(ticketType.id, allowance)
                    }
                    testHook={`ticketType.TypeCard_${ticketType.id}`}
                  />

                  {ticketType.children
                    .filter((child) => !child?.isDeleted)
                    .map((ticketSubType) => (
                      <TicketTypeRow
                        key={ticketSubType.id}
                        ticketType={ticketSubType}
                        hasPatientRequestFeature={settingsStore.hasFeature(
                          FEATURES.PATIENT_REQUESTS
                        )}
                        isTicketSubType
                        onCreateTicketSubType={() => openCreateTicketSubTypeModal(ticketSubType.id)}
                        onDeleteTicketSubType={() => openDeleteTicketSubTypeModal(ticketSubType.id)}
                        onEditTicketType={() => openEditTicketSubTypeModal(ticketSubType.id)}
                        toggleTicketTypeAllowance={(allowance: TicketTypeCreationAllowance) =>
                          ticketTypesStore.toggleTicketTypeOrSubTypeAllowance(
                            ticketSubType.id,
                            allowance
                          )
                        }
                        testHook={`ticketType.SubTypeCard_${ticketSubType.id}`}
                      />
                    ))}
                </Fragment>
              ))}
            </StyledCollapsibleSection>
          );
        })}
      </StyledContentContainer>
    </>
  );
};

const StyledSearchBar = styled(SearchBar)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(48)};
  `
);

const StyledCollapsibleSection = styled(CollapsibleSection)<{ isCategoryEnabled: boolean }>(
  ({ theme, isCategoryEnabled }) => css`
    &.collapsible-section {
      margin-bottom: ${theme.spacing(28)};

      & .collapsible-section-header {
        margin-bottom: ${theme.spacing(28)};

        & .collapsible-section-trigger {
          h3 {
            color: ${!isCategoryEnabled ? theme.palette.text.disabled : 'inherit'};
          }
        }
      }
    }
  `
);

const StyledContentContainer = styled('div')(
  ({ theme }) => css`
    margin: ${theme.spacing(20)} 0;
  `
);

export default observer(ManagePracticeTicketTypesPage);
