// @ts-strict-ignore

import React, { FC, useState } from 'react';

import { observer } from 'mobx-react';
import { Row } from 'react-table';

import { useStores } from 'mobx/hooks/useStores';

import PatientTag from 'models/PatientTag';

import AddEditTagModal from 'views/Modals/AddEditTagModal';

import Icon from 'components/Icons/Icon';
import { Table, TableColumn } from 'components/Table';
import { OutlinedIconButton } from 'components/UIkit/atoms/Button';

import './ManagePracticeLocationsPage.scss';

interface PracticeTagsRow {
  id: number;
  name: string;
  institutionId: number;
}

export const ManagePracticeTagsPage: FC = observer(() => {
  const { constantsStore } = useStores();
  const [isAddTagModalOpen, setAddTagModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const columns: TableColumn<PracticeTagsRow>[] = [
    {
      Header: '',
      accessor: 'name'
    }
  ];
  const rows = constantsStore.activeTags.map((tag: PatientTag) => {
    return {
      id: tag.id,
      name: tag.name,
      institutionId: tag.institutionId
    };
  });

  const handleRowClicked = (row: Row<PracticeTagsRow>) => {
    setAddTagModalOpen(true);
    setSelectedTag(row.original.id);
  };

  return (
    <div className="practice-locations-page">
      <div className="top-tab-section">
        <OutlinedIconButton
          onClick={() => setAddTagModalOpen(true)}
          icon={<Icon.Plus />}
          variant="secondary"
        >
          Add Tag
        </OutlinedIconButton>
      </div>
      <AddEditTagModal
        isOpen={isAddTagModalOpen}
        tagId={selectedTag}
        onCancel={() => setAddTagModalOpen(false)}
        onSubmit={() => setAddTagModalOpen(false)}
        resetDataAfterClose={() => setSelectedTag(null)}
      />
      <Table
        columns={columns}
        rowData={rows}
        hideHeaders
        rowAction={handleRowClicked}
        disableRowRule={(original) => !Boolean(original.institutionId)}
      />
    </div>
  );
});
