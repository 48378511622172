import { FC, MouseEventHandler, ReactNode, useState } from 'react';

import { css, styled } from '@mui/material';

import Typography from '@mui/material/Typography';
import { Dialogs } from 'analytics/events/dialog';
import classNames from 'classnames';

import {
  components,
  ControlProps,
  InputProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  SingleValueProps,
  ValueContainerProps
} from 'react-select';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { SelectComponents } from 'components/UIkit/atoms/Dropdown/Select.shared';

import { DefaultMenuOption } from './ReactSelectOptionComponent';

const Control: FC<ControlProps<any, boolean>> = ({ children, ...props }) => (
  <components.Control {...props}>
    <Typography
      variant="form-text"
      component="div"
      display="flex"
      flexGrow={1}
      width="100%"
      flexWrap="wrap"
    >
      {children}
    </Typography>
  </components.Control>
);

const Input: FC<InputProps<any, true>> = (props) => {
  return <components.Input {...props} />;
};

export const defaultSelectComponents: SelectComponents = {
  Option: DefaultMenuOption,
  Control,
  Input,
  IndicatorSeparator: (): null => null
};

// TODO: changed to styledAPI
export const ValueContainer: FC<ValueContainerProps<any, boolean>> = ({ children, ...props }) => {
  const { isFocused, isDisabled } = props.selectProps;
  const count = props.getValue().length;
  const childNodes = children as ReactNode[];
  const lastChild = childNodes[childNodes.length - 1];
  const classes = classNames({
    'flex-nowrap': count < 2
  });

  return isFocused || count < 2 ? (
    <components.ValueContainer {...props} className={classes}>
      {children}
    </components.ValueContainer>
  ) : (
    <>
      <span
        className={classNames('select-value-summary', {
          disabled: isDisabled
        })}
      >{`${count} Selected`}</span>
      <components.ValueContainer {...props} className="empty-value-container">
        {lastChild}
      </components.ValueContainer>
    </>
  );
};

// === ticket type autocomplete components ===//
export const TicketTypeMultiValueLabel: FC<MultiValueGenericProps<TicketSubTypeOption>> = (
  props
) => {
  const label = props.data.labelFull ? props.data.labelFull : props.data.label;
  return <components.MultiValueLabel {...props}>{label}</components.MultiValueLabel>;
};

export const TicketTypeSingleValueLabel: FC<SingleValueProps<TicketSubTypeOption>> = (props) => {
  const label = props.data.labelFull ? props.data.labelFull : props.data.label;
  return <components.SingleValue {...props}>{label}</components.SingleValue>;
};

export const TicketTypeCustomControl: FC<ControlProps> = (props) => (
  // @ts-ignore
  <StyledControl {...props}>{props.children}</StyledControl>
);

const StyledControl = styled(components.Control, {
  shouldForwardProp: () => true // react-select "theme" object  should be passed down to react-select component
})((props: ControlProps) => {
  // when styling react-select component - use muiTheme instead of react-select theme
  const { borderRadius } = props.selectProps.muiTheme;

  return css`
    border-radius: ${borderRadius.large} ${borderRadius.large} 0 0;
  `;
});

export const TicketTypeMultiValueRemove: FC<MultiValueRemoveProps<TicketSubTypeOption>> = (
  props
) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const onRemoveClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (props.data.parentKind !== 'symptom') {
      setShowWarning(true);
      return;
    }

    const shouldShowWarning =
      props.selectProps.value &&
      ('parentKind' in props.selectProps.value ||
        (props.selectProps.value as TicketSubTypeOption[]).filter(
          (option: TicketSubTypeOption) => option.parentKind === 'symptom'
        ).length === 1);

    if (shouldShowWarning) {
      setShowWarning(true);
      return;
    }

    (props.innerProps.onClick as any)(event);
  };

  return (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <MessageDialog
        id={props.selectProps.isSub ? Dialogs.DeleteTicketTypeSubSelect : Dialogs.DeleteTicketType}
        isOpen={showWarning}
        title={`Delete ${props.data.labelFull}?`}
        testHook={`remove-operator-${props.data.value}-message-dialog`}
        handleClose={() => setShowWarning(false)}
        primaryActionProps={{
          text: 'Delete',
          onClick: (event) => props.innerProps.onClick(event as any)
        }}
        secondaryActionProps={{ text: 'Cancel', onClick: () => setShowWarning(false) }}
      >
        Any details you have entered in the corresponding text field will be erased.
      </MessageDialog>

      <components.MultiValueRemove
        {...props}
        innerProps={{ ...props.innerProps, onClick: onRemoveClick }}
      >
        <components.CrossIcon />
      </components.MultiValueRemove>
    </div>
  );
};

// === to be removed ofter ticket type re-design ===//
const SingleValue = ({ children, ...props }: any) => {
  const { selectProps } = props;
  return (
    <components.SingleValue {...props}>
      <span className="text-bold">{selectProps.label}: </span>
      {children}
    </components.SingleValue>
  );
};

const LabelInPlaceholder = (props: any) => {
  const { selectProps } = props;
  return (
    <components.SingleValue {...props}>
      <span className="text-bold">{selectProps.label}: </span>
      {selectProps.placeholder}
    </components.SingleValue>
  );
};

export const InlineLabelComponents = {
  SingleValue,
  Placeholder: LabelInPlaceholder
};
