import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import {
  ActivePathwayTemplatesResponse,
  PathwaysTemplateRecentVersions
} from './responses/pathways-builder.response';

const httpService = new HttpService('Pathway-Builder');

interface PathwayBuilderTemplate {
  // todo: update type and extract
  pathways: any[];
  questions: any[];
}

export class PathwayBuilderFetcher {
  static async fetchActivePathwaysTemplate() {
    return await httpService.get<ActivePathwayTemplatesResponse>({
      url: API_URLS.PATHWAY_BUILDER_ACTIVE_TEMPLATE,
      transformResponse: (
        response: ActivePathwayTemplatesResponse
      ): ActivePathwayTemplatesResponse => ({
        pathwayTemplates: {
          pathways: response.pathwayTemplates.pathways,
          questions: response.pathwayTemplates.questions
        },
        updatedAt: response.updatedAt
      })
    });
  }

  static async validatePathwayTemplate(pathwaysTemplate: PathwayBuilderTemplate) {
    return await httpService.post<{ error: string }>({
      url: API_URLS.PATHWAY_BUILDER_VALIDATE_PATHWAY,
      data: pathwaysTemplate
    });
  }

  static async fetchPathwaysTemplateRecentVersions(): Promise<PathwaysTemplateRecentVersions[]> {
    return await httpService.get<PathwaysTemplateRecentVersions[]>({
      url: API_URLS.PATHWAY_BUILDER_RECENT_VERSIONS
    });
  }

  static async publishPathwayTemplate(pathwaysTemplate: PathwayBuilderTemplate) {
    return await httpService.post({
      url: API_URLS.PATHWAY_BUILDER_PUBLISH,
      data: pathwaysTemplate
    });
  }

  static async activatePathwayTemplate(id: number) {
    return await httpService.patch({
      url: API_URLS.PATHWAY_BUILDER_ACTIVATE(id)
    });
  }
}
