// @ts-strict-ignore
import { FC, useEffect, useRef, useState } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackEditTimerModalAnalyticsEvent } from 'analytics/events/edit-timer-modal';
import classNames from 'classnames';

import FlipMove from 'react-flip-move';

import { useMount } from 'react-use';

import { Col } from 'reactstrap';

import Doctor from 'models/Doctor';
import { DurationInterval } from 'models/DurationInterval';

import { useClinicianFullNameWithCredentials } from 'hooks/useClinicianFullNameWithCredentials';

import Icon from 'components/Icons/Icon';

import { useContainerHeight } from 'components/IntervalsEditor/IntervalsEditor.utils';
import { TextIconButton } from 'components/UIkit/atoms/Button';

import IntervalEditorRow from './IntervalEditorRow';

import { useIntervalContext } from './IntervalsContext';

import {
  EntriesEditorHeaderCell,
  EntriesEditorHeaderRow,
  EntriesEditorWrapper
} from './IntervalsEditor.shared';

interface Props {
  virtualPage: 'call logger';
  doctor: Doctor;
}

export const IntervalsEditor: FC<Props> = ({ virtualPage, doctor }) => {
  const fullNameWithCredentials = useClinicianFullNameWithCredentials(
    doctor.firstName,
    doctor.lastName,
    doctor.credentialId
  );
  const [hasNewInterval, setHasNewInterval] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const { intervalCtx, setLastEditedInterval, externalValidation, errors } = useIntervalContext();
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const { containerRef, containerStyle } = useContainerHeight(intervalCtx.current);
  const NUMBER_OF_INTERVAL_ROWS_OPENING_DOWN = 5;
  // empty intervals should appear first
  const sortedIntervals = intervalCtx.current.sort((intervalA, intervalB) =>
    intervalA.startDate && intervalB.startDate
      ? intervalA.startDate.getTime() - intervalB.startDate.getTime()
      : 1
  );

  useMount(function setIsOpen() {
    setIsEditorOpen(true);
  });

  useEffect(
    function scrollToBottom() {
      if (hasNewInterval || isEditorOpen) {
        outerContainerRef.current.scrollTo({
          top: outerContainerRef.current.scrollHeight,
          behavior: 'smooth'
        });
        setHasNewInterval(false);
        setIsEditorOpen(false);
      }
    },
    [hasNewInterval, isEditorOpen]
  );

  const onUpdateInterval = (interval: DurationInterval) => {
    const updatedIntervals = intervalCtx.current.map((item) =>
      item.uniqueIdentifier === interval.uniqueIdentifier ? interval : item
    );
    intervalCtx.setCurrent(updatedIntervals);
    setLastEditedInterval(interval);
    externalValidation.validate(interval);

    trackEditTimerModalAnalyticsEvent({
      action: AnalyticEventAction.UpdateInterval,
      virtual_page: virtualPage
    });
  };

  const onRemoveInterval = (interval: DurationInterval) => {
    const updatedIntervals = intervalCtx.current.filter(
      (item) => item.uniqueIdentifier !== interval.uniqueIdentifier
    );
    intervalCtx.setCurrent(updatedIntervals);

    trackEditTimerModalAnalyticsEvent({
      action: AnalyticEventAction.RemoveInterval,
      virtual_page: virtualPage
    });
  };

  const addInterval = () => {
    const newInterval = new DurationInterval({ id: doctor.id, name: fullNameWithCredentials });
    intervalCtx.setCurrent([...intervalCtx.current, newInterval]);
    setHasNewInterval(true);

    trackEditTimerModalAnalyticsEvent({
      action: AnalyticEventAction.AddInterval,
      virtual_page: virtualPage
    });
  };

  return (
    <EntriesEditorWrapper>
      <EntriesEditorHeaderRow>
        <EntriesEditorHeaderCell colSize={3}>
          <b>Start Time</b>
        </EntriesEditorHeaderCell>
        <EntriesEditorHeaderCell colSize={3}>
          <b>End Time</b>
        </EntriesEditorHeaderCell>
        <EntriesEditorHeaderCell colSize={2}>
          <b>User</b>
        </EntriesEditorHeaderCell>
        <EntriesEditorHeaderCell colSize={3}>
          <b>
            Duration <span className="gray-text">hh:mm</span>
          </b>
        </EntriesEditorHeaderCell>
        <Col lg={1} />
      </EntriesEditorHeaderRow>
      <div style={containerStyle} ref={outerContainerRef}>
        <div ref={containerRef}>
          <FlipMove enterAnimation="fade" leaveAnimation="fade">
            {sortedIntervals.map((interval, i) => {
              return (
                <div
                  key={interval.uniqueIdentifier}
                  className={classNames('entry-editor-row row', {
                    highlight: errors.isOverlapped.has(interval.uniqueIdentifier)
                  })}
                >
                  {/* keep div wrapper - there is a bug in FlipMove (which not maintained)
                      removing it throws error see 
                      https://github.com/Pomax/react-onclickoutside/issues/353
                  */}
                  <IntervalEditorRow
                    key={interval.uniqueIdentifier}
                    interval={interval}
                    currentClinicianName={fullNameWithCredentials}
                    removeAllowed={sortedIntervals.length > 1}
                    onUpdateInterval={onUpdateInterval}
                    onRemoveInterval={onRemoveInterval}
                    openDatePickerUp={i >= NUMBER_OF_INTERVAL_ROWS_OPENING_DOWN}
                    errors={errors.errorByUuid.get(interval.uniqueIdentifier)}
                  />
                </div>
              );
            })}
          </FlipMove>
        </div>
      </div>

      <TextIconButton
        onClick={addInterval}
        disabled={!intervalCtx.isValid}
        icon={<Icon.CirclePlus />}
        mt={24}
      >
        Add Interval
      </TextIconButton>
    </EntriesEditorWrapper>
  );
};
