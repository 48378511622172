import { FC } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { FeatureIntroCodes } from 'models/Doctor';
import Patient from 'models/Patient';

import CareTimerIntroModal from 'views/Modals/CareTimerIntroModal';

import CareTimer, { CareTimerButton } from 'components/CareTimer';
import { Tooltip } from 'components/Tooltip';

interface Props {
  patient: Patient;
}

export const PatientDetailsCardCareTimer: FC<Props> = ({ patient }) => {
  const { userStore, careTimerStore } = useStores();
  const hasCareTimerIntro = userStore.currentDoctor.hasFeature(FeatureIntroCodes.CareTimer);

  if (hasCareTimerIntro) {
    return <CareTimer />;
  }

  return (
    <div className="caretimer-container">
      <Tooltip
        maxWidth="none"
        placement="bottom"
        controller={{
          visible: true,
          onClickOutside: () => null
        }}
        disabled={false}
        label={<CareTimerButton disabled />}
      >
        <div>
          <CareTimerIntroModal
            onFinished={() => {
              careTimerStore.startNewSession({ id: userStore.currentDoctor.id }, patient.id);
            }}
          />
        </div>
      </Tooltip>
    </div>
  );
};
