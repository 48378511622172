import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { CommentsParser } from 'parsers/CommentsParser';

import Comment from 'models/Comment';

const httpService = new HttpService('Comments');

class CommentsFetcher {
  static async fetchCommentsByIdAndType(commentableId: number, commentableType: string) {
    return await httpService.get<Comment[]>({
      url: API_URLS.GET_COMMENTS(commentableId, commentableType),
      transformResponse: CommentsParser.parseComments
    });
  }
}

export default CommentsFetcher;
