import { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ButtonProps, StyledOutlined } from 'components/UIkit/atoms/Button/StyledButtons';
import { withSpacing } from 'components/UIkit/theme/spacing';

interface Props extends ButtonProps {
  children: ReactNode;
  subText: string;
  color?: 'error';
}

const OutlinedSubtext: FC<Props> = ({ onClick, subText, color, disabled, testHook, children }) => (
  <Container color={color} onClick={onClick} disabled={disabled} data-test-hook={testHook}>
    <Typography variant="button-medium-outline">{children}</Typography>
    <Typography variant="button-medium-second-line">{subText}</Typography>
  </Container>
);

const Container = styled(StyledOutlined)`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
`;

export const OutlinedSubtextButton = withSpacing(OutlinedSubtext);
