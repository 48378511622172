import { FC } from 'react';

import { observer } from 'mobx-react';

import { formatDate } from 'utils/DateUtils';

import { OralReportV1 } from 'models/OralReport/OralReportV1';

import { useOralReportCycle } from 'hooks/useOralReportCycle';

import LabelDatePicker from 'components/DateTimePicker/LabelDateTimePicker';
import { OralReportCycleProps } from 'components/Ticket/TicketRow/OralReport/OralReport.shared';

export const OralReportCycleV1: FC<OralReportCycleProps<OralReportV1>> = observer(
  ({ reportId, oral, patient }) => {
    const { firstPill, startOfCycle } = oral;
    const { selectedDate, handleDateSelect } = useOralReportCycle(patient, reportId, startOfCycle);

    if (firstPill?.isAnswered) {
      return <span>{formatDate(firstPill.value!, 'MMMM D, YYYY')}</span>;
    }

    return (
      <LabelDatePicker date={selectedDate} onSelect={handleDateSelect} placeholder="Date Unknown" />
    );
  }
);
