import { PopperModifier } from 'components/Tooltip/Tooltip.types';

export const sameWidthPopperModifier: PopperModifier = {
  enabled: true,
  fn: ({ instance, state }) => {
    const triggerReferenceWidth = `${state.rects.reference.width}px`;

    if (state.styles.popper.width !== triggerReferenceWidth) {
      state.styles.popper.width = triggerReferenceWidth;
      // force update the popper instance, so it can reposition the dropdown properly
      instance.update();
    }
  },
  name: 'sameWidth',
  phase: 'beforeWrite',
  requires: ['computeStyles']
};
