import { FC } from 'react';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  title: string;
  isDisabled?: boolean;
}

export const QuestionTitle: FC<Props> = ({ title, isDisabled = false }) => (
  <Text
    variant="h3"
    color={isDisabled ? 'disabled' : 'primary'}
    display="flex"
    height="45px"
    alignItems="center"
  >
    {title}
  </Text>
);
