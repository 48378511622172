import { useEffect, useRef, useState } from 'react';

import { Box, css, styled } from '@mui/material';
import { Dialogs } from 'analytics/events/dialog';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocalStorage, useMount } from 'react-use';
import { pathwayBuilderTestSelectors } from 'tests/models/pages/pathway-builder/pathway-builder-page.selectors';

import { v4 as uuid } from 'uuid';

import { useStores } from 'mobx/hooks/useStores';

import DepartmentsFetcher from 'fetchers/DepartmentFetcher';
import { PathwayBuilderFetcher } from 'fetchers/PathwayBuilderFetcher';
import { PathwaysTemplateRecentVersions } from 'fetchers/responses/pathways-builder.response';

import { formatDate } from 'utils/DateUtils';
import { isStagingOrDev } from 'utils/EnvUtils';

import { pluralize } from 'utils/StringUtils';

import { showToast } from 'utils/UserMessageUtils';

import { BasicDepartmentInfo } from 'models/Department';

import {
  CHANGED_PATHWAY_IDS_LOCAL_STORAGE_KEY,
  CHANGED_QUESTIONS_IDS_LOCAL_STORAGE_KEY,
  PATHWAY_BUILDER_ID_QUERY_PARAM,
  PATHWAY_BUILDER_TABS
} from 'views/Pages/PathwayBuilder/PathwayBuilderPage.constants';

import { StyledContainer } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.shared';

import { PathwayBuilderTab } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.types';
import { getDownloadJsonButtonSubText } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.utils';
import { PathwayEditorView } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView';
import { PathwaysTabView } from 'views/Pages/PathwayBuilder/PathwaysTabView/PathwaysTabView';

import { QuestionBankTabView } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView';
import ToggleBar from 'views/Widgets/ToggleBar';

import Icon from 'components/Icons/Icon';
import FixedLoader from 'components/Loaders/FixedLoader';
import { ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { OutlinedIconSubtextButton } from 'components/UIkit/atoms/Button';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';
import { Text } from 'components/UIkit/atoms/Text';

export const PathwayBuilderPage = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [isValidatingJson, setIsValidatingJson] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [isPublishMenuOpen, setIsPublishMenuOpen] = useState(false);
  const [isVersionsMenuOpen, setIsVersionsMenuOpen] = useState(false);
  const [isNewPathway, setIsNewPathway] = useState(false);
  const [institutions, setInstitutions] = useState<BasicDepartmentInfo[]>([]);
  const [pathwayTemplatesVersions, setPathwayTemplatesVersions] = useState<
    PathwaysTemplateRecentVersions[]
  >([]);
  const publishButtonRef = useRef(null);

  const [changedPathwayIds, setChangedPathwayIds] = useLocalStorage<string[]>(
    CHANGED_PATHWAY_IDS_LOCAL_STORAGE_KEY,
    []
  );
  const [changedQuestionIds, setChangedQuestionIds] = useLocalStorage<string[]>(
    CHANGED_QUESTIONS_IDS_LOCAL_STORAGE_KEY,
    []
  );
  const { pathwayBuilderStore, pathwaysStore } = useStores();
  const { fetchActivePathwaysTemplate, getPathwayById, pathways, questions } = pathwayBuilderStore;

  const history = useHistory();
  const { activeTab } = useParams<{ activeTab: PathwayBuilderTab }>();
  const params = new URLSearchParams(history.location.search);
  const selectedPathwayId = params.get(PATHWAY_BUILDER_ID_QUERY_PARAM) || '';
  const isValidPathwayId = Boolean(getPathwayById(selectedPathwayId)) || isNewPathway;
  const isPathwayEditorViewVisible = selectedPathwayId && isValidPathwayId;

  useMount(async function initPage() {
    try {
      const [versions, shouldRemoveChangedQuestionsAndPathwaysFromLocalStorage, institutions] =
        await Promise.all([
          PathwayBuilderFetcher.fetchPathwaysTemplateRecentVersions(),
          fetchActivePathwaysTemplate(),
          DepartmentsFetcher.fetchInstitutions()
        ]);
      setPathwayTemplatesVersions(versions);
      setInstitutions(institutions);

      if (shouldRemoveChangedQuestionsAndPathwaysFromLocalStorage) {
        removeChangedQuestionsAndPathwaysFromLocalStorage();
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(
    function redirectToPathwaysTab() {
      if (isLoading) {
        return;
      }

      //if someone manually change the pathway id in the URL
      if (!isValidPathwayId && activeTab === PathwayBuilderTab.Pathways) {
        history.push(`/pathway-builder/${PathwayBuilderTab.Pathways}`);
      }
    },
    [history, isValidPathwayId, isLoading, activeTab]
  );

  const removeChangedQuestionsAndPathwaysFromLocalStorage = () => {
    setChangedPathwayIds([]);
    setChangedQuestionIds([]);
  };

  const handlePathwaySaved = (pathwayId: string) => {
    if (changedPathwayIds?.includes(pathwayId)) {
      return;
    }

    setChangedPathwayIds([...(changedPathwayIds || []), pathwayId]);
  };

  const handleQuestionSaved = (questionId: string) => {
    if (changedQuestionIds?.includes(questionId)) {
      return;
    }

    setChangedQuestionIds([...(changedQuestionIds || []), questionId]);
  };

  const refetchPageData = async () => {
    const [versions, shouldRemoveChangedQuestionsAndPathwaysFromLocalStorage, institutions] =
      await Promise.all([
        PathwayBuilderFetcher.fetchPathwaysTemplateRecentVersions(),
        fetchActivePathwaysTemplate(),
        DepartmentsFetcher.fetchInstitutions(),
        pathwaysStore.fetchPathwayBasicInfo(),
        pathwaysStore.fetchPathwayTemplates()
      ]);

    setPathwayTemplatesVersions(versions);
    setInstitutions(institutions);
    if (shouldRemoveChangedQuestionsAndPathwaysFromLocalStorage) {
      removeChangedQuestionsAndPathwaysFromLocalStorage();
    }
  };

  const downloadJson = async () => {
    setIsValidatingJson(true);
    const response = await PathwayBuilderFetcher.validatePathwayTemplate({
      pathways,
      questions
    });
    setIsValidatingJson(false);

    if (response.error) {
      setErrorMessage(response.error);
      setIsErrorPopupOpen(true);
      setIsPublishMenuOpen(false);
      setIsVersionsMenuOpen(false);
      return;
    }

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify({
        pathways: toJS(pathways),
        questions: toJS(questions)
      })
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `pathways-${Date.now()}.json`;

    link.click();

    setIsPublishMenuOpen(false);
    showToast({
      message: 'JSON Downloaded',
      description: 'Other users will not see changes until JSON is uploaded.'
    });
  };

  const hasChangedPathways = changedPathwayIds && changedPathwayIds?.length > 0;
  const hasChangedQuestions = changedQuestionIds && changedQuestionIds?.length > 0;
  const hasNewChanges = hasChangedPathways || hasChangedQuestions;

  if (isLoading) {
    return <FixedLoader hasBackdrop />;
  }

  if (isPathwayEditorViewVisible) {
    return (
      <PathwayEditorView
        pathwayId={selectedPathwayId}
        onBackClick={() => history.push(`/pathway-builder/${PathwayBuilderTab.Pathways}`)}
        handlePathwaySaved={handlePathwaySaved}
        isNewPathway={isNewPathway}
        institutions={institutions}
        resetData={() => setIsNewPathway(false)}
      />
    );
  }

  let versionOptions: ITooltipOption[] = pathwayTemplatesVersions.map(
    ({ updatedAt, isActive, id }, index) => ({
      groupHeader: index === 0 ? 'Previous Versions' : '',
      onClick: async () => {
        await PathwayBuilderFetcher.activatePathwayTemplate(id);
        setIsVersionsMenuOpen(false);
        await refetchPageData();
        showToast({ message: 'Staging Successfully Updated' });
      },
      isSelected: isActive,
      text: (
        <Box display="flex" flexDirection="column">
          <Text variant="form-text">{formatDate(updatedAt, 'MM/DD/YYYY')}</Text>
          <Text variant="body2">{formatDate(updatedAt, 'HH:mm A')}</Text>
        </Box>
      )
    })
  );

  if (hasNewChanges) {
    versionOptions.unshift({
      onClick: async () => {
        await PathwayBuilderFetcher.publishPathwayTemplate({
          pathways,
          questions
        });
        setIsVersionsMenuOpen(false);
        await refetchPageData();
        showToast({ message: 'Staging Successfully Updated' });
      },
      text: (
        <Box display="flex" flexDirection="column">
          <Text variant="form-text">Upload Latest Changes</Text>
          <>
            {hasChangedQuestions && (
              <Text variant="body2">
                {changedQuestionIds.length} {pluralize('Question', changedQuestionIds.length)}
              </Text>
            )}

            {hasChangedPathways && (
              <Text variant="body2">
                {changedPathwayIds.length} {pluralize('Pathway', changedPathwayIds.length)}
              </Text>
            )}
          </>
        </Box>
      )
    });
  }

  return (
    <>
      <StyledContainer data-test-hook={pathwayBuilderTestSelectors.page}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={40}>
          <Box display="flex" flexDirection="column">
            <Text variant="h1" mb={4}>
              Pathway Builder
            </Text>

            <Text variant="form-text">
              Changes are saved to the browser - do not clear browser data. Unpublished changes can
              only be seen by this user, on this device.
            </Text>
          </Box>

          <OutlinedIconSubtextButton
            icon={<Icon.DownloadArrow />}
            subText={getDownloadJsonButtonSubText(changedQuestionIds, changedPathwayIds)}
            isActive={isPublishMenuOpen}
            onClick={() => {
              if (isVersionsMenuOpen || isPublishMenuOpen) {
                setIsVersionsMenuOpen(false);
                setIsPublishMenuOpen(false);
                return;
              }
              setIsPublishMenuOpen((prevState) => !prevState);
            }}
            ref={publishButtonRef}
          >
            Publish
          </OutlinedIconSubtextButton>

          <Tooltip
            reference={publishButtonRef}
            controller={{
              visible: isPublishMenuOpen,
              onClickOutside: () => setIsPublishMenuOpen(false)
            }}
          >
            <TooltipSelect
              options={[
                {
                  disabled: !isStagingOrDev,
                  text: 'Publish to Staging...',
                  onClick: () => {
                    setIsVersionsMenuOpen(true);
                    setIsPublishMenuOpen(false);
                  }
                },
                { text: 'Download JSON', onClick: downloadJson }
              ]}
            />
          </Tooltip>

          <StyledVersionsMenu
            reference={publishButtonRef}
            controller={{
              visible: isVersionsMenuOpen,
              onClickOutside: () => setIsVersionsMenuOpen(false)
            }}
          >
            <StyledVersionsOptionsMenu options={versionOptions} />
          </StyledVersionsMenu>
        </Box>

        <StyledToggleBar
          id="pathwayBuilderToggle"
          options={PATHWAY_BUILDER_TABS}
          selected={activeTab}
          onOptionSelected={(value: PathwayBuilderTab) => history.push(`/pathway-builder/${value}`)}
          isSquared
          size="small"
        />

        {activeTab === PathwayBuilderTab.Questions && (
          <QuestionBankTabView handleQuestionSaved={handleQuestionSaved} />
        )}

        {activeTab === PathwayBuilderTab.Pathways && (
          <PathwaysTabView
            openPathwayEditor={(pathwayId) =>
              history.push({
                pathname: `/pathway-builder/${PathwayBuilderTab.Pathways}`,
                search: `?id=${pathwayId}`
              })
            }
            onNewPathwayClick={() => {
              setIsNewPathway(true);
              history.push({
                pathname: `/pathway-builder/${PathwayBuilderTab.Pathways}`,
                search: `?id=${uuid()}`
              });
            }}
          />
        )}
      </StyledContainer>

      <MessageDialog
        id={Dialogs.PathwayBuilderJsonValidationError}
        isOpen={isErrorPopupOpen}
        title="JSON Validation Error"
        handleClose={() => setIsErrorPopupOpen(false)}
        children={errorMessage}
        primaryActionProps={{ text: 'Close', onClick: () => setIsErrorPopupOpen(false) }}
      />

      {isValidatingJson && <FixedLoader hasBackdrop />}
    </>
  );
});

const StyledToggleBar = styled(ToggleBar)(
  ({ theme }) => css`
    width: fit-content;
    margin-bottom: ${theme.spacing(44)};
  `
) as typeof ToggleBar;

const StyledVersionsMenu = styled(Tooltip)`
  .tippy-content {
    min-width: 222px;
  }
`;

const StyledVersionsOptionsMenu = styled(TooltipSelect)`
  max-height: 400px;
`;
