// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { PageFiltersProps } from 'views/Filters/filters.types';
import { TicketFilters } from 'views/Filters/TicketFilters';

import { useSinglePatientPeriodicUpdate } from 'views/Filters/useFilters';

import { usePatientContext } from 'components/Patient/PatientContextProvider';

const PatientPageFiltersBase: FC<PageFiltersProps> = ({
  localStorageKey,
  className,
  defaultTasksQuery,
  defaultTicketsQuery
}) => {
  const { settingsStore } = useStores();
  const patient = usePatientContext();
  const { filters, updateFiltersByKey } = useSinglePatientPeriodicUpdate(
    patient.id,
    defaultTasksQuery,
    defaultTicketsQuery,
    localStorageKey
  );

  return (
    <TicketFilters
      filters={filters}
      updateFiltersByKey={updateFiltersByKey}
      className={className}
      reducedNumOfFiltersPerRow
      hideTags
      disableSearchName
      showTaskFilters={settingsStore.hasFeature(FEATURES.SEARCH_BY_TASK_FILTER)}
    />
  );
};

export const PatientPageFilters = observer(PatientPageFiltersBase);
