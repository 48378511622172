import { FC, useState } from 'react';

import { Grid } from '@mui/material';

import { useUpdateEffect } from 'react-use';

import QaFetcher from 'fetchers/QaFetcher';

import { usePatientModel } from 'components/Patient/usePatientModel';
import { ExternalLink } from 'components/UIkit/atoms/Link';
import { Modal } from 'components/UIkit/atoms/Modal';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const QaWebEProTokenModal: FC<Props> = ({ isOpen, onSubmit, onClose }) => {
  const patient = usePatientModel();
  const [longUrl, setLongUrl] = useState<string | null>(null);
  const [shortUrl, setShortUrl] = useState<string | null>(null);

  useUpdateEffect(
    function fetchWebEProToken() {
      const doFetch = async () => {
        if (patient?.id && isOpen) {
          const { longUrl, shortUrl } = await QaFetcher.getWebTokenForPatient(patient.id);
          setLongUrl(longUrl);
          setShortUrl(shortUrl);
        }
      };

      doFetch();
    },
    [patient?.id, isOpen]
  );

  const patientNameAndMrn = `${patient?.fullName} ${patient?.mrn ? `, MRN: ${patient.mrn}` : ''}`;

  return (
    <Modal
      isOpen={isOpen}
      title="Patient Web EPro Token"
      closeAction={{
        disabled: false,
        onClick: onClose
      }}
      confirmActions={[
        {
          onClick: onSubmit,
          text: 'Ok',
          disabled: false
        }
      ]}
    >
      <Grid container flexDirection="column" pb={24}>
        {longUrl && shortUrl ? (
          <>
            <Grid container my={24} flexWrap="wrap" flexGrow={1}>
              <Text variant="h3">
                Web EPro for patient: <strong>{patientNameAndMrn}</strong>
              </Text>
            </Grid>
            <Text variant="h3">Url (with jwt is part of the url)</Text>
            <Grid container my={24} flexWrap="wrap" flexGrow={1}>
              <ExternalLink href={longUrl}>{longUrl}</ExternalLink>
            </Grid>
            <Text variant="h3">Short link:</Text>
            <Grid container mt={24} flexWrap="wrap" flexGrow={1}>
              <ExternalLink href={shortUrl}>{shortUrl}</ExternalLink>
            </Grid>
          </>
        ) : (
          <Text>Access to Web EPro token must be done on the desired patient's page</Text>
        )}
      </Grid>
    </Modal>
  );
};
