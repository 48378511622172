import { FunctionComponent } from 'react';

import { flow, groupBy, orderBy } from 'lodash/fp';

import { useStores } from 'mobx/hooks/useStores';

import Patient from 'models/Patient';
import QuestionnaireAnswer from 'models/QuestionnaireAnswer';
import SeverityReportedCause, {
  CauseSeverity,
  isSeverityReportedCause,
  SeveritiesText
} from 'models/ReportedCause';

import { getDeltaForCause } from 'components/Ticket/TicketRow/shared';

import { TagPrefix } from './AlertTag';

import AlertTags from './AlertTags';

import './DistressCausesReport.scss';

interface Props {
  report: QuestionnaireAnswer;
  patient: Patient;
}

type GroupedDistressCauses = {
  [key in CauseSeverity]?: SeverityReportedCause[];
};

const DistressCausesReport: FunctionComponent<Props> = ({ report, patient }) => {
  const { alertsStore, constantsStore } = useStores();
  const { causes } = report.answer;

  const severityCauses = causes?.filter((cause) => isSeverityReportedCause(cause));
  const distressCauses: GroupedDistressCauses = flow(groupBy('severity'))(severityCauses);
  const distressEntries = orderBy(
    [([severity]) => severity],
    ['desc'],
    Object.entries(distressCauses)
  );

  return (
    <div>
      {distressEntries.map(([severity, causes], entryIndex) => {
        return (
          <span key={severity} className="d-inline-block">
            <div className="distress-cause-container">
              <span className="symptom-name">
                {SeveritiesText[Number(severity) as CauseSeverity]}:&nbsp;
              </span>

              {causes.map((cause: SeverityReportedCause, causeIndex) => {
                const reportedCauseName = constantsStore.symptomsMap.get(cause.causeId)?.name;
                const hasCause = Boolean(reportedCauseName);
                const dxTags = alertsStore
                  .getDxAlertDescriptionsForSymptom(cause, patient.dxCodeIds)
                  .map((text) => ({ prefix: TagPrefix.Dx, text }));
                const txTags = alertsStore
                  .getRelevantTxAlertsForSymptom(cause, patient)
                  .map((text) => ({ prefix: TagPrefix.Tx, text }));

                const deltaForCause = getDeltaForCause(cause);

                if (!hasCause) {
                  console.warn(`Couldn't find ${cause.causeId} in store`);
                }

                return hasCause ? (
                  <div key={causeIndex} className="cause-and-alerts-container">
                    <div className="cause-container">
                      <span className="cause-name">{reportedCauseName}</span>
                      <span>{deltaForCause ? <>&nbsp;{deltaForCause}</> : ''}</span>
                    </div>
                    <AlertTags tags={[...dxTags, ...txTags]} />
                    {(entryIndex < distressEntries.length - 1 ||
                      causeIndex < causes.length - 1) && (
                      <span className="comma-separator">,&nbsp;</span>
                    )}
                  </div>
                ) : null;
              })}
            </div>
          </span>
        );
      })}
    </div>
  );
};

export default DistressCausesReport;
