export const Question = () => {
  return (
    <svg width="10" height="17" viewBox="0 0 10 17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.71 14.34a1.143 1.143 0 1 1 0 2.286 1.143 1.143 0 0 1 0-2.286zM3.986.937a4.286 4.286 0 0 1 2.894.412 4.326 4.326 0 0 1 2.1 5.142A4.266 4.266 0 0 1 7.185 8.8c-.314.158-.619.332-.914.523-.233.165-.435.37-.597.606a2.086 2.086 0 0 0-.323.802l-.134 1.143v.029c-.018.097-.238.171-.506.171-.269 0-.489-.074-.506-.171l-.034-.292a18.604 18.604 0 0 1-.092-.888 3.303 3.303 0 0 1 .24-1.429c.186-.451.455-.863.795-1.214.17-.17.353-.326.548-.466l.286-.194c.057-.046.117-.08.171-.129a2.554 2.554 0 0 0-1.817-4.548 2.84 2.84 0 0 0-2.52 2.306v.022c-.011.08-.234.146-.5.149C1.017 5.223.8 5.137.8 5.023l-.01-.187C.75 3.094 2.094 1.318 3.986.937z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  );
};
