// @ts-strict-ignore
import { CommentResponse } from 'fetchers/responses/comments.response';

import { parseDate } from 'utils/DateUtils';

import Comment from 'models/Comment';

export class CommentsParser {
  static parseComments = (unparsedComments: CommentResponse[]) =>
    unparsedComments.map(CommentsParser.parseComment);

  static parseComment(unparsedComment: CommentResponse) {
    return new Comment(
      unparsedComment.user,
      unparsedComment.id,
      unparsedComment.text,
      // TODO: change when server is camelCase only
      parseDate(unparsedComment.created_at || unparsedComment.createdAt)
    );
  }
}
