import { AlertAttention, AlertUrgency } from 'models/ClinicianAlert';
import { SymptomTicketUrgency } from 'models/OperatorTicket';

export const symptomTicketUrgencyToAlertAttentionMap = {
  [SymptomTicketUrgency.NurseReview]: AlertAttention.NurseReview,
  [SymptomTicketUrgency.AttentionToday]: AlertAttention.AttentionToday,
  [SymptomTicketUrgency.ImmediateAttention]: AlertAttention.ImmediateAttention
};

export const alertAttentionByUrgency = {
  [AlertUrgency.Alert]: AlertAttention.NurseReview,
  [AlertUrgency.UrgentAlert]: AlertAttention.AttentionToday,
  [AlertUrgency.CriticalAlert]: AlertAttention.ImmediateAttention
};
