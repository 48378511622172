import { Theme } from '@mui/material';

export const getContainerColor = (theme: Theme, disabled?: boolean, error?: boolean) => {
  if (disabled) {
    return theme.palette.text.disabled;
  }

  if (error) {
    return theme.palette.error.dark;
  }

  return theme.palette.text.primary;
};
