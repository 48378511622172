// @ts-strict-ignore
import moment from 'moment';

import { useStores } from 'mobx/hooks/useStores';

import { getClinicianFullName } from 'utils/ClinicianCredentialUtils';

import Call, { CallClinician } from 'models/Call';
import Patient from 'models/Patient';

import { isNumericReportedCause, ReportedCause } from 'models/ReportedCause';

import {
  CallsAndCallbackPreferenceTableRowData,
  ReportTableCellData,
  ReportTableCellDataColor,
  ReportTableData,
  ReportTableHeaderData,
  ReportTableIcon,
  ReportTableOralChemoSection,
  ReportTableRowData,
  ReportTableSymptomSection,
  StartOfCycle,
  TableReportItem
} from './ReportTable.model';
import { addNewCycleReportData, addOralReportData } from './ReportTableDataOral.util';
import { addSymptomsReportData } from './ReportTableDataSymptoms.util';

function getDistressCellData(report: TableReportItem): ReportTableCellData {
  if (report.isOperatorReport) {
    return null;
  }
  const { distressLevel } = report.answer;
  const normalizedDistressLevel = Math.ceil((distressLevel / 10) * 4);
  return {
    color: normalizedDistressLevel,
    content: distressLevel
  };
}

function getDisplayedCall(createdAt: Date, doctor: CallClinician) {
  return `${moment(createdAt).format(`M/D/YYYY`)} ${
    doctor ? `by ${getClinicianFullName(doctor)}` : ''
  }`;
}

export function useCreateReportTableDataObject(
  allReports: TableReportItem[],
  patient: Patient
): ReportTableData {
  const { constantsStore, alertsStore } = useStores();
  const headerRow: ReportTableHeaderData[] = [];
  const distressRow: ReportTableRowData = [];
  const numericValuesSection: ReportTableSymptomSection = {};
  const callbackRow: CallsAndCallbackPreferenceTableRowData = [];
  const symptomsSection: ReportTableSymptomSection = {};
  const oralChemoSection: ReportTableOralChemoSection = {
    NewCycle: [],
    MedReceived: [],
    ActiveMedication: [],
    RegimenAdherence: [],
    ChangeInOtherMeds: [],
    MissedDose: [],
    DosesRemaining: []
  };

  // init all causes of symptomsSection
  allReports.forEach((questionnaire) => {
    questionnaire.answer?.causes?.forEach((cause: ReportedCause) => {
      const symptom = constantsStore.symptomsMap.get(cause.causeId);
      if (isNumericReportedCause(cause)) {
        if (!numericValuesSection[symptom.name]) {
          numericValuesSection[symptom.name] = [];
        }
        return;
      }
      if (!symptom) {
        console.warn(`Couldn't find ${cause.causeId} in store`);
        return;
      }

      if (!symptomsSection[symptom.name]) {
        symptomsSection[symptom.name] = [];
      }
    });
  });

  allReports.forEach((report: TableReportItem) => {
    headerRow.push({
      text: moment(report.createdAt).format('MMM Do'),
      isOperator: report.isOperatorReport
    });

    if (report.type === StartOfCycle) {
      distressRow.push(null);
      addNewCycleReportData(oralChemoSection, symptomsSection, report);
    } else {
      const distressCellData = getDistressCellData(report);
      distressRow.push(distressCellData);

      addOralReportData(
        oralChemoSection,
        report,
        constantsStore.missedDosesReasonsMap,
        constantsStore.remainingDoseOptionsMap
      );
      addSymptomsReportData(
        symptomsSection,
        numericValuesSection,
        report,
        constantsStore.symptomsMap,
        alertsStore
      );
    }

    const { value, customMessage } = report.answer.callbackRequest || {};
    let tooltipText;
    let extraText = '';

    if (!value) {
      if (customMessage) {
        extraText = ' - ' + report.answer.callbackRequest?.customMessage;
      } else if (report.answer.callbackRequest?.callbackDenyReasonId) {
        extraText =
          ' - ' +
          constantsStore.getDenyCallbackReasonById(
            report.answer.callbackRequest?.callbackDenyReasonId
          ).text;
      }
    }
    tooltipText = !report.answer.callbackRequest?.isAnswered
      ? ''
      : `${value ? 'Yes' : 'No' + extraText}`;

    let icon: ReportTableIcon;
    let calls: Call[] = [];
    let drafts: Call[] = [];
    let displayedCalls: string[] = [];
    let displayedDrafts: string[] = [];

    //check if there are calls to show
    //if yes - we display the phone call icon and all the logged calls / drafts
    if (report.callIds?.length > 0) {
      icon = ReportTableIcon.phoneCall;

      const { savedCalls, drafts: draftsCalls } = patient.callsByType;
      calls = savedCalls?.filter((call) => report.callIds?.includes(call.id));
      drafts = draftsCalls?.filter((draft) => report.callIds?.includes(draft.id));

      if (calls?.length > 0) {
        displayedCalls = calls.map((call) => {
          const clinician = call ? call.clinician : null;
          return getDisplayedCall(call.createdAt, clinician);
        });
      }

      if (drafts?.length > 0) {
        displayedDrafts = drafts.map((draft) => {
          const clinician = draft ? draft.clinician : null;
          return getDisplayedCall(draft.createdAt, clinician);
        });
      }
    }
    //if no calls - we check if we have callback preference
    //if yes - we display the check icon
    else if (Boolean(value)) {
      icon = ReportTableIcon.check;
    }
    //if no - we display the x icon
    else {
      icon = ReportTableIcon.x;
    }

    const showTableColumnContent = report.answer.callbackRequest?.isAnswered || calls?.length > 0;

    if (showTableColumnContent) {
      callbackRow.push({
        color: ReportTableCellDataColor.gray,
        iconContent: icon,
        callbackPreference: tooltipText,
        calls: displayedCalls,
        drafts: displayedDrafts
      });
    } else {
      callbackRow.push(null);
    }
  });

  return {
    headerRow,
    distressRow,
    oralChemoSection,
    symptomsSection,
    numericValuesSection,
    callbackRow
  };
}
