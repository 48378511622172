// @ts-strict-ignore
import { ReactNode } from 'react';

interface ErrorUiData {
  title: string;
  description: ReactNode;
}

export class ErrorWithUi extends Error {
  ui: ErrorUiData = { title: null, description: null };

  constructor(message: string, uiTitle?: string, uiDescription?: string) {
    super(message);
    if (uiTitle) {
      this.ui.title = uiTitle;
    }
    if (uiDescription) {
      this.ui.description = uiDescription;
    }
  }
}
