import { FC } from 'react';

import { Box, styled } from '@mui/material';

import { isEmpty } from 'lodash/fp';

import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { LightweightPathwayQuestionServerStructure } from 'fetchers/responses/pathways.response';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import {
  LightweightPathwayQuestionAction,
  LightweightPathwayQuestionAnswer,
  LightweightPathwayQuestionBaseProps,
  SingleQuestionActionMeta,
  SingleValue
} from 'components/LightweightPathway/LightweightPathway.types';

import { HtmlContent } from 'components/Ticket/TicketRow/HtmlContent';
import { RadioButton } from 'components/UIkit/atoms/RadioButton';

export const SingleQuestion: FC<
  LightweightPathwayQuestionBaseProps<SingleValue, SingleQuestionActionMeta>
> = ({ question, pathway, index, answer, handleAnswerChanged, parentQuestionId, pathwayIndex }) => {
  const onChange = (question: LightweightPathwayQuestionServerStructure, option: SingleValue) => {
    let newAnswer = { ...(answer || {}) } as LightweightPathwayQuestionAnswer<SingleValue>;

    let actionMeta: SingleQuestionActionMeta = {
      action: LightweightPathwayQuestionAction.AddFirstOption,
      optionId: option.id,
      questionId: question.id,
      type: PathwayQuestionTypes.SINGLE
    };

    if (!isEmpty(newAnswer)) {
      if (newAnswer.value.id === option.id) {
        (newAnswer.value as {}) = {};
        actionMeta = {
          ...actionMeta,
          action: LightweightPathwayQuestionAction.RemoveLastOption
        };
      } else {
        newAnswer.value = option;
        actionMeta = { ...actionMeta, action: LightweightPathwayQuestionAction.ChangeOption };
      }
    } else {
      newAnswer = {
        ...question,
        parentQuestionId,
        pathway: { ...pathway, index: pathwayIndex },
        index,
        value: option
      };
    }

    handleAnswerChanged(newAnswer, actionMeta);
  };

  return (
    <StyledContainer>
      {question.options!.map((option, optionIndex) => (
        <RadioButton
          key={option.id}
          id={option.id}
          label={<HtmlContent>{option.title}</HtmlContent>}
          onChange={() => onChange(question, option)}
          canToggle
          mb={4}
          checked={answer?.value?.id === option.id}
          testHook={sharedTestSelectors.lightweightPathwaySingleQuestionOption(option.id)}
        />
      ))}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  > div:last-of-type {
    margin-bottom: 0;
  }
`;
