import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import ParseServerResponseService from 'parsers/ParseServerResponseService';

export interface CreateEndPatientEpisodeReasonReq {
  name: string;
  isActive: boolean;
}

export interface EndEpisodeRequestBody {
  reasonId: number;
  details?: string;
}

const httpService = new HttpService('End Episode Reason', true);

export class PatientEndEpisodeReasonsFetcher {
  static async fetchReasons() {
    return await httpService.get({
      url: API_URLS.PATIENT_EPISODE_END_REASONS,
      transformResponse: ParseServerResponseService.parsePatientEpisodeEndReason
    });
  }

  static async createReason(data: CreateEndPatientEpisodeReasonReq): Promise<number> {
    return await httpService.post({ url: API_URLS.PATIENT_EPISODE_END_REASONS, data });
  }

  static async updateReason(id: number, name: string) {
    return await httpService.put({
      url: API_URLS.UPDATE_PATIENT_END_EPISODE_REASON(id),
      data: { name }
    });
  }

  static async deleteReason(id: number) {
    return await httpService.delete({ url: API_URLS.UPDATE_PATIENT_END_EPISODE_REASON(id) });
  }
}
