import { FC } from 'react';

import { InternalLink } from 'components/UIkit/atoms/Link/InternalLink';

interface Props {
  name: string;
  to: string;
}

export const PatientDetailsCell: FC<Props> = ({ name, to }) => (
  <InternalLink to={to} isEllipsis>
    {name}
  </InternalLink>
);
