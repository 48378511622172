import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import englishTranslations from 'lang/en_US.json';
import spanishTranslations from 'lang/es_US.json';
import armenianTranslations from 'lang/hy_US.json';

const resources = {
  en_US: {
    translation: englishTranslations
  },
  es_US: {
    translation: spanishTranslations
  },
  hy_US: {
    translation: armenianTranslations
  }
};
i18n.use(initReactI18next).init({
  fallbackLng: 'en_US',
  lng: 'en_US',
  resources
});

export default i18n;
