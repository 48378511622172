import React, { FC, useState } from 'react';

import { Box } from '@mui/material';

import { useForm } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';
import { Col, Row } from 'reactstrap';

import { useStores } from 'mobx/hooks/useStores';

import { parseDateForInputField } from 'utils/DateUtils';

import { SelectOption } from 'models/SelectOption';

import { PatientLocationAutocomplete } from 'views/Widgets/PatientLocationInput';
import { PatientProviderAutocomplete } from 'views/Widgets/PatientProviderInput';

import { FormInputField } from 'components/Forms';
import FormDateField from 'components/Forms/FormDateField';
import FormTextAreaField from 'components/Forms/FormTextAreaField';
import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

interface CmRowFormFields {
  patientId?: string;
  emrPatientId?: string;
  primaryInsurance?: string;
  secondaryInsurance?: string;
  cancerDxCodes?: string;
  cancerDxDescription?: string;
  chronicDxCodes?: string;
  chronicDxDescription?: string;
  monthlyCareTimeMinutes?: number;
  visitsPast12Months?: number;
  nextVisitLocation?: number;
  nextVisitProvider?: string;
  nextVisitTime?: string;
  antineoplasticAdmins: SelectOption<string>;
}

export const QaCreateCmRowModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const { qaStore, constantsStore } = useStores();
  const [antineoplasticAdmins, setAntineoplasticAdmins] = useState<SelectOption<string>[]>([]);
  const methods = useForm<CmRowFormFields>();

  useUpdateEffect(
    function fetchCmData() {
      const fetchAntineoplasticData = async () => {
        const { antineoplasticAdmins: admins } =
          await constantsStore.fetchCareManagementFilterData();
        setAntineoplasticAdmins(admins.map(({ id, name }) => ({ value: id, label: name })));
      };

      if (isOpen) {
        fetchAntineoplasticData();
      }
    },
    [isOpen]
  );

  const { handleSubmit, watch } = methods;

  const { patientId, emrPatientId } = watch();

  const handleOnSubmit = (cmRowFormFields: CmRowFormFields) => {
    const normalizedFields = {
      ...cmRowFormFields,
      antineoplasticAdmins: cmRowFormFields.antineoplasticAdmins.value
    };
    qaStore.addCmRow(normalizedFields);
    onSubmit();
  };

  return (
    <FormModal
      defaultValues={null}
      methods={methods}
      title="Create CM Row"
      isOpen={isOpen}
      confirmActions={[{ onClick: handleSubmit(handleOnSubmit), text: 'Create', disabled: false }]}
      closeAction={{ onClick: onClose, disabled: false }}
    >
      <Box mb={40}>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <FormInputField isRequired={!emrPatientId} name="patientId" label="Patient ID" />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormInputField isRequired={!patientId} name="emrPatientId" label="EMR Patient ID" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <FormInputField name="primaryInsurance" label="Primary Insurance" />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormInputField name="secondaryInsurance" label="Secondary Insurance" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <PatientLocationAutocomplete name="nextVisitLocation" label="Next Visit Location" />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <PatientProviderAutocomplete name="nextVisitProvider" label="Next Visit Provider" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <FormTextAreaField
              name="cancerDxDescription"
              label="Cancer Dx Description"
              hint="Comma separated values: X, Y, Z"
            />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormTextAreaField
              name="chronicDxDescription"
              label="Chronic Dx Description"
              hint="Comma separated values: X, Y, Z"
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} sm={6} lg={6}>
            <FormInputField
              name="monthlyCareTimeMinutes"
              label="Monthly Care Time (Minutes)"
              type="number"
            />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormInputField
              name="visitsPast12Months"
              label="Visits in past 12 months"
              type="number"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={6}>
            <FormDateField
              name="nextVisitTime"
              label="Next Visit Time"
              min={parseDateForInputField(new Date())}
            />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormAutocomplete
              label="Eligibility Score"
              options={antineoplasticAdmins}
              name="antineoplasticAdmins"
              sortAlphabetically={false}
              isRequired
            />
          </Col>
        </Row>
      </Box>
    </FormModal>
  );
};
