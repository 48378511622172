import React, { FC } from 'react';

import { css, styled } from '@mui/material/styles';

import ReactHighlighter from 'react-highlight-words';

interface Props {
  searchValue: string;
  textToHighlight: string;
  className?: string;
}

export const Highlighter: FC<Props> = ({ searchValue, textToHighlight, className }) => (
  <StyledHighlighter
    searchWords={[searchValue]}
    autoEscape
    textToHighlight={textToHighlight}
    className={className}
  />
);

const StyledHighlighter = styled(ReactHighlighter)(
  () => css`
    mark {
      background-color: transparent;
      font-weight: bold;
      padding: 0;
      color: inherit;
    }
  `
);
