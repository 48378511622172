import { FC } from 'react';

import { styled } from '@mui/material/styles';

import Icon from 'components/Icons/Icon';

const CareManagementRowIcon: FC = () => {
  return (
    <StyledContainer>
      <Icon.Cm />
    </StyledContainer>
  );
};

const StyledContainer = styled('div')`
  cursor: default;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  height: 100%;
  border-radius: 16px;
  min-width: 31px;
  margin-right: 24px;
`;
export default CareManagementRowIcon;
