import {
  NO_ANSWER_COMMENT,
  VOICEMAIL_COMMENT
} from 'views/Widgets/CallLogging/CallLoggingSaveDraft';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Select | AnalyticEventAction.Click;
type Value = typeof NO_ANSWER_COMMENT | typeof VOICEMAIL_COMMENT | 'Save Draft';

interface Properties {
  action: Action;
  virtual_page: 'call logger';
  value?: Value;
  summary_manual_edited: 'yes' | 'no';
}

export const trackSaveDraftMenuAnalyticsEvent = ({
  action,
  virtual_page,
  summary_manual_edited,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.SaveDraftMenu,
    properties: { action, virtual_page, summary_manual_edited, ...optionalProps }
  });
};
