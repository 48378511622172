import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.PasteFromClipboard
  | AnalyticEventAction.Select
  | AnalyticEventAction.Clear
  | AnalyticEventAction.Search
  | AnalyticEventAction.Cancel
  | AnalyticEventAction.AdvancedSearch
  | AnalyticEventAction.ClearAll
  | AnalyticEventAction.RefineWithAdvancedSearch;

type Value =
  | 'add new patient'
  | 'searched by EMPTY'
  | 'searched by MRN'
  | 'searched by NAME'
  | 'searched by DOB'
  | 'search result'
  | 'searched by NAME and DOB'
  | 'searched by NAME and MRN'
  | 'searched by DOB and MRN'
  | 'searched by NAME and DOB and MRN';

interface Properties {
  action: Action;
  value?: Value;
  source?: 'keyboard' | null;
  type?: 'regular search' | 'advanced search';
}

export const trackSearchUsageAnalyticsEvent = ({ action, ...optionalProps }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.SearchUsage,
    properties: { action, ...optionalProps }
  });
};
