import { useMemo } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { ALL_ROLES, NO_ROLE } from 'constants/tasks.const';

export function useRoleOptions() {
  const { constantsStore } = useStores();

  return useMemo(
    () =>
      constantsStore.rolesForSelect.map((option) => ({
        value: option.value.id,
        label: option.label,
        isEditable: option.value.id !== ALL_ROLES && option.value.id !== NO_ROLE
      })),
    [constantsStore.rolesForSelect]
  );
}
