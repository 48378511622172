import { RefObject, useEffect, useRef, useState } from 'react';

interface UseStickyReturnType<T> {
  stickyRef: RefObject<T>;
  isSticky: boolean;
}

export function useSticky<T extends HTMLElement>(): UseStickyReturnType<T> {
  const stickyRef = useRef<T>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(
    function registerStickyEventListeners() {
      // Observe when ref enters or leaves sticky state
      // rAF https://stackoverflow.com/questions/41740082/scroll-events-requestanimationframe-vs-requestidlecallback-vs-passive-event-lis
      function observe() {
        if (!stickyRef.current) return;
        const refPageOffset = stickyRef.current.getBoundingClientRect().top;
        const stickyOffset = parseInt(getComputedStyle(stickyRef.current).top);
        const stickyActive = refPageOffset <= stickyOffset;

        if (stickyActive && !isSticky) setIsSticky(true);
        else if (!stickyActive && isSticky) setIsSticky(false);
      }
      observe();

      const scrollableAppElement = document.getElementById('app')!;

      // Bind events
      scrollableAppElement?.addEventListener('scroll', observe);
      window.addEventListener('resize', observe);
      window.addEventListener('orientationchange', observe);

      return () => {
        scrollableAppElement?.removeEventListener('scroll', observe);
        window.removeEventListener('resize', observe);
        window.removeEventListener('orientationchange', observe);
      };
    },
    [isSticky]
  );

  return { stickyRef, isSticky };
}
