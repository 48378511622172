import CallPathwayAnswer from './CallPathwayAnswer';

export default class OrderedCallPathwayAnswer extends CallPathwayAnswer {
  order: number;

  constructor(callPathwayAnswer: CallPathwayAnswer, order: number) {
    super(
      callPathwayAnswer.pathwayId,
      callPathwayAnswer.pathwayName,
      callPathwayAnswer.questionId,
      callPathwayAnswer.questionTitle,
      callPathwayAnswer.questionType,
      callPathwayAnswer.categoryId,
      callPathwayAnswer.value,
      callPathwayAnswer.deniedOptions
    );
    this.order = order;
  }
}
