import { FC, ReactNode } from 'react';

import { Link as MuiLink } from '@mui/material';

import { StyledLink } from 'components/UIkit/atoms/Link/StyledLink';

import { getTypographyVariant } from 'components/UIkit/atoms/Link/utils';

interface Props {
  children?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary';
  onClick: () => void;
}

//we also have InternalLink and ExternalLink components with the "to" prop
//here we use the "onClick" prop
export const LinkButton: FC<Props> = ({
  size = 'medium',
  variant = 'primary',
  onClick,
  children
}) => (
  <StyledLink variant={getTypographyVariant(size)} color={variant}>
    <MuiLink onClick={onClick} component="button" underline="hover">
      {children}
    </MuiLink>
  </StyledLink>
);
