import { ConditionType } from 'models/ClinicianAlert';
import QuestionnaireAnswer, { IReportedInputQuestion } from 'models/QuestionnaireAnswer';

import ClinicianAlertCondition from './ClinicianAlertCondition';

export default class InputQuestionCondition extends ClinicianAlertCondition {
  private minSeverity: number;
  private questionId: number;

  constructor(rawCondition: any) {
    super(ConditionType.InputQuestion);
    this.minSeverity = rawCondition.minSeverity;
    this.questionId = rawCondition.questionId;
    this.regimenId = rawCondition.regimenId;
  }

  getConditionsString(): string {
    return `Temperature is at or above ${this.minSeverity}°F`;
  }

  isMet(report: QuestionnaireAnswer): boolean {
    // hard coded for temperature input question until we merge distress level question into input questions
    return (
      report.answer.inputQuestions &&
      report.answer.inputQuestions.some(
        (question: IReportedInputQuestion) =>
          question.id === 1 && (this.minSeverity <= question.answer || question.answer === 0)
      )
    );
  }

  getBottomThreshold(): number {
    return this.minSeverity;
  }

  getId(): number {
    return this.questionId;
  }

  setBottomThreshold(threshold: number): void {
    this.minSeverity = threshold;
  }
}
