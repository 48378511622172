// @ts-strict-ignore
import { formatDate, removeTimeZone } from 'utils/DateUtils';

import Ticket from 'models/Ticket';

type MinMaxDate = 'dueDateMax' | 'dueDateMin';

class ClusteredTaskTicket {
  id: number;
  dueDateMin: Date;
  dueDateMax: Date;
  episodeIds: number[];
  monthNumbers: number[];
  patientId: number;
  taskCount: number;
  singleTask: Ticket | null;

  constructor(clusteredTaskTicket: any, task: Ticket | null) {
    Object.assign(this, {
      ...clusteredTaskTicket,
      // since clustered tasks are not a real db entity we give them the patient id as unique id
      id: clusteredTaskTicket.singleTask
        ? clusteredTaskTicket.singleTask.id
        : clusteredTaskTicket.patientId,

      dueDateMin: new Date(removeTimeZone(clusteredTaskTicket.dueDateMin)),
      dueDateMax: new Date(removeTimeZone(clusteredTaskTicket.dueDateMax)),
      singleTask: task
    });
  }

  dueDateByTypeAsNumber(type: MinMaxDate) {
    return this[type].getDate();
  }

  formattedDateByType(type: MinMaxDate) {
    return formatDate(this[type], 'M/D/YYYY');
  }
}

export default ClusteredTaskTicket;
