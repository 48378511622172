import React, { FC, ReactNode } from 'react';

import { FormProvider, UseFormReturn, FieldValues } from 'react-hook-form';

import { useUpdateEffect } from 'react-use';

import { Modal, ModalProps } from 'components/UIkit/atoms/Modal/Modal';

interface Props extends ModalProps {
  children: ReactNode;
  defaultValues: FieldValues | null;
  methods: UseFormReturn<any>;
  isInternalModalOpen?: boolean;

  //we have a query param which initially open the modal but the data is not initializes yet (see EH-4560)
  //so the reset useEffect needs another trigger except for isOpen
  //maybe in the future we will need another triggers, so I make this property an array.
  resetFieldsTriggers?: any[];
}

export const FormModal: FC<Props> = ({
  defaultValues,
  methods,
  isOpen,
  isInternalModalOpen = false,
  resetFieldsTriggers = [],
  ...rest
}) => {
  const resetFieldsDependencies = [methods.reset, isOpen];

  if (resetFieldsTriggers.length > 0) {
    resetFieldsTriggers.forEach((trigger) => resetFieldsDependencies.push(trigger));
  }

  useUpdateEffect(function resetFields() {
    if (isOpen) {
      methods.reset(defaultValues);
    }
  }, resetFieldsDependencies);

  return (
    <FormProvider {...methods}>
      <Modal isOpen={isOpen && !isInternalModalOpen} {...rest} />
    </FormProvider>
  );
};
