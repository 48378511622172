import { FC } from 'react';

import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';

export interface WqSectionTriggerProps {
  sectionName: Section;
  totalItems: number;
}

export const WqSectionTrigger: FC<WqSectionTriggerProps> = ({ sectionName, totalItems }) => (
  <div className="report-section-header" data-test-hook={`${sectionName}_header`}>
    {sectionName} ({totalItems})
  </div>
);
