import { FC, useRef } from 'react';

import { Box, css, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Reorder } from 'framer-motion';

import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { PathwayOptionAction } from 'models/PathwayTemplates';

import { useToggle } from 'hooks/useToggle';

import {
  MIN_OPTION_COUNT,
  optionActionToLabelMap
} from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.constants';
import {
  StyledRemoveIcon,
  StyledVerticalSeparator
} from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.shared';
import { QuestionForm } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.types';

import StyledToggleButton, { LabelPosition } from 'views/Widgets/StyledToggleButton';

import Icon from 'components/Icons/Icon';
import { ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';
import { TextButton, TextIconButton } from 'components/UIkit/atoms/Button';
import { FormRichText } from 'components/UIkit/atoms/RichText/FormRichText';
import { Text } from 'components/UIkit/atoms/Text';

export const MultipleQuestionOptionsForm: FC = () => {
  const { control, formState, getValues, trigger } = useFormContext<QuestionForm>();
  const { errors } = formState;
  const {
    isOpen: isSpecialResponseMenuOpen,
    setIsOpen: setIsSpecialResponseMenuOpen,
    toggle: toggleIsSpecialResponseMenuOpen
  } = useToggle(false);
  const selectRef = useRef(null);
  const {
    fields: options,
    remove: removeOption,
    replace: replaceOptions,
    append: addOption,
    update: updateOption
  } = useFieldArray<QuestionForm, `options`, 'rhfId'>({
    control,
    name: 'options',
    rules: {
      minLength: MIN_OPTION_COUNT,
      required: true
    },
    keyName: 'rhfId'
  });

  const onRemoveOption = (index: number) => {
    removeOption(index);
  };

  const onAddOption = () => {
    addOption({
      id: uuid(),
      title: ''
    });
  };

  const addSpecialResponseOption = (action: PathwayOptionAction) => {
    addOption({
      id: uuid(),
      title: action === PathwayOptionAction.AcceptAll ? 'All of the above' : 'None of the above',
      action
    });
  };

  const onReorder = (reorderedOptions: FieldArrayWithId<QuestionForm, 'options', 'rhfId'>[]) => {
    const currentOptions = getValues('options');
    const newOptions: FieldArrayWithId<QuestionForm, 'options', 'rhfId'>[] = reorderedOptions.map(
      (option) => ({
        ...option,
        title: currentOptions?.find((currentOption) => currentOption.id === option.id)?.title || ''
      })
    );
    replaceOptions(newOptions);
    trigger('options');
  };

  const getOptionLabel = (option: FieldArrayWithId<QuestionForm, 'options', 'rhfId'>) => {
    if (option.action) {
      return optionActionToLabelMap[option.action];
    }
  };

  const noneOfTheAboveText = (
    <Box display="flex" flexDirection="column">
      <Text variant="form-text">None of the above</Text>
      <Text variant="body2">
        Deselects other responses <br />
        Denied responses exposed in Call Notes / PDF
      </Text>
    </Box>
  );

  const allOfTheAboveText = (
    <Box display="flex" flexDirection="column">
      <Text variant="form-text">All of the above</Text>
      <Text variant="body2">
        Selects all other responses <br />
        Hides “All of the Above” from Call Notes / PDF
      </Text>
    </Box>
  );

  const specialResponseOptions: ITooltipOption[] = [
    {
      text: noneOfTheAboveText,
      onClick: () => {
        addSpecialResponseOption(PathwayOptionAction.DenyAll);
        toggleIsSpecialResponseMenuOpen();
      }
    },
    {
      text: allOfTheAboveText,
      onClick: () => {
        addSpecialResponseOption(PathwayOptionAction.AcceptAll);
        toggleIsSpecialResponseMenuOpen();
      }
    }
  ];

  const handleToggleChange = (index: number, checked: boolean) => {
    const currentOptions = getValues('options');
    const currentOption = currentOptions![index];

    updateOption(index, {
      ...currentOption,
      isHomeCareInstructions: checked
    });
  };

  return (
    <>
      <Reorder.Group values={options} onReorder={onReorder} axis="y" as="div">
        {options.map((option, index) => {
          const showRemoveIcon = options.length > MIN_OPTION_COUNT;
          const fieldName = `options.${index}.title`;
          const error = errors.options ? errors.options[index]?.title : null;

          return (
            <Reorder.Item key={option.id} value={option} as="div" layout="position">
              <Grid
                container
                alignItems={option.action ? 'baseline' : 'center'}
                justifyContent="space-between"
                mb={12}
                gap={8}
              >
                <Icon.Checkbox />
                <Grid item flex={1}>
                  <FormRichText
                    name={fieldName}
                    placeholder="Response"
                    isError={Boolean(error)}
                    helperText={getOptionLabel(option)}
                    variant="outlined"
                    isRequired
                  />
                </Grid>
                <StyledToggleButton
                  checked={Boolean(option.isHomeCareInstructions)}
                  label={
                    <Text variant="body3" mr={2}>
                      Homecare Instructions
                    </Text>
                  }
                  labelPosition={LabelPosition.RIGHT}
                  onChange={(event) => {
                    handleToggleChange(index, event.target.checked);
                  }}
                />
                <StyledVerticalSeparator />
                {showRemoveIcon && <StyledRemoveIcon onClick={() => onRemoveOption(index)} />}
              </Grid>
            </Reorder.Item>
          );
        })}
      </Reorder.Group>
      <Box display="flex" alignItems="center" gap={20} pt={12}>
        <TextIconButton icon={<Icon.Plus />} onClick={onAddOption} variant="secondary">
          Add Response
        </TextIconButton>
        <Separator />
        <Tooltip
          controller={{
            visible: isSpecialResponseMenuOpen,
            onClickOutside: () => setIsSpecialResponseMenuOpen(false)
          }}
          label={
            <TextButton
              onClick={toggleIsSpecialResponseMenuOpen}
              variant="secondary"
              ref={selectRef}
            >
              Add Special Response
            </TextButton>
          }
          trigger={TooltipTrigger.CLICK}
          appendTo="parent"
          maxWidth={360}
        >
          <TooltipSelect options={specialResponseOptions} />
        </Tooltip>
      </Box>
    </>
  );
};

const Separator = styled(Box)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.dark};
    :after {
      content: '●';
    }
  `
);
