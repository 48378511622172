import { css } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledRadioButton = styled('div')(
  ({ theme }) => css`
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    -webkit-appearance: none;
    background-color: ${theme.palette.natural.white};
    border: solid 1px ${theme.palette.primary.main};
    border-radius: ${theme.borderRadius.full};
    cursor: pointer;
  `
);

export const StyledRadioButtonChecked = styled('div')(
  ({ theme }) => css`
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    -webkit-appearance: none;
    background-color: ${theme.palette.primary.main};
    border: solid 1px ${theme.palette.primary.main};
    border-radius: ${theme.borderRadius.full};
    cursor: pointer;
  `
);
