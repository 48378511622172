// @ts-strict-ignore
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { getDefaultDatesRequestParams } from 'utils/serverFiltersUtils';

import { FEATURES } from 'constants/features';

import Patient from 'models/Patient';
import Ticket, { TicketStatus } from 'models/Ticket';

import { useFeature } from 'hooks/useFeature';

import { useManageEpisodesContext } from 'views/Pages/EpisodesManagement/episode-context';
import { EpisodeAndTasksFilters } from 'views/Pages/EpisodesManagement/EpisodeAndTasksFilters';

import { useEpisodes } from 'views/Pages/EpisodesManagement/use-episodes.hooks';

import { HIGHLIGHT_TICKET_QUERY_PARAM } from 'views/Patient/PatientMain/PatientMainView.constants';

import FixedLoader from 'components/Loaders/FixedLoader';

import { ItemDeletedPopup } from 'components/NotificationCenter/ItemDeletedPopup';

import TasksSectionViewManager from 'components/Ticket/TicketsContainers/TasksSectionViewManager';

import { EpisodesSection } from './EpisodesSection';

import './PatientEpisodesAndTasks.scss';

const NoEpisodesPlaceholder: FC<{ enroll: () => void }> = ({ enroll }) => (
  <div className="no-episodes">
    No Episodes •
    <span onClick={enroll} className="enroll-episode-trigger">
      &nbsp;Click to Enroll
    </span>
  </div>
);
interface EpisodesAndTasksProps {
  patient: Patient;
}

export const PATIENT_EPISODES_AND_TASKS_FILTERS_LOCAL_STORAGE_KEY =
  'PATIENT_EPISODES_AND_TASKS_FILTERS';

export const PatientEpisodesAndTasks = observer(({ patient }: EpisodesAndTasksProps) => {
  const {
    patientEpisodesStore,
    tasksStore,
    careManagementPatientStore,
    settingsStore,
    ticketFiltersStore
  } = useStores();
  const { institutionSettings } = settingsStore;
  const { singlePatientRetrievalInterval } = institutionSettings;
  const [isLoading, setIsLoading] = useState(
    !Boolean(patientEpisodesStore.patientEpisodes.get(patient.id))
  );
  const [isDeletedPopupOpen, setIsDeletedPopupOpen] = useState(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const highlightId = Number(params.get(HIGHLIGHT_TICKET_QUERY_PARAM));
  const [ticketRefs, setTicketRefs] = useState<any>({});
  const hasCareManagement = useFeature(FEATURES.CARE_MANAGEMENT);
  const hasPatientFiltersFeature = settingsStore.hasFeature(FEATURES.PATIENT_PAGE_FILTERS);

  const ticketRefsCallback = useCallback((node: HTMLElement, ticketId: number) => {
    setTicketRefs((prev: Record<string, HTMLElement>) => ({ ...prev, [ticketId]: node }));
  }, []);

  useEffect(
    function initPatientEpisodes() {
      Promise.all([
        patientEpisodesStore.fetchPatientEpisodes(patient.id),
        hasCareManagement
          ? careManagementPatientStore.fetchPatientCmSummary(patient.id)
          : Promise.resolve()
      ]).finally(() => {
        setIsLoading(false);
      });
    },
    [tasksStore, patientEpisodesStore, careManagementPatientStore, patient.id, hasCareManagement]
  );

  useEffect(
    function initViewByMonths() {
      tasksStore.initViewByMonthsFromStorage(Boolean(highlightId));
      return () => tasksStore.resetViewByMonths();
    },
    [tasksStore, highlightId]
  );
  const ticketToScroll = ticketRefs[highlightId];

  useEffect(
    function scrollToHighlightTask() {
      if (tasksStore.allTasksFetchedForPatientId === patient.id) {
        if (ticketToScroll) {
          document.getElementById('app')!.scrollBy({
            top: ticketToScroll.getBoundingClientRect().top - window.innerHeight / 3,
            behavior: 'smooth'
          });
        } else if (highlightId && !tasksStore.ticketsMap.get(highlightId)) {
          setIsDeletedPopupOpen(true);
        }
      }
    },
    [
      ticketToScroll,
      tasksStore.allTasksFetchedForPatientId,
      tasksStore.ticketsMap,
      patient.id,
      highlightId
    ]
  );

  const { openEnrollEpisode } = useManageEpisodesContext();

  const { hasEpisodes } = useEpisodes(patient.id);

  const defaultQuery = useMemo(() => {
    const statusesToFetch = [
      ...Ticket.TICKET_ACTIVE_STATUSES,
      TicketStatus.RESOLVED,
      TicketStatus.CLOSED
    ];
    return {
      patientId: patient.id,
      taskStatuses: statusesToFetch,
      fromDate: getDefaultDatesRequestParams().fromDate
    };
  }, [patient.id]);

  useMount(function useDefaultFilters() {
    // if patient-page-filters feature flag is off (default) we want to
    // initialize this tab with default queries and start polling
    // otherwise the filter component is responsible for everything
    if (!hasPatientFiltersFeature) {
      ticketFiltersStore.setDefaultTaskQuery(defaultQuery);
      tasksStore.fetchTasksOrDelta(singlePatientRetrievalInterval);
    }
  });

  useUpdateEffect(
    function updateOnChange() {
      if (!hasPatientFiltersFeature) {
        tasksStore.resetStore();
        ticketFiltersStore.setDefaultTaskQuery(defaultQuery);
        tasksStore.fetchTasksOrDelta(singlePatientRetrievalInterval);
      }
    },
    [highlightId]
  );

  useUnmount(function resetStore() {
    // see useMount comment
    if (!hasPatientFiltersFeature) {
      tasksStore.resetStore();
    }
  });

  return (
    <div className="patient-episodes-and-tasks">
      {(isLoading || tasksStore.updatingTasks) && <FixedLoader background={!isLoading} />}
      {!isLoading && (
        <>
          {hasEpisodes ? (
            <EpisodesSection patientId={patient.id} bottomSpace={!hasPatientFiltersFeature} />
          ) : (
            <NoEpisodesPlaceholder enroll={openEnrollEpisode} />
          )}
          {isDeletedPopupOpen && (
            <ItemDeletedPopup isOpen={isDeletedPopupOpen} setIsPopupOpen={setIsDeletedPopupOpen} />
          )}
          {hasPatientFiltersFeature && (
            <div className="task-filters">
              <EpisodeAndTasksFilters patientId={patient.id} defaultQuery={defaultQuery} />
            </div>
          )}
          <TasksSectionViewManager
            tasks={tasksStore.tasks}
            ticketRefsCallback={ticketRefsCallback}
          />
        </>
      )}
    </div>
  );
});
