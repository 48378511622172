import { SortByAnalyticsValue } from 'analytics/events/sort-by';

import { CareManagementEnrolledFiltersType, CareManagementReportFiltersType } from 'mobx/stores';

import { CmPatientColumns } from 'constants/careManagement.const';

import { FilterName } from 'views/Filters/filters.types';

export enum CmEnrolledPatientColumns {
  PatientName = 'patientName',
  ProviderName = 'providerName',
  Episode = 'episodeId',
  CareTime = 'careTime',
  Visits = 'visits'
}

export const enrolledPatientsFiltersToNames: Record<
  keyof CareManagementEnrolledFiltersType,
  FilterName
> = {
  nameOrMrn: FilterName.SearchByNameOrMrn,
  providers: FilterName.Providers,
  episodes: FilterName.Episodes
};

export const enrollmentManagerFiltersToNames: Record<
  keyof CareManagementReportFiltersType,
  FilterName
> = {
  nameOrMrn: FilterName.SearchByNameOrMrn,
  providers: FilterName.PrimaryProvider,
  primaryInsurances: FilterName.PrimaryInsurance,
  secondaryInsurances: FilterName.SecondaryInsurance,
  nextVisitLocations: FilterName.LocationOfNextVisit,
  antineoplasticAdmins: FilterName.EligibilityScore,
  activeTx: FilterName.ActiveTx,
  cmStatuses: FilterName.EnrollmentStatus
};

export const cmAccessorsToNames: { [key: string]: SortByAnalyticsValue } = {
  [CmPatientColumns.PatientName]: 'Patient',
  [CmPatientColumns.ProviderName]: 'Provider',
  [CmPatientColumns.PrimaryInsurance]: 'Primary Ins.',
  [CmPatientColumns.SecondaryInsurance]: '2nd Ins.',
  [CmPatientColumns.Diagnoses]: 'Diagnoses',
  [CmPatientColumns.Visits]: 'Visits',
  [CmPatientColumns.NextVisit]: 'Next Visit',
  [CmPatientColumns.MonthlyCareTimeMinutes]: 'Monthly Care Time',
  [CmPatientColumns.CmStatus]: 'CM Status',
  [CmEnrolledPatientColumns.CareTime]: 'Care Time',
  [CmEnrolledPatientColumns.Episode]: 'Episode'
};

export type ValueOf<T> = T[keyof T];
