import { FC } from 'react';

import { Grid } from '@mui/material';

import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { LocationRequestData } from 'fetchers/QaFetcher';

import { FormInputField } from 'components/Forms';
import { Separator } from 'components/Separator';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

interface LocationFormFields {
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export const QaAddLocationModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const { qaStore } = useStores();
  const methods = useForm<LocationFormFields>();

  const { handleSubmit } = methods;

  const handleOnSubmit = (locationFields: LocationFormFields) => {
    // avoid sending empty fields
    const location: LocationRequestData = {
      name: locationFields.name,
      address1: locationFields.address1 || undefined,
      address2: locationFields.address2 || undefined,
      city: locationFields.city || undefined,
      state: locationFields.state || undefined,
      zip: locationFields.zip || undefined
    };

    qaStore.addLocation(location);
    onSubmit();
  };

  return (
    <FormModal
      defaultValues={null}
      methods={methods}
      title="Add Location"
      isOpen={isOpen}
      confirmActions={[{ onClick: handleSubmit(handleOnSubmit), text: 'Add', disabled: false }]}
      closeAction={{ onClick: onClose, disabled: false }}
    >
      <Grid container mb={40} spacing={12}>
        <Grid item xs={12}>
          <FormInputField isRequired name="name" label="Name" />
        </Grid>
        <Grid item xs={12}>
          Filling in anything below this line will also create an EMR location record
          <Separator />
        </Grid>
        <Grid item xs={12}>
          <FormInputField name="address1" label="Address 1" />
        </Grid>
        <Grid item xs={12}>
          <FormInputField name="address2" label="Address 2" />
        </Grid>
        <Grid item xs={6}>
          <FormInputField name="city" label="City" />
        </Grid>
        <Grid item xs={6}>
          <FormInputField name="state" label="State" maxLength={2} />
        </Grid>
        <Grid item xs={6}>
          <FormInputField name="zip" label="Zip" />
        </Grid>
      </Grid>
    </FormModal>
  );
};
