import { FC, ForwardedRef, forwardRef, ForwardRefExoticComponent, ReactNode } from 'react';

import { css, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

import { Testable } from 'utils/TypeUtils';

import { Checkbox, CheckboxProps } from 'components/UIkit/atoms/Checkbox/Checkbox';
import { getContainerColor } from 'components/UIkit/atoms/Checkbox/utils';
import { withSpacing, WithSpacingProps } from 'components/UIkit/theme/spacing';

interface InternalProps extends CheckboxProps, Testable {
  label: ReactNode;
  forwardedRef?: ForwardedRef<HTMLInputElement>;
  alignItems?: 'center' | 'flex-start';
}

export type ExternalLabeledCheckboxProps = Omit<InternalProps, 'forwardedRef'> & WithSpacingProps;

const InternalLabeledCheckbox: FC<InternalProps> = ({
  id,
  label,
  checked,
  disabled = false,
  error = false,
  name,
  onChange,
  onKeyDown,
  readonly,
  testHook,
  forwardedRef,
  indeterminate,
  alignItems = 'center'
}) => (
  <StyledContainer disabled={disabled} error={error} alignItems={alignItems}>
    <Checkbox
      id={id}
      name={name}
      disabled={disabled}
      readonly={readonly}
      error={error}
      checked={checked}
      onChange={onChange}
      onKeyDown={onKeyDown}
      testHook={testHook}
      ref={forwardedRef}
      indeterminate={indeterminate}
    />

    <Typography
      variant="form-text"
      htmlFor={id}
      component="label"
      data-test-hook={`${testHook}_label`}
    >
      {label}
    </Typography>
  </StyledContainer>
);

const StyledContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'disabled' && prop !== 'alignItems'
})<{
  disabled: boolean;
  error: boolean;
  alignItems: 'center' | 'flex-start';
}>(
  ({ theme, disabled, error, alignItems }) => css`
    display: flex;
    align-items: ${alignItems};
    gap: ${theme.spacing(8)};
    color: ${getContainerColor(theme, disabled, error)};

    label {
      margin-bottom: 0;
      cursor: ${disabled ? 'initial' : 'pointer'};
    }
  `
);

const LabeledCheckboxWithSpacing = withSpacing(InternalLabeledCheckbox);

export const LabeledCheckbox: FC<ExternalLabeledCheckboxProps> = forwardRef<
  HTMLInputElement,
  ExternalLabeledCheckboxProps
>((props, ref) => (
  <LabeledCheckboxWithSpacing {...props} forwardedRef={ref} />
)) as ForwardRefExoticComponent<ExternalLabeledCheckboxProps>;
