import iterateMentionsMarkup from './iterateMentionsMarkup';

// For a given indexInPlainText that lies inside a mention,
// returns a the index of of the first and last char of the mention in the plain text.
// If indexInPlainText does not lie inside a mention, returns indexInPlainText.
const findMentionBoundsInPlainText = (value, config, indexInPlainText) => {
  let startOfMention = indexInPlainText;
  let endOfMention = null;
  let foundMention = false;

  let markupIteratee = (
    markup,
    index,
    mentionPlainTextIndex,
    id,
    display,
    childIndex,
    lastMentionEndIndex
  ) => {
    if (
      mentionPlainTextIndex <= indexInPlainText &&
      mentionPlainTextIndex + display.length > indexInPlainText
    ) {
      startOfMention = mentionPlainTextIndex;
      endOfMention = mentionPlainTextIndex + display.length;
      foundMention = true;
    }
  };
  iterateMentionsMarkup(value, config, markupIteratee);

  if (foundMention) {
    return { startOfMention, endOfMention };
  }
};

export default findMentionBoundsInPlainText;
