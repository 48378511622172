import { FC } from 'react';

import { Box, css, styled, Typography } from '@mui/material';

import { SuggestedPathwayUrgency } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

interface Props {
  urgency: SuggestedPathwayUrgency;
  pathwayName?: string;
}

export const PathwayQuestionOptionSuggestedUrgency: FC<Props> = ({
  urgency,
  pathwayName = 'Pathway'
}) => {
  let text = 'Consider';

  if (urgency === SuggestedPathwayUrgency.High) {
    text = 'Continue in';
  }

  text = `${text} ${pathwayName}`;

  return (
    <StyledContainer py={2} px={4} urgency={urgency} maxWidth="240px">
      <Typography color="secondary.dark" variant="h4" noWrap>
        {text}
      </Typography>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'urgency'
})<{ urgency: SuggestedPathwayUrgency }>(
  ({ theme, urgency }) => css`
    background-color: ${urgency === SuggestedPathwayUrgency.High
      ? theme.palette.error.light
      : theme.palette.primary.contrastText};
    border-radius: ${theme.borderRadius.xSmall};
    width: fit-content;
  `
);
