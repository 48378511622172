import { Box } from '@mui/material';

import {
  PathwayQuestionOption,
  PathwayTemplatesQuestion
} from 'fetchers/responses/pathways-builder.response';

import { StyledOptionItemContainer } from 'views/Pages/PathwayBuilder/shared/styled';

import { TableCellParams } from 'components/Table';
import { Text } from 'components/UIkit/atoms/Text';

export const HomeCareInstructionsCell = ({
  value: questionHasHomeCareInstructions,
  row
}: TableCellParams<PathwayTemplatesQuestion>) => {
  const options = row?.original?.options?.map((option: PathwayQuestionOption) => {
    return option.isHomeCareInstructions ? (
      <StyledOptionItemContainer key={option.id}>Yes</StyledOptionItemContainer>
    ) : (
      <StyledOptionItemContainer key={option.id} />
    );
  });

  return (
    <Box display="flex" flexDirection="column">
      <Text variant="form-text" isEllipsis>
        <Box>{questionHasHomeCareInstructions ? 'Yes' : ''}</Box>
      </Text>
      {row.isExpanded && <Box mt={20}>{options}</Box>}
    </Box>
  );
};
