import { ErrorWithUi } from 'errors';
import { omit } from 'lodash/fp';

import { handleError } from 'services/errorHandlingService';

import { sanitizeFiltersOrQuery } from 'utils/serverFiltersUtils';

export const getFiltersFromLocalStorageByKey = (localStorageKey: string) => {
  let unparsedFilters;
  try {
    unparsedFilters = localStorage.getItem(localStorageKey);
    if (unparsedFilters) {
      let parsedFilters = JSON.parse(unparsedFilters);
      parsedFilters = omit(['careTeams', 'fromDate', 'toDate'], parsedFilters);
      return sanitizeFiltersOrQuery(parsedFilters) as any;
    }
  } catch (e: any) {
    // since this is a synchronous action - handleError should be called here directly (so it won't stop the render function)
    const error = new ErrorWithUi(
      'Parsing filters from local storage failed',
      `${e.message}: ${unparsedFilters}`
    );
    handleError(error);
  }
};
