import { FC, ReactNode } from 'react';

import { Col, Row } from 'reactstrap';

interface Props {
  children: ReactNode;
  classNames?: string;
}
export const InvitationFormSection: FC<Props> = (props) => (
  <Row className={props.classNames}>
    <Col xs={1} sm={1} lg={1}>
      <div className="section-number" />
    </Col>
    <Col xs={11} sm={11} lg={11}>
      {props.children}
    </Col>
  </Row>
);
