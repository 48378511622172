// @ts-strict-ignore
import { useMemo } from 'react';

import {
  trackDropdownFilterUsageAnalyticsEvent,
  trackSearchByNameOrMrnFilterUsageAnalyticsEvent
} from 'analytics/events/filter-usage';
import debounce from 'debounce-promise';
import { ActionMeta } from 'react-select';
import { Col, Row } from 'reactstrap';

import PatientProvider from 'models/PatientProvider';

import SearchBar from 'views/Dashboard/SearchBar';
import { FilterName, PatientFilters } from 'views/Filters/filters.types';
import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';
import { ValueOf } from 'views/Pages/CareManagement/CareManagementPage.utils';

import { ISelectOption } from 'components/UIkit/atoms/Dropdown/Select.shared';

import { LocationsFilterField, ProvidersFilterField } from './FilterFields';
import { useClientPersistentFilters } from './useFilters';

import './PageFilters.scss';

interface IPatientsActivationPageFiltersProps {
  filters: PatientFilters;
  onFilterChange: (filters: PatientFilters) => void;
  localStorageKey?: string;
}

export const PatientsActivationPageFilters = ({
  filters,
  onFilterChange,
  localStorageKey
}: IPatientsActivationPageFiltersProps) => {
  const updateFiltersByKey = useClientPersistentFilters<PatientFilters>(filters, onFilterChange, {
    localStorageKey
  });

  const onTrackSearchAnalyticsEvent = useMemo(() => {
    return debounce(
      (searchQuery: string) => trackSearchByNameOrMrnFilterUsageAnalyticsEvent(searchQuery),
      DEBOUNCE_DELAY
    );
  }, []);

  const onDropdownFilterChange = (
    values: ValueOf<ISelectOption<PatientProvider>>,
    filterKey: keyof PatientFilters,
    filterName: FilterName,
    actionMeta: ActionMeta<any>
  ) => {
    trackDropdownFilterUsageAnalyticsEvent(actionMeta, filterName);

    updateFiltersByKey(filterKey)(values);
  };

  return (
    <div className="page-filters">
      <Row>
        <Col xs={12} sm={4} lg={4}>
          <SearchBar
            className="search"
            placeholder="Search by Name or MRN"
            searchValue={filters.searchTerm}
            onSearchChanged={(value) => {
              onTrackSearchAnalyticsEvent(value);
              updateFiltersByKey('searchTerm')(value);
            }}
          />
        </Col>
        <Col xs={12} sm={4} lg={4}>
          <ProvidersFilterField
            value={filters.providers}
            onChange={(values, actionMeta) =>
              onDropdownFilterChange(values, 'providers', FilterName.Providers, actionMeta)
            }
          />
        </Col>
        <Col xs={12} sm={4} lg={4}>
          <LocationsFilterField
            value={filters.locations}
            onChange={(values, actionMeta) =>
              onDropdownFilterChange(values, 'locations', FilterName.Locations, actionMeta)
            }
          />
        </Col>
      </Row>
    </div>
  );
};
