// @ts-strict-ignore
import { FunctionComponent, Children, PropsWithChildren, useMemo, ReactNode, FC } from 'react';

import classNames from 'classnames';

import Loading from 'components/Loaders/Loading';
import { CardRow } from 'components/Surfaces/Card';

import './CardStack.scss';

interface CardStackClasses {
  open?: string;
  closed?: string;
  root?: string;
  stacked?: string;
}

interface Props {
  children: ReactNode;
  isOpen: boolean;
  isSelected?: boolean;
  classes?: CardStackClasses;
  onClick?: () => void;
  numberOfStackedItems: number;
}

interface DummyStackedElementsProps {
  size: number;
}

export const getNumberOfStackedItems = (itemsLength: number) => {
  if (!itemsLength) {
    return 0;
  }

  if (itemsLength === 1) {
    return 1;
  }

  return 2;
};

const DummyStackedElements: FC<DummyStackedElementsProps> = ({ size }) => {
  if (!size) {
    return null;
  }

  return (
    <>
      {Array(size)
        .fill(null)
        .map((item, index) => (
          <div key={index} className="fake-stacked-element" />
        ))}
    </>
  );
};

const CardStack: FunctionComponent<Props> = ({
  isOpen,
  isSelected = false,
  children,
  classes,
  onClick,
  numberOfStackedItems
}) => {
  const [firstStackedElement, ...restStackedElements] = Children.map(
    children,
    (element) => element
  );

  const isStacked = restStackedElements.length >= 1;
  const isStackedOpen = isStacked && isOpen;
  const isStackedClosed = isStacked && !isOpen;
  const containerClasses = classNames('stack', classes?.root, {
    stacked: isStacked,
    closed: isStackedClosed,
    open: isStackedOpen,
    selected: isSelected,
    [classes?.stacked]: isStacked,
    [classes?.open]: isStackedOpen,
    [classes?.closed]: isStackedClosed
  });

  const stackedElementClasses = classNames('stacked-element', {
    selected: isSelected
  });

  return (
    <CardRow>
      <div className={containerClasses}>
        <div className={stackedElementClasses} onClick={onClick}>
          {firstStackedElement}
        </div>
        {isStackedOpen &&
          restStackedElements.map((element, index) => (
            <div key={index} className="stacked-element">
              {element}
            </div>
          ))}
        {isStackedClosed && (
          <DummyStackedElements size={getNumberOfStackedItems(numberOfStackedItems)} />
        )}
      </div>
    </CardRow>
  );
};

interface LazyCardStackProps {
  isLoading?: boolean;
  isOpen: boolean;
  isSelected?: boolean;
  classes?: CardStackClasses;
  trigger: ReactNode;
  items: ReactNode[] | null;
  loader?: ReactNode;
}

export const LazyCardStack = ({
  isLoading,
  isOpen,
  isSelected,
  classes,
  trigger,
  items,
  loader = <Loading />
}: PropsWithChildren<LazyCardStackProps>) => {
  const containerClasses = classNames('stack stacked', classes.root, {
    closed: !isOpen,
    open: isOpen,
    selected: isSelected,
    [classes?.stacked]: isOpen,
    [classes?.open]: isOpen,
    [classes?.closed]: !isOpen
  });

  const stackedElementClasses = classNames('stacked-element', {
    selected: isSelected
  });

  const shouldShowItems = isOpen && !isLoading && items?.length;

  const itemsList = useMemo(
    () =>
      shouldShowItems
        ? items.map((element, index) => (
            <div key={index} className="stacked-element">
              {element}
            </div>
          ))
        : null,
    [items, shouldShowItems]
  );

  return (
    <CardRow>
      <div className={containerClasses}>
        <div className={stackedElementClasses}>{trigger}</div>
        {isLoading && loader}
        {itemsList}
        {!isOpen && <DummyStackedElements size={2} />}
      </div>
    </CardRow>
  );
};

export default CardStack;
