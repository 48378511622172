import { FC } from 'react';

import { styled } from '@mui/material';
import { components, MenuProps } from 'react-select';

export const PaginatorSelectMenu: FC<MenuProps> = ({ children, ...props }) => (
  <StyledMenu {...props}>{children}</StyledMenu>
);

const StyledMenu: FC<MenuProps> = styled(components.Menu, { shouldForwardProp: () => true })`
  z-index: 1300;
  width: 44px;
  box-shadow: 0 2px 4px 0 #00000040;
  padding: 0;
  left: 50%;
  transform: translateX(-50%);
`;
