import { PatientEpisodeResponse } from 'fetchers/responses/patient-episodes.response';

import PatientEpisode from 'models/PatientEpisode';

export class PatientEpisodesParser {
  static parsePatientEpisodes = (
    unparsedPatientEpisodes: PatientEpisodeResponse[]
  ): PatientEpisode[] => unparsedPatientEpisodes.map(PatientEpisodesParser.parsePatientEpisode);

  static parsePatientEpisode = (unparsedPatientEpisode: PatientEpisodeResponse) =>
    new PatientEpisode(unparsedPatientEpisode);
}
