import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { castArray } from 'lodash';
import {
  HeaderGroup,
  Hooks,
  IdType,
  Row,
  TableState,
  useExpanded,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable
} from 'react-table';
import { useUpdateEffect } from 'react-use';

import { UrlSortParams } from 'utils/urlUtils';

import { useNavPagination } from 'hooks/useNavPagination';
import { useNavSort } from 'hooks/useNavSort';

import StyledToggleButton, { LabelPosition } from 'views/Widgets/StyledToggleButton';

import { PaginatorAction, Paginator } from 'components/Paginator';

import { Checkbox } from 'components/UIkit/atoms/Checkbox';

import { BaseTableOptions, PaginationLocation, SortOptions } from './Table.shared';
import { useSortOverrides } from './Table.utils';
import TableBodyContainer from './TableBodyContainer';
import TableCell from './TableCell';
import TableContent from './TableContent';
import TableHeader from './TableHeader';
import { TableSelectAllNotification } from './TableNotification';
import TableRow from './TableRow';

import './PagedTable.scss';

declare module 'react-table' {
  export interface UseRowSelectState<D extends object> {
    selectedRowIds: Record<IdType<D>, boolean>;
    isAllRowsInTableSelected: boolean;
  }
}

interface PagedTableOptions<D extends object = {}> extends BaseTableOptions<D> {
  totalPages: number;
  isLoading: boolean;
  disableSelect?: boolean;
  paginationLocation: PaginationLocation[];
  expandAllOptions?: { expandedText: string; collapsedText: string };
  onManualSort?: (column: HeaderGroup<any>, sortParams: UrlSortParams) => void;
  onToggleAllRows?: (isAllRowsExpanded: boolean) => void;
  totalItemsCount?: number;
  isRowsSelectable?: boolean;
  onSelectedRowChange?: (selectedRowIds: string[], isAllRowsInTableSelected: boolean) => void;
  shouldUnselectAllRows?: boolean;
  resetShouldUnselectAllRows?: () => void;
  onToggleSelectedRow?: (isRowSelected: boolean) => void;
  onToggleAllSelectedRowsInPage?: (isPageSelected: boolean) => void;
  onToggleAllSelectedRowsInTable?: (isTableSelected: boolean) => void;
  getRowId?: (originalRow: D, relativeIndex: number, parent?: Row<D>) => string;
  dashedRowRule?: (original: D) => boolean;
  onManualPageChange?: (currentPage: number, action: PaginatorAction) => void;
}

export const PagedTable = <D extends {}>({
  columns,
  rowData,
  rowLink,
  rowAction,
  hideHeaders,
  emptyTableView,
  expandAllOptions,
  // if using paging, must use all paging props (TODO, make paging props enforced)
  totalPages,
  totalItemsCount = 0,
  isLoading,
  paginationLocation,
  onManualSort,
  onToggleAllRows,
  disableSelect,
  isRowsSelectable = false,
  onSelectedRowChange,
  shouldUnselectAllRows,
  resetShouldUnselectAllRows,
  onToggleSelectedRow,
  onToggleAllSelectedRowsInTable,
  onToggleAllSelectedRowsInPage,
  getRowId,
  dashedRowRule,
  onManualPageChange
}: // if using paging, must use all paging props
PagedTableOptions<D>) => {
  const { setPageInUrl, currentPageByUrl } = useNavPagination();
  const { setSortInUrl } = useNavSort();
  const [shouldResetScroll, setShouldResetScroll] = useState(false);

  //When this state is true, all the rows in table become selected but just from the UI perspective, state.selectedRowIds doesn't change
  const [isAllRowsInTableSelected, setIsAllRowsInTableSelected] = useState(false);

  const useRowSelectPlugin = (hooks: Hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: 'selection',
        width: 50,
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllPageRowsSelectedProps, state }) => {
          const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            if (state.isAllRowsInTableSelected) {
              toggleAllRowsSelected(false); //clear all the selected rows from all pages
              toggleAllPageRowsSelected(true); //select the current page rows
            }

            const { onChange } = getToggleAllPageRowsSelectedProps();

            if (onChange) {
              onChange(event);

              if (onToggleAllSelectedRowsInPage) {
                onToggleAllSelectedRowsInPage(event.target.checked);
              }
            }
          };

          const isIndeterminate =
            (getToggleAllPageRowsSelectedProps()?.indeterminate ||
              getToggleAllPageRowsSelectedProps()?.checked ||
              Object.keys(state.selectedRowIds).length > 0) &&
            !state.isAllRowsInTableSelected;

          const isChecked =
            state.isAllRowsInTableSelected || getToggleAllPageRowsSelectedProps()?.checked;

          return (
            <div>
              <Checkbox
                {...getToggleAllPageRowsSelectedProps()}
                variant="secondary"
                checked={isChecked}
                disabled={disableSelect}
                indeterminate={isIndeterminate}
                onChange={handleChange}
              />
            </div>
          );
        },
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row, state }) => {
          const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            if (state.isAllRowsInTableSelected) {
              toggleAllRowsSelected(false);
              toggleAllPageRowsSelected(true);
            }

            const { onChange } = row.getToggleRowSelectedProps();

            if (onChange) {
              onChange(event);

              if (onToggleSelectedRow) {
                onToggleSelectedRow(event.target.checked);
              }
            }
          };

          return (
            <div>
              <Checkbox
                {...row.getToggleRowSelectedProps()}
                disabled={disableSelect}
                variant="secondary"
                checked={state.isAllRowsInTableSelected || row.getToggleRowSelectedProps()?.checked}
                onChange={handleChange}
              />
            </div>
          );
        }
      },
      ...columns
    ]);
  };

  const plugins = [];

  if (isRowsSelectable) {
    plugins.push(...[useRowSelect, useRowSelectPlugin]);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    toggleAllRowsExpanded,
    isAllRowsExpanded,
    toggleAllRowsSelected,
    state,
    isAllRowsSelected: isAllRowsInPageSelected,
    toggleAllPageRowsSelected
  } = useTable(
    {
      columns,
      data: rowData,
      initialState: {
        // @ts-ignore
        // this will be ignored if manualSort is true
        sortBy: useMemo(
          () =>
            columns
              .filter((column) => column.defaultSort)
              .map((column) => ({
                id: column.accessor,
                desc: column.defaultSort === SortOptions.desc
              })),
          [columns]
        )
      },
      getRowId: useCallback(
        (originalRow, relativeIndex, parent: Row<any>) => {
          if (isRowsSelectable && getRowId) {
            return getRowId(originalRow, relativeIndex, parent);
          }

          return parent ? [parent.id, relativeIndex].join('.') : relativeIndex.toString();
        },
        [isRowsSelectable, getRowId]
      ),
      manualPagination: true,
      manualSortBy: true,
      pageCount: totalPages,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      autoResetRowState: false,
      stateReducer: (newState, action): TableState<D | {}> => {
        switch (action.type) {
          //we change this action to clear all state.selectedRowIds and not just the selected row from the current page.
          case 'toggleAllRowsSelected':
            if (action.value === false) {
              setIsAllRowsInTableSelected(false);

              return {
                ...newState,
                selectedRowIds: {}
              };
            }

            return newState;

          default:
            return newState;
        }
      },
      useControlledState: (state) => {
        const overrides: Partial<TableState> = {
          pageIndex: currentPageByUrl,
          isAllRowsInTableSelected
        };
        const sortingOverrides = useSortOverrides();
        overrides.sortBy = sortingOverrides.sortBy;
        return { ...state, ...overrides };
      }
    },
    useSortBy,
    useExpanded,
    useFlexLayout,
    usePagination,
    useRowState,
    ...plugins
  );

  const showSelectAllNotification =
    (totalItemsCount > rowData.length && isAllRowsInPageSelected) || isAllRowsInTableSelected;

  useEffect(
    function scrollToTopOnPageChanged() {
      if (shouldResetScroll) {
        setShouldResetScroll(false);
        document.getElementById('app')?.scrollTo({ top: 0 });
      }
    },
    [shouldResetScroll]
  );

  useUpdateEffect(
    function handleSelectedRowChange() {
      if (onSelectedRowChange && isRowsSelectable) {
        const ids = Object.keys(state.selectedRowIds);
        onSelectedRowChange(ids, isAllRowsInTableSelected);
      }
    },
    [state.selectedRowIds, isRowsSelectable, isAllRowsInTableSelected]
  );

  //this useEffect is for cleaning the row selection after filtering
  useEffect(
    function unselectAllSelectedRows() {
      if (shouldUnselectAllRows && isRowsSelectable) {
        toggleAllRowsSelected(false);

        if (resetShouldUnselectAllRows && isRowsSelectable) {
          resetShouldUnselectAllRows();
        }
      }
    },
    [resetShouldUnselectAllRows, shouldUnselectAllRows, toggleAllRowsSelected, isRowsSelectable]
  );

  paginationLocation = castArray(paginationLocation);

  const content = useMemo(() => {
    return page.map((row, rowIndex) => {
      prepareRow(row);
      const cells = row.cells.map((cell) => (
        <TableCell key={cell.column.id} isExpanded={row.isExpanded} {...cell} />
      ));

      let isSelected: boolean = false;

      if (state.isAllRowsInTableSelected) {
        isSelected = true;
      } else if (state.selectedRowIds) {
        isSelected = state.selectedRowIds[getRowId ? getRowId(row.original, rowIndex) : rowIndex];
      }

      const rowClassNames = classNames({
        dashed: dashedRowRule ? dashedRowRule(row.original) : false
      });

      return (
        <TableRow
          isSelected={isSelected}
          key={row.id}
          row={row}
          rowAction={rowAction}
          rowLink={rowLink}
          rowContent={cells}
          rowClasses={rowClassNames}
        />
      );
    });
  }, [
    page,
    prepareRow,
    rowLink,
    rowAction,
    state.selectedRowIds,
    state.isAllRowsInTableSelected,
    getRowId,
    dashedRowRule
  ]);

  const handlePageChanged = (
    currentPage: number,
    action: PaginatorAction,
    shouldResetScroll: boolean
  ) => {
    setPageInUrl(currentPage - 1);
    setShouldResetScroll(shouldResetScroll);
    onManualPageChange && onManualPageChange(currentPage, action);
    return;
  };

  const selectAllRowsInTable = () => {
    if (isRowsSelectable && onToggleAllSelectedRowsInTable) {
      onToggleAllSelectedRowsInTable(!isAllRowsInTableSelected);
    }

    setIsAllRowsInTableSelected(true);
  };

  const unselectAllRowsInTable = () => {
    if (isRowsSelectable && onToggleAllSelectedRowsInTable) {
      onToggleAllSelectedRowsInTable(!isAllRowsInTableSelected);
    }

    toggleAllRowsSelected(false);
  };

  const showTableTop = expandAllOptions || paginationLocation?.includes(PaginationLocation.TOP);

  return (
    <>
      {showTableTop && (
        <div className="table-top-container">
          {expandAllOptions && (
            <StyledToggleButton
              className="expand-all-btn"
              checked={isAllRowsExpanded}
              testHook="expand-collapse-toggle"
              label={
                isAllRowsExpanded ? expandAllOptions.expandedText : expandAllOptions.collapsedText
              }
              labelPosition={LabelPosition.RIGHT}
              onChange={() => {
                toggleAllRowsExpanded(!isAllRowsExpanded);

                if (onToggleAllRows) {
                  onToggleAllRows(!isAllRowsExpanded);
                }
              }}
            />
          )}

          {paginationLocation?.includes(PaginationLocation.TOP) && pageCount > 0 && (
            <Paginator
              currentPage={currentPageByUrl + 1}
              totalPages={pageCount}
              onPageChange={(currentPage, action) => handlePageChanged(currentPage, action, false)}
              testHookPrefix="pagination-top"
            />
          )}
        </div>
      )}

      {showSelectAllNotification && (
        <TableSelectAllNotification
          allItemsCount={totalItemsCount}
          unselectAll={unselectAllRowsInTable}
          isAllRowsInTableSelected={isAllRowsInTableSelected}
          selectAllRowsInTable={selectAllRowsInTable}
        />
      )}

      <TableContent {...getTableProps()}>
        <div>
          {!hideHeaders &&
            headerGroups.map((headerGroup) => {
              const { key, ...restHeaderProps } = headerGroup.getHeaderGroupProps();
              return (
                <div key={key} {...restHeaderProps}>
                  {headerGroup.headers.map((column) => {
                    return (
                      column.isVisible && (
                        <TableHeader
                          key={column.id}
                          column={column}
                          manualSort
                          setSortInUrl={setSortInUrl}
                          style={{
                            flex: `${column.totalWidth} 0 auto`,
                            width: column.totalWidth
                          }}
                          onManualSort={onManualSort}
                        />
                      )
                    );
                  })}
                </div>
              );
            })}
        </div>

        <div {...getTableBodyProps()}>
          <TableBodyContainer
            content={content}
            hasData={Boolean(page.length)}
            emptyTableView={isLoading ? null : emptyTableView}
          />
        </div>
      </TableContent>

      {paginationLocation?.includes(PaginationLocation.BOTTOM) && pageCount > 0 && (
        <div className="table-bottom-container">
          <Paginator
            currentPage={currentPageByUrl + 1}
            totalPages={pageCount}
            onPageChange={(currentPage, action) => handlePageChanged(currentPage, action, true)}
          />
        </div>
      )}
    </>
  );
};
