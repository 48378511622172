// @ts-strict-ignore
import { ConditionType } from 'models/ClinicianAlert';
import { OralQuestionStatus, OralReportVersion } from 'models/OralReport/OralReport.shared';
import { OralReportV1, OralQuestionTypeV1 } from 'models/OralReport/OralReportV1';
import { OralReportV2, OralQuestionTypeV2 } from 'models/OralReport/OralReportV2';
import QuestionnaireAnswer, { ReportType } from 'models/QuestionnaireAnswer';

import ClinicianAlertCondition from './ClinicianAlertCondition';

enum OralAlertComparators {
  EQUALS = 'equals',
  GREATER_THAN = 'greaterThan'
}

export default class OralConditions extends ClinicianAlertCondition {
  private readonly answer: any;
  private readonly questionType: OralQuestionTypeV1 | OralQuestionTypeV2;
  private readonly answerStatus: OralQuestionStatus;
  private readonly condition: OralAlertComparators;

  constructor(rawCondition: any) {
    super(ConditionType.Oral);
    this.answer = rawCondition.answer;
    this.questionType = rawCondition.question;
    this.answerStatus = rawCondition.answerStatus;
    this.condition = rawCondition.condition;
    this.regimenId = rawCondition.regimenId;
  }

  getConditionsString(): string {
    return ``; // not implemented at the moment since we don't show oral alerts in alerts table
  }

  isMet(report: QuestionnaireAnswer): boolean {
    if (report.type !== ReportType.Oral) {
      return false;
    }

    if (
      this.answerStatus === OralQuestionStatus.IDontKnow &&
      ((!report.answer.version && (report.answer.oral as OralReportV1).firstPill?.isIDontKnow) ||
        (report.answer.version === OralReportVersion.V2 &&
          (report.answer.oral as OralReportV2)[OralQuestionTypeV2.StartOfCycle]?.isIDontKnow))
    ) {
      return true;
    }
    if (this.answerStatus === OralQuestionStatus.Answered) {
      if (!report.answer.version) {
        const oralReportV1 = report.answer.oral as OralReportV1;

        if (this.condition === OralAlertComparators.EQUALS) {
          return this.answer === oralReportV1[this.questionType as OralQuestionTypeV1]?.value;
        }

        if (this.condition === OralAlertComparators.GREATER_THAN) {
          return this.answer < oralReportV1[this.questionType as OralQuestionTypeV1]?.value;
        }
      } else if (report.answer.version === OralReportVersion.V2) {
        const oralReportV2 = report.answer.oral as OralReportV2;

        if (this.condition === OralAlertComparators.EQUALS) {
          return this.answer === oralReportV2[this.questionType as OralQuestionTypeV2]?.value;
        }

        if (this.condition === OralAlertComparators.GREATER_THAN) {
          return this.answer < oralReportV2[this.questionType as OralQuestionTypeV2]?.value;
        }
      }
    }

    return false;
  }

  getBottomThreshold(): number {
    return 0;
  }

  getId(): number {
    return null;
  }

  setBottomThreshold(): void {}
}
