import { FC, MouseEvent } from 'react';

import { Box, css, styled } from '@mui/material';

import Patient from 'models/Patient';

import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  clearSearch: (event: MouseEvent<HTMLButtonElement>) => void;
  variant: 'dark' | 'light';
  patient: Patient;
}

export const SelectedSearchedPatientBox: FC<Props> = ({ clearSearch, variant, patient }) => (
  <StyledAdvancedControl
    variant={variant}
    width="100%"
    display="flex"
    justifyContent="space-between"
  >
    <Text variant="form-text" isEllipsis>
      {patient.lastName && `${patient.lastName},`} {patient.firstName}
    </Text>

    <Text component="div" display="flex" alignItems="center">
      {patient?.dateOfBirth && (
        <Text color="disabled" variant="form-text" ml={32}>
          DOB: <Text variant="form-text">{patient.formattedDateOfBirth}</Text>
        </Text>
      )}

      {patient?.mrn && (
        <Text color="disabled" variant="form-text" ml={32}>
          MRN: <Text variant="form-text">{patient?.mrn}</Text>
        </Text>
      )}

      <Box ml={32}>
        <IconButton onClick={clearSearch}>
          <Icon.XThin />
        </IconButton>
      </Box>
    </Text>
  </StyledAdvancedControl>
);

const StyledAdvancedControl = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant'
})<{
  variant: 'dark' | 'light';
}>(({ theme, variant }) => {
  const isDarkVariant = variant === 'dark';

  return css`
    //same styles as .header-search-box .styled-input .input-area input
    width: 100%;
    min-width: 320px;
    background-color: ${isDarkVariant
      ? theme.palette.natural.contrastDark
      : theme.palette.natural.white};

    border-radius: 23px;
    font-size: 13.5px;
    border: 1px solid ${isDarkVariant ? 'transparent' : theme.palette.natural.border};
    height: 45px;
    padding: ${theme.spacing(16)};
    position: relative;
    display: flex;
    align-items: center;
  `;
});
