import { FC, useRef, useState } from 'react';

import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Dialogs } from 'analytics/events/dialog';
import { observer } from 'mobx-react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { useClickAway } from 'react-use';

import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { BlockModeSeparator } from 'views/Pages/PathwayBuilder/PathwayEditorView/BlockModeSeparator';

import { StyledQuestionCard } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.shared';

import {
  CategoryFormField,
  PathwayEditorFormFields,
  QuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import { getRemovePathwayCategoryPopupId } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';
import { CancerDiagnosisQuestion } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/CancerDiagnosisQuestion';
import { PathwayEditorBlockActionBar } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/PathwayEditorBlockActionBar';

import { PathwayQuestions } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/PathwayQuestions';

import { FormInputField } from 'components/Forms';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  category: CategoryFormField;
  path: `categories.${number}`;
  index: number;
  onRemoveCategory: (index?: number | number[]) => void;
  onInsertCategory: (index: number, value: CategoryFormField) => void;
}

export const PathwayCategory: FC<Props> = observer(
  ({ category, path, index, onRemoveCategory, onInsertCategory }) => {
    const [isRemoveCategoryPopupOpen, setIsRemoveCategoryPopupOpen] = useState(false);
    const { control, getFieldState, trigger } = useFormContext<PathwayEditorFormFields>();
    const { isSelected, selectPathwayEditorBlock, resetSelectedPathwayEditorBlock } =
      usePathwayEditorSelection(category.categoryId);
    const categoryRef = useRef(null);
    const categoryTitleFieldState = getFieldState(`${path}.title`);

    const {
      fields: questions,
      insert: insertQuestion,
      update: updateQuestion,
      remove: removeQuestion,
      move: moveQuestion,
      replace: replaceQuestions
    } = useFieldArray<PathwayEditorFormFields, `categories.${number}.questions`>({
      control,
      name: `${path}.questions`
    });

    const removePathwayCategoryPopupId = getRemovePathwayCategoryPopupId(category.categoryId);

    useClickAway(categoryRef, (event) => {
      if (!isSelected) {
        return;
      }

      const clickedElement = event.target as HTMLElement;

      const removeCategoryPopup = document.getElementById(
        removePathwayCategoryPopupId
      ) as HTMLElement;

      let shouldAllowClick = false;

      if (removeCategoryPopup?.contains(clickedElement)) {
        shouldAllowClick = true;
      }

      if (!shouldAllowClick) {
        trigger(`${path}.title`);
        resetSelectedPathwayEditorBlock();
      }
    });

    const onInsertQuestion = (questionIndex: number, data: QuestionFormField) =>
      insertQuestion(questionIndex, data);

    const onUpdateQuestion = (questionIndex: number, data: QuestionFormField) =>
      updateQuestion(questionIndex, data);

    const onRemoveQuestion = (index?: number | number[]) => removeQuestion(index);

    const onMoveQuestion = (indexA: number, indexB: number) => moveQuestion(indexA, indexB);

    const onReplaceQuestions = (value: QuestionFormField | QuestionFormField[]) =>
      replaceQuestions(value);

    const isBackgroundInfoCategory = path === 'categories.0';
    const isCategoryNameInputVisible = isSelected || Boolean(categoryTitleFieldState.error);

    const categoryTitle = useWatch({ control, name: `${path}.title` });

    return (
      <>
        <Box mt={20}>
          {/* just on "Background Info" category which is always the first category */}
          {isBackgroundInfoCategory && (
            <>
              <StyledCategoryTitle>
                <Text variant="body1" color="disabled" textTransform="uppercase">
                  CATEGORY: {categoryTitle}
                </Text>
              </StyledCategoryTitle>

              <CancerDiagnosisQuestion title="Cancer Diagnosis" />
            </>
          )}

          {!isBackgroundInfoCategory && (
            <StyledCategoryCard isSelected={isSelected} ref={categoryRef}>
              <BlockModeSeparator
                isSelected={isSelected}
                selectPathwayEditorBlock={() => selectPathwayEditorBlock(category.categoryId)}
              />

              {isSelected && (
                <PathwayEditorBlockActionBar
                  onRemove={() => setIsRemoveCategoryPopupOpen(true)}
                  index={index}
                />
              )}

              {isCategoryNameInputVisible ? (
                <FormInputField
                  name={`${path}.title`}
                  label="Category Name"
                  placeholder={null}
                  isRequired
                />
              ) : (
                <Text variant="body1" color="primary" textTransform="uppercase">
                  CATEGORY: {categoryTitle}
                </Text>
              )}
            </StyledCategoryCard>
          )}

          <PathwayQuestions
            questions={questions}
            containerPath={path}
            onInsertQuestion={onInsertQuestion}
            onMoveQuestion={onMoveQuestion}
            onRemoveQuestion={onRemoveQuestion}
            onUpdateQuestion={onUpdateQuestion}
            onReplaceQuestions={onReplaceQuestions}
            onInsertCategory={onInsertCategory}
            categoryIndex={index}
          />
        </Box>

        <MessageDialog
          id={Dialogs.RemovePathwayCategory}
          isOpen={isRemoveCategoryPopupOpen}
          title="Remove Category and All Questions"
          handleClose={() => setIsRemoveCategoryPopupOpen(false)}
          primaryActionProps={{ text: 'Remove', onClick: () => onRemoveCategory(index) }}
          secondaryActionProps={{
            text: 'Cancel',
            onClick: () => setIsRemoveCategoryPopupOpen(false)
          }}
        >
          Removing this category will also remove all questions within it.
          <br />
          <br />
          This action cannot be undone.
        </MessageDialog>
      </>
    );
  }
);

const StyledCategoryCard = styled(StyledQuestionCard)(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.large};
  `
);

const StyledCategoryTitle = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(20)};
    margin-top: 2 ${theme.spacing(20)};
    border-radius: ${theme.borderRadius.large};
    background-color: ${theme.palette.natural.white};
    border: 1px solid ${theme.palette.natural.border};
  `
);
