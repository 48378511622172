import { ChangeEvent, FC } from 'react';

import { Box, styled } from '@mui/material';

import { isEmpty } from 'lodash/fp';

import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { LightweightPathwayQuestionServerStructure } from 'fetchers/responses/pathways.response';

import { PathwayOptionAction, PathwayQuestionTypes } from 'models/PathwayTemplates';

import {
  LightweightPathwayQuestionAction,
  LightweightPathwayQuestionAnswer,
  LightweightPathwayQuestionBaseProps,
  LightweightPathwayQuestionOption,
  MultipleQuestionActionMeta,
  MultipleValue
} from 'components/LightweightPathway/LightweightPathway.types';

import { HtmlContent } from 'components/Ticket/TicketRow/HtmlContent';
import { LabeledCheckbox } from 'components/UIkit/atoms/Checkbox';

export const MultipleQuestion: FC<
  LightweightPathwayQuestionBaseProps<MultipleValue, MultipleQuestionActionMeta>
> = ({ question, pathway, index, answer, handleAnswerChanged, parentQuestionId, pathwayIndex }) => {
  const onChange = (
    event: ChangeEvent<HTMLInputElement>,
    question: LightweightPathwayQuestionServerStructure,
    option: LightweightPathwayQuestionOption,
    optionIndex: number
  ) => {
    let newAnswer = { ...(answer || {}) } as LightweightPathwayQuestionAnswer<MultipleValue>;
    const denyAllOption = question.options!.find(
      (option) => option.action === PathwayOptionAction.DenyAll
    );

    const acceptAllOption = question.options!.find(
      (option) => option.action === PathwayOptionAction.AcceptAll
    );

    let actionMeta: MultipleQuestionActionMeta = {
      action: LightweightPathwayQuestionAction.AddFirstOption,
      optionId: option.id,
      questionId: question.id,
      type: PathwayQuestionTypes.MULTIPLE
    };

    const orderedQuestionOptions: MultipleValue = question.options!.map((option) => ({
      ...option,
      index: optionIndex
    }));

    if (!isEmpty(newAnswer)) {
      if (event.target.checked) {
        //check option
        if (option.action === PathwayOptionAction.AcceptAll) {
          newAnswer.value = orderedQuestionOptions;
        } else if (option.action === PathwayOptionAction.DenyAll) {
          newAnswer.value = [{ ...option, index: optionIndex }];
        } else {
          newAnswer.value = newAnswer.value.filter(
            (option) => option.id !== denyAllOption?.id && option.id !== acceptAllOption?.id
          );
          newAnswer.value.push({ ...option, index: optionIndex });
        }

        actionMeta = { ...actionMeta, action: LightweightPathwayQuestionAction.AddNewOption };
      } else {
        //uncheck option
        newAnswer.value = newAnswer.value.filter(
          (answeredOption) =>
            answeredOption.id !== option.id && answeredOption.id !== acceptAllOption?.id
        );
        actionMeta = {
          ...actionMeta,
          action: isEmpty(newAnswer.value)
            ? LightweightPathwayQuestionAction.RemoveLastOption
            : LightweightPathwayQuestionAction.RemoveOption
        };
      }
    } else {
      newAnswer = {
        ...question,
        parentQuestionId,
        pathway: { ...pathway, index: pathwayIndex },
        index,
        value:
          option.action === PathwayOptionAction.AcceptAll
            ? orderedQuestionOptions
            : [{ ...option, index: optionIndex }]
      };
    }

    if (Boolean(denyAllOption)) {
      newAnswer.deniedOptions = [];
      const selectedOptionIds = newAnswer.value.map((option) => option.id);

      orderedQuestionOptions!.forEach((option) => {
        if (
          !selectedOptionIds.includes(option.id) &&
          option.action !== PathwayOptionAction.DenyAll
        ) {
          newAnswer.deniedOptions!.push({
            id: option.id,
            questionId: question.id,
            title: option.title
          });
        }
      });
    }

    handleAnswerChanged(newAnswer, actionMeta);
  };

  return (
    <StyledContainer>
      {question.options!.map((option, optionIndex) => (
        <LabeledCheckbox
          id={option.id}
          key={option.id}
          label={<HtmlContent>{option.title}</HtmlContent>}
          mb={16}
          checked={Boolean(
            answer?.value?.find((answeredOption) => answeredOption.id === option.id)
          )}
          onChange={(event) => onChange(event, question, option, optionIndex)}
          testHook={sharedTestSelectors.lightweightPathwayMultipleQuestionOption(option.id)}
        />
      ))}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  > div:last-of-type {
    margin-bottom: 0;
  }
`;
