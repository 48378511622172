import { FC } from 'react';

import { observer } from 'mobx-react';

import './StyledInputHeader.scss';

interface StyledInputHeaderProps {
  text: string;
  error?: boolean;
  errorMessage?: string;
}

const StyledInputHeader: FC<StyledInputHeaderProps> = (props) => {
  return (
    <div className="input-header">
      <span className="header-text">{props.text || ''}</span>
      {props.error && props.errorMessage && (
        <span className="error-text">{props.errorMessage}</span>
      )}
    </div>
  );
};

export default observer(StyledInputHeader);
