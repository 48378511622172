import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { OptOutReason } from 'models/OptOutReason';

const httpService = new HttpService('opt out reason', true);

export class OptOutReasonsFetcher {
  static async getReasons(): Promise<OptOutReason[]> {
    return await httpService.get({
      url: API_URLS.OPT_OUT_REASONS,
      transformResponse: (items: any[]) => items.map((item) => OptOutReason.fromJson(item))
    });
  }

  static async createReason(name: string, isActive: boolean): Promise<OptOutReason> {
    return await httpService.post({
      url: API_URLS.OPT_OUT_REASONS,
      data: { name, isActive },
      transformResponse: (item) => OptOutReason.fromJson(item)
    });
  }

  static async updateReason(id: number, name: string): Promise<void> {
    return await httpService.put({
      url: API_URLS.UPDATE_OPT_OUT_REASON(id),
      data: { name }
    });
  }

  static async removeReason(id: number): Promise<void> {
    return await httpService.delete({
      url: API_URLS.REMOVE_OPT_OUT_REASON(id)
    });
  }
}
