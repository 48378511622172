import { ProviderResponse } from 'fetchers/responses/providers.response';

import PatientProvider from 'models/PatientProvider';

export class ProvidersParser {
  static parseProviders = (providers: ProviderResponse[]) =>
    providers.map(ProvidersParser.parseProvider);

  static parseProvider = (unparsedProvider: ProviderResponse) =>
    new PatientProvider(unparsedProvider);
}
