// @ts-strict-ignore
import { computed, makeAutoObservable } from 'mobx';

import {
  CmPatientResponse,
  CmStatusEnum,
  CmStatusResponse,
  ReasonResponse
} from 'fetchers/responses/care-management.response';

import {
  DATE_ONLY_FORMAT,
  formatDate,
  HoursAndMinutes,
  removeTimeZone,
  shortDateStringWithoutTimeZone,
  toHoursAndMinutes
} from 'utils/DateUtils';

const NONE = 'None';

export class CmStatus {
  static CcmStatusIds = [
    CmStatusEnum.EligibleForCcm,
    CmStatusEnum.InvitedCcm,
    CmStatusEnum.LearnMoreCcm,
    CmStatusEnum.ConsentedCcm,
    CmStatusEnum.InvitationFailedCcm,
    CmStatusEnum.SuggestedCcm
  ];
  static PcmStatusIds = [
    CmStatusEnum.EligibleForPcm,
    CmStatusEnum.InvitedPcm,
    CmStatusEnum.LearnMorePcm,
    CmStatusEnum.ConsentedPcm,
    CmStatusEnum.InvitationFailedPcm,
    CmStatusEnum.SuggestedPcm
  ];
  static NonEpisodeIds = [
    CmStatusEnum.None,
    CmStatusEnum.OptedOut,
    CmStatusEnum.EligibleForCcm,
    CmStatusEnum.EligibleForPcm,
    CmStatusEnum.Ineligible,
    CmStatusEnum.Ended,
    CmStatusEnum.Active,
    CmStatusEnum.SuggestedCcm,
    CmStatusEnum.SuggestedPcm,
    CmStatusEnum.InvitedCcm,
    CmStatusEnum.InvitedPcm,
    CmStatusEnum.ConsentedCcm,
    CmStatusEnum.ConsentedPcm,
    CmStatusEnum.LearnMoreCcm,
    CmStatusEnum.LearnMorePcm,
    CmStatusEnum.InvitationFailedCcm,
    CmStatusEnum.InvitationFailedPcm
  ];
  static PatientLedEnrollmentStatuses = [
    CmStatusEnum.InvitedCcm,
    CmStatusEnum.InvitedPcm,
    CmStatusEnum.ConsentedCcm,
    CmStatusEnum.ConsentedPcm,
    CmStatusEnum.LearnMoreCcm,
    CmStatusEnum.LearnMorePcm,
    CmStatusEnum.InvitationFailedCcm,
    CmStatusEnum.InvitationFailedPcm
  ];
  static SuggestedStatuses = [CmStatusEnum.SuggestedCcm, CmStatusEnum.SuggestedPcm];
  status: CmStatusEnum;
  episodeId?: number;
  date?: string;
  endDate?: string;
  reason?: ReasonResponse;
  notes?: string;

  constructor(cmStatus: CmStatusResponse) {
    Object.assign(this, cmStatus);
    makeAutoObservable(this);
  }

  get isNone() {
    return this.status === CmStatusEnum.None;
  }

  get isActive() {
    return this.status === CmStatusEnum.Active;
  }

  get hasSuggestions() {
    return this.status === CmStatusEnum.SuggestedCcm || this.status === CmStatusEnum.SuggestedPcm;
  }

  get isOptedOut() {
    return this.status === CmStatusEnum.OptedOut;
  }

  get isIneligible() {
    return this.status === CmStatusEnum.Ineligible;
  }

  get isEligibleForPcm() {
    return this.status === CmStatusEnum.EligibleForPcm;
  }

  get isEligibleForCcm() {
    return this.status === CmStatusEnum.EligibleForCcm;
  }

  get isEnded() {
    return this.status === CmStatusEnum.Ended;
  }

  get isEndedManually() {
    return this.isEnded && this.reason;
  }

  get isExpired() {
    return (
      this.status === CmStatusEnum.Active && this.endDate && new Date(this.endDate) < new Date()
    );
  }

  get isInviteFailed() {
    return (
      this.status === CmStatusEnum.InvitationFailedCcm ||
      this.status === CmStatusEnum.InvitationFailedPcm
    );
  }

  get formattedDate() {
    return formatDate(removeTimeZone(this.isExpired ? this.endDate : this.date), DATE_ONLY_FORMAT);
  }

  get reasonText() {
    return this.reason?.title;
  }

  get reasonDetails() {
    return this.notes;
  }
}

export class CmPatient {
  id: string;
  patientName: string;
  patientMrn: string;
  providerName: string;
  primaryInsurance: string | null;
  secondaryInsurance: string | null;
  cancerDiagnostics: string[] | null;
  chronicDiagnostics: string[] | null;
  visits: number;
  monthlyCareTimeMinutes: number;
  patientId: number;
  cmStatus: CmStatus;
  nextVisitLocation: string;
  nextVisitDate: string;
  nextVisitProvider: string;

  get nextVisitText(): string {
    return this.nextVisitDate ? shortDateStringWithoutTimeZone(this.nextVisitDate) : null;
  }

  get monthlyCareTime(): HoursAndMinutes {
    if (typeof this.monthlyCareTimeMinutes !== 'number') {
      return null;
    }
    const roundedMinutes = Math.round(this.monthlyCareTimeMinutes);
    return toHoursAndMinutes(roundedMinutes);
  }

  get nextVisitSummary(): string {
    if (!this.nextVisitDate && !this.nextVisitProvider && !this.nextVisitLocation) {
      return NONE;
    }
    const nextVisit = this.nextVisitDate ? `${this.nextVisitText} ` : '';

    const providerText = this.nextVisitProvider || '';
    const locationText = this.nextVisitLocation || '';
    const at = this.nextVisitProvider && this.nextVisitLocation ? ' at ' : '';

    const providerAndLocation =
      providerText || locationText ? `(${providerText}${at}${locationText})` : '';

    // example: '12/31/2021 (Dr. Rogers at Springdale)';
    return `${nextVisit}${providerAndLocation}`;
  }

  get cmSummary() {
    return `Primary Ins: ${this.primaryInsuranceName} • 2nd Ins: ${this.secondaryInsuranceName} • ${this.nextVisitSummary}`;
  }

  @computed
  get primaryInsuranceName(): string {
    return this.primaryInsurance || NONE;
  }

  @computed
  get secondaryInsuranceName(): string {
    return this.secondaryInsurance || NONE;
  }

  constructor(patientCareManagementResponse: CmPatientResponse) {
    Object.assign(this, patientCareManagementResponse);
    this.id = patientCareManagementResponse.emrPatientId;
    this.cmStatus = new CmStatus(patientCareManagementResponse.cmStatus);
    makeAutoObservable(this);
  }
}

export interface EnrolledPatient {
  id: string;
  patientName: string;
  patientMrn: string;
  providerName: string;
  episodeId: number;
  visits: number;
  patientId: number;
  careTime: string;
}
