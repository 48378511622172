import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Click | AnalyticEventAction.Select;

type Value = 'To Do' | 'In Progress' | 'Closed' | 'Resolved' | 'Resolve and Comment';

interface Properties {
  action: Action;
  value?: Value;
  ticket_id?: number;
  item_index?: number;
  page_number?: number;
}

export const trackTaskStatusMenuAnalyticsEvent = ({
  action,
  value,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.TaskStatusMenu,
    properties: { action, value, ...optionalProps }
  });
};
