import {
  FC,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes
} from 'react';

import { css, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

import { ButtonProps, StyledOutlined } from 'components/UIkit/atoms/Button/StyledButtons';
import { withSpacing, WithSpacingProps } from 'components/UIkit/theme/spacing';

interface InternalProps extends ButtonProps {
  children: ReactNode;
  icon: ReactNode;
  variant?: 'primary' | 'secondary';
  forwardedRef?: ForwardedRef<HTMLButtonElement>;
  hasBoxShadow?: boolean;
}

type ExternalProps = Omit<InternalProps, 'forwardedRef'> & WithSpacingProps;

const OutlinedIcon: FC<InternalProps> = ({
  onClick,
  disabled,
  icon,
  variant = 'primary',
  testHook,
  children,
  forwardedRef,
  hasBoxShadow = true
}) => (
  <StyledContainer
    onClick={onClick}
    disabled={disabled}
    ref={forwardedRef}
    data-test-hook={testHook}
    variant={variant}
    hasBoxShadow={hasBoxShadow}
  >
    {icon}
    <Typography variant="button-medium-outline">{children}</Typography>
  </StyledContainer>
);

const StyledContainer = styled(StyledOutlined, {
  shouldForwardProp: (prop) => prop !== 'hasBoxShadow'
})<{ variant: 'primary' | 'secondary'; hasBoxShadow: boolean }>(
  ({ theme, variant, hasBoxShadow }) => css`
    box-shadow: ${hasBoxShadow ? theme.boxShadow.common : 'none'};
    display: flex;
    align-items: center;

    ${variant === 'secondary' &&
    css`
      box-shadow: none;
    `}

    svg {
      margin-right: ${theme.spacing(8)};
      color: ${theme.palette.secondary.dark};
    }

    &:disabled {
      box-shadow: none;

      svg {
        color: ${theme.palette.text.disabled};
      }
    }
  `
);

const OutlinedIconWithSpacing = withSpacing(OutlinedIcon);

export const OutlinedIconButton: FC<ExternalProps> = forwardRef<HTMLButtonElement, ExternalProps>(
  (props, ref) => <OutlinedIconWithSpacing {...props} forwardedRef={ref} />
) as ForwardRefExoticComponent<ExternalProps & RefAttributes<HTMLButtonElement>>;
