// @ts-strict-ignore
interface OutOfView {
  isAbove: boolean;
  isBelow: boolean;
}

export function isScrolledOutOfView(childRef: Element, parentRef: Element): OutOfView {
  if (childRef && parentRef) {
    const childBoundingRect = childRef.getBoundingClientRect();
    const parentBoundingRect = parentRef.getBoundingClientRect();
    const isAbove = childBoundingRect.top + parentBoundingRect.top < 0;
    const isBelow = childBoundingRect.top > parentBoundingRect.bottom;

    return { isAbove, isBelow };
  }
}

export const isScrolledToBottom = (element: Element, parentRef: Element) => {
  if (!element || !parentRef) {
    return false;
  }
  const rect = element.getBoundingClientRect();
  return rect.bottom <= parentRef.clientHeight;
};

export const scrollbarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute(
    'style',
    'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;'
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

export default scrollbarWidth;

export function isInViewport(element: Element) {
  if (!element) {
    return false;
  }
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function isModalOpen() {
  const modal = document.body.querySelector('.MuiModal-root');
  return Boolean(modal);
}
