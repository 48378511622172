import React, { FC } from 'react';

import { OutlinedButton } from 'components/UIkit/atoms/Button';
import {
  FormLabeledCheckbox,
  FormSubtextLabeledCheckbox,
  LabeledCheckbox
} from 'components/UIkit/atoms/Checkbox';
import { ExternalLabeledCheckboxProps } from 'components/UIkit/atoms/Checkbox/LabeledCheckbox';
import { SecondaryAction } from 'components/UIkit/atoms/Modal/Modal';

interface Props {
  action: SecondaryAction;
}

const ModalSecondaryAction: FC<Props> = ({ action }) => {
  if (!('isVisible' in action)) {
    //isVisible default value is true
    action.isVisible = true;
  }

  const { type, isVisible, ...rest } = action;

  return (
    <>
      {isVisible && (
        <>
          {type === 'button' && (
            <OutlinedButton
              onClick={action.onClick}
              disabled={action.disabled}
              size="medium"
              color="error"
              testHook={action.testHook}
            >
              {action.text}
            </OutlinedButton>
          )}

          {type === 'labeled-checkbox' && (
            <FormLabeledCheckbox label={action.label} name={action.name || ''} />
          )}

          {type === 'controlled-labeled-checkbox' && (
            <LabeledCheckbox {...(rest as ExternalLabeledCheckboxProps)} />
          )}

          {type === 'subtext-labeled-checkbox' && (
            <FormSubtextLabeledCheckbox
              id={action.id}
              label={action.label}
              name={action.name}
              subText={action.subText}
            />
          )}
        </>
      )}
    </>
  );
};

export default ModalSecondaryAction;
