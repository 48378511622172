import { UserType } from 'utils/AccountUtils';

import Doctor from 'models/Doctor';

export class PermissionService {
  static MANAGER_ROLES = [UserType.ADMIN, UserType.CHIEF_NURSE];
  static isManager(doctor: Doctor) {
    return PermissionService.MANAGER_ROLES.includes(doctor?.role);
  }
  static isAdmin(doctor: Doctor) {
    return doctor.role === UserType.ADMIN;
  }
}
