import { FC, useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackFilterUsageAnalyticsEvent } from 'analytics/events/filter-usage';

import debounce from 'debounce-promise';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import PatientProvider from 'models/PatientProvider';

import SearchBar from 'views/Dashboard/SearchBar';
import { FilterName } from 'views/Filters/filters.types';

import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';

import { VisibilityToggleCell } from 'components/Patient/Table/VisibilityToggleCell';
import { defaultStringPropertyCompare, TableColumn, Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip/Tooltip';

import './ManagePracticeProvidersPage.scss';

interface Row {
  id: number | string;
  name: string;
  visible: boolean;
}
export const ManagePracticeProvidersPage: FC = observer(() => {
  const [searchTerm, setSearchTerm] = useState('');
  const { providersStore } = useStores();

  const columns: TableColumn<Row>[] = [
    {
      Header: 'Provider',
      accessor: 'name',
      sortType: defaultStringPropertyCompare('name')
    },
    {
      Header: '',
      accessor: 'visible',
      disableSortBy: true,
      Cell: (cellParams) => (
        <div className="d-flex">
          <div className="ml-auto">
            <Tooltip
              placement="right-start"
              label={<VisibilityToggleCell disabled isVisible={cellParams.row.original.visible} />}
            >
              <div className="p-3">
                Provider visibility is determined via the EMR, and cannot be toggled directly in
                Canopy.
              </div>
            </Tooltip>
          </div>
        </div>
      )
    }
  ];

  const onTrackSearchAnalyticsEvent = useMemo(() => {
    return debounce((searchQuery) => {
      trackFilterUsageAnalyticsEvent({
        action: !searchQuery ? AnalyticEventAction.Clear : AnalyticEventAction.SearchedByProviders,
        value: FilterName.SearchProviders
      });
    }, DEBOUNCE_DELAY);
  }, []);

  const filteredProviders = providersStore.providersByQuery(searchTerm);

  const rows = filteredProviders.map((provider: PatientProvider) => {
    return {
      id: provider.id,
      name: provider.name,
      visible: provider.isActive()
    };
  });
  return (
    <div className="practice-providers-page">
      <SearchBar
        searchValue={searchTerm}
        onSearchChanged={(searchTerm) => {
          onTrackSearchAnalyticsEvent(searchTerm);
          setSearchTerm(searchTerm);
        }}
        placeholder="Search Providers"
        className="searchbar"
      />
      <Table columns={columns} rowData={rows} />
    </div>
  );
});
