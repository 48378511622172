import axios, { AxiosError } from 'axios';

import { NoServerResponseError, NoNetworkError, ErrorFactory } from 'errors';

import { castArray, flow } from 'lodash/fp';

import { checkUnauthorizedError } from 'services/errorHandlingService';
import networkLabelService from 'services/networkLabelService';

import AbortSignalManager from 'utils/AbortSignalManager';

import {
  AllRequestParams,
  GetParams,
  getUrlWithQuery,
  hasInternet,
  HTTP_METHODS,
  RequestParamsWithData,
  RequestParamsWithoutMethod
} from 'utils/http.utils';

import { API_HOST, isCanary } from 'constants/config';

import { RequestCancelledError } from 'errors/RequestCancelledError';

import { publicUrlsService } from './publicUrls.service';

const defaultHeaders: any = {
  'Content-Type': 'application/json'
};

const axiosInstance = axios.create({
  headers: defaultHeaders,
  withCredentials: true,
  // change to canary when needed. API_HOST may be removed when no longer needed
  baseURL: publicUrlsService.getUrlWithCurrentProtocol(isCanary ? 'apiCanary' : 'api') || API_HOST
});

class HttpService {
  private entityName = '';
  private standardCrudErrors = false;
  constructor(entityName: string, standardCrudErrors = false) {
    this.entityName = entityName;
    this.standardCrudErrors = standardCrudErrors;
  }

  get = async <Response = any>({ url, query, ...rest }: GetParams) => {
    if (query) {
      url = getUrlWithQuery(url, query);
    }

    return await this.request<Response>({
      method: HTTP_METHODS.GET,
      url,
      ...rest
    });
  };

  post = async <Response = any, Data = any>(params: RequestParamsWithData<Data>) =>
    await this.request<Response>({
      method: HTTP_METHODS.POST,
      ...params
    });

  put = async <Response = any, Data = any>(params: RequestParamsWithData<Data>) =>
    await this.request<Response>({
      method: HTTP_METHODS.PUT,
      ...params
    });

  patch = async <Response = any, Data = any>(params: RequestParamsWithData<Data>) =>
    await this.request<Response>({
      method: HTTP_METHODS.PATCH,
      ...params
    });

  delete = async <Response = any>(params: RequestParamsWithoutMethod) =>
    await this.request<Response>({
      method: HTTP_METHODS.DELETE,
      ...params
    });

  private request = async <Response = any>(params: AllRequestParams) => {
    let {
      networkLabel,
      transformResponse,
      transformError,
      cancellationKey,
      overrideBaseURL,
      ...axiosParams
    } = params;

    // If cancellationKey is not provided, generate one from url and method
    // cancellationKey is useful when you want to use cancellation logic from outside
    const abortSignalRequestKey = cancellationKey || `${axiosParams.url}-${axiosParams.method}`;

    // Check if same request is already pending and abort it
    if (AbortSignalManager.has(abortSignalRequestKey)) {
      AbortSignalManager.abort(abortSignalRequestKey);
    }

    AbortSignalManager.set(abortSignalRequestKey);
    const signal = AbortSignalManager.getSignal(abortSignalRequestKey);

    networkLabel = networkLabel || axiosParams.url;

    if (!hasInternet()) {
      throw new NoNetworkError();
    }

    networkLabelService.startNetwork(networkLabel);

    try {
      let { data: responseData } = await axiosInstance.request<Response>({
        ...(overrideBaseURL && { baseURL: overrideBaseURL }),
        ...axiosParams,
        // spread signal if it exists
        ...(signal && { signal })
      });
      if (transformResponse) {
        const transformers = castArray(transformResponse);
        responseData = flow(...transformers)(responseData);
      }
      return responseData;
    } catch (originError) {
      // https://axios-http.com/docs/handling_errors

      // for type completion purposes only
      const axiosError = originError as AxiosError;
      if (axios.isCancel(axiosError)) {
        throw new RequestCancelledError(axiosError.message);
      }

      // this is the only case where server responded with an error
      if ((axiosError as AxiosError).response) {
        checkUnauthorizedError(axiosError);
        const error = ErrorFactory.createHttpError(
          axiosError,
          this.entityName,
          this.standardCrudErrors
        );
        throw transformError ? transformError(error) : error;
      }

      // Will happen when we are online but no response from server, convert error
      if ((axiosError as AxiosError).request) {
        throw new NoServerResponseError(axiosError);
      }
      // for any other error
      throw axiosError;
      // No error conversion needed
    } finally {
      networkLabelService.endNetwork(networkLabel);
      AbortSignalManager.delete(abortSignalRequestKey);
    }
  };
}

export { axiosInstance };

export default HttpService;
