import { FC } from 'react';

import { noop } from 'lodash/fp';
import { DateTimePicker } from 'react-widgets';

export interface DateTimePickerTooltipContentProps {
  min?: Date;
  max?: Date;
  onSelect: (date: Date) => void;
  date?: Date;
}

// why using noop handlers?
// Since react show a warning for setting a value without a handler which changes it
// an empty function (noop) used for onToggle and onChange
// * onToggle is required since "open" is used, but since DatePicker is wrapped with tooltip - no onToggle is required
// * onSelect used instead of onChange, since onSelect invoked for any selection (and not only change) - that is required
// for closing the tooltip when the same value selected (so onChange was not used)

export const DateTimePickerTooltipContent: FC<DateTimePickerTooltipContentProps> = ({
  min,
  max,
  onSelect,
  date
}) => (
  <div className="datepicker-tooltip-content-wrapper">
    <DateTimePicker
      containerClassName="d-none check"
      inputProps={{ id: 'check' }}
      time={false}
      open="date"
      onToggle={noop} // suppress DateTimePicker error when onToggle not provided, see "why using noop handlers" above
      onChange={noop} // suppress DateTimePicker error when onChange not provided, see "why using noop handlers" above
      min={min || undefined} // bug where date not shown as selected if min/max = null
      max={max || undefined}
      onSelect={onSelect}
      defaultValue={date}
      value={date}
    />
  </div>
);
