import { FC } from 'react';

import { styled } from '@mui/material';

import { isEmpty } from 'lodash/fp';

import moment from 'moment';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { LightweightPathwayQuestionServerStructure } from 'fetchers/responses/pathways.response';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import {
  DateQuestionActionMeta,
  DateValue,
  LightweightPathwayQuestionAction,
  LightweightPathwayQuestionAnswer,
  LightweightPathwayQuestionBaseProps
} from 'components/LightweightPathway/LightweightPathway.types';

export const DateQuestion: FC<
  LightweightPathwayQuestionBaseProps<DateValue, DateQuestionActionMeta>
> = ({ question, pathway, index, answer, pathwayIndex, handleAnswerChanged, parentQuestionId }) => {
  const onChange = (question: LightweightPathwayQuestionServerStructure, date?: Date) => {
    let newAnswer = { ...(answer || {}) } as LightweightPathwayQuestionAnswer<DateValue>;
    let actionMeta: DateQuestionActionMeta;

    if (date && moment(date).isValid()) {
      if (!isEmpty(newAnswer)) {
        newAnswer.value = date.toISOString();
        actionMeta = {
          action: LightweightPathwayQuestionAction.Update,
          questionId: question.id,
          type: PathwayQuestionTypes.DATE
        };
      } else {
        actionMeta = {
          action: LightweightPathwayQuestionAction.AddFirst,
          questionId: question.id,
          type: PathwayQuestionTypes.DATE
        };

        newAnswer = {
          ...question,
          parentQuestionId,
          pathway: { ...pathway, index: pathwayIndex },
          index,
          value: date.toISOString()
        };
      }
    } else {
      newAnswer.value = '';
      actionMeta = {
        action: LightweightPathwayQuestionAction.RemoveLast,
        questionId: question.id,
        type: PathwayQuestionTypes.DATE
      };
    }

    handleAnswerChanged(newAnswer, actionMeta);
  };

  return (
    <StyledDateTimePicker
      onChange={(date) => onChange(question, date)}
      step={1}
      date
      time={false}
      value={answer?.value ? moment(answer.value).toDate() : undefined}
      format="MM/DD/YYYY"
    />
  );
};

const StyledDateTimePicker = styled(DateTimePicker)`
  max-width: 200px;
`;
