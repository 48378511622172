import { FC, ReactNode } from 'react';

import './PropertyItem.scss';

interface PropertyItemProps {
  label: string;
  value: ReactNode;
}

const PropertyItem: FC<PropertyItemProps> = ({ label = 'N/A', value = '' }) => (
  <div className="property-item" aria-label={label}>
    <span className="property-item-label">{label}:</span>
    <span>{value}</span>
  </div>
);

export default PropertyItem;
