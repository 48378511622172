import { FC, FocusEvent, useCallback, useState } from 'react';

import { $generateHtmlFromNodes } from '@lexical/html';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { css } from '@mui/material';
import { styled } from '@mui/material/styles';

import { $getRoot, EditorState, LexicalEditor } from 'lexical';

import { useController, useFormContext } from 'react-hook-form';

import { FormRichTextProps } from 'components/UIkit/atoms/RichText/RichText.types';
import { isEditorHtmlPureString } from 'components/UIkit/atoms/RichText/RichText.utils';
import { Text } from 'components/UIkit/atoms/Text';

import { RichText } from './RichText';

export const FormRichText: FC<FormRichTextProps> = ({
  name,
  placeholder,
  isRequired,
  disabled,
  isError,
  showToolbarOnFocus = true,
  variant = 'standard',
  size,
  helperText,
  isPartialBorder,
  toolbarListeners,
  onBlur,
  testHook
}) => {
  const { control, getValues } = useFormContext();

  const {
    field: { value, onChange }
  } = useController({
    name,
    control,
    rules: {
      validate: {
        required: () => {
          const currentValue = getValues(name);
          return isRequired && currentValue.length > 0;
        }
      }
    },
    disabled
  });

  const [valueAfterFocus, setValueAfterFocus] = useState(value);

  const handleChange = useCallback(
    (_: EditorState, currentEditor: LexicalEditor) => {
      currentEditor.update(() => {
        const root = $getRoot();
        const textContent = root.getTextContent();
        const rawHtmlString = $generateHtmlFromNodes(currentEditor, null);

        /*When editor is empty the string representation
        is '<p><br></p>' so validation doesn't work. case of empty html*/
        if (!textContent) {
          onChange('');
          return;
        }

        // this is a pure string no need to save html (only editor default tags available)
        if (isEditorHtmlPureString(rawHtmlString)) {
          onChange(textContent);
          return;
        }

        // real html
        onChange(rawHtmlString);
      });
    },
    [onChange]
  );

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      if (onBlur) {
        const currentValue = getValues(name);

        onBlur(event, currentValue, valueAfterFocus);
      }
    },
    [getValues, name, onBlur, valueAfterFocus]
  );

  const handleFocus = useCallback(() => {
    const currentValue = getValues(name);
    setValueAfterFocus(currentValue);
  }, [getValues, name]);

  return (
    <>
      <RichText
        placeholder={placeholder}
        initialState={value}
        isError={isError}
        disabled={disabled}
        showToolbarOnFocus={showToolbarOnFocus}
        editorId={name}
        variant={variant}
        size={size}
        isPartialBorder={isPartialBorder}
        toolbarListeners={toolbarListeners}
        onBlur={handleBlur}
        onFocus={handleFocus}
        testHook={testHook}
      >
        <OnChangePlugin onChange={handleChange} ignoreSelectionChange />
      </RichText>

      {helperText && (
        <StyledHelperText variant="helper-text" isError={isError}>
          {helperText}
        </StyledHelperText>
      )}
    </>
  );
};

export const StyledHelperText = styled(Text, {
  shouldForwardProp: (prop) => prop !== 'isError'
})<{ isError?: boolean }>(
  ({ theme, isError }) => css`
    color: ${isError ? theme.palette.error.dark : theme.palette.text.secondary};
  `
);
