import { Env } from 'utils/env.types';

import { ENV } from 'constants/config';

interface AutomationWindow extends Window {
  QA_WOLF?: boolean;
}

export const isProd = ENV === Env.Production;
export const isStaging = ENV === Env.Staging;
export const isPreProd = ENV === Env.PreProd;
export const isDemo = ENV === Env.Demo;
export const isDevelopment = ENV === Env.Development;
export const isStagingOrDev = [Env.Development, Env.Staging].includes(ENV as Env);
export const isStagingDemoOrDev = [Env.Development, Env.Staging, Env.Demo].includes(ENV as Env);

const automationWindow: AutomationWindow = window;

export const isAutomationClient = automationWindow.QA_WOLF === true;
