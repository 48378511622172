// @ts-strict-ignore
import { FunctionComponent, MutableRefObject, ReactElement, useCallback, useMemo } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackReassignMenuAnalyticsEvent } from 'analytics/events/reassign-menu';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { TicketClinician } from 'models/Ticket';

import { useCliniciansTooltipAsyncSelect } from 'hooks/useCliniciansTooltipAsyncSelect';

import { ITooltipOption, Tooltip } from 'components/Tooltip';
import { TooltipController, TooltipTrigger } from 'components/Tooltip/Tooltip.types';
import { TooltipAsyncSelect } from 'components/Tooltip/TooltipAsyncSelect';

interface Props {
  onDoctorSelected: (clinicianId: number) => void;
  label?: ReactElement;
  tooltipReference?: MutableRefObject<Element>;
  tooltipController?: TooltipController;
  showUnassignOption?: boolean;
  closeTooltip: () => void;
  ticketIds: number[];
  ticketIndex: number;
  ticketSectionCurrentPage: number;
  selectedDoctor: TicketClinician;
}

const ReassignDropDown: FunctionComponent<Props> = ({
  onDoctorSelected,
  label,
  tooltipReference,
  tooltipController,
  showUnassignOption,
  closeTooltip,
  ticketIds,
  ticketIndex,
  ticketSectionCurrentPage,
  selectedDoctor
}) => {
  const { userStore } = useStores();
  const { currentDoctor } = userStore;

  const handleReassign = useCallback(
    (clinicianId: number | null) => {
      trackReassignMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        value: clinicianId ? 'list item' : 'unassign',
        ticket_ids: ticketIds,
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });
      onDoctorSelected(clinicianId);
      closeTooltip();
    },
    [onDoctorSelected, closeTooltip, ticketIds, ticketIndex, ticketSectionCurrentPage]
  );

  const { isLoading, options, onSearchChange } = useCliniciansTooltipAsyncSelect(
    handleReassign,
    selectedDoctor?.id
  );

  const unassignOption: ITooltipOption = useMemo(
    () => ({
      text: 'Unassign',
      onClick: () => handleReassign(null)
    }),
    [handleReassign]
  );
  const formattedOptions = [...options];
  const isCurrentDoctorSelected = currentDoctor?.id === selectedDoctor?.id;

  const currentDoctorOption: ITooltipOption = useMemo(
    () => ({
      text: 'Myself',
      onClick: () => handleReassign(currentDoctor?.id),
      isSelected: isCurrentDoctorSelected,
      disabled: isCurrentDoctorSelected
    }),
    [handleReassign, currentDoctor, isCurrentDoctorSelected]
  );

  formattedOptions.unshift(currentDoctorOption);

  if (showUnassignOption) {
    formattedOptions.unshift(unassignOption);
  }

  return (
    <Tooltip
      label={label}
      trigger={TooltipTrigger.CLICK}
      reference={tooltipReference}
      controller={tooltipController}
    >
      <TooltipAsyncSelect
        isLoading={isLoading}
        options={formattedOptions}
        onSearchChange={onSearchChange}
        searchPlaceholder="Search Staff"
      />
    </Tooltip>
  );
};

export default observer(ReassignDropDown);
