import { CliniciansSupportedFilters } from 'utils/filtersUtils';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { Mandatory } from 'utils/TypeUtils';

import PatientProvider from 'models/PatientProvider';
import { ReportType } from 'models/QuestionnaireAnswer';
import { TicketStatus } from 'models/Ticket';

import { ResolvedDateFilterOptionId, SPECIAL_FILTERS } from 'views/Filters/filters.constants';

import { ISelectOption } from 'views/Widgets/StyledSelect';

export interface TicketFiltersBaseProps {
  className?: string;
  reducedNumOfFiltersPerRow?: boolean;
  disableSearchName?: boolean;
  hideTags?: boolean;
  showTaskFilters?: boolean;
}

export interface FilterProps {
  updateFiltersByKey: (key: keyof SearchFiltersType) => (value: any) => void;
  filters: SearchFiltersType;
}

export interface TicketTypeRequestParam {
  ticketTypeId: number;
  ticketSubTypesIds: number[];
  isCategoryFilterChosen?: boolean;
}

export interface FiltersType {
  nameOrMrn?: string;
  searchTerm?: string;
  patientTags?: ISelectOption<number>[] | null;
  locations?: ISelectOption<number>[] | null;
  providers?: ISelectOption<PatientProvider>[] | null;
  assignees?: ISelectOption<number | SPECIAL_FILTERS>[] | null;
}

export interface PatientFilters extends FiltersType {
  monitoringConsent?: ISelectOption<string>[];
}

export interface TasksFiltersType extends FiltersType {
  role?: ISelectOption<number>[];
  status?: ISelectOption<TicketStatus>[];
  owners?: ISelectOption<number>[];
  episodeIds?: ISelectOption<number>[];
  episodeNumbers?: ISelectOption<number>[];
  taskSearchTerm?: string;
  lastNameFirstLetter?: ISelectOption<string>[];
  episodeTasks?: boolean;
  fromDate?: Date;
  toDate?: Date;
}

export interface TicketsFiltersType extends FiltersType {
  ticketType?: TicketSubTypeOption[] | null;
}

export interface ResolvedTicketsFiltersType extends Omit<TicketsFiltersType, 'assignees'> {
  resolvedDate?: ISelectOption<ResolvedDateFilterOptionId> | null;
  resolvedBy?: ISelectOption<number | SPECIAL_FILTERS>[] | null;
  episodeIds?: ISelectOption<number>[] | null;
}

export type SearchFiltersType = TicketsFiltersType & TasksFiltersType;

export interface SearchRequestParams {
  nameOrMrn?: string;
  providers?: string[];
  locations?: number[];
  assignees?: number[];
  patientId?: number;
  patientIds?: number[];
  lastRetrievedTimestamp?: number;
  ticketIds?: number[];
  includeAssignToMe?: boolean;
  patientTags?: number[];
  ticketTypes?: TicketTypeRequestParam[];
}

export interface TasksQueryRequestParams extends SearchRequestParams {
  fromDate?: string | null;
  toDate?: string | null;
  episodes?: number[];
  searchTerm?: string;
  lastNameFirstLetter?: string[];
  taskStatuses?: TicketStatus[];
  roles?: number[];
  owners?: number[];
  overdue?: boolean;
  episodeNumbers?: number[];
  episodeTasks?: boolean;
}

export interface DefaultTasksRequestParams
  extends Mandatory<TasksQueryRequestParams, 'taskStatuses'> {}

export interface TicketsQueryRequestParams extends SearchRequestParams {
  ticketStatuses?: TicketStatus[];
  reportTypes?: ReportType[];
}

export interface PageFiltersProps extends TicketFiltersBaseProps {
  localStorageKey?: string;
  defaultTasksQuery?: DefaultTasksRequestParams;
  defaultTicketsQuery?: TicketsQueryRequestParams;
}

export type AllFilters = SearchFiltersType & CliniciansSupportedFilters;

export enum FilterName {
  ItemTypes = 'Item Types',
  Providers = 'Providers',
  Locations = 'Locations',
  Assignees = 'Assignees',
  ResolvedBy = 'Resolved By',
  ResolvedDate = 'Resolved Date',
  PatientTags = 'Patient Tags',
  Episodes = 'Episodes',
  EpisodeNumber = 'Episode Number',
  TaskOwner = 'Task Owner',
  TaskRole = 'Task Role',
  SearchByNameOrMrn = 'Search by Name or MRN',
  LastNameFirstLetter = 'Last Name First Letter',
  SearchByTask = 'Search by Task',
  TaskStatus = 'Task Status',
  Credentials = 'Credentials',
  UserType = 'User Type',
  UserStatus = 'User Status',
  PrimaryProvider = 'Primary Provider',
  PrimaryInsurance = 'Primary Insurance',
  SecondaryInsurance = 'Secondary Insurance',
  LocationOfNextVisit = 'Location of Next Visit',
  EligibilityScore = 'Eligibility Score',
  EnrollmentStatus = 'Enrollment Status',
  SearchByTicketType = 'Search by Ticket Type',
  SearchLocations = 'Search Locations',
  SearchProviders = 'Search Providers',
  ActiveTx = 'Active Tx'
}
