import React, { FC, useMemo, useState } from 'react';

import { Box, css, styled } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useMount } from 'react-use';

import QaFetcher from 'fetchers/QaFetcher';

import { formatDate } from 'utils/DateUtils';

import { isSubStringCaseInsensitive } from 'utils/StringUtils';

import Patient from 'models/Patient';

import SearchBar from 'views/Dashboard/SearchBar';

import { PatientListTableRow } from 'views/Pages/PatientList/PatientList.types';

import { PatientListTableCell } from 'views/Pages/PatientList/PatientListTableCell';

import FixedLoader from 'components/Loaders/FixedLoader';

import {
  defaultDatePropertyCompare,
  defaultNumberStringPropertyCompare,
  defaultStringPropertyCompare,
  SortOptions,
  Table,
  TableColumn
} from 'components/Table';
import { Text } from 'components/UIkit/atoms/Text';

export const PatientList: FC = () => {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useMount(async function initPage() {
    setIsLoading(true);
    const patients = await QaFetcher.getPatientList();
    setPatients(patients);
    setIsLoading(false);
  });

  const columns: TableColumn<PatientListTableRow>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        defaultSort: SortOptions.asc,
        Cell: PatientListTableCell,
        sortType: defaultStringPropertyCompare('name')
      },
      {
        Header: 'MRN',
        accessor: 'mrn',
        Cell: PatientListTableCell,
        sortType: defaultNumberStringPropertyCompare('mrn')
      },
      {
        Header: 'Last ticket date',
        accessor: 'lastTicketDate',
        Cell: PatientListTableCell,
        sortType: defaultDatePropertyCompare('lastTicketDate')
      },
      {
        Header: 'Provider',
        accessor: 'provider',
        Cell: PatientListTableCell,
        sortType: defaultStringPropertyCompare('provider')
      },
      {
        Header: 'Invited status',
        accessor: 'remoteMonitoringStatus',
        Cell: PatientListTableCell,
        sortType: defaultStringPropertyCompare('remoteMonitoringStatus')
      },
      {
        Header: 'DOB',
        accessor: 'dob',
        Cell: PatientListTableCell,
        sortType: defaultDatePropertyCompare('dob')
      },
      {
        Header: 'Report Protocol',
        accessor: 'reportProtocol',
        Cell: PatientListTableCell,
        sortType: defaultStringPropertyCompare('reportProtocol')
      }
    ],
    []
  );

  const filteredPatients = patients.filter((patient) =>
    isSubStringCaseInsensitive(patient.fullName, searchTerm)
  );

  const rows: PatientListTableRow[] = filteredPatients.map((patient) => ({
    id: patient.id,
    name: patient.fullName,
    mrn: patient.mrn,
    lastTicketDate: patient.lastTicketCreatedAt
      ? formatDate(patient.lastTicketCreatedAt, 'MM/DD/YYYY')
      : '',
    provider: patient.provider?.name,
    remoteMonitoringStatus: patient.remoteMonitoringStatus,
    dob: patient.formattedDateOfBirth || '',
    reportProtocol: patient.mainScheduledProtocol?.getProtocolTypeString() || 'No Protocol'
  }));

  return (
    <StyledContainer>
      <Text variant="h1" mb={40}>
        Patient List
      </Text>

      <Box mb={20}>
        <SearchBar
          searchValue={searchTerm}
          onSearchChanged={(searchTerm) => setSearchTerm(searchTerm)}
          placeholder="Filter by Name"
        />
      </Box>

      {isLoading ? (
        <FixedLoader />
      ) : (
        <Table
          columns={columns}
          rowData={rows}
          rowAction={(row) => history.push(`/patient/${row.original.id}`)}
          emptyTableView={<Text>No Patients</Text>}
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    height: 100%;
    width: 90%;
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-top: ${theme.spacing(52)};
  `
);
