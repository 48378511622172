import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { groupBy, isEmpty } from 'lodash/fp';

import { formatDate } from 'utils/DateUtils';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import Ticket from 'models/Ticket';

import Icon from 'components/Icons/Icon';

import {
  ATTENTION_TODAY,
  IMMEDIATE_ATTENTION
} from 'components/LightweightPathway/LightweightPathway.constants';

import { LightweightPathwayQuestionOption } from 'components/LightweightPathway/LightweightPathway.types';
import { getLightweightQuestionAlert } from 'components/LightweightPathway/LightweightPathway.utils';
import { HtmlContent } from 'components/Ticket/TicketRow/HtmlContent';

import './TicketPathwaysAnswers.css';

interface Props {
  ticket: Ticket;
  isExpanded: boolean;
}

export const TicketPathwaysAnswers: FC<Props> = ({ ticket, isExpanded }) => {
  const pathwaysAnswers = groupBy('pathwayId', ticket.operatorTicket!.pathwayAnswers);

  return (
    <div className="ticket-pathways-answers">
      {Object.entries(pathwaysAnswers).map(([pathwayId, pathwayAnswers]) => {
        const pathwayName = pathwayAnswers[0].pathwayName;

        return (
          <div key={pathwayId} className="pathway-questions-answers-container">
            <span className="pathway-name">Pathway: {pathwayName}</span>

            {pathwayAnswers.map((pathwayAnswer) => {
              const alertText = getLightweightQuestionAlert({
                type: pathwayAnswer.questionType,
                value: pathwayAnswer.value
              });
              let alertIcon: ReactNode;
              let specificAlertClassName = '';

              if (alertText === IMMEDIATE_ATTENTION) {
                specificAlertClassName = 'immediate-attention';
                alertIcon = <Icon.ImmediateAttention />;
              }

              if (alertText === ATTENTION_TODAY) {
                specificAlertClassName = 'attention-today';
                alertIcon = <Icon.AttentionToday />;
              }

              return (
                <div key={pathwayAnswer.id} className="pathway-question-answer">
                  <div className="question-header">
                    <span className="question-title">{pathwayAnswer.questionTitle}</span>

                    {isExpanded && (
                      <div className={classNames('question-alert', specificAlertClassName)}>
                        {alertIcon}
                      </div>
                    )}
                  </div>

                  <div className="answers-container">
                    {pathwayAnswer.questionType === PathwayQuestionTypes.MULTIPLE && (
                      <>
                        {pathwayAnswer.value.map((answer: LightweightPathwayQuestionOption) => (
                          <HtmlContent key={answer.id}>{answer.title}</HtmlContent>
                        ))}

                        {!isEmpty(pathwayAnswer.deniedOptions) &&
                          pathwayAnswer.deniedOptions!.map((deniedOption) => (
                            <div key={deniedOption.id} className="denied-option">
                              <span className="prefix">Denies:</span>
                              <HtmlContent>{deniedOption.title}</HtmlContent>
                            </div>
                          ))}
                      </>
                    )}

                    {pathwayAnswer.questionType === PathwayQuestionTypes.SINGLE && (
                      <HtmlContent key={pathwayAnswer.id}>{pathwayAnswer.value.title}</HtmlContent>
                    )}

                    {pathwayAnswer.questionType === PathwayQuestionTypes.TEXT && (
                      <HtmlContent key={pathwayAnswer.id}>{pathwayAnswer.value}</HtmlContent>
                    )}

                    {pathwayAnswer.questionType === PathwayQuestionTypes.DATE && (
                      <span>{formatDate(pathwayAnswer.value, 'MM/DD/yyyy')}</span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
