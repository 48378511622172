import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Dialogs } from 'analytics/events/dialog';
import { isEmpty, omit } from 'lodash/fp';
import { observer } from 'mobx-react';

import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';

import { useMount, useUnmount } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import PatientsFetcher from 'fetchers/PatientsFetcher';
import {
  Pathway,
  PathwayCategory as PathwayCategoryType,
  PathwayTemplateCategoryQuestion
} from 'fetchers/responses/pathways-builder.response';

import { showToast } from 'utils/UserMessageUtils';

import { BasicDepartmentInfo } from 'models/Department';

import { PATHWAY_BUILDER_SELECTED_QUESTION_ID_QUERY_PARAM } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.constants';

import { StyledContainer } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.shared';
import { PathwayCategories } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayCategories';

import { PathwayEditorHeader } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorHeader';
import {
  MultipleQuestionFormField,
  PathwayEditorFormFields,
  PathwayType,
  SingleQuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import {
  getPathwayBasicInfos,
  normalizePathwaysTemplate
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';
import { PathwayProvider } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayProvider';

import { CANCER_DIAGNOSIS_QUESTION_ID } from 'views/Patient/PatientMain/PathwaysView.constants';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  pathwayId: string;
  onBackClick: () => void;
  handlePathwaySaved: (pathwayId: string) => void;
  isNewPathway: boolean;
  resetData: () => void;
  institutions: BasicDepartmentInfo[];
}

export const PathwayEditorView: FC<Props> = observer(
  ({ pathwayId, onBackClick, handlePathwaySaved, isNewPathway, resetData, institutions }) => {
    const [duplicateNameErrorMessage, setDuplicateNameErrorMessage] = useState('');
    const history = useHistory();
    const { pathwayBuilderStore, callLoggingStore, pathwaysStore } = useStores();
    const searchParams = new URLSearchParams(history.location.search);

    const {
      getEditorDefaultValues,
      getPathwayById,
      getPathwayCategoryById,
      convertEditorQuestionToServerStructure,
      pathways,
      setPathways,
      questions
    } = pathwayBuilderStore;

    const { setPathwaysData, setPathwayBasicInfos, setIsPreviewMode } = pathwaysStore;

    const editorDefaultValues = useMemo(
      () => getEditorDefaultValues(pathwayId, institutions, isNewPathway),
      [getEditorDefaultValues, pathwayId, isNewPathway, institutions]
    );

    const methods = useForm<PathwayEditorFormFields>({
      defaultValues: editorDefaultValues,
      mode: 'onChange',
      shouldFocusError: true
    });

    const { formState } = methods;

    const keepOnPage = useCallback(
      (event: BeforeUnloadEvent) => {
        if (formState.isDirty) {
          event.preventDefault();
          event.returnValue = 'Changes you made may not be saved.';
        }
      },
      [formState.isDirty]
    );

    useMount(function scrollToTop() {
      if (!searchParams.has(PATHWAY_BUILDER_SELECTED_QUESTION_ID_QUERY_PARAM)) {
        const appScrollableElement = document.getElementById('app');
        appScrollableElement?.scrollTo(0, 0);
      }
    });

    useUnmount(function reset() {
      resetData();
    });

    useEffect(
      function initBeforeUnloadListener() {
        window.addEventListener('beforeunload', keepOnPage);

        return () => window.removeEventListener('beforeunload', keepOnPage);
      },
      [keepOnPage]
    );

    const savePathway = (editorValues: PathwayEditorFormFields) => {
      const otherPathwayNames = pathways
        .filter((pathway) => pathway.id !== editorValues.id)
        .map((pathway) => pathway.name.toLowerCase());

      if (otherPathwayNames.includes(editorValues.title.toLowerCase())) {
        setDuplicateNameErrorMessage('Please choose a Pathway name that does not yet exist.');
        methods.setError('title', { type: 'validate' });
        return;
      }

      const originalPathway: Omit<Pathway, 'keywords' | 'institutions'> | Record<string, never> =
        !isNewPathway ? omit(['keywords', 'institutions'], getPathwayById(pathwayId)) : {};

      const convertedPathway: Pathway = {
        ...originalPathway,
        id: pathwayId,
        name: editorValues.title,
        categories: [],
        type: editorValues.type
      };

      const convertedCategories: PathwayCategoryType[] = [];

      editorValues.categories.forEach((editorCategory, editorCategoryIndex) => {
        let convertedCategoryQuestions: PathwayTemplateCategoryQuestion[] = [];

        if (editorCategoryIndex === 0) {
          //cancer diagnosis is not part of the editor, so we add it manually
          convertedCategoryQuestions = [{ id: CANCER_DIAGNOSIS_QUESTION_ID, isKey: false }];
        }

        editorCategory.questions.forEach((editorQuestion) => {
          const convertedQuestion = convertEditorQuestionToServerStructure(
            editorQuestion,
            pathwayId,
            editorCategory.categoryId,
            null,
            isNewPathway
          );

          convertedCategoryQuestions.push(convertedQuestion);

          const questionWithDependentQuestionsBlocks = editorQuestion as
            | SingleQuestionFormField
            | MultipleQuestionFormField;

          //check for dependent questions blocks
          if (!isEmpty(questionWithDependentQuestionsBlocks.dependentQuestionsBlocks)) {
            questionWithDependentQuestionsBlocks.dependentQuestionsBlocks!.map(
              (dependentQuestionsBlock) =>
                dependentQuestionsBlock.questions.forEach((dependentQuestion) => {
                  const convertedQuestion = convertEditorQuestionToServerStructure(
                    dependentQuestion,
                    pathwayId,
                    editorCategory.categoryId,
                    dependentQuestionsBlock,
                    isNewPathway
                  );

                  convertedCategoryQuestions.push(convertedQuestion);
                })
            );
          }
        });

        convertedCategories.push({
          ...(!isNewPathway ? getPathwayCategoryById(pathwayId, editorCategory.categoryId) : {}),
          id: editorCategory.categoryId,
          title: editorCategory.title,
          questions: convertedCategoryQuestions
        });
      });

      convertedPathway.categories = convertedCategories;

      if (!isEmpty(editorValues.keywords)) {
        convertedPathway.keywords = editorValues.keywords.map((keyword) => keyword.label);
      }

      if (!isEmpty(editorValues.institutions)) {
        convertedPathway.institutions = editorValues.institutions.map(
          (institutions) => institutions.value
        );
      }

      const newPathways = [...pathways];

      if (!isNewPathway) {
        const pathwayIndex = newPathways.findIndex((pathway) => pathway.id === convertedPathway.id);
        newPathways[pathwayIndex] = convertedPathway;
      } else {
        newPathways.push(convertedPathway);
      }

      setPathways(newPathways);
      handlePathwaySaved(pathwayId);
      searchParams.delete(PATHWAY_BUILDER_SELECTED_QUESTION_ID_QUERY_PARAM);
      methods.reset(getEditorDefaultValues(pathwayId, institutions));
      showToast({
        message: isNewPathway ? 'New Pathway Saved' : 'Pathway Changes Saved',
        description:
          'Changes are saved locally - do not delete browser data. Only you can see these changes, on this device.'
      });
      history.replace({ search: searchParams.toString() });
    };

    const onPreviewClick = async () => {
      const patients = await PatientsFetcher.searchPatients({ searchTerm: 'a', limit: 1 });
      const pathway = getPathwayById(pathwayId)!;
      const pathwaysData = normalizePathwaysTemplate(pathways, questions);
      const pathwayBasicInfos = getPathwayBasicInfos(pathways);
      setPathwaysData(pathwaysData);
      callLoggingStore.startCallSession({
        selectedPathway: {
          id: pathway.id,
          name: pathway.name,
          keywords: pathway.keywords || [],
          type: pathway.type || PathwayType.Symptom
        }
      });
      setPathwayBasicInfos(pathwayBasicInfos);
      setIsPreviewMode(true);
      window.open(window.location.href, '_blank');
      history.push(`/patient/${patients[0].patientId}`);
    };

    return (
      <PathwayProvider pathwayId={pathwayId}>
        <StyledContainer>
          <FormProvider {...methods}>
            <PathwayEditorHeader
              onBackClick={onBackClick}
              onSavePathway={savePathway}
              onPreviewClick={onPreviewClick}
              isNewPathway={isNewPathway}
              institutions={institutions}
            />

            <PathwayCategories />
          </FormProvider>
        </StyledContainer>

        <Prompt
          message="Changes you made may not be saved."
          when={formState.isDirty && !formState.isSubmitting}
        />

        <MessageDialog
          id={Dialogs.PathwayBuilderJsonValidationError}
          isOpen={Boolean(duplicateNameErrorMessage)}
          title="Pathway Name Already Exists"
          children={duplicateNameErrorMessage}
          handleClose={() => setDuplicateNameErrorMessage('')}
          primaryActionProps={{ text: 'OK', onClick: () => setDuplicateNameErrorMessage('') }}
        />
      </PathwayProvider>
    );
  }
);
