import { FC } from 'react';

import { HoursAndMinutes } from 'utils/DateUtils';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  time: HoursAndMinutes;
}

export const MonthlyCareTime: FC<Props> = ({ time }) => (
  <Text variant="body2">
    {time.hours > 0 && (
      <>
        <Text variant="body1">{time.hours}</Text>h
      </>
    )}
    {time.hours > 0 && time.minutes > 0 && ' '}
    {time.minutes > 0 && (
      <>
        <Text variant="body1">{time.minutes}</Text>m
      </>
    )}
  </Text>
);
