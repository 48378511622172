// @ts-strict-ignore
export class PhoneNumberCarrierDetails {
  type: PhoneType;
}

export enum PhoneType {
  mobile = 'mobile',
  landline = 'landline',
  voip = 'voip'
}

export type PhoneTypeStrings = keyof typeof PhoneType;
