export const uniqArray = (array: any[]) =>
  array.filter((elem, pos, arr) => arr.indexOf(elem) === pos);

export const compactArray = (array: any[]) => array.filter((obj) => obj);

export const hideSSN = (ssn: string) => {
  const revealCount = 4;
  ssn = ssn.replace(/ /g, '');
  if (ssn.length < 4) {
    return getAsteriskString(ssn.length);
  }
  return getAsteriskString(ssn.length - revealCount) + ssn.substr(ssn.length - 4);
};

const getAsteriskString = (numberOfChars: number) => {
  let chars = '';
  for (let i = 0; i < numberOfChars; i++) {
    chars += '*';
    if (i === 2 || i === 4) {
      chars += ' ';
    }
  }
  return chars;
};

export const extractIdsFromItems = (items: any[]): Array<number> => {
  return items.map((item: any) => item.id);
};

export const createMapBy = <T>(array: T[], getKey: (item: T) => number | string) => {
  const result = new Map<number | string, T>();
  array.forEach((item: any) => {
    result.set(getKey(item), item);
  });
  return result;
};
