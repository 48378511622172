import { useCallback } from 'react';

import { ErrorName } from 'errors';

import { useStores } from 'mobx/hooks/useStores';

import { TicketResolutionResponse } from 'fetchers/TicketsFetcher';

import { useTicketActionCallbacks } from 'components/Ticket/TicketsContainers/useTicketActionCallbacks';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

interface UseTicketResolveReturn {
  (ticketIds: number[], comment?: string): Promise<TicketResolutionResponse | undefined>;
}

export const useTicketResolve = (): UseTicketResolveReturn => {
  const { ticketsStore } = useStores();
  const { openDisconnectDraftPopup } = useTicketOverviewContext();
  const { onResolve } = useTicketActionCallbacks();

  return useCallback(
    async function resolveTickets(ticketIds: number[], comment?: string) {
      try {
        const resolutionResponse = await ticketsStore.resolveTickets(ticketIds, comment);
        onResolve && onResolve(ticketIds);
        return resolutionResponse;
      } catch (error: any) {
        if (error.name === ErrorName.ResolveClosedTicket) {
          onResolve && onResolve(ticketIds);
        }

        if (error.name === ErrorName.TicketConnectedToDraft) {
          openDisconnectDraftPopup({
            ticketIds,
            callback: async () => {
              await ticketsStore.resolveTickets(ticketIds, comment);
              onResolve && onResolve(ticketIds);
            }
          });
          return;
        }

        throw error;
      }
    },
    [onResolve, openDisconnectDraftPopup, ticketsStore]
  );
};
