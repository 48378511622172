import { FC } from 'react';

import { readClipboard } from 'utils/ClipboardUtils';

import { MissingPermissionError } from 'errors/MissingPermissionError';

import { TextButton } from 'components/UIkit/atoms/Button';

const ErrorMessageContent =
  "Please click the clipboard icon with the red 'x' at the top right of your Chrome window (it's on the right-hand side of the URL bar) and click \"always allow\" so that Canopy may paste from your clipboard.";

interface Props {
  onClick(clipboardContent: string): void;
}

export const PasteFromClipboardButton: FC<Props> = ({ onClick }) => {
  const handleClick = async () => {
    try {
      const clipboardText = await readClipboard();
      onClick(clipboardText);
    } catch (error) {
      throw new MissingPermissionError(
        'Allow Clipboard Permission to Paste MRN',
        ErrorMessageContent
      );
    }
  };

  return <TextButton onClick={handleClick}>Paste MRN</TextButton>;
};
