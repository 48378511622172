import { WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Clear
  | AnalyticEventAction.SaveAndManage
  | AnalyticEventAction.Save
  | AnalyticEventAction.LogCall
  | AnalyticEventAction.Snooze
  | AnalyticEventAction.RequestReport
  | AnalyticEventAction.Reopen
  | AnalyticEventAction.PickUp
  | AnalyticEventAction.SendInvite
  | AnalyticEventAction.OptOut
  | AnalyticEventAction.ResolveAll
  | AnalyticEventAction.TriageRules
  | AnalyticEventAction.ResumeCall
  | AnalyticEventAction.Maximize
  | AnalyticEventAction.Minimize
  | AnalyticEventAction.CancelIcon
  | AnalyticEventAction.EditEpisode
  | AnalyticEventAction.EndEpisode
  | AnalyticEventAction.EditConnectedTickets
  | AnalyticEventAction.PauseCallTimer
  | AnalyticEventAction.SaveDraft
  | AnalyticEventAction.DisabledActivate
  | AnalyticEventAction.Activate
  | AnalyticEventAction.SubmitCall
  | AnalyticEventAction.FinishCall
  | AnalyticEventAction.GenerateNewSmartSummary
  | AnalyticEventAction.Undo
  | AnalyticEventAction.Redo
  | AnalyticEventAction.Bold
  | AnalyticEventAction.Italic
  | AnalyticEventAction.NumberedList
  | AnalyticEventAction.BulletedList
  | AnalyticEventAction.AddRule
  | AnalyticEventAction.EditRule
  | AnalyticEventAction.Cancel
  | AnalyticEventAction.Delete
  | AnalyticEventAction.Comment
  | AnalyticEventAction.Send;

type Value = 'single report' | 'missing fields' | 'send successfully' | 'on' | 'off' | number;

interface Properties {
  action: Action;
  patient_id?: number;
  ticket_id?: number;
  ticket_ids?: number[];
  value?: Value;
  type?: 'by protocol';
  virtual_page?: 'call logger';
  item_index?: number;
  page_number?: number;
  summary_manual_edited?: 'yes' | 'no';
  tab?: WorkQueueTabName;
}

export const trackActionButtonAnalyticsEvent = ({ action, ...optionalProps }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.ActionButton,
    properties: {
      action,
      ...optionalProps
    }
  });
};
