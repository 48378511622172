import { useCallback, useMemo, useState } from 'react';

import debounce from 'debounce-promise';

import { useMount } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { CliniciansFetcher, SearchedClinician } from 'fetchers/CliniciansFetcher';

import { getClinicianFullName, getNameWithCredentials } from 'utils/ClinicianCredentialUtils';

import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';

import { ITooltipOption } from 'components/Tooltip';

interface UseCliniciansTooltipAsyncSelectReturnValue {
  isLoading: boolean;
  onSearchChange: (value: string) => void;
  options: ITooltipOption[];
}

export const useCliniciansTooltipAsyncSelect = (
  handleOptionClick: (clinicianId: number) => void,
  selectedClinicianId?: number
): UseCliniciansTooltipAsyncSelectReturnValue => {
  const { cliniciansStore, constantsStore } = useStores();
  const [isReassignSelectLoading, setIsReassignSelectLoading] = useState(false);
  const [reassignOptions, setReassignOptions] = useState<ITooltipOption[]>([]);

  useMount(function setAsyncSelectDefaultOptions() {
    const defaultOptions: ITooltipOption[] =
      cliniciansStore.defaultCliniciansForSelectWithoutCurrentDoctor.map(getAsyncSelectOption);

    setReassignOptions(defaultOptions);
  });

  const getAsyncSelectOption = useCallback(
    (searchedClinician: SearchedClinician): ITooltipOption => {
      const { credentialId, id } = searchedClinician;

      const fullName = getClinicianFullName(searchedClinician);
      const credentialText = constantsStore.getClinicianCredentialById(credentialId)?.text;
      const label = getNameWithCredentials(fullName, credentialText || null);
      const isSelected = selectedClinicianId === id;

      return {
        text: label,
        onClick: () => {
          handleOptionClick(id);
        },
        isSelected,
        disabled: isSelected
      };
    },
    [handleOptionClick, constantsStore, selectedClinicianId]
  );

  const debouncedSearchClinicians = useMemo(
    () =>
      debounce(
        (searchQuery: string) => CliniciansFetcher.searchClinicians({ searchTerm: searchQuery }),
        DEBOUNCE_DELAY
      ),
    []
  );

  const onReassignSearchChange = async (value: string) => {
    setIsReassignSelectLoading(true);
    const cliniciansResponse = await debouncedSearchClinicians(value);
    const options: ITooltipOption[] = cliniciansResponse.map(getAsyncSelectOption);
    setReassignOptions(options);
    setIsReassignSelectLoading(false);
  };

  return {
    isLoading: isReassignSelectLoading,
    onSearchChange: onReassignSearchChange,
    options: reassignOptions
  };
};
