import { trackPreselectedFilterUsageAnalyticsEvent } from 'analytics/events/filter-usage';

import { intersection, isNil } from 'lodash/fp';
import { action, computed, makeAutoObservable, observable } from 'mobx';

import { DataMap } from 'mobx/stores/index';

import { RootStore } from 'mobx/stores/rootStore';

import { WorkQueueCheckSectionStatusResponse } from 'fetchers/responses/work-queue.response';
import { WorkQueueFetcher } from 'fetchers/WorkQueueFetcher';

import { handleErrorSilently } from 'services/errorHandlingService';

import { RequestConfig } from 'utils/http.utils';

import { getFiltersFromLocalStorageByKey } from 'utils/localStorage.utils';

import { mergeWithQueryDefaults, omitFalsyValuesFromQuery } from 'utils/serverFiltersUtils';

import { showRequestReportSummaryToast } from 'utils/UserMessageUtils';

import Patient from 'models/Patient';
import Ticket from 'models/Ticket';

import { WORKQUEUE_FILTERS_LOCAL_STORAGE_KEY } from 'views/Filters/filters.constants';

import { allFiltersToNames } from 'views/Filters/TicketFilters';
import {
  allWqOpenItemsSectionNames,
  defaultWqFiltersQuery,
  defaultWqSectionCurrentPageMap,
  defaultWqSectionDataMap,
  defaultWqOpenItemsSectionFiltersMap,
  defaultWqSectionStatusData,
  defaultWqSectionStatusDataMap,
  WQ_SECTION_WITHOUT_TICKETS_PAGE_NUMBER_SERVER_RESPONSE
} from 'views/WorkQueue/WorkQueue.constants';
import {
  ParsedWorkQueueResponse,
  WorkQueueItemType,
  WorkQueueOpenItemsSectionName,
  WorkQueueRequestSection,
  WorkQueueSectionFilters,
  WorkQueueSectionName,
  WorkQueueSections,
  WorkQueueSectionStatusData
} from 'views/WorkQueue/WorkQueue.types';
import {
  buildServerSectionQuery,
  buildServerSectionsOnFirstPageQuery,
  extractWqQueryFromFilters
} from 'views/WorkQueue/WorkQueue.utils';

export class WorkQueueStore {
  rootStore: RootStore;

  //for open items + resolved items tabs
  @observable
  patientsMap: DataMap<Patient> = new DataMap<Patient>();

  //for open items tab
  @observable
  sectionDataMap: WorkQueueSections = defaultWqSectionDataMap;

  //for open items tab
  @observable
  sectionCurrentPageMap: Record<WorkQueueOpenItemsSectionName, number> =
    defaultWqSectionCurrentPageMap;

  //for open items tab
  @observable
  sectionStatusDataMap: Record<WorkQueueOpenItemsSectionName, WorkQueueSectionStatusData> =
    defaultWqSectionStatusDataMap;

  //for open items tab
  @observable
  sectionFiltersMap: Record<WorkQueueOpenItemsSectionName, WorkQueueSectionFilters> =
    defaultWqOpenItemsSectionFiltersMap;

  //for open items tab
  @observable
  autoRefreshInterval: ReturnType<typeof setInterval> | null = null;

  //for open items tab
  @observable
  autoRefreshLastRetrievedTimestamp: number | null = null;

  //for open items tab
  @observable
  currentLoadingSections: Set<WorkQueueSectionName> = new Set(); //this state is just for the skeleton loader for tickets

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @computed
  get sectionsToCheckStatusNames() {
    return allWqOpenItemsSectionNames.filter(
      (sectionName) => this.sectionCurrentPageMap[sectionName] > 0
    );
  }

  @computed
  get sectionsToUpdateNames() {
    return allWqOpenItemsSectionNames.filter(
      (sectionName) => this.sectionCurrentPageMap[sectionName] === 0
    );
  }

  getPatientSymptomOperatorTickets = (patient: Patient): Ticket[] => {
    const assignedToMeItems = this.sectionDataMap[WorkQueueSectionName.AssignedToMe]?.items || [];
    const urgentItems = this.sectionDataMap[WorkQueueSectionName.UrgentPatientReports]?.items || [];

    return [...assignedToMeItems, ...urgentItems]
      .filter(
        (item) =>
          item.itemType === WorkQueueItemType.OperatorTicket &&
          item.itemData.isSymptomOperatorTicket &&
          item.itemData.patientId === patient.id
      )
      .map((item) => item.itemData);
  };

  @action
  initQueryOnLoad() {
    const { ticketFiltersStore } = this.rootStore.stores;

    this.rootStore.stores.ticketFiltersStore.setDefaultTaskQuery({
      taskStatuses: Ticket.TICKET_ACTIVE_STATUSES
    });

    const localStorageFilters = getFiltersFromLocalStorageByKey(
      WORKQUEUE_FILTERS_LOCAL_STORAGE_KEY
    );

    if (localStorageFilters) {
      ticketFiltersStore.updateFilters(localStorageFilters);
      trackPreselectedFilterUsageAnalyticsEvent(localStorageFilters, allFiltersToNames);
    }
  }

  async init() {
    this.initQueryOnLoad();
    const { alertsStore } = this.rootStore.stores;
    const requestTimestamp = Date.now();

    try {
      const [wqResponse] = await Promise.all([
        this.fetchDefaultWq(),
        alertsStore.getClinicianAlerts()
      ]);

      this.setAutoRefreshLastRetrievedTimestamp(requestTimestamp);

      const { sections, patients } = wqResponse;
      this.handleWqResponse(sections, patients, requestTimestamp);
      this.startAutoRefreshInterval();
    } catch (error) {
      throw error;
    }
  }

  @action
  checkAndFetchOpenClusterTasks(wqResponse: ParsedWorkQueueResponse, networkLabel?: string) {
    const { tasksStore } = this.rootStore.stores;
    const { sections } = wqResponse;

    if (tasksStore.patientIdsToFetch.size === 0) {
      return;
    }

    const patientsIdsInTaskSection = sections[WorkQueueSectionName.TasksDue]?.items
      .filter((item) => !item.itemData.singleTask)
      .map((item) => item.itemData.patientId);

    const patientIdsToFetch = intersection(
      patientsIdsInTaskSection,
      Array.from(tasksStore.patientIdsToFetch)
    );

    // Check for open sections in Tasks section
    if (patientIdsToFetch.length > 0) {
      tasksStore.fetchTasksForPatientCluster(patientIdsToFetch, networkLabel);
    }
  }

  async fetchWorkQueue(sections: WorkQueueRequestSection[], requestConfig?: RequestConfig) {
    const wqResponse = await WorkQueueFetcher.fetchWorkQueue(
      {
        sections,
        filters: this.buildServerFiltersQuery()
      },
      requestConfig
    );

    this.checkAndFetchOpenClusterTasks(wqResponse, requestConfig?.networkLabel as string);

    return wqResponse;
  }

  //fetch all sections with first page query
  fetchDefaultWq() {
    const { settingsStore } = this.rootStore.stores;
    const { ticketSortOrder, wqRecordsPerPage, wqAssignedToMeRecordsPerPage } =
      settingsStore.institutionSettings;

    return this.fetchWorkQueue(
      buildServerSectionsOnFirstPageQuery({
        sections: allWqOpenItemsSectionNames,
        ticketSortOrder,
        assignedToMeRecordsPerPage: wqAssignedToMeRecordsPerPage,
        recordsPerPage: wqRecordsPerPage
      })
    );
  }

  @action
  async fetchSections(
    sectionConfig: WorkQueueOpenItemsSectionName[] | WorkQueueOpenItemsSectionName,
    requestConfig?: RequestConfig
  ) {
    const sectionNames = Array.isArray(sectionConfig) ? sectionConfig : [sectionConfig];
    const { settingsStore } = this.rootStore.stores;
    const { ticketSortOrder, wqAssignedToMeRecordsPerPage, wqRecordsPerPage } =
      settingsStore.institutionSettings;

    try {
      const sectionsToFetch = sectionNames.map((sectionName) => ({
        ...buildServerSectionQuery({
          sectionName,
          pageNumber: this.sectionCurrentPageMap[sectionName],
          ticketSortOrder,
          recordsPerPage: wqRecordsPerPage,
          assignedToMeRecordsPerPage: wqAssignedToMeRecordsPerPage
        })
      }));

      const requestTimestamp = Date.now();

      const { sections, patients } = await this.fetchWorkQueue(sectionsToFetch, requestConfig);

      this.handleWqResponse(sections, patients, requestTimestamp);
    } catch (error) {
      throw error;
    }
  }

  @action
  handleWqResponse(sections: WorkQueueSections, patients: Patient[], requestTimestamp: number) {
    this.updatePatientsMap(patients);
    this.setSectionDataMap(sections);
    // delete sections['resolvedItems'];
    this.updateWqResponseRelatedData(sections, requestTimestamp);
  }

  @action
  updateWqResponseRelatedData(sections: WorkQueueSections, requestTimestamp: number) {
    Object.keys(sections).forEach((key) => {
      const sectionName = key as WorkQueueOpenItemsSectionName;

      // sync server page number response
      this.setSectionCurrentPage(sectionName, sections[sectionName]!.pageNumber);

      if (this.sectionCurrentPageMap[sectionName] === 0) {
        // update the section timestamp
        this.setLastRetrievedTimestampFilterForSections([sectionName], requestTimestamp);

        // reset section status data (we don't want to show the section's refresh button if we go back to a different page)
        this.resetSectionStatusData(sectionName);
      }
    });
  }

  buildServerFiltersQuery = () => {
    const { ticketFiltersStore, ticketTypesStore } = this.rootStore.stores;
    const { filters } = ticketFiltersStore;

    const queryWithDefaults = mergeWithQueryDefaults(
      {
        ...extractWqQueryFromFilters(filters, ticketTypesStore)
      },
      defaultWqFiltersQuery
    );

    return omitFalsyValuesFromQuery(queryWithDefaults);
  };

  @action
  setCurrentLoadingSections = (sectionNames: WorkQueueSectionName | WorkQueueSectionName[]) => {
    const sectionNamesArray = Array.isArray(sectionNames) ? sectionNames : [sectionNames];
    sectionNamesArray.forEach((sectionName) => this.currentLoadingSections.add(sectionName));
  };

  clearCurrentLoadingSections() {
    this.currentLoadingSections.clear();
  }

  @action
  async handleSectionPageChange(sectionName: WorkQueueOpenItemsSectionName) {
    const { tasksStore } = this.rootStore.stores;
    this.setCurrentLoadingSections(sectionName);

    // we only keep the open clusters per page. reset when changing page
    if (sectionName === WorkQueueSectionName.TasksDue) {
      tasksStore.patientIdsToFetch.clear();
    }

    try {
      await this.fetchSections(sectionName);
    } catch (error) {
      throw error;
    } finally {
      this.clearCurrentLoadingSections();
    }
  }

  @action
  startAutoRefreshInterval() {
    const { settingsStore } = this.rootStore.stores;
    const { ticketRetrievalInterval } = settingsStore.institutionSettings;

    this.autoRefreshInterval = setInterval(async () => {
      try {
        await this.fetchAndCheckSections('refresh-wq');
      } catch (error: any) {
        handleErrorSilently(error);
      }
    }, ticketRetrievalInterval);
  }

  @action
  async fetchAndCheckSections(cancellationKey?: string) {
    const { settingsStore } = this.rootStore.stores;
    const { ticketSortOrder, wqRecordsPerPage, wqAssignedToMeRecordsPerPage } =
      settingsStore.institutionSettings;

    const hasSectionsToCheckStatus = this.sectionsToCheckStatusNames.length > 0;
    const hasSectionsToUpdate = this.sectionsToUpdateNames.length > 0;

    let fetchWqPromise: Promise<ParsedWorkQueueResponse | false> = Promise.resolve(false);

    let checkSectionStatusPromise: Promise<WorkQueueCheckSectionStatusResponse | false> =
      Promise.resolve(false);

    if (hasSectionsToUpdate) {
      fetchWqPromise = this.fetchWorkQueue(
        buildServerSectionsOnFirstPageQuery({
          sections: this.sectionsToUpdateNames,
          ticketSortOrder,
          recordsPerPage: wqRecordsPerPage,
          assignedToMeRecordsPerPage: wqAssignedToMeRecordsPerPage
        }),
        { cancellationKey }
      );
    }

    if (hasSectionsToCheckStatus) {
      checkSectionStatusPromise = WorkQueueFetcher.checkSectionStatus({
        sections: this.sectionsToCheckStatusNames.map((sectionName) =>
          buildServerSectionQuery({
            sectionName,
            pageNumber: this.sectionCurrentPageMap[sectionName],
            ticketSortOrder,
            filters: { ...this.sectionFiltersMap[sectionName] },
            recordsPerPage: wqRecordsPerPage,
            assignedToMeRecordsPerPage: wqAssignedToMeRecordsPerPage
          })
        ),
        filters: this.buildServerFiltersQuery()
      });
    }

    const requestTimestamp = Date.now();

    const [workQueueResponse, checkSectionStatusResponse] = await Promise.all([
      fetchWqPromise,
      checkSectionStatusPromise
    ]);

    this.setAutoRefreshLastRetrievedTimestamp(requestTimestamp);

    if (workQueueResponse) {
      const { sections, patients } = workQueueResponse;

      // auto-refresh requests:
      // WQ request - fetch all the sections on page 0
      // check status - check the status of all the section on page > 0
      //  flow 1:
      //  - 2 requests - auto refresh and fetch section after page changing
      //  - we get auto refresh response after page changing response
      //  flow 2:
      //  - user in page 0
      //  - 2 requests - auto refresh and fetch sections after ticket action
      //  - we get auto refresh response after ticket action response
      Object.keys(sections).forEach((key) => {
        const sectionName = key as WorkQueueOpenItemsSectionName;

        const isAfterFirstPage = this.sectionCurrentPageMap[sectionName] !== 0;

        const isStaleResponse =
          this.sectionFiltersMap[sectionName].lastRetrievedTimestamp! >
          this.autoRefreshLastRetrievedTimestamp!;

        if (isAfterFirstPage || isStaleResponse) {
          delete sections[sectionName];
        }
      });

      this.handleWqResponse(sections, patients, requestTimestamp);
    }

    if (checkSectionStatusResponse) {
      this.setSectionStatusDataMap(checkSectionStatusResponse);
    }
  }

  @action
  setSectionFiltersMap(sectionFiltersMap: Record<WorkQueueSectionName, WorkQueueSectionFilters>) {
    this.sectionFiltersMap = sectionFiltersMap;
  }

  @action
  setLastRetrievedTimestampFilterForSections(
    sectionNames: WorkQueueOpenItemsSectionName[],
    lastRetrievedTimestamp: number
  ) {
    sectionNames.forEach((sectionName) => {
      this.sectionFiltersMap[sectionName].lastRetrievedTimestamp = lastRetrievedTimestamp;
    });
  }

  @action
  setAutoRefreshLastRetrievedTimestamp(autoRefreshLastRetrievedTimestamp: number) {
    this.autoRefreshLastRetrievedTimestamp = autoRefreshLastRetrievedTimestamp;
  }

  @action
  setSectionStatusDataMap(sectionStatusDataMap: WorkQueueCheckSectionStatusResponse) {
    this.sectionStatusDataMap = { ...this.sectionStatusDataMap, ...sectionStatusDataMap };
  }

  @action
  setSectionCurrentPage(sectionName: WorkQueueOpenItemsSectionName, pageNumber: number) {
    const shouldResetToFirstPage =
      isNil(pageNumber) || pageNumber === WQ_SECTION_WITHOUT_TICKETS_PAGE_NUMBER_SERVER_RESPONSE;

    this.sectionCurrentPageMap[sectionName] = shouldResetToFirstPage ? 0 : pageNumber;
  }

  @action
  setSectionDataMap(sections: WorkQueueSections) {
    this.sectionDataMap = { ...this.sectionDataMap, ...sections };
  }

  @action
  updatePatientsMap(patients: Patient[]) {
    this.patientsMap.replaceItems(patients);
  }

  getPatientModel(patientId: number) {
    return this.patientsMap.get(patientId);
  }

  @action
  resetStore() {
    this.patientsMap.clear();
    this.resetSectionsDataMap();
    this.resetSectionCurrentPageMap();
    this.resetSectionStatusDataMap();
    this.rootStore.stores.ticketFiltersStore.resetFiltersAndDefaultQueryValues();
    this.clearCurrentLoadingSections();

    if (this.autoRefreshInterval) {
      clearInterval(this.autoRefreshInterval);
    }

    this.autoRefreshInterval = null;
    this.setAutoRefreshLastRetrievedTimestamp(0);
  }

  @action
  resetSectionStatusData(sectionName: WorkQueueOpenItemsSectionName) {
    this.sectionStatusDataMap[sectionName] = defaultWqSectionStatusData;
  }

  @action
  resetSectionStatusDataMap() {
    this.sectionStatusDataMap = defaultWqSectionStatusDataMap;
  }

  @action
  resetSectionCurrentPageMap() {
    this.sectionCurrentPageMap = defaultWqSectionCurrentPageMap;
  }

  @action
  resetSectionsDataMap() {
    this.sectionDataMap = defaultWqSectionDataMap;
  }

  @action
  resetSectionFiltersMap() {
    this.sectionFiltersMap = defaultWqOpenItemsSectionFiltersMap;
  }

  @action
  async resetAndFetchDefaultWq() {
    const { ticketFiltersStore } = this.rootStore.stores;
    this.resetSectionCurrentPageMap();

    try {
      const requestTimestamp = Date.now();

      const { sections, patients } = await this.fetchDefaultWq();
      this.handleWqResponse(sections, patients, requestTimestamp);
    } catch (error) {
      throw error;
    } finally {
      ticketFiltersStore.setUpdatingFilters(false);
    }
  }

  isInAssignedToMe(ticketIds: number[]) {
    return ticketIds.some((ticketId) =>
      this.sectionDataMap[WorkQueueSectionName.AssignedToMe]!.items.some(
        (item) => item.itemData.id === ticketId
      )
    );
  }

  async sendBulkReportRequest(patientIds: number[]) {
    const result = await WorkQueueFetcher.sendBulkReportRequest({
      patientIds
    });

    let patients: Patient[] = [];

    if (result.blockedIds.length > 0) {
      patients = result.blockedIds.map((id: number) => this.patientsMap.get(id)!);
    }

    showRequestReportSummaryToast(result.successIds.length, patients);
  }
}
