import { Box, css, styled } from '@mui/material';

export const StyledOptionItemContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 24px;
    gap: ${theme.spacing(20)};

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(16)};
    }
  `
);

export const StyledTextareaPresentation = styled(Box)(
  ({ theme }) => css`
    height: 94px;
    width: 100%;
    padding: ${theme.spacing(12)};
    background-color: ${theme.palette.natural.inactiveBackground};
    border: 1px solid ${theme.palette.natural.border};
    border-radius: ${theme.borderRadius.large};
  `
);

export const StyledInputPresentation = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: ${theme.spacing(12)};
    background-color: ${theme.palette.natural.inactiveBackground};
    border: 1px solid ${theme.palette.natural.border};
    border-radius: ${theme.borderRadius.large};
  `
);

export const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing(20, 0)};
  `
);
