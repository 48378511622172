import { FC } from 'react';

import { Box, css, keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledBulkMenuItem } from 'components/UIkit/atoms/BulkMenu/BulkMenu.shared';
import { BulkItem } from 'components/UIkit/atoms/BulkMenu/BulkMenu.types';
import { BulkMenuItem } from 'components/UIkit/atoms/BulkMenu/BulkMenuItem';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  isOpen: boolean;
  summaryText: string;
  items: BulkItem[];
}

export const BulkMenu: FC<Props> = ({ isOpen, summaryText, items }) => (
  <StyledContainer isOpen={isOpen}>
    <StyledBulkMenuItem>
      <Text variant="h4">{summaryText}</Text>
    </StyledBulkMenuItem>

    {items.map((item, index) => (
      <BulkMenuItem key={index} item={item} />
    ))}
  </StyledContainer>
);

const showBulkMenu = keyframes`
  0% {
    opacity: 0.8;
    bottom: 7vh;
    z-index: 1;
  }
  
  100% {
    opacity: 1;
    bottom: 10vh;
    z-index: 1;
  }
`;

const hideBulkMenu = keyframes`
  0% {
    opacity: 1;
    bottom: 10vh;
    z-index: 1;
  }
  
  25% {
    opacity: 0.75;
    z-index: 1;
  }
  
  50% {
    opacity: 0.5;
    z-index: 1;
  }
  
  75% {
    opacity: 0.25;
    z-index: 1;
  }
  
  100% {
    opacity: 0;
    bottom: 7vh;
    z-index: -1;
  }
`;

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOpen'
})<{ isOpen: boolean }>(
  ({ theme, isOpen }) => css`
    position: fixed;
    display: flex;
    bottom: 7vh;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden; // used to prevent displayin animation on init
    box-shadow: ${theme.boxShadow.common};
    background-color: white;
    z-index: -1;
    border-radius: ${theme.borderRadius.large};
    border: 2px solid ${theme.palette.primary.main};
    animation: ${hideBulkMenu} 200ms ease-in;
    transition: visibility 200ms ease-in;

    ${isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      z-index: 2;
      bottom: 10vh;
      animation: ${showBulkMenu} 200ms ease-out;
    `}
  `
);
