import { FC, useEffect } from 'react';

import { observer } from 'mobx-react';
import { Redirect, RouteProps, useHistory } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import AuditService from 'fetchers/AuditFetcher';

import { Route } from 'services/sentryService';

import Layout from 'containers/Layout/Layout';

import { TAB_QUERY_PARAM } from 'views/Patient/PatientMain/PatientMainView.constants';

function addPageViewAudit(pathname: string, search: string) {
  const searchParams = new URLSearchParams(search);
  const tabName = searchParams.get(TAB_QUERY_PARAM); // if not exists - tabName will be null
  const queryParams: Record<string, string> = {};

  // currently we have no standard way to include tabs in the URL, so only patient view tabs treated here
  // see https://expain.atlassian.net/browse/EH-2447
  searchParams.forEach((value: string, key: string) => {
    if (key !== TAB_QUERY_PARAM) {
      queryParams[key] = value;
    }
  });

  AuditService.addPageViewAudit({
    pageName: pathname,
    tabName,
    queryParams
  });
}

interface Props extends RouteProps {
  shouldAllow?: boolean;
  redirectRoute?: string;
  name?: string;
}

const PrivateRoute: FC<Props> = observer(
  ({ component: Component, redirectRoute, shouldAllow = true, ...rest }) => {
    const history = useHistory();
    const { userStore } = useStores();

    useEffect(
      function logRouteChanges() {
        // for the first time a page is loaded
        userStore.isAuthenticated &&
          addPageViewAudit(history.location.pathname, history.location.search);

        // history.listen return unsubscribe method
        return history.listen((location) => {
          const isPatientPageIndexRoute =
            userStore.isAuthenticated &&
            location.pathname.includes('/patient/') &&
            !location.search;
          // skip route "/patient/123" since it temporary (redirected to default tab)
          const shouldAddAudit = userStore.isAuthenticated && !isPatientPageIndexRoute;
          return shouldAddAudit && addPageViewAudit(location.pathname, location.search);
        });
      },
      [history, userStore.isAuthenticated]
    );

    const isAllowed = () => {
      if (!userStore.isAuthenticated) {
        return false;
      }

      return shouldAllow;
    };

    return (
      <Route
        exact
        {...rest}
        render={(props) => {
          if (isAllowed()) {
            return (
              <Layout>
                <Component {...props} />{' '}
              </Layout>
            );
          }

          return (
            <Redirect
              exact
              to={{
                pathname: redirectRoute,
                state: { from: props.location }
              }}
            />
          );
        }}
      />
    );
  }
);

export default PrivateRoute;
