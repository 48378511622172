import { FC, ReactNode } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { CheckboxProps, SubtextLabeledCheckbox } from 'components/UIkit/atoms/Checkbox';

export interface FormSubtextLabeledCheckboxProps extends CheckboxProps {
  label: ReactNode;
  subText: string;
  required?: boolean;
  name: string;
}

export const FormSubtextLabeledCheckbox: FC<FormSubtextLabeledCheckboxProps> = ({
  id,
  label,
  subText,
  name,
  disabled,
  required,
  onKeyDown
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const error = Boolean(errors[name]);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required
      }}
      render={({ field: { value: checked, ref, onChange } }) => (
        <SubtextLabeledCheckbox
          id={id}
          name={name}
          onChange={onChange}
          checked={checked}
          ref={ref}
          disabled={disabled}
          label={label}
          subText={subText}
          error={error}
          onKeyDown={onKeyDown}
        />
      )}
    />
  );
};
