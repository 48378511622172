// @ts-strict-ignore
import { FC, MutableRefObject, ReactNode, useState } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackPathwayMenuAnalyticsEvent } from 'analytics/events/pathway-menu';

import { observer } from 'mobx-react';

import { Testable } from 'utils/TypeUtils';

import { PathwayBasicInfo } from 'models/PathwayTemplates';
import Ticket from 'models/Ticket';

import { usePathwayOptions } from 'hooks/usePathwaysOptions';

import { ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { TooltipController, TooltipTrigger } from 'components/Tooltip/Tooltip.types';

export interface CallAndPathwaysDropDownProps extends Testable {
  children?: ReactNode;
  label?: string | JSX.Element;
  tooltipReference?: MutableRefObject<Element>;
  tooltipController?: TooltipController;
  allowNoPathway?: boolean;
  onSelect: (pathway: PathwayBasicInfo) => void;
  disabled?: boolean;
  showSelected?: boolean;
  patientId?: number;
  ticketIds?: number[];
  virtualPage?: 'call logger';
  tab?: 'All Questions' | 'Key Questions';
  ticketIndex?: number;
  ticketSectionCurrentPage?: number;
  ticket?: Ticket;
}

export enum PathwaySelectType {
  Regular = 'list item',
  Alert = 'alert',
  Recommended = 'recommended',
  WithoutPathway = 'call without pathway'
}

const CallAndPathwaysDropDown: FC<CallAndPathwaysDropDownProps> = ({
  label = null,
  disabled,
  tooltipReference,
  tooltipController,
  allowNoPathway = false,
  onSelect,
  children,
  showSelected = false,
  patientId,
  ticketIds,
  virtualPage,
  tab,
  ticketIndex = 0,
  ticketSectionCurrentPage = 0,
  testHook,
  ticket
}) => {
  const [searchValue, setSearchValue] = useState('');

  const options: ITooltipOption[] = usePathwayOptions(
    {
      tooltipController,
      allowNoPathway,
      onSelect,
      showSelected,
      patientId,
      ticketIds,
      ticketSectionCurrentPage,
      ticketIndex,
      ticket
    },
    searchValue
  );
  return (
    <Tooltip
      disabled={disabled}
      trigger={TooltipTrigger.CLICK}
      label={<span>{label}</span>}
      reference={tooltipReference}
      controller={tooltipController}
      testHook={testHook}
    >
      <TooltipSelect
        options={options}
        onSearchChange={setSearchValue}
        onSearchBlur={() => {
          trackPathwayMenuAnalyticsEvent({
            action: AnalyticEventAction.Search,
            virtual_page: virtualPage,
            tab: tab,
            page_number: ticketSectionCurrentPage + 1,
            item_index: ticketIndex + 1
          });
        }}
        searchPlaceholder="Search Pathways"
        searchValue={searchValue}
      >
        {children}
      </TooltipSelect>
    </Tooltip>
  );
};

export default observer(CallAndPathwaysDropDown);
