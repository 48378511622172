import { useStores } from 'mobx/hooks/useStores';

export const usePathwayEditorSelection = (blockUuid?: string) => {
  const { pathwayBuilderStore } = useStores();
  const {
    selectPathwayEditorBlock,
    selectedPathwayEditorBlockUuid,
    resetSelectedPathwayEditorBlock
  } = pathwayBuilderStore;
  const isSelected = selectedPathwayEditorBlockUuid === blockUuid;

  return {
    isSelected,
    selectPathwayEditorBlock,
    resetSelectedPathwayEditorBlock,
    selectedPathwayEditorBlockUuid
  };
};
