import { FC, useEffect } from 'react';

import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { ActionMeta } from 'react-select/dist/declarations/src/types';

import { ISelectOption } from './Select.shared';
import { findOption, isAddOptionValue } from './SelectUtils';
import { FormProps, getErrorByName } from './withForm.shared';

function useSyncSelectedOption(isEditable: boolean, name: string, options: ISelectOption<any>[]) {
  const { setValue } = useFormContext();
  const selectedOption = useWatch({ name });

  useEffect(
    function syncCurrentOption() {
      if (!isEditable || !selectedOption || isAddOptionValue(selectedOption?.value)) {
        // sync is only required when options may be changed/deleted by the component (isEditable=true),
        // and when option is selected (Excluding "Add" option, which has no real value)
        return;
      }

      const providedOption = options.find((option) => option.value === selectedOption.value);
      if (!providedOption) {
        // option no longer exists
        setValue(name, null);
      } else if (providedOption.label !== selectedOption.label) {
        // option label has been changed
        setValue(name, providedOption);
      }
    },
    [name, isEditable, selectedOption, options, setValue]
  );
}

export function withFormSelect<Props extends FormProps>(WrappedComponent: FC<{ value: any }>) {
  return ({
    isRequired,
    name,
    options,
    validate,
    onChange,
    treatAddAsNormalOption,
    defaultValue = null,
    ...rest
  }: Props) => {
    const { control, formState } = useFormContext();
    const isError = getErrorByName(formState.errors, name);
    // currently Multi Select are not editable. to support editable multi select useSyncSelectedOption should be updated
    const isEditable = Boolean(rest.onEditClick);
    useSyncSelectedOption(isEditable, name, options);

    return (
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        rules={{
          required: isRequired,
          validate: (currentOption) => {
            const isAddOption = isAddOptionValue(currentOption?.value);
            const skipOptionValidation = isAddOption && treatAddAsNormalOption;
            let option = currentOption;
            if (isEditable && !skipOptionValidation) {
              // required for editable dropdown, since option may be deleted
              option = findOption(currentOption?.value, options);
            }

            // satisfy isRequired (make sure option is not deleted)
            if (isRequired && !option) {
              return false;
            }
            // satisfy provided validation function (optional)
            return !validate || validate(option);
          }
        }}
        render={({ field }) => {
          return (
            <WrappedComponent
              value={field?.value ? field.value : null}
              onChange={(
                option: ISelectOption<any>,
                actionMeta: ActionMeta<any>,
                eventKey: string | null
              ) => {
                const isAddOption = isAddOptionValue(option?.value);
                if (!isAddOption || treatAddAsNormalOption) {
                  field.onChange(option, actionMeta);
                }
                onChange && onChange(option, actionMeta, eventKey);
              }}
              isError={isError}
              options={options}
              isRequired
              {...rest}
            />
          );
        }}
      />
    );
  };
}
