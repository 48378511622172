import { useStores } from 'mobx/hooks/useStores';

import { ANALYTICS_FEATURES, FEATURES } from 'constants/features';

export const useFeature = (featureName: FEATURES) => {
  const { settingsStore } = useStores();
  return settingsStore.hasFeature(featureName);
};

export const useHasAnalyticsFeatures = () => {
  const { settingsStore } = useStores();
  return ANALYTICS_FEATURES.some((feature) => settingsStore.hasFeature(feature));
};
