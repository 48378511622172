import { MouseEvent, FC } from 'react';

import { ToggleButtonGroup, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { OverridableStringUnion } from '@mui/types';

import { Testable } from 'utils/TypeUtils';

import { StyledToggleButton } from 'components/UIkit/atoms/Button/Toggle/StyledToggleButton';

export type ExclusiveToggleOption = {
  value: string | number;
  label: string;
  disabled?: boolean;
} & Testable;

export interface ExclusiveToggleProps {
  options: ExclusiveToggleOption[];
  value: string | number;
  onChange: (event: MouseEvent<HTMLElement>, newSelection: string | number) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  isError?: boolean;
  variant?: 'primary' | 'secondary';
}

export const ExclusiveToggleButton: FC<ExclusiveToggleProps> = ({
  options,
  value: selectedValue,
  onChange,
  fullWidth = false,
  disabled = false,
  isError = false,
  variant = 'primary'
}) => {
  const handleChange = (event: MouseEvent<HTMLElement>, newSelection: string) => {
    if (newSelection !== null) {
      onChange(event, newSelection);
    }
  };

  return (
    <ToggleButtonGroup
      value={selectedValue}
      onChange={handleChange}
      color={isError ? 'error' : 'primary'}
      fullWidth={fullWidth}
      exclusive
      disabled={disabled}
    >
      {options.map(({ value: optionValue, label, disabled = false, testHook }) => {
        const isSelected = optionValue === selectedValue;
        let typographyVariant: OverridableStringUnion<
          Variant | 'inherit',
          TypographyPropsVariantOverrides
        > = 'form-text';

        if (variant === 'secondary') {
          if (isSelected) {
            typographyVariant = 'body1';
          } else {
            typographyVariant = 'body2';
          }
        }

        return (
          <StyledToggleButton
            key={optionValue}
            value={optionValue}
            selected={isSelected}
            disabled={disabled}
            data-test-hook={testHook}
            variant={variant}
          >
            <Typography variant={typographyVariant}>{label}</Typography>
          </StyledToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
