import { FC } from 'react';

import { Box, css, styled } from '@mui/material';

import { getPathwayContainerBlockActionsBar } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';

import Icon from 'components/Icons/Icon';
import { SimpleTooltip } from 'components/Tooltip';
import { theme } from 'components/UIkit/theme';

interface Props {
  index: number;
  totalItems?: number;
  onMove?: (indexA: number, indexB: number) => void;
  onRemove?: (index?: number | number[]) => void;
  withoutRemoveAction?: boolean;
  containerUuid?: string;
}

export const PathwayEditorBlockActionBar: FC<Props> = ({
  index,
  totalItems,
  onMove,
  onRemove,
  withoutRemoveAction = false,
  containerUuid = ''
}) => {
  const canMoveQuestionUp = !!onMove && index !== 0;

  const canMoveQuestionDown = !!onMove && !!totalItems && totalItems > 1 && index < totalItems - 1;

  const canRemoveQuestion = !!onRemove && !withoutRemoveAction;

  return (
    <>
      {(canMoveQuestionUp || canMoveQuestionDown || canRemoveQuestion) && (
        <StyledContainer id={getPathwayContainerBlockActionsBar(containerUuid)}>
          <>
            {canMoveQuestionUp && (
              <SimpleTooltip
                title={
                  <Box display="flex" justifyContent="center" width="max-content" p={20}>
                    Move Up
                  </Box>
                }
                placement="left"
                disablePortal
              >
                <StyledChevronIconContainer onClick={() => onMove(index, index - 1)}>
                  <Icon.Chevron up />
                </StyledChevronIconContainer>
              </SimpleTooltip>
            )}

            {canMoveQuestionDown && (
              <SimpleTooltip
                title={
                  <Box display="flex" justifyContent="center" width="max-content" p={20}>
                    Move Down
                  </Box>
                }
                placement="left"
                disablePortal
              >
                <StyledChevronIconContainer onClick={() => onMove(index, index + 1)}>
                  <Icon.Chevron />
                </StyledChevronIconContainer>
              </SimpleTooltip>
            )}

            {(canMoveQuestionUp || canMoveQuestionDown) && <StyledSeparator />}
          </>

          {canRemoveQuestion && (
            <SimpleTooltip title={<Box p={20}>Remove</Box>} placement="left" disablePortal>
              <StyledRemoveIcon onClick={() => onRemove(index)} />
            </SimpleTooltip>
          )}
        </StyledContainer>
      )}
    </>
  );
};

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(20)};
    position: absolute;
    right: -80px;
    top: 0;
    background-color: ${theme.palette.natural.white};
    border-radius: ${theme.borderRadius.large};
    box-shadow: ${theme.boxShadow.common};
    border: 1px solid ${theme.palette.natural.border};
  `
);

const StyledChevronIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  margin-bottom: ${theme.spacing(20)};

  svg {
    height: 20px;
    width: 20px;
    cursor: pointer;

    * {
      cursor: pointer;
    }
  }
`;

const StyledRemoveIcon = styled(Icon.Remove)`
  cursor: pointer;
`;

const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing(0, 0, 20, 0)};
  `
);
