import { FC, Fragment } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';

import { observer } from 'mobx-react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useWqOpenItemsSection } from 'hooks/useWqOpenItemsSection';

import { WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

import { WorkQueueItemType, WorkQueueSectionName } from 'views/WorkQueue/WorkQueue.types';

import WqCollapsibleSection from 'views/WorkQueue/WorkQueueOpenItemsView/WqCollapsibleSection';
import { WqSectionControl } from 'views/WorkQueue/WorkQueueOpenItemsView/WqSectionControl';
import { WqTicketPlaceholder } from 'views/WorkQueue/WqTicketPlaceholder';

import CallbackRequestTicketRow from 'components/Ticket/TicketRow/CallbackRequestTicketRow';
import OperatorTicketRow from 'components/Ticket/TicketRow/OperatorTicketRow';

import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';

const WqTicketsAndCallbackRequests: FC = () => {
  const { totalPages, totalItems, items, currentPage, isSectionPageLoading } =
    useWqOpenItemsSection(WorkQueueSectionName.TicketsAndCallbackRequests);

  return (
    <div className="report-section">
      <WqCollapsibleSection
        sectionName={Section.Callback}
        totalItems={totalItems}
        onTrigger={(isOpen) =>
          trackSectionUsageAnalyticsEvent({
            action: isOpen ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
            value: Section.Callback,
            page_number: currentPage,
            tab: WorkQueueTabName.OpenItems
          })
        }
        testHook="tickets-and-callback-section"
      >
        {totalPages > 1 && (
          <WqSectionControl sectionName={WorkQueueSectionName.TicketsAndCallbackRequests} />
        )}

        <div className="tickets-list">
          <TransitionGroup>
            {items.map(({ itemType, itemData }, index) => (
              <CSSTransition key={itemData.id} classNames="connect-ticket-transition" timeout={600}>
                <Fragment>
                  {isSectionPageLoading ? (
                    <WqTicketPlaceholder />
                  ) : (
                    <>
                      {itemType === WorkQueueItemType.OperatorTicket &&
                        !itemData.isSymptomOperatorTicket && (
                          <OperatorTicketRow
                            ticket={itemData}
                            withPatientLink
                            ticketIndex={index}
                            ticketSectionCurrentPage={currentPage - 1}
                          />
                        )}

                      {itemType === WorkQueueItemType.CallbackRequestTicket && (
                        <CallbackRequestTicketRow
                          ticket={itemData}
                          withPatientLink
                          ticketIndex={index}
                          ticketSectionCurrentPage={currentPage - 1}
                        />
                      )}
                    </>
                  )}
                </Fragment>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>

        {totalPages > 1 && (
          <WqSectionControl
            sectionName={WorkQueueSectionName.TicketsAndCallbackRequests}
            isBottom
          />
        )}
      </WqCollapsibleSection>
    </div>
  );
};

export default observer(WqTicketsAndCallbackRequests);
