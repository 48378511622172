import { useEffect, useState } from 'react';

import './animations.scss';
import './Fade.scss';

export const Fade = ({
  show,
  children,
  durationInSeconds = 0.5
}: {
  show: boolean;
  children: JSX.Element;
  durationInSeconds?: number;
}) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    shouldRender && (
      <div
        className="fade-container"
        style={{ animation: `${show ? 'fadeIn' : 'fadeOut'} ${durationInSeconds}s` }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
};
