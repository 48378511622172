import { Optional } from 'utils/TypeUtils';

export enum CmStatusEnum {
  OptedOut = -1,
  None = -2,
  Ineligible = -3,
  EligibleForCcm = -4,
  EligibleForPcm = -5,
  Active = -6,
  Ended = -7,
  SuggestedCcm = -8,
  SuggestedPcm = -9,
  InvitedCcm = -10,
  InvitedPcm = -11,
  ConsentedPcm = -12,
  ConsentedCcm = -13,
  LearnMoreCcm = -14,
  LearnMorePcm = -15,
  InvitationFailedCcm = -16,
  InvitationFailedPcm = -17
}

export interface ReasonResponse {
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  isEditable: boolean;
  title: string;
}

export interface CmStatusResponse {
  status: CmStatusEnum;
  episodeId?: number;
  date?: string | null;
  endDate?: string | null;
  reason?: ReasonResponse;
  notes?: string | null;
}

export interface CmPatientResponse {
  patientId: number;
  patientName: string | null;
  patientMrn: string;
  providerName: string | null;
  primaryInsurance: string | null;
  secondaryInsurance: string | null;
  cancerDiagnostics?: string[] | null;
  chronicDiagnostics?: string[] | null;
  visits: number;
  cmStatus: CmStatusResponse;
  monthlyCareTimeMinutes: number | null;
  emrPatientId: string;
  nextVisitDate: string;
  nextVisitLocation: string;
  nextVisitProvider: string;
}

interface EnrolledPatientResponse {
  id: string;
  patientName: string;
  patientMrn: string;
  providerName: string;
  episodeId: number;
  visits: number;
  patientId: number;
  careTime: string;
}

export interface CareManagementReportResponse {
  totalPages: number;
  currentPage: number;
  totalCmPatientsCount: number;
  cmPatients: CmPatientResponse[];
}

export interface CareManagementEnrolledResponse {
  totalPages: number;
  currentPage: number;
  enrolledPatients: EnrolledPatientResponse[];
}

export interface Identifier<I = string, N = string> {
  id: I;
  name: N;
  isDeleted?: boolean;
  isActive?: boolean;
}

export type ProviderResponseType = Identifier;
export type CmStatusResponseType = Identifier<number>;
export type InsuranceResponseType = Optional<Identifier<number>, 'id'>;
export type NextVisitLocationResponseType = Identifier<number>;
export type AntineoplasticResponseType = Identifier;

export interface CareManagementFilterDataResponse {
  providers: ProviderResponseType[];
  providersForEnrolledPatients: ProviderResponseType[];
  cmStatuses: CmStatusResponseType[];
  primaryInsurances: InsuranceResponseType[];
  secondaryInsurances: InsuranceResponseType[];
  nextVisitLocations: NextVisitLocationResponseType[];
  antineoplasticAdmins: AntineoplasticResponseType[];
}

export type CareManagementSendBulkCareInvitationSessionId = string;

export interface CareManagementCheckInviteProgressResponse {
  totalRequestCount: number;
  success: number;
  fail: number;
  incompatible: number;
}
