import { FC } from 'react';

import { Box } from '@mui/material';

import { components, MultiValueGenericProps } from 'react-select';

import { removeHtmlTags } from 'views/Widgets/SanitizeHtml';

import { Text } from 'components/UIkit/atoms/Text';

export const DependentQuestionsBlockTriggersChip: FC<MultiValueGenericProps<any, boolean>> = ({
  children,
  ...props
}) => (
  <components.MultiValueLabel {...props}>
    <Box display="flex" flexGrow={1} width="100%">
      <Text variant="body2" isEllipsis>
        {removeHtmlTags(children as string)}
      </Text>
    </Box>
  </components.MultiValueLabel>
);
