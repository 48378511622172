// @ts-strict-ignore
import { FC } from 'react';

import FlipMove from 'react-flip-move';

import { Col } from 'reactstrap';

import { DurationInterval } from 'models/DurationInterval';

import IconButton from 'components/Buttons/IconButton';
import ComboDateTimePicker from 'components/DateTimePicker/ComboDateTimePicker';
import Icon from 'components/Icons/Icon';

interface Props {
  interval: DurationInterval;
  errors: string[] | undefined;
  removeAllowed: boolean;
  currentClinicianName: string;
  openDatePickerUp?: boolean;
  onUpdateInterval: (interval: DurationInterval) => void;
  onRemoveInterval: (interval: DurationInterval) => void;
}

const IntervalEditorRow: FC<Props> = ({
  interval,
  errors,
  removeAllowed,
  openDatePickerUp,
  onUpdateInterval,
  onRemoveInterval,
  currentClinicianName
}) => {
  const onUpdateStartDate = (date: Date) => {
    const updatedInterval = interval.copyWith({ startDate: date });
    onUpdateInterval(updatedInterval);
  };

  const onUpdateEndDate = (date: Date) => {
    const updatedInterval = interval.copyWith({ endDate: date });
    onUpdateInterval(updatedInterval);
  };

  return (
    <>
      <Col lg={3}>
        <ComboDateTimePicker
          date={interval.startDate}
          onChange={onUpdateStartDate}
          openUp={openDatePickerUp}
        />
      </Col>
      <Col lg={3}>
        <ComboDateTimePicker
          date={interval.endDate}
          onChange={onUpdateEndDate}
          openUp={openDatePickerUp}
        />
      </Col>
      <Col lg={2} className="d-flex align-items-center justify-content-center">
        <span className="text-truncate">
          <b>{interval.doctorDetails.name || currentClinicianName}</b>
        </span>
      </Col>
      <Col lg={3} className="d-flex align-items-center justify-content-center">
        <b className="gray-text">{interval.durationAsText}</b>
      </Col>
      <Col lg={1} className="remove-interval-btn">
        <IconButton disabled={!removeAllowed} onClick={() => onRemoveInterval(interval)}>
          {removeAllowed ? <Icon.XPurple /> : <Icon.XDisabled />}
        </IconButton>
      </Col>
      {errors?.length > 0 && (
        <FlipMove className="entry-error-list" enterAnimation="fade" leaveAnimation="fade">
          <ul>
            {errors.map((error) => (
              <li key={error}>
                <span>{error}</span>
              </li>
            ))}
          </ul>
        </FlipMove>
      )}
    </>
  );
};

export default IntervalEditorRow;
