import { PhoneType } from 'models/PhoneNumberDetails';
import Ticket from 'models/Ticket';

import { usePatientModel } from 'components/Patient/usePatientModel';

export const usePatientSmsStatus = (ticket?: Ticket) => {
  const patient = usePatientModel(ticket?.patientId)!;

  // Determine if we're checking patient or contact
  const isContactContext = ticket?.operatorTicket?.patientContactId;
  const contact = isContactContext
    ? patient.getContactById(ticket.operatorTicket!.patientContactId!)
    : null;

  // Set all SMS-related status based on whether we're checking contact or patient
  const status = contact
    ? {
        hasMobilePhone: contact.phoneType === PhoneType.mobile,
        isSMSDisabled: false
      }
    : {
        hasMobilePhone: patient.hasMobilePhone,
        isSMSDisabled: patient.isSMSDisabled
      };

  const canSendSms = status.hasMobilePhone && !status.isSMSDisabled;
  const shouldOpenSmsBlockedPopup = status.hasMobilePhone && status.isSMSDisabled;
  const isSmsOptionDisabled =
    !status.hasMobilePhone || (!patient.messagingConsent && !status.isSMSDisabled);

  return {
    canSendSms,
    shouldOpenSmsBlockedPopup,
    isSmsOptionDisabled,
    hasMobilePhone: status.hasMobilePhone,
    messagingConsent: patient.messagingConsent
  };
};
