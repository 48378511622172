import { omit } from 'lodash/fp';

import { LightweightPathwayServerStructure } from 'fetchers/responses/pathways.response';

import { PathwayOptionOutcome, PathwayQuestionTypes } from 'models/PathwayTemplates';

import { pathwayAlertToText } from 'components/LightweightPathway/LightweightPathway.constants';

import {
  LightweightPathwayDependentFlatQuestion,
  LightweightPathwayDependentQuestionServerStructure,
  LightweightPathwayMainFlatQuestion,
  LightweightPathwayQuestionAlertText,
  LightweightPathwayQuestionAnswer,
  MultipleValue,
  OrderedLightweightPathwayDetails,
  PathwayAnswerValue,
  SingleValue
} from 'components/LightweightPathway/LightweightPathway.types';

export const getLightweightQuestionAlert = (
  answer?: object & {
    type: PathwayQuestionTypes;
    value: PathwayAnswerValue;
  }
): LightweightPathwayQuestionAlertText => {
  let alert = PathwayOptionOutcome.NONE;

  if (answer?.type === PathwayQuestionTypes.SINGLE) {
    const answerValue = answer.value as SingleValue;

    if (answerValue.outcome) {
      alert = answerValue.outcome;
    }
  }

  if (answer?.type === PathwayQuestionTypes.MULTIPLE) {
    (answer.value as MultipleValue).forEach((option) => {
      if (option?.outcome && option.outcome > alert) {
        alert = option.outcome;
      }
    });
  }

  return pathwayAlertToText[alert]!;
};

export const getAnsweredOptionsIds = (answers: LightweightPathwayQuestionAnswer[]) => {
  const answeredOptionIds: string[] = [];

  answers.forEach((questionAnswer) => {
    if (questionAnswer.type === PathwayQuestionTypes.SINGLE) {
      answeredOptionIds.push((questionAnswer.value as SingleValue).id);
    }

    if (questionAnswer.type === PathwayQuestionTypes.MULTIPLE) {
      (questionAnswer.value as MultipleValue).forEach((option) =>
        answeredOptionIds.push(option.id)
      );
    }
  });
  return answeredOptionIds;
};

export const isDependentQuestionVisible = (
  dependentQuestion: LightweightPathwayDependentQuestionServerStructure,
  answers: LightweightPathwayQuestionAnswer[]
) => {
  const answeredOptionIds = getAnsweredOptionsIds(answers);

  return dependentQuestion?.dependsOn?.some((dependOnOption) =>
    answeredOptionIds.some((answeredOptionId) => dependOnOption === answeredOptionId)
  );
};

export const getLightweightPathwaysFlatQuestions = (
  pathways: LightweightPathwayServerStructure[]
): (LightweightPathwayMainFlatQuestion | LightweightPathwayDependentFlatQuestion)[] => {
  const allQuestions: (
    | LightweightPathwayMainFlatQuestion
    | LightweightPathwayDependentFlatQuestion
  )[] = [];

  pathways.forEach((pathway, pathwayIndex) => {
    let index = 0;

    pathway.questions.forEach((question) => {
      const formattedPathway: OrderedLightweightPathwayDetails = {
        ...omit('questions', pathway),
        index: pathwayIndex
      };
      // @ts-ignore
      const newQuestion: LightweightPathwayMainFlatQuestion = {
        ...question,
        pathway: formattedPathway,
        index
      };
      allQuestions.push(newQuestion);
      index++;

      (question as LightweightPathwayMainFlatQuestion).dependentQuestions?.forEach(
        (dependentQuestion) => {
          allQuestions.push({
            ...dependentQuestion,
            parentQuestionId: question.id,
            pathway: formattedPathway,
            index
          } as LightweightPathwayDependentFlatQuestion);

          index++;
        }
      );
    });
  });

  return allQuestions;
};
