import { FC, useRef, useState } from 'react';

import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';

import { observer } from 'mobx-react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useClickAway } from 'react-use';

import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { DependentQuestionsBlockTriggers } from 'views/Pages/PathwayBuilder/PathwayEditorView/DependentQuestionsBlockTriggers';

import {
  PATHWAY_ADD_QUESTION_BUTTON_ID,
  PATHWAY_ADD_SUB_QUESTION_BUTTON_ID,
  PATHWAY_QUESTION_OPTION_ALERTS_SELECT,
  PATHWAY_QUESTION_OPTION_SUGGESTED_SELECT,
  PATHWAY_QUESTION_OPTION_SUGGESTED_URGENCY_SELECT,
  REMOVE_PATHWAY_QUESTION_POPUP_ID
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.constants';

import {
  DependentQuestionsBlockType,
  PathwayEditorFormFields,
  SubQuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import { PathwayEditorBlockActionBar } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/PathwayEditorBlockActionBar';
import { PathwayQuestions } from 'views/Pages/PathwayBuilder/PathwayEditorView/Questions/PathwayQuestions';
import { RemoveBlockPopup } from 'views/Pages/PathwayBuilder/PathwayEditorView/RemoveBlockPopup';
import { StyledSeparator } from 'views/Pages/PathwayBuilder/shared/styled';

interface Props {
  path: `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}`;
  index: number;
  questionOptions: { optionId: string; title: string }[];
  removeDependentQuestionsBlocks: (index?: number | number[]) => void;
  dependentQuestionsBlock: DependentQuestionsBlockType & Record<'id', string>;
}

export const DependentQuestionsBlock: FC<Props> = observer(
  ({ dependentQuestionsBlock, path, index, questionOptions, removeDependentQuestionsBlocks }) => {
    const [dependentBlockIndexToRemove, setDependentBlockIndexToRemove] = useState<number | null>(
      null
    );
    const [isRemoveDependentBlockPopupOpen, setIsRemoveDependentBlockPopupOpen] = useState(false);
    const {
      selectedPathwayEditorBlockUuid,
      resetSelectedPathwayEditorBlock,
      selectPathwayEditorBlock,
      isSelected: isBlockSelected
    } = usePathwayEditorSelection(dependentQuestionsBlock.uuid);

    const { control, watch, trigger } = useFormContext<PathwayEditorFormFields>();
    const currentDependentQuestionsBlock = watch(path);
    const blockRef = useRef<HTMLDivElement>(null);

    const {
      fields: subQuestions,
      update: updateSubQuestion,
      insert: insertSubQuestion,
      remove: removeSubQuestion,
      move: moveSubQuestion,
      replace: replaceSubQuestions
    } = useFieldArray<
      PathwayEditorFormFields,
      `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}.questions`
    >({
      control,
      name: `${path}.questions`
    });

    useClickAway(blockRef, (event) => {
      if (!isSelected) {
        return;
      }

      let shouldAllowClick = false;

      const questionOptionAlertsSelect = document.getElementById(
        PATHWAY_QUESTION_OPTION_ALERTS_SELECT
      ) as HTMLElement;

      const questionOptionSuggestedSelect = document.getElementById(
        PATHWAY_QUESTION_OPTION_SUGGESTED_SELECT
      ) as HTMLElement;

      const questionOptionSuggestedUrgencySelect = document.getElementById(
        PATHWAY_QUESTION_OPTION_SUGGESTED_URGENCY_SELECT
      ) as HTMLElement;

      const removeBlockPopup = document.getElementById(
        `${REMOVE_PATHWAY_QUESTION_POPUP_ID}-${dependentQuestionsBlock.uuid}`
      ) as HTMLElement;

      const clickedElement = event.target as HTMLElement;
      const clickedParentElement = clickedElement.parentElement as HTMLElement;

      if (
        questionOptionAlertsSelect?.contains(clickedElement) ||
        questionOptionSuggestedSelect?.contains(clickedElement) ||
        questionOptionSuggestedUrgencySelect?.contains(clickedElement) ||
        removeBlockPopup?.contains(clickedElement) ||
        clickedElement.id === PATHWAY_ADD_QUESTION_BUTTON_ID ||
        clickedParentElement?.id === PATHWAY_ADD_QUESTION_BUTTON_ID ||
        clickedElement.id === PATHWAY_ADD_SUB_QUESTION_BUTTON_ID ||
        clickedParentElement?.id === PATHWAY_ADD_SUB_QUESTION_BUTTON_ID
      ) {
        shouldAllowClick = true;
      }

      if (!shouldAllowClick) {
        trigger(`${path}.triggers`);
        trigger(`${path}.questions`);
        resetSelectedPathwayEditorBlock();
      }
    });

    const onInsertQuestion = (questionIndex: number, data: SubQuestionFormField) =>
      insertSubQuestion(questionIndex, data);

    const onUpdateQuestion = (questionIndex: number, data: SubQuestionFormField) =>
      updateSubQuestion(questionIndex, data);

    const onRemoveQuestion = (questionIndex?: number | number[]) => {
      removeSubQuestion(questionIndex);

      if (questionIndex === 0 && dependentQuestionsBlock.questions?.length === 1) {
        updateSubQuestion(0, { ...subQuestions[0], type: 'NewQuestion', title: null });
      }
    };

    const onMoveQuestion = (indexA: number, indexB: number) => moveSubQuestion(indexA, indexB);

    const onReplaceSubQuestions = (value: any) => replaceSubQuestions(value);

    //the dependent questions block is selected if one of the blocks' questions is selected
    const isSelected =
      isBlockSelected ||
      Boolean(
        currentDependentQuestionsBlock?.questions?.find(
          (question) => question.uuid === selectedPathwayEditorBlockUuid
        )
      );

    return (
      <>
        <StyledContainer
          isSelected={isSelected}
          ref={blockRef}
          onClick={(event) => {
            event.stopPropagation();
            selectPathwayEditorBlock(dependentQuestionsBlock.uuid);
          }}
        >
          <DependentQuestionsBlockTriggers
            dependentQuestionsBlockPath={path}
            isBlockSelected={isSelected}
            questionOptions={questionOptions.map((questionOption) => ({
              value: questionOption.optionId,
              label: questionOption.title
            }))}
          />

          <StyledSeparator />

          <Box pr={76} id={currentDependentQuestionsBlock.uuid}>
            <PathwayQuestions
              questions={subQuestions}
              containerPath={path}
              onInsertQuestion={onInsertQuestion}
              onMoveQuestion={onMoveQuestion}
              onRemoveQuestion={onRemoveQuestion}
              onUpdateQuestion={onUpdateQuestion}
              onReplaceQuestions={onReplaceSubQuestions}
              isAddSubQuestionButtonDisabled={!isSelected}
              isSub
              containerUuid={dependentQuestionsBlock.uuid}
              containerRef={blockRef}
            />
          </Box>

          {isSelected && (
            <PathwayEditorBlockActionBar
              containerUuid={dependentQuestionsBlock.uuid}
              index={index}
              onRemove={() => {
                setDependentBlockIndexToRemove(index);
                setIsRemoveDependentBlockPopupOpen(true);
              }}
            />
          )}
        </StyledContainer>

        <RemoveBlockPopup
          blockUuid={dependentQuestionsBlock.uuid}
          isOpen={isRemoveDependentBlockPopupOpen}
          onCancel={() => {
            setDependentBlockIndexToRemove(null);
            setIsRemoveDependentBlockPopupOpen(false);
          }}
          onRemove={() => {
            removeDependentQuestionsBlocks(dependentBlockIndexToRemove as number);
            setDependentBlockIndexToRemove(null);
            setIsRemoveDependentBlockPopupOpen(false);
          }}
        />
      </>
    );
  }
);

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    width: auto;
    border-radius: ${theme.borderRadius.large};
    border: 1px solid ${theme.palette.natural.border};
    padding: ${theme.spacing(20, 20, 20, 28)};
    margin: ${theme.spacing(20, 0, 0, 0)};
    position: relative;
    background-color: ${theme.palette.natural.white};

    &:hover {
      border-color: ${theme.palette.primary.main};
    }

    &::after {
      content: '';
      position: absolute;
      left: 60px;
      top: -21px;
      height: 20px;
      width: 10px;
      background-color: ${theme.palette.natural.border};
    }

    ${!isSelected &&
    css`
      cursor: pointer;
      * {
        cursor: pointer;
      }
    `}

    ${isSelected &&
    css`
      border: 1px solid ${theme.palette.primary.main};
      box-shadow: ${theme.boxShadow.common};
    `}
  `
);
