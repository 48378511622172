import clinicians from 'test-utils/mocks/clinicians.json';
import patient from 'test-utils/mocks/patient.json';
import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';
import { PatientPageTabs } from 'tests/models/pages/patient-page/patient-page.types';

export function getPatientPageTabToSelectorMap() {
  return {
    [PatientPageTabs.OpenItems]: patientPageTestSelectors.tab(PatientPageTabs.OpenItems),
    [PatientPageTabs.ResolvedTickets]: patientPageTestSelectors.tab(
      PatientPageTabs.ResolvedTickets
    ),
    [PatientPageTabs.Calls]: patientPageTestSelectors.tab(PatientPageTabs.Calls),
    [PatientPageTabs.EpisodesAndTasks]: patientPageTestSelectors.tab(
      PatientPageTabs.EpisodesAndTasks
    )
  };
}

export const TEST_PATHWAY_ID = 'ed6e12f1-5925-4c79-92ff-860a573901fc';
export const TEST_PATHWAY_MULTIPLE_QUESTION_ID = '37950612-84d9-4b32-9723-8aa80a897e4b';
export const TEST_PATHWAY_OPTION_ID_IN_MULTIPLE_QUESTION = '81f8427b-d6fa-4947-89fd-e5798f512ca3';
export const TEST_PATHWAY_HOMECARE_INSTRUCTIONS_OPTION_ID_IN_MULTIPLE_QUESTION =
  'homecare-instructions-option-id';
export const TEST_PATHWAY_HOMECARE_INSTRUCTIONS_OPTION_TITLE_IN_MULTIPLE_QUESTION =
  'This is a homecare instructions option title';
export const TEST_PATHWAY_OPTION_TITLE_IN_MULTIPLE_QUESTION = 'NO OUTCOME OPTION 1';
export const TEST_PATHWAY_OPTION_IN_MULTIPLE_QUESTION_NAME =
  'KEY QUESTION: Multiple choice question';
export const TEST_PATHWAY_CATEGORY_ID = '76e5be3b-a86e-4dc1-9882-688c4d633177';
export const TEST_PATHWAY_NAME = 'TestPathway';
export const TEST_CURRENT_CLINICIAN_ID = clinicians[clinicians.length - 1].id;
export const TEST_CURRENT_CLINICIAN_USER_ID = clinicians[clinicians.length - 1].user_id;
export const testDraftCall = patient.calls[1];
export const TEST_DRAFT_CALL_ID = testDraftCall.id;
export const TEST_DRAFT_CALL_TICKET_ID = testDraftCall.ticketsToCalls[0].ticket_id;
export const TEST_SMART_SUMMARY_ID = 1;
export const TEST_SMART_SUMMARY_TEXT = 'Test Smart Summary Text';
