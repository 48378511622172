import { AnalyticEventAction } from 'analytics/AnalyticEventAction';
import { AnalyticEventName, AnalyticsService } from 'analytics/index';

import { NotificationType } from 'components/NotificationCenter/types';

type Action =
  | AnalyticEventAction.Open
  | AnalyticEventAction.Close
  | AnalyticEventAction.LoadMore
  | AnalyticEventAction.Click;

interface Properties {
  action: Action;
  value: 'with badge' | 'without badge' | 'by bell icon' | 'not by bell icon' | 'read' | 'unread';
  section?: 'seen' | 'unseen';
  type?: NotificationType;
}

export const trackNotificationsPanelAnalyticsEvent = ({
  action,
  value,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.NotificationsPanel,
    properties: { action, value, ...optionalProps }
  });
};
