import { ErrorName } from './Error.utils';
import { ErrorWithUi } from './ErrorWithUi';

// this error is handled silently - see getErrorActions
export class MissingEntity extends ErrorWithUi {
  readonly name = ErrorName.MissingEntity;
  constructor(message: string) {
    super(message);
    Object.freeze(this); // prevent modification for MissingEntity
  }
}
