import { useCallback } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { TicketResolutionResponse } from 'fetchers/TicketsFetcher';

import { useTicketResolve } from 'hooks/useTicketResolve';

interface UseTicketResolveReturn {
  (ticketIds: number[], comment?: string): Promise<TicketResolutionResponse | undefined>;
}

export const useBulkTicketResolve = (): UseTicketResolveReturn => {
  const { ticketsStore } = useStores();
  const ticketResolve = useTicketResolve();

  return useCallback(
    function bulkResolveTickets(ticketIds: number[], comment?: string) {
      ticketsStore.resetTicketsBulkActionSet();
      return ticketResolve(ticketIds, comment);
    },
    [ticketResolve, ticketsStore]
  );
};
