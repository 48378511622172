import { useState } from 'react';

import debounce from 'debounce-promise';
import { Options } from 'react-select/dist/declarations/src/types';

import PatientsFetcher from 'fetchers/PatientsFetcher';

import { SpecificPatientOption } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/SpecificPatientsAsyncMultiAutocomplete';
import { ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY } from 'components/UIkit/atoms/Dropdown/Select.shared';

export const useAsyncPatientSearch = (isGlobal?: boolean) => {
  const [isSpecificPatientsSelectMenuOpen, setIsSpecificPatientsSelectMenuOpen] = useState(false);
  const handleSpecificPatientsSearched = (newValue: string) => {
    if (newValue.trim().length < 2) {
      setIsSpecificPatientsSelectMenuOpen(false);
      return;
    }

    setIsSpecificPatientsSelectMenuOpen(true);
  };
  const loadOptions = debounce(
    async (inputValue: string, callback: (options: Options<SpecificPatientOption>) => void) => {
      if (inputValue.trim().length < 2) {
        callback([]);
        return;
      }

      let patients;
      if (isGlobal) {
        const { patients: globalPatients } = await PatientsFetcher.searchGlobalPatients({
          searchTerm: inputValue
        });
        patients = globalPatients;
      } else {
        patients = await PatientsFetcher.searchPatients({ searchTerm: inputValue });
      }

      // @ts-ignore
      const patientsForSelect = patients.map((patient) => ({
        value: patient.patientId,
        label: `${patient.firstName} ${patient.lastName}`,
        dob: patient.dob,
        mrn: patient.mrn,
        ...(isGlobal && { emrPatientId: patient.emrPatientId })
      }));

      callback(patientsForSelect);
    },
    ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY
  );
  return {
    handleSpecificPatientsSearched,
    isSpecificPatientsSelectMenuOpen,
    loadOptions
  };
};
