// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';

import { ExtendedTicket } from 'utils/TicketClusteringUtils';

import { Section } from './TicketsContainers.constants';
import TicketsSection from './TicketsSection';

interface Props {
  tickets: ExtendedTicket[];
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
}

const TicketsSectionUrgent: FC<Props> = ({ tickets, ticketRefsCallback }) => (
  <TicketsSection
    section={Section.Urgent}
    tickets={tickets}
    ticketRefsCallback={ticketRefsCallback}
  />
);

export default observer(TicketsSectionUrgent);
