import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Open | AnalyticEventAction.Cancel | AnalyticEventAction.OptOut;

interface Properties {
  action: Action;
  value?: 'missing fields';
}

export const trackOptOutRemoteMonitoringModalAnalyticsEvent = ({
  action,
  ...optionalProperties
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.OptOutRemoteMonitoringModal,
    properties: { action, ...optionalProperties }
  });
};
