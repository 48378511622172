// @ts-strict-ignore
import { FC, useRef, useState } from 'react';

import { omit } from 'lodash/fp';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { DuplicateTaskTemplateAttrs } from 'fetchers/TaskTemplatesFetcher';

import { useEpisodeDurationTooltipOptions } from 'views/Pages/PracticeManagement/Episodes/useEpisodeDuration';

import { Tooltip, TooltipSelect, TooltipDropdown } from 'components/Tooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';
import { OutlinedButton } from 'components/UIkit/atoms/Button';

interface Props {
  taskTemplateId: number;
  onEdit: (id: number) => void;
}

const TaskTemplateActions: FC<Props> = ({ taskTemplateId, onEdit }) => {
  const tooltipRef = useRef();
  const {
    episodeManagementStore: { duplicateTaskTemplate, taskTemplatesMap, currentFilteredEpisodesMap }
  } = useStores();
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
  const [isDuplicationOpen, setIsDuplicationOpen] = useState(false);
  const [isDuplicateOnceOpen, setIsDuplicateOnceOpen] = useState(false);
  const taskTemplate = taskTemplatesMap.get(taskTemplateId) || null;
  const episode = currentFilteredEpisodesMap.get(taskTemplate?.episodeId) || null;

  const handleDuplicateClicked =
    (isMonthly: boolean = false) =>
    async (monthNumber: number) => {
      setIsDuplicationOpen(false);
      setIsDuplicateOnceOpen(false);
      const normalizeTemplate = isMonthly
        ? omit<DuplicateTaskTemplateAttrs>('monthNumber', taskTemplate)
        : { ...taskTemplate, monthNumber };
      await duplicateTaskTemplate(taskTemplateId, normalizeTemplate);
    };

  const monthOptions = useEpisodeDurationTooltipOptions(episode, handleDuplicateClicked());
  const duplicateOptions = [
    {
      text: 'Duplicate Once',
      id: 'duplicateOnce',
      onClick: () => {
        setIsDuplicateOnceOpen(true);
        setIsDuplicationOpen(false);
      }
    },
    {
      text: 'Duplicate Monthly',
      id: 'duplicateMonthly',
      disabled: taskTemplate.monthNumber === episode.duration,
      onClick: handleDuplicateClicked(true)
    }
  ];

  const mainOptions = [
    {
      text: 'Edit',
      id: 'editTaskTemplate',
      onClick: () => {
        onEdit(taskTemplateId);
        setIsActionsMenuOpen(false);
      }
    },
    {
      text: 'Duplicate',
      id: 'duplicateTaskTemplate',
      onClick: () => {
        setIsActionsMenuOpen(false);
        setIsDuplicationOpen(true);
      }
    }
  ];

  const closeTooltips = () => {
    setIsActionsMenuOpen((prevState) => !prevState);
    setIsDuplicationOpen(false);
    setIsDuplicateOnceOpen(false);
  };

  if (!taskTemplate) {
    return null;
  }

  return (
    <>
      <OutlinedButton
        ref={tooltipRef}
        onClick={closeTooltips}
        testHook="taskTemplateActions"
        isActive={isActionsMenuOpen}
      >
        Actions
      </OutlinedButton>

      <Tooltip
        trigger={TooltipTrigger.CLICK}
        reference={tooltipRef}
        controller={{
          visible: isActionsMenuOpen,
          onClickOutside: () => setIsActionsMenuOpen(false)
        }}
      >
        <TooltipSelect options={mainOptions} />
      </Tooltip>

      <TooltipDropdown
        tooltipReference={tooltipRef}
        tooltipController={{
          visible: isDuplicationOpen,
          onClickOutside: () => setIsDuplicationOpen(false)
        }}
        options={duplicateOptions}
      />
      <TooltipDropdown
        tooltipReference={tooltipRef}
        tooltipController={{
          visible: isDuplicateOnceOpen,
          onClickOutside: () => setIsDuplicateOnceOpen(false)
        }}
        options={monthOptions}
      />
    </>
  );
};

export default observer(TaskTemplateActions);
