import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBulkMenuItem = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(16)} ${theme.spacing(16)};
    white-space: nowrap;
  `
);
