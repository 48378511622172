// @ts-strict-ignore
import { useMemo } from 'react';

import { observer } from 'mobx-react';

import moment from 'moment';

import {
  DAY_IN_MILLISECONDS,
  HOUR_IN_MILLISECONDS,
  MONTH_IN_MILLISECONDS,
  WEEK_IN_MILLISECONDS
} from 'utils/DateUtils';

import ScheduledProtocol from 'models/ScheduledProtocol';

import './ProtocolTimesMessageDivider.scss';

const MessageDivider = ({
  intervalFrequency,
  startHour,
  startDate,
  isPhoneProtocol,
  weekdays
}: {
  intervalFrequency: number;
  startHour?: number;
  startDate?: string;
  isPhoneProtocol?: boolean;
  weekdays?: number[];
}) => {
  let explanationText =
    'Consider scheduling reports two days post-treatment. Also note that Fridays are short days at the clinic.';

  if (intervalFrequency === DAY_IN_MILLISECONDS) {
    explanationText = 'Consider using the weekly protocol instead to avoid report fatigue.';
  }

  if (intervalFrequency === MONTH_IN_MILLISECONDS) {
    explanationText =
      'This is the recommended option for patients in remission or follow-up with little symptom burden.';
  }

  if (intervalFrequency === HOUR_IN_MILLISECONDS) {
    explanationText = 'Reports will not be requested from 10:00PM to 9:00AM (local time).';
  }

  const warningText = useMemo(() => {
    if (
      isPhoneProtocol &&
      startHour &&
      startDate &&
      ScheduledProtocol.isOutsideBusinessHours(
        intervalFrequency === WEEK_IN_MILLISECONDS ? weekdays : null,
        moment(startDate).hour(startHour).toISOString()
      )
    ) {
      return 'Please reconsider scheduling calls outside of business days / hours ';
    }
    return '';
  }, [isPhoneProtocol, startDate, startHour, weekdays, intervalFrequency]);

  return (
    <div className="divider-message">
      {warningText && <p className="warning-text">{warningText}</p>}
      <p className="explanation-text">{explanationText}</p>
    </div>
  );
};
export default observer(MessageDivider);
