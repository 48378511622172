import { NationalNumber, format } from 'libphonenumber-js';

export const formatInternationalNumber = (
  countryCode: string,
  phoneNum: string | NationalNumber
) => {
  return countryCode + phoneNum;
};

export const formatPhoneNumber = (phoneNumber: NationalNumber): string =>
  format(phoneNumber, 'US', 'INTERNATIONAL');
