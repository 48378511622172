// @ts-strict-ignore

import { ErrorWithUi } from 'errors';

import { castArray } from 'lodash';
import { action, observable, makeObservable } from 'mobx';

import { RootStore } from './rootStore';

export default class UiStore {
  rootStore: RootStore;

  @observable
  error: Error | ErrorWithUi;

  @observable
  errorDescription: string[];

  @observable
  action: { actionText: string; actionCallback: () => void };

  @observable
  isIntro: boolean;

  @observable
  isNotificationPanelOpen: boolean = false;

  @observable
  bulkInviteToastId: string | number = '';

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  postError = (
    error: Error,
    errorDescription: string | string[] = null,
    errorAction: { actionText: string; actionCallback: () => void } = null
  ) => {
    this.error = error;
    this.errorDescription = errorDescription ? castArray(errorDescription) : null;
    this.action = errorAction;
  };

  @action
  clearError = () => {
    this.error = null;
  };

  @action
  setIntro(show: boolean) {
    this.isIntro = show;
  }

  @action
  setIsNotificationPanelOpen(isOpen: boolean) {
    this.isNotificationPanelOpen = isOpen;
  }

  @action
  setBulkInviteToastId(toastId: string | number) {
    this.bulkInviteToastId = toastId;
  }

  @action
  clearBulkInviteToastId = () => {
    this.bulkInviteToastId = '';
  };
}
