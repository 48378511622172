import { SyntheticEvent } from 'react';

import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';

import { PathwayTemplatesQuestion } from 'fetchers/responses/pathways-builder.response';

import Icon from 'components/Icons/Icon';

import { TableCellParams } from 'components/Table';

import { SimpleTooltip } from 'components/Tooltip';

interface Props extends TableCellParams<PathwayTemplatesQuestion> {
  setEditedRowId: (questionId: string) => void;
}

export const RowActionsCell = (cellParams: Props) => {
  const { row, setEditedRowId } = cellParams;

  const handleEditClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    setEditedRowId(row.original.id);
  };

  const title = <Box p={20}>Edit</Box>;

  return (
    <Box display="flex" justifyContent="flex-end" className="actions-container">
      <SimpleTooltip title={title} placement="top">
        <StyledEditIcon onClick={handleEditClick} />
      </SimpleTooltip>
    </Box>
  );
};

const StyledEditIcon = styled(Icon.PencilOutline)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${theme.palette.primary.main};
    }
  `
);
