import { FC } from 'react';

import { AsyncBaseSelect } from 'components/UIkit/atoms/Dropdown/BaseSelect';
import { InlineLabelComponents } from 'components/UIkit/atoms/Dropdown/Select/ReactSelectComponents';
import {
  AsyncBaseSelectProps,
  ReactSelectInternalProps
} from 'components/UIkit/atoms/Dropdown/Select.shared';
import {
  AsyncFormProps,
  withFormAsyncSelect
} from 'components/UIkit/atoms/Dropdown/withFormAsyncSelect';
// === form components ===//
// since react-form-hook is setting the value, it can be omitted
export interface BaseAsyncSelectFormProps
  extends Omit<AsyncBaseSelectProps, 'value'>,
    AsyncFormProps {}
export const FormAsyncBaseSelect = withFormAsyncSelect<
  BaseAsyncSelectFormProps & ReactSelectInternalProps
>(AsyncBaseSelect);

// currently not in used, since only ICD codes uses AsyncSelect (see FormIcdCodesAsyncMultiAutocomplete)
export const FormAsyncMultiAutocomplete: FC<BaseAsyncSelectFormProps> = (props) => {
  return <FormAsyncBaseSelect isMulti defaultOptions {...props} />;
};

export const FormAsyncAutocompleteInlineLabel: FC<BaseAsyncSelectFormProps> = (props) => (
  <FormAsyncBaseSelect {...props} components={InlineLabelComponents} />
);

export const FormAsyncAutocomplete: FC<BaseAsyncSelectFormProps> = (props) => (
  <FormAsyncBaseSelect {...props} />
);
