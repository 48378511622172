import { SearchedClinician } from 'fetchers/CliniciansFetcher';

import { TicketClinician } from 'models/Ticket';
import { Clinician } from 'models/TicketHistoryAudit';

import { PracticeManagerClinician } from 'views/Pages/PracticeManagement/ManagePracticeUsersPage/ManagePracticeUsersPage.types';

export type ShortClinician =
  | Clinician
  | TicketClinician
  | SearchedClinician
  | PracticeManagerClinician;

export function getNameWithCredentials(name: string, credential: string | null): string {
  return credential ? `${name}, ${credential}` : name;
}

export const getClinicianFullName = (clinician: ShortClinician) => {
  if (!clinician) {
    return '';
  }

  const { firstName, lastName } = clinician;
  return `${firstName} ${lastName}`;
};

export function shortenedFullName(clinician: TicketClinician): string {
  if (!clinician) {
    return '';
  }
  return `${clinician.firstName} ${clinician.lastName?.charAt(0) || ''}`;
}
