import { mapValues } from 'lodash';

import { IIcdCategory } from 'models/Icd';
import { PathwaysData, PathwayTemplate, BaseQuestion, BaseOption } from 'models/PathwayTemplates';

export class ConstantsParser {
  static parsePathwayData(data: any): PathwaysData {
    const pathways: { [id: string]: PathwayTemplate } = mapValues(data.pathways);
    const questions: { [id: string]: BaseQuestion } = mapValues(data.questions);
    const options: { [id: string]: BaseOption } = mapValues(data.options);

    return {
      pathways,
      questions,
      options
    };
  }

  static parseIcdCategories(unparsedCategories: any[]): IIcdCategory[] {
    return unparsedCategories.map((unparsedCategory) => {
      return {
        id: unparsedCategory.id,
        code: unparsedCategory.code,
        text: unparsedCategory.description,
        icdCodes: unparsedCategory.icdCodes.map((unparsedCode: any) => {
          return {
            id: unparsedCode.id,
            code: unparsedCode.code,
            text: unparsedCode.description
          };
        })
      };
    });
  }
}
