import { FC, ReactNode } from 'react';

import { Modal } from 'reactstrap';

import BaseModalHeader from './BaseModalHeader';

import './BaseModal.scss';

interface INoFooterModalProps {
  children: ReactNode;
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  isWarning?: boolean;
  className?: string;
  unmountOnClose?: boolean;
  size?: 'sm' | 'lg' | 'xl';
}
const NoFooterModal: FC<INoFooterModalProps> = ({
  isOpen,
  title,
  subtitle,
  isWarning = false,
  className,
  unmountOnClose,
  size = 'lg',
  children
}) => (
  <Modal
    // solve select-focus issue. see https://github.com/getexpain/dashboard/pull/2085
    returnFocusAfterClose={false}
    unmountOnClose={unmountOnClose}
    isOpen={isOpen}
    className={`modal-${size} p-4 base-modal ${className}`}
  >
    <BaseModalHeader title={title} subtitle={subtitle} isWarning={isWarning} />
    {children}
  </Modal>
);

export default NoFooterModal;
