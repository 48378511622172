import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { PracticeManagerParser } from 'parsers/PracticeManagerParser';

import {
  PracticeManagerUsersBody,
  PracticeManagerUsersParsedResponse
} from 'views/Pages/PracticeManagement/ManagePracticeUsersPage/ManagePracticeUsersPage.types';

const httpService = new HttpService('practice manager');

export class PracticeManagerFetcher {
  static async fetchPracticeManagerUsers(
    requestBody: PracticeManagerUsersBody
  ): Promise<PracticeManagerUsersParsedResponse> {
    return await httpService.post({
      url: API_URLS.PRACTICE_MANAGER_USERS,
      data: requestBody,
      transformResponse: PracticeManagerParser.parseUsers
    });
  }
}
