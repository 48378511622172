import React, { FC } from 'react';

import { Grid } from '@mui/material';
import debounce from 'debounce-promise';

import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { DxCodeData, DxRequestData } from 'fetchers/QaFetcher';

import { addDecimalToICDCodeIfNeeded } from 'utils/IcdCodesUtils';

import CallReason from 'models/CallReason';
import { IIcdCategory, IIcdCode } from 'models/Icd';
import { SelectOption } from 'models/SelectOption';

import { FormInputField } from 'components/Forms';
import { FormIcdCodesAsyncMultiAutocomplete } from 'components/UIkit/atoms/Dropdown';
import {
  ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY,
  ISelectOption
} from 'components/UIkit/atoms/Dropdown/Select.shared';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

interface DxRowFormFields {
  patientId: string;
  dxCodes: SelectOption<DxCodeData>[];
}

export const QaAddDxModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const { qaStore, constantsStore } = useStores();
  const methods = useForm<DxRowFormFields>();

  const { handleSubmit } = methods;

  const handleOnSubmit = (dxRowFormFields: DxRowFormFields) => {
    const normalizedRequest: DxRequestData = {
      patientId: Number(dxRowFormFields.patientId),
      dxCodes: dxRowFormFields.dxCodes?.map((dxCode) => dxCode.value) as DxCodeData[]
    };
    qaStore.addDiagnosisToPatient(normalizedRequest);
    onSubmit();
  };

  const getSelectOptions = (categories: IIcdCategory[]) =>
    categories.map((callReason: IIcdCategory) => ({
      label: `${addDecimalToICDCodeIfNeeded(callReason.code)} ${callReason.text}`,
      options: callReason.icdCodes.map((icdCode: IIcdCode) => ({
        label: `${addDecimalToICDCodeIfNeeded(icdCode.code)} ${icdCode.text}`,
        value: {
          code: icdCode.code,
          description: icdCode.text
        }
      }))
    }));

  const loadOptions = debounce(async (inputValue: string) => {
    const resultCategories = await constantsStore.searchIcdCodeCategories(inputValue);
    return getSelectOptions(resultCategories);
  }, ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY);

  return (
    <FormModal
      defaultValues={null}
      methods={methods}
      title="Add Diagnosis to Patient"
      isOpen={isOpen}
      confirmActions={[{ onClick: handleSubmit(handleOnSubmit), text: 'Add', disabled: false }]}
      closeAction={{ onClick: onClose, disabled: false }}
    >
      <Grid container mb={40} spacing={12}>
        <Grid item xs={6}>
          <FormInputField isRequired name="patientId" label="Patient ID" />
        </Grid>
        <Grid item xs={6}>
          <FormIcdCodesAsyncMultiAutocomplete
            label="ICD-10 Codes"
            testHook="testHook"
            name="dxCodes"
            isRequired
            loadOptions={loadOptions}
            placeholder="Search By Symptom or Code"
            getOptionValue={(option: ISelectOption<CallReason>) =>
              option.value!.icdCodeCode + option.value!.text
            } // To make the options unique
          />
        </Grid>
      </Grid>
    </FormModal>
  );
};
