import { css, Theme } from '@mui/material/styles';

import { editorTheme } from 'components/UIkit/atoms/RichText/RichText.constants';

export const sharedRichTextStyles = (theme: Theme) => css`
  .${editorTheme.list.ul} {
    padding-left: ${theme.spacing(24)};
    margin: 0;
  }

  .${editorTheme.list.ol} {
    padding-left: ${theme.spacing(24)};
    margin: 0;
  }

  p {
    margin: 0;
  }

  .${editorTheme.list.nested.listitem} {
    list-style-type: none;
  }

  .${editorTheme.list.nested.listitem}:before, .${editorTheme.list.nested.listitem}:after {
    display: none;
  }

  .${editorTheme.list.olDepth[0]} {
    list-style-type: decimal;
  }

  .${editorTheme.list.olDepth[1]} {
    list-style-type: upper-alpha;
  }

  .${editorTheme.list.olDepth[2]} {
    list-style-type: lower-alpha;
  }

  .${editorTheme.list.olDepth[3]} {
    list-style-type: upper-roman;
  }

  .${editorTheme.list.olDepth[4]} {
    list-style-type: lower-roman;
  }

  .${editorTheme.text.bold} {
    font-weight: bold;
  }

  .${editorTheme.text.italic} {
    font-style: italic;
  }
`;
