// @ts-strict-ignore
import { Dispatch, FC, SetStateAction } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { IPatientContact } from 'models/Patient';

import EditableDropdown from 'views/Widgets/EditableDropdown';

interface Props {
  setContactToEdit: Dispatch<SetStateAction<IPatientContact>>;
  openPatientContactModal: (contactId?: number) => void;
}
export const EditPatientsContacts: FC<Props> = observer(
  ({ setContactToEdit, openPatientContactModal }) => {
    const { settingsStore, patientPageStore } = useStores();
    const { patient } = patientPageStore;

    const onContactSelect = (contactId: number) => {
      if (contactId < 0) {
        openPatientContactModal();
        return;
      }

      openPatientContactModal(contactId);
    };

    const getOptions = () =>
      patient.activeContacts.map((contact: IPatientContact) => ({
        value: contact.id,
        label: contact.name,
        onEditClick: () => {
          openPatientContactModal(contact.id);
          setContactToEdit(contact);
        }
      }));

    return (
      <div>
        <EditableDropdown
          className="contact-edit-dropdown"
          placeholder="View or Edit Callback Contacts..."
          addText="Add Callback Contact"
          onChange={onContactSelect}
          options={getOptions()}
          value={null}
          label="Callback Contacts"
          disabled={!settingsStore.hasFeature(FEATURES.CALLBACK_CONTACTS)}
          openUp={patient.activeContacts?.length > 2}
        />
      </div>
    );
  }
);
