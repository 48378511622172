import { FC } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { PathwayCategory } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayCategory';
import {
  CategoryFormField,
  PathwayEditorFormFields
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

export const PathwayCategories: FC = () => {
  const { control } = useFormContext<PathwayEditorFormFields>();

  const {
    fields: categories,
    remove: removeCategory,
    insert: insertCategory
  } = useFieldArray<PathwayEditorFormFields, 'categories'>({
    control,
    name: 'categories'
  });

  const onRemoveCategory = (index?: number | number[]) => removeCategory(index);

  const onInsertCategory = (index: number, value: CategoryFormField) =>
    insertCategory(index, value);

  return (
    <>
      {categories.map((category: CategoryFormField, index) => (
        <PathwayCategory
          key={category.categoryId}
          index={index}
          path={`categories.${index}`}
          category={category}
          onRemoveCategory={onRemoveCategory}
          onInsertCategory={onInsertCategory}
        />
      ))}
    </>
  );
};
