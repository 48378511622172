import { compact, isNil } from 'lodash/fp';
import { computed, ObservableMap, action, observable, makeObservable } from 'mobx';

import { debounceService } from 'services/debounceService';

export interface DataFallback<T = any> {
  token?: string;
  isDataExist: (item: T) => boolean;
  fetchMissingData: (item?: T) => Promise<any>;
  onDataNotFound: (item?: T) => void;
}

type dataMapKeyType = string | number;

interface DataMapOptions<T> {
  keyProp?: keyof T & string;
  fallbacks?: Set<DataFallback>;
}

const DEFAULT_MAP_KEY = 'id';

export class DataMap<T> {
  private readonly fallbacks: Set<DataFallback> = new Set();
  private readonly keyProp: keyof T & string;
  @observable itemsMap: ObservableMap<dataMapKeyType, T>;

  constructor(options: DataMapOptions<T> = {}) {
    makeObservable(this);
    this.itemsMap = observable.map();
    const { keyProp, fallbacks } = options;
    this.keyProp = keyProp ? keyProp : (DEFAULT_MAP_KEY as keyof T & string);

    if (fallbacks && fallbacks.size) {
      this.fallbacks = new Set(fallbacks);
    }
  }

  // proxy methods to observable.map:
  values = () => this.itemsMap.values();
  keys = () => this.itemsMap.keys();
  clear = () => this.itemsMap.clear();
  delete = (key: dataMapKeyType) => this.itemsMap.delete(key);
  get = (key: dataMapKeyType) => this.itemsMap.get(key);
  set = (key: dataMapKeyType, item: T) => this.itemsMap.set(key, item);

  @computed
  get items(): T[] {
    return Array.from(this.values());
  }

  @computed
  get keysArray(): dataMapKeyType[] {
    return Array.from(this.keys());
  }

  @computed
  get size() {
    return this.itemsMap.size;
  }

  getItemByKeys = (keys: dataMapKeyType[]): T[] => {
    const items = keys.map((key: dataMapKeyType) => {
      const item = this.get(key);
      if (isNil(item)) {
        console.warn(`item with key: ${key} requested is not defined`);
      }
      return item;
    });

    return compact(items);
  };

  @action
  setItems(items: T[]) {
    this.clear();
    this.replaceItems(items);
  }

  @action
  replaceItems(items: T[]) {
    items.forEach((item) => this.replaceItem(item));
  }

  @action
  addFallback(fallback: DataFallback<T>) {
    this.fallbacks.add(fallback);
  }

  @action
  removeFallback(fallback: DataFallback<T>) {
    this.fallbacks.delete(fallback);
  }

  @action
  async replaceItem(item: T) {
    // fallback provided
    if (this.fallbacks?.size) {
      const fallbacks = Array.from(this.fallbacks);
      for (const fallback of fallbacks) {
        const { token, isDataExist, fetchMissingData, onDataNotFound } = fallback;
        if (isDataExist && !isDataExist(item)) {
          const doFetch = debounceService.isMinimalTimePassed(token);
          // make sure  minimal time passes between fallbacks
          if (doFetch) {
            debounceService.updateTimestamp(token);
            await fetchMissingData(item);
            // fallback invoked, but data is still missing
            if (!isDataExist(item)) {
              onDataNotFound(item);
            }
          }
        }
      }
    }
    const key = item[this.keyProp] as unknown as dataMapKeyType;
    this.set(key, item);
  }
}
