import { ToggleButton } from '@mui/material';
import { css, styled } from '@mui/material/styles';

export const StyledToggleButton = styled(ToggleButton, {
  shouldForwardProp: (prop) => prop !== 'variant'
})<{ variant: 'primary' | 'secondary' }>(
  ({ theme, variant }) => css`
    &.MuiToggleButton-root {
      padding: ${variant === 'primary'
        ? `${theme.spacing(20)} ${theme.spacing(12)}`
        : `${theme.spacing(8)} ${theme.spacing(20)}`};

      &.Mui-selected {
        ${variant === 'primary' &&
        css`
          background-color: ${theme.palette.primary.main};
          color: ${theme.palette.natural.contrast};
          border: 1px solid ${theme.palette.primary.main};

          &:hover {
            background-color: ${theme.palette.primary.main};
          }
        `}

        ${variant === 'secondary' &&
        css`
          background-color: ${theme.palette.secondary.alternate};
          color: ${theme.palette.secondary.dark};

          &:hover {
            background-color: ${theme.palette.secondary.alternate};
          }
        `}
        
        &.Mui-disabled {
          background-color: ${theme.palette.natural.pressed};
          color: ${theme.palette.natural.white};
        }
      }

      &.Mui-disabled {
        background-color: ${theme.palette.natural.inactiveBackground};
        border: 1px solid ${theme.palette.natural.border};
        color: ${theme.palette.text.disabled};
      }
    }
  `
);
