// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { Dialogs } from 'analytics/events/dialog';
import { trackDropdownInputUsageAnalyticsEvent } from 'analytics/events/input-usage';
import { trackOptOutRemoteMonitoringAnalyticsEvent } from 'analytics/events/opt-out-remote-monitoring';
import { trackOptOutRemoteMonitoringModalAnalyticsEvent } from 'analytics/events/opt-out-remote-monitoring-modal';

import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { OptOutReasonsFetcher } from 'fetchers/OptOutReasonsFetcher';

import { OptOutReason } from 'models/OptOutReason';
import { PatientOptOut } from 'models/PatientOptOut';

import { SelectOption } from 'models/SelectOption';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { FormSelect, isAddOptionValue } from 'components/UIkit/atoms/Dropdown';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import './OptOutModal.scss';

interface IOptOutModalProps {
  isOpen: boolean;
  onClose: (value: PatientOptOut | null) => void;
  patientId?: string;
}

export interface IOptOutSubmitObject {
  reasonId: number;
  details: string;
  name: string;
}

interface OptOutModalForm {
  selectedReason: SelectOption<number>;
  customReasonName: string;
  extraOptOutDetails: string;
}

interface EditReasonForm {
  editedReasonName: string;
}
export const PatientOptOutModal = ({ isOpen, onClose, patientId }: IOptOutModalProps) => {
  const { userStore } = useStores();
  const optOutMethods = useForm<OptOutModalForm>();
  const editReasonMethods = useForm<EditReasonForm>();
  const [isDataInitialized, setIsDataInitialized] = useState(false);
  const [reasons, setReasons] = useState<OptOutReason[]>([]);
  const [saveNewCustomReason, setSaveNewCustomReason] = useState(true);
  const [removedReason, setRemovedReason] = useState(null);
  const [editedReason, setEditedReason] = useState(null);
  const [isEditingReason, setIsEditingReason] = useState(false);
  const selectedReason = optOutMethods.watch('selectedReason');
  const isAddReasonSelected = isAddOptionValue(selectedReason?.value);

  const options = useMemo(
    () =>
      reasons.map((reason) => ({
        value: reason.id,
        label: reason.name,
        isEditable: Boolean(reason.institutionId) && userStore.isManager
      })),
    [reasons, userStore.isManager]
  );

  const editReason = useCallback((reason: OptOutReason) => {
    editReasonMethods.reset({ editedReasonName: reason.name });
    setEditedReason(reason);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateReasons = useCallback(() => {
    async function fetchReasons() {
      setIsDataInitialized(false);
      const optOutReasons: OptOutReason[] = await OptOutReasonsFetcher.getReasons();
      setReasons(optOutReasons);
      setIsDataInitialized(true);
    }
    fetchReasons();
  }, []);

  const initState = useCallback(() => {
    setReasons([]);
    setSaveNewCustomReason(true);
    populateReasons();
  }, [populateReasons]);

  useEffect(() => {
    if (isOpen) {
      initState();
    }
  }, [isOpen, initState]);

  const onOptOutSave = async () => {
    trackOptOutRemoteMonitoringModalAnalyticsEvent({
      action: AnalyticEventAction.OptOut
    });

    trackOptOutRemoteMonitoringAnalyticsEvent({
      action: AnalyticEventAction.OptOut,
      patient_id: patientId
    });

    let reasonId;
    let reasonName;

    if (isAddReasonSelected) {
      const newReason = await saveReason();
      reasonId = newReason.id;
      reasonName = newReason.name;
    } else {
      const reason = reasons.find((reason) => reason.id === selectedReason.value);
      reasonId = reason.id;
      reasonName = reason.name;
    }
    const details = optOutMethods.getValues('extraOptOutDetails');
    const now = new Date();
    onClose(
      new PatientOptOut(null, new OptOutReason(reasonId, reasonName, null), details, now, now)
    );
  };

  const deleteReasonClicked = useCallback((reason: OptOutReason) => {
    setRemovedReason(reason);
  }, []);

  const handleReasonEdit = async () => {
    setIsEditingReason(true);
    const editedReasonName = editReasonMethods.getValues('editedReasonName');
    try {
      await OptOutReasonsFetcher.updateReason(editedReason.id, editedReasonName);
      const updatedReason = new OptOutReason(
        editedReason.id,
        editedReasonName,
        editedReason.institutionId
      );
      const updatedReasons = reasons.map((reason) =>
        reason.id === updatedReason.id ? updatedReason : reason
      );
      setReasons(updatedReasons);
      setEditedReason(null);
    } finally {
      setIsEditingReason(false);
    }
  };

  const onEditClick = (option: SelectOption<number>) => {
    const reason = reasons.find((reason) => reason.id === option.value);
    if (reason) {
      editReason(reason);
    }
  };

  const saveReason = () => {
    const customReasonName = optOutMethods.getValues('customReasonName');
    return OptOutReasonsFetcher.createReason(customReasonName, saveNewCustomReason);
  };

  const sendRemoveReasonRequest = async () => {
    setIsEditingReason(true);
    try {
      await OptOutReasonsFetcher.removeReason(removedReason.id);
      if (removedReason.id === selectedReason) {
        optOutMethods.reset();
      }
      setReasons(reasons.filter((reason) => reason.id !== removedReason.id));
      setEditedReason(null);
      closeRemoveReasonModal();
    } finally {
      setIsEditingReason(false);
    }
  };

  const onSaveNewReasonCheckboxClick = () => {
    setSaveNewCustomReason(!saveNewCustomReason);
  };

  const closeRemoveReasonModal = () => {
    setRemovedReason(null);
  };

  const renderRemoveReasonPopup = () => {
    return (
      <MessageDialog
        id={Dialogs.RemoveReason}
        isOpen={removedReason !== null}
        title="Remove this reason from suggestions?"
        handleClose={closeRemoveReasonModal}
        primaryActionProps={{ text: 'Remove', onClick: sendRemoveReasonRequest }}
        secondaryActionProps={{ text: 'Cancel', onClick: closeRemoveReasonModal }}
      >
        {removedReason ? `"${removedReason.name}"` : ''}
      </MessageDialog>
    );
  };

  const buildForm = () => {
    return (
      <div className="opt-out-form">
        {isDataInitialized && (
          // form selects should be initialize only after options are valid (or internal sync function will reset the form value)
          <FormSelect
            options={options}
            addText={userStore.isManager && 'Reason'}
            placeholder="Select Reason"
            label="Opt-Out Reason"
            isDisabled={reasons.length === 0}
            name="selectedReason"
            onEditClick={onEditClick}
            treatAddAsNormalOption
            isRequired
            onChange={(value, actionMeta, eventKey) =>
              trackDropdownInputUsageAnalyticsEvent(
                actionMeta,
                'Opt Out Reason',
                eventKey === 'Enter',
                Boolean(selectedReason)
              )
            }
          />
        )}
        {isAddReasonSelected && (
          <div>
            <RHFStyledInput
              name="customReasonName"
              register={optOutMethods.register}
              error={Boolean(optOutMethods.formState.errors.customReasonName)}
              isRequired={isAddReasonSelected}
              placeholder="Opt-Out Reason Title"
              rounded
            />
          </div>
        )}
        {selectedReason && (
          <div>
            <RHFStyledInput
              name="extraOptOutDetails"
              register={optOutMethods.register}
              error={Boolean(optOutMethods.formState.errors.extraOptOutDetails)}
              placeholder={`Optional: add details to the opt-out reason. `}
              rounded
              type="textarea"
            />
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      {renderRemoveReasonPopup()}

      <FormModal
        defaultValues={null}
        methods={editReasonMethods}
        isOpen={editedReason !== null}
        title="Edit Opt-Out Reason"
        confirmActions={[
          {
            onClick: editReasonMethods.handleSubmit(handleReasonEdit),
            text: isEditingReason ? 'Saving...' : 'Save',
            disabled: isEditingReason
          }
        ]}
        closeAction={{ onClick: () => setEditedReason(null), disabled: false }}
        secondaryAction={{
          type: 'button',
          onClick: () => deleteReasonClicked(editedReason),
          text: 'Delete',
          disabled: false
        }}
      >
        <div className="edit-reason-modal-content">
          <RHFStyledInput
            rounded
            isRequired
            name="editedReasonName"
            error={Boolean(editReasonMethods.formState.errors.editedReasonName)}
            register={editReasonMethods.register}
          />
        </div>
      </FormModal>

      <FormModal
        defaultValues={null}
        methods={optOutMethods}
        isOpen={isOpen}
        isInternalModalOpen={editedReason}
        title="Opt Out of Remote Monitoring?"
        confirmActions={[
          {
            onClick: optOutMethods.handleSubmit(onOptOutSave, () =>
              trackOptOutRemoteMonitoringModalAnalyticsEvent({
                action: AnalyticEventAction.OptOut,
                value: 'missing fields'
              })
            ),
            text: 'Opt Out',
            disabled: false
          }
        ]}
        closeAction={{
          onClick: () => {
            trackOptOutRemoteMonitoringModalAnalyticsEvent({ action: AnalyticEventAction.Cancel });
            onClose(null);
          },
          disabled: false
        }}
        secondaryAction={{
          isVisible: isAddReasonSelected,
          type: 'controlled-labeled-checkbox',
          label: 'Save this reason for future use',
          id: 'save-reason',
          onChange: onSaveNewReasonCheckboxClick,
          checked: saveNewCustomReason
        }}
      >
        <div className="inner-modal-block">{buildForm()}</div>
      </FormModal>
    </div>
  );
};
