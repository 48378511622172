import { FC } from 'react';

import { observer } from 'mobx-react';

import Ticket from 'models/Ticket';

import { CollapsibleSection } from 'views/Widgets/CollapsibleSection';

import CallbackRequestTicketRow from 'components/Ticket/TicketRow/CallbackRequestTicketRow';
import OperatorTicketRow from 'components/Ticket/TicketRow/OperatorTicketRow';
import { useIsInPatientRoute } from 'components/Ticket/TicketsContainers/useIsInPatientRoute';

import { Section } from './TicketsContainers.constants';
import { useSectionExpand } from './useTicketOverviewContext';

interface Props {
  operatorTickets: Ticket[];
  callbackRequestTickets: Ticket[];
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
}

const TicketsSectionCallback: FC<Props> = ({
  operatorTickets,
  callbackRequestTickets,
  ticketRefsCallback
}) => {
  const [isExpanded, setIsExpanded] = useSectionExpand(Section.Callback);
  const isOnPatientPage = useIsInPatientRoute();

  return (
    <div className="report-section">
      <CollapsibleSection
        onTrigger={setIsExpanded}
        name={Section.Callback}
        trigger={
          <div className="report-section-header">
            {`${Section.Callback} (${operatorTickets.length + callbackRequestTickets.length})`}
          </div>
        }
        initiallyOpen={isExpanded}
      >
        <div className="tickets-list">
          {operatorTickets.map((operatorTicket, index) => (
            <OperatorTicketRow
              key={operatorTicket.id}
              ticket={operatorTicket}
              withPatientLink={!isOnPatientPage}
              ticketRefs={ticketRefsCallback}
              ticketIndex={index}
            />
          ))}

          {callbackRequestTickets.map((ticket, index) => (
            <CallbackRequestTicketRow
              ticket={ticket}
              ticketRefsCallback={ticketRefsCallback}
              withPatientLink={!isOnPatientPage}
              ticketIndex={operatorTickets.length + index}
              key={ticket.id}
            />
          ))}
        </div>
      </CollapsibleSection>
    </div>
  );
};

export default observer(TicketsSectionCallback);
