// @ts-strict-ignore
import { FC, ReactNode, SyntheticEvent, useRef, useState } from 'react';

import { formatDate, isToday } from 'utils/DateUtils';

import { DatePickerTooltip } from 'components/Tooltip/DatepickerTooltip';
import { TextButton } from 'components/UIkit/atoms/Button';

import './DateTimePicker.scss';

interface Props {
  date: Date;
  onSelect: (value: Date) => void;
  placeholder?: ReactNode;
  min?: Date;
  max?: Date;
  labelSize?: 'medium' | 'large';
}

// see: // https://jquense.github.io/react-widgets/api/DateTimePicker
const LabelDatePicker: FC<Props> = ({
  date,
  onSelect,
  placeholder,
  min,
  max,
  labelSize = 'medium'
}) => {
  const tooltipRef = useRef();
  const [visible, setVisible] = useState(false);

  const labelClickHandler = (event: SyntheticEvent) => {
    event.stopPropagation();
    setVisible((prevState) => !prevState);
  };

  const selectHandler = (date: Date) => {
    setVisible(false);
    onSelect(date);
  };

  const displayDate = () => {
    if (isToday(date)) {
      return 'Today';
    }

    return date ? formatDate(date, 'MMM D, YYYY') : placeholder || '';
  };

  return (
    <>
      <TextButton onClick={labelClickHandler} isActive={visible} ref={tooltipRef} size={labelSize}>
        {displayDate()}
      </TextButton>

      <DatePickerTooltip
        reference={tooltipRef}
        date={date}
        placement="bottom"
        onSelect={selectHandler}
        min={min}
        max={max}
        controller={{
          visible,
          onClickOutside: () => setVisible(false)
        }}
      />
    </>
  );
};

export default LabelDatePicker;
