import { FC, useMemo, useState } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import { InView } from 'react-intersection-observer';
import { Table } from 'reactstrap';

import { useStores } from 'mobx/hooks/useStores';

import { DAY_IN_MILLISECONDS, getDateSorter } from 'utils/DateUtils';

import { FEATURES } from 'constants/features';

import Patient from 'models/Patient';

import Ticket from 'models/Ticket';

import { CallsAndCallbackPreferenceRow } from 'views/Patient/PatientMain/ReportsTable/CallsAndCallbackPreferenceRow';

import { useCreateReportTableDataObject } from 'views/Patient/PatientMain/ReportsTable/ReportTableData.util';

import { ReportsTr, SectionHeaderTr, TdShape } from './ReportsTableFragments';
import { TableReportItem } from './ReportTable.model';
import { displayOralSection } from './ReportTableDataOral.util';
import { useReportNormalization } from './useReportNormalization';

import './ReportsTable.scss';

interface Props {
  patient: Patient;
  symptomOperatorTickets: Ticket[];
  daysToLoad: number;
}

function filterByTimeFrame(collection: TableReportItem[], timeInMs: number) {
  const now = Date.now();
  if (timeInMs !== 0) {
    return collection.filter((item) => item.createdAt.getTime() > now - timeInMs);
  }
  return collection;
}

const ReportsTable: FC<Props> = ({ patient, symptomOperatorTickets, daysToLoad }) => {
  const { settingsStore } = useStores();
  const { distressOralAndDrugReports } = patient;
  const timeFrameInMs = DAY_IN_MILLISECONDS * daysToLoad;
  const [isInView, setIsInView] = useState(false);
  const allReports = useReportNormalization(
    symptomOperatorTickets,
    distressOralAndDrugReports,
    patient
  );

  const reports = useMemo(() => {
    const filteredReports = filterByTimeFrame(allReports, timeFrameInMs);
    return filteredReports.sort(getDateSorter('createdAt', false));
  }, [allReports, timeFrameInMs]);

  const tableData = useCreateReportTableDataObject(reports, patient);
  const {
    distressRow,
    oralChemoSection,
    symptomsSection,
    numericValuesSection,
    headerRow,
    callbackRow
  } = tableData;

  const displayOral = useMemo(() => {
    return displayOralSection(oralChemoSection);
  }, [oralChemoSection]);

  return (
    <div className="reports-table">
      {headerRow.length > 0 ? (
        <InView
          as="div"
          className="table-container"
          onChange={setIsInView}
          threshold={0}
          triggerOnce
        >
          {isInView && (
            <Table hover responsive className="mb-0 d-sm-table">
              <thead>
                <tr>
                  <th className="top-left-corner-cell" />
                  {headerRow.map((headerData, index) => (
                    <th
                      key={index}
                      className={classNames('th-severity', { triage: headerData.isOperator })}
                    >
                      {headerData.text}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="table-body">
                {settingsStore.hasFeature(FEATURES.DISTRESS_THERMOMETER_ROW) && (
                  <ReportsTr
                    text="Distress (of 10)"
                    data={distressRow}
                    cellShape={TdShape.Rectangle}
                  />
                )}
                {Object.keys(numericValuesSection).map((causeName) => {
                  const cause = numericValuesSection[causeName];
                  /* Hard coding farenheight since temperature is the only numeric cause at the moment. this will cause bugs
                  in the future but we currently have no other way of differentiating the causes (if adding a new numeric cause,
                  be sure to also fix DrugSpecificReportSummary */
                  return (
                    <ReportsTr
                      key={causeName}
                      text={causeName + ' (°F)'}
                      data={cause}
                      cellShape={TdShape.Rectangle}
                    />
                  );
                })}

                <CallsAndCallbackPreferenceRow
                  text="Calls & Callback Preference"
                  callsAndCallbackRow={callbackRow}
                />

                {displayOral && (
                  <>
                    <SectionHeaderTr text="ORAL ONCOLYTICS" colSpan={headerRow.length} />
                    <ReportsTr text="New Cycle" data={oralChemoSection.NewCycle} />
                    <ReportsTr text="Medication Received" data={oralChemoSection.MedReceived} />
                    <ReportsTr text="Active Medication" data={oralChemoSection.ActiveMedication} />
                    <ReportsTr text="Regimen Change" data={oralChemoSection.RegimenAdherence} />
                    <ReportsTr
                      text="Change in Other Meds"
                      data={oralChemoSection.ChangeInOtherMeds}
                    />
                    <ReportsTr text="Missed Dose" data={oralChemoSection.MissedDose} />
                    <ReportsTr text="Doses Remaining" data={oralChemoSection.DosesRemaining} />
                  </>
                )}
                <SectionHeaderTr text="SYMPTOMS" colSpan={headerRow.length} />
                {Object.keys(symptomsSection).map((causeName) => {
                  const cause = symptomsSection[causeName];
                  return <ReportsTr key={causeName} text={causeName} data={cause} />;
                })}
                {}
              </tbody>
            </Table>
          )}
        </InView>
      ) : (
        <div className="empty-state-table">
          <p className="empty-state-text">No reports were submitted in this timeframe.</p>
        </div>
      )}
    </div>
  );
};

export default observer(ReportsTable);
