import { FC } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import Doctor from 'models/Doctor';

import './DoctorDetails.scss';

interface Props {
  doctor: Doctor;
  className?: string;
  showDepartments?: boolean;
}

const DoctorDetails: FC<Props> = ({ doctor, className, showDepartments }) => {
  const { departmentsStore } = useStores();

  const departments = doctor?.departmentIds
    ? departmentsStore.departments.getItemByKeys(doctor.departmentIds)
    : [];
  const containerClasses = classNames('doctor-details', className);

  return (
    <div className={containerClasses}>
      <div className="general-data">
        <div className="d-flex flex-column">
          <div className="details">
            <div className="doctor-name">{doctor.fullName},</div>
            <div className="institution-name">{departmentsStore.root.name}</div>
          </div>

          {showDepartments && departments && (
            <div className="departments">
              {departments
                .filter((department) => department.name !== departmentsStore.root.name)
                .map(
                  (department, i) => `${department.name}${i !== departments.length - 1 ? ', ' : ''}`
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(DoctorDetails);
