// @ts-strict-ignore
import { Card, CardBody } from 'reactstrap';

import ClinicianAlert from 'models/ClinicianAlert';

import NoDataView from 'views/Patient/PatientMain/NoDataView';

import Loading from 'components/Loaders/Loading';

import './AlertsTable.scss';

interface IAlertsTableProps {
  alerts: ClinicianAlert[];
  onRemoveAlertClicked?: (id: number) => void;
  onAlertClicked?: (alert: ClinicianAlert) => void;
  textPlaceHolder?: string;
}

const AlertsTable = (props: IAlertsTableProps) => {
  const handleClickedAlert = (alert: ClinicianAlert) => {
    props.onAlertClicked && props.onAlertClicked(alert);
  };

  if (!props.alerts) {
    return (
      <Card>
        <CardBody>
          <div className="p-4">
            <Loading primaryColor />
          </div>
        </CardBody>
      </Card>
    );
  }
  if (props.alerts.length === 0) {
    return (
      <div className="no-alerts-placeholder">
        <NoDataView text={props.textPlaceHolder} />
      </div>
    );
  }

  return (
    <div className="blue-white-table">
      <table className="d-sm-table">
        <thead>
          <tr>
            <th>Patient Population</th>
            <th>Report Protocol</th>
            <th>Urgency</th>
            <th>Conditions</th>
          </tr>
        </thead>
        <tbody>
          {props.alerts.map((alert: ClinicianAlert) => (
            <tr
              className="cursor-pointer"
              onClick={() => handleClickedAlert(alert)}
              key={alert.id}
              data-test-hook="alert-row-is-global"
            >
              <td>
                <div>{alert.population.name}</div>
              </td>
              <td>
                <div>{`${alert.reportType} Assessment`}</div>
              </td>
              <td>
                <div>{alert.getUrgencyName()}</div>
              </td>
              <td className="conditions-column">
                <div>{alert.getConditionsString()}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default AlertsTable;
