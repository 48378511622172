import { ReactElement } from 'react';

import classNames from 'classnames';

import { Testable } from 'utils/TypeUtils';

import { Tooltip } from 'components/Tooltip/Tooltip';

import './ToggleBar.scss';

export interface TabOption<T = any> extends Testable {
  value: T;
  label: string;
  extraText?: string;
  disabled?: boolean;
  tooltipText?: string;
}

interface IToggleBarProps<T = any> {
  options: Array<TabOption<T>>;
  selected: T;
  onOptionSelected: (value: T) => void;
  className?: string;
  isSquared?: boolean;
  size?: 'small' | 'medium' | 'large';
  itemClassName?: string;
  id: string;
}

const sizeClasses = {
  small: 'small-toggle-container',
  medium: 'medium-toggle-container',
  large: 'large-toggle-container'
};

const ToggleBar = <T,>(props: IToggleBarProps<T>) => {
  const {
    size = 'large',
    className,
    itemClassName,
    isSquared,
    options,
    selected,
    onOptionSelected,
    id
  } = props;

  const containerClasses = classNames('toggle-container', className, sizeClasses[size], {
    squared: isSquared
  });

  return (
    <div className={containerClasses} data-test-hook={id}>
      {options.map((option) => {
        const itemClasses = classNames('toggle-item', itemClassName, {
          active: selected === option.value,
          disabled: option.disabled
        });
        const tab: ReactElement = (
          <div
            key={option.label}
            className={itemClasses}
            data-test-hook={option.testHook || option.label}
            onClick={() => onOptionSelected(option.value)}
          >
            <p className="toggle-text">{option.label}</p>
            {option.extraText ? <p className="toggle-text">{option.extraText}</p> : null}
          </div>
        );

        return option.tooltipText ? (
          <Tooltip label={tab} key={option.label}>
            <div className="p-3">{option.tooltipText}</div>
          </Tooltip>
        ) : (
          tab
        );
      })}
    </div>
  );
};

export default ToggleBar;
