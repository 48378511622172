import { PathwaySelectType } from 'components/Ticket/TicketRow/CallAndPathwaysDropDown';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Click | AnalyticEventAction.Select | AnalyticEventAction.Search;

type Value = PathwaySelectType | 'closed pathway';

interface Properties {
  action: Action;
  value?: Value;
  virtual_page?: 'call logger';
  ticket_ids?: number[];
  tab?: 'All Questions' | 'Key Questions';
  page_number?: number;
  item_index?: number;
  bulk_item?: boolean;
}

export const trackPathwayMenuAnalyticsEvent = ({
  action,
  bulk_item = false,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.PathwayMenu,
    properties: { action, bulk_item, ...optionalProps }
  });
};
