import { ReactNode, useEffect } from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { sortParamsToUrlString } from 'utils/urlUtils';

import { FEATURES } from 'constants/features';

import { ManagePracticeTagsPage } from 'views/Pages/PracticeManagement/ManagePracticeTagsPage';
import ManagePracticeUsersPage from 'views/Pages/PracticeManagement/ManagePracticeUsersPage/ManagePracticeUsersPage';
import { PracticeManagerUsersSortBy } from 'views/Pages/PracticeManagement/ManagePracticeUsersPage/ManagePracticeUsersPage.types';
import ToggleBar, { TabOption } from 'views/Widgets/ToggleBar';

import ManagePracticeEpisodesPage from './Episodes/ManagePracticeEpisodesPage';
import { ManagePracticeLocationsPage } from './ManagePracticeLocationsPage';
import { ManagePracticeProvidersPage } from './ManagePracticeProvidersPage';
import ManagePracticeTicketTypesPage from './TicketTypes/ManagePracticeTicketTypesPage';

import './PracticeManagerPage.scss';

interface IPracticeManagerPage extends RouteComponentProps<{ activeTab: string }> {}

const TABS = {
  USERS: 'users',
  TICKET_TYPES: 'ticketTypes',
  EPISODES: 'episodes',
  TAGS: 'tags',
  PROVIDERS: 'providers',
  LOCATIONS: 'locations'
};

export const PRACTICE_MANAGEMENT_TABS: {
  [key: string]: {
    value: string;
    label: string;
    page: ReactNode;
  };
} = {
  [TABS.USERS]: {
    value: TABS.USERS,
    label: 'Users',
    page: <ManagePracticeUsersPage />
  },
  [TABS.TICKET_TYPES]: {
    value: TABS.TICKET_TYPES,
    label: 'Ticket Types',
    page: <ManagePracticeTicketTypesPage />
  },
  [TABS.EPISODES]: {
    value: TABS.EPISODES,
    label: 'Episodes',
    page: <ManagePracticeEpisodesPage />
  },
  [TABS.TAGS]: {
    value: 'tags',
    label: 'Patient Tags',
    page: <ManagePracticeTagsPage />
  },
  [TABS.PROVIDERS]: {
    value: TABS.PROVIDERS,
    label: 'Providers',
    page: <ManagePracticeProvidersPage />
  },
  [TABS.LOCATIONS]: {
    value: TABS.LOCATIONS,
    label: 'Locations',
    page: <ManagePracticeLocationsPage />
  }
};

const DEFAULT_ACTIVE_TAB = TABS.USERS;

const defaultUrlParams = {
  sortBy: PracticeManagerUsersSortBy.Date,
  sortAsc: false
};

export const PracticeManagerPage = ({ history, match }: IPracticeManagerPage) => {
  const { settingsStore } = useStores();

  const getSelectedTab = () => {
    const { activeTab } = match.params;
    return PRACTICE_MANAGEMENT_TABS[activeTab];
  };

  const getTabOptions = (): Array<TabOption<string>> => {
    const tabOptions: Array<TabOption<string>> = [];
    if (settingsStore.hasFeature(FEATURES.PM_USERS)) {
      tabOptions.push(PRACTICE_MANAGEMENT_TABS[TABS.USERS]);
    }

    if (settingsStore.hasFeature(FEATURES.PM_TICKETS_TYPES)) {
      tabOptions.push(PRACTICE_MANAGEMENT_TABS[TABS.TICKET_TYPES]);
    }

    if (settingsStore.hasFeature(FEATURES.PM_EPISODES)) {
      tabOptions.push(PRACTICE_MANAGEMENT_TABS[TABS.EPISODES]);
    }

    if (settingsStore.hasFeature(FEATURES.PM_PATIENT_TAGS)) {
      tabOptions.push(PRACTICE_MANAGEMENT_TABS[TABS.TAGS]);
    }

    if (settingsStore.hasFeature(FEATURES.PM_PROVIDERS)) {
      tabOptions.push(PRACTICE_MANAGEMENT_TABS[TABS.PROVIDERS]);
    }

    if (settingsStore.hasFeature(FEATURES.PM_LOCATIONS)) {
      tabOptions.push(PRACTICE_MANAGEMENT_TABS[TABS.LOCATIONS]);
    }

    return tabOptions;
  };

  const selectedTab = getSelectedTab();

  useEffect(
    function onTabChange() {
      if (!selectedTab || selectedTab.value === TABS.USERS) {
        const urlParams = sortParamsToUrlString(defaultUrlParams.sortBy, defaultUrlParams.sortAsc);
        history.push(`/practice/${DEFAULT_ACTIVE_TAB}?${urlParams}`);
      }
    },
    [history, selectedTab]
  );

  const onTabSelect = (tab: string) => {
    if (selectedTab.value !== tab) {
      let urlParams = '';

      if (tab === TABS.USERS) {
        urlParams = sortParamsToUrlString(defaultUrlParams.sortBy, defaultUrlParams.sortAsc);
      }

      history.push(`/practice/${tab}?${urlParams}`);
    }
  };

  return (
    <div className="animated fadeIn limited-width dashboard-view practice-manager-page">
      <div className="title">Practice Manager</div>
      {selectedTab && (
        <>
          <div className="content-tabs">
            <ToggleBar
              id="practiceManagerToggle"
              options={getTabOptions()}
              selected={selectedTab.value}
              onOptionSelected={onTabSelect}
              isSquared
              size="small"
              className="practice-manager-page-tabs-selector"
              itemClassName="practice-manager-page-selector-item"
            />
          </div>
          <div className="content">{selectedTab.page}</div>
        </>
      )}
    </div>
  );
};

export default PracticeManagerPage;
