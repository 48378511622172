export const canReadClipboard = async () => {
  if (navigator.permissions && navigator.clipboard) {
    //permission name: 'clipboard-read' is not fully finalized yet so TS doesn't include it
    // @ts-ignore
    const { state } = await navigator.permissions.query({ name: 'clipboard-read' });
    return ['granted', 'prompt'].includes(state);
  } else {
    return false;
  }
};

export const readClipboard = async () => {
  if (await canReadClipboard()) {
    return await navigator.clipboard.readText();
  } else {
    throw new Error('No clipboard permission');
  }
};
