import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import './TableBodyContainer.scss';

interface TableBodyContainerProps {
  containerClasses?: string;
  content: ReactNode;
  emptyTableView: ReactNode;
  hasData: boolean;
  style?: object;
}

const TableBodyContainer: FC<TableBodyContainerProps> = (props) => {
  return (
    <div
      className={classNames(props.containerClasses, {
        'list-container': props.hasData
      })}
      style={props.style}
    >
      {props.hasData ? props.content : <div className="empty-view">{props.emptyTableView}</div>}
    </div>
  );
};

export default TableBodyContainer;
