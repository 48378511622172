import { observer } from 'mobx-react';

import CallReason, { CallTopicDiscussed } from 'models/CallReason';
import Patient from 'models/Patient';

import {
  CallReasonSelectionFields,
  useCallReasonFields,
  CallReasonsFields,
  callReasonOptionsToValues
} from 'views/Widgets/CallIReasonsFields';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import './CallReasonSelectionModal.scss';

interface ICallReasonSelectionModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSaved: (callReasons: CallReason[], topicsDiscussed: CallTopicDiscussed[]) => void;
  patient: Patient;
  initialValue?: CallReason[];
}

export const CallReasonSelectionModal = observer(
  ({ isOpen, onCancel, onSaved, patient, initialValue = [] }: ICallReasonSelectionModalProps) => {
    const { callReasonFieldsMethods, defaultValues } = useCallReasonFields(patient, {
      callReasons: initialValue,
      topics: []
    });

    const onSubmit = (values: CallReasonSelectionFields) => {
      const { callReasons, topicsDiscussed } = callReasonOptionsToValues(values);
      onSaved(callReasons, topicsDiscussed);
    };

    return (
      <FormModal
        defaultValues={defaultValues}
        methods={callReasonFieldsMethods}
        isOpen={isOpen}
        confirmActions={[
          { onClick: callReasonFieldsMethods.handleSubmit(onSubmit), text: 'Save', disabled: false }
        ]}
        closeAction={{ onClick: onCancel, disabled: false }}
        title="Select ICD-10 & Topics Discussed"
      >
        <CallReasonsFields patient={patient} defaultValues={defaultValues} />
      </FormModal>
    );
  }
);
