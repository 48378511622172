import { FC } from 'react';

import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { ActionMeta } from 'react-select';

import { useCliniciansAsyncSelect } from 'hooks/useCliniciansAsyncSelect';

import { SelectFieldCustomComponents } from 'views/Widgets/StyledSelect';

import {
  FormAsyncAutocomplete,
  FormAsyncAutocompleteInlineLabel
} from 'components/UIkit/atoms/Dropdown/Select/Autocomplete/AsyncAutocomplete';

interface IProps {
  fieldName: string;
  customComponents?: SelectFieldCustomComponents;
  placeholder?: string;
  label: string;
  isInline?: boolean;
  onChange?: (value: any, actionMeta: ActionMeta<any>, eventKey: string | null) => void;
  validate?: (value: any) => boolean;
  withClearableOption?: boolean;
}

const ClinicianSelectionFormField: FC<IProps> = ({
  fieldName,
  placeholder,
  label,
  isInline = false,
  onChange,
  validate,
  withClearableOption = false
}) => {
  const { loadOptions, defaultOptions } = useCliniciansAsyncSelect();
  const { watch } = useFormContext();

  let fieldToWatch = null;

  if (withClearableOption) {
    fieldToWatch = watch(fieldName);
  }

  // FormAutocompleteInlineLabel will removed after ticket type re-design
  const Autocomplete = isInline ? FormAsyncAutocompleteInlineLabel : FormAsyncAutocomplete;

  return (
    <Autocomplete
      loadOptions={loadOptions}
      label={label}
      name={fieldName}
      placeholder={placeholder}
      defaultOptions={defaultOptions}
      onChange={onChange}
      validate={validate}
      isClearable={Boolean(fieldToWatch?.value)}
    />
  );
};

export default observer(ClinicianSelectionFormField);
