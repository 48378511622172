// @ts-strict-ignore
import { DataMap } from 'mobx/stores/DataMap';

import { createStringSortFn } from 'utils/StringUtils';

import { treeScan } from 'utils/TicketType.utils';

import {
  IOperTicketType,
  OperatorTicketMap,
  SymptomType,
  TicketTypeCreationAllowance,
  TicketTypeKind
} from 'models/TicketTypes';

import { TicketTypeNode } from './TicketTypeNode';

const MAX_TREE_LEVELS = 3;
/**
 * Tree of types with minimum level of 2 (in case no subtypes exist) and maximum level of 3 (with subtypes)
 * Level calculation does not including root (which is array of nodes, but not a node of itself)
 * Levels are:
 * Root
 * --Categories
 * ----Types
 * -------Subtypes (optional)
 */

// here the key is ticketTypeId_ticketSubTypeId (for subtypes) because we might have subtypes with the same ID
// (symptoms subtypes are taken from a different table)
export function getNodeKey(id: number, parentId?: number): string {
  return parentId ? `${parentId}_${id}` : id.toString();
}

export class TicketTypeTree {
  symptomCategory: TicketTypeNode;
  categories: TicketTypeNode[];
  // Keys per levels - lvl1:'id'; lvl2/3: 'parentId_id'
  nodesMap: Map<string, TicketTypeNode>;

  get operatorCategories() {
    return this.categories.filter((category) => category.kind !== TicketTypeKind.symptom);
  }

  constructor() {
    this.categories = [];
    this.nodesMap = new Map<string, TicketTypeNode>();
  }

  addCategoriesAndOperatorTypes(operatorTicketTypes: OperatorTicketMap) {
    const untreatedTypes: IOperTicketType[] = [];

    operatorTicketTypes.forEach((ticketType: IOperTicketType) => {
      const newNode = new TicketTypeNode(
        ticketType.id,
        ticketType.name,
        ticketType.kind || TicketTypeKind.other,
        Boolean(ticketType.institutionId),
        ticketType.displaySettings,
        ticketType.isDeleted,
        ticketType.displayNames,
        ticketType.hasActiveTickets
      );
      const nodeKey = getNodeKey(ticketType.id, ticketType.parentId);

      if (!ticketType.parentId) {
        this.categories.push(newNode);
        this.nodesMap.set(nodeKey, newNode);
        if (newNode.kind === TicketTypeKind.symptom) {
          this.symptomCategory = newNode;
        }
      } else {
        const addedNode = this.addOperatorTypeNodeAsChild(newNode, ticketType);
        if (!addedNode) {
          // parent not in tree yet
          untreatedTypes.push(ticketType);
        }
      }
    });
    this.treatSubtypes(untreatedTypes, operatorTicketTypes);
    this.sortTree();
  }

  treatSubtypes(untreatedTypes: IOperTicketType[], operatorTicketTypes: OperatorTicketMap) {
    for (let i = 0; untreatedTypes.length && i < MAX_TREE_LEVELS; i++) {
      const stillUtreated: IOperTicketType[] = [];

      untreatedTypes.forEach((ticketType: IOperTicketType) => {
        const newNode = new TicketTypeNode(
          ticketType.id,
          ticketType.name,
          TicketTypeKind.other,
          Boolean(ticketType.institutionId),
          ticketType.displaySettings,
          ticketType.isDeleted,
          ticketType.displayNames,
          ticketType.hasActiveTickets
        );
        const addedNode = this.addOperatorTypeNodeAsChild(newNode, ticketType, operatorTicketTypes);
        if (!addedNode) {
          stillUtreated.push(ticketType);
        }
      });

      untreatedTypes = stillUtreated;
    }

    if (untreatedTypes.length > 0) {
      // assuming no more levels than MAX_TREE_LEVELS - all parents should be in tree
      console.warn('could not locate parent for some ticket types', untreatedTypes);
    }
  }

  sortTree() {
    const stringCompare = createStringSortFn<TicketTypeNode>((node) => node.name);
    this.categories.sort(stringCompare);
    this.categories.forEach((category) => {
      treeScan(category, (node) => node.children.sort(stringCompare));
    });
  }

  addOperatorTypeNodeAsChild(
    newNode: TicketTypeNode,
    ticketType: IOperTicketType,
    operatorTicketTypes?: OperatorTicketMap
  ) {
    // parent and child should have the same kind value
    const { parentId } = ticketType;
    let grandParentId;

    if (operatorTicketTypes) {
      // required for lvl3 only
      const parent = operatorTicketTypes.get(parentId);
      grandParentId = parent.parentId;
    }

    const parentKey = getNodeKey(parentId, grandParentId);
    const parentNode = this.nodesMap.get(parentKey);
    if (parentNode) {
      // parent already in tree - set parent and add as child
      this.addChildNode(parentNode, newNode);
      return newNode;
    }
  }

  addSymptomTypes(symptomsTicketTypes: DataMap<SymptomType>) {
    symptomsTicketTypes.items.forEach((symptom) => {
      const newNode = new TicketTypeNode(
        symptom.id,
        symptom.name,
        TicketTypeKind.symptom,
        false, // symptom types are never editable
        [TicketTypeCreationAllowance.OPERATOR], // symptom types are always allowed
        symptom.isDeleted,
        [],
        symptom.hasActiveTickets,
        symptom.reportableIn // only symptoms
      );
      this.addChildNode(this.symptomCategory, newNode);
    });
  }

  addChildNode(parentNode: TicketTypeNode, childNode: TicketTypeNode) {
    childNode.parent = parentNode;
    parentNode.children.push(childNode);

    const nodeKey = getNodeKey(childNode.id, parentNode.id);
    this.nodesMap.set(nodeKey, childNode);
  }
}
