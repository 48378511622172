import { FC, ReactNode } from 'react';

import { TableProps } from 'react-table';

import './TableContent.scss';

const TableContent: FC<TableProps & { children: ReactNode }> = (props) => {
  return (
    <div {...props} className="expain-table">
      {props.children}
    </div>
  );
};

export default TableContent;
