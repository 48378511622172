import { FC, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import QaFetcher from 'fetchers/QaFetcher';

import { VERSION } from 'constants/config';

import { Text } from 'components/UIkit/atoms/Text';

export const Versions: FC = () => {
  const [serverVersion, setServerVersion] = useState<string>('');

  useEffect(function init() {
    const getServerVersion = async () => {
      const serverVersion = await QaFetcher.getServerVersion();
      setServerVersion(serverVersion);
    };

    getServerVersion();
  }, []);

  return (
    <Box>
      <Text display="block">
        Client Version: <Text id="client-version">{VERSION}</Text>
      </Text>

      <Text>
        Server Version: <Text id="server-version">{serverVersion}</Text>
      </Text>
    </Box>
  );
};
