import { useStores } from 'mobx/hooks/useStores';

import { usePathwayId } from './usePathwayId';

export const useSymptomOptionsForPathway = () => {
  const { constantsStore } = useStores();
  const pathwayId = usePathwayId();

  if (pathwayId) {
    return constantsStore.getSymptomOptionsForPathwayById(pathwayId);
  }

  return [];
};
