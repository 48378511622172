// @ts-strict-ignore
import { FC, useEffect, useRef, useState } from 'react';

import { styled } from '@mui/material/styles';

import autosize from 'autosize';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { FormProvider, useForm } from 'react-hook-form';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { getFormattedCommentWithMention } from 'utils/MentionUtils';

import { API_LABELS } from 'constants/apiUrls';

import Comment from 'models/Comment';
import Ticket from 'models/Ticket';

import { useAsyncMentionOptions } from 'hooks/useAsyncMentionOptions';

import SanitizeHtml from 'views/Widgets/SanitizeHtml';

import IconButton from 'components/Buttons/IconButton';
import { FormMentionableInput } from 'components/UIkit/atoms/Input';
import { MENTION_CLASS } from 'components/UIkit/atoms/Input/FormMentionableInput';

interface IProps {
  ticket: Ticket;
  comments: Comment[];
  onSubmit?: () => void;
}

const TicketComment: FC<{ comment: Comment; className?: string }> = observer(
  ({ comment, className }) => {
    const commentClasses = classNames('comment d-flex flex-column', className);

    return (
      <div className={commentClasses}>
        <div className="comment-date">{comment.creationInfo}</div>
        <SanitizeHtml
          html={comment.textWithLineBreaks}
          className={classNames('comment-description')}
        />
      </div>
    );
  }
);

const TicketComments: FC<IProps> = ({ ticket, comments, onSubmit }) => {
  const newCommentRef = useRef<HTMLInputElement>();
  const { ticketsStore } = useStores();
  const mentionOptions = useAsyncMentionOptions();
  const [isLoading, setIsLoading] = useState(false);
  const inProgress = useNetworkLoading(API_LABELS.COMMENTS(ticket.id));
  const methods = useForm();

  useEffect(
    function () {
      if (newCommentRef.current) {
        autosize(newCommentRef.current);
      }
    },
    [newCommentRef]
  );

  const handleSubmit = async (data: { newComment: string }) => {
    const text = getFormattedCommentWithMention(data.newComment);
    onSubmit && onSubmit();

    setIsLoading(true);
    try {
      await ticketsStore.addTicketComment(ticket, text);
      methods.reset({ newComment: '' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="ticket-comments d-flex flex-column">
      {comments?.map((comment) => (
        <TicketComment key={comment.id} comment={comment} />
      ))}
      <FormProvider {...methods}>
        <div className="new-comment-container">
          <StyledFormMentionableInput
            options={mentionOptions}
            name="newComment"
            grayBackground
            isRequired
            withSendButtonStyle
          />
          <div className="comment-send mr-2">
            <IconButton
              onClick={methods.handleSubmit(handleSubmit)}
              disabled={isLoading || inProgress}
            >
              <i className="send-icon p-2" />
            </IconButton>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

const StyledFormMentionableInput = styled(FormMentionableInput)`
  & .${MENTION_CLASS}__control .${MENTION_CLASS}__input:not(:focus) {
    border: none;
  }
`;

export default TicketComments;
