// @ts-strict-ignore
import { FC, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { showToast } from 'utils/UserMessageUtils';

import { API_URLS } from 'constants/apiUrls';
import { ALL_ROLES } from 'constants/tasks.const';

import { SelectOption } from 'models/SelectOption';

import { useRoleOptions } from 'hooks/useRole';

import TaskTemplateModalFields from 'views/Pages/PracticeManagement/Episodes/TaskTemplateModalFields';
import { useEpisodeDurationSelectOptions } from 'views/Pages/PracticeManagement/Episodes/useEpisodeDuration';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  isInternalModalOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onEditCreateRole: (roleId: number) => void;
  episodeId: number;
}

interface CreateTaskTemplateFormFields {
  name: string;
  role: SelectOption<number> | null;
  monthNumber: SelectOption<number> | null;
  description: string | null;
  duplicateMonthly: boolean;
  isBillable: boolean | null;
}

const CreateTaskTemplateModal: FC<Props> = ({
  isOpen,
  isInternalModalOpen,
  episodeId,
  onCancel,
  onSubmit,
  onEditCreateRole
}) => {
  const {
    episodeManagementStore: { currentFilteredEpisodesMap, createTaskTemplate }
  } = useStores();
  const episode = currentFilteredEpisodesMap.get(episodeId);
  const roleOptions = useRoleOptions();
  const monthOptions = useEpisodeDurationSelectOptions(episode);
  const isLoading = useNetworkLoading(API_URLS.TASK_TEMPLATES);
  const methods = useForm<CreateTaskTemplateFormFields>();
  const { handleSubmit } = methods;

  const role = useMemo(
    () => roleOptions.find((option) => option.value === ALL_ROLES),
    [roleOptions]
  );

  const prepareBodyForRequest = (taskTemplateForm: CreateTaskTemplateFormFields) => {
    return {
      name: taskTemplateForm.name,
      roleId: taskTemplateForm.role.value,
      monthNumber: taskTemplateForm.monthNumber.value,
      description: taskTemplateForm.description,
      duplicateMonthly: taskTemplateForm.duplicateMonthly,
      isBillable: taskTemplateForm.isBillable,
      episodeId
    };
  };

  const handleSaveClicked = async (formData: CreateTaskTemplateFormFields) => {
    const body = prepareBodyForRequest(formData);
    await createTaskTemplate(body);
    showToast({ message: `New Task Created in "${episode.name}"` });
    onSubmit();
  };

  return (
    <FormModal
      defaultValues={{
        name: null,
        role,
        monthNumber: null,
        description: null,
        isBillable: episode?.isCareManagement ? false : null
      }}
      methods={methods}
      isOpen={isOpen}
      isInternalModalOpen={isInternalModalOpen}
      title="Add Task"
      confirmActions={[
        { onClick: handleSubmit(handleSaveClicked), text: 'Save', disabled: isLoading }
      ]}
      closeAction={{ onClick: onCancel, disabled: false }}
      secondaryAction={{
        name: 'duplicateMonthly',
        label: 'Duplicate this task for subsequent months',
        type: 'labeled-checkbox'
      }}
    >
      <TaskTemplateModalFields
        monthOptions={monthOptions}
        onEditCreateRole={onEditCreateRole}
        episode={episode}
      />
    </FormModal>
  );
};

export default CreateTaskTemplateModal;
