import { SymptomTicketUrgency, OperatorTicketUrgency } from 'models/OperatorTicket';
import { TicketClass, TicketStatus } from 'models/Ticket';
import { TicketTypeKind } from 'models/TicketTypes';

export enum NotificationType {
  Comment = 'comment',
  Mention = 'mention',
  Assign = 'assign'
}

export interface DoctorDetails {
  id: number;
  firstName: string;
  lastName: string;
}

export interface PatientDetails {
  id: number;
  firstName: string;
  lastName: string;
}

export interface NotificationDetails {
  patient: PatientDetails;
  changeCreatedBy: DoctorDetails | null;
}

export interface CommentDetails extends NotificationDetails {
  ticket: TicketDetails;
}

export interface MentionDetails extends NotificationDetails {
  ticket: TicketDetails;
}

export interface AssignDetails extends NotificationDetails {
  ticket: TicketDetails;
  targetDoctor: DoctorDetails | null;
}

export interface TicketDetails {
  id: number;
  ticketClass: TicketClass;
  displayName: string;
  dueDate?: Date;
  urgency: OperatorTicketUrgency | SymptomTicketUrgency; // depends on the ticket class
  ticketKind?: TicketTypeKind; // will be here if ticketClass is operator
  status: TicketStatus.OPEN | TicketStatus.RESOLVED | TicketStatus.DELETED;
}

export interface BaseClinicianNotification {
  id: number;
  doctorId: number;
  isDeleted: boolean;
  isRead: boolean;
  isSeen: boolean;
  createdAt: Date;
  deletedAt: Date;
}
export interface NotificationProps<NotificationType = ClinicianNotification> {
  notification: NotificationType;
  onClick: (notification: NotificationType) => void;
  onNotificationDelete: (notificationId: number) => void;
  onNotificationMarkAsRead: (notificationId: number) => void;
  onNotificationMarkAsUnread: (notificationId: number) => void;
}

export type ClinicianNotification =
  | ClinicianAssignNotification
  | ClinicianMentionNotification
  | ClinicianCommentNotification;

export interface ClinicianAssignNotification extends BaseClinicianNotification {
  type: NotificationType.Assign;
  notificationDetails: AssignDetails;
}

export interface ClinicianMentionNotification extends BaseClinicianNotification {
  type: NotificationType.Mention;
  notificationDetails: MentionDetails;
}

export interface ClinicianCommentNotification extends BaseClinicianNotification {
  type: NotificationType.Comment;
  notificationDetails: CommentDetails;
}
