import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Save
  | AnalyticEventAction.Cancel
  | AnalyticEventAction.SaveAndResolve
  | AnalyticEventAction.Open;

interface Properties {
  action: Action;
  virtual_page: 'call logger';
  value?: 'missing fields';
}

export const trackEditConnectedTicketsModalAnalyticsEvent = ({
  action,
  virtual_page,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.EditConnectedTicketsModal,
    properties: { action, virtual_page, ...optionalProps }
  });
};
