// @ts-strict-ignore
import { useCallback, useMemo } from 'react';

import { trackDropdownFilterUsageAnalyticsEvent } from 'analytics/events/filter-usage';
import debounce from 'debounce-promise';
import { ActionMeta } from 'react-select';
import { Col, Row } from 'reactstrap';

import { CliniciansSupportedFilters } from 'utils/filtersUtils';

import { useResetPageInUrl } from 'hooks/useNavPagination';

import SearchBar from 'views/Dashboard/SearchBar';
import { FilterName } from 'views/Filters/filters.types';
import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';

import { ValueOf } from 'views/Pages/CareManagement/CareManagementPage.utils';

import {
  ClinicianCredentialsFilterField,
  ClinicianUserStatusFilterField,
  UserTypeFilterField
} from './FilterFields';

import { useSetPersistFilters } from './useFilters';

import './PageFilters.scss';

interface PracticeUsersFiltersProps {
  filters: CliniciansSupportedFilters;
  onFilterChange: (newFilters: CliniciansSupportedFilters) => void;
  fetchDataAfterFilterChange: (newFilters: CliniciansSupportedFilters) => void;
}
export const CLINICIAN_LIST_FILTERS_LOCAL_STORAGE_KEY = '_cliniciansPageFilters';

export const PracticeUsersFilters = ({
  filters,
  onFilterChange,
  fetchDataAfterFilterChange
}: PracticeUsersFiltersProps) => {
  const resetPageInUrl = useResetPageInUrl();

  const debouncedFetchDataAfterFilterChange = useMemo(
    () =>
      debounce(
        (newFilters: CliniciansSupportedFilters) => fetchDataAfterFilterChange(newFilters),
        DEBOUNCE_DELAY
      ),
    // eslint-disable-next-line
    []
  );

  const handleFiltersChanged = useCallback(
    (newFilters: CliniciansSupportedFilters) => {
      resetPageInUrl();
      onFilterChange(newFilters);
      debouncedFetchDataAfterFilterChange(newFilters);
    },
    [resetPageInUrl, onFilterChange, debouncedFetchDataAfterFilterChange]
  );

  const { updateFiltersByKey } = useSetPersistFilters<CliniciansSupportedFilters>(
    filters,
    handleFiltersChanged,
    {
      localStorageKey: CLINICIAN_LIST_FILTERS_LOCAL_STORAGE_KEY,
      excludeFieldKeys: ['searchTerm']
    }
  );

  const onDropdownFilterChange = (
    values: ValueOf<CliniciansSupportedFilters>,
    filterKey: keyof CliniciansSupportedFilters,
    filterName: FilterName,
    actionMeta: ActionMeta<any>
  ) => {
    trackDropdownFilterUsageAnalyticsEvent(actionMeta, filterName);
    updateFiltersByKey(filterKey)(values);
  };

  return (
    <div className="page-filters">
      <Row>
        <Col xs={12} sm={3} lg={3}>
          <SearchBar
            className="search"
            placeholder="Filter by User, Mobile Phone or Email"
            searchValue={filters.searchTerm}
            onSearchChanged={updateFiltersByKey('searchTerm')}
          />
        </Col>
        <Col xs={12} sm={3} lg={3}>
          <ClinicianCredentialsFilterField
            onChange={(values, actionMeta) =>
              onDropdownFilterChange(values, 'credentialIds', FilterName.Credentials, actionMeta)
            }
            value={filters.credentialIds}
          />
        </Col>
        <Col xs={12} sm={3} lg={3}>
          <ClinicianUserStatusFilterField
            onChange={(values, actionMeta) =>
              onDropdownFilterChange(values, 'userStatuses', FilterName.UserStatus, actionMeta)
            }
            value={filters.userStatuses}
          />
        </Col>
        <Col xs={12} sm={3} lg={3}>
          <UserTypeFilterField
            value={filters.userTypes}
            onChange={(values, actionMeta) =>
              onDropdownFilterChange(values, 'userTypes', FilterName.UserType, actionMeta)
            }
          />
        </Col>
      </Row>
    </div>
  );
};
