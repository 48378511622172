import { FC, useState } from 'react';

import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { ActionMeta } from 'react-select/dist/declarations/src/types';

import { isSubStringCaseInsensitive } from 'utils/StringUtils';

import { Testable } from 'utils/TypeUtils';

import {
  AutocompleteProps,
  MultiAutocomplete
} from 'components/UIkit/atoms/Dropdown/Select/Autocomplete/Autocomplete';
import { CustomSelectCheckboxOption } from 'components/UIkit/atoms/Dropdown/Select/CustomOptions/CustomSelectCheckboxOption';
import {
  getCustomSelectCheckboxOptionChildren,
  getCustomSelectCheckboxOptionParent
} from 'components/UIkit/atoms/Dropdown/Select/CustomOptions/CustomSelectCheckboxOption.utils';
import {
  ISelectOption,
  SelectOnChangeHandler
} from 'components/UIkit/atoms/Dropdown/Select.shared';

interface Props extends Omit<AutocompleteProps, 'options' | 'value'>, Testable {
  options: ISelectOption<number>[];
  value: ISelectOption<number>[];
  onChange: SelectOnChangeHandler;
}

export const CheckboxesMultiAutocomplete: FC<Props> = ({
  label,
  options,
  value,
  onChange,
  testHook,
  ...restProps
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (
    value: ISelectOption<number>[] | null,
    actionMeta: ActionMeta<ISelectOption<any>>,
    eventKey: string | null
  ) => {
    const parentOption = value?.find((option) => option.isParent);

    if (!value || !Boolean(parentOption)) {
      onChange(value, actionMeta, eventKey);
      return;
    }

    if (!isSubStringCaseInsensitive(parentOption!.label.trim(), searchTerm)) {
      return;
    }

    const selectValueWithoutParentOption = value?.filter(
      (option) => option.value !== parentOption!.value
    );

    const {
      children: parentChildren,
      childrenIds: parentChildrenIds,
      isAllChildrenUnchecked
    } = getCustomSelectCheckboxOptionChildren(
      parentOption!.value as number,
      options,
      selectValueWithoutParentOption
    );

    if (isAllChildrenUnchecked) {
      const selectValueWithAllParentChildren = [
        ...selectValueWithoutParentOption,
        ...parentChildren.map(({ value, label }) => ({
          value,
          label
        }))
      ];

      onChange(
        selectValueWithAllParentChildren,
        { action: 'select-option', option: { ...parentOption! } },
        eventKey
      );
      return;
    }

    const selectValueWithoutParentChildren = selectValueWithoutParentOption.filter(
      (option) => !parentChildrenIds.includes(option.value)
    );

    onChange(
      selectValueWithoutParentChildren,
      { action: 'deselect-option', option: { ...parentOption! } },
      eventKey
    );
  };

  const filterOption = (option: FilterOptionOption<ISelectOption<number>>, searchText: string) => {
    if (!searchText) {
      return true;
    }

    const { isParent, value, label } = option.data;

    if (isParent) {
      if (isSubStringCaseInsensitive(label.trim(), searchText)) {
        return true;
      }

      const { children } = getCustomSelectCheckboxOptionChildren(value as number, options);

      if (children.length > 0) {
        return Boolean(
          children.some((childOption) =>
            isSubStringCaseInsensitive(childOption.label.trim(), searchText)
          )
        );
      }
    }

    if (isSubStringCaseInsensitive(label.trim(), searchText)) {
      return true;
    }

    const parentOption = getCustomSelectCheckboxOptionParent(value as number, options);

    if (parentOption && isSubStringCaseInsensitive(parentOption.label.trim(), searchText)) {
      return true;
    }

    return false;
  };

  return (
    <MultiAutocomplete
      sortAlphabetically={false}
      label={label}
      options={options}
      value={value}
      onChange={(
        value: ISelectOption<number>[] | null,
        actionMeta: ActionMeta<ISelectOption<any>>,
        eventKey: string | null
      ) => {
        setSearchTerm('');
        handleChange(value, actionMeta, eventKey);
      }}
      testHook={testHook}
      onInputChange={(inputValue) => setSearchTerm(inputValue)}
      inputValue={searchTerm}
      components={{ Option: CustomSelectCheckboxOption }}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      filterOption={filterOption}
      isOptionDisabled={(option) =>
        Boolean(option.isParent && !isSubStringCaseInsensitive(option!.label.trim(), searchTerm))
      }
      {...restProps}
    />
  );
};
