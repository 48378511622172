import debounce from 'debounce-promise';

import { Options } from 'react-select/dist/declarations/src/types';

import { useStores } from 'mobx/hooks/useStores';

import { CliniciansFetcher } from 'fetchers/CliniciansFetcher';

import { getClinicianFullName, getNameWithCredentials } from 'utils/ClinicianCredentialUtils';

import { SPECIAL_FILTERS } from 'views/Filters/filters.constants';

import { ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY } from 'components/UIkit/atoms/Dropdown/Select.shared';

interface UseCliniciansAsyncSelectReturnValue {
  loadOptions: (inputValue: string, callback: (options: Options<any>) => void) => void;
  defaultOptions: { label: string; value: number }[];
}

interface Config {
  withUnassignedOption?: boolean;
}

//for react-select components
export const useCliniciansAsyncSelect = (
  config: Config = { withUnassignedOption: false }
): UseCliniciansAsyncSelectReturnValue => {
  const { withUnassignedOption } = config;
  const { cliniciansStore, constantsStore } = useStores();

  const loadOptions = debounce(async (inputValue: string) => {
    const cliniciansResponse = await CliniciansFetcher.searchClinicians({ searchTerm: inputValue });
    const options = cliniciansResponse.map((searchedClinician) => {
      const { credentialId, id } = searchedClinician;
      const credentialText = constantsStore.getClinicianCredentialById(credentialId)?.text;
      const fullName = getClinicianFullName(searchedClinician);
      const label = getNameWithCredentials(fullName, credentialText || null);

      return { value: id, label };
    });

    if (withUnassignedOption) {
      options.unshift({ value: SPECIAL_FILTERS.UNASSIGNED, label: 'Unassigned Tickets' });
    }

    return options;
  }, ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY);

  const defaultOptions = cliniciansStore.defaultCliniciansForSelect.items.map(
    (searchedClinician) => {
      const { credentialId, id } = searchedClinician;
      const credentialText = constantsStore.getClinicianCredentialById(credentialId)?.text;
      const fullName = getClinicianFullName(searchedClinician);
      const label = getNameWithCredentials(fullName, credentialText || null);

      return { value: id, label };
    }
  );

  if (withUnassignedOption) {
    defaultOptions.unshift({ value: SPECIAL_FILTERS.UNASSIGNED, label: 'Unassigned Tickets' });
  }

  return { loadOptions, defaultOptions };
};
