// @ts-strict-ignore
import { InstitutionSettingsResponseType } from 'fetchers/responses/instituion-settings.response';

import { DxCode } from 'models/Conditions/SpecificCauseCondition';

import { SymptomTicketUrgency } from 'models/OperatorTicket';
import {
  OralReportResponse,
  OralReportVersion,
  ReportQuestionAnswer
} from 'models/OralReport/OralReport.shared';
import {
  OralAnswerValueV1,
  OralReportV1,
  OralQuestionTypeV1
} from 'models/OralReport/OralReportV1';
import {
  OralAnswerValueV2,
  OralReportV2,
  OralQuestionTypeV2
} from 'models/OralReport/OralReportV2';
import { IPatientContact } from 'models/Patient';
import { PatientEpisodeEndReason } from 'models/PatientEpisodeEndReason';
import PatientLocation from 'models/PatientLocation';
import QuestionnaireAnswer, {
  IReportAnswer,
  IReportedInputQuestion,
  IReportedYesNoQuestion,
  ReportSource,
  ReportType
} from 'models/QuestionnaireAnswer';
import SeverityReportedCause, {
  isNumericReportedCause,
  isSeverityReportedCause,
  NumericReportedCause,
  ReportCauseChange
} from 'models/ReportedCause';
import InstitutionSettings from 'models/Settings';

export interface IReportFromServer {
  id: number;
  type: ReportType;
  created_at: string;
  patient_report_index: number;
  answer: {
    causes?: ReportedCauseFromServer[];
    distressLevel?: number;
    callbackRequest?: ReportQuestionAnswer<boolean>;
    oral?: OralReportResponse;
    inputQuestions?: any[];
    yesNoQuestions?: any[];
    version?: 2;
  };
  ticket_id: number;
  source: ReportSource;
  urgency: SymptomTicketUrgency;
}

export interface BaseReportedCauseFromServer {
  causeId: number;
  change?: ReportCauseChange;
  dxCodes?: DxCode[];
}

export type ReportedCauseFromServer =
  | SeverityReportedCauseFromServer
  | NumericReportedCauseFromServer;

export interface SeverityReportedCauseFromServer extends BaseReportedCauseFromServer {
  severity: number;
}

export interface NumericReportedCauseFromServer extends BaseReportedCauseFromServer {
  value: number | 'iDontKnow';
}

export default class ParseServerResponseService {
  static parsePatientContact = (unparsedContact: any): IPatientContact => {
    if (!unparsedContact) {
      return;
    }
    return {
      id: unparsedContact.id,
      relationship: unparsedContact.relationship,
      name: unparsedContact.name,
      phoneNumber: unparsedContact.phone_number,
      phoneType: unparsedContact.phone_type,
      deletedAt: unparsedContact.deleted_at
    };
  };

  static parseLocation = (unparsedLocation: any) => {
    return new PatientLocation(unparsedLocation);
  };

  static parseReport = (unparsedReport: IReportFromServer) => {
    const questionnaire = new QuestionnaireAnswer();
    questionnaire.id = unparsedReport.id;
    questionnaire.type = unparsedReport.type;
    questionnaire.createdAt = unparsedReport.created_at;
    questionnaire.answer = ParseServerResponseService.parseReportAnswer(unparsedReport);
    questionnaire.patientReportIndex = unparsedReport.patient_report_index;
    questionnaire.ticketId = unparsedReport.ticket_id;
    questionnaire.source = unparsedReport.source;
    questionnaire.urgency = unparsedReport.urgency;
    return questionnaire;
  };

  static parseReportAnswer = (unparsedReport: IReportFromServer) => {
    const answer: IReportAnswer = {};
    switch (unparsedReport.type) {
      case ReportType.Oral:
        answer.distressLevel = unparsedReport.answer.distressLevel;
        answer.causes = ParseServerResponseService.parseReportCauses(unparsedReport);

        if (unparsedReport.answer.version === OralReportVersion.V2) {
          answer.oral = new OralReportV2(
            unparsedReport.answer.oral as OralReportResponse<OralAnswerValueV2, OralQuestionTypeV2>
          );
        } else {
          answer.oral = new OralReportV1(
            unparsedReport.answer.oral as OralReportResponse<OralAnswerValueV1, OralQuestionTypeV1>
          );
        }

        answer.callbackRequest = new ReportQuestionAnswer<boolean>(
          unparsedReport.answer.callbackRequest
        );
        answer.version = unparsedReport.answer.version;
        break;

      case ReportType.Distress:
      case ReportType.DrugSpecific:
        answer.distressLevel = unparsedReport.answer.distressLevel;
        answer.causes = ParseServerResponseService.parseReportCauses(unparsedReport);
        answer.callbackRequest = new ReportQuestionAnswer<boolean>(
          unparsedReport.answer.callbackRequest
        );
        break;

      case ReportType.COVID:
        answer.causes = ParseServerResponseService.parseReportCauses(unparsedReport);
        answer.inputQuestions = unparsedReport.answer.inputQuestions
          ? unparsedReport.answer.inputQuestions.map((unparsedQuestion: any) => {
              const reportedQuestion: IReportedInputQuestion = {
                id: unparsedQuestion.id,
                questionText: unparsedQuestion.questionText,
                inputType: unparsedQuestion.inputType,
                answer: unparsedQuestion.answer
              };
              return reportedQuestion;
            })
          : [];
        answer.yesNoQuestions = unparsedReport.answer.yesNoQuestions.map(
          (unparsedQuestion: any) => {
            const reportedQuestion: IReportedYesNoQuestion = {
              id: unparsedQuestion.id,
              questionText: unparsedQuestion.questionText,
              value: unparsedQuestion.value
            };
            return reportedQuestion;
          }
        );
        break;
    }
    return answer;
  };

  private static parseReportCauses(unparsedReport: IReportFromServer) {
    return unparsedReport.answer.causes.map((unparsedCause) => {
      const { causeId } = unparsedCause;

      let reportedCause: SeverityReportedCause | NumericReportedCause;

      if (isSeverityReportedCause(unparsedCause)) {
        reportedCause = new SeverityReportedCause(causeId, unparsedCause.severity);
      } else if (isNumericReportedCause(unparsedCause)) {
        reportedCause = new NumericReportedCause(causeId, unparsedCause.value);
      }

      reportedCause.change = unparsedCause.change;
      return reportedCause;
    });
  }

  static parseInstitutionSettings(unparsedInstitutionSettings: InstitutionSettingsResponseType) {
    const { features, ...settings } = unparsedInstitutionSettings;
    return {
      settings: new InstitutionSettings(settings),
      features: features
    };
  }

  static parsePatientEpisodeEndReason(unparsedReasons: any[]): PatientEpisodeEndReason[] {
    return unparsedReasons.map((unparsedReason) => new PatientEpisodeEndReason(unparsedReason));
  }
}
