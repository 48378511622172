// @ts-strict-ignore
import { makeObservable, observable } from 'mobx';

import ObjectCopier from 'utils/ObjectCopier';

import AlertTargetPopulation from './AlertTargetPopulation';
import ClinicianAlertCondition from './Conditions/ClinicianAlertCondition';
import SpecificCauseCondition from './Conditions/SpecificCauseCondition';
import { SymptomTicketUrgencyText } from './OperatorTicket';
import { ProtocolName } from './ScheduledProtocol';

export enum AlertUrgency {
  Alert = 'Alert',
  UrgentAlert = 'UrgentAlert',
  CriticalAlert = 'CriticalAlert'
}

export enum PopulationType {
  All = 'All',
  MyPatients = 'MyPatients',
  SpecificPatients = 'SpecificPatients',
  SpecificCareTeams = 'SpecificCareTeams',
  PatientsWithDiagnosis = 'PatientsWithDiagnosis',
  PatientsWithSpecificDrug = 'PatientsWithSpecificDrug',
  PatientsWithSpecificRegimen = 'PatientsWithSpecificRegimen'
}

export enum AlertAttention {
  NurseReview = 2,
  AttentionToday = 3,
  ImmediateAttention = 4
}

export enum ConditionType {
  AnyCause = 0,
  SpecificCause = 1,
  Distress = 2,
  InputQuestion = 3,
  YesNoQuestion = 4,
  Oral = 6
}

export enum ConditionTypeName {
  AnyCause = 'Any Issues at or Above Threshold',
  SpecificCause = 'Specific Issues at or Above Threshold',
  Distress = 'Distress Level'
}

export enum ConditionRule {
  Any = 1,
  All = 2
}

export default class ClinicianAlert extends ObjectCopier {
  @observable id: number;

  @observable population: AlertTargetPopulation = new AlertTargetPopulation();

  @observable urgency: AlertUrgency;

  @observable reportType: ProtocolName;

  @observable conditions: ClinicianAlertCondition[] = [];

  @observable conditionsRule: number;

  @observable doctorId: number = -1;

  constructor() {
    super();
    makeObservable(this);
  }

  getUrgencyName = () => {
    switch (this.urgency) {
      case AlertUrgency.Alert:
        return SymptomTicketUrgencyText.NurseReview;
      case AlertUrgency.UrgentAlert:
        return SymptomTicketUrgencyText.AttentionToday;
      case AlertUrgency.CriticalAlert:
        return SymptomTicketUrgencyText.ImmediateAttention;
      default:
        throw new Error(` no contactMethod ${this.urgency}`);
    }
  };
  getConditionsRuleString = (): string => {
    switch (this.conditionsRule) {
      case ConditionRule.Any:
        return 'Any';
      case ConditionRule.All:
        return 'All';
      default:
        throw new Error('No condition rule ' + this.conditionsRule);
    }
  };

  getConditionsString = (): string => {
    let finalStr = '';
    if (this.reportType === ProtocolName.covid && this.conditions.length > 1) {
      finalStr += `${this.getConditionsRuleString()} of: `;
    }
    const specificCausesObj: { [severity: number]: ClinicianAlertCondition[] } = {};
    this.conditions.forEach((condition) => {
      if (condition instanceof SpecificCauseCondition) {
        const arr = specificCausesObj[condition.getBottomThreshold()] || [];
        arr.push(condition);
        specificCausesObj[condition.getBottomThreshold()] = arr;
      }
    });
    this.conditions
      .filter((condition) => condition.type !== ConditionType.SpecificCause)
      .forEach((condition, i) => {
        finalStr = finalStr.concat(
          `${condition.getConditionsString()}${this.conditions.length - 1 !== i ? ', ' : ''}`
        );
      });

    Object.keys(specificCausesObj).forEach((key: any) => {
      const sameSeverityConditionsArray = specificCausesObj[key];
      if (sameSeverityConditionsArray.length === 1) {
        finalStr = finalStr.concat(sameSeverityConditionsArray[0].getConditionsString());
        return;
      }
      let conditionStr = '';
      sameSeverityConditionsArray.forEach((condition: SpecificCauseCondition, i: number) => {
        if (i === sameSeverityConditionsArray.length - 1) {
          conditionStr = conditionStr.slice(0, -2);
          finalStr = finalStr.concat(
            `${conditionStr} and/or ${condition.getCauseName()}: ${ClinicianAlertCondition.getSeverityString(
              condition.getBottomThreshold()
            )} severity or greater`
          );
          return;
        }
        conditionStr = conditionStr.concat(`${condition.getCauseName()}, `);
      });
    });
    return finalStr;
  };
}
