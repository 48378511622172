// @ts-strict-ignore
import { useState } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { useStores } from 'mobx/hooks/useStores';

import { getNumberAndCountryCodeFromPhone } from 'utils/PhoneUtils';

import Patient, {
  callbackRelationshipOptions,
  IPatientContact,
  RelationshipToPatientEnum
} from 'models/Patient';

import { RHFStyledInput } from 'views/Widgets/StyledInput';
import StyledPhoneInput from 'views/Widgets/StyledPhoneInput';
import { ISelectOption } from 'views/Widgets/StyledSelect';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

export interface IPatientContactFormFields {
  name: string;
  phone: string;
  countryCode: string;
  relationship: ISelectOption<RelationshipToPatientEnum>;
}

export const PatientContactModalForm = ({
  patient,
  contact,
  isOpen,
  onCancel,
  onSuccess,
  onDelete
}: {
  patient: Patient;
  contact: IPatientContact;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: (contact: IPatientContact) => void;
  onDelete: (contact: IPatientContact) => void;
}) => {
  const methods = useForm<IPatientContactFormFields>({
    mode: 'all'
  });

  const { handleSubmit, register, formState } = methods;

  const { errors } = formState;

  const { patientPageStore } = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveContact = async (values: IPatientContactFormFields) => {
    let res;
    setIsSubmitting(true);
    const contactInfo = {
      ...values,
      phoneNumber: values.countryCode + values.phone,
      relationship: values.relationship.value
    };

    try {
      if (contact.id) {
        res = await patientPageStore.updateContact(patient.id, contact.id, contactInfo);
      } else {
        res = await patientPageStore.createContact(patient.id, contactInfo);
      }
      onSuccess(res);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [initialCountryCode, initialPhoneNumber] = getNumberAndCountryCodeFromPhone(
    contact?.phoneNumber
  );

  const formDefaultValues = {
    name: contact?.name,
    phone: initialPhoneNumber?.toString() || '',
    countryCode: initialCountryCode,
    relationship: contact?.relationship
      ? { value: contact?.relationship, label: contact?.relationship }
      : null
  };

  return (
    <FormModal
      methods={methods}
      defaultValues={formDefaultValues}
      title={`${patient.fullName}: Callback Contacts`}
      isOpen={isOpen}
      confirmActions={[
        {
          onClick: handleSubmit(saveContact),
          text: isSubmitting ? 'Saving...' : 'Save',
          disabled: !formState.isValid
        }
      ]}
      closeAction={{ onClick: onCancel, disabled: false }}
      secondaryAction={{
        type: 'button',
        isVisible: Boolean(contact?.id),
        onClick: () => onDelete(contact),
        disabled: isSubmitting,
        text: 'Delete'
      }}
    >
      <StyledFormContainer mb={40}>
        <Row>
          <Col xs={12} sm={12} lg={12}>
            <RHFStyledInput
              isRequired
              name="name"
              label="Callback Name"
              register={register}
              error={Boolean(errors.name)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={6}>
            <FormAutocomplete
              label="Relationship to Patient"
              isRequired
              name="relationship"
              options={callbackRelationshipOptions}
            />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <StyledPhoneInput
              disabled={false}
              label="Phone Number"
              error={Boolean(errors.phone || errors.countryCode)}
            />
          </Col>
        </Row>
      </StyledFormContainer>
    </FormModal>
  );
};

const StyledFormContainer = styled(Box)`
  .styled-input {
    margin-bottom: 1.25rem;
  }
`;
