import { FC, MutableRefObject } from 'react';

import { observer } from 'mobx-react';

import { ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { TooltipController, TooltipTrigger } from 'components/Tooltip/Tooltip.types';

interface Props {
  onProtocolSaved: (duration: HybridProtocolOption) => void;
  tooltipReference?: MutableRefObject<Element>;
  tooltipController?: TooltipController;
}

export enum HybridProtocolOption {
  Weekly,
  Monthly
}
export const HybridProtocolDropdown: FC<Props> = observer(
  ({ onProtocolSaved, tooltipReference, tooltipController }) => {
    const getOptions = (): ITooltipOption[] => [
      {
        text: 'Weekly (or Post-Treatment)',
        onClick: () => onProtocolSaved(HybridProtocolOption.Weekly)
      },
      {
        text: 'Monthly (or Post-Treatment)',
        onClick: () => onProtocolSaved(HybridProtocolOption.Monthly)
      }
    ];

    return (
      <Tooltip
        trigger={TooltipTrigger.CLICK}
        reference={tooltipReference}
        controller={tooltipController}
        maxWidth="none"
      >
        <TooltipSelect options={getOptions()} />
      </Tooltip>
    );
  }
);
