import { useEffect } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackShowMoreAnalyticsEvent } from 'analytics/events/show-more';
import { useHistory, useLocation } from 'react-router-dom';

import { removeUrlParam, useGetUrlParam } from 'utils/urlUtils';

import { useToggle } from 'hooks/useToggle';

import { HIGHLIGHT_TICKET_QUERY_PARAM } from 'views/Patient/PatientMain/PatientMainView.constants';
import { WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

export const useTicketToggle = (
  ticketId: number,
  isTask: boolean = false,
  ticketSectionCurrentPage: number,
  isTicketDescriptionVerticalOverflowActive: boolean = false,
  tab?: WorkQueueTabName
) => {
  const highlightId = Number(useGetUrlParam(HIGHLIGHT_TICKET_QUERY_PARAM));
  const history = useHistory();
  const location = useLocation();
  const {
    isOpen: isExpanded,
    toggle,
    setIsOpen
  } = useToggle(false, (isOpen) => {
    trackShowMoreAnalyticsEvent({
      action: isOpen ? AnalyticEventAction.Show : AnalyticEventAction.Hide,
      value: isTask ? 'task' : 'ticket',
      page_number: ticketSectionCurrentPage + 1,
      type: isTicketDescriptionVerticalOverflowActive ? 'long ticket description' : null,
      tab
    });
    removeUrlParam(history, location.search, HIGHLIGHT_TICKET_QUERY_PARAM);
  });

  useEffect(
    function expandOnHighlight() {
      if (highlightId === ticketId) {
        setIsOpen(true);
      }
    },
    [highlightId, setIsOpen, ticketId]
  );
  return { isExpanded, toggle };
};
