import classNames from 'classnames';
import { Cell, ColumnInstance } from 'react-table';

import './TableCell.scss';

type TableColumnInstance<D extends {}> = ColumnInstance<D> & {
  removeVerticalPadding?: boolean;
};

type TableCellProps<D extends {}> = Cell<D> & {
  isExpanded?: boolean;
  column: TableColumnInstance<D>;
};

const TableCell = <D extends {}>({ isExpanded, ...cell }: TableCellProps<D>) => {
  return (
    <div
      {...cell.getCellProps()}
      className={classNames('cell', {
        'text-truncate': !isExpanded,
        'remove-vertical-padding': cell.column.removeVerticalPadding
      })}
    >
      {cell.render('Cell')}
    </div>
  );
};

export default TableCell;
