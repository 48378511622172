// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';

import { ActionMeta } from 'react-select';

import Patient from 'models/Patient';

import PatientLocation from 'models/PatientLocation';

import { SelectFieldCustomComponents } from 'views/Widgets/StyledSelect';

import { FormAutocomplete, FormAutocompleteInlineLabel } from 'components/UIkit/atoms/Dropdown';

import { useTicketLocationsOptions } from './OperatorTicketsInfoFormCommon';

interface IProps {
  fieldName: string;
  patient: Patient;
  customComponents?: SelectFieldCustomComponents;
  isRequired?: boolean;
  isInline?: boolean;
  onChange?: (value: any, action: ActionMeta<any>, eventKey: string | null) => void;
}

// TODO: move this component from tickets
const PatientLocationFormField: FC<IProps> = ({
  fieldName,
  patient,
  isRequired = false,
  isInline = false,
  onChange
}) => {
  const locationOptions = useTicketLocationsOptions();

  // FormAutocompleteInlineLabel will removed after ticket type re-design
  const Autocomplete = isInline ? FormAutocompleteInlineLabel : FormAutocomplete;
  return (
    <Autocomplete
      isClearable={false}
      label="Location"
      isRequired={isRequired}
      name={fieldName}
      options={locationOptions}
      getOptionLabel={(option: { value: PatientLocation; label: string }) =>
        option.value.id === patient.locationId ? `Primary (${option.label})` : option.label
      }
      getOptionValue={(option: any) => option.value.id}
      defaultValue={
        patient?.location ? { label: patient.location.name, value: patient.location } : null
      }
      onChange={onChange}
    />
  );
};

export default observer(PatientLocationFormField);
