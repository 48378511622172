import { FC } from 'react';

import { styled } from '@mui/material/styles';

import { StyledHtmlContentContainer } from 'utils/html.styles';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import Icon from 'components/Icons/Icon';
import { HtmlContent } from 'components/Ticket/TicketRow/HtmlContent';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  title: string;
  type: PathwayQuestionTypes.MULTIPLE | PathwayQuestionTypes.SINGLE;
}

export const PathwayQuestionOption: FC<Props> = ({ title, type }) => (
  <StyledHtmlContentContainer flex={1} display="flex">
    {type === PathwayQuestionTypes.MULTIPLE && <StyledCheckboxIcon />}
    {type === PathwayQuestionTypes.SINGLE && <StyledRadioIcon />}

    <StyledText variant="form-text" ml={12}>
      <StyledHtmlContent>{title}</StyledHtmlContent>
    </StyledText>
  </StyledHtmlContentContainer>
);

const StyledCheckboxIcon = styled(Icon.Checkbox)`
  flex-shrink: 0;
`;

const StyledText = styled(Text)`
  display: grid;
`;

const StyledRadioIcon = styled(Icon.RadioButton)`
  flex-shrink: 0;
`;

const StyledHtmlContent = styled(HtmlContent)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
