import { useStores } from 'mobx/hooks/useStores';

import Patient from 'models/Patient';

export const usePatientEmrLink = (patient: Patient) => {
  const { settingsStore } = useStores();
  const { oncoEmrBaseUrl } = settingsStore.institutionSettings;
  const { emrPatientId } = patient;

  if (!emrPatientId) {
    return null;
  }

  return `${oncoEmrBaseUrl}/nav/summary?PID=${emrPatientId}`;
};
