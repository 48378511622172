// @ts-strict-ignore
import { convertFromRaw, Editor, EditorState } from 'draft-js';

import { ErrorWithUi } from 'errors';

import { handleError } from 'services/errorHandlingService';

import Call from 'models/Call';

interface ICallViewProps {
  call: Call | Partial<Call>;
}

const DeprecatedGeneratedCallView = (props: ICallViewProps) => {
  const { note } = props.call;
  try {
    return (
      <Editor
        readOnly
        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(note)))}
        onChange={() => null}
      />
    );
  } catch (e) {
    // since this is a synchronous action - handleError should be called here directly (so it won't stop the render function)
    const error = new ErrorWithUi('Error parsing old call notes', `${e.message}: ${note}`);
    handleError(error);
    return props.call.note as any;
  }
};

export default DeprecatedGeneratedCallView;
