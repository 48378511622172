// @ts-strict-ignore
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
  AuthType,
  init,
  LiveboardEmbed,
  logout,
  Action,
  RuntimeFilterOp
} from '@thoughtspot/visual-embed-sdk';
import classNames from 'classnames';

import { useStores } from 'mobx/hooks/useStores';

import AnalyticsFetcher from 'fetchers/AnalyticsFetcher';

import { THOUGHT_SPOT_HOST, TS_LIVE_BOARDS_IDS } from 'constants/config';
import { FEATURES } from 'constants/features';

import ToggleBar, { TabOption } from 'views/Widgets/ToggleBar';

import './AnalyticsDashboardPage.scss';

async function fetchTsToken() {
  const response = await AnalyticsFetcher.fetchThoughtSpotToken();
  return response.token;
}

function initializePage(token: string, username: string) {
  init({
    thoughtSpotHost: THOUGHT_SPOT_HOST,
    authType: AuthType.AuthServer,
    getAuthToken: fetchTsToken,
    username,
    autoLogin: true,
    disableLoginRedirect: true
  });
}

enum TabOptions {
  PracticeHealth,
  ProductivityAnalytics,
  Tickets,
  CareTimerCalls,
  PathwayAnalysis,
  Patients,
  TaskManagement,
  CMEnrollment,
  CMBilling
}

const tabs: { value: TabOptions; label: string; feature: FEATURES }[] = [
  {
    value: TabOptions.PracticeHealth,
    label: 'Practice Health',
    feature: FEATURES.IP_PRACTICE_HEALTH
  },
  {
    value: TabOptions.ProductivityAnalytics,
    label: 'Productivity Analytics',
    feature: FEATURES.IP_PRODUCTIVITY_ANALYTICS
  },
  { value: TabOptions.Tickets, label: 'Tickets', feature: FEATURES.IP_TICKETS },
  {
    value: TabOptions.CareTimerCalls,
    label: 'Care Timer & Calls',
    feature: FEATURES.IP_CARE_TIMER_AND_CALLS
  },
  {
    value: TabOptions.PathwayAnalysis,
    label: 'Pathway Analysis',
    feature: FEATURES.IP_PATHWAYS_ANALYSIS
  },
  { value: TabOptions.Patients, label: 'ePRO Enrollment', feature: FEATURES.IP_EPRO_ENROLLMENT },
  {
    value: TabOptions.TaskManagement,
    label: 'Task Management',
    feature: FEATURES.IP_TASK_MANAGEMENT
  },
  {
    value: TabOptions.CMEnrollment,
    label: 'Care Management - Enrollment',
    feature: FEATURES.IP_CARE_MANAGEMENT
  },
  {
    value: TabOptions.CMBilling,
    label: 'Care Management - Billing',
    feature: FEATURES.IP_CARE_MANAGEMENT
  }
];

const tabToLiveBoardIdMap = {
  [TabOptions.PracticeHealth]: TS_LIVE_BOARDS_IDS.practiceHealth,
  [TabOptions.ProductivityAnalytics]: TS_LIVE_BOARDS_IDS.productivityAnalytics,
  [TabOptions.CareTimerCalls]: TS_LIVE_BOARDS_IDS.careTimerCalls,
  [TabOptions.Tickets]: TS_LIVE_BOARDS_IDS.ticketManagement,
  [TabOptions.Patients]: TS_LIVE_BOARDS_IDS.patients,
  [TabOptions.PathwayAnalysis]: TS_LIVE_BOARDS_IDS.pathwayAnalysis,
  [TabOptions.TaskManagement]: TS_LIVE_BOARDS_IDS.taskManagementAnalytics,
  [TabOptions.CMEnrollment]: TS_LIVE_BOARDS_IDS.careManagementEnrollment,
  [TabOptions.CMBilling]: TS_LIVE_BOARDS_IDS.careManagementBilling
};

const tabToElementIdMap = {
  [TabOptions.PracticeHealth]: 'practiceHealth',
  [TabOptions.ProductivityAnalytics]: 'productivityAnalytics',
  [TabOptions.CareTimerCalls]: 'careTimerCalls',
  [TabOptions.Tickets]: 'ticketManagement',
  [TabOptions.Patients]: 'patients',
  [TabOptions.PathwayAnalysis]: 'pathwayAnalysis',
  [TabOptions.TaskManagement]: 'taskManagementAnalytics',
  [TabOptions.CMEnrollment]: 'careManagementEnrollment',
  [TabOptions.CMBilling]: 'careManagementBilling'
};

const AnalyticsDashboardPage: FC = () => {
  const { userStore, settingsStore, departmentsStore } = useStores();
  const [selectedTab, setSelectedTab] = useState(getTabOptions()[0].value);
  const [tsInitialized, setTsInitialized] = useState(false);
  const liveBoardsRefs = useRef(new Map<string, LiveboardEmbed>());

  useLayoutEffect(
    function logoutFromActiveSession() {
      async function logoutHandler() {
        await logout(true);
      }
      logoutHandler();
    },
    [userStore.currentDoctor.email]
  );

  useEffect(
    function pageMountEffect() {
      async function fetchToken() {
        const token = await fetchTsToken();
        initializePage(token, userStore.currentDoctor.email);
        setTsInitialized(true);
      }

      fetchToken();
    },
    [userStore.currentDoctor.email]
  );

  useEffect(
    function onTabChange() {
      if (!tsInitialized) {
        return;
      }

      const embedId = tabToElementIdMap[selectedTab];
      const boardEmbedRef = liveBoardsRefs.current.get(embedId);

      if (!boardEmbedRef) {
        const visibleActions: Action[] = [
          Action.DownloadAsCsv,
          Action.DownloadAsXlsx,
          Action.Download,
          Action.AxisMenuSort,
          Action.AxisMenuTimeBucket,
          Action.AxisMenuFilter
        ];

        if (
          settingsStore.hasFeature(FEATURES.ANALYTICS_DRILL_DOWN_ACTION) &&
          selectedTab === TabOptions.PathwayAnalysis
        ) {
          visibleActions.push(Action.DrillDown);
        }

        liveBoardsRefs.current.set(
          embedId,
          new LiveboardEmbed(`#${embedId}`, {
            liveboardV2: true,
            disabledActionReason: '',
            liveboardId: tabToLiveBoardIdMap[selectedTab],
            fullHeight: true,
            visibleActions,
            runtimeFilters: [
              {
                columnName: 'institution_id',
                operator: RuntimeFilterOp.EQ,
                values: [departmentsStore.rootDepartmentId]
              }
            ]
          })
        );
        liveBoardsRefs.current.get(embedId).render();
      }
    },
    [selectedTab, tsInitialized, departmentsStore.rootDepartmentId, settingsStore]
  );

  function getTabOptions(): TabOption<TabOptions>[] {
    const tabOptions: TabOption<TabOptions>[] = [];

    tabs.forEach((tab) => {
      if (settingsStore.hasFeature(tab.feature)) {
        tabOptions.push({ value: tab.value, label: tab.label });
      }
    });

    return tabOptions;
  }

  return (
    <div className="animated fadeIn dashboard-view wide-view analytics-dashboard-page">
      <h1 className="title">Practice Analytics</h1>
      <ToggleBar
        options={getTabOptions()}
        selected={selectedTab}
        onOptionSelected={(option) => setSelectedTab(option)}
        id="live-boards-toggle"
        className="live-boards-toggle"
        size="small"
        isSquared
      />

      {Object.values(TabOptions).map((tabOption: TabOptions) => (
        <div
          key={tabOption}
          id={tabToElementIdMap[tabOption]}
          className={classNames('tab-container', {
            active: selectedTab === tabOption
          })}
        />
      ))}
    </div>
  );
};

export default AnalyticsDashboardPage;
