// @ts-strict-ignore

import { FC, useEffect, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { PermissionService } from 'services/PermissionService';

import ClinicianAlert from 'models/ClinicianAlert';

import { CreateSymptomsAlertModal } from 'views/Modals/SymptomsAlertModals/CreateSymptomsAlertModal';
import { EditSymptomsAlertModal } from 'views/Modals/SymptomsAlertModals/EditSymptomsAlertModal';

import { CollapsibleSection } from 'views/Widgets/CollapsibleSection';

import Icon from 'components/Icons/Icon';
import { OutlinedIconButton } from 'components/UIkit/atoms/Button';

import AlertsTable from './AlertsTable';

import './ClinicianAlerts.scss';

const ClinicianAlerts: FC = () => {
  const [editedAlert, setEditedAlert] = useState<ClinicianAlert | null>(null);
  const [isCreateAlertModalOpen, setIsCreateAlertModalOpen] = useState(false);
  const [isEditAlertModalOpen, setIsEditAlertModalOpen] = useState(false);

  const { alertsStore, constantsStore, departmentsStore, userStore } = useStores();

  useEffect(
    function init() {
      alertsStore.getClinicianAlerts();
      constantsStore.getYesNoQuestions();
    },
    [alertsStore, constantsStore, departmentsStore]
  );

  const handleGlobalAlertClicked = (alert: ClinicianAlert) => {
    if (hasPermission) {
      setIsEditAlertModalOpen(true);
      trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.EditRule });
      setEditedAlert(alert);
    }
  };

  const handleAddGlobalAlertClicked = () => {
    setIsCreateAlertModalOpen(true);
    trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.AddRule });
  };

  const doctor = userStore.currentDoctor;
  const hasPermission = PermissionService.isManager(doctor);

  const dataLoading =
    !alertsStore.alerts ||
    !constantsStore.yesNoQuestions ||
    !constantsStore.symptomsMap.size ||
    !departmentsStore.root;

  if (dataLoading) {
    return null;
  }

  return (
    <div className="h-100 d-flex flex-column">
      <div className="alerts-page limited-width animated fadeIn">
        <CreateSymptomsAlertModal
          isOpen={isCreateAlertModalOpen}
          closeModal={() => {
            setIsCreateAlertModalOpen(false);
          }}
        />

        <EditSymptomsAlertModal
          isOpen={isEditAlertModalOpen}
          closeModal={() => {
            setIsEditAlertModalOpen(false);
          }}
          alert={editedAlert}
          onDeleteAlert={() =>
            trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.Delete })
          }
        />

        <div className="header">
          <h2 className="title">Triage Rules</h2>
          <h4 className="subtitle">
            All tickets and Symptom Reports that are created manually via operator are automatically
            marked as urgent.
          </h4>
        </div>

        <div className="alerts-section">
          <CollapsibleSection
            name="Shared Triage Rules"
            trigger={<h4>{departmentsStore.root.name} - Shared Triage Rules</h4>}
            trailing={
              hasPermission ? (
                <OutlinedIconButton
                  icon={<Icon.Plus />}
                  onClick={handleAddGlobalAlertClicked}
                  variant="secondary"
                  testHook="add-global-alert-button"
                >
                  Add Rule
                </OutlinedIconButton>
              ) : undefined
            }
          >
            <AlertsTable
              textPlaceHolder="No Shared Rules"
              onAlertClicked={handleGlobalAlertClicked}
              alerts={alertsStore.globalAlerts}
            />
          </CollapsibleSection>
        </div>
      </div>
    </div>
  );
};

export const ClinicianAlertsPage = observer(ClinicianAlerts);

export default ClinicianAlertsPage;
