import { FC, useState } from 'react';

import { components, MultiValueRemoveProps } from 'react-select';

import { ClearCmTableSelectionPopup } from 'views/Modals/ClearCmTableSelectionPopup';

export interface CmMultiValueRemoveProps extends MultiValueRemoveProps {
  selectedTableRowsCount: number;
}

export const CmFilterMultiValueRemove: FC<CmMultiValueRemoveProps> = (props) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);

  return (
    <div onMouseDown={(event) => event.stopPropagation()}>
      <ClearCmTableSelectionPopup
        isOpen={showWarning}
        onClose={() => setShowWarning(false)}
        onSave={props.innerProps.onClick as any}
        selectedTableRowsCount={props.selectedTableRowsCount}
      />

      <components.MultiValueRemove
        {...props}
        innerProps={{ ...props.innerProps, onClick: () => setShowWarning(true) }}
      />
    </div>
  );
};
