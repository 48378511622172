import { observable, makeObservable } from 'mobx';

import { TicketClinician } from 'models/Ticket';

export default class TicketAssign {
  @observable
  assignDate: Date;

  @observable
  doctor: TicketClinician;

  @observable
  isMain: boolean;

  constructor(assignDate: Date, assignee: TicketClinician, isMain: boolean) {
    makeObservable(this);
    this.assignDate = assignDate;
    this.doctor = assignee;
    this.isMain = isMain;
  }
}
