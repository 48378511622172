import { FC, FocusEvent, Fragment } from 'react';

import { Box, Collapse, styled } from '@mui/material';

import {
  LightweightPathwayServerStructure,
  LightweightPathwayQuestionServerStructure
} from 'fetchers/responses/pathways.response';

import {
  LightweightPathwayDependentQuestionServerStructure,
  LightweightPathwayQuestionAnswer,
  QuestionActionMeta
} from 'components/LightweightPathway/LightweightPathway.types';
import { isDependentQuestionVisible } from 'components/LightweightPathway/LightweightPathway.utils';
import { LightweightPathwayQuestionComponent } from 'components/LightweightPathway/LightweightPathwayQuestion';

interface Props {
  questions: LightweightPathwayQuestionServerStructure[];
  pathway: Omit<LightweightPathwayServerStructure, 'questions'>;
  answers: LightweightPathwayQuestionAnswer[];
  handleAnswerChanged: (
    newValue: LightweightPathwayQuestionAnswer,
    actionMeta: QuestionActionMeta
  ) => void;
  handleTextQuestionBlur?: (
    event: FocusEvent<HTMLInputElement>,
    currentValue: string,
    valueAfterFocus: string,
    question:
      | LightweightPathwayQuestionServerStructure
      | LightweightPathwayDependentQuestionServerStructure
  ) => void;
  pathwayIndex: number;
}

export const LightweightPathwayQuestions: FC<Props> = ({
  questions,
  pathway,
  answers = [],
  handleAnswerChanged,
  handleTextQuestionBlur,
  pathwayIndex
}) => {
  return (
    <StyledQuestionsContainer>
      {questions.map((question, questionIndex) => {
        const lightweightPathwayQuestionAnswer = answers.find(
          (questionAnswer) => questionAnswer.id === question.id
        );

        return (
          <Fragment key={question.id}>
            <LightweightPathwayQuestionComponent
              question={question}
              pathway={pathway}
              pathwayIndex={pathwayIndex}
              index={questionIndex}
              answer={lightweightPathwayQuestionAnswer}
              handleAnswerChanged={handleAnswerChanged}
              handleTextQuestionBlur={handleTextQuestionBlur}
            />

            {question?.dependentQuestions?.map((dependentQuestion, dependentQuestionIndex) => {
              const dependentQuestionAnswer = answers.find(
                (questionAnswer) => questionAnswer.id === dependentQuestion.id
              );

              const isVisible = isDependentQuestionVisible(dependentQuestion, answers);

              return (
                <Collapse key={dependentQuestion.id} in={isVisible}>
                  <LightweightPathwayQuestionComponent
                    parentQuestionId={question.id}
                    question={dependentQuestion}
                    pathway={pathway}
                    pathwayIndex={pathwayIndex}
                    index={dependentQuestionIndex}
                    answer={dependentQuestionAnswer}
                    handleAnswerChanged={handleAnswerChanged}
                    handleTextQuestionBlur={handleTextQuestionBlur}
                  />
                </Collapse>
              );
            })}
          </Fragment>
        );
      })}
    </StyledQuestionsContainer>
  );
};

const StyledQuestionsContainer = styled(Box)`
  > div.lightweight-pathway-question:first-of-type {
    margin-top: 0;
  }
`;
