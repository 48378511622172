import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { MultipleSelectFilterFieldProps } from 'views/Filters/FilterFields';
import StyledSummaryMultiSelect from 'views/Widgets/StyledSummaryMultiSelect';

const CmEnrolledProvidersFilter = observer(
  ({ value, onChange }: MultipleSelectFilterFieldProps<string>) => {
    const { careManagementReportStore } = useStores();
    return (
      <StyledSummaryMultiSelect
        label="Providers"
        testHook="providers-for-enrolled"
        options={careManagementReportStore.enrolledProvidersForCmSelect}
        value={value}
        onChange={onChange}
      />
    );
  }
);

export default CmEnrolledProvidersFilter;
