import { MINUTE_IN_MILLISECONDS, SECOND_IN_MILLISECONDS } from 'utils/DateUtils';

import { FEATURES } from 'constants/features';

export const DEFAULT_WQ_RECORDS_PER_PAGE = 15;
export const DEFAULT_WQ_ASSIGNED_TO_ME_RECORDS_PER_PAGE = 200;

const DEFAULT_INTERVALS = {
  TICKET: 75 * SECOND_IN_MILLISECONDS,
  TASK: 75 * SECOND_IN_MILLISECONDS,
  SINGLE_PATIENT: 90 * SECOND_IN_MILLISECONDS,
  PATIENTS: 90 * SECOND_IN_MILLISECONDS,
  CARE_TIMER: MINUTE_IN_MILLISECONDS,
  NOTIFICATIONS: MINUTE_IN_MILLISECONDS
};

export enum TicketSortOrder {
  OldestFirst = 'oldestFirst',
  NewestFirst = 'newestFirst'
}

export default class InstitutionSettings {
  saveCallSendToEmrByDefault = true;
  providerFieldEditable = false;
  ticketSortOrder = TicketSortOrder.NewestFirst;
  ticketRetrievalInterval = DEFAULT_INTERVALS.TICKET;
  taskRetrievalInterval = DEFAULT_INTERVALS.TASK;
  singlePatientRetrievalInterval = DEFAULT_INTERVALS.SINGLE_PATIENT;
  allPatientRetrievalInterval = DEFAULT_INTERVALS.PATIENTS;
  careTimerUpdateIntervalMillis = DEFAULT_INTERVALS.CARE_TIMER;
  notificationsInterval = DEFAULT_INTERVALS.NOTIFICATIONS;
  careTimerIdleTimeoutMillis = MINUTE_IN_MILLISECONDS * 30;
  careTimerIdleWarningDurationMillis = SECOND_IN_MILLISECONDS * 10;
  isTest = false;
  features: FEATURES[] = [];
  wqRecordsPerPage: number = DEFAULT_WQ_RECORDS_PER_PAGE;
  wqAssignedToMeRecordsPerPage: number = DEFAULT_WQ_ASSIGNED_TO_ME_RECORDS_PER_PAGE;
  oncoEmrBaseUrl: string | null = null;

  constructor(settings?: any) {
    // settings object are stored and parsed as flat objects
    Object.assign(this, settings);
  }
}
