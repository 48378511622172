// @ts-strict-ignore
import { makeObservable, observable } from 'mobx';

export default class PatientTag {
  id: number;

  @observable
  name: string;

  @observable
  bgColor: string;

  @observable
  isDeleted?: boolean;

  institutionId?: number;

  constructor(tag?: any) {
    makeObservable(this);
    Object.assign(this, tag);
  }
}
