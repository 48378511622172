import { FC } from 'react';

import { v4 as uuid } from 'uuid';

import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { OutlinedButton } from 'components/UIkit/atoms/Button';

interface Props {
  onClick: (questionUuid: string) => void;
  isCategoryButton?: boolean;
}

export const PathwayAddQuestionButton: FC<Props> = ({ onClick, isCategoryButton = false }) => {
  const { selectPathwayEditorBlock } = usePathwayEditorSelection();

  const onInsertQuestion = () => {
    const questionUuid = uuid();
    onClick(questionUuid);
    selectPathwayEditorBlock(questionUuid);
  };

  return (
    <OutlinedButton
      mr={12}
      onClick={onInsertQuestion}
      id={`pathway-${isCategoryButton ? 'category-' : ''}add-question-button`}
    >
      Add Question
    </OutlinedButton>
  );
};
