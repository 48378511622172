import { FC, ReactNode } from 'react';

import { css, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ButtonProps, StyledFilled } from 'components/UIkit/atoms/Button/StyledButtons';

interface Props extends ButtonProps {
  icon: ReactNode;
  fullWidth?: boolean;
}

export const FilledIconButton: FC<Props> = ({
  onClick,
  disabled,
  icon,
  fullWidth = false,
  testHook,
  children
}) => (
  <Container onClick={onClick} disabled={disabled} fullWidth={fullWidth} data-test-hook={testHook}>
    {icon}
    <Typography variant="button-medium">{children}</Typography>
  </Container>
);

const Container = styled(StyledFilled)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacing(8)};
    }
  `
);
