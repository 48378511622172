// @ts-strict-ignore
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import sizeMe from 'react-sizeme';

import { defaultTagSort } from 'mobx/stores';

import PatientTag from 'models/PatientTag';

import Chip from 'components/Chips/Chip';
import { SimpleTooltip } from 'components/Tooltip';

import './PatientDynamicTags.scss';

interface PatientTagsCellProps {
  tags: PatientTag[];
  size: any;
}
const MORE_TAGS_WIDTH = 65;
const TAG_MARGIN_AND_BORDER = 10;

const MoreTagsTag: FC<{ remainingTags: number }> = ({ remainingTags }) => {
  return (
    <div className="more-tags" style={{ width: MORE_TAGS_WIDTH }}>{`${remainingTags} More...`}</div>
  );
};

const PatientDynamicTags: FC<PatientTagsCellProps> = ({ tags, size }) => {
  const containerRef = useRef();
  const sortedTags = tags.sort(defaultTagSort);
  const tagRefs = useRef<Record<string, HTMLSpanElement>>({});
  const [widthsMap, setWidthsMap] = useState(new Map<number, number>());
  const [tagIds, setTagIds] = useState([]);

  useEffect(
    function measureChips() {
      const newMap = new Map();
      let savedTagIds: number[] = [];
      sortedTags.forEach((tag, i) => {
        if (tagRefs.current[i]) {
          newMap.set(i, tagRefs.current[i].getBoundingClientRect().width);
          savedTagIds.push(tag.id);
        }
      });
      if (newMap.size) {
        setWidthsMap(newMap);
        setTagIds(savedTagIds);
      }
    },
    [sortedTags, widthsMap.size]
  );
  const { chips, truncated }: any = useMemo(() => {
    const tagsChanged = () => {
      return (
        sortedTags.length !== widthsMap.size ||
        tagIds.some((id) => !sortedTags.some((tag) => tag.id === id))
      );
    };

    let chips: any = [];
    const width = size.width;

    if (widthsMap.size === 0 || tagsChanged()) {
      // render all tags to get size
      widthsMap.clear();
      return {
        chips: sortedTags.map((tag, i) => (
          <Chip.Item
            borderless={Boolean(tag.bgColor)}
            ref={(ref: HTMLSpanElement) => (tagRefs.current[i] = ref)}
            key={tag.id}
            bgColorHex={tag.bgColor}
          >
            {tag.name}
          </Chip.Item>
        )),
        truncated: false
      };
    }

    if (sortedTags.length === 1) {
      const chip = (
        <Chip.Item
          className="truncate-chip-item"
          key={sortedTags[0].id}
          borderless={Boolean(sortedTags[0].bgColor)}
          bgColorHex={sortedTags[0].bgColor}
        >
          {sortedTags[0].name}
        </Chip.Item>
      );

      return { chips: chip, truncated: widthsMap.get(0) >= width };
    }

    if (widthsMap.get(0) + TAG_MARGIN_AND_BORDER + MORE_TAGS_WIDTH > width) {
      // If the first chip already takes up all the room, shorten it to make space for the "more tags" text.
      chips.push(
        <div
          key={sortedTags[0].id}
          className="d-flex flex-nowrap mr-2"
          style={{ width: width - MORE_TAGS_WIDTH }}
        >
          <Chip.Item
            className="truncate-chip-item"
            borderless={Boolean(sortedTags[0].bgColor)}
            bgColorHex={sortedTags[0].bgColor}
          >
            {sortedTags[0].name.slice(0, -3) + '...'}
          </Chip.Item>
        </div>
      );
      chips.push(<MoreTagsTag key="more-tags" remainingTags={sortedTags.length - 1} />);
      return { chips: chips, truncated: true };
    }

    let availableSpace = width;
    for (let i = 0; i < sortedTags.length; i++) {
      const tag = sortedTags[i];
      availableSpace -= widthsMap.get(i);
      if (availableSpace - TAG_MARGIN_AND_BORDER > MORE_TAGS_WIDTH) {
        availableSpace -= TAG_MARGIN_AND_BORDER;
        chips.push(
          <Chip.Item borderless={Boolean(tag.bgColor)} key={tag.id} bgColorHex={tag.bgColor}>
            {tag.name}
          </Chip.Item>
        );
      } else {
        // if last element, will not need margin and so might still have room
        if (availableSpace > 0 && i === sortedTags.length - 1) {
          chips.push(
            <Chip.Item borderless={Boolean(tag.bgColor)} key={tag.id} bgColorHex={tag.bgColor}>
              {tag.name}
            </Chip.Item>
          );
        }
        break;
      }
    }

    const truncated = sortedTags.length > chips.length;
    if (truncated) {
      chips.push(<MoreTagsTag key="more-tags" remainingTags={sortedTags.length - chips.length} />);
    }
    return { chips, truncated };
  }, [widthsMap, size.width, sortedTags, tagIds]);

  if (widthsMap.size === 0) {
    return <Chip.List classes="tags-list">{chips}</Chip.List>;
  }

  const tooltipContent = tags ? (
    <Chip.List classes="flex-column p-4">
      {tags.map((tag) => (
        <Chip.Item
          className="tooltip-tag"
          borderless={Boolean(tag.bgColor)}
          key={tag.id}
          bgColorHex={tag.bgColor}
        >
          {tag.name}
        </Chip.Item>
      ))}
    </Chip.List>
  ) : null;

  return (
    <div className="patient-tags" ref={containerRef}>
      <SimpleTooltip title={tooltipContent} disabled={!truncated}>
        <Chip.List classes="tags-list">{chips}</Chip.List>
      </SimpleTooltip>
    </div>
  );
};

export default sizeMe({ noPlaceholder: true })(PatientDynamicTags);
