import React, { FC } from 'react';

import { Box, css, Grid, styled, Typography } from '@mui/material';

import classNames from 'classnames';

import {
  CallsAndCallbackPreferenceTableCellData,
  ReportTableIcon
} from 'views/Patient/PatientMain/ReportsTable/ReportTable.model';

import Icon from 'components/Icons/Icon';
import { SimpleTooltip } from 'components/Tooltip';

interface Props {
  callsAndCallbackColumn: CallsAndCallbackPreferenceTableCellData;
}

const getIcon = (icon: ReportTableIcon) => {
  switch (icon) {
    case ReportTableIcon.phoneCall:
      return <StyledLandPhoneIcon width="20" height="20" />;
    case ReportTableIcon.check:
      return (
        <div className="black">
          <Icon.CheckMark />
        </div>
      );
    case ReportTableIcon.x:
      return (
        <div className="black">
          <Icon.XThin />
        </div>
      );
    default:
      return 'icon';
  }
};

export const CallsAndCallbackPreferenceColumn: FC<Props> = ({ callsAndCallbackColumn }) => {
  if (!callsAndCallbackColumn) {
    return <td className={classNames('td-severity innerCell position-relative')} />;
  }

  const { callbackPreference, drafts, calls } = callsAndCallbackColumn;

  const content = (
    <div className={classNames('content', `color-${callsAndCallbackColumn?.color}`)}>
      {typeof callsAndCallbackColumn?.iconContent === 'number' &&
        getIcon(callsAndCallbackColumn?.iconContent)}
    </div>
  );

  return (
    <td className={classNames('td-severity innerCell position-relative')}>
      <SimpleTooltip
        placement="top"
        title={
          <Box p={20}>
            {callbackPreference && (
              <StyledContainer container flexDirection="column">
                <Typography variant="body1">Callback Preference</Typography>
                <Typography variant="body2">{callbackPreference}</Typography>
              </StyledContainer>
            )}

            {drafts?.length > 0 && (
              <StyledContainer container flexDirection="column">
                <Typography variant="body1">Call Drafts</Typography>

                {drafts.map((draft, index) => (
                  <StyledList key={`${draft}_${index}`}>
                    <Typography variant="body2" component="li">
                      {draft}
                    </Typography>
                  </StyledList>
                ))}
              </StyledContainer>
            )}

            {calls?.length > 0 && (
              <StyledContainer container flexDirection="column">
                <Typography variant="body1">Calls Logged</Typography>

                {calls.map((call, index) => (
                  <StyledList key={`${call}_${index}`}>
                    <Typography variant="body2" component="li">
                      {call}
                    </Typography>
                  </StyledList>
                ))}
              </StyledContainer>
            )}
          </Box>
        }
      >
        {content}
      </SimpleTooltip>
    </td>
  );
};

const StyledLandPhoneIcon = styled(Icon.LandPhone)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
  `
);

const StyledContainer = styled(Grid)(
  ({ theme }) => css`
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(20)};
    }
  `
);

const StyledList = styled('ul')(
  ({ theme }) => css`
    padding-left: ${theme.spacing(20)};
    margin: 0;
  `
);
