import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { UserParser } from 'parsers/UserParser';

import Doctor from 'models/Doctor';

export interface IDoctorSignUpArgs {
  firstName: string;
  lastName: string;
  credentialId: number;
  email: string;
  phone: string;
  password: string;
  selectedTeams: number[];
  role: string;
}

export interface IDoctorUpdateArgs {
  doctorId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  teams: number[];
}

export interface DeactivateResponse {
  clinician: Doctor;
  dismissedTicketsIds: number[];
}

export interface SearchedClinician {
  id: number;
  firstName: string;
  lastName: string;
  credentialId: number;
}

export interface SearchCliniciansQuery {
  searchTerm?: string;
}

const httpService = new HttpService('clinician', true);

export class CliniciansFetcher {
  static async fetchClinicianById(clinicianId: number): Promise<Doctor> {
    return await httpService.get({
      url: API_URLS.GET_CLINICIAN_BY_ID(clinicianId),
      transformResponse: UserParser.parseDoctor
    });
  }

  static async fetchCurrentClinicianProfile(): Promise<Doctor> {
    return await httpService.get({
      url: API_URLS.GET_OWN_PROFILE,
      transformResponse: UserParser.parseDoctor
    });
  }

  static async searchClinicians(query: SearchCliniciansQuery = {}): Promise<SearchedClinician[]> {
    return await httpService.get({
      url: API_URLS.SEARCH_CLINICIANS,
      query
    });
  }

  static async inviteClinician(data: IDoctorSignUpArgs): Promise<Doctor> {
    return await httpService.post({
      url: API_URLS.SIGN_UP,
      data
    });
  }

  static async deactivateClinician(clinicianId: number): Promise<DeactivateResponse> {
    return await httpService.put({
      url: API_URLS.DEACTIVATE_CLINICIAN(clinicianId)
    });
  }

  static async activateClinician(clinicianId: number): Promise<Doctor> {
    return await httpService.put({
      url: API_URLS.ACTIVATE_CLINICIAN(clinicianId)
    });
  }

  static async updatePersonalDetails(data: IDoctorUpdateArgs) {
    return await httpService.put<Doctor>({
      url: API_URLS.UPDATE_DOCTOR_DETAILS(data.doctorId),
      data
    });
  }

  static async changePassword(id: number, oldPassword: string, newPassword: string): Promise<void> {
    return await httpService.put({
      url: API_URLS.UPDATE_CLINICIAN_PASSWORD(id),
      data: { oldPassword, newPassword }
    });
  }
}
