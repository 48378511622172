import { FC, useRef, useState } from 'react';

import { Box, css, styled, Typography } from '@mui/material';

import { sortBy } from 'lodash/fp';

import { useHistory } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { PATHWAY_BUILDER_ID_QUERY_PARAM } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.constants';

import {
  defaultPathwayQuestionOptionSuggestedPathway,
  PATHWAY_QUESTION_OPTION_SUGGESTED_SELECT,
  PATHWAY_QUESTION_OPTION_SUGGESTED_URGENCY_SELECT
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.constants';

import {
  CategoryQuestionOption,
  QuestionOptionSuggestedPathway,
  SuggestedPathwayUrgency
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import { getPathwayQuestionOptionSelectChevronIconColor } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';
import { PathwayQuestionOptionSuggestedUrgency } from 'views/Pages/PathwayBuilder/PathwayEditorView/SuggestedPathway/PathwayQuestionOptionSuggestedUrgency';

import Icon from 'components/Icons/Icon';
import { DividerPlacement, ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';

interface Props {
  option: CategoryQuestionOption;
  selectOption: (optionId: string, optionSuggestedPathway: QuestionOptionSuggestedPathway) => void;
}

export const PathwayQuestionOptionSuggestedSelect: FC<Props> = ({ option, selectOption }) => {
  const { pathwayBuilderStore } = useStores();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const currentPathwayId = searchParams.get(PATHWAY_BUILDER_ID_QUERY_PARAM);

  const { optionsForPathwayNameSelect } = pathwayBuilderStore;
  const [isPathwaysMenuOpen, setIsPathwaysMenuOpen] = useState(false);
  const [isUrgencyMenuOpen, setIsUrgencyMenuOpen] = useState(false);
  const [clickedPathway, setClickedPathway] = useState<{ value: string; label: string } | null>(
    null
  );
  const selectRef = useRef(null);
  const hasSuggestedPathway = Boolean(option.suggestedPathway.pathway.value);

  const chevronIconColor = getPathwayQuestionOptionSelectChevronIconColor(
    isPathwaysMenuOpen || isUrgencyMenuOpen,
    hasSuggestedPathway
  );

  const selectLabel = (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      onClick={() => setIsPathwaysMenuOpen((prevState) => !prevState)}
      ref={selectRef}
      maxWidth="256px"
    >
      {hasSuggestedPathway ? (
        <PathwayQuestionOptionSuggestedUrgency
          urgency={option.suggestedPathway.urgency}
          pathwayName={option.suggestedPathway.pathway.label}
        />
      ) : (
        <StyledTypography variant="body1" mr={8} isOpen={isPathwaysMenuOpen || isUrgencyMenuOpen}>
          Suggested Pathway
        </StyledTypography>
      )}

      <StyledChevronIcon up={isPathwaysMenuOpen || isUrgencyMenuOpen} color={chevronIconColor} />
    </Box>
  );

  const filteredPathwayOptions = optionsForPathwayNameSelect.filter(
    (pathway) => pathway.value !== currentPathwayId
  );
  const sortedPathwaysOptions = sortBy('label', filteredPathwayOptions);

  const pathwayMenuOptions: ITooltipOption[] = sortedPathwaysOptions.map((pathway) => ({
    text: pathway.label,
    onClick: () => {
      setClickedPathway({ value: pathway.value!, label: pathway.label });
      setIsUrgencyMenuOpen(true);
      setIsPathwaysMenuOpen(false);
    },
    isSelected: option.suggestedPathway.pathway.value === pathway.value
  }));

  pathwayMenuOptions.unshift({
    text: 'No Suggestion',
    withDivider: true,
    dividerPlacement: DividerPlacement.Bottom,
    onClick: () => {
      setIsPathwaysMenuOpen(false);
      selectOption(option.optionId, defaultPathwayQuestionOptionSuggestedPathway);
    },
    isSelected: !hasSuggestedPathway
  });

  const urgencyMenuOptions: ITooltipOption[] = [
    {
      text: <PathwayQuestionOptionSuggestedUrgency urgency={SuggestedPathwayUrgency.Low} />,
      groupHeader: 'Suggestion Urgency',
      onClick: () => {
        selectOption(option.optionId, {
          pathway: { label: clickedPathway!.label, value: clickedPathway!.value },
          urgency: SuggestedPathwayUrgency.Low
        });
        setIsUrgencyMenuOpen(false);
      }
    },
    {
      text: <PathwayQuestionOptionSuggestedUrgency urgency={SuggestedPathwayUrgency.High} />,
      onClick: () => {
        selectOption(option.optionId, {
          pathway: { label: clickedPathway!.label, value: clickedPathway!.value },
          urgency: SuggestedPathwayUrgency.High
        });
        setIsUrgencyMenuOpen(false);
      }
    }
  ];

  const handleUrgencyMenuClickOutside = () => {
    setIsUrgencyMenuOpen(false);
    setClickedPathway(null);
  };

  return (
    <>
      <Tooltip
        controller={{
          visible: isPathwaysMenuOpen,
          onClickOutside: () => setIsPathwaysMenuOpen(false)
        }}
        label={selectLabel}
        trigger={TooltipTrigger.CLICK}
        maxWidth={300}
      >
        <TooltipSelect options={pathwayMenuOptions} id={PATHWAY_QUESTION_OPTION_SUGGESTED_SELECT} />
      </Tooltip>

      <Tooltip
        controller={{
          visible: isUrgencyMenuOpen,
          onClickOutside: handleUrgencyMenuClickOutside
        }}
        trigger={TooltipTrigger.CLICK}
        maxWidth={300}
        reference={selectRef}
      >
        <StyledTooltipSelect
          options={urgencyMenuOptions}
          id={PATHWAY_QUESTION_OPTION_SUGGESTED_URGENCY_SELECT}
        />
      </Tooltip>
    </>
  );
};

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isOpen'
})<{ isOpen: boolean }>(
  ({ theme, isOpen }) => css`
    color: ${isOpen ? theme.palette.primary.dark : theme.palette.primary.main};
  `
);

const StyledTooltipSelect = styled(TooltipSelect)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(12)};
  `
);

const StyledChevronIcon = styled(Icon.Chevron, {
  shouldForwardProp: (prop) => prop !== 'color'
})<{ color: string }>(
  ({ theme, color }) => css`
    color: ${color};
    margin-left: ${theme.spacing(4)};
  `
);
