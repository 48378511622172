import { MouseEvent } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import { useHistory } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import Call from 'models/Call';
import Patient from 'models/Patient';
import Ticket from 'models/Ticket';

import { OutlinedButton } from 'components/UIkit/atoms/Button';

import CallRow from './CallRow';

import HorizontalLoader from './HorizontalLoader';

interface PatientCallsListParams {
  calls: Call[];
  patient: Patient;
  isLoadingCalls?: boolean;
  hideActionButtons?: boolean;
}

// Notice! This is not a component, it must be a function because of how CardStack works
export const PatientCallsList = ({
  calls,
  patient,
  isLoadingCalls = false,
  hideActionButtons
}: PatientCallsListParams) => {
  const { callLoggingStore } = useStores();

  const history = useHistory();

  if (isLoadingCalls) {
    return (
      <span className="call-row" style={{ position: 'relative' }}>
        <HorizontalLoader />
      </span>
    );
  }

  if (!calls || calls.length === 0) return null;

  return calls.map((call) => {
    let shouldShowAction: boolean =
      !hideActionButtons && (Boolean(!call.isSentToEMR && call.ticketIds) || call.isDraft);
    let action = <UpdateCallTicketsAction call={call} />;

    if (call.isDraft) {
      action = (
        <div className="resume-call-button">
          <OutlinedButton
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              trackActionButtonAnalyticsEvent({
                action: AnalyticEventAction.ResumeCall,
                ticket_ids: call.ticketIdsArray
              });
              callLoggingStore.setSelectedResumedDraft(call);
              callLoggingStore.startCallSession({
                forTicketIds: Array.from(call.ticketIds)
              });

              if (!history.location.pathname.includes('patient')) {
                history.push(`/patient/${patient.id}`);
              }
            }}
          >
            Resume Call
          </OutlinedButton>
        </div>
      );
    }

    return (
      <CallRow
        key={call.id}
        patient={patient}
        call={call}
        action={shouldShowAction ? action : null}
      />
    );
  });
};

interface TicketPatientCallsListParams extends PatientCallsListParams {
  ticket: Ticket;
}

// Notice! This is not a component, it must be a function because of how CardStack works
// It also must return this empty div in order for the CardStack to work properly
export const TicketPatientCallsList = ({
  ticket,
  calls,
  patient,
  isLoadingCalls = false,
  hideActionButtons
}: TicketPatientCallsListParams) => {
  if (ticket.callsAndDraftsCount) {
    if (calls.length === 0) {
      return <div />; // This is important for CardStack to work properly (it expects a component
    }
    return PatientCallsList({
      calls,
      isLoadingCalls,
      patient,
      hideActionButtons
    });
  }
  return null;
};

const UpdateCallTicketsAction = ({ call }: { call: Call }) => {
  const { callsStore } = useStores();

  return (
    <div className="edit-call-tickets-button">
      <OutlinedButton
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          trackActionButtonAnalyticsEvent({
            action: AnalyticEventAction.EditConnectedTickets,
            ticket_ids: call.ticketIdsArray
          });
          event.stopPropagation();
          callsStore.setEditedCall(call);
        }}
      >
        Edit Connected Tickets
      </OutlinedButton>
    </div>
  );
};
