import { FC, MouseEvent } from 'react';

import { css } from '@mui/material';

import { styled } from '@mui/material/styles';

import classNames from 'classnames';

import { useToggle } from 'hooks/useToggle';

import { ISelectOption } from 'views/Widgets/StyledSelect';

import Icon from 'components/Icons/Icon';

import StyledDropdown from './StyledDropdown';

import './EditableDropdown.scss';

export const ADD_OPTION_VALUE: number = -1;

interface Props {
  options: ISelectOption<any>[];
  onChange: (value: any) => void;
  value?: any;
  addText: string;
  placeholder?: string;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  inlineLabel?: boolean;
  disabled?: boolean;
  className?: string;
  addOptionValue?: number;
  editText?: string;
  smallHeadHeight?: boolean;
  openUp?: boolean;
}

export const EditableDropdown: FC<Props> = ({
  options,
  onChange,
  value,
  addText,
  editText = 'EDIT',
  placeholder,
  label,
  error,
  errorMessage,
  inlineLabel,
  disabled,
  className,
  addOptionValue = ADD_OPTION_VALUE,
  smallHeadHeight,
  openUp
}: Props) => {
  const { isOpen, toggle } = useToggle(false);
  const dropdownClassnames = classNames(className, {
    'quick-addition-dropdown-container': smallHeadHeight
  });
  const isEditOptionClicked = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    return target.classList.contains('edit-button');
  };

  const handleDropDownChange = (value: number, clickEvent: MouseEvent<HTMLDivElement>) => {
    if (!isEditOptionClicked(clickEvent)) {
      onChange(value);
    }
  };

  const getOptions = () => {
    return [
      ...options.map((item: any) => {
        return {
          ...item,
          builder: () => {
            return (
              <div className="dropdown-option-item">
                <div className="text" title={item.label}>
                  {item.label}
                </div>
                {item.onEditClick && (
                  <div>
                    <div
                      id={`item_${item.value}`}
                      onClick={item.onEditClick}
                      className="edit-button"
                    >
                      {item.editText || editText}
                    </div>
                  </div>
                )}
              </div>
            );
          }
        };
      }),
      {
        value: addOptionValue,
        label: addText,
        disableSelect: true,
        builder: () => (
          <AddEditOption className="add-edit-option">
            <StyledCirclePlusIcon />
            <span>{addText}</span>
          </AddEditOption>
        )
      }
    ];
  };
  return (
    <>
      <div className="styled-select">
        <div className={dropdownClassnames}>
          <StyledDropdown
            isOpen={isOpen}
            onClick={toggle}
            noSelectedValueText={placeholder}
            onChange={handleDropDownChange}
            value={value}
            error={error}
            errorMessage={errorMessage}
            disabled={disabled}
            options={getOptions()}
            inlineLabel={inlineLabel}
            label={label}
            smallHeadHeight={smallHeadHeight}
            openUp={openUp}
          />
        </div>
      </div>
    </>
  );
};

const AddEditOption = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.palette.primary.main};
  `
);

const StyledCirclePlusIcon = styled(Icon.CirclePlus)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(8)};
    color: ${theme.palette.primary.main};
  `
);

export default EditableDropdown;
