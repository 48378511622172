import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Search
  | AnalyticEventAction.Select
  | AnalyticEventAction.Clear
  | AnalyticEventAction.Cancel
  | AnalyticEventAction.AdvancedSearch
  | AnalyticEventAction.ClearAll
  | AnalyticEventAction.RefineWithAdvancedSearch;

export type SearchHeaderUsageValue =
  | 'searched by EMPTY'
  | 'searched by MRN'
  | 'searched by NAME'
  | 'searched by DOB'
  | 'recently viewed'
  | 'search result'
  | 'searched by NAME and DOB'
  | 'searched by NAME and MRN'
  | 'searched by DOB and MRN'
  | 'searched by NAME and DOB and MRN';

export type SearchHeaderUsageMetric =
  | 'MRN'
  | 'NAME'
  | 'DOB'
  | 'NAME and DOB'
  | 'NAME and MRN'
  | 'DOB and MRN'
  | 'NAME and DOB and MRN';

interface Properties {
  action: Action;
  type?: 'regular search' | 'advanced search';
  value?: SearchHeaderUsageValue;
  source?: 'keyboard' | null;
}

export const trackSearchHeaderUsageAnalyticsEvent = ({ action, ...optionalProps }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.SearchHeaderUsage,
    properties: { action, ...optionalProps }
  });
};
