// @ts-strict-ignore
import { FC, ReactNode } from 'react';

import i18n from 'i18n';
import { observer } from 'mobx-react';

import { useProvider } from 'mobx/hooks/useProvidersOptions';
import { useStores } from 'mobx/hooks/useStores';
import { defaultTagSort } from 'mobx/stores';

import { CmStatusEnum } from 'fetchers/responses/care-management.response';

import { formatDate } from 'utils/DateUtils';
import { getFormattedPhoneNumber } from 'utils/PhoneUtils';

import { FEATURES } from 'constants/features';

import Patient from 'models/Patient';

import Chip from 'components/Chips/Chip';
import Icon from 'components/Icons/Icon';
import { Tooltip } from 'components/Tooltip';

import PropertyItem from './PropertyItem';

import './PatientInfoBar.scss';

interface PatientInfoBarProps {
  patient: Patient;
}

const NOT_ENROLLED = 'Not Enrolled';

const PatientInfoBar: FC<PatientInfoBarProps> = ({ patient }) => {
  const { fullName, mrn = '', dateOfBirth, phone, location, providerId, regimenTitle } = patient;
  const { constantsStore, careManagementReportStore, settingsStore, locationsStore } = useStores();
  const dob = dateOfBirth ? formatDate(dateOfBirth, 'MMMM Do, YYYY', true) : '';
  const sex = patient.sexText;

  const locationName = location
    ? location.name || locationsStore.getLocationById(location.id)?.name || ''
    : '';

  const provider = useProvider(providerId);
  const providerLabel = provider ? provider.label : '';

  const remoteMonitoringStatus = patient.remoteMonitoringStatus;
  const formattedRegimenTitle = regimenTitle || NOT_ENROLLED;
  const patientTags = constantsStore.getTagsByIds(patient.tags || []).sort(defaultTagSort);

  const getInfoTooltip = (labelText: string) => {
    return (
      <div className="d-flex flex-row justify-content-center">
        {labelText}
        <Tooltip
          placement="top"
          label={
            <div className="d-flex align-items-center h-100 ml-1">
              <Icon.Info />
            </div>
          }
        >
          <div className="p-3 d-flex flex-column">
            <span className="mb-2">{patient.cmStatus.reasonText}</span>
            {patient.cmStatus.reasonDetails && <span>{patient.cmStatus.reasonDetails}</span>}
          </div>
        </Tooltip>
      </div>
    );
  };
  const getCmStatus = (): ReactNode => {
    switch (patient.cmStatus?.status) {
      case CmStatusEnum.Active:
        return `Enrolled ${
          patient.cmStatus.isExpired ? '(Expired) ' : ''
        }- ${careManagementReportStore.episodeName(patient.cmStatus)}`;
      case CmStatusEnum.Ended:
        const label = `Ended ${
          patient.cmStatus.isEndedManually ? '(Manually) ' : ''
        }- ${careManagementReportStore.episodeName(patient.cmStatus)}`;
        return patient.cmStatus.isEndedManually ? getInfoTooltip(label) : label;
      case CmStatusEnum.EligibleForCcm:
        return `Eligible - CCM`;
      case CmStatusEnum.EligibleForPcm:
        return `Eligible - PCM`;
      case CmStatusEnum.None:
        return NOT_ENROLLED;
      case CmStatusEnum.Ineligible:
        return getInfoTooltip(`Ineligible`);
      case CmStatusEnum.OptedOut:
        return getInfoTooltip(`Opted Out`);
      case CmStatusEnum.InvitedPcm:
        return 'Invited - PCM';
      case CmStatusEnum.InvitedCcm:
        return 'Invited - CCM';
      case CmStatusEnum.InvitationFailedPcm:
        return 'Invitation Failed - PCM';
      case CmStatusEnum.InvitationFailedCcm:
        return 'Invitation Failed - CCM';
      case CmStatusEnum.LearnMorePcm:
        return 'Learn More - PCM';
      case CmStatusEnum.LearnMoreCcm:
        return 'Learn More - CCM';
      case CmStatusEnum.ConsentedPcm:
        return 'Consented - PCM';
      case CmStatusEnum.ConsentedCcm:
        return 'Consented - CCM';
      default:
        return NOT_ENROLLED;
    }
  };

  const careManagementStatus = settingsStore.hasFeature(FEATURES.CARE_MANAGEMENT)
    ? getCmStatus()
    : NOT_ENROLLED;

  return (
    <div className="patient-info-bar">
      <div className="name-container">
        <h4 className="full-name">{fullName}</h4>
      </div>

      {patient.tags && (
        <Chip.List>
          {patientTags.map((tag) => {
            return (
              <Chip.Item
                key={tag.id}
                className="mb-2"
                bgColorHex={tag.bgColor}
                borderless={Boolean(tag.bgColor)}
              >
                {tag.name}
              </Chip.Item>
            );
          })}
        </Chip.List>
      )}
      <div className="info-container">
        <div className="info-column">
          <PropertyItem label="MRN" value={mrn} />
          <PropertyItem label="DoB" value={dob} />
          <PropertyItem label="Sex" value={sex} />
          <PropertyItem label="Phone" value={getFormattedPhoneNumber(phone)} />
          <PropertyItem label="Language" value={i18n.t(patient.language) as string} />
        </div>
        <div className="info-column">
          <PropertyItem label="Provider" value={providerLabel} />
          <PropertyItem label="Location" value={locationName} />
          <PropertyItem label="Remote Monitoring Status" value={remoteMonitoringStatus} />
          <PropertyItem label="Oral Chemo Regimen" value={formattedRegimenTitle} />
          <PropertyItem label="Care Management Status" value={careManagementStatus} />
        </div>
      </div>
    </div>
  );
};

export default observer(PatientInfoBar);
