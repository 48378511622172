// @ts-strict-ignore
import { isEmpty } from 'lodash/fp';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';

import { computedFn } from 'mobx-utils';

import { DataMap } from 'mobx/stores/DataMap';

import { LocationsFetcher } from 'fetchers/LocationsFetcher';
import { Region } from 'fetchers/responses/regions.response';

import { extractIdsFromItems } from 'utils/helpers';

import PatientLocation from 'models/PatientLocation';

import { ISelectOption } from 'components/UIkit/atoms/Dropdown/Select.shared';

import { RootStore } from './rootStore';

export const SELECTED_LOCATIONS_STORAGE_KEY = '_selectedLocations';

export default class LocationsStore {
  @observable
  locations: DataMap<PatientLocation> = new DataMap<PatientLocation>();

  @observable
  regions: DataMap<Region> = new DataMap<Region>();

  @observable
  selectedLocationIds: Set<number> = new Set();

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  async fetchPatientLocations() {
    const fetchedLocations = await LocationsFetcher.getPatientLocations();
    runInAction(() => {
      this.locations.setItems(fetchedLocations);
      this.setSelectedLocationsAfterFetch();
    });
  }

  @computed
  get visibleRegions() {
    const visibleRegions = this.regions.items.filter(
      (region) => region.visible && !isEmpty(region.locations)
    );

    return visibleRegions.map((region) => {
      const visibleLocations = region.locations.filter((location) => location.visible);

      return {
        ...region,
        locations: visibleLocations
      };
    });
  }

  @action
  async updateLocation(updatedLocation: PatientLocation) {
    await LocationsFetcher.updateLocation(updatedLocation);
    runInAction(() => {
      this.locations.set(updatedLocation.id, updatedLocation);
    });
  }

  setSelectedLocationsAfterFetch = () => {
    const localStorageLocationIds = JSON.parse(
      localStorage.getItem(SELECTED_LOCATIONS_STORAGE_KEY)
    );
    if (localStorageLocationIds && localStorageLocationIds.length > 0) {
      this.setSelectedLocationIds(localStorageLocationIds);
    } else {
      this.setSelectedLocationIds(extractIdsFromItems(this.locations.items));
    }
  };

  @action
  setSelectedLocationIds(selectedLocationIds: number[]) {
    this.selectedLocationIds = new Set(selectedLocationIds);
  }

  @action
  addSelectedLocationId(selectedLocationId: number) {
    this.selectedLocationIds.add(selectedLocationId);
  }

  @action
  removeSelectedLocationId(selectedLocationId: number) {
    this.selectedLocationIds.delete(selectedLocationId);
  }

  @computed
  get visibleLocations() {
    return this.locations.items.filter((location) => location.visible);
  }

  getLocationById = computedFn((locationId: string | number): PatientLocation => {
    return this.locations.get(locationId);
  });

  @computed
  get locationsForSelect(): ISelectOption<PatientLocation>[] {
    return this.visibleLocations.map((location: PatientLocation) => ({
      value: location,
      label: location.name
    }));
  }

  @action
  async fetchRegions() {
    const regions = await LocationsFetcher.getRegions();

    runInAction(() => {
      this.regions.setItems(regions);
    });
  }

  @computed
  get regionsForSelect() {
    const regionsForSelect: ISelectOption<number>[] = [];

    this.visibleRegions.forEach((region) => {
      regionsForSelect.push({
        value: region.id,
        label: region.name,
        isParent: true
      });

      region.locations.forEach((location) => {
        regionsForSelect.push({
          value: location.id,
          label: location.name
        });
      });
    });

    return regionsForSelect;
  }
}
