import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import {
  getCurrentSortByUrl,
  getURLSearchParams,
  isSortEquals,
  setBooleanUrlParam,
  UrlSortParams
} from 'utils/urlUtils';

import { PAGE_PARAM, SORT_ASC_PARAM, SORT_BY_PARAM } from 'constants/urlParams.const';

function useSetSortInUrl() {
  const history = useHistory();

  return useCallback(
    (sortParams: UrlSortParams | null) => {
      const currentSort = getCurrentSortByUrl();

      if (isSortEquals(sortParams, currentSort)) {
        return;
      }

      const queryParams = getURLSearchParams();
      queryParams.delete(PAGE_PARAM); // reset pagination if exist

      if (sortParams === null) {
        queryParams.delete(SORT_BY_PARAM);
        queryParams.delete(SORT_ASC_PARAM);
      } else {
        queryParams.set(SORT_BY_PARAM, sortParams.sortBy);
        setBooleanUrlParam(queryParams, SORT_ASC_PARAM, sortParams.sortAsc);
      }
      history.replace({
        pathname: history.location.pathname,
        search: queryParams.toString()
      });
    },
    [history]
  );
}

export function useNavSort() {
  const setSortInUrl = useSetSortInUrl();

  return {
    setSortInUrl
  };
}
