import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import { Modal } from 'reactstrap';

import { Testable } from 'utils/TypeUtils';

import { FilledButton, OutlinedButton } from 'components/UIkit/atoms/Button';

import BaseModalHeader from './BaseModalHeader';

import './BaseModal.scss';

interface IDefaultModalProps extends Testable {
  children: ReactNode;
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  centerTitle?: boolean;
  className?: string;
  positiveDisabled?: boolean;
  positiveBtnText: string;
  negativeBtnText?: string;
  actionBtnText?: string;
  onPositiveAction: () => void;
  onNegativeAction: () => void;
  onActionBtnClicked?: () => void;
  unmountOnClose?: boolean;
  useDefaultPadding?: boolean;
  actionBtnDisabled?: boolean | null;
  size?: 'sm' | 'lg' | 'xl';
}
const DefaultModal: FC<IDefaultModalProps> = ({
  isOpen,
  title,
  subtitle,
  className,
  centerTitle,
  positiveDisabled,
  positiveBtnText,
  negativeBtnText,
  actionBtnText,
  onPositiveAction,
  onNegativeAction,
  onActionBtnClicked,
  unmountOnClose,
  actionBtnDisabled,
  size = 'lg',
  useDefaultPadding = true,
  testHook,
  children
}) => {
  return (
    <Modal
      data-test-hook={testHook}
      unmountOnClose={unmountOnClose}
      isOpen={isOpen}
      className={classNames(
        `modal-${size} base-modal`,
        {
          'p-4': useDefaultPadding
        },
        className
      )}
    >
      <BaseModalHeader title={title} subtitle={subtitle} centerTitle={centerTitle} />
      {children}
      <div className="base-modal-footer">
        {onActionBtnClicked && (
          <OutlinedButton
            onClick={onActionBtnClicked}
            disabled={Boolean(actionBtnDisabled)}
            color="error"
            size="medium"
            testHook="action-btn"
          >
            {actionBtnText}
          </OutlinedButton>
        )}

        <OutlinedButton onClick={onNegativeAction} size="medium" mr={16} ml="auto">
          {negativeBtnText || 'Cancel'}
        </OutlinedButton>

        <FilledButton onClick={onPositiveAction} disabled={positiveDisabled} testHook="submit-btn">
          {positiveBtnText}
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DefaultModal;
