// @ts-strict-ignore
import { useCallback, useMemo } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import Ticket, { TicketClinician } from 'models/Ticket';

import { useTicketAssign } from 'hooks/useTicketAssign';

function useSelectedDoctor(tickets: Ticket[]): TicketClinician {
  let selectedDoctor;
  if (tickets.length > 0 && tickets[0].mainAssign) {
    selectedDoctor = tickets[0].mainAssign.doctor;
  }
  return selectedDoctor;
}

export function useDoctors(ticketInput: Ticket[] | Ticket) {
  const tickets = Array.isArray(ticketInput) ? ticketInput : [ticketInput];
  const selectedDoctor = useSelectedDoctor(tickets);
  return { selectedDoctor };
}

export function useDoctorsAndAssignees(ticket: Ticket) {
  const { selectedDoctor: ticketDoctor } = useDoctors(ticket);
  const assignTicket = useTicketAssign();
  const { userStore } = useStores();
  const { currentDoctor } = userStore;
  const { mainAssign } = ticket;

  const handleDoctorSelected = useCallback(
    async (clinicianId?: number) => {
      await assignTicket(ticket, clinicianId);
    },
    [assignTicket, ticket]
  );

  const isCurrentDoctorAssigned = useMemo(
    () => currentDoctor?.id === ticketDoctor?.id,
    [currentDoctor, ticketDoctor]
  );

  return {
    mainAssign,
    ticketDoctor,
    currentDoctor,
    handleDoctorSelected,
    isCurrentDoctorAssigned
  };
}
