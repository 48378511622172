import { transformErrorUiProps } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { EpisodesParser } from 'parsers/EpisodesParser';

import { EpisodeRuleset } from 'models/Episode';

export interface SearchEpisodesQuery {
  searchTerm?: string;
  episodeIds?: number[];
}

export interface CreateEpisodeAttrs {
  name: string;
  duration: number;
  ruleset: EpisodeRuleset;
}

export interface UpdateEpisodeAttrs extends CreateEpisodeAttrs {}

const httpService = new HttpService('episode', true);
export class EpisodesFetcher {
  static async fetchEpisodes() {
    return await httpService.get({
      url: API_URLS.EPISODES,
      transformResponse: EpisodesParser.parseEpisodes
    });
  }

  static async searchEpisodes(query: SearchEpisodesQuery = {}) {
    return await httpService.get({
      url: API_URLS.SEARCH_EPISODES,
      query,
      transformResponse: EpisodesParser.parseEpisodesSearch,
      transformError: transformErrorUiProps('Failed to search episodes')
    });
  }

  static async createEpisode(data: CreateEpisodeAttrs) {
    return await httpService.post({ url: API_URLS.EPISODES, data });
  }

  static async updateEpisode(id: number, data: UpdateEpisodeAttrs) {
    return await httpService.put({ url: API_URLS.UPDATE_EPISODE(id), data });
  }

  static async deleteEpisode(id: number) {
    return await httpService.delete({ url: API_URLS.DELETE_EPISODE(id) });
  }
}
