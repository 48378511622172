import { FC, useMemo } from 'react';

import { Box, styled, css } from '@mui/material';

import { SelectOption } from 'models/SelectOption';

import Icon from 'components/Icons/Icon';
import { PaginatorSelect } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/PaginatorSelect/PaginatorSelect';

import { Text } from 'components/UIkit/atoms/Text';

export type PaginatorAction = 'select' | 'first' | 'previous' | 'next' | 'last';

export interface PaginatorProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (currentPage: number, action: PaginatorAction, previousPage: number) => void;
  testHookPrefix?: string;
}

export const Paginator: FC<PaginatorProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  testHookPrefix = 'paginator'
}) => {
  const paginatorSelectOptions = useMemo(
    () =>
      Array.from(Array(totalPages).keys()).map((_item, index) => ({
        value: index + 1,
        label: `${index + 1}`
      })),
    [totalPages]
  );

  const handlePageChange = (selectOption: SelectOption<any> | SelectOption<any>[] | null) => {
    if (!(selectOption as SelectOption<any>)?.value) {
      return;
    }

    onPageChange((selectOption as SelectOption<any>).value, 'select', currentPage);
  };

  return (
    <Box display="flex" flexDirection="row-reverse">
      <Box display="flex" alignItems="center">
        <StyledButton
          mr={4}
          onClick={() => onPageChange(1, 'first', currentPage)}
          disabled={currentPage === 1}
          component="button"
          data-test-hook={`${testHookPrefix}-first-button`}
        >
          <Icon.FirstPageArrowPagination />
        </StyledButton>

        <StyledButton
          mr={8}
          disabled={currentPage === 1}
          component="button"
          onClick={() => onPageChange(currentPage - 1, 'previous', currentPage)}
          data-test-hook={`${testHookPrefix}-previous-button`}
        >
          <Icon.PreviousPageArrowPagination />
        </StyledButton>

        <Box mr={8}>
          <PaginatorSelect
            value={{ value: currentPage, label: currentPage.toString() }}
            options={paginatorSelectOptions}
            onChange={handlePageChange}
          />
        </Box>

        <Text color="secondary" mr={8}>
          OF
        </Text>

        <Text color="secondary" mr={8}>
          {totalPages}
        </Text>

        <StyledButton
          mr={4}
          onClick={() => onPageChange(currentPage + 1, 'next', currentPage)}
          disabled={currentPage === totalPages}
          component="button"
          data-test-hook={`${testHookPrefix}-next-button`}
        >
          <Icon.NextPageArrowPagination />
        </StyledButton>

        <StyledButton
          onClick={() => onPageChange(totalPages, 'last', currentPage)}
          disabled={currentPage === totalPages}
          component="button"
          data-test-hook={`${testHookPrefix}-last-button`}
        >
          <Icon.LastPageArrowPagination />
        </StyledButton>
      </Box>
    </Box>
  );
};

const StyledButton = styled(Box)<{ disabled: boolean }>(
  ({ theme, disabled }) => css`
    background-color: transparent;
    border: 0;
    padding: 0;

    svg {
      ${disabled &&
      css`
        color: ${theme.palette.text.disabled};
      `}

      ${!disabled &&
      css`
        color: ${theme.palette.text.primary};

        &:hover {
          color: ${theme.palette.secondary.main};
          cursor: pointer;
        }

        &:active {
          color: ${theme.palette.secondary.dark};
        }
      `}
    }
  `
);
