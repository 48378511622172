import { IOperTicketType } from 'models/TicketTypes';

export default class ParseTicketTypeResponseService {
  static parseOperatorTicketTypes = (rawData: any): IOperTicketType[] => {
    if (!Array.isArray(rawData)) {
      throw new Error(
        'Cannot parse ticket type raw data (Array is expected). Raw Data: ' +
          JSON.stringify(rawData)
      );
    }

    if (rawData.length === 0) {
      throw new Error('Parsing operator Ticket Types failed: Got empty data array');
    }

    return rawData.map((unparsedTicketType: any) => ({
      id: unparsedTicketType.id,
      institutionId: unparsedTicketType.institutionId,
      name: unparsedTicketType.name,
      parentId: unparsedTicketType.parentId,
      kind: unparsedTicketType.kind,
      isDeleted: !!unparsedTicketType.isDeleted,
      displaySettings: unparsedTicketType.displaySettings,
      displayNames: unparsedTicketType.displayNames,
      hasActiveTickets: unparsedTicketType.hasActiveTickets
    }));
  };
}
