import { FC } from 'react';

import { ITooltipOption, TooltipSelect, TooltipSelectProps } from 'components/Tooltip';
import {
  DateTimePickerTooltipContent,
  DateTimePickerTooltipContentProps
} from 'components/Tooltip/DatePickerTooltipContent';
import { TooltipAsyncSelect, TooltipAsyncSelectProps } from 'components/Tooltip/TooltipAsyncSelect';
import { BulkItemContent } from 'components/UIkit/atoms/BulkMenu/BulkMenu.types';

interface Props {
  content: BulkItemContent;
  close: () => void;
}

export const BulkMenuContent: FC<Props> = ({ content, close }) => {
  const { type, ...props } = content;

  const isSelectContentType = type === 'select' || type === 'async-select';

  const onDateSelect = (date: Date) => {
    (props as DateTimePickerTooltipContentProps).onSelect(date);
    close();
  };

  const onSelectOptionClick = (option: ITooltipOption) => {
    if (option.onClick) {
      option.onClick();
    }

    close();
  };

  let selectOptions: ITooltipOption[] = [];

  if (isSelectContentType) {
    selectOptions = (props as TooltipSelectProps).options.map((option) => {
      return {
        ...option,
        onClick: () => onSelectOptionClick(option)
      };
    });

    if (type === 'select') {
      return <TooltipSelect {...(props as TooltipSelectProps)} options={selectOptions} />;
    }

    if (type === 'async-select') {
      return <TooltipAsyncSelect {...(props as TooltipAsyncSelectProps)} options={selectOptions} />;
    }
  }

  return (
    <DateTimePickerTooltipContent
      {...(props as DateTimePickerTooltipContentProps)}
      onSelect={onDateSelect}
    />
  );
};
