import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import ParseServerResponseService from 'parsers/ParseServerResponseService';

const httpService = new HttpService('institution setting', true);

export class SettingsFetcher {
  static async getInstitutionSettings() {
    return await httpService.get({
      url: API_URLS.INSTITUTION_SETTINGS,
      transformResponse: ParseServerResponseService.parseInstitutionSettings
    });
  }
}
