import React, { FC, useState } from 'react';

import { Box } from '@mui/material';

import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { showToast } from 'utils/UserMessageUtils';

import { useAsyncPatientSearch } from 'hooks/useAsyncPatientSearch';

import {
  FormSpecificPatientsAsyncMultiAutocomplete,
  SpecificPatientOption
} from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/SpecificPatientsAsyncMultiAutocomplete';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

interface AddOralAutoProtocolFormFields {
  selectedPatient: SpecificPatientOption;
}

export const QaAddOralAutoProtocolModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const { qaStore } = useStores();
  const methods = useForm<AddOralAutoProtocolFormFields>();
  const { handleSubmit } = methods;
  const [isLoading, setIsLoading] = useState(false);

  const { loadOptions, handleSpecificPatientsSearched, isSpecificPatientsSelectMenuOpen } =
    useAsyncPatientSearch(true);

  const addOralAutoProtocol = async (fields: AddOralAutoProtocolFormFields) => {
    try {
      setIsLoading(true);
      await qaStore.addOralAutoProtocol(fields.selectedPatient.value);
      showToast({ message: 'Adding Oral Auto Protocol...' });
      onSubmit();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormModal
      defaultValues={null}
      methods={methods}
      title="Add Oral Auto Protocol"
      isOpen={isOpen}
      confirmActions={[
        { onClick: handleSubmit(addOralAutoProtocol), text: 'Add', disabled: isLoading }
      ]}
      closeAction={{ onClick: onClose, disabled: isLoading }}
    >
      <Box mb={40}>
        <FormSpecificPatientsAsyncMultiAutocomplete
          name="selectedPatient"
          loadOptions={loadOptions}
          placeholder="Search by Name, MRN, or DOB."
          openMenuOnClick={false}
          menuIsOpen={isSpecificPatientsSelectMenuOpen}
          isRequired
          onInputChange={handleSpecificPatientsSearched}
          isMulti={false}
        />
      </Box>
    </FormModal>
  );
};
