// @ts-strict-ignore
import { FC, Fragment } from 'react';

import classNames from 'classnames';

import { Fade } from 'views/Widgets/animations/Fade';

import { Backdrop } from 'components/Backdrop';
import Loading from 'components/Loaders/Loading';

import './FixedLoader.scss';

interface Props {
  background?: boolean;
  hasBackdrop?: boolean;
}

const FixedLoader: FC<Props> = ({ background = true, hasBackdrop = false }) => {
  const classes = classNames('fixed-loader', { background });
  return (
    <>
      {hasBackdrop && <Backdrop isOpen />}

      <div className={classes}>
        <Fade show>
          <Fragment>
            <Loading primaryColor />
          </Fragment>
        </Fade>
      </div>
    </>
  );
};

export default FixedLoader;
