import { palette } from './colors';

type ThemeBorderRadiusKeys = 'smallest' | 'xSmall' | 'small' | 'medium' | 'large' | 'full';
type ThemeFontWeightsKeys = 'normal' | 'medium' | 'bold' | 'xBold';
type ThemeFontSizesKeys = 'xSmall' | 'small' | 'normal' | 'medium' | 'large' | 'xLarge';
type ThemeBoxShadowKeys = 'common' | 'tooltip';
type ThemeBorderRadius = Record<ThemeBorderRadiusKeys, `${number}px` | `${number}%`>;
type ThemeFontWeights = Record<ThemeFontWeightsKeys, number>;
type ThemeFontSizes = Record<ThemeFontSizesKeys, `${number}px`>;
type ThemeBoxShadows = Record<ThemeBoxShadowKeys, string>;

export interface ExtraThemeOptions {
  borderRadius: ThemeBorderRadius;
  fontWeights: ThemeFontWeights;
  fontSizes: ThemeFontSizes;
  boxShadow: ThemeBoxShadows;
  menu: {
    maxHeight: string;
    maxWidth: string;
  };
}

export const extraThemeOptions: ExtraThemeOptions = {
  borderRadius: {
    smallest: '2px',
    xSmall: '4px',
    small: '6px',
    medium: '12px',
    large: '16px',
    full: '50%'
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
    xBold: 900
  },
  fontSizes: {
    xSmall: '10px',
    small: '12px',
    normal: '14px',
    medium: '16px',
    large: '18px',
    xLarge: '24px'
  },
  boxShadow: {
    common: `0 1px 4px ${palette.natural.shadow}`,
    tooltip:
      '0 0 20px 4px rgba(154,161,177,.15), 0 4px 80px -8px rgba(36,40,47,.25), 0 4px 4px -2px rgba(91,94,105,.15)'
  },
  menu: {
    // sizes of all menus controls - selects, tooltip-select, mentions ect.
    maxHeight: '300px',
    maxWidth: '250px'
  }
};
