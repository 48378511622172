import { ConditionType } from 'models/ClinicianAlert';
import QuestionnaireAnswer from 'models/QuestionnaireAnswer';

import ClinicianAlertCondition from './ClinicianAlertCondition';

export default class YesNoQuestionCondition extends ClinicianAlertCondition {
  questionId: number;
  questionText: string;

  constructor(rawCondition: any) {
    super(ConditionType.YesNoQuestion);
    this.questionId = rawCondition.questionId;
    this.questionText = rawCondition.questionText;
    this.regimenId = rawCondition.regimenId;
  }

  getConditionsString(): string {
    return `${this.questionText}`;
  }

  isMet(report: QuestionnaireAnswer): boolean {
    return (
      report.answer.yesNoQuestions &&
      report.answer.yesNoQuestions.some(
        (questionObj: { id: number; value: boolean }) =>
          questionObj.id === this.questionId && questionObj.value === true
      )
    );
  }

  getBottomThreshold(): number {
    return 1;
  }

  setBottomThreshold(): void {
    return;
  }

  getId(): number {
    return this.questionId;
  }
}
