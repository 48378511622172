import React from 'react';

import Chip from 'components/Chips/Chip';
import { Tag } from 'components/Ticket/TicketRow/AlertTag';
import { Tooltip } from 'components/Tooltip';

import './AlertTag.scss';

interface Props {
  tags: Tag[];
}

const AdditionalAlertTags: React.FC<Props> = ({ tags }) => (
  <Tooltip
    label={
      <Chip.Item borderless className="tag-item-container">
        <span className="tag-item-truncate">{tags.length} More...</span>
      </Chip.Item>
    }
    maxWidth={342}
  >
    <div className="tag-tooltip-container">
      <ul>
        {tags.map(({ prefix, text }) => (
          <li key={`${prefix}: ${text}`}>
            {prefix}: {text}
          </li>
        ))}
      </ul>
    </div>
  </Tooltip>
);

export default AdditionalAlertTags;
