import { ChangeEvent, FC, FocusEventHandler, Ref } from 'react';

import { Testable } from 'utils/TypeUtils';

import StyledInput from 'views/Widgets/StyledInput';

import Icon from 'components/Icons/Icon';

import './SearchBar.scss';

interface Props extends Testable {
  searchValue: string;
  placeholder: string;
  disabled?: boolean;
  onSearchChanged: (searchTerm: string) => void;
  className?: string;
  onFocus?: FocusEventHandler<any>;
  inputRef?: Ref<HTMLInputElement | HTMLTextAreaElement>;
}

const SearchBar: FC<Props> = ({
  searchValue,
  placeholder,
  disabled,
  onSearchChanged,
  className,
  onFocus,
  inputRef,
  testHook
}) => (
  <div className={`search-bar ${className || ''}`}>
    <div className="icon-search-container">
      <Icon.Search />
    </div>
    <StyledInput
      testHook={testHook}
      label={placeholder}
      type="text"
      disabled={disabled}
      value={searchValue || ''}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChanged(e.target.value)}
      onFocus={onFocus}
      inputRef={inputRef}
    />
  </div>
);

export default SearchBar;
