import networkLabelService from 'services/networkLabelService';

import { API_LABELS, API_URLS } from 'constants/apiUrls';

const useNetworkLoading = (loadingLabels: string | string[]) => {
  return networkLabelService.isLoading(loadingLabels);
};

export const useTicketNetworkLoading = (ticketId: number) => {
  return useNetworkLoading([
    API_URLS.RESOLVE_TICKET(ticketId),
    API_LABELS.RESOLVE_TICKET(ticketId),
    API_URLS.ASSIGN_TICKET(ticketId),
    API_LABELS.WQ_FETCH_SECTIONS_DATA_AFTER_TICKET_ACTION(ticketId)
  ]);
};

export const useTaskNetworkLoading = (taskId: number) => {
  return useNetworkLoading([API_URLS.UPDATE_TASK(taskId)]);
};

export const useTaskTicketNetworkLoading = (ticketId: number, taskId: number) => {
  const ticketIsLoading = useTicketNetworkLoading(ticketId);
  const taskIsLoading = useTaskNetworkLoading(taskId);
  return taskIsLoading || ticketIsLoading;
};

export default useNetworkLoading;
