import { Components, Theme } from '@mui/material';

import { ExtraThemeOptions } from './extraThemeOptions';

// https://mui.com/material-ui/customization/theme-components/
declare module '@mui/material/styles' {
  interface Theme extends ExtraThemeOptions {}
}

export const components: Components<Omit<Theme, 'components'>> = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true // No more ripple, on the whole application
    },
    styleOverrides: {
      root: ({ theme }) => ({
        height: '32px',
        padding: `${theme.spacing(8)} ${theme.spacing(20)}`,
        whiteSpace: 'nowrap',
        borderRadius: theme.borderRadius.large
      })
    }
  },

  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        'form-text': 'span',
        body1: 'span',
        body2: 'span',
        body3: 'span',
        subtext: 'span',
        'button-large': 'span',
        'button-medium': 'span',
        'button-medium-outline': 'span',
        'button-medium-second-line': 'span',
        'button-small': 'span',
        'button-small-selected': 'span',
        tab: 'span',
        task: 'span',
        badge: 'span',
        'helper-text': 'span'
      }
    }
  },

  MuiToggleButtonGroup: {
    styleOverrides: {
      grouped: ({ theme }) => ({
        '&:not(:last-of-type)': {
          marginLeft: 0,
          borderLeft: 0
        },

        '&:not(:first-of-type)': {
          marginLeft: 0,
          borderLeft: 0
        },

        '&:first-of-type': {
          border: `1px solid ${theme.palette.natural.border}`
        },

        '&.Mui-error': {
          border: `1px solid ${theme.palette.error.dark}`
        },

        '&.Mui-error:not(:last-of-type)': {
          marginLeft: 0
        },

        '&.Mui-error:not(:first-of-type)': {
          marginLeft: 0,
          borderLeft: 0
        }
      })
    }
  },

  MuiToggleButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.natural.white,
        border: `1px solid ${theme.palette.natural.border}`,
        color: theme.palette.text.primary,
        textTransform: 'initial',

        '&:first-of-type': {
          borderRadius: `${theme.borderRadius.large} 0 0 ${theme.borderRadius.large}`
        },

        '&:last-of-type': {
          borderRadius: `0 ${theme.borderRadius.large} ${theme.borderRadius.large} 0`
        }
      })
    }
  },

  MuiRadio: {
    defaultProps: {
      disableRipple: true
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          div: {
            borderColor: theme.palette.primary.dark
          },
          '&.Mui-checked': {
            div: {
              backgroundColor: theme.palette.primary.dark
            }
          }
        },
        '&.Mui-disabled': {
          '&.Mui-checked': {
            div: {
              backgroundColor: theme.palette.primary.light
            }
          },
          div: {
            backgroundColor: theme.palette.natural.inactiveBackground,
            borderColor: theme.palette.natural.border
          }
        }
      })
    }
  },

  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) => ({
        '.MuiPaper-root': {
          borderRadius: `${theme.borderRadius.large} 0 ${theme.borderRadius.large}  ${theme.borderRadius.large}`,
          minWidth: '335px',
          maxWidth: '438px'
        },
        'h2.MuiDialogTitle-root': {
          fontSize: theme.fontSizes.large,
          fontWeight: theme.fontWeights.bold,
          color: theme.palette.natural.black,
          wordBreak: 'break-word',
          padding: theme.spacing(28, 28, 0, 28),
          marginBottom: '10px'
        },
        '.MuiDialogContent-root': {
          padding: theme.spacing(0, 28, 0, 28),
          marginBottom: theme.spacing(32)
        },
        'p.MuiDialogContentText-root': {
          color: theme.palette.natural.black,
          fontSize: theme.fontSizes.normal,
          fontWeight: theme.fontWeights.normal,
          lineHeight: theme.spacing(20)
        },
        '.MuiDialogActions-root': {
          padding: `${theme.spacing(8, 28, 28, 28)}`
        },
        '.MuiDialogActions-root > .MuiBox-root': {
          button: {
            marginRight: `${theme.spacing(20)}`
          },
          '&:last-child button': {
            marginRight: `${theme.spacing(0)}`
          }
        }
      })
    }
  },

  MuiCheckbox: {
    defaultProps: {
      disableRipple: true
    },
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
        height: 'fit-content',

        div: {
          transition: 'all 0.2s ease-in-out'
        },

        '&:hover, &:focus-within': {
          div: {
            backgroundColor: theme.palette.natural.hover,
            borderColor: theme.palette.primary.dark
          }
        },

        '&.Mui-checked, &.MuiCheckbox-indeterminate': {
          div: {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main
          },
          '&:hover': {
            div: {
              borderColor: theme.palette.primary.dark,
              backgroundColor: theme.palette.primary.dark
            }
          }
        },

        '&.Mui-disabled': {
          div: {
            backgroundColor: theme.palette.natural.inactiveBackground,
            borderColor: theme.palette.natural.border,
            cursor: 'initial'
          },

          '&:hover': {
            div: {
              backgroundColor: theme.palette.natural.inactiveBackground
            }
          }
        },

        '&.MuiCheckbox-colorError': {
          div: {
            borderColor: theme.palette.error.dark
          },

          '&:hover': {
            div: {
              borderColor: theme.palette.error.contrastText
            }
          }
        }
      })
    }
  },

  MuiFormControl: {
    styleOverrides: {
      root: ({ theme }) => ({
        '.MuiFormGroup-root.Mui-error': {
          '.MuiRadio-root': {
            div: {
              borderColor: theme.palette.error.dark
            }
          },
          '.MuiRadio-root + label': {
            color: theme.palette.error.dark
          }
        }
      })
    }
  },

  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.secondary,
        alignItems: 'start',
        margin: theme.spacing(0, 0, 4, 0),

        '&.Mui-error': {
          color: theme.palette.error.dark
        }
      })
    }
  },

  MuiLink: {
    styleOverrides: {
      root: () => ({
        '&.MuiLink-button': {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit'
        }
      })
    }
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        borderRadius: theme.borderRadius.small,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: theme.boxShadow.tooltip,
        fontSize: theme.fontSizes.normal,
        fontWeight: theme.fontWeights.normal,
        padding: 0
      }),
      arrow: ({ theme }) => ({
        color: theme.palette.common.white
      })
    }
  },

  MuiLinearProgress: {
    styleOverrides: {
      root: () => ({
        height: '2px'
      })
    }
  },

  MuiPaper: {
    styleOverrides: {
      root: ({ theme }) => ({
        '.MuiPickersLayout-root': {
          '.MuiButtonBase-root.MuiIconButton-root.MuiPickersArrowSwitcher-button:hover': {
            backgroundColor: 'initial'
          },

          '.MuiMultiSectionDigitalClock-root ul': {
            li: {
              borderRadius: theme.borderRadius.smallest
            }
          },

          '.MuiMultiSectionDigitalClock-root ul::after': {
            height: 'auto'
          },

          '.MuiPickersDay-root, .MuiMultiSectionDigitalClock-root ul li, .MuiDayCalendar-weekDayLabel, .MuiPickersYear-yearButton, .MuiPickersLayout-actionBar button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary':
            {
              fontSize: '14px',
              fontWeight: '400'
            }
        }
      })
    }
  }
};
