import { computed, makeObservable, observable } from 'mobx';

import { RootStore } from 'mobx/stores';

import {
  CliniciansFetcher,
  IDoctorSignUpArgs,
  SearchedClinician
} from 'fetchers/CliniciansFetcher';

import Doctor from 'models/Doctor';

import { DataMap } from './DataMap';

export default class CliniciansStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable
  defaultCliniciansForSelect = new DataMap<SearchedClinician>();

  async fetchClinicianById(id: number) {
    return await CliniciansFetcher.fetchClinicianById(id);
  }

  async fetchAssigneesForFilterSelect() {
    const assigneesResponse = await CliniciansFetcher.searchClinicians();
    this.defaultCliniciansForSelect.setItems(assigneesResponse);
  }

  async deactivateClinician(clinicianId: number) {
    await CliniciansFetcher.deactivateClinician(clinicianId);
  }

  async activateClinician(clinicianId: number) {
    return await CliniciansFetcher.activateClinician(clinicianId);
  }

  async changePassword(clinicianId: number, oldPassword: string, newPassword: string) {
    await CliniciansFetcher.changePassword(clinicianId, oldPassword, newPassword);
  }

  async inviteClinician(data: IDoctorSignUpArgs): Promise<Doctor> {
    return await CliniciansFetcher.inviteClinician(data);
  }

  @computed
  get defaultCliniciansForSelectWithoutCurrentDoctor() {
    return this.defaultCliniciansForSelect.items.filter(
      (clinician) => clinician.id !== this.rootStore.stores.userStore.currentDoctor?.id
    );
  }
}
