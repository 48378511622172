import { FC } from 'react';

import { observer } from 'mobx-react';

import { InternalLink } from 'components/UIkit/atoms/Link';
import { Text } from 'components/UIkit/atoms/Text';

import './CmNameCell.scss';

interface CmNameCellProps {
  name: string;
  mrn?: string;
  patientId: number;
}

const CmNameCell: FC<CmNameCellProps> = ({ name, mrn, patientId }) => (
  <>
    <InternalLink to={`/patient/${patientId}?tab=episodes_and_tasks`} isEllipsis>
      {name}
    </InternalLink>

    <Text variant="body2" component="div" isEllipsis>
      MRN: {mrn}
    </Text>
  </>
);

export default observer(CmNameCell);
