// @ts-strict-ignore
import { DepartmentResponse } from 'fetchers/responses/departments.response';

import Department from 'models/Department';

export class DepartmentsParser {
  static parseDepartments = (departments: DepartmentResponse[]) =>
    departments.map(DepartmentsParser.parseDepartment);

  static parseDepartment = (unparsedDepartment: DepartmentResponse) =>
    new Department({
      id: unparsedDepartment.id,
      name: unparsedDepartment.name,
      parentId: unparsedDepartment.parentId,
      isAutomation: unparsedDepartment.isAutomation
    });
}

export default DepartmentsParser;
