import { FC } from 'react';

import { css, styled } from '@mui/material';
import { components, MenuListProps } from 'react-select';

export const PaginatorSelectMenuList: FC<MenuListProps> = ({ children, ...props }) => (
  <StyledMenuList {...props}>{children}</StyledMenuList>
);

const StyledMenuList: FC<MenuListProps> = styled(components.MenuList, {
  shouldForwardProp: () => true
})((props: MenuListProps) => {
  const { borderRadius } = props.selectProps.muiTheme!;

  return css`
    padding: 0;
    border-radius: 0 0 ${borderRadius.xSmall} ${borderRadius.xSmall};
  `;
});
