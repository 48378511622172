import { FC } from 'react';

import { Box } from '@mui/material';

import { observer } from 'mobx-react';

import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { QuestionFormField } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import { QuestionTitle } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayQuestionTitle/QuestionTitle';
import { QuestionTitleFormSelect } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayQuestionTitle/QuestionTitleFormSelect';

interface Props {
  question: QuestionFormField & Record<'id', string>;
  questionPath:
    | `categories.${number}.questions.${number}`
    | `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}.questions.${number}`;
  questionIndex: number;
  updateQuestion: (questionIndex: number, data: QuestionFormField) => void;
  isDisabled?: boolean;
}

export const PathwayQuestionTitle: FC<Props> = observer(
  ({ question, questionPath, questionIndex, updateQuestion, isDisabled = false }) => {
    const { isSelected } = usePathwayEditorSelection(question.uuid);

    return (
      <Box flex={1}>
        {isSelected ? (
          <QuestionTitleFormSelect
            questionPath={questionPath}
            questionIndex={questionIndex}
            updateQuestion={updateQuestion}
          />
        ) : (
          <QuestionTitle title={question.title!.label} isDisabled={isDisabled} />
        )}
      </Box>
    );
  }
);
