import { FC, ReactNode } from 'react';

import { observer } from 'mobx-react';

import { getRegimenText } from 'utils/OralOncoUtils';
import { pluralize } from 'utils/StringUtils';

import RegimenTemplate, { StomachStatus } from 'models/RegimenTemplate';

import StyledInputHeader from 'views/Widgets/StyledInputHeader';

import './RegimenSummaryField.scss';

interface RegimenSummaryFieldProps {
  regimen: RegimenTemplate;
  onCustomizeClicked: () => void;
  isCustom: boolean;
}

const RegimenSummaryField: FC<RegimenSummaryFieldProps> = (props) => {
  const { regimen, onCustomizeClicked, isCustom } = props;
  let foodLimitation: ReactNode = '';
  if (regimen.foodLimitations?.length > 0) {
    let foodLimitationText = regimen.foodLimitations[0];
    if (regimen.foodLimitations.length > 1) {
      const last = regimen.foodLimitations[regimen.foodLimitations.length - 1];
      const foodList = regimen.foodLimitations.slice(0, regimen.foodLimitations.length - 1);
      foodLimitationText = foodList.join(', ') + ' and ' + last;
    }
    foodLimitation = (
      <span>
        {regimen.stomachStatus ? ', but' : ''}{' '}
        <b>
          {regimen.stomachStatus ? 'a' : 'A'}void {foodLimitationText}
        </b>
      </span>
    );
  }
  const hasDaysOnOff = Boolean(regimen.daysOff && regimen.daysOn);
  const hasMinimumHoursBetweenDoses = Boolean(regimen.minHoursBetweenDoses);
  let showRegimenTimesDetails = hasMinimumHoursBetweenDoses || hasDaysOnOff;
  return (
    <div className="regimen-summary-container">
      <StyledInputHeader text="Regimen Instructions" />
      <div className="regimen-summary">
        <span>
          My medication is <b>{`${getRegimenText(regimen.name, isCustom)}`}</b>
        </span>
        <br />
        <span>
          <span>
            I take it{' '}
            <b>
              {RegimenTemplate.timesText(regimen.timesPerDay)} per day
              {showRegimenTimesDetails ? ', ' : '.'}
            </b>
          </span>
          {showRegimenTimesDetails && (
            <>
              {hasMinimumHoursBetweenDoses && (
                <span>
                  with{' '}
                  <b>
                    {regimen.minHoursBetweenDoses} {pluralize('hour', regimen.daysOn)} between doses
                  </b>
                  {hasDaysOnOff ? ', ' : '.'}
                </span>
              )}
              {hasDaysOnOff && (
                <span>
                  <b>
                    for {regimen.daysOn} {pluralize('day', regimen.daysOn)}
                  </b>
                  , then stop taking it{' '}
                  <b>
                    for {regimen.daysOff} {pluralize('day', regimen.daysOff)}
                  </b>
                  .
                </span>
              )}
            </>
          )}
        </span>
        {(regimen.stomachStatus || foodLimitation) && (
          <span>
            {regimen.stomachStatus && (
              <span>
                I take it{' '}
                <b>
                  {regimen.stomachStatus === StomachStatus.Full
                    ? 'with food'
                    : 'on an empty stomach'}
                </b>
              </span>
            )}
            {foodLimitation}.
          </span>
        )}
      </div>
      <span onClick={onCustomizeClicked} className="customize-button">
        Customize
      </span>
    </div>
  );
};

export default observer(RegimenSummaryField);
