// @ts-strict-ignore
import { AxiosError, Method } from 'axios';

import { HttpStatusCode } from 'constants/httpStatusCode.const';

import { ErrorName, getErrorSpecByServerErrorCode, getErrorTitle } from './Error.utils';
import { ErrorWithUi } from './ErrorWithUi';
import { FailureInfoFromServer } from './FailureInfoFromServer';

export class HttpError<
  S extends FailureInfoFromServer = FailureInfoFromServer
> extends ErrorWithUi {
  name = ErrorName.HttpError;
  httpFailure: HttpFailure<S>;

  isStatusCodeEquals(code: HttpStatusCode) {
    return this.httpFailure.statusCode === code;
  }
}

export class ErrorFactory {
  static createHttpError<S extends FailureInfoFromServer>(
    axiosError: AxiosError<S, any>,
    entityName: string,
    standardCrudErrorMessage: boolean
  ): HttpError<S> {
    const httpFailure = new HttpFailure<S>(axiosError);
    const error = new HttpError<S>(httpFailure.summary);
    error.httpFailure = httpFailure;

    // example - Action: POST
    const actionStr = `Action: ${httpFailure.method.toUpperCase()}\r\n`;

    // basic title and description. keep title HIPPA compliant, since it is used by slack
    error.ui.title = getErrorTitle(
      entityName,
      httpFailure.method as Method,
      standardCrudErrorMessage
    );
    error.ui.description =
      error.httpFailure.serverData.message || `${actionStr}\r\n${httpFailure.title}`;

    let { errorCode } = httpFailure.serverData;
    // the following can be removed once EH-3337 and EH-3297 completed
    if (!errorCode) {
      const nonConventionalServerData: any = httpFailure.serverData;
      if (nonConventionalServerData.error) {
        errorCode = nonConventionalServerData.error;
      }
    }

    if (errorCode) {
      // if server error code exist - we can display more specific error info
      const { name, title, description } = getErrorSpecByServerErrorCode(errorCode);
      error.name = name;
      error.ui.title = title || error.ui.title;
      error.ui.description = description || error.ui.description;
    }

    return error;
  }
}

export class HttpFailure<S extends FailureInfoFromServer = FailureInfoFromServer> {
  readonly name = 'HttpError';
  axiosError?: AxiosError<S>;

  get url(): string {
    return this.axiosError.config.url;
  }

  get method() {
    return this.axiosError.config.method;
  }

  get statusCode(): HttpStatusCode {
    return this.axiosError.response.status;
  }

  get serverData(): S {
    return this.axiosError.response.data;
  }

  get title(): string {
    const { status } = this.axiosError.response;
    return `Http Error with code ${status}`;
  }

  get summary(): string {
    return `Request URL: ${this.url}
     Request Method: ${this.method}
     Status Code: ${this.statusCode}
     Response Payload:\n${JSON.stringify(this.serverData, null, 2)}`;
  }

  constructor(axiosError: AxiosError<S>) {
    this.axiosError = axiosError;
  }

  toJSON = () => {
    return {
      url: this.url,
      method: this.method,
      statusCode: this.statusCode,
      serverData: this.serverData
    };
  };
}
