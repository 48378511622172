import { WorkQueueSectionName, WorkQueueTab } from 'views/WorkQueue/WorkQueue.types';

export const workQueueTestSelectors = {
  page: 'work-queue-page',
  refreshDataButton: 'refresh-data-button',
  tab: (tab: WorkQueueTab) => `work-queue-${tab}-tab`,
  sectionTopPaginatorNextButton: (sectionName: WorkQueueSectionName) =>
    `${sectionName}-paginator-top-next-button`,
  sectionTopPaginatorLastButton: (sectionName: WorkQueueSectionName) =>
    `${sectionName}-paginator-top-last-button`,
  sectionTopPaginatorPreviousButton: (sectionName: WorkQueueSectionName) =>
    `${sectionName}-paginator-top-previous-button`,
  sectionTopPaginatorFirstButton: (sectionName: WorkQueueSectionName) =>
    `${sectionName}-paginator-top-first-button`,
  openItemsTab: {
    container: 'work-queue-open-items-view',
    sectionTrigger: (sectionName: WorkQueueSectionName) => `${sectionName}-trigger`,
    requestAllOverdueReportsButton: 'request-all-overdue-reports-button',
    requestAllOverdueReportsPopup: 'request-all-overdue-reports-popup',
    requestAllOverdueReportsPopupSubmitButton: 'popup-submit-btn-request-all-overdue-reports-popup',
    resolveAllPatientReportsButton: 'resolve-all-patient-reports-button',
    resolveAllPatientReportsPopup: 'resolve-all-patient-reports-popup',
    resolveAllPatientReportsPopupSubmitButton: 'popup-submit-btn-resolve-all-patient-reports-popup'
  },
  resolvedItemsTab: {
    container: 'work-queue-resolved-items-view'
  }
};
