import { v4 as uuid } from 'uuid';

import { Pathway, PathwayTemplatesQuestion } from 'fetchers/responses/pathways-builder.response';

import {
  BaseOption,
  BaseQuestion,
  PathwayBasicInfo,
  PathwaysData,
  PathwayTemplate
} from 'models/PathwayTemplates';

import {
  HOSPICE_CARE_OPTION_ID,
  IMMUNOTHERAPY_OPTION_ID,
  INTRAVENOUS_THERAPY_OPTION_ID,
  OBSERVATION_ONLY_OPTION_ID,
  ORAL_THERAPY_OPTION_ID,
  PATHWAY_CONTAINER_BLOCK_ACTION_BAR_ID,
  RADIATION_THERAPY_OPTION_ID,
  REMOVE_PATHWAY_CATEGORY_POPUP_ID
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.constants';

import {
  CategoryFormField,
  NewDependentQuestionsBlock,
  NewQuestionFormField,
  PathwayOptionAlert,
  PathwayType
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import {
  CANCER_DIAGNOSIS_QUESTION_ID,
  LAST_RECEIVE_TREATMENT_DATE_QUESTION_ID,
  TREATMENT_TYPE_QUESTION_ID
} from 'views/Patient/PatientMain/PathwaysView.constants';

import { theme } from 'components/UIkit/theme';

export const getNewCategory = (categoryUuid: string): CategoryFormField => ({
  categoryId: categoryUuid,
  questions: [],
  title: ''
});

export const getNewQuestion = (questionUuid: string): NewQuestionFormField => ({
  type: 'NewQuestion',
  title: null,
  questionId: '',
  uuid: questionUuid,
  isKey: false
});

export const getNewDependentQuestionsBlock = (
  dependentQuestionUuid: string
): NewDependentQuestionsBlock => {
  const newQuestionUuid = uuid();

  return {
    uuid: dependentQuestionUuid,
    triggers: null,
    questions: [getNewQuestion(newQuestionUuid)]
  };
};

export const getAlertOption = (
  alert: PathwayOptionAlert
): { value: PathwayOptionAlert; label: string } => ({
  value: alert,
  label: alertOptionForSelectMap[alert].label
});

export const alertToBackgroundColorMap: Record<PathwayOptionAlert, string> = {
  [PathwayOptionAlert.CLINIC]: theme.palette.warning.dark,
  [PathwayOptionAlert.CLINIC_EMERGENT]: theme.palette.error.dark,
  [PathwayOptionAlert.NONE]: theme.palette.natural.white
};

export const alertOptionForSelectMap: Record<
  PathwayOptionAlert,
  { label: string; backgroundColor: string }
> = {
  [PathwayOptionAlert.CLINIC]: {
    label: 'ATTENTION TODAY',
    backgroundColor: alertToBackgroundColorMap[PathwayOptionAlert.CLINIC]
  },
  [PathwayOptionAlert.CLINIC_EMERGENT]: {
    label: 'IMMEDIATE ATTENTION',
    backgroundColor: alertToBackgroundColorMap[PathwayOptionAlert.CLINIC_EMERGENT]
  },
  [PathwayOptionAlert.NONE]: {
    label: '',
    backgroundColor: alertToBackgroundColorMap[PathwayOptionAlert.NONE]
  }
};

export const getPathwayQuestionOptionSelectChevronIconColor = (
  isMenuOpen: boolean,
  hasValue: boolean
) => {
  if (isMenuOpen) {
    return theme.palette.primary.dark;
  }

  if (hasValue) {
    return theme.palette.text.primary;
  }

  return theme.palette.primary.main;
};

//server code is very very bad
export const normalizePathwaysTemplate = (
  pathways: Pathway[],
  questions: PathwayTemplatesQuestion[]
) => {
  const normalizedPathways: { [id: string]: PathwayTemplate } = {};
  const normalizedQuestions: { [id: string]: BaseQuestion } = {};
  const normalizedOptions: { [id: string]: BaseOption } = {};

  pathways.forEach((pathway) => {
    pathway.categories.forEach((category) => {
      category.questions = category.questions.map((question) => {
        // @ts-ignore
        question.categoryId = category.id;
        return question;
      });
    });
    // @ts-ignore
    normalizedPathways[pathway.id] = pathway;
  });

  questions.forEach((question) => {
    // @ts-ignore
    normalizedQuestions[question.id] = question;
    // @ts-ignore
    question.optionIds = [];
    if (question.options) {
      question.options.forEach((option) => {
        // @ts-ignore
        question.optionIds.push(option.id);
        // @ts-ignore
        option.questionId = question.id;
        // @ts-ignore
        normalizedOptions[option.id] = option;
      });
    }
  });

  const pathwaysData: PathwaysData = {
    questions: normalizedQuestions,
    pathways: normalizedPathways,
    options: normalizedOptions
  };

  return pathwaysData;
};

export const getPathwayBasicInfos = (pathways: Pathway[]): PathwayBasicInfo[] =>
  pathways.map((pathway) => ({
    id: pathway.id,
    name: pathway.name,
    keywords: pathway.keywords || [],
    type: pathway.type || PathwayType.Symptom
  }));

export const getPathwayContainerBlockActionsBar = (containerUuid: string) =>
  `${PATHWAY_CONTAINER_BLOCK_ACTION_BAR_ID}-${containerUuid}`;

export const getRemovePathwayCategoryPopupId = (categoryId: string) =>
  `${REMOVE_PATHWAY_CATEGORY_POPUP_ID}-${categoryId}`;

export const getNewPathway = (): Pathway => ({
  id: uuid(),
  name: '',
  categories: [
    {
      id: uuid(),
      title: 'Background Info',
      questions: [
        {
          id: CANCER_DIAGNOSIS_QUESTION_ID,
          isKey: false
        },
        {
          id: TREATMENT_TYPE_QUESTION_ID,
          isKey: true,
          outcomes: {
            [IMMUNOTHERAPY_OPTION_ID]: {
              outcome: PathwayOptionAlert.CLINIC,
              optionId: IMMUNOTHERAPY_OPTION_ID
            },
            [HOSPICE_CARE_OPTION_ID]: {
              outcome: PathwayOptionAlert.NONE,
              optionId: HOSPICE_CARE_OPTION_ID
            },
            [OBSERVATION_ONLY_OPTION_ID]: {
              outcome: PathwayOptionAlert.NONE,
              optionId: OBSERVATION_ONLY_OPTION_ID
            },
            [ORAL_THERAPY_OPTION_ID]: {
              outcome: PathwayOptionAlert.NONE,
              optionId: ORAL_THERAPY_OPTION_ID
            },
            [INTRAVENOUS_THERAPY_OPTION_ID]: {
              outcome: PathwayOptionAlert.NONE,
              optionId: INTRAVENOUS_THERAPY_OPTION_ID
            },
            [RADIATION_THERAPY_OPTION_ID]: {
              outcome: PathwayOptionAlert.NONE,
              optionId: RADIATION_THERAPY_OPTION_ID
            }
          }
        },
        {
          id: LAST_RECEIVE_TREATMENT_DATE_QUESTION_ID,
          isKey: false,
          dependsOn: [
            INTRAVENOUS_THERAPY_OPTION_ID,
            ORAL_THERAPY_OPTION_ID,
            IMMUNOTHERAPY_OPTION_ID,
            RADIATION_THERAPY_OPTION_ID
          ]
        }
      ]
    },
    { title: 'Onset', id: uuid(), questions: [] }
  ],
  type: PathwayType.Symptom
});
