import { FC } from 'react';

import { Box, css, styled } from '@mui/material';

import { UseFieldArrayUpdate, useFormContext } from 'react-hook-form';

import {
  MultipleQuestionFormField,
  PathwayEditorFormFields,
  CategoryQuestionOption,
  QuestionOptionAlert,
  SingleQuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import { alertToBackgroundColorMap } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';
import { PathwayOptionAlertsSelect } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayOptionAlertsSelect';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  option: CategoryQuestionOption;
  question: MultipleQuestionFormField | SingleQuestionFormField;
  questionIndex: number;
  updateQuestion: UseFieldArrayUpdate<PathwayEditorFormFields, `categories.${number}.questions`>;
  isQuestionSelected: boolean;
  questionPath:
    | `categories.${number}.questions.${number}`
    | `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}.questions.${number}`;
}

export const PathwayQuestionOptionAlert: FC<Props> = ({
  option,
  question,
  questionIndex,
  updateQuestion,
  isQuestionSelected,
  questionPath
}) => {
  const { setValue } = useFormContext<PathwayEditorFormFields>();

  const selectOption = (optionId: string, optionAlert: QuestionOptionAlert) => {
    let isKeyQuestion = question.isKey;
    const newOptions = [...question.options];
    const option = newOptions.find((option) => option.optionId === optionId)!;
    option.alert = optionAlert;

    if (newOptions.some((option) => option.alert.value > 0)) {
      isKeyQuestion = true;
    }

    updateQuestion(questionIndex, {
      ...question,
      isKey: isKeyQuestion,
      options: newOptions
    });

    setValue(`${questionPath}.isKey`, isKeyQuestion);
  };

  if (isQuestionSelected) {
    return <PathwayOptionAlertsSelect option={option} selectOption={selectOption} />;
  }

  return (
    <Box mr={16} width="290px" display="flex" justifyContent="flex-end">
      <StyledAlertContainer
        py={2}
        px={20}
        backgroundColor={alertToBackgroundColorMap[option.alert.value]}
      >
        <Text variant="h4" color="white">
          {option.alert.label}
        </Text>
      </StyledAlertContainer>
    </Box>
  );
};

const StyledAlertContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor'
})<{ backgroundColor: string }>(
  ({ theme, backgroundColor }) => css`
    background-color: ${backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.borderRadius.xSmall};
  `
);
