import { useCallback } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { useTicketAssign } from 'hooks/useTicketAssign';

interface UseBulkTicketAssignReturn {
  (doctorId: number): Promise<void>;
}

export const useBulkTicketAssign = (): UseBulkTicketAssignReturn => {
  const { ticketsStore } = useStores();
  const assignTicket = useTicketAssign();

  return useCallback(
    async function bulkAssignTicket(doctorId: number | null) {
      await Promise.all(
        ticketsStore.ticketsBulkAction.map((ticket) => assignTicket(ticket, doctorId || null))
      );

      ticketsStore.resetTicketsBulkActionSet();
    },
    [assignTicket, ticketsStore]
  );
};
