import { useCallback, useContext } from 'react';

import { TicketOverviewContext } from 'components/Ticket/TicketsContainers/TicketOverviewProvider';

export function useTicketOverviewContext() {
  return useContext(TicketOverviewContext);
}

export function useSectionExpand(section: string): [boolean, () => any] {
  const context = useContext(TicketOverviewContext);
  const sectionSetIsExpanded = useCallback(
    () => context.setExpandState(section),
    [context, section]
  );
  return [context.expandState[section], sectionSetIsExpanded];
}

export default useTicketOverviewContext;
