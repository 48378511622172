// @ts-strict-ignore
import { omit } from 'lodash/fp';
import { runInAction, action, makeObservable, observable } from 'mobx';

import { RootStore } from 'mobx/stores';

import { CallResolutionResponse, CallsFetcher, FetchCallsQuery } from 'fetchers/CallsFetcher';

import { showToast } from 'utils/UserMessageUtils';

import Call from 'models/Call';
import { ICallSaveOptions } from 'models/CallSaveOptions';
import Patient from 'models/Patient';

export default class CallsStore {
  rootStore: RootStore;

  @observable
  currentEditedCall: Call;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  private setCallPathwayQuestionSummary(call: Call) {
    call.pathwayQuestionSummary = this.rootStore.stores.pathwaysStore.activePathwaysQuestionSummary;
  }

  updateSavedCall = async (
    call: Call,
    shouldResolveIncludedTickets: boolean,
    patient: Patient
  ): Promise<CallResolutionResponse> => {
    this.setCallPathwayQuestionSummary(call);
    const response = await CallsFetcher.updateSavedCall(call, shouldResolveIncludedTickets);
    const { call: responseCall } = response;

    runInAction(() => {
      this.fetchCallsForPatient(
        { callIds: [...response.pdfSucceededCallIds, responseCall.id] },
        patient
      );
      this.resetEditedCall();
    });
    this.handleCallSaveUpdateResponse(response, {
      resolveConnectedTickets: shouldResolveIncludedTickets
    });
    return response;
  };

  addNewCall = (call: Call, options: ICallSaveOptions): Promise<CallResolutionResponse> => {
    this.setCallPathwayQuestionSummary(call);
    const { patient: patientModel } = this.rootStore.stores.patientPageStore;
    return CallsFetcher.saveCall(call, options.sendToEmr, options.resolveConnectedTickets).then(
      (response) => {
        const { call: responseCall } = response;
        runInAction(() => {
          // was a draft before
          if (call.id) {
            patientModel.callsMap.delete(call.id);
          }

          patientModel.resetCallbackInfo();
          if (call.ticketIdsArray?.length) {
            this.rootStore.stores.ticketsStore.searchTickets({
              ticketIds: call.ticketIdsArray
            });
          }
          this.fetchCallsForPatient(
            { callIds: [...response.pdfSucceededCallIds, responseCall.id] },
            patientModel
          );
        });
        this.handleCallSaveUpdateResponse(response, options);
        return response;
      }
    );
  };

  handleCallSaveUpdateResponse = (
    response: CallResolutionResponse,
    options?: Partial<ICallSaveOptions>
  ) => {
    const messageFrags = ['Call Saved'];
    if (response.pdfSucceededCallIds.length > 0) {
      messageFrags.push('PDF Sent to EMR');
    }
    if (options?.copyToClipboard && !options?.sendToEmr) {
      messageFrags.push('Notes Copied to Clipboard');
    }
    showToast({ message: messageFrags.join(' • ') });
    if (options.resolveConnectedTickets) {
      this.rootStore.stores.ticketsStore.handleTicketResolutionMessages(response);
    }
  };

  updateDraftCall = (call: Call, patient: Patient) => {
    return CallsFetcher.updateDraftCall(omit('homeCareInstructions', call)).then(() =>
      runInAction(() => {
        patient.callsMap.set(call.id, call);
      })
    );
  };

  createDraftCall = (call: Call, patient: Patient) => {
    return CallsFetcher.createDraftCall(call).then((id: number) => {
      call.id = id;
      runInAction(() => {
        const { durationIntervals } = call;
        const isCallAttempt = durationIntervals[durationIntervals.length - 1]?.isCallAttempt;

        patient.callsMap.set(call.id, call);
        showToast({ message: `Draft Call Saved ${isCallAttempt ? '• Quick Comment Added' : ''}` });
      });
    });
  };

  disconnectDraftsFromTickets = (ticketIds: number[], excludedCallIds?: number[]) => {
    return CallsFetcher.disconnectDraftsFromTickets(ticketIds, excludedCallIds);
  };

  deleteCall = (call: Call) => {
    const { patientPageStore } = this.rootStore.stores;
    const { patient: patientModel } = patientPageStore;

    return CallsFetcher.deleteDraftCall(call).then(() => {
      runInAction(() => {
        patientModel.callsMap.delete(call.id);
      });
    });
  };

  fetchCallsByIds = ({ callIds = [] }: FetchCallsQuery) => {
    if (callIds.length) {
      return CallsFetcher.fetchCallsByQuery({ callIds });
    }
  };

  fetchCallsForPatient = async ({ callIds = [] }: FetchCallsQuery, patient: Patient) => {
    const calls = await this.fetchCallsByIds({ callIds });
    this.addCallsForPatient(calls, patient);
  };

  validateInterval = (startedAt: Date, endedAt: Date, patientId: number) => {
    return CallsFetcher.validateInterval(startedAt, endedAt, patientId);
  };

  @action
  addCallsForPatient = (calls: Call[], patient?: Patient) => {
    calls?.forEach((call) => {
      if (!patient) {
        console.error('Could not find patient with ID', call.patientId, ' on call ID ', call.id);
      } else {
        patient.callsMap.set(call.id, call);
      }
    });
  };

  @action
  setEditedCall = (call: Call) => (this.currentEditedCall = call);

  @action
  resetEditedCall = () => this.setEditedCall(null);
}
