// @ts-strict-ignore
import { FC } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';

import * as ValidationUtils from 'utils/ValidationUtils';

import { API_URLS } from 'constants/apiUrls';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface IChangePasswordModalProps {
  shouldInputCurrentPass: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onSaved: (oldPass: string, newPass: string) => void;
  clinicianId: number;
}

export interface ChangePasswordFields {
  currentEditedPass?: string;
  newEditedPass: string;
  newEditedPassConfirm: string;
}

const ChangePasswordModal: FC<IChangePasswordModalProps> = ({
  isOpen,
  onCancel,
  onSaved,
  clinicianId,
  shouldInputCurrentPass
}) => {
  const methods = useForm<ChangePasswordFields>();
  const { register, handleSubmit, getValues, formState, reset } = methods;
  const { errors } = formState;

  const onSubmit = (data: ChangePasswordFields) => {
    onSaved(data.currentEditedPass, data.newEditedPass);
  };
  const isLoading = useNetworkLoading(API_URLS.UPDATE_CLINICIAN_PASSWORD(clinicianId));

  const validateSamePassword = (confirmPassword: string) => {
    return confirmPassword !== getValues('newEditedPass') ? 'Passwords do not match' : true;
  };

  return (
    <FormModal
      defaultValues={null}
      methods={methods}
      isOpen={isOpen}
      title="Change Password"
      confirmActions={[{ text: 'Save', onClick: handleSubmit(onSubmit), disabled: isLoading }]}
      closeAction={{ onClick: onCancel, disabled: false }}
      resetDataAfterClose={() => reset()}
    >
      <StyledContainer>
        {shouldInputCurrentPass && (
          <RHFStyledInput
            isRequired
            type="password"
            name="currentEditedPass"
            label="Current Password"
            register={register}
            error={Boolean(errors.currentEditedPass)}
            errorMessage={errors.currentEditedPass?.message}
          />
        )}
        <RHFStyledInput
          showErrorOutside
          isRequired
          name="newEditedPass"
          type="password"
          label="New Password"
          register={register}
          error={Boolean(errors.newEditedPass)}
          validate={ValidationUtils.isStrongPassword}
          hint="At Least: 8 characters (Lowercase & Uppercase), 1 Number, 1 Special Character"
        />
        <RHFStyledInput
          showErrorOutside
          isRequired
          name="newEditedPassConfirm"
          type="password"
          label="Confirm New Password"
          register={register}
          error={Boolean(errors.newEditedPassConfirm)}
          validate={validateSamePassword}
        />
      </StyledContainer>
    </FormModal>
  );
};

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  & .styled-input {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 40px;
    }
  }
`;

export default observer(ChangePasswordModal);
