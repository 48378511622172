import { FC } from 'react';

import { Box } from '@mui/material';
import { observer } from 'mobx-react';

import { useWatch } from 'react-hook-form';

import { SelectOption } from 'models/SelectOption';

import { useSymptomOptionsForPathway } from 'views/Pages/PathwayBuilder/PathwayEditorView/useSymptomOptionsForPathway';

import { SimpleTooltip } from 'components/Tooltip';

import { FormMultiAutocomplete } from 'components/UIkit/atoms/Dropdown';

import { StyledNoPointerEventsContainer } from './PathwayEditorView.shared';

interface Props {
  questionPath: string;
  isQuestionSelected?: boolean;
}

export const QuestionSymptomsFormSelect: FC<Props> = observer(
  ({ questionPath, isQuestionSelected }) => {
    const symptomOptions = useSymptomOptionsForPathway();
    const fieldName = `${questionPath}.symptoms`;
    const currentQuestionSymptoms = useWatch({ name: fieldName });

    const content = (
      <StyledNoPointerEventsContainer ml={20} width={290} isSelected={isQuestionSelected}>
        <FormMultiAutocomplete
          name={fieldName}
          displaySelectedSummary
          isDisabled={!isQuestionSelected}
          placeholder="Lightweight Pathway Symptoms"
          options={symptomOptions}
          isClearable
        />
      </StyledNoPointerEventsContainer>
    );

    if (isQuestionSelected) {
      return content;
    }

    return (
      <SimpleTooltip
        disabled={!currentQuestionSymptoms || currentQuestionSymptoms.length <= 1}
        title={
          <Box pt={20} pr={20}>
            <ul>
              {currentQuestionSymptoms?.map((option: SelectOption<number>) => (
                <li key={option.value}>{option.label}</li>
              ))}
            </ul>
          </Box>
        }
      >
        {content}
      </SimpleTooltip>
    );
  }
);
