import moment from 'moment';

import { EpisodeMetadata } from 'mobx/stores';

import { EpisodeRuleset } from 'models/Episode';

export const getEpisodeEndDate = (startDate: Date, episode: EpisodeMetadata) => {
  const defaultHandler = new DefaultEpisodeRulesHandler(episode);
  const ruleSetHandlers = {
    [EpisodeRuleset.default]: defaultHandler,
    [EpisodeRuleset.ocm]: new OCMEpisodeRulesHandler(episode)
  };

  const handler = ruleSetHandlers[episode.ruleset];

  if (!handler) {
    console.warn(`Episode: ${episode.name} has ruleset: ${episode.ruleset} which is not supported`);
    return defaultHandler.calculateEndDate(startDate);
  }

  return handler.calculateEndDate(startDate);
};

abstract class EpisodeRulesCalculator {
  episode: EpisodeMetadata;
  constructor(episode: EpisodeMetadata) {
    this.episode = episode;
  }
  abstract calculateEndDate(startDate: Date): Date;
}

class DefaultEpisodeRulesHandler extends EpisodeRulesCalculator {
  calculateEndDate(startDate: Date): Date {
    return moment(startDate).add(this.episode.duration, 'month').toDate();
  }
}

class OCMEpisodeRulesHandler extends EpisodeRulesCalculator {
  /*
   *  Calculation logic of end date for OCM is as follows:
   *  - Start date is first day of the month => end date is last day of the month prior to start date + episode duration
   *  - Start date day is greater than the amount of days in end date => start date + episode duration last day of month  - 1 day.
   *  - For any other case ("Regular case") => start date + episode duration - 1 day
   * */
  calculateEndDate(startDate: Date): Date {
    const momentStartDate = moment(startDate);
    const startDay = parseInt(momentStartDate.format('D'));
    const isStartDateFirstDay = startDay === 1;

    if (isStartDateFirstDay) {
      return this.getDateWhenStartDayIsFirstDay(startDate);
    }

    const startDayGreaterThanEndMonthDays =
      startDay > momentStartDate.add(this.episode.duration, 'month').daysInMonth();

    if (startDayGreaterThanEndMonthDays) {
      return this.getDateWhenStartDayGreaterThanEndMonthDays(startDate);
    }

    return this.getRegularCaseEndDate(startDate);
  }

  private getDateWhenStartDayIsFirstDay(startDate: Date): Date {
    return moment(startDate)
      .add(this.episode.duration - 1, 'month')
      .endOf('month')
      .toDate();
  }

  private getDateWhenStartDayGreaterThanEndMonthDays(startDate: Date) {
    return moment(startDate)
      .add(this.episode.duration, 'month')
      .endOf('month')
      .subtract(1, 'day')
      .toDate();
  }

  private getRegularCaseEndDate(startDate: Date) {
    return moment(startDate).add(this.episode.duration, 'month').subtract(1, 'day').toDate();
  }
}
