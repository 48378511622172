import { AnalyticEventAction } from 'analytics/AnalyticEventAction';

import { DialogCloseReason } from './MessageDialog.constants';
/**
 * Convert the close reason of a dialog to the relevent analytics event action.
 */
export const closeReasonToAnaltiycsEventAction = (
  closeReason: DialogCloseReason
): AnalyticEventAction | undefined => {
  switch (closeReason) {
    case DialogCloseReason.BackdropClick:
      return AnalyticEventAction.BackdropClick;
    case DialogCloseReason.CloseIconClick:
      return AnalyticEventAction.CancelIcon;
    case DialogCloseReason.CloseButtonClick:
      return AnalyticEventAction.Cancel;
    case DialogCloseReason.EscapeKeyDown:
      return AnalyticEventAction.EscapeKey;
    default:
      return undefined;
  }
};
