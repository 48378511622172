import React, { FC } from 'react';

import { Box } from '@mui/material';

import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { FormInputField } from 'components/Forms';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

interface ProviderFormFields {
  name: string;
}

export const QaProviderModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const { qaStore } = useStores();
  const methods = useForm<ProviderFormFields>();
  const { handleSubmit } = methods;

  const addProvider = (providerFormFields: ProviderFormFields) => {
    qaStore.addProvider(providerFormFields);
    onSubmit();
  };

  return (
    <FormModal
      defaultValues={null}
      methods={methods}
      title="Add Provider"
      isOpen={isOpen}
      confirmActions={[{ onClick: handleSubmit(addProvider), text: 'Add', disabled: false }]}
      closeAction={{ onClick: onClose, disabled: false }}
    >
      <Box mb={40}>
        <FormInputField name="name" label="Name" isRequired />
      </Box>
    </FormModal>
  );
};
