import { ProtocolName } from 'models/ScheduledProtocol';

import { CareManagementTabNames } from 'views/Pages/CareManagement/CareManagementPage';
import { WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.TabSelect;
type Value =
  | 'All Questions'
  | 'Key Questions'
  | 'Enrollment Manager'
  | 'Enrolled Patients'
  | 'Open Items'
  | 'Resolved Tickets'
  | 'Calls'
  | 'Episodes & Tasks'
  | ProtocolName
  | WorkQueueTabName;

type Tab = CareManagementTabNames | ProtocolName | WorkQueueTabName;

interface Properties {
  action: Action;
  value: Value;
  virtual_page?: 'call logger' | 'report protocol modal';
  tab?: Tab;
}

export const trackTabNavigationAnalyticsEvent = ({
  action,
  value,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.TabNavigation,
    properties: { action, value, ...optionalProps }
  });
};
