// @ts-strict-ignore

import {
  ComponentType,
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';

import { useStores } from 'mobx/hooks/useStores';

import Patient from 'models/Patient';
import Ticket from 'models/Ticket';

import { WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

import TicketActions from 'components/Ticket/TicketRow/TicketActions/TicketActions';
import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';

import {
  DisconnectPopupConfig,
  useDisconnectDraftPopup,
  useEditModal,
  useEditTaskModal,
  useTicketResolveAndCommentModal
} from './TicketsContainers.shared';

export interface ActionCallbacks {
  onEditTicket?: (ticket: Ticket) => void;
  onDeleteTicket?: (ticket: Ticket) => void;
  onAssign?: (ticket: Ticket, doctorId: number | null) => any;
  onResolve?: (ticketIds: number[]) => void;
  onStatusChange?: (ticket: Ticket) => void;
  onEditTask?: (ticket: Ticket) => void;
  onDeleteTask?: (ticket: Ticket) => void;
  onRescheduleTask?: (ticket: Ticket) => void;
  onReopenTask?: (ticket: Ticket) => void;
  onSnooze?: (patient: Patient) => void;
  onRequestReport?: (patient: Patient) => void;
}

type TicketOverviewContextType = {
  expandState: OverviewExpandState;
  setExpandState: (expand: Section | string) => any;
  ticketActions: any;
  allowBulkActions: boolean;
  openEditTicketModal: (patient: Patient, ticket: Ticket) => any;
  openEditTaskModal: (patient: Patient, ticket: Ticket) => any;
  openDisconnectDraftPopup: (disconnectConfig: DisconnectPopupConfig) => any;
  openResolveAndCommentModal: (ticketIds: number[]) => void;
  actionCallbacks: ActionCallbacks;
  useIntersectionObserver: boolean;
  getPatientSymptomOperatorTickets: (patient: Patient) => Ticket[];
  tab?: WorkQueueTabName;
  hideActionButtons: boolean;
};
export const TicketOverviewContext = createContext<TicketOverviewContextType>(null);

export interface TicketActionsProps {
  ticket: Ticket;
  onEdit?: () => void;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
}

export interface TicketOverviewProviderProps {
  children?: ReactNode;
  ticketActions?: ComponentType<TicketActionsProps>;
  allowBulkActions?: boolean;
  initialExpandState?: OverviewExpandState;
  expandStatePersistKey?: string;
  actionCallbacks: ActionCallbacks;
  useIntersectionObserver?: boolean;
  getPatientSymptomOperatorTickets?: (patient: Patient) => Ticket[];
  tab?: WorkQueueTabName;
  hideActionButtons?: boolean;
}

type OverviewExpandState = Record<string, boolean>;

const baseInitialExpandState: OverviewExpandState = {
  [Section.Assigned]: true,
  [Section.Urgent]: true,
  [Section.Callback]: true,
  [Section.NonUrgent]: true,
  [Section.Overdue]: true,
  [Section.TasksDue]: true
};

function getExpandState(persistKey: string, initialExpandState: OverviewExpandState) {
  if (persistKey) {
    return JSON.parse(localStorage.getItem(persistKey)) || initialExpandState;
  }

  return initialExpandState;
}

const TicketOverviewProvider: FC<TicketOverviewProviderProps> = ({
  children,
  ticketActions = TicketActions,
  allowBulkActions = false,
  initialExpandState = baseInitialExpandState,
  expandStatePersistKey = null,
  actionCallbacks = {},
  useIntersectionObserver = false,
  getPatientSymptomOperatorTickets = () => [],
  tab,
  hideActionButtons
}) => {
  const { ticketsStore } = useStores();
  const { onDeleteTask, onDeleteTicket, onEditTicket, onEditTask } = actionCallbacks;
  const [expandState, setSectionExpandState] = useState<OverviewExpandState>(
    getExpandState(expandStatePersistKey, initialExpandState)
  );
  const [ticketEditModal, openEditTicketModal] = useEditModal({ onDeleteTicket, onEditTicket });
  const [taskEditModal, openEditTaskModal] = useEditTaskModal({ onDeleteTask, onEditTask });
  const [resolveAndCommentModal, openResolveAndCommentModal] = useTicketResolveAndCommentModal();

  useEffect(
    function resetBulkAction() {
      ticketsStore.resetTicketsBulkActionSet();
    },
    [ticketsStore]
  );

  const setExpandState = useCallback(
    (expand: Section) => {
      setSectionExpandState((prevState) => {
        const newExpandState = {
          ...prevState,
          [expand]: !Boolean(prevState[expand])
        };

        if (expandStatePersistKey) {
          localStorage.setItem(expandStatePersistKey, JSON.stringify(newExpandState));
        }

        return newExpandState;
      });
    },
    [expandStatePersistKey]
  );

  const [disconnectDraftPopup, openDisconnectDraftPopup] = useDisconnectDraftPopup();

  return (
    <TicketOverviewContext.Provider
      value={{
        expandState,
        setExpandState,
        ticketActions,
        allowBulkActions,
        openEditTicketModal,
        openEditTaskModal,
        openDisconnectDraftPopup,
        openResolveAndCommentModal,
        actionCallbacks,
        useIntersectionObserver,
        getPatientSymptomOperatorTickets,
        tab,
        hideActionButtons
      }}
    >
      {ticketEditModal}
      {taskEditModal}
      {resolveAndCommentModal}
      {disconnectDraftPopup}
      {children}
    </TicketOverviewContext.Provider>
  );
};

export default TicketOverviewProvider;
