import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { PathwaySearchResult } from 'models/PathwayTemplates';

import { Highlighter } from 'components/Tooltip/Highlighter';

interface Props {
  pathway: PathwaySearchResult;
  searchValue: string;
  keywords: string[];
}

export const PathwayWithKeywords: FC<Props> = ({ pathway, searchValue, keywords }) => (
  <Grid container flexDirection="column">
    <Typography variant="form-text">{pathway.name}</Typography>
    {keywords.length > 0 && (
      <StyledKeywords className="keywords">
        {keywords.map((keyword, index) => (
          <React.Fragment key={keyword}>
            <Highlighter searchValue={searchValue} textToHighlight={keyword} />
            {index + 1 < keywords.length && ', '}
          </React.Fragment>
        ))}
      </StyledKeywords>
    )}
  </Grid>
);

const StyledKeywords = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.text.disabled};
  `
);
