import { FC } from 'react';

import { isEmpty } from 'lodash/fp';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { formatDate } from 'utils/DateUtils';

import { getMissedDosesReasonsText } from 'utils/OralOncoUtils';

import { pluralize } from 'utils/StringUtils';

import { ReportQuestionAnswer } from 'models/OralReport/OralReport.shared';
import { OralReportV1 } from 'models/OralReport/OralReportV1';

import {
  noInfoProvidedText,
  OralReportSummaryProps
} from 'components/Ticket/TicketRow/OralReport/OralReport.shared';

import { OralReportCycleV1 } from 'components/Ticket/TicketRow/OralReport/OralReportCycleV1';
import { OralReportRow } from 'components/Ticket/TicketRow/OralReport/OralReportRow';

export const OralReportSummaryV1: FC<OralReportSummaryProps<OralReportV1>> = observer(
  ({ reportId, oral, patient }) => {
    const { constantsStore } = useStores();

    if (oral!.medReceived?.isAnswered && !oral.medReceived.isOk) {
      return <OralReportRow title="Medication Filled">No</OralReportRow>;
    }

    const {
      regimenAsPrescribed,
      firstPill,
      pillsLeft,
      changesInOtherMeds,
      missedDoses,
      previousReportDate
    } = oral;

    const missedDosesReasonsText = getMissedDosesReasonsText(
      missedDoses!,
      constantsStore.missedDosesReasonsMap
    );

    return (
      <>
        {regimenAsPrescribed?.isAnswered && !regimenAsPrescribed.isOk && (
          <OralReportRow title="Regimen Adherence Issue">
            {regimenAsPrescribed.customMessage
              ? regimenAsPrescribed.customMessage
              : noInfoProvidedText}
          </OralReportRow>
        )}

        {firstPill?.isAnsweredOrDontKnow && (
          <OralReportRow title="Start of Cycle">
            <OralReportCycleV1 reportId={reportId} oral={oral} patient={patient} />
            {pillsLeft && <>&nbsp;({getRemainingPillsText(pillsLeft)})</>}
          </OralReportRow>
        )}

        {pillsLeft?.isAnsweredOrDontKnow && !firstPill?.isAnsweredOrDontKnow && (
          <OralReportRow title="Remaining Pills">
            {pillsLeft.isAnswered ? pillsLeft.value : 'Unknown'}
          </OralReportRow>
        )}

        {changesInOtherMeds?.isAnswered && !changesInOtherMeds.isOk && (
          <OralReportRow title="Other Medication Change">
            {changesInOtherMeds.customMessage
              ? changesInOtherMeds.customMessage
              : noInfoProvidedText}
          </OralReportRow>
        )}

        {((missedDoses?.isAnswered && !missedDoses.isOk) || missedDoses?.isIDontKnow) && (
          <OralReportRow title="Missed Doses">
            {missedDoses.isIDontKnow ? (
              'Unknown'
            ) : (
              <>
                {missedDoses.value}
                {` Since ${
                  previousReportDate
                    ? formatDate(previousReportDate, 'MMMM Do, YYYY')
                    : 'Start of Cycle'
                }`}
                {!isEmpty(missedDosesReasonsText) ? ` - ${missedDosesReasonsText}` : ''}
              </>
            )}
          </OralReportRow>
        )}
      </>
    );
  }
);

function getRemainingPillsText(pillsLeft: ReportQuestionAnswer<number>) {
  if (pillsLeft.isIDontKnow) {
    return 'Pills Remaining: Unknown';
  }
  const { value } = pillsLeft;
  return `${value} ${pluralize('Pill', value!)} Remaining`;
}
