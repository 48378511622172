import { ClinicianAlertsResponse } from 'fetchers/responses/clinician-alerts.response';

import AlertTargetPopulation from 'models/AlertTargetPopulation';
import ClinicianAlert, { ConditionType } from 'models/ClinicianAlert';
import AnyCauseCondition from 'models/Conditions/AnyCauseCondition';
import ClinicianAlertCondition from 'models/Conditions/ClinicianAlertCondition';
import DistressCondition from 'models/Conditions/DistressCondition';
import InputQuestionCondition from 'models/Conditions/InputQuestionCondition';
import OralConditions from 'models/Conditions/OralConditions';
import SpecificCauseCondition from 'models/Conditions/SpecificCauseCondition';
import YesNoQuestionCondition from 'models/Conditions/YesNoQuestionCondition';

export class ClinicianAlertsParser {
  static parseClinicianAlerts = (alerts: ClinicianAlertsResponse[]) =>
    alerts.map((unparsedAlert) => ClinicianAlertsParser.parseClinicianAlert(unparsedAlert));

  static parseClinicianAlert(unparsedAlert: ClinicianAlertsResponse) {
    const alert = new ClinicianAlert();
    alert.urgency = unparsedAlert.urgency;
    alert.reportType = unparsedAlert.report_type;
    alert.conditionsRule = unparsedAlert.conditions_rule;
    alert.population = new AlertTargetPopulation();
    alert.population.type = unparsedAlert.population;
    alert.doctorId = unparsedAlert.doctor_id;
    alert.population.patients = [];
    alert.population.patientIds = [];
    alert.population.careTeamIds = [];

    if (unparsedAlert.patients) {
      alert.population.patients = unparsedAlert.patients.map((patient: any) => ({
        patientId: patient.id,
        firstName: patient.user.first_name,
        lastName: patient.user.last_name
      }));

      alert.population.patientIds = unparsedAlert.patients.map((patient: any) => patient.id);
    }

    if (unparsedAlert.departments) {
      alert.population.careTeamIds = unparsedAlert.departments.map(
        (department: any) => department.id
      );
    }
    alert.id = unparsedAlert.id;
    alert.conditions = unparsedAlert.conditions.map((unparsedCondition: any) =>
      ClinicianAlertsParser.parseCondition(unparsedCondition)
    );
    return alert;
  }

  static parseCondition(unparsedCondition: any): ClinicianAlertCondition {
    switch (unparsedCondition.type) {
      case ConditionType.AnyCause:
        return new AnyCauseCondition(unparsedCondition);
      case ConditionType.SpecificCause:
        return new SpecificCauseCondition(unparsedCondition);
      case ConditionType.YesNoQuestion:
        return new YesNoQuestionCondition(unparsedCondition);
      case ConditionType.Distress:
        return new DistressCondition(unparsedCondition);
      case ConditionType.InputQuestion:
        return new InputQuestionCondition(unparsedCondition);
      case ConditionType.Oral:
        return new OralConditions(unparsedCondition);

      default:
        throw new Error('Unknown condition type');
    }
  }
}
