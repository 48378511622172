// @ts-strict-ignore
import { computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import {
  QuickAdditionRequest,
  QuickAdditionResponse
} from 'fetchers/responses/care-time-data.response';

import { Reason } from 'models/Reason';

export const QUICK_ADDITION_MAX_DURATION = 60;

export class QuickAddition {
  id: number;
  duration: number;
  loggedTime?: Date;
  reason?: Reason;

  @observable
  isDeleted: boolean;

  @observable
  identifierFallback: string; // used internally when id is not exists (e.i. before interval sent to server)

  static createEmpty() {
    return new QuickAddition({
      id: null,
      loggedTime: null,
      duration: 0,
      reason: null
    });
  }

  static toRequest(quickAddition: QuickAddition): QuickAdditionRequest {
    return {
      id: quickAddition.id || null,
      duration: quickAddition.duration,
      reasonId: quickAddition.reason?.id || null,
      loggedTime: quickAddition.loggedTime ? quickAddition.loggedTime.toString() : null
    };
  }

  static toDeleteRequest(quickAddition: QuickAddition): QuickAdditionRequest {
    return {
      id: quickAddition.id,
      duration: quickAddition.duration,
      isDeleted: true
    };
  }

  @computed
  get uniqueIdentifier(): string {
    return this.id ? this.id.toString() : this.identifierFallback;
  }

  constructor(quickAdditionData: QuickAdditionResponse) {
    Object.assign(this, quickAdditionData);
    this.loggedTime = quickAdditionData.loggedTime ? new Date(quickAdditionData.loggedTime) : null;
    this.id = quickAdditionData.id || null;
    this.identifierFallback = uuidv4();
  }

  equals(quickAddition: QuickAddition): boolean {
    return (
      this.uniqueIdentifier === quickAddition.uniqueIdentifier &&
      this.duration === quickAddition.duration &&
      this.reason === quickAddition.reason
    );
  }
}
