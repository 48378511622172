import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Consent | AnalyticEventAction.NonConsent;

interface Properties {
  action: Action;
  patientId: number;
}

export const trackMessagingConsentAnalyticsEvent = ({
  action,
  patientId,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent({
    name: AnalyticEventName.MessagingConsent,
    properties: {
      action,
      patient_id: patientId,
      ...optionalProps
    }
  });
};
