// @ts-strict-ignore
import { observable, makeObservable } from 'mobx';

import Patient from './Patient';

interface IDepartmentProps {
  id: number;
  name: string;
  parentId: number;
  isAutomation: boolean;
}

export interface BasicDepartmentInfo {
  id: number;
  name: string;
}
export default class Department {
  id: number;

  name: string;

  parentId: number;

  isAutomation: boolean;

  @observable
  children: Department[] = [];

  @observable
  patients: Patient[] = [];

  constructor(data: IDepartmentProps) {
    makeObservable(this);
    Object.assign(this, data);
  }
}
