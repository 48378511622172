import { intersection } from 'lodash/fp';

import { ISelectOption } from 'components/UIkit/atoms/Dropdown/Select.shared';

export const getCustomSelectCheckboxOptionChildren = (
  optionId: number,
  options: ISelectOption<number>[],
  selectedOptions: ISelectOption<number>[] = []
) => {
  let optionIndex: number = 0;

  const option = options.find((option, index) => {
    if (option.value === optionId) {
      optionIndex = index;
      return true;
    }

    return false;
  });

  const slicedOptions = options.slice(optionIndex + 1);
  const children: ISelectOption<number>[] = [];

  if (option?.isParent) {
    for (const option of slicedOptions) {
      if (option.isParent) {
        break;
      }

      children.push(option);
    }
  }

  const selectedOptionIds = selectedOptions.map((option) => option.value);
  const childrenIds = children.map((option) => option.value);
  const selectedChildrenIds = intersection(selectedOptionIds, childrenIds);

  return {
    children,
    childrenIds,
    isAllChildrenChecked: selectedChildrenIds.length === childrenIds.length,
    isAllChildrenUnchecked: selectedChildrenIds.length === 0
  };
};

export const getCustomSelectCheckboxOptionParent = (
  optionId: number,
  options: ISelectOption<number>[]
) => {
  const currentOption = options.find((option) => option.value === optionId);
  let parentOption = null;

  for (const option of options) {
    if (option.isParent) {
      parentOption = option;
    }

    if (option.value === currentOption?.value) {
      break;
    }
  }

  return parentOption;
};
