import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import { ToolbarPluginListeners } from 'components/UIkit/atoms/RichText/Plugins/ToolbarPlugin/ToolbarPlugin.types';

export const getDefaultToolbarListeners = (patientId: number): ToolbarPluginListeners => ({
  onBoldClick: (currentValue) =>
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.Bold,
      value: currentValue ? 'on' : 'off',
      patient_id: patientId
    }),
  onItalicClick: (currentValue) =>
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.Italic,
      value: currentValue ? 'on' : 'off',
      patient_id: patientId
    }),
  onUndoClick: () =>
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.Undo,
      patient_id: patientId
    }),
  onRedoClick: () =>
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.Redo,
      patient_id: patientId
    }),
  onListClick: (currentValue) =>
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.BulletedList,
      value: currentValue ? 'on' : 'off',
      patient_id: patientId
    }),
  onOrderedListClick: (currentValue) =>
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.NumberedList,
      value: currentValue ? 'on' : 'off',
      patient_id: patientId
    })
});
