// @ts-strict-ignore
import { FC, forwardRef, ReactNode, SyntheticEvent } from 'react';

import { noop } from 'lodash';

import { Testable } from 'utils/TypeUtils';

import Icon from 'components/Icons/Icon';

import './SplitButton.scss';

interface SplitButtonWrapperProps {
  children: ReactNode;
  onClick: () => void;
}

export const SplitButtonWrapper: FC<SplitButtonWrapperProps> = ({ children, onClick }) => {
  return (
    <div className="split-button" onClick={onClick}>
      {children}
    </div>
  );
};

interface SplitButtonMainProps extends Testable {
  children: ReactNode;
  disabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
}

const SplitButtonMain: FC<SplitButtonMainProps> = ({ disabled, onClick, testHook, children }) => {
  return (
    <button
      className="split-button-main"
      disabled={disabled}
      data-test-hook={testHook}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      {children}
    </button>
  );
};

interface ISplitButtonChevron extends Testable {
  disabled?: boolean;
  onClick?: () => void;
}

const SplitButtonOpenEllipsis = forwardRef<HTMLButtonElement, ISplitButtonChevron>(
  ({ disabled, testHook, onClick = noop }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        className="split-button-chevron"
        disabled={disabled}
        data-test-hook={testHook}
      >
        <Icon.OpenEllipsis />
      </button>
    );
  }
);

const SplitButton = {
  Wrapper: SplitButtonWrapper,
  Main: SplitButtonMain,
  OpenEllipsis: SplitButtonOpenEllipsis
};
export default SplitButton;
