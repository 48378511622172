// @ts-strict-ignore
import { FC } from 'react';

import { FieldError } from 'react-hook-form';

import './FormErrors.scss';

export type FormErrorsType = Record<string, FieldError> | string[] | null;

function getErrorMessages(errors: FormErrorsType): string[] {
  if (errors === null) {
    return [];
  }
  if (Array.isArray(errors)) {
    return errors;
  }
  return Object.values(errors).map((error) => error.message);
}

interface Props {
  errors: FormErrorsType;
}

export const FormErrors: FC<Props> = ({ errors }) => {
  const errorMessages = getErrorMessages(errors);

  if (!errorMessages.length) {
    return null;
  }

  return (
    <div className="error-messages">
      {errorMessages.map((error, index) => (
        <div
          data-test-hook={`formError_${index}`}
          key={error}
          className="error-message bouncy-animation"
        >
          {error}
        </div>
      ))}
    </div>
  );
};
