import { FC, useRef } from 'react';

import { css, styled } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { Testable } from 'utils/TypeUtils';

import { isModalOpen } from 'utils/ViewUtils';

import { FEATURES } from 'constants/features';

import { MentionsInput, Mention, SuggestionDataItem } from './ReactMentions';
import { inputStyle } from './shared.styles';

const COMMENT_DEFAULT_PLACEHOLDER = 'Add a comment...';
const MENTION_PLACEHOLDER = 'Type @ to mention and notify someone.';

interface Props extends Testable {
  name: string;
  options: (query: string, callback: (data: SuggestionDataItem[]) => void) => Promise<void>;
  isRequired?: boolean;
  disabled?: boolean;
  withSendButtonStyle?: boolean;
  grayBackground?: boolean;
  className?: string;
}

export const FormMentionableInput: FC<Props> = ({
  name,
  options,
  isRequired,
  disabled,
  withSendButtonStyle,
  grayBackground,
  className
}) => {
  const { control } = useFormContext();
  const { settingsStore } = useStores();
  const hasNotifications = settingsStore.hasFeature(FEATURES.NOTIFICATIONS);
  const focusPlaceholder = hasNotifications ? MENTION_PLACEHOLDER : COMMENT_DEFAULT_PLACEHOLDER;
  const wrapperRef = useRef<HTMLDivElement>();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired
      }}
      render={({ field }) => {
        return (
          <MentionsInputWrapper ref={wrapperRef} className={className}>
            <StyledMentionsInput
              withSendButtonStyle={withSendButtonStyle}
              grayBackground={grayBackground}
              inputRef={field.ref}
              className={MENTION_CLASS}
              placeholder={COMMENT_DEFAULT_PLACEHOLDER}
              disabled={disabled}
              onChange={(event) => {
                // this event is a modified object created by react-mentions
                field.onChange(event);
              }}
              onFocus={(e) => {
                e.target.placeholder = focusPlaceholder;
              }}
              onBlur={(e) => (e.target.placeholder = COMMENT_DEFAULT_PLACEHOLDER)}
              value={field.value || ''}
              allowSuggestionsAboveCursor
              allowSpaceInQuery
              suggestionsPortalHost={isModalOpen() ? wrapperRef.current : null}
            >
              <Mention
                trigger="@"
                data={options}
                markup="[[@doctorId=__id__][@doctorName=__display__]]"
                appendSpaceOnAdd
                className="mention"
              />
            </StyledMentionsInput>
          </MentionsInputWrapper>
        );
      }}
    />
  );
};

export const MENTION_CLASS = 'mentionable-input';

export const StyledMentionsInput = styled(MentionsInput, {
  shouldForwardProp: (prop) => prop !== 'withSendButtonStyle' && prop !== 'grayBackground'
})<{
  withSendButtonStyle?: boolean;
  grayBackground?: boolean;
}>(({ theme, withSendButtonStyle, grayBackground }) => {
  const textAreaPadding = withSendButtonStyle ? theme.spacing(16, 40, 16, 16) : theme.spacing(16);
  const inputBackground = grayBackground
    ? theme.palette.natural.contrastDark
    : theme.palette.natural.white;
  // based on react-mentions internal classes, see https://github.com/signavio/react-mentions/blob/master/demo/src/examples/example.module.css
  return css`
    // wraps the text area and the and the highlighter
    .${MENTION_CLASS}__control {
      // the text-area itself
      .${MENTION_CLASS}__input {
        ${inputStyle(theme)}
        padding: ${textAreaPadding};
        border-radius: ${theme.borderRadius.large};
        background-color: ${inputBackground} !important;
        ${withSendButtonStyle &&
        css`
          border-bottom-left-radius: 0;
        `}
      }
      // highlighter contain the highlighted mention (displayed on top of the actual text-area)
      .${MENTION_CLASS}__highlighter {
        padding: ${textAreaPadding};
        .mention {
          position: relative;
          z-index: 1;
          color: ${theme.palette.text.primary};
          // keep transparent edges so caret is not hidden
          background: radial-gradient(
            circle at center,
            ${theme.palette.natural.border} 90%,
            transparent 100%
          );
        }
      }
    }
  `;
});

const MentionsInputWrapper = styled('div')(
  ({ theme }) => css`
    width: 100%;
    // some react-mentions classed overridden by the wrapper, since suggestionsPortalHost is used
    .${MENTION_CLASS}__suggestions {
      border-radius: ${theme.borderRadius.small};
      position: relative;
    }
    .${MENTION_CLASS}__suggestions__list {
      border-radius: ${theme.borderRadius.small};
      box-shadow: ${theme.boxShadow.common};
      max-height: ${theme.menu.maxHeight};
      width: ${theme.menu.maxWidth};
      overflow: auto;

      .${MENTION_CLASS}__suggestions__item {
        padding: ${theme.spacing(8, 12)};
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
      .${MENTION_CLASS}__suggestions__item--focused {
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.natural.white};
      }
    }
  `
);
