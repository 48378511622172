import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { CmMultipleSelectFilterFieldProps } from 'views/Filters/CareManagementReportFilters';

import { CmFilterMultiAutocomplete } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/CmFilterMultiAutocomplete/CmFilterMultiAutocomplete';

const ProvidersFilterField = observer(
  ({ value, onChange, selectedTableRowsCount }: CmMultipleSelectFilterFieldProps<string>) => {
    const { careManagementReportStore } = useStores();
    return (
      <CmFilterMultiAutocomplete
        label="Primary Provider"
        options={careManagementReportStore.providersForCmSelect}
        testHook="providers-filter"
        value={value}
        onChange={onChange}
        selectedTableRowsCount={selectedTableRowsCount}
      />
    );
  }
);

export default ProvidersFilterField;
