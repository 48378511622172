import React from 'react';

import Chip from 'components/Chips/Chip';
import AdditionalAlertTags from 'components/Ticket/TicketRow/AdditionalAlertTags';
import AlertTag, { Tag } from 'components/Ticket/TicketRow/AlertTag';

interface Props {
  tags: Tag[];
}

const AlertTags: React.FC<Props> = ({ tags }) => {
  let displayedTags = [...tags];
  let additionalTags: Tag[] = [];

  if (tags.length > 2) {
    displayedTags = [tags[0]];
    additionalTags = tags.slice(1);
  }

  return (
    <Chip.List>
      {displayedTags.map((tag) => (
        <AlertTag key={`${tag.prefix}: ${tag.text}`} tag={tag} />
      ))}
      {additionalTags.length > 0 && <AdditionalAlertTags tags={additionalTags} />}
    </Chip.List>
  );
};

export default AlertTags;
