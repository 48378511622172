// @ts-strict-ignore

import React, { ComponentType, FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { Dialogs } from 'analytics/events/dialog';
import classNames from 'classnames';
import {
  isEqual,
  isFunction,
  isNaN,
  isNil,
  keyBy,
  map,
  omit,
  omitBy,
  orderBy,
  overSome,
  pick,
  pickBy
} from 'lodash/fp';
import { observer } from 'mobx-react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { components } from 'react-select';
import { MultiValueRemoveProps } from 'react-select/dist/declarations/src/components/MultiValue';
import { useMap } from 'react-use';

import { isAllDigits } from 'utils/ValidationUtils';

import RegimenTemplate, { StomachStatus } from 'models/RegimenTemplate';

import { AddCircleIcon } from 'views/Widgets/icons/AddCircle';
import { RemoveCircleIcon } from 'views/Widgets/icons/RemoveCircle';
import { RHFStyledInput } from 'views/Widgets/StyledInput';
import { ISelectOption, RHFTagsInput } from 'views/Widgets/StyledSelect';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import './RegimenEditorModal.scss';

interface Props {
  onSave: (fields: RegimenTemplate) => void;
  onCancel: () => void;
  regimen: RegimenTemplate;
  regimenTemplate: RegimenTemplate;
  isOpen: boolean;
}

interface EditableFieldRenderProps {
  isEditing: boolean;
}

export interface DynamicFieldConfig {
  name: string;
  label: ReactNode;
  readonly?: boolean;
  isEditing?: boolean;
  render: ReactNode | ComponentType<EditableFieldRenderProps>;
  valueSetter?: (...args: any) => any;
  className?: string;
}

type OnToggle = (id: string | number) => void;

const MAX_DAYS_ON_OFF = 100;
const MIN_DAYS_ON_OFF = 1;

const useEditableForm = (
  fieldsConfig: DynamicFieldConfig[],
  fieldsConfigDefaults: DynamicFieldConfig[]
) => {
  const [editStateMap, { set: setRowsState, get: getRowStateById, setAll }] = useMap(
    keyBy('name', fieldsConfig)
  );

  const resetToDefault = useCallback(() => {
    setAll(keyBy('name', fieldsConfigDefaults));
  }, [fieldsConfigDefaults, setAll]);

  const getRowConfig = useCallback((id: number) => getRowStateById(id), [getRowStateById]);

  const onToggleItem = useCallback(
    (id?: number) => {
      const item = getRowConfig(id);
      setRowsState(id, {
        ...item,
        isEditing: !item.readonly && !item.isEditing
      });
    },
    [getRowConfig, setRowsState]
  );

  const orderedFields = useMemo(() => {
    const aggregatedFields = fieldsConfig.map((fieldConfig) => {
      const { name } = fieldConfig;
      // @ts-ignore - old oral-onco code left untouched until upgrade to react18
      const { isEditing = false, readonly = false } = getRowConfig(name);
      return {
        ...fieldConfig,
        isEditing,
        readonly
      };
    });

    // Readonly and edited fields always on top. These 'modes' take precedence of order in config given.
    // When 2 fields have same 'mode' original order in config given wins
    return orderBy(['readonly', 'isEditing'], ['desc', 'desc'], aggregatedFields);
  }, [fieldsConfig, getRowConfig]);

  const isDefaultEditState = useMemo(() => {
    const editFieldsPicker = pick(['readonly', 'isEditing', 'name']);
    const defaultEditState = keyBy('name', map(editFieldsPicker, fieldsConfigDefaults));
    const currentEditState = keyBy('name', map(editFieldsPicker, editStateMap));

    return isEqual(defaultEditState, currentEditState);
  }, [editStateMap, fieldsConfigDefaults]);

  return {
    onToggleItem,
    orderedFields,
    editState: editStateMap,
    resetEditState: resetToDefault,
    isDefaultEditState
  };
};

const EditableFormRow: FC<{
  children: any;
  isEditing?: boolean;
  readonly?: boolean;
  onToggle: OnToggle;
  id: string | number;
  label?: ReactNode;
  className?: string;
}> = ({ isEditing, readonly, onToggle, children, id, label, className }) => {
  let IconContainer = AddCircleIcon;

  if (readonly) {
    IconContainer = RemoveCircleIcon;
  }

  if (isEditing) {
    IconContainer = RemoveCircleIcon;
  }

  const shouldShowFormField = isEditing || readonly;
  const classes = classNames('d-flex align-items-end', className, {
    editable: !readonly
  });
  const fieldClasses = classNames({
    'd-none': !shouldShowFormField
  });
  const iconClasses = classNames('mr-2 icon-container', {
    'field-visible': shouldShowFormField,
    editable: !readonly
  });

  return (
    <div className={classes} onClick={shouldShowFormField ? null : () => onToggle(id)}>
      <div className={iconClasses} onClick={shouldShowFormField ? () => onToggle(id) : null}>
        <IconContainer disabled={readonly} />
      </div>
      {!shouldShowFormField && label}
      <div className={fieldClasses}>{children}</div>
    </div>
  );
};

const EditableForm = ({
  fields,
  onToggleItem
}: {
  fields: DynamicFieldConfig[];
  onToggleItem: OnToggle;
}) => (
  <>
    {fields.map(({ name, label, render, className, readonly, isEditing }) => (
      <EditableFormRow
        id={name}
        key={name}
        onToggle={onToggleItem}
        readonly={readonly}
        isEditing={isEditing}
        label={label}
        className={className}
      >
        {isFunction(render) ? render({ isEditing }) : render}
      </EditableFormRow>
    ))}
  </>
);

const stomachStatusOptions = [
  { value: StomachStatus.Full, label: 'With Food' },
  { value: StomachStatus.Empty, label: 'On an empty stomach' }
];

interface RegimenEditorFormFields {
  name: string;
  timesPerDay: number;
  daysOn?: number;
  daysOff?: number;
  stomachStatus?: ISelectOption<StomachStatus>;
  foodLimitations?: ISelectOption<string>[];
  minHoursBetweenDoses?: number;
}

const EditableFormLabel: FC<{ children: ReactNode }> = ({ children }) => {
  return <span className="editable-form-label">{children}</span>;
};

interface RemoveFoodTypeProps {
  data: ISelectOption<any>;
  innerProps: any;
}

const RemoveFoodType: FC<RemoveFoodTypeProps> = (props: MultiValueRemoveProps<any>) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const { MultiValueRemove, CrossIcon } = components;
  const onRemoveClick = () => {
    setShowWarning(true);
  };

  return (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <MessageDialog
        id={Dialogs.DeleteFoodsToAvoid}
        isOpen={showWarning}
        title={`Delete "${props.data.label}"?`}
        handleClose={() => setShowWarning(false)}
        primaryActionProps={{ text: 'Delete', onClick: props.innerProps.onClick as any }}
        secondaryActionProps={{ text: 'Cancel', onClick: () => setShowWarning(false) }}
      >
        Are you sure you would like to delete this item from the list of foods to avoid?
      </MessageDialog>
      <MultiValueRemove {...props} innerProps={{ ...props.innerProps, onClick: onRemoveClick }}>
        <CrossIcon />
      </MultiValueRemove>
    </div>
  );
};

const isEditingDigits = (isEditing: boolean) => (value: string) => {
  return isEditing ? isAllDigits(String(value)) : null;
};

const daysOnOffValueSetter = (value: number | undefined) => {
  return value < MIN_DAYS_ON_OFF || value > MAX_DAYS_ON_OFF ? null : value;
};

const getRegimenFieldsConfig = (
  regimen: RegimenTemplate,
  methods: UseFormReturn<RegimenEditorFormFields>
) => {
  const { register, control, getValues, setValue } = methods;

  return [
    {
      name: 'timesPerDay',
      label: <EditableFormLabel>Times Per Day</EditableFormLabel>,
      readonly: true,
      render: (
        <RHFStyledInput
          name="timesPerDay"
          type="number"
          register={register}
          error={Boolean(methods.formState.errors.timesPerDay)}
          validate={(value: number) => isAllDigits(String(value))}
          label="Times Per Day"
          isRequired
          min={1}
          max={10}
          onChange={(event) => {
            if (Number((event.target as HTMLInputElement).value) === 1) {
              setValue('minHoursBetweenDoses', null, { shouldValidate: true });
            }
          }}
        />
      ),
      className: 'regimen-editor-row'
    },
    {
      name: 'daysOnOff',
      label: <EditableFormLabel>Days On/Off</EditableFormLabel>,
      isEditing: !isNil(regimen?.daysOn) && !isNil(regimen?.daysOff),
      render: (props: EditableFieldRenderProps) => (
        <div className="d-flex days-row">
          <RHFStyledInput
            name="daysOn"
            type="number"
            register={register}
            error={Boolean(methods.formState.errors.daysOn)}
            validate={isEditingDigits(props.isEditing)}
            label="Days On"
            min={MIN_DAYS_ON_OFF}
            max={MAX_DAYS_ON_OFF}
          />
          <RHFStyledInput
            name="daysOff"
            type="number"
            register={register}
            error={Boolean(methods.formState.errors.daysOff)}
            validate={isEditingDigits(props.isEditing)}
            label="Days Off"
            min={MIN_DAYS_ON_OFF}
            max={MAX_DAYS_ON_OFF}
          />
        </div>
      ),
      valueSetter: (
        valuesToSubmit: Partial<RegimenEditorFormFields>,
        rawForm: RegimenEditorFormFields
      ) => ({
        ...valuesToSubmit,
        daysOn: daysOnOffValueSetter(rawForm.daysOn),
        daysOff: daysOnOffValueSetter(rawForm.daysOff)
      }),
      className: 'regimen-editor-row'
    },
    {
      name: 'stomachStatus',
      label: <EditableFormLabel>With or Without Food</EditableFormLabel>,
      isEditing: !isNil(regimen?.stomachStatus),
      render: (
        <FormAutocomplete
          name="stomachStatus"
          options={stomachStatusOptions}
          label="With or Without Food"
        />
      ),
      valueSetter: (
        valuesToSubmit: Partial<RegimenEditorFormFields>,
        rawForm: RegimenEditorFormFields
      ) => ({
        ...valuesToSubmit,
        stomachStatus: rawForm.stomachStatus?.value
      }),
      className: 'regimen-editor-row'
    },
    {
      name: 'foodLimitations',
      label: <EditableFormLabel>Foods to Avoid</EditableFormLabel>,
      isEditing: !isNil(regimen?.foodLimitations),
      render: (
        <RHFTagsInput
          control={control}
          name="foodLimitations"
          register={register}
          label="Foods to Avoid"
          placeholder="Use Enter to Save Items"
          isClearable={false}
          backspaceRemovesValue={false}
          components={{
            MultiValueRemove: RemoveFoodType
          }}
        />
      ),
      valueSetter: (
        valuesToSubmit: Partial<RegimenEditorFormFields>,
        rawForm: RegimenEditorFormFields
      ) => {
        return {
          ...valuesToSubmit,
          foodLimitations: rawForm.foodLimitations?.map(
            (option: ISelectOption<string>) => option.value
          )
        };
      },
      className: 'regimen-editor-row'
    },
    {
      name: 'minHoursBetweenDoses',
      label: <EditableFormLabel>Hours Between Doses</EditableFormLabel>,
      readonly: true,
      render: (
        <RHFStyledInput
          name="minHoursBetweenDoses"
          type="number"
          disabled={getValues('timesPerDay') === 1}
          error={Boolean(methods.formState.errors.minHoursBetweenDoses)}
          step={0.5}
          min={1}
          max={24}
          register={register}
          label="Hours Between Doses"
        />
      ),
      className: 'regimen-editor-row'
    }
  ];
};

const getDefaultRegimenEditorValues = (regimen: RegimenTemplate) => {
  return {
    timesPerDay: isNil(regimen?.timesPerDay) ? null : regimen?.timesPerDay,
    daysOn: isNil(regimen?.daysOn) ? null : regimen?.daysOn,
    daysOff: isNil(regimen?.daysOff) ? null : regimen?.daysOff,
    stomachStatus:
      stomachStatusOptions.find((option) => option.value === regimen?.stomachStatus) || null,
    foodLimitations:
      regimen?.foodLimitations?.map((foodLimitation: string) => ({
        value: foodLimitation,
        label: foodLimitation
      })) || null,
    minHoursBetweenDoses: isNil(regimen?.minHoursBetweenDoses)
      ? null
      : regimen?.minHoursBetweenDoses
  };
};

const formValuesToRegimen = (formValues: RegimenEditorFormFields) => ({
  ...formValues,
  stomachStatus: formValues.stomachStatus?.value || null,
  foodLimitations: formValues.foodLimitations?.map((limitation) => limitation.value) || null
});

const RegimenEditorModal: FC<Props> = ({ regimen, regimenTemplate, onSave, onCancel, isOpen }) => {
  const defaultValues = getDefaultRegimenEditorValues(regimen);
  const defaultTemplateValues = getDefaultRegimenEditorValues(regimenTemplate);

  const methods = useForm<RegimenEditorFormFields>();

  const { handleSubmit, reset: resetForm, watch } = methods;
  const values = watch();

  const fieldsConfig = getRegimenFieldsConfig(regimen, methods);
  const templatesDefaultFieldsConfig = getRegimenFieldsConfig(regimenTemplate, methods);

  const { onToggleItem, orderedFields, editState, resetEditState, isDefaultEditState } =
    useEditableForm(fieldsConfig, templatesDefaultFieldsConfig);

  const isRevertToDefaultsEnabled = useMemo(() => {
    const currentFormValues = formValuesToRegimen(values);
    const regimenTemplateValues = omit(['id', 'name', 'isCustom'], regimenTemplate?.toJSON());

    return !isEqual(currentFormValues, regimenTemplateValues) || !isDefaultEditState;
  }, [values, regimenTemplate, isDefaultEditState]);

  const onSubmit = useCallback(
    (regimenValues: RegimenEditorFormFields) => {
      const fieldsConfigToSubmit = pickBy((state) => {
        return state.isEditing || state.readonly;
      }, editState);

      let valuesToSubmit: any = {};

      // Take only edited fields
      Object.keys(fieldsConfigToSubmit).forEach((key: keyof RegimenEditorFormFields) => {
        if (fieldsConfigToSubmit[key].valueSetter) {
          valuesToSubmit = fieldsConfigToSubmit[key].valueSetter(valuesToSubmit, regimenValues);
        } else {
          valuesToSubmit[key] = regimenValues[key];
        }
      });

      // Remove fields that were open for edit but not filled
      const isFieldEmpty = overSome([isNil, isNaN]);

      const finalFieldsToSubmit = omitBy(isFieldEmpty, valuesToSubmit);
      const customRegimen = new RegimenTemplate(finalFieldsToSubmit);
      customRegimen.isCustom = isRevertToDefaultsEnabled;
      customRegimen.name = regimen?.name;
      onSave(customRegimen);
    },
    [editState, isRevertToDefaultsEnabled, onSave, regimen?.name]
  );

  const revert = () => {
    resetForm(defaultTemplateValues);
    resetEditState();
  };

  const title = `${regimen?.name || ''} Regimen`;

  return (
    <FormModal
      methods={methods}
      defaultValues={defaultValues}
      isOpen={isOpen}
      confirmActions={[{ onClick: handleSubmit(onSubmit), text: 'Save', disabled: false }]}
      closeAction={{ onClick: onCancel, disabled: false }}
      title={title}
      secondaryAction={{
        type: 'button',
        onClick: revert,
        text: 'Revert to Regimen Defaults',
        disabled: !isRevertToDefaultsEnabled
      }}
    >
      <div className="regimen-editor-modal">
        <div className="regimen-form-container">
          <EditableForm fields={orderedFields} onToggleItem={onToggleItem} />
        </div>
      </div>
    </FormModal>
  );
};

export default observer(RegimenEditorModal);
