// @ts-strict-ignore
import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';

import { observer } from 'mobx-react';

import { useMount } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { CmStatusEnum } from 'fetchers/responses/care-management.response';

import history from 'services/history';

import { CM_INELIGIBILITY, CM_OPT_OUT } from 'constants/reasons.const';

import { CmPatient } from 'models/CmPatient';
import Patient from 'models/Patient';

import { useToggle } from 'hooks/useToggle';

import CareManagementModals from 'views/Pages/CareManagement/CareManagementModals';
import { EnrollEpisodeModal } from 'views/Pages/EpisodesManagement/EnrollEpisodeModal';

import { EnrollmentStatusDropDown } from './EnrollmentStatusDropDown';

// eslint-disable-next-line import/order
import { trackEnrollEpisodeModalAnalyticsEvent } from 'analytics/events/enroll-episode-modal';

export enum WarningTypes {
  OptOutWhenActive = 'optOutWhenActive',
  MarkIneligibleWhenOptedOut = 'markIneligibleWhenOptedOut',
  MarkEligibleOrEnrollWhenIneligible = 'markEligibleOrEnrollWhenIneligible',
  EnrollWhenEnded = 'enrollWhenEnded',
  EnrollWhenOptedOut = 'enrollWhenOptedOut',
  RemoveEnrollWhenOptedOut = 'removeEnrollWhenOptedOut',
  RemoveEnrollWhenIneligible = 'removeEnrollWhenIneligible'
}

export type REASON_TYPE_CM = 'cm_ineligibility' | 'cm_opt_out';

type CareManagementEnrollmentContextType = {
  getEnrollmentDropdown: (patient: CmPatient) => ReactNode;
  handlePatientClick: (patient: CmPatient) => Promise<void>;
  isRedirectingToPatientPage: boolean;
};

export const CareManagementEnrollmentContext =
  createContext<CareManagementEnrollmentContextType>(null);

interface Props {
  children: ReactNode;
  onUpdateStatus: () => void;
}

export const CareManagementEnrollmentProvider: FC<Props> = observer(
  ({ onUpdateStatus, children }) => {
    const { careManagementReportStore, patientPageStore, patientEpisodesStore, reasonsStore } =
      useStores();
    const { isOpen: isEnrollEpisodeOpen, toggle: toggleIsEnrollEpisodeOpen } = useToggle(false);
    const [activePatient, setActivePatient] = useState<CmPatient>();
    // needed for enrolling
    const [activeExpainPatient, setActiveExpainPatient] = useState<Patient>();
    const [warningType, setWarningType] = useState(null);
    const [eligibilityStatus, setEligibilityStatus] = useState<
      CmStatusEnum.EligibleForPcm | CmStatusEnum.EligibleForCcm
    >(null);
    const [reasonType, setReasonType] = useState<REASON_TYPE_CM>(null);
    const [isCmSelectReasonModalOpen, setIsCmSelectReasonModalOpen] = useState(false);
    const [episodeId, setEpisodeId] = useState(null);
    const [isRedirectingToPatientPage, setIsRedirectingToPatientPage] = useState(false);

    useMount(function fetchCmReasons() {
      if (!reasonsStore.isInitialized) {
        reasonsStore.fetchCareManagementReasons();
      }
    });

    const getPatientId = useCallback(
      async (patient: CmPatient) => {
        return patient.patientId || (await patientPageStore.createPatientFromEMR(patient.id));
      },
      [patientPageStore]
    );

    const checkIfCanMarkIneligible = useCallback((patient: CmPatient) => {
      setActivePatient(patient);
      if (patient.cmStatus.isOptedOut) {
        setWarningType(WarningTypes.MarkIneligibleWhenOptedOut);
        return;
      }

      setReasonType(CM_INELIGIBILITY);
      setIsCmSelectReasonModalOpen(true);
    }, []);

    const checkIfCanOptOut = useCallback((patient: CmPatient) => {
      setActivePatient(patient);
      if (patient.cmStatus.isActive) {
        setWarningType(WarningTypes.OptOutWhenActive);
        return;
      }

      setReasonType(CM_OPT_OUT);
      setIsCmSelectReasonModalOpen(true);
    }, []);

    const markPatientEligible = useCallback(
      async (
        patient: CmPatient,
        eligibilityStatus: CmStatusEnum.EligibleForCcm | CmStatusEnum.EligibleForPcm
      ) => {
        const patientId = await getPatientId(patient);
        await careManagementReportStore.updateStatus(
          patientId,
          { status: eligibilityStatus },
          onUpdateStatus
        );
        setEligibilityStatus(null);
        setActivePatient(null);
      },
      [careManagementReportStore, getPatientId, onUpdateStatus]
    );

    const removePatientEnroll = useCallback(
      async (patient: CmPatient) => {
        const patientId = await getPatientId(patient);
        await careManagementReportStore.updateStatus(
          patientId,
          { status: CmStatusEnum.None },
          onUpdateStatus
        );
        setActivePatient(null);
      },
      [careManagementReportStore, getPatientId, onUpdateStatus]
    );

    const checkIfCanMarkEligible = useCallback(
      (
        patient: CmPatient,
        eligibilityStatus: CmStatusEnum.EligibleForCcm | CmStatusEnum.EligibleForPcm
      ) => {
        setActivePatient(patient);
        if (patient.cmStatus.isIneligible) {
          setWarningType(WarningTypes.MarkEligibleOrEnrollWhenIneligible);
          setEligibilityStatus(eligibilityStatus);
          return;
        }

        markPatientEligible(patient, eligibilityStatus);
      },
      [markPatientEligible]
    );

    const setPatientModelFromCmPatient = useCallback(
      async (cmPatient: CmPatient) => {
        const patientId = await getPatientId(cmPatient);
        const { patient: fullPatient } = await patientPageStore.fetchPatient(patientId);
        await patientEpisodesStore.fetchPatientEpisodes(patientId);
        setActiveExpainPatient(fullPatient);
        trackEnrollEpisodeModalAnalyticsEvent({
          action: AnalyticEventAction.Open,
          patient_id: fullPatient.id
        });
        toggleIsEnrollEpisodeOpen();
      },
      [getPatientId, patientEpisodesStore, patientPageStore, toggleIsEnrollEpisodeOpen]
    );

    const enrollPatientInEpisode = useCallback(
      async (cmPatient: CmPatient) => {
        await setPatientModelFromCmPatient(cmPatient);
      },
      [setPatientModelFromCmPatient]
    );

    const checkIfCanRemoveEnroll = useCallback(
      (cmPatient: CmPatient) => {
        setActivePatient(cmPatient);
        if (cmPatient.cmStatus.isIneligible) {
          setWarningType(WarningTypes.RemoveEnrollWhenIneligible);
          return;
        }
        if (cmPatient.cmStatus.isOptedOut) {
          setWarningType(WarningTypes.RemoveEnrollWhenOptedOut);
          return;
        }
        removePatientEnroll(cmPatient);
      },
      [removePatientEnroll]
    );

    const checkIfCanEnroll = useCallback(
      (cmPatient: CmPatient, episodeId: number) => {
        setActivePatient(cmPatient);
        setEpisodeId(episodeId);
        if (cmPatient.cmStatus.isEndedManually) {
          setWarningType(WarningTypes.EnrollWhenEnded);
          return;
        }

        if (cmPatient.cmStatus.isIneligible) {
          setWarningType(WarningTypes.MarkEligibleOrEnrollWhenIneligible);
          return;
        }

        if (cmPatient.cmStatus.isOptedOut) {
          setWarningType(WarningTypes.EnrollWhenOptedOut);
          return;
        }
        enrollPatientInEpisode(cmPatient);
      },
      [enrollPatientInEpisode]
    );

    const resetEnrollState = () => {
      setActiveExpainPatient(null);
      setActivePatient(null);
      setEpisodeId(null);
    };

    const handlePatientClick = useCallback(
      async (patient: CmPatient) => {
        setIsRedirectingToPatientPage(true);

        try {
          const patientId = await getPatientId(patient);
          const path = `/patient/${patientId}?tab=episodes_and_tasks`;
          history.push(path);
        } finally {
          setIsRedirectingToPatientPage(false);
        }
      },
      [getPatientId]
    );

    const handlers = useMemo(
      () => ({
        checkIfCanMarkEligible,
        checkIfCanMarkIneligible,
        checkIfCanOptOut,
        checkIfCanEnroll,
        handlePatientClick,
        checkIfCanRemoveEnroll
      }),
      [
        checkIfCanEnroll,
        checkIfCanMarkEligible,
        checkIfCanMarkIneligible,
        checkIfCanOptOut,
        checkIfCanRemoveEnroll,
        handlePatientClick
      ]
    );

    const getEnrollmentDropdown = useCallback(
      (cmPatient: CmPatient) => (
        <EnrollmentStatusDropDown patient={cmPatient} handlers={handlers} />
      ),
      [handlers]
    );

    return (
      <CareManagementEnrollmentContext.Provider
        value={{
          getEnrollmentDropdown,
          handlePatientClick,
          isRedirectingToPatientPage
        }}
      >
        <EnrollEpisodeModal
          isOpen={isEnrollEpisodeOpen}
          onSubmit={() => {
            onUpdateStatus();
            toggleIsEnrollEpisodeOpen();
          }}
          patient={activeExpainPatient}
          onCancel={toggleIsEnrollEpisodeOpen}
          resetDataAfterClose={resetEnrollState}
          episodeId={episodeId}
        />
        {activePatient && (
          <CareManagementModals
            patient={activePatient}
            setActivePatient={setActivePatient}
            warningType={warningType}
            setWarningType={setWarningType}
            reasonType={reasonType}
            setReasonType={setReasonType}
            eligibilityStatus={eligibilityStatus}
            setEligibilityStatus={setEligibilityStatus}
            markPatientEligible={markPatientEligible}
            episodeId={episodeId}
            setEpisodeId={setEpisodeId}
            enrollPatientInEpisode={enrollPatientInEpisode}
            getPatientId={getPatientId}
            onUpdateStatus={onUpdateStatus}
            isCmSelectReasonModalOpen={isCmSelectReasonModalOpen}
            toggleCmSelectReasonModalOpen={() =>
              setIsCmSelectReasonModalOpen((prevState) => !prevState)
            }
          />
        )}
        {children}
      </CareManagementEnrollmentContext.Provider>
    );
  }
);

export const useCareManagementEnrollmentContext = () => {
  const context = useContext(CareManagementEnrollmentContext);
  if (context === undefined) {
    throw new Error(
      'useCareManagementEnrollmentContext must be used within a EpisodeEditCreateProvider'
    );
  }
  return context;
};
