import { FC } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  isOpen: boolean;
  blockUuid: string;
  onRemove: () => void;
  onCancel: () => void;
}

export const RemoveBlockPopup: FC<Props> = ({ blockUuid, onRemove, onCancel, isOpen }) => (
  <MessageDialog
    id={Dialogs.RemovePathwayBlock}
    isOpen={isOpen}
    title="Remove from Pathway"
    handleClose={onCancel}
    primaryActionProps={{ text: 'Remove', onClick: onRemove }}
    secondaryActionProps={{ text: 'Cancel', onClick: onCancel }}
  >
    If this is a question with sub-questions, they will also be deleted.
    <br />
    <br />
    This action cannot be undone.
  </MessageDialog>
);
