import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Open | AnalyticEventAction.Cancel | AnalyticEventAction.Save;

type Value = 'save with changes' | 'save without changes' | 'missing fields';

interface Properties {
  action: Action;
  value?: Value;
}

export const trackEditPatientModalAnalyticsEvent = ({
  action,
  ...optionalProperties
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.EditPatientModal,
    properties: { action, ...optionalProperties }
  });
};
