import { FC } from 'react';

import { Box, css, styled } from '@mui/material';
import { observer } from 'mobx-react';

import { useFormContext } from 'react-hook-form';
import { components } from 'react-select';

import { DependentQuestionsBlockTriggersChip } from 'views/Pages/PathwayBuilder/PathwayEditorView/DependentQuestionsBlockTriggersChip';

import { DependentQuestionsBlockTriggersOption } from 'views/Pages/PathwayBuilder/PathwayEditorView/DependentQuestionsBlockTriggersOption';

import { PathwayEditorFormFields } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import { removeHtmlTags } from 'views/Widgets/SanitizeHtml';

import { FormMultiAutocomplete } from 'components/UIkit/atoms/Dropdown';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  isBlockSelected: boolean;
  dependentQuestionsBlockPath: `categories.${number}.questions.${number}.dependentQuestionsBlocks.${number}`;
  questionOptions: { value: string; label: string }[];
}

export const DependentQuestionsBlockTriggers: FC<Props> = observer(
  ({ isBlockSelected, dependentQuestionsBlockPath, questionOptions }) => {
    const { getFieldState, watch } = useFormContext<PathwayEditorFormFields>();
    const fieldState = getFieldState(`${dependentQuestionsBlockPath}.triggers`);
    const isError = Boolean(fieldState.error);
    const triggers = watch(`${dependentQuestionsBlockPath}.triggers`);

    return (
      <>
        {(isBlockSelected || !Boolean(triggers)) && (
          <FormMultiAutocomplete
            name={`${dependentQuestionsBlockPath}.triggers`}
            options={questionOptions}
            displaySelectedSummary={false}
            label="Response Triggers for Sub-Question"
            isRequired
            autoFocus={!Boolean(triggers)}
            components={{
              Option: DependentQuestionsBlockTriggersOption,
              MultiValueLabel: DependentQuestionsBlockTriggersChip
            }}
            sortAlphabetically={false}
          />
        )}

        {!isBlockSelected && Boolean(triggers) && (
          <Box>
            <Text variant="body1" color={isError ? 'error' : 'primary'}>
              Response Triggers for Sub-Question
            </Text>

            <StyledInputPresentation isError={isError}>
              {triggers?.map((option) => (
                <StyledOptionTag key={option.value}>
                  <StyledXIcon width={14} />

                  <Text isEllipsis color="disabled">
                    {removeHtmlTags(option.label)}
                  </Text>
                </StyledOptionTag>
              ))}
            </StyledInputPresentation>
          </Box>
        )}
      </>
    );
  }
);

const StyledInputPresentation = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isError'
})<{ isError: boolean }>(
  ({ theme, isError }) => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 45px;
    width: 100%;
    padding: ${theme.spacing(0, 8)};
    border: 1px solid ${isError ? theme.palette.error.main : theme.palette.natural.border};
    border-radius: 0 ${theme.borderRadius.large} ${theme.borderRadius.large}
      ${theme.borderRadius.large};
  `
);

//tried to make it exactly like in our selects
const StyledOptionTag = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.large};
    border: none;
    padding-right: 11px;
    display: flex;
    align-items: center;
    background-color: ${theme.palette.natural.hover};
    color: ${theme.palette.text.disabled};
    margin: 2px 2px 3px 2px;
    padding-top: 1px;
    padding-bottom: 0;
    height: 32px;
    max-width: 100%;

    span {
      display: inline-block;
      line-height: 1.88;
      font-weight: 400;
      padding-left: 6px;
    }
  `
);

const StyledXIcon = styled(components.CrossIcon)(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 4)};
    height: 32px;
    margin-bottom: ${theme.spacing(2)};
  `
);
