import { Box, css, styled } from '@mui/material';

export const StyledQuestionCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    width: 100%;
    border-radius: ${theme.borderRadius.large};
    padding: ${theme.spacing(20)};
    background-color: ${theme.palette.natural.white};
    position: relative;
    border: 1px solid ${theme.palette.natural.border};

    &:hover {
      border-color: ${theme.palette.primary.main};
    }

    ${!isSelected &&
    css`
      cursor: pointer;
      * {
        cursor: pointer;
      }
    `}

    ${isSelected &&
    css`
      border-radius: 0 ${theme.borderRadius.large} ${theme.borderRadius.large} 0;
      border: 1px solid ${theme.palette.primary.main};
      box-shadow: ${theme.boxShadow.common};
      user-select: none;
    `}
  `
);

export const StyledDragIndicatorContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.natural.white};
    position: absolute;
    left: -24px;
    height: calc(100% + 2px);
    bottom: -1px;
    background-color: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
    border-radius: ${theme.borderRadius.large} 0 0 ${theme.borderRadius.large};
    cursor: grab;
    user-select: auto;

    &:hover {
      border-color: ${theme.palette.primary.main};
    }

    svg,
    svg * {
      cursor: grab;
      user-select: auto;
    }
  `
);

export const StyledNoPointerEventsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{
  isSelected?: boolean;
}>(
  ({ isSelected }) => css`
    pointer-events: ${isSelected ? 'all' : 'none'};
  `
);
