// @ts-strict-ignore
import { makeObservable, observable } from 'mobx';

import ObjectCopier from 'utils/ObjectCopier';

export enum CurrentIndicatorTypes {
  Y = 'Y',
  N = 'N'
}
export default class PatientProvider extends ObjectCopier {
  id: string;

  currentIndicator: CurrentIndicatorTypes;

  @observable
  name: string;

  constructor(provider?: any) {
    super();
    makeObservable(this);
    Object.assign(this, provider);
  }

  isActive = () => {
    return this.currentIndicator === CurrentIndicatorTypes.Y;
  };
}
