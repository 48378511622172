// @ts-strict-ignore
import { useLayoutEffect, useRef, useState } from 'react';

import { DurationInterval } from 'models/DurationInterval';

import { IntervalErrors } from './IntervalsEditor.shared';

const MISSING_START_OR_END_DATE = 'Missing start or end Date';
const START_DATE_END_DATE_EQUAL = 'Start date is the same as the end date';
const OVERLAPPING_INTERVALS = 'This interval overlaps with the highlighted intervals';
const START_TIME_IN_FUTURE = 'Start time cannot be in the future.';
const END_DATE_IN_FUTURE = 'End time cannot be in the future.';
const END_TIME_BEFORE_START_TIME = 'End time cannot be before start time.';
const MAX_INTERVALS_LIST_HEIGHT = 280;

export function calculateIntervalErrors(
  intervals: DurationInterval[],
  externalValidationErrors: Map<string, string>,
  lastEditedInterval?: DurationInterval
): IntervalErrors {
  const errorByUuid = new Map<string, string[]>();
  const overlappingIntervals = lastEditedInterval
    ? lastEditedInterval.findOverlappingIntervals(intervals)
    : [];

  intervals.forEach((interval) => {
    const errorList = calculateDateTimeErrors(interval);
    if (externalValidationErrors.has(interval.uniqueIdentifier)) {
      errorList.push(externalValidationErrors.get(interval.uniqueIdentifier));
    }

    if (interval === lastEditedInterval) {
      // check if last edited interval overlaps with others
      if (overlappingIntervals.length > 0) {
        errorList.push(OVERLAPPING_INTERVALS);
      }
    }

    // General error - will not be displayed on interval row
    if (!interval.startDate || !interval.endDate) {
      errorByUuid.set('some-interval-missing-date', [MISSING_START_OR_END_DATE]);
    }

    if (errorList.length > 0) {
      errorByUuid.set(interval.uniqueIdentifier, errorList);
    }
  });

  const isOverlapped = overlapArrayToSet(overlappingIntervals);
  return { errorByUuid, isOverlapped };
}

function calculateDateTimeErrors(interval: DurationInterval) {
  const errorList = [];
  const now = Date.now();

  if (interval.startDate && interval.endDate && interval.durationInSeconds === 0) {
    errorList.push(START_DATE_END_DATE_EQUAL);
  }

  if (interval.startDate?.getTime() > now) {
    errorList.push(START_TIME_IN_FUTURE);
  }

  if (interval.endDate?.getTime() > now) {
    errorList.push(END_DATE_IN_FUTURE);
  }
  if (interval.startDate && interval.endDate && interval.endDate < interval.startDate) {
    errorList.push(END_TIME_BEFORE_START_TIME);
  }
  return errorList;
}

function overlapArrayToSet(overlappingIntervals: DurationInterval[]) {
  const isOverlappedByUuid = new Set<string>();
  overlappingIntervals.forEach((interval: DurationInterval) => {
    isOverlappedByUuid.add(interval.uniqueIdentifier);
  });
  return isOverlappedByUuid;
}

export function useContainerHeight(layoutDeps: any) {
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useLayoutEffect(
    function getIntervalContainerHeight() {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.offsetHeight);
      }
    },
    [layoutDeps]
  );

  const containerStyle = getIntervalContainerStyle(containerHeight);

  return { containerRef, containerStyle };
}

function getIntervalContainerStyle(currentHeight: number) {
  return {
    maxHeight: MAX_INTERVALS_LIST_HEIGHT,
    overflow: currentHeight > MAX_INTERVALS_LIST_HEIGHT ? 'auto' : 'initial'
  };
}
