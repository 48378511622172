import { ListItemNode, ListNode } from '@lexical/list';
import { InitialConfigType } from '@lexical/react/LexicalComposer';

export const editorTheme = {
  list: {
    listitem: 'editor-list-item',
    nested: {
      listitem: 'editor-nested-list-item'
    },
    olDepth: [
      'editor-ol-first',
      'editor-ol-second',
      'editor-ol-third',
      'editor-ol-fourth',
      'editor-ol-fifth'
    ],
    ul: 'editor-ul',
    ol: 'editor-ol'
  },
  text: {
    bold: 'editor-bold',
    italic: 'editor-italic'
  }
};

export const editorConfig: Omit<InitialConfigType, 'namespace'> = {
  theme: editorTheme,
  onError(error: any) {
    throw error;
  },
  nodes: [ListNode, ListItemNode]
};

export const TOOLBAR_BUTTONS = {
  UNDO: 'toolbarUndo',
  REDO: 'toolbarRedo',
  BOLD: 'toolbarBold',
  ITALIC: 'toolbarItalic',
  LIST: 'toolbarList',
  ORDERED_LIST: 'toolbarOrderedList'
};

export const TOOLBAR_CONTAINER = 'toolbarContainer';

export const TOOLBAR_IDS = [...Object.values(TOOLBAR_BUTTONS), TOOLBAR_CONTAINER];
export const LIST_MAX_INDENT_ALLOWED = 7;
