// @ts-strict-ignore

import { RemainingDoseOption } from 'mobx/stores';

import {
  OralAnswerStructure,
  OralReportRegimenMeta,
  OralReportResponse,
  ReportQuestionAnswer
} from 'models/OralReport/OralReport.shared';

export type OralAnswerValueV2 = boolean | number | string | Date | RemainingDoseOption;

export enum OralQuestionTypeV2 {
  ActiveMedication = 'activeMedication',
  StartOfCycle = 'startOfCycle',
  RegimenAdherence = 'regimenAdherence',
  OtherMedicationChange = 'otherMedicationChange',
  MissedDoses = 'missedDoses',
  DosesRemaining = 'dosesRemaining',
  RefillRequest = 'refillRequest'
}

export class OralReportV2 implements OralReportRegimenMeta {
  startOfCycleDate: Date;
  regimen: string;
  isCustom: false;
  [OralQuestionTypeV2.ActiveMedication]: ReportQuestionAnswer<boolean, OralQuestionTypeV2>;
  [OralQuestionTypeV2.StartOfCycle]?: ReportQuestionAnswer<Date, OralQuestionTypeV2>;
  [OralQuestionTypeV2.RegimenAdherence]?: ReportQuestionAnswer<boolean, OralQuestionTypeV2>;
  [OralQuestionTypeV2.OtherMedicationChange]?: ReportQuestionAnswer<boolean, OralQuestionTypeV2>;
  [OralQuestionTypeV2.MissedDoses]?: ReportQuestionAnswer<number, OralQuestionTypeV2>;
  [OralQuestionTypeV2.DosesRemaining]?: ReportQuestionAnswer<
    RemainingDoseOption,
    OralQuestionTypeV2
  >;
  [OralQuestionTypeV2.RefillRequest]?: ReportQuestionAnswer<boolean, OralQuestionTypeV2>;
  previousReportDate?: Date;

  constructor(
    oralReportUnparsedResponse: OralReportResponse<OralAnswerValueV2, OralQuestionTypeV2>
  ) {
    Object.assign(this, oralReportUnparsedResponse);
    this.startOfCycleDate = oralReportUnparsedResponse?.startOfCycle
      ? new Date(oralReportUnparsedResponse.startOfCycle)
      : null;
    this.previousReportDate = oralReportUnparsedResponse?.previousReportDate
      ? new Date(oralReportUnparsedResponse.previousReportDate)
      : null;

    oralReportUnparsedResponse.reportItems.forEach((answer) => {
      switch (answer.questionType) {
        case OralQuestionTypeV2.StartOfCycle:
          answer.value = new Date(answer.value as string);
          this[OralQuestionTypeV2.StartOfCycle] = new ReportQuestionAnswer<
            Date,
            OralQuestionTypeV2
          >(answer as OralAnswerStructure<Date, OralQuestionTypeV2>);
          break;

        case OralQuestionTypeV2.ActiveMedication:
          this[OralQuestionTypeV2.ActiveMedication] = new ReportQuestionAnswer<
            boolean,
            OralQuestionTypeV2
          >(answer as OralAnswerStructure<boolean, OralQuestionTypeV2>, { value: true });
          break;

        case OralQuestionTypeV2.RegimenAdherence:
          this[OralQuestionTypeV2.RegimenAdherence] = new ReportQuestionAnswer<
            boolean,
            OralQuestionTypeV2
          >(answer as OralAnswerStructure<boolean, OralQuestionTypeV2>, { value: false });
          break;

        case OralQuestionTypeV2.OtherMedicationChange:
          this[OralQuestionTypeV2.OtherMedicationChange] = new ReportQuestionAnswer<
            boolean,
            OralQuestionTypeV2
          >(answer as OralAnswerStructure<boolean, OralQuestionTypeV2>, { value: false });
          break;

        case OralQuestionTypeV2.MissedDoses:
          this[OralQuestionTypeV2.MissedDoses] = new ReportQuestionAnswer<
            number,
            OralQuestionTypeV2
          >(answer as OralAnswerStructure<number, OralQuestionTypeV2>, { value: 0 });
          break;

        case OralQuestionTypeV2.DosesRemaining:
          this[OralQuestionTypeV2.DosesRemaining] = new ReportQuestionAnswer<
            RemainingDoseOption,
            OralQuestionTypeV2
          >(answer as OralAnswerStructure<RemainingDoseOption, OralQuestionTypeV2>, {
            value: RemainingDoseOption.OverAWeek
          });
          break;

        case OralQuestionTypeV2.RefillRequest:
          this[OralQuestionTypeV2.RefillRequest] = new ReportQuestionAnswer<
            boolean,
            OralQuestionTypeV2
          >(answer as OralAnswerStructure<boolean, OralQuestionTypeV2>, {
            value: false,
            customMessageExistenceValue: false
          });
          break;
      }
    });
  }

  get newCycleDate() {
    // if patient did not provide first pill date - use startOfCycle meta (can be update by a nurse)
    return this[OralQuestionTypeV2.StartOfCycle]?.isAnswered
      ? this[OralQuestionTypeV2.StartOfCycle].value
      : this.startOfCycleDate;
  }

  set cycle(date: Date) {
    this.startOfCycleDate = date;
  }
}
