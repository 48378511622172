import { FC, useEffect, useRef, useState } from 'react';

import { fromNow } from 'utils/DateUtils';

import { Testable } from 'utils/TypeUtils';

import Icon from 'components/Icons/Icon';
import { OutlinedIconSubtextButton } from 'components/UIkit/atoms/Button';

const UPDATE_INTERVAL_DELAY = 5000;
const DEFAULT_TIME_STR = 'Unknown';

function getLastUpdatedString(timestamp: Date) {
  let timeStr = DEFAULT_TIME_STR;
  if (timestamp) {
    timeStr = fromNow(timestamp, { short: true, useSeconds: true });
  }
  return timeStr;
}

interface Props extends Testable {
  lastUpdatedTime: Date;
  isLoading: boolean;
  onClick: () => void;
  isDisabled?: boolean;
  width?: string;
  hasBoxShadow?: boolean;
  alignStart?: boolean;
}

const RefreshButton: FC<Props> = ({
  lastUpdatedTime,
  isLoading,
  onClick,
  testHook,
  isDisabled = false,
  width,
  hasBoxShadow = true,
  alignStart = false
}) => {
  const [time, setTime] = useState<string>();
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(
    function initLastUpdateInterval() {
      const updateTime = () => {
        const initTimeStr = getLastUpdatedString(lastUpdatedTime);
        setTime(initTimeStr);
      };

      updateTime();

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      if (!isDisabled) {
        intervalRef.current = setInterval(updateTime, UPDATE_INTERVAL_DELAY);
      }

      return () => {
        clearInterval(intervalRef.current);
      };
    },
    [setTime, lastUpdatedTime, isDisabled]
  );

  let subText = time;

  if (isLoading || isDisabled) {
    subText = '';
  }

  return (
    <OutlinedIconSubtextButton
      onClick={onClick}
      icon={<Icon.Refresh />}
      testHook={testHook}
      subText={subText}
      disabled={isDisabled || isLoading}
      width={width}
      hasBoxShadow={hasBoxShadow}
      alignStart={alignStart}
    >
      {isLoading ? 'Refreshing Data…' : 'Refresh Data'}
    </OutlinedIconSubtextButton>
  );
};

export default RefreshButton;
