import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { ReactComponent as EmptyNotificationImg } from 'img/empty-notification.svg';

export const EmptyNotifications: FC = () => (
  <StyledEmptyNotificationsGrid
    container
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <EmptyNotificationImg />
    <StyledEmptyStateTitle variant="h3">No Notifications</StyledEmptyStateTitle>
  </StyledEmptyNotificationsGrid>
);

const StyledEmptyNotificationsGrid = styled(Grid)`
  position: relative;
  top: 20%;
`;

const StyledEmptyStateTitle = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.disabled};
  `
);
