// @ts-strict-ignore
import { computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { CareIntervalRequest } from 'fetchers/responses/care-time-data.response';

import { secondsToFormattedTime } from 'utils/DateUtils';
import ObjectCopier from 'utils/ObjectCopier';

export interface DurationDoctorDetails {
  id: number;
  name?: string;
}
export class DurationInterval extends ObjectCopier {
  @observable
  startDate: Date;

  @observable
  id: number;

  @observable
  identifierFallback: string; // used internally when id is not exists (e.i. before interval sent to server)

  @observable
  endDate: Date;

  @observable
  isCallAttempt?: boolean;

  @observable
  doctorDetails: DurationDoctorDetails;

  static toRequest(interval: DurationInterval): CareIntervalRequest {
    return {
      id: interval.id || null,
      startedAt: interval.startDate.toString(),
      endedAt: interval.endDate ? interval.endDate.toString() : null,
      isCallAttempt: interval.isCallAttempt
    };
  }

  static toDeleteRequest(interval: DurationInterval): CareIntervalRequest {
    return {
      id: interval.id,
      startedAt: interval.startDate.toString(),
      isDeleted: true,
      isCallAttempt: interval.isCallAttempt
    };
  }

  constructor(
    doctorDetails: DurationDoctorDetails,
    startDate: Date = null,
    endDate: Date = null,
    id: number = null,
    isCallAttempt: boolean = false
  ) {
    super();
    this.doctorDetails = doctorDetails;
    this.startDate = startDate;
    this.endDate = endDate;
    this.id = id;
    this.identifierFallback = uuidv4();
    this.isCallAttempt = isCallAttempt;
    makeObservable(this);
  }

  @computed
  get uniqueIdentifier(): string {
    return this.id ? this.id.toString() : this.identifierFallback;
  }

  @computed
  get durationInSeconds(): number {
    if (this.endDate && this.startDate) {
      return moment.duration(moment(this.endDate).diff(this.startDate)).asSeconds();
    }
    return 0;
  }

  @computed
  get durationAsText(): string {
    const seconds = this.durationInSeconds;

    if (seconds === 0) {
      return '00:00';
    }

    if (seconds < 60) {
      return 'Under a minute';
    }

    return secondsToFormattedTime(seconds);
  }

  copyWith(modifyObject: { [P in keyof this]?: this[P] }): this {
    return super.copyWith({ ...modifyObject, identifierFallback: this.identifierFallback });
  }

  equals(interval: DurationInterval): boolean {
    return (
      this.uniqueIdentifier === interval.uniqueIdentifier &&
      moment(this.startDate).isSame(interval.startDate) &&
      moment(this.endDate).isSame(interval.endDate)
    );
  }

  findOverlappingIntervals(intervals: DurationInterval[]): DurationInterval[] {
    return intervals.filter(
      (selfOrOtherInterval) =>
        selfOrOtherInterval !== this &&
        moment(selfOrOtherInterval.startDate).isBefore(this.endDate) &&
        moment(selfOrOtherInterval.endDate).isAfter(this.startDate)
    );
  }
}
