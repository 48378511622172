// @ts-strict-ignore
import {
  createContext,
  useContext,
  FC,
  useState,
  SetStateAction,
  Dispatch,
  ReactNode
} from 'react';

import { cloneDeep } from 'lodash';

import { DurationInterval } from 'models/DurationInterval';

import { QuickAddition } from 'models/QuickAddition';

import { Reason } from 'models/Reason';

import { ExternalValidation, IntervalEditorCtx, IntervalErrors } from './IntervalsEditor.shared';
import { calculateIntervalErrors } from './IntervalsEditor.utils';

type IntervalContextType = {
  setLastEditedInterval: Dispatch<SetStateAction<DurationInterval>>;
  externalValidation: ExternalValidationCtx; // TODO: rename - externalIntervalValidation
  intervalCtx: IntervalEditorCtx<DurationInterval>;
  quickAdditionsCtx: IntervalEditorCtx<QuickAddition>;
  quickAdditionsEditedReason: Reason;
  setQuickAdditionEditedReason: (reason: Reason) => void;
  errors: IntervalErrors;
  isValid: boolean;
  quickAdditionIdsWithoutReason: string[];
  setQuickAdditionIdsWithoutReasons: Dispatch<SetStateAction<string[]>>;
};
export const IntervalContext = createContext<IntervalContextType>(null);

export function useIntervalContext() {
  return useContext(IntervalContext);
}

type ExternalValidationCtx = {
  inProgress: boolean;
  errorByInterval: Map<string, string>;
  validate: (interval: DurationInterval) => Promise<void>;
};

interface IntervalContextProps {
  children: ReactNode;
  initialIntervals: DurationInterval[];
  externalValidationAction: ExternalValidation;
  initialQuickAdditions?: QuickAddition[];
}

export const IntervalContextProvider: FC<IntervalContextProps> = ({
  initialIntervals,
  externalValidationAction,
  initialQuickAdditions,
  children
}) => {
  // clone so we won't override the provided value
  const clonedInitialIntervals = initialIntervals ? cloneDeep(initialIntervals) : null;
  const clonedQuickAdditions = initialQuickAdditions ? cloneDeep(initialQuickAdditions) : null;
  const [intervals, setIntervals] = useState(clonedInitialIntervals);
  const [quickAdditions, setQuickAdditions] = useState<QuickAddition[]>(clonedQuickAdditions);
  const [lastEditedInterval, setLastEditedInterval] = useState<DurationInterval>();
  const [quickAdditionsEditedReason, setQuickAdditionEditedReason] = useState(null);
  const [quickAdditionIdsWithoutReason, setQuickAdditionIdsWithoutReasons] = useState([]);
  const externalValidation = useExternalValidation(externalValidationAction);

  const errors =
    intervals &&
    calculateIntervalErrors(intervals, externalValidation.errorByInterval, lastEditedInterval);

  const intervalCtx: IntervalEditorCtx<DurationInterval> = {
    initial: clonedInitialIntervals,
    current: intervals,
    setCurrent: setIntervals,
    isValid: errors && errors.errorByUuid.size === 0
  };

  const quickAdditionsCtx: IntervalEditorCtx<QuickAddition> = {
    initial: clonedQuickAdditions,
    current: quickAdditions,
    setCurrent: setQuickAdditions,
    isValid:
      quickAdditions && quickAdditions.every((item) => item.duration > 0 && item.duration <= 60)
  };

  const isValid = intervalCtx.isValid && (!quickAdditions || quickAdditionsCtx.isValid);

  return (
    <IntervalContext.Provider
      value={{
        intervalCtx,
        quickAdditionsCtx,
        setLastEditedInterval,
        externalValidation,
        errors,
        isValid,
        quickAdditionsEditedReason,
        setQuickAdditionEditedReason,
        quickAdditionIdsWithoutReason,
        setQuickAdditionIdsWithoutReasons
      }}
    >
      {children}
    </IntervalContext.Provider>
  );
};

export function useExternalValidation(
  externalValidationAction: ExternalValidation
): ExternalValidationCtx {
  const [inProgress, setInProgress] = useState(false);
  const [errorByInterval, setErrorByInterval] = useState<Map<string, string>>(new Map());

  async function validate(interval: DurationInterval) {
    setInProgress(true);
    const error = await externalValidationAction(interval);

    if (error) {
      errorByInterval.set(interval.uniqueIdentifier, error);
    } else {
      errorByInterval.delete(interval.uniqueIdentifier);
    }
    setErrorByInterval(errorByInterval);
    setInProgress(false);
  }
  return { inProgress, errorByInterval, validate };
}
