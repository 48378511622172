import { MouseEvent } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import { observer } from 'mobx-react';

import { Testable } from 'utils/TypeUtils';

import Call from 'models/Call';
import Patient from 'models/Patient';

import CallRow from 'views/Widgets/CallRow';
import { CollapsibleSection } from 'views/Widgets/CollapsibleSection';
import { PatientCallsList } from 'views/Widgets/PatientCallsList';

import { CardRow } from 'components/Surfaces/Card';
import { OutlinedButton } from 'components/UIkit/atoms/Button';

import './CallsTab.scss';

interface CallsTabProps extends Testable {
  patient: Patient;
  onEditCallClick: (call: Call) => void;
}

const CallsTab = observer(({ patient, onEditCallClick, testHook }: CallsTabProps) => {
  const { drafts, savedCalls } = patient.callsByType;

  const resumeCallAction = (call: Call) => (
    <OutlinedButton
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        trackActionButtonAnalyticsEvent({
          action: AnalyticEventAction.ResumeCall,
          ticket_ids: call.ticketIdsArray
        });
        onEditCallClick(call);
      }}
      testHook={`resume-call-${call.id}-button`}
    >
      Resume Call
    </OutlinedButton>
  );

  if (!patient) {
    return null;
  }

  const hasNoCalls = !Boolean(savedCalls.length) && !Boolean(drafts.length);

  let savedCallsElements = null;
  if (savedCalls.length > 0) {
    savedCallsElements = PatientCallsList({ calls: savedCalls, patient });
    if (Array.isArray(savedCallsElements)) {
      savedCallsElements = savedCallsElements.map((callRow) => (
        <CardRow key={callRow.key} noMinHeight testHook={`call-${callRow.key}`}>
          {callRow}
        </CardRow>
      ));
    }
  }

  return (
    <div className="calls-tab" data-test-hook={testHook}>
      {drafts.length > 0 && (
        <CollapsibleSection
          name="Drafts"
          className="calls-tab-section"
          trigger={<div className="section-title bold">Drafts ({drafts.length})</div>}
        >
          {drafts.map((call) => (
            <CardRow key={call.id} noMinHeight testHook={`call-${call.id}`}>
              <CallRow patient={patient} call={call} action={resumeCallAction(call)} />
            </CardRow>
          ))}
        </CollapsibleSection>
      )}
      {savedCalls.length > 0 && (
        <CollapsibleSection
          name="Saved Calls"
          className="calls-tab-section"
          trigger={<div className="section-title bold">Saved Calls ({savedCalls.length})</div>}
        >
          {savedCallsElements}
        </CollapsibleSection>
      )}
      {hasNoCalls && <div className="empty-state bold">No Drafts or Saved Calls</div>}
    </div>
  );
});

export default CallsTab;
