// @ts-strict-ignore

import { ChangeEvent, FC } from 'react';

import { Box, css, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  trackDateInputUsageAnalyticsEvent,
  trackDropdownInputUsageAnalyticsEvent,
  trackToggleButtonInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';
import moment from 'moment';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import { ActionMeta } from 'react-select';

import { useStores } from 'mobx/hooks/useStores';

import {
  defaultMinDateInput,
  getTodayFormattedDate,
  HOUR_IN_MILLISECONDS,
  LONG_DATE_FORMAT,
  timeOptions,
  WEEK_IN_MILLISECONDS
} from 'utils/DateUtils';

import { DrugSpecificReportProtocolTemplate, DrugSpecificType } from 'models/DrugSpecific';
import ScheduledProtocol from 'models/ScheduledProtocol';
import { SelectOption } from 'models/SelectOption';

import { DrugSpecificProtocolForm } from 'views/Modals/ScheduledProtocolModal';
import EndProtocolOptions, {
  defaultEndDateDurationUnitOptions
} from 'views/Widgets/EndProtocolOptions';
import MessageDivider from 'views/Widgets/ProtocolTimesMessageDivider';
import RFHWeekdaySelector from 'views/Widgets/RFHWeekdaySelector';
import { RHFStyledInput } from 'views/Widgets/StyledInput';

import Icon from 'components/Icons/Icon';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { Text } from 'components/UIkit/atoms/Text';

import {
  defaultDrugSpecificIntervalOptions,
  drugSpecificEndOptions,
  specificDrugToLabelMap
} from './DrugSpecificProtocolFields.constants';

import './OralProtocolFields.scss';

interface Props {
  protocol?: ScheduledProtocol;
}

export const DrugSpecificProtocolFields: FC<Props> = ({ protocol }) => {
  const { control, setValue, formState, reset, register, getValues } =
    useFormContext<DrugSpecificProtocolForm>();
  const { constantsStore } = useStores();
  const { field, fieldState } = useController({
    name: 'selectedStartDate',
    control,
    shouldUnregister: false,
    rules: {
      validate: (value) => {
        if (moment(value).isBefore(defaultMinDateInput)) {
          return false;
        }

        if (!moment(value).isValid()) {
          return false;
        }

        return true;
      }
    }
  });

  const isDateTimePickerError = Boolean(fieldState.error);

  const [drug, type, selectedFrequency, selectedStartDate, selectedEndOption] = useWatch({
    control,
    name: ['drug', 'type', 'selectedFrequency', 'selectedStartDate', 'selectedEndOption']
  });

  const hasDrugValue = Boolean(drug?.value);

  const currentDrugHourlyTypeId = constantsStore.getSpecificDrugTypeId(
    drug?.value,
    DrugSpecificType.Hourly
  );

  const currentDrugWeeklyCrsTypeId = constantsStore.getSpecificDrugTypeId(
    drug?.value,
    DrugSpecificType.WeeklyCRS
  );

  const currentDrugWeeklyInfectionTypeId = constantsStore.getSpecificDrugTypeId(
    drug?.value,
    DrugSpecificType.WeeklyInfection
  );

  const isHourlyType = type?.value === currentDrugHourlyTypeId;

  const isSelectedEndOptionDisabled =
    selectedEndOption?.value === ScheduledProtocol.END_TYPE_DURATION && isHourlyType;

  const isDurationUnitFieldsDisabled =
    selectedEndOption?.value === ScheduledProtocol.END_TYPE_DURATION && isHourlyType;

  const isWeekDaysFieldVisible =
    type?.value === currentDrugWeeklyCrsTypeId || type?.value === currentDrugWeeklyInfectionTypeId;

  const setDrugDefaults = (drugTemplate: DrugSpecificReportProtocolTemplate) => {
    let defaultDrugTimeUnit = WEEK_IN_MILLISECONDS;
    let defaultDrugMultiplier =
      drugTemplate.timeBetweenReportsInMilliseconds / WEEK_IN_MILLISECONDS;
    const defaultEndOption = drugSpecificEndOptions.find(
      (option: SelectOption<number>) => option.value === drugTemplate.endType
    );

    if (drugTemplate.type === DrugSpecificType.Hourly) {
      defaultDrugTimeUnit = HOUR_IN_MILLISECONDS;
      defaultDrugMultiplier = drugTemplate.timeBetweenReportsInMilliseconds / HOUR_IN_MILLISECONDS;
      setValue('selectedStartDate', moment(new Date()));
    } else {
      setValue('selectedStartDate', getTodayFormattedDate(LONG_DATE_FORMAT));
    }

    setValue('frequencyMultiplier', defaultDrugMultiplier);
    setValue(
      'selectedFrequency',
      defaultDrugSpecificIntervalOptions.find((option) => option.value === defaultDrugTimeUnit)
    );
    setValue('selectedEndOption', defaultEndOption);
    setValue('endDateDuration', drugTemplate.duration);
    setValue(
      'endDateDurationUnit',
      defaultEndDateDurationUnitOptions.find(
        (option) => option.value === drugTemplate.durationUnitType
      )
    );
  };

  const onDrugChange = (
    option: SelectOption<number>,
    actionMeta: ActionMeta<SelectOption<number>>,
    eventKey: string
  ) => {
    trackDropdownInputUsageAnalyticsEvent(actionMeta, 'Drug', eventKey === 'Enter', hasDrugValue, {
      virtual_page: 'report protocol modal'
    });

    if (option.value === protocol?.info?.drugId) {
      reset();
      return;
    }
    const drug = constantsStore.getSpecificDrugById(option.value);
    const defaultDrugTemplate = drug.reportProtocolTemplates[0];
    setValue('type', {
      label: specificDrugToLabelMap[defaultDrugTemplate.type],
      value: defaultDrugTemplate.id
    });

    setDrugDefaults(defaultDrugTemplate);
  };

  const onTypeChange = (
    option: SelectOption<number>,
    actionMeta: ActionMeta<SelectOption<number>>,
    eventKey: string
  ) => {
    trackDropdownInputUsageAnalyticsEvent(
      actionMeta,
      'Type',
      eventKey === 'Enter',
      Boolean(type?.value),
      {
        virtual_page: 'report protocol modal'
      }
    );

    if (option.value === protocol?.reportProtocolTemplateId) {
      reset();
      return;
    }

    const drugTemplate = constantsStore.getSpecificDrugTemplate(drug?.value, option.value);
    setDrugDefaults(drugTemplate);
  };

  return (
    <>
      <Box display="flex" width="100%" gap={20}>
        <StyledSection>
          <FormAutocomplete
            label="Drug"
            isRequired
            options={constantsStore.specificDrugsForSelect}
            name="drug"
            onChange={onDrugChange}
          />
        </StyledSection>

        {hasDrugValue && (
          <StyledSection>
            <FormAutocomplete
              label="Type"
              isRequired
              options={constantsStore.getSpecificDrugTypesForSelect(drug.value)}
              name="type"
              onChange={onTypeChange}
            />
          </StyledSection>
        )}
      </Box>

      {hasDrugValue && (
        <>
          <Text
            variant="body2"
            color="disabled"
            display="inline-block"
            textTransform="uppercase"
            mt={20}
          >
            Report Frequency
          </Text>

          <Box display="flex" flexDirection="column" width="fit-content">
            <Box display="flex" alignItems="center" gap="1.2rem" py={20}>
              <span>Report every</span>

              <Box width="60px">
                <RHFStyledInput
                  type="number"
                  min={1}
                  rounded
                  isRequired
                  disabled
                  error={Boolean(formState.errors.frequencyMultiplier)}
                  name="frequencyMultiplier"
                  register={register}
                />
              </Box>

              <Box width="130px">
                <FormAutocomplete
                  sortAlphabetically={false}
                  isClearable={false}
                  isDisabled
                  options={defaultDrugSpecificIntervalOptions}
                  name="selectedFrequency"
                  isRequired
                />
              </Box>

              {!isHourlyType && (
                <>
                  <div>at</div>

                  <Box width="130px">
                    <FormAutocomplete
                      sortAlphabetically={false}
                      isClearable={false}
                      options={timeOptions}
                      name="selectedStartHour"
                    />
                  </Box>
                </>
              )}
            </Box>

            {isWeekDaysFieldVisible && (
              <Box py={20}>
                <RFHWeekdaySelector
                  control={control}
                  error={Boolean(formState.errors.weekdays)}
                  name="weekdays"
                  forceSingleDaySelection
                  onChange={() =>
                    trackToggleButtonInputUsageAnalyticsEvent(
                      getValues('weekdays')?.toString(),
                      'Week Days',
                      {
                        virtual_page: 'report protocol modal'
                      }
                    )
                  }
                />
              </Box>
            )}
          </Box>

          <MessageDivider intervalFrequency={selectedFrequency?.value} />

          <Text variant="body2" color="disabled" display="inline-block" textTransform="uppercase">
            Report Activation
          </Text>

          <StyledSection>
            {isHourlyType ? (
              <Box width="100%">
                <Text color={isDateTimePickerError ? 'error' : 'secondary'}>
                  First Report Request
                </Text>

                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  localeText={{ todayButtonLabel: 'Now' }}
                >
                  <StyledDateTimePicker
                    value={moment(field.value)}
                    onChange={(newValue) => {
                      trackDateInputUsageAnalyticsEvent(
                        newValue.toString(),
                        selectedStartDate?.toString(),
                        'Activation of Protocol'
                      );

                      field.onChange(moment(newValue.toDate()));
                    }}
                    slotProps={{
                      actionBar: {
                        actions: ['today']
                      }
                    }}
                    slots={{ openPickerIcon: Icon.Calendar }}
                    timeSteps={{ minutes: 15 }}
                    disablePast
                    format="DD/MM/YYYY hh:mm A"
                    closeOnSelect={false}
                    isError={isDateTimePickerError}
                    inputRef={field.ref}
                  />
                </LocalizationProvider>
              </Box>
            ) : (
              <RHFStyledInput
                key="date"
                type="date"
                label="Activation of Protocol"
                isRequired
                register={register}
                error={Boolean(formState.errors.selectedStartDate)}
                name="selectedStartDate"
                onChange={(event) => {
                  trackDateInputUsageAnalyticsEvent(
                    (event as ChangeEvent<HTMLInputElement>).target.value,
                    selectedStartDate as string,
                    'Activation of Protocol'
                  );
                }}
                min={defaultMinDateInput}
              />
            )}
          </StyledSection>

          <EndProtocolOptions
            minEndDate={selectedStartDate as string}
            endOptions={drugSpecificEndOptions}
            endDateDurationUnitOptions={defaultEndDateDurationUnitOptions}
            isSelectedEndOption={isSelectedEndOptionDisabled}
            isDurationUnitFieldsDisabled={isDurationUnitFieldsDisabled}
          />
        </>
      )}
    </>
  );
};

const StyledSection = styled(Box)(
  ({ theme }) => css`
    margin: ${theme.spacing(20, 0)};
    display: flex;
    align-items: center;
    gap: ${theme.spacing(20)};
    width: 100%;

    .styled-select {
      width: 100%;
    }

    .styled-input {
      width: 100%;
    }
  }
  `
);

const StyledDateTimePicker = styled(DateTimePicker, {
  shouldForwardProp: (prop) => prop !== 'isError'
})<{ isError: boolean }>(
  ({ theme, isError }) => css`
    &.MuiFormControl-root.MuiTextField-root {
      width: 100%;

      .MuiInputBase-root.MuiOutlinedInput-root {
        border-radius: 0 16px 16px 16px;

        &.Mui-focused {
          fieldset.MuiOutlinedInput-notchedOutline {
            border-color: ${isError ? theme.palette.error.main : theme.palette.primary.main};
          }
        }

        input {
          font-size: 16px;
          font-weight: 400;
          box-sizing: border-box;
          height: 45px;
          padding: ${theme.spacing(4, 12)};
        }
      }

      svg {
        color: ${theme.palette.text.primary};
      }

      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: ${isError ? theme.palette.error.main : theme.palette.natural.border};
        border-width: 1px;
      }

      legend {
        width: auto;
      }

      .MuiInputAdornment-root .MuiButtonBase-root.MuiIconButton-root:hover {
        background-color: initial;
      }
    }
  `
);
