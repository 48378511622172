// @ts-strict-ignore
export default class CallLogIntervalAuditTrailRecord {
  constructor(
    type: CallLogAuditTrailType,
    intervalUUID: string,
    startDate: Date,
    endDate: Date,
    previousValues?: { startDate: Date; endDate: Date }
  ) {
    this.updatedAt = new Date();
    this.type = type;
    this.intervalUUID = intervalUUID;
    this.startDate = startDate;
    this.endDate = endDate;
    this.previousValues = previousValues;
  }
  updatedAt: Date;
  type: CallLogAuditTrailType;
  intervalUUID: string;
  startDate: Date;
  endDate: Date;
  previousValues: {
    startDate: Date;
    endDate: Date;
  };
}

export enum CallLogAuditTrailType {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  ADD = 'ADD'
}
