import { FC, useState, FocusEvent, useCallback } from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AnimatePresence } from 'framer-motion';

import { Placeholder } from 'components/UIkit/atoms/RichText/Placeholder';
import { ImportFromHtmlPlugin } from 'components/UIkit/atoms/RichText/Plugins/ImportFromHtmlPlugin/ImportFromHtmlPlugin';

import { ListMaxIndentLevelPlugin } from 'components/UIkit/atoms/RichText/Plugins/ListMaxIndentLevelPlugin/ListMaxIndentLevelPlugin';
import { OnFocusBlurListenerPlugin } from 'components/UIkit/atoms/RichText/Plugins/OnFocusBlurListenerPlugin/OnFocusBlurListenerPlugin';
import { ToolbarPlugin } from 'components/UIkit/atoms/RichText/Plugins/ToolbarPlugin/ToolbarPlugin';
import {
  editorConfig,
  LIST_MAX_INDENT_ALLOWED
} from 'components/UIkit/atoms/RichText/RichText.constants';
import { sharedRichTextStyles } from 'components/UIkit/atoms/RichText/RichText.styles';
import { RichTextProps } from 'components/UIkit/atoms/RichText/RichText.types';

export const RichText: FC<RichTextProps> = ({
  initialState,
  placeholder,
  children: externalPlugins,
  showToolbarOnFocus = true,
  isError,
  disabled,
  editorId,
  variant = 'standard',
  size,
  isPartialBorder,
  toolbarListeners,
  onBlur,
  onFocus,
  testHook
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const shouldShowToolbar = showToolbarOnFocus ? isFocused : true;
  let inputMinHeightValue = size === 'medium' ? '76px' : 'auto';

  const handleFocus = useCallback(
    (event: FocusEvent) => {
      setIsFocused(true);

      onFocus && onFocus(event);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      setIsFocused(false);

      onBlur && onBlur(event);
    },
    [onBlur]
  );

  return (
    <LexicalComposer initialConfig={{ ...editorConfig, editable: !disabled, namespace: editorId }}>
      <StyledEditorContainer
        isError={isError}
        disabled={disabled}
        isFocused={isFocused}
        variant={variant}
        isPartialBorder={isPartialBorder}
      >
        <AnimatePresence>
          {shouldShowToolbar && <ToolbarPlugin disabled={disabled} listeners={toolbarListeners} />}
        </AnimatePresence>

        <StyledInnerEditorContainer size={size}>
          <RichTextPlugin
            contentEditable={
              <StyledContentEditable
                data-test-hook={testHook}
                inputMinHeight={inputMinHeightValue}
              />
            }
            placeholder={<Placeholder text={placeholder || 'Enter text here...'} />}
            ErrorBoundary={LexicalErrorBoundary}
          />

          <HistoryPlugin />

          <OnFocusBlurListenerPlugin onFocus={handleFocus} onBlur={handleBlur} />
          <ListPlugin />

          <ListMaxIndentLevelPlugin maxDepth={LIST_MAX_INDENT_ALLOWED} />
          <TabIndentationPlugin />

          <ImportFromHtmlPlugin value={initialState} />

          {externalPlugins}
        </StyledInnerEditorContainer>
      </StyledEditorContainer>
    </LexicalComposer>
  );
};

export const StyledEditorContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    !['isFocused', 'isError', 'disabled', 'variant', 'isPartialBorder'].includes(prop as string)
})<{
  isFocused?: boolean;
  isError?: boolean;
  disabled?: boolean;
  variant?: 'outlined' | 'standard';
  isPartialBorder?: boolean;
}>(
  ({ theme, isFocused, disabled, isError, variant, isPartialBorder }) => css`
    color: ${theme.palette.text.primary};
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 0;
    background-color: ${theme.palette.natural.white};
    border: 1px solid ${theme.palette.natural.border};

    ${variant === 'outlined' &&
    css`
      border-radius: ${theme.borderRadius.medium};
    `}

    ${isPartialBorder &&
    css`
      ${!isFocused &&
      !isError &&
      css`
        border-top: 0;
        border-bottom: 0;
      `}
    `}

    ${isFocused &&
    !isError &&
    !disabled &&
    css`
      border-color: ${theme.palette.primary.main};
      &:hover {
        border-color: ${theme.palette.primary.main};
      }
    `}

    ${isError &&
    css`
      border-color: ${theme.palette.error.dark};
      &:hover {
        border-color: ${theme.palette.error.dark};
      }
    `}
  
    ${disabled &&
    css`
      * {
        color: ${theme.palette.text.disabled};
      }
      color: ${theme.palette.text.disabled};
      background-color: ${theme.palette.natural.inactiveBackground};
    `}
  `
);

const StyledInnerEditorContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<{ size?: 'small' | 'medium' }>(
  ({ theme, size }) => css`
    position: relative;
    cursor: text;

    ${size === 'medium' &&
    css`
      min-height: 76px;
    `}

    * {
      font-size: ${theme.fontSizes.medium};
    }

    ${sharedRichTextStyles(theme)};
  `
);

const StyledContentEditable = styled(ContentEditable, {
  shouldForwardProp: (prop) => prop !== 'inputMinHeight'
})<{ inputMinHeight?: string }>(
  ({ theme, inputMinHeight }) => css`
    ${inputMinHeight &&
    css`
      min-height: ${inputMinHeight};
    `}

    height: 100%;
    font-size: ${theme.fontSizes.normal};
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: ${theme.spacing(8)} ${theme.spacing(12)};
  `
);
