// @ts-strict-ignore
import { ReactNode } from 'react';

import { css, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { getClinicianFullName } from 'utils/ClinicianCredentialUtils';
import { joinStringArrayByDelimiter, textWithLineBreaks } from 'utils/StringUtils';

import PatientEpisode from 'models/PatientEpisode';

import { ItemRow } from 'views/Widgets/ItemRow';
import SanitizeHtml from 'views/Widgets/SanitizeHtml';

import Icon from 'components/Icons/Icon';

function getIcon(episode: PatientEpisode) {
  if (episode.isMarkedAsError) {
    return <Icon.EpisodeError />;
  }
  if (episode.isExpired) {
    return <Icon.EpisodeExpired />;
  }
  if (episode.isEnded) {
    return <Icon.EpisodeEnded />;
  }
  return <Icon.Episode />;
}

export const PatientEpisodeRow = ({
  episode,
  action
}: {
  episode: PatientEpisode;
  action: ReactNode;
}) => (
  <StyledItemRow
    className="patient-episode-row"
    icon={getIcon(episode)}
    content={<PatientEpisodeMainInfo episode={episode} />}
    action={episode.isEnded ? null : action}
    collapsibleContent={<PatientEpisodeFullDetails episode={episode} />}
  />
);

const PatientEpisodeMainInfo = observer(({ episode }: { episode: PatientEpisode }) => {
  const { constantsStore } = useStores();
  const { providersStore, locationsStore, patientEpisodesStore } = useStores();

  const provider = providersStore.getProviderById(episode.providerId)?.name;
  const location = locationsStore.getLocationById(episode.locationId)?.name;
  const owner = getClinicianFullName(episode.owner);
  const description = joinStringArrayByDelimiter([
    episode.dateRange,
    owner ? `Owner: ${owner}` : null,
    provider,
    location
  ]);

  let extraEpisodeText = '';
  if (episode.isExpired) {
    extraEpisodeText = ' (Expired)';
  }
  if (episode.isEnded) {
    extraEpisodeText = ` (Ended - ${
      patientEpisodesStore.episodeEndReasons.get(episode.endReasonId)?.name
    })`;
  }

  return (
    <>
      <Typography variant="h4" component="span">
        {/*remove component prop when we change h4 to be a span by default*/}
        {constantsStore.getEpisodeById(episode.episodeId)?.name}: {episode.formattedEpisodeNumber}{' '}
        {extraEpisodeText}
      </Typography>{' '}
      {description && <StyledTypography variant="form-text">— {description}</StyledTypography>}
    </>
  );
});

const PatientEpisodeFullDetails = ({ episode }: { episode: PatientEpisode }) => {
  const { providersStore, locationsStore, constantsStore, patientEpisodesStore } = useStores();
  const episodeType = constantsStore.getEpisodeById(episode.episodeId)?.name;
  const provider = providersStore.getProviderById(episode.providerId)?.name;
  const location = locationsStore.getLocationById(episode.locationId)?.name;
  const owner = getClinicianFullName(episode.owner);
  const endingClinician = getClinicianFullName(episode.endedBy);
  const endingReason = patientEpisodesStore.episodeEndReasons.get(episode.endReasonId)?.name;

  return (
    <StyledContainer>
      <StyledGrid container direction="column">
        <Typography variant="body2" mb={2}>
          Episode: {episodeType}
        </Typography>

        <Typography variant="body2" mb={2}>
          Episode Number: {episode.formattedEpisodeNumber}
        </Typography>

        <Typography variant="body2" mb={2}>
          Start Date: {episode.formattedStartDate}
        </Typography>

        <Typography variant="body2" mb={2}>
          End Date: {episode.formattedEndDate}
        </Typography>

        <Typography variant="body2" mb={2}>
          Provider: {provider}
        </Typography>

        {owner && (
          <Typography variant="body2" mb={2}>
            Episode Owner: {owner}
          </Typography>
        )}

        <Typography variant="body2" mb={2}>
          Location: {location}
        </Typography>

        <Typography variant="body2">
          Historical Episode: {episode.isHistorical ? 'Yes' : 'No'}
        </Typography>
      </StyledGrid>

      {Boolean(episode.isEnded) && (
        <StyledGrid container direction="column">
          <Typography variant="body1" mb={4}>
            Episode Ended: {episode.formattedEndedDate} by {endingClinician}
          </Typography>

          <Typography variant="body2" mb={2}>
            Reason: {endingReason}
          </Typography>

          {episode.endReasonDetails && (
            <Typography variant="body2">Details: {episode.endReasonDetails}</Typography>
          )}
        </StyledGrid>
      )}

      {episode.diagnosis && (
        <StyledGrid container direction="column">
          <Typography variant="body1" mb={4}>
            Qualifying Diagnosis
          </Typography>

          <SanitizeHtml html={textWithLineBreaks(episode.diagnosis)} />
        </StyledGrid>
      )}

      {episode.treatment && (
        <StyledGrid container direction="column">
          <Typography variant="body1" mb={4}>
            Qualifying Treatment:
          </Typography>

          <SanitizeHtml html={textWithLineBreaks(episode.treatment)} />
        </StyledGrid>
      )}

      {episode.notes && (
        <StyledGrid container direction="column">
          <Typography variant="body1" mb={4}>
            Additional Notes:
          </Typography>

          <SanitizeHtml html={textWithLineBreaks(episode.notes)} />
        </StyledGrid>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled('div')`
  max-height: 500px;
  overflow-y: auto;
`;

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
  `
);

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(20)};
    }
  `
);

const StyledItemRow = styled(ItemRow)`
  .item-icon-container {
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;
