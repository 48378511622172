// @ts-strict-ignore
import { ComponentType, MouseEvent, ReactNode, useCallback } from 'react';

import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { Row } from 'react-table';

import './TableRow.scss';

interface TableRowProps<D extends {}> {
  row: Row<D>;
  rowContent: ReactNode;
  rowLink?: (row: D) => string;
  style?: {};
  rowClasses?: string;
  rowAction?: (row: Row<D>) => void;
  isSelected?: boolean;
  isEditMode?: boolean;
  RowEditComponent?: ComponentType<{ row: Row<D> }>;
}

const TableRow = <D extends {}>({
  row,
  rowLink,
  style,
  rowClasses,
  rowAction,
  rowContent,
  isSelected,
  isEditMode,
  RowEditComponent
}: TableRowProps<D>) => {
  const classes = classNames(rowClasses, 'expain-row', {
    'cursor-pointer': Boolean(rowAction),
    selected: Boolean(isSelected)
  });

  const { ref, inView } = useInView();

  const onClick = useCallback(
    (event: MouseEvent) => {
      const element = event.target as HTMLElement;
      if (rowAction && element.tagName.toUpperCase() !== 'INPUT') {
        // skip if the click was on a checkbox/input
        rowAction(row);
      }
    },
    [rowAction, row]
  );

  const viewMode = rowLink ? (
    <Link to={rowLink(row.original)} className={classes}>
      {rowContent}
    </Link>
  ) : (
    <div className={classes} onClick={onClick}>
      {rowContent}
    </div>
  );

  const children = isEditMode ? (
    <div className={classes}>
      <RowEditComponent row={row} />
    </div>
  ) : (
    viewMode
  );

  return (
    <div
      ref={ref}
      data-test-hook={`table_row_${row.id}`}
      {...row.getRowProps({ style })}
      className={classNames('row-container', {
        expanded: row.isExpanded || isEditMode
      })}
    >
      {inView && children}
    </div>
  );
};

export default TableRow;
