import { observable, computed, makeObservable } from 'mobx';

import moment from 'moment';

import { getNameWithCredentials } from 'utils/ClinicianCredentialUtils';

import { formatDate, fromNow } from 'utils/DateUtils';
import { textWithLineBreaks } from 'utils/StringUtils';

export type CommentableType = 'ticket';

interface CommentAuthor {
  firstName: string;
  lastName: string;
  credential: string | null;
}
export default class Comment {
  id: number;
  @observable
  text: string;
  @observable
  createdAt: Date;
  @observable
  author: CommentAuthor;

  constructor(user: any, id: number, text: string, createdAt: Date) {
    makeObservable(this);
    this.author = {
      // snake case used on comment creation (until server is updated to V2)
      firstName: user.first_name || user.firstName,
      lastName: user.last_name || user.lastName,
      credential: user.credential
    };
    this.id = id;
    this.text = text;
    this.createdAt = createdAt;
  }

  @computed
  get authorFullName() {
    return getNameWithCredentials(
      `${this.author.firstName} ${this.author.lastName}`,
      this.author.credential
    );
  }

  @computed
  get creationInfo() {
    let createdAt = '';
    const wasCreatedToday = moment(this.createdAt).isAfter(moment().subtract(1, 'day'));
    if (wasCreatedToday) {
      createdAt = `${fromNow(this.createdAt)} – `;
    }
    createdAt += formatDate(this.createdAt, 'M/D/YYYY, H:mm A');

    return `${this.authorFullName} • ${createdAt}`;
  }

  @computed
  get textWithLineBreaks() {
    return textWithLineBreaks(this.text);
  }
}
