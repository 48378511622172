// @ts-strict-ignore
import { FC, ReactNode } from 'react';

import { Box, css, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useStores } from 'mobx/hooks/useStores';

import { CmPatient } from 'models/CmPatient';

import { useCareManagementEnrollmentContext } from 'views/Pages/CareManagement/CareManagementEnrollment/CareManagementEnrollmentProvider';
import CareManagementRowIcon from 'views/Pages/CareManagement/CareManagementRowIcon';
import { ItemRow } from 'views/Widgets/ItemRow';

import { CardTextRow } from 'components/Typography/Typography';

import { MonthlyCareTime } from './MonthlyCareTime';

interface Props {
  children: ReactNode;
  isExpanded: boolean;
}

export const CareManagementRow: FC<Props> = ({ isExpanded, children }) => {
  const { careManagementPatientStore } = useStores();
  const { patientCmSummary } = careManagementPatientStore;
  const { getEnrollmentDropdown } = useCareManagementEnrollmentContext();

  return (
    <StyledItemRow
      className="patient-episode-row"
      icon={<CareManagementRowIcon />}
      isExpanded={isExpanded}
      content={
        <div className="d-flex align-items-center ">
          <StyledStatusBox mr={4}>
            <CardTextRow
              title="Care Management Status"
              subtitle={patientCmSummary ? patientCmSummary.cmSummary : null}
            />
            {children}
          </StyledStatusBox>
          {patientCmSummary ? getEnrollmentDropdown(patientCmSummary) : null}
        </div>
      }
      collapsibleContent={
        patientCmSummary ? (
          <CareManagementDetails cmPatient={patientCmSummary} />
        ) : (
          'There are no details available to display here…'
        )
      }
      withToggleTextButton={false}
    />
  );
};

const StyledStatusBox = styled(Box)`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface DetailsProps {
  cmPatient: CmPatient;
}

const CareManagementDetails: FC<DetailsProps> = ({ cmPatient }) => (
  <StyledContainer>
    <Box mb={20}>
      <Typography variant="body2" component="div" mb={2}>
        Primary Ins: {cmPatient.primaryInsuranceName}
      </Typography>

      <Typography variant="body2" component="div" mb={2}>
        Secondary Ins: {cmPatient.secondaryInsuranceName}
      </Typography>

      <Typography variant="body2" component="div" mb={2}>
        Next Visit: {cmPatient.nextVisitSummary}
      </Typography>

      <Typography variant="body2" component="div" mb={2}>
        Visits (Past 12 Months): {cmPatient.visits || 'N/A'}
      </Typography>

      <Typography variant="body2" component="div" mb={2}>
        Monthly Care:{' '}
        {cmPatient.monthlyCareTime ? <MonthlyCareTime time={cmPatient.monthlyCareTime} /> : 'None'}
      </Typography>
    </Box>

    <DxSection title="Cancer Dx" items={cmPatient.cancerDiagnostics} />
    <DxSection title="Chronic Dx" items={cmPatient.chronicDiagnostics} />
  </StyledContainer>
);

const DxSection: FC<{ title: string; items: string[] }> = ({ title, items }) => {
  if (!Array.isArray(items)) {
    return null;
  }

  return (
    <StyledBox>
      <Typography variant="body1">{title}</Typography>

      {items.map((item, index) => (
        <Typography key={index} variant="body2" component="div" mb={2}>
          {item}
        </Typography>
      ))}
    </StyledBox>
  );
};

const StyledContainer = styled('div')`
  max-height: 500px;
  overflow-y: auto;
`;

const StyledBox = styled(Box)(
  ({ theme }) => css`
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(20)};
    }
  `
);

const StyledItemRow = styled(ItemRow)`
  .item-icon-container {
    svg {
      height: 24px;
      width: 24px;
    }
  }

  .enrollment-status-button {
    max-width: 250px;

    .status,
    .date {
      line-height: 20px;
    }
  }
`;
