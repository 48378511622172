// @ts-strict-ignore
import { FC, useEffect } from 'react';

import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';

import { useFormContext } from 'react-hook-form';

import { reportProtocolModalTestSelectors } from 'tests/models/components/modal/report-protocol-modal/report-protocol-modal.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { WEEK_IN_MILLISECONDS } from 'utils/DateUtils';

import { FEATURES } from 'constants/features';

import {
  OralFillLocations,
  OralProtocolReportFrequencies,
  ProtocolType
} from 'models/ScheduledProtocol';

import { SelectOption } from 'models/SelectOption';

import EndProtocolOptions from 'views/Widgets/EndProtocolOptions';
import ProtocolIntervalFields from 'views/Widgets/ProtocolIntervalFields';
import ProtocolTimesMessageDivider from 'views/Widgets/ProtocolTimesMessageDivider';
import RegimenSummaryField from 'views/Widgets/RegimenSummaryField';
import { RHFStyledInput } from 'views/Widgets/StyledInput';
import StyledToggleButton, { LabelPosition } from 'views/Widgets/StyledToggleButton';

import Icon from 'components/Icons/Icon';

import { SimpleTooltip } from 'components/Tooltip';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { Text } from 'components/UIkit/atoms/Text';

import './OralProtocolFields.scss';

interface OralProtocolFieldsProps {
  type: ProtocolType;
  openRegimenModal: () => void;
  isRegimenModalOpen: boolean;
  isAutomatic: boolean;
}

export const fillLocationOptions = [
  { label: 'In-House', value: OralFillLocations.IN_HOUSE },
  { label: 'External Pharmacy', value: OralFillLocations.EXTERNAL }
];

export const frequencyOptions = [
  { label: 'Standard Frequency (Weekly)', value: OralProtocolReportFrequencies.REGULAR },
  { label: 'Reduced Frequency (Once Per Cycle)', value: OralProtocolReportFrequencies.LOW }
];

const OralProtocolFields: FC<OralProtocolFieldsProps> = (props: OralProtocolFieldsProps) => {
  const methods = useFormContext();
  const { constantsStore, settingsStore } = useStores();

  const [regimenId, regimen, selectedStartHour, selectedStartDate, weekdays] = methods.watch([
    'regimenId',
    'regimen',
    'selectedStartHour',
    'selectedStartDate',
    'weekdays'
  ]);

  useEffect(() => {
    methods.register('regimen');
  });

  const hasOralAutoProtocolFeature = settingsStore.hasFeature(FEATURES.ORAL_AUTO_PROTOCOL);
  const isOralAutoProtocol = hasOralAutoProtocolFeature && props.isAutomatic;

  const regimenTemplate = constantsStore.regimens.get(regimenId?.value);
  const finalRegimen = regimen || regimenTemplate;

  return (
    <>
      <div className="oral-protocol-fields">
        <Text
          variant="body2"
          color="disabled"
          mb={20}
          display="inline-block"
          textTransform="uppercase"
        >
          Report Frequency
        </Text>
        {hasOralAutoProtocolFeature && (
          <StyledToggleButton
            label={
              <Box display="flex" alignItems="center">
                <Text mr={4} variant="body1" color="disabled">
                  Automatic Frequency
                </Text>
                <SimpleTooltip
                  title={
                    <StyledTooltipText
                      data-test-hook={
                        reportProtocolModalTestSelectors.oralOncolyticsTab
                          .automaticFrequencyToggleTooltipContent
                      }
                    >
                      <li>
                        Automatic Oral Oncolytics Protocol is enabled if patient does not have an
                        active Report Protocol, and an order is detected (via EMR) for an oral
                        chemotherapy treatment.
                      </li>
                      <li>Report is requested 2 days after order is detected.</li>
                      <li>If that falls on a weekend, request is pushed to Monday.</li>
                    </StyledTooltipText>
                  }
                >
                  <Icon.Info
                    data-test-hook={
                      reportProtocolModalTestSelectors.oralOncolyticsTab
                        .automaticFrequencyToggleTooltipIcon
                    }
                  />
                </SimpleTooltip>
              </Box>
            }
            labelPosition={LabelPosition.RIGHT}
            checked={!!props.isAutomatic}
            disabled
            testHook={reportProtocolModalTestSelectors.oralOncolyticsTab.automaticFrequencyToggle}
          />
        )}
        {!isOralAutoProtocol && (
          <>
            <ProtocolIntervalFields showDaySelector forceWeekly forceSingleDaySelection />
            <ProtocolTimesMessageDivider
              weekdays={weekdays}
              startHour={selectedStartHour.value}
              startDate={selectedStartDate}
              isPhoneProtocol={props.type === ProtocolType.phone}
              intervalFrequency={WEEK_IN_MILLISECONDS}
            />
            <Text
              variant="body2"
              color="disabled"
              mb={12}
              display="inline-block"
              textTransform="uppercase"
            >
              Report Activation
            </Text>
            <div className="section">
              <RHFStyledInput
                type="date"
                label="Activation of Protocol"
                isRequired
                register={methods.register}
                error={Boolean(methods.formState.errors.selectedStartDate)}
                name="selectedStartDate"
              />
            </div>
            <div className="section">
              <EndProtocolOptions minEndDate={selectedStartDate} />
            </div>
          </>
        )}

        <div className="section">
          <div className="styled-input">
            <Text
              variant="body2"
              color="disabled"
              mb={12}
              display="inline-block"
              textTransform="uppercase"
            >
              Regimen
            </Text>
            <FormAutocomplete
              isDisabled={isOralAutoProtocol}
              label="Regimen"
              isRequired
              onChange={(option: SelectOption<number>) => {
                if (option && option?.value !== regimenTemplate?.id) {
                  const template = constantsStore.regimens.get(option.value);
                  methods.setValue('regimen', template);
                }
              }}
              options={constantsStore.regimenTemplatesForSelect}
              name="regimenId"
            />
          </div>
        </div>
        {!isOralAutoProtocol && (
          <>
            {regimenId && (
              <>
                <div className="section">
                  <RegimenSummaryField
                    isCustom={Boolean(finalRegimen?.isCustom)}
                    regimen={finalRegimen}
                    onCustomizeClicked={props.openRegimenModal}
                  />
                </div>
                <div className="section">
                  <RHFStyledInput
                    type="textarea"
                    label="Additional Regimen Instructions (Optional)"
                    register={methods.register}
                    name="notes"
                    maxLength={500}
                    error={Boolean(methods.formState.errors.notes)}
                  />
                </div>
              </>
            )}
            <div className="section">
              <FormAutocomplete
                sortAlphabetically={false}
                isRequired
                label="Fill Location"
                options={fillLocationOptions}
                name="fillLocation"
              />
              <RHFStyledInput
                type="number"
                min={1}
                max={120}
                label="Fill Frequency (Days)"
                error={Boolean(methods.formState.errors.fillFrequency)}
                isRequired
                register={methods.register}
                name="fillFrequency"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const StyledTooltipText = styled(`ul`)(
  ({ theme }) => css`
    margin: 0;
    padding: ${theme.spacing(16, 24)};
  `
);

export default observer(OralProtocolFields);
