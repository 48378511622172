import { FC } from 'react';

import { v4 as uuid } from 'uuid';

import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { OutlinedButton } from 'components/UIkit/atoms/Button';

interface Props {
  onClick: (questionUuid: string) => void;
}

export const PathwayAddCategoryButton: FC<Props> = ({ onClick }) => {
  const { selectPathwayEditorBlock } = usePathwayEditorSelection();

  const onInsertCategory = () => {
    const categoryUuid = uuid();
    onClick(categoryUuid);
    selectPathwayEditorBlock(categoryUuid);
  };

  return (
    <OutlinedButton mr={12} onClick={onInsertCategory}>
      Add Category Split
    </OutlinedButton>
  );
};
