class AbortSignalManager {
  controllers: Map<string, AbortController>;

  constructor() {
    this.controllers = new Map<string, AbortController>();
  }

  set(id: string) {
    const controller = new AbortController();
    this.controllers.set(id, controller);
  }

  getSignal(id: string) {
    const controller = this.controllers.get(id);
    if (controller) {
      return controller.signal;
    }
  }

  abort(id: string) {
    const controller = this.controllers.get(id);
    if (controller) {
      controller.abort();
      this.delete(id);
    }
  }

  has(id: string) {
    return this.controllers.has(id);
  }

  delete(id: string) {
    this.controllers.delete(id);
  }
}

const abortSignalManager = new AbortSignalManager();

export default abortSignalManager;
