import { FC, ReactNode } from 'react';

import { Link as MuiLink } from '@mui/material';

import { css } from '@mui/material';

import { styled } from '@mui/material/styles';

import { Testable } from 'utils/TypeUtils';

import { StyledLink } from 'components/UIkit/atoms/Link/StyledLink';
import { withSpacing } from 'components/UIkit/theme/spacing';

interface Props extends Testable {
  icon: ReactNode;
  iconPlacement?: 'left' | 'right';
  size?: 'medium' | 'large';
  variant?: 'primary' | 'secondary';
  href: string;
  children: string;
}

const BaseExternalLinkWithIcon: FC<Props> = ({
  icon,
  iconPlacement = 'left',
  size = 'medium',
  variant = 'primary',
  testHook,
  children,
  href
}) => (
  <MuiLink href={href} underline="hover" target="_blank">
    <StyledContainer
      iconPlacement={iconPlacement}
      data-test-hook={testHook}
      color={variant}
      variant={size === 'medium' ? 'button-medium-outline' : 'form-text'}
    >
      {iconPlacement === 'left' && icon}
      {children}
      {iconPlacement === 'right' && icon}
    </StyledContainer>
  </MuiLink>
);

const StyledContainer = styled(StyledLink, {
  shouldForwardProp: (prop) => prop !== 'iconPlacement'
})<{ iconPlacement: 'left' | 'right' }>(
  ({ theme, iconPlacement }) => css`
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;

    svg {
      margin-right: ${iconPlacement === 'left' ? theme.spacing(8) : 0};
      margin-left: ${iconPlacement === 'right' ? theme.spacing(8) : 0};
    }
  `
);

export const ExternalLinkWithIcon = withSpacing(BaseExternalLinkWithIcon);
