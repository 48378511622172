import { FC, MouseEvent, useState } from 'react';

import { Box, css, styled } from '@mui/material';

import { useFormContext } from 'react-hook-form';

import { useClickAway, useUpdateEffect } from 'react-use';

import { pathwayBuilderTestSelectors } from 'tests/models/pages/pathway-builder/pathway-builder-page.selectors';

import { BasicDepartmentInfo } from 'models/Department';

import { useSticky } from 'hooks/useSticky';

import { pathwayTypeOptions } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.constants';
import { StyledNoPointerEventsContainer } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.shared';

import { PathwayEditorFormFields } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import { useSymptomOptionsForPathway } from 'views/Pages/PathwayBuilder/PathwayEditorView/useSymptomOptionsForPathway';

import { RHFTagsInput } from 'views/Widgets/StyledSelect';

import Icon from 'components/Icons/Icon';
import { SimpleTooltip } from 'components/Tooltip';
import {
  FilledButton,
  FormExclusiveToggleButton,
  OutlinedIconButton
} from 'components/UIkit/atoms/Button';
import { FormMultiAutocomplete, MultiAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { reactSelectStyle } from 'components/UIkit/atoms/Dropdown/Select.styles';
import { Text } from 'components/UIkit/atoms/Text';
import { extraThemeOptions } from 'components/UIkit/theme/extraThemeOptions';

interface Props {
  onBackClick: () => void;
  onSavePathway: (editorValues: PathwayEditorFormFields) => void;
  onPreviewClick: () => void;
  isNewPathway: boolean;
  institutions: BasicDepartmentInfo[];
}

export const PathwayEditorHeader: FC<Props> = ({
  onBackClick,
  onSavePathway,
  onPreviewClick,
  isNewPathway,
  institutions
}) => {
  const [isHeaderSelected, setIsHeaderSelected] = useState(isNewPathway);
  const { stickyRef, isSticky } = useSticky();
  const symptomOptions = useSymptomOptionsForPathway();

  const { register, formState, handleSubmit, control, setFocus } =
    useFormContext<PathwayEditorFormFields>();

  useClickAway(stickyRef, () => setIsHeaderSelected(false));

  useUpdateEffect(
    function focusPathwayNameInput() {
      if (isHeaderSelected) {
        setFocus('title');
      }
    },
    [isHeaderSelected]
  );

  const onSaveClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleSubmit(onSavePathway)();
  };

  const isSaveButtonDisabled = !formState.isValid || !formState.isDirty;
  const isPreviewButtonDisabled = formState.isDirty || formState.submitCount === 0;
  const isFullHeaderVisible = !isSticky;

  return (
    <>
      <StyledContainer
        isSelected={isHeaderSelected}
        isError={Boolean(formState.errors.title)}
        isSticky={isSticky}
        ref={stickyRef}
        onClick={(event) => {
          event.stopPropagation();
          setIsHeaderSelected(true);
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Text variant="h1" component="h1" mr={4}>
              Pathway:
            </Text>

            <StyledTitleInput
              {...register('title', { required: true })}
              placeholder="Name"
              autoFocus={isNewPathway}
              data-test-hook={pathwayBuilderTestSelectors.pathwayEditor.header.nameInput}
            />
          </Box>

          <StyledBox isVisible={isFullHeaderVisible}>
            {isFullHeaderVisible && (
              <>
                <StyledSeparator />

                <Box display="flex" gap={20}>
                  <StyledNoPointerEventsContainer flex={1} isSelected={isHeaderSelected}>
                    <FormExclusiveToggleButton
                      name="type"
                      label="Pathway Type"
                      required
                      options={pathwayTypeOptions}
                      disabled={!isHeaderSelected}
                    />
                  </StyledNoPointerEventsContainer>

                  <StyledNoPointerEventsContainer flex={1} isSelected={isHeaderSelected}>
                    <FormMultiAutocomplete
                      name="institutions"
                      label="Customer"
                      isNarrow={false}
                      placeholder="All Customers"
                      isClearable
                      isDisabled={!isHeaderSelected}
                      options={institutions.map((institution) => ({
                        value: institution.id,
                        label: `${institution.name} (${institution.id})`
                      }))}
                    />
                  </StyledNoPointerEventsContainer>

                  <StyledNoPointerEventsContainer flex={1} isSelected={isHeaderSelected}>
                    <RHFTagsInput
                      name="keywords"
                      isDisabled={!isHeaderSelected}
                      register={register}
                      control={control}
                      styles={reactSelectStyle}
                      label="Related Keywords (for Searchability)"
                      placeholder={null}
                    />
                  </StyledNoPointerEventsContainer>

                  <Box flex={1}>
                    <SimpleTooltip
                      disabled={isHeaderSelected || symptomOptions.length <= 1}
                      title={
                        <Box pt={20} pr={20}>
                          <ul>
                            {symptomOptions.map((option) => (
                              <li key={option.value}>{option.label}</li>
                            ))}
                          </ul>
                        </Box>
                      }
                    >
                      <MultiAutocomplete
                        options={symptomOptions || []}
                        isDisabled
                        label="Map Symptoms to Pathway"
                        value={symptomOptions}
                      />
                    </SimpleTooltip>
                  </Box>
                </Box>
              </>
            )}
          </StyledBox>
        </Box>

        <StyledBackButtonContainer>
          <OutlinedIconButton icon={<Icon.Chevron left />} onClick={onBackClick}>
            Back
          </OutlinedIconButton>
        </StyledBackButtonContainer>

        <StyledPreviewButtonContainer>
          <SimpleTooltip
            disabled={!isPreviewButtonDisabled}
            title={<Box p={20}>Save Pathway to Preview</Box>}
          >
            <FilledButton disabled={isPreviewButtonDisabled} onClick={onPreviewClick}>
              Preview Pathway
            </FilledButton>
          </SimpleTooltip>
        </StyledPreviewButtonContainer>

        <StyledSaveButtonContainer>
          <SimpleTooltip
            disabled={isSaveButtonDisabled}
            title={<Box p={20}>Changes Saved Locally (Not Published)</Box>}
          >
            <FilledButton
              disabled={isSaveButtonDisabled}
              onClick={onSaveClick}
              testHook={pathwayBuilderTestSelectors.pathwayEditor.header.savePathwayButton}
            >
              Save Pathway
            </FilledButton>
          </SimpleTooltip>
        </StyledSaveButtonContainer>
      </StyledContainer>

      <StyledBackgroundHelper />
    </>
  );
};

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isError' && prop !== 'isSticky'
})<{ isSelected: boolean; isError: boolean; isSticky: boolean }>(
  ({ theme, isSelected, isError, isSticky }) => {
    const getBorderColor = () => {
      if (isError) {
        return theme.palette.error.dark;
      }

      if (isSelected) {
        return theme.palette.primary.main;
      }

      return 'transparent';
    };

    return css`
      position: sticky;
      top: 100px;
      transition: transform 0.3s;
      transform: ${isSticky ? 'scale(1.15)' : ''};
      z-index: 101;
      padding: ${theme.spacing(36, 20, 20, 20)};
      background-color: ${theme.palette.natural.white};
      box-shadow: ${isSticky ? theme.boxShadow.tooltip : theme.boxShadow.common};
      border: 1px solid ${getBorderColor()};
      border-radius: ${theme.borderRadius.large};

      &:hover {
        border-color: ${theme.palette.primary.main};
      }

      ${!isSelected &&
      css`
        cursor: pointer;
        * {
          cursor: pointer;
        }
      `}
    `;
  }
);

const StyledTitleInput = styled('input')(
  ({ theme }) => css`
    border: 0;
    font-weight: ${extraThemeOptions.fontWeights.medium};
    font-size: ${extraThemeOptions.fontSizes.xLarge};
    line-height: 28px;
    color: ${theme.palette.text.primary};
    padding: 0;
    flex: 1;

    &:focus {
      outline: 0;
    }
  `
);

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isVisible'
})<{ isVisible: boolean }>(
  ({ isVisible }) => css`
    ${!isVisible &&
    css`
      visibility: hidden;
      position: absolute;
    `}
  `
);

const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.natural.border};
    margin: ${theme.spacing(20, 0)};
    width: 100%;
  `
);

const StyledHeaderButtonContainer = styled(Box)`
  position: absolute;
  top: -18px;
  z-index: 100;
`;

const StyledBackButtonContainer = styled(StyledHeaderButtonContainer)`
  left: 20px;
`;

const StyledSaveButtonContainer = styled(StyledHeaderButtonContainer)`
  right: 20px;
`;

const StyledPreviewButtonContainer = styled(StyledHeaderButtonContainer)`
  right: 175px;
`;

const StyledBackgroundHelper = styled(Box)(
  ({ theme }) => css`
    position: fixed;
    left: 0;
    top: 45px;
    width: 100vw;
    height: 147px;
    z-index: 90;
    background-color: ${theme.palette.natural.contrast};
  `
);
