// @ts-strict-ignore
import { AxiosError } from 'axios';

import { toHipaaCompliantUrl } from 'utils/urlUtils';

import { ErrorName } from './Error.utils';
import { ErrorWithUi } from './ErrorWithUi';

function getMessage(axiosError: AxiosError) {
  const hipaaComplaintUrl = toHipaaCompliantUrl(axiosError.config.url);
  return `Could no get a response from the server (${hipaaComplaintUrl})`;
}
export class NoServerResponseError extends ErrorWithUi {
  readonly name = ErrorName.NoServerResponse;

  constructor(axiosError: AxiosError) {
    super(getMessage(axiosError), 'Something went wrong', 'Please refresh the browser');
  }
}
