import { PathwayQuestionTypes } from 'models/PathwayTemplates';

export interface PathwayEditorFormFields {
  id: string;
  title: string;
  categories: CategoryFormField[];
  keywords: PathwayKeyword[];
  institutions: PathwayInstitution[];
  type: PathwayType;
}

export interface CategoryFormField {
  categoryId: string;
  title: string;
  questions: QuestionFormField[];
}

export interface PathwayKeyword {
  value: string;
  label: string;
  id: string;
}

export interface PathwayInstitution {
  value: number;
  label: string;
}

export type QuestionFormField =
  | SingleQuestionFormField
  | MultipleQuestionFormField
  | TextQuestionFormField
  | TextArrayQuestionFormField
  | DateQuestionFormField
  | NewQuestionFormField;

//we added an uuid property because the question id is not unique enough because we may have the same question a couple of times.
//we don't use the rhf generated id of useFieldArray because when we update an item, this id changes. see https://github.com/react-hook-form/react-hook-form/issues/9076.
interface BaseQuestionFormField {
  uuid: string;
  questionId: string;
  title: { value: string; label: string };
  isKey: boolean;
  symptoms?: { value: number; label: string }[];
}

export type SubQuestionFormField = Omit<
  SingleQuestionFormField | MultipleQuestionFormField | NewQuestionFormField,
  'dependentQuestionsBlocks'
>;

export enum PathwayOptionAlert {
  NONE = 0,
  CLINIC_EMERGENT = 5,
  CLINIC = 4
}

export enum SuggestedPathwayUrgency {
  Low,
  High
}

export interface QuestionOptionAlert {
  value: PathwayOptionAlert;
  label: string;
}

export interface QuestionOptionSuggestedPathway {
  pathway: { value: string; label: string };
  urgency: SuggestedPathwayUrgency;
}

export interface CategoryQuestionOption {
  optionId: string;
  title: string;
  alert: QuestionOptionAlert;
  suggestedPathway: QuestionOptionSuggestedPathway;
}

export interface DependentQuestionsBlockType {
  uuid: string;
  triggers: { value: string; label: string }[] | null;
  questions: SubQuestionFormField[];
}

export interface NewQuestionFormField extends Omit<BaseQuestionFormField, 'title'> {
  uuid: string;
  type: 'NewQuestion';
  questionId: '';
  title: null;
  isKey: false;
}

export interface NewDependentQuestionsBlock extends Omit<DependentQuestionsBlockType, 'triggers'> {
  triggers: null;
  questions: [NewQuestionFormField];
}

export interface SingleQuestionFormField extends BaseQuestionFormField {
  type: PathwayQuestionTypes.SINGLE;
  options: CategoryQuestionOption[];
  dependentQuestionsBlocks?: (DependentQuestionsBlockType | NewDependentQuestionsBlock)[];
}

export interface MultipleQuestionFormField extends BaseQuestionFormField {
  type: PathwayQuestionTypes.MULTIPLE;
  options: CategoryQuestionOption[];
  dependentQuestionsBlocks?: (DependentQuestionsBlockType | NewDependentQuestionsBlock)[];
}

export interface TextQuestionFormField extends BaseQuestionFormField {
  type: PathwayQuestionTypes.TEXT;
}

export interface TextArrayQuestionFormField extends BaseQuestionFormField {
  type: PathwayQuestionTypes.TEXT_ARRAY;
}

export interface DateQuestionFormField extends BaseQuestionFormField {
  type: PathwayQuestionTypes.DATE;
}

export enum PathwayCategoryShowConditionType {
  Score = 'SCORE',
  AlertExact = 'ALERT_EXACT',
  AlertMinimum = 'ALERT_MINIMUM'
}

export enum PathwayType {
  Symptom = 'symptom',
  CareManagement = 'care-management',
  AccumulatedScore = 'accumulated-score',
  DecreasedScore = 'decreasing-score'
}
