import { css, Theme } from '@mui/material/styles';

export const inputStyle = (theme: Theme) => css`
  border: 1px solid ${theme.palette.natural.border};
  outline: none;
  box-shadow: none;
  &:not(:disabled):focus {
    border-color: ${theme.palette.primary.main};
  }
`;
