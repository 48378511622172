import { FC } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';

import { observer } from 'mobx-react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useStores } from 'mobx/hooks/useStores';

import { useWqOpenItemsSection } from 'hooks/useWqOpenItemsSection';

import { StyledSectionActionsContainer } from 'views/WorkQueue/WorkQueue.styled';
import { WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

import { WorkQueueSectionName } from 'views/WorkQueue/WorkQueue.types';

import WqCollapsibleSection from 'views/WorkQueue/WorkQueueOpenItemsView/WqCollapsibleSection';
import { WqSectionControl } from 'views/WorkQueue/WorkQueueOpenItemsView/WqSectionControl';
import { WqTicketPlaceholder } from 'views/WorkQueue/WqTicketPlaceholder';

import { ClusteredTasksItem } from 'components/Ticket/TicketRow/ClusteredTasksItem';
import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';
import TicketsSectionTasksDueFilters from 'components/Ticket/TicketsContainers/TicketsSectionTasksDueFilters';

const WqTasksSection: FC = () => {
  const { totalPages, totalItems, totalPatients, items, currentPage, isSectionPageLoading } =
    useWqOpenItemsSection(WorkQueueSectionName.TasksDue);
  const { ticketFiltersStore, workQueueStore } = useStores();

  const onFromDateChanged = async (date: Date | null) => {
    ticketFiltersStore.setQueryFromDate(date);
    await workQueueStore.handleSectionPageChange(WorkQueueSectionName.TasksDue);
  };

  const onToDateChanged = async (date: Date | null) => {
    ticketFiltersStore.setQueryToDate(date);
    await workQueueStore.handleSectionPageChange(WorkQueueSectionName.TasksDue);
  };

  return (
    <div className="report-section">
      <WqCollapsibleSection
        sectionName={Section.TasksDue}
        totalItems={totalItems}
        totalPatients={totalPatients}
        onTrigger={(isOpen) =>
          trackSectionUsageAnalyticsEvent({
            action: isOpen ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
            value: Section.TasksDue,
            page_number: currentPage,
            tab: WorkQueueTabName.OpenItems
          })
        }
        testHook="tasks-section"
      >
        <StyledSectionActionsContainer>
          <TicketsSectionTasksDueFilters
            onFromDateChanged={onFromDateChanged}
            onToDateChanged={onToDateChanged}
          />

          {totalPages > 1 && <WqSectionControl sectionName={WorkQueueSectionName.TasksDue} />}
        </StyledSectionActionsContainer>

        <div className="tickets-list">
          <TransitionGroup>
            {items.map(({ itemType, itemData }, index) => (
              <CSSTransition key={itemData.id} classNames="connect-ticket-transition" timeout={600}>
                {isSectionPageLoading ? (
                  <WqTicketPlaceholder />
                ) : (
                  <ClusteredTasksItem
                    clusteredTask={itemData}
                    ticketIndex={index}
                    ticketSectionCurrentPage={currentPage - 1}
                  />
                )}
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>

        {totalPages > 1 && (
          <WqSectionControl sectionName={WorkQueueSectionName.TasksDue} isBottom />
        )}
      </WqCollapsibleSection>
    </div>
  );
};

export default observer(WqTasksSection);
