import { FC } from 'react';

import Icon from 'components/Icons/Icon';
import { Tooltip } from 'components/Tooltip';

interface TooltipTableHeaderProps {
  headerText: string;
  tooltipText: string;
}

const TooltipTableHeader: FC<TooltipTableHeaderProps> = ({ tooltipText, headerText }) => {
  return (
    <div className="d-flex align-items-center justify-content-between text-nowrap">
      <span className="mr-1">{headerText}</span>
      <Tooltip placement="top" label={<Icon.Info />}>
        <div className="p-2">{tooltipText}</div>
      </Tooltip>
    </div>
  );
};

export default TooltipTableHeader;
