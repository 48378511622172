import { FC } from 'react';

import { styled, css } from '@mui/material';

import { Box, Skeleton } from '@mui/material';

export const WqTicketPlaceholder: FC = () => (
  <StyledContainer>
    <StyledCircularSkeleton variant="circular" width={24} height={24} animation="wave" />

    <Box width="180px" mr={76}>
      <StyledTextSkeleton variant="rectangular" width={100} height={16} animation="wave" />
      <StyledTextSkeleton variant="rectangular" width={100} height={16} animation="wave" />
    </Box>

    <Box flex={1} mr={100}>
      <StyledTextSkeleton variant="rectangular" width={336} height={20} animation="wave" />
      <StyledTextSkeleton variant="rectangular" width="100%" height={12} animation="wave" />
      <StyledTextSkeleton variant="rectangular" width="85%" height={12} animation="wave" />
    </Box>
  </StyledContainer>
);

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.palette.natural.inactiveBackground};
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    padding: ${theme.spacing(20)};
    margin-bottom: ${theme.spacing(24)};

    &:last-of-type {
      margin-bottom: ${theme.spacing(16)};
    }
  `
);

const StyledCircularSkeleton = styled(Skeleton)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(24)};
    background-color: ${theme.palette.natural.inactiveBackground};
  `
);

const StyledTextSkeleton = styled(Skeleton)(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.large};
    background-color: ${theme.palette.natural.inactiveBackground};

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(12)};
    }
  `
);
