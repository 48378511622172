// @ts-strict-ignore
import { useMemo } from 'react';

import { TableState, HeaderGroup } from 'react-table';

import { getCurrentSortStringByUrl, getCurrentSortByUrl, UrlSortParams } from 'utils/urlUtils';

export function useSortOverrides() {
  const currentSortStringByUrl = getCurrentSortStringByUrl();

  const sortingOverrides: Pick<TableState, 'sortBy'> = {
    sortBy: useMemo(() => {
      const currentSortByUrl = getCurrentSortByUrl();
      const value = [];

      if (currentSortByUrl) {
        value.push({
          id: currentSortByUrl.sortBy,
          desc: !currentSortByUrl.sortAsc
        });
      }
      return value;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSortStringByUrl])
  };
  return sortingOverrides;
}

export function getColumnHeaderSortProps(
  column: HeaderGroup<any>,
  manualSort: boolean,
  setSortInUrl: (params: UrlSortParams) => void,
  onManualSort: (column: HeaderGroup<any>, sortParams: UrlSortParams) => void
) {
  const sortProps = column.getSortByToggleProps();

  if (manualSort && column.canSort) {
    const defaultOnClick = sortProps.onClick;
    (sortProps as any).onClick = (args: any) => {
      let newSortParams: UrlSortParams = null;

      const sortedDesc = column.isSorted && column.isSortedDesc;

      if (!sortedDesc) {
        newSortParams = {
          sortBy: column.id,
          sortAsc: !column.isSorted // toggle order
        };
      }

      setSortInUrl && setSortInUrl(newSortParams);

      if (onManualSort) {
        onManualSort(column, newSortParams);
      }

      defaultOnClick(args);
    };
  }
  return sortProps;
}
