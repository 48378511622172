import { transformErrorUiProps } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { EpisodesParser } from 'parsers/EpisodesParser';

export interface CreateTaskTemplateAttrs {
  episodeId: number;
  name: string;
  description?: string;
  roleId: number;
  monthNumber: number;
  duplicateMonthly?: boolean;
}

export interface UpdateTaskTemplateAttrs extends CreateTaskTemplateAttrs {}

export interface DuplicateTaskTemplateAttrs {
  monthNumber?: number;
}

const httpService = new HttpService('task template', true);

class TaskTemplatesFetcher {
  static async createTaskTemplate(data: CreateTaskTemplateAttrs) {
    return await httpService.post({
      url: API_URLS.TASK_TEMPLATES,
      data,
      transformResponse: EpisodesParser.parseTaskTemplate /* Remove if not needed */
    });
  }

  static async updateTaskTemplate(id: number, data: UpdateTaskTemplateAttrs) {
    return await httpService.put({ url: API_URLS.UPDATE_TASK_TEMPLATE(id), data });
  }

  static async deleteTaskTemplate(id: number) {
    return await httpService.delete({ url: API_URLS.DELETE_TASK_TEMPLATE(id) });
  }

  static async duplicateTaskTemplate(id: number, data: DuplicateTaskTemplateAttrs) {
    return await httpService.post({
      url: API_URLS.DUPLICATE_TASK_TEMPLATE(id),
      data,
      transformError: transformErrorUiProps('Failed to Duplicate Task Template')
    });
  }
}

export default TaskTemplatesFetcher;
