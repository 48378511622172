import { FC } from 'react';

import { css, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
  title: string;
  subtitle: string;
}

// TODO: replace all cards with this wonderful staff (eh-3360)
export const CardTextRow: FC<Props> = ({ title, subtitle }) => (
  <>
    <Typography variant="h4" component="span">
      {title}
    </Typography>
    {subtitle && (
      <StyledTypography variant="form-text" ml={4}>
        {' '}
        — {subtitle}
      </StyledTypography>
    )}
  </>
);

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.disabled};
  `
);
