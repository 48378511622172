// @ts-strict-ignore
import moment from 'moment';

import { DataMap, RemainingDosesOption } from 'mobx/stores';

import { isSameDay } from 'utils/DateUtils';

import { getMissedDosesReasonsText, getRegimenText } from 'utils/OralOncoUtils';

import MissedDosesReasons from 'models/MissedDosesReasons';
import { OralReportVersion, ReportQuestionAnswer } from 'models/OralReport/OralReport.shared';

import { OralReportV1 } from 'models/OralReport/OralReportV1';
import { OralQuestionTypeV2, OralReportV2 } from 'models/OralReport/OralReportV2';

import {
  ReportTableCellData,
  ReportTableCellDataColor,
  ReportTableIcon,
  ReportTableOralChemoSection,
  ReportTableSymptomSection,
  TableReportItem
} from './ReportTable.model';

const FIRST_PILL_UNKNOWN = 'Start of Cycle Date Unknown';

function getNewCycleTooltip(date: Date, oral: OralReportV1 | OralReportV2): string {
  const { regimen, isCustom } = oral;
  const regimenStr = getRegimenText(regimen, isCustom);
  const dateStr = moment(date).format('MMM Do, YYYY');
  return `New ${regimenStr} Cycle: ${dateStr}`;
}

// new cycle report (created when first pill date is not the same as report date) contain only start of cycle date
export function addNewCycleReportData(
  oralChemoSection: ReportTableOralChemoSection,
  symptomsSection: ReportTableSymptomSection,
  report: TableReportItem
) {
  oralChemoSection.NewCycle.push({
    color: ReportTableCellDataColor.gray,
    iconContent: ReportTableIcon.pill,
    tooltip: getNewCycleTooltip(report.createdAt, report.answer.oral)
  });

  if (report.answer.version === OralReportVersion.V2) {
    oralChemoSection.ActiveMedication.push(null);
    oralChemoSection.DosesRemaining.push(null);
  } else {
    oralChemoSection.MedReceived.push(null);
  }

  oralChemoSection.RegimenAdherence.push(null);
  oralChemoSection.ChangeInOtherMeds.push(null);
  oralChemoSection.MissedDose.push(null);

  Object.keys(symptomsSection).forEach((causeName: string) => {
    symptomsSection[causeName].push(null);
  });
}

function getNewCycleOralCellData(
  reportedOnDifferentDay: boolean,
  oral?: OralReportV1 | OralReportV2
): ReportTableCellData {
  if (!oral) {
    return null;
  }

  const { newCycleDate } = oral;
  const unknownCycleDate =
    !newCycleDate &&
    ((oral as OralReportV1).firstPill?.isIDontKnow ||
      (oral as OralReportV2)[OralQuestionTypeV2.StartOfCycle]?.isIDontKnow);
  let cellData: ReportTableCellData = null;

  const shouldDisplay = (!!newCycleDate || unknownCycleDate) && !reportedOnDifferentDay;
  if (shouldDisplay) {
    cellData = {
      color: unknownCycleDate ? ReportTableCellDataColor.purple : ReportTableCellDataColor.gray,
      iconContent: unknownCycleDate ? ReportTableIcon.question : ReportTableIcon.pill,
      tooltip: unknownCycleDate ? FIRST_PILL_UNKNOWN : getNewCycleTooltip(newCycleDate, oral)
    };
  }

  return cellData;
}

function getOralCellData(
  answer?: ReportQuestionAnswer<any>,
  tooltipData?: string
): ReportTableCellData {
  let cellData: ReportTableCellData = null;
  if (answer?.isAnswered) {
    cellData = {
      color: answer.isOk ? ReportTableCellDataColor.green : ReportTableCellDataColor.red,
      tooltip: tooltipData || answer.customMessage
    };
  }
  return cellData;
}

function getOralMissedDosesCellData(
  missedDoses: ReportQuestionAnswer<number>,
  missedDosesReasonsMap: DataMap<MissedDosesReasons>
): ReportTableCellData {
  const cellData: ReportTableCellData = getOralCellData(missedDoses);
  if (missedDoses?.isAnswered && missedDoses.value > 0) {
    cellData.content = missedDoses.value;
    cellData.tooltip = getMissedDosesReasonsText(missedDoses, missedDosesReasonsMap);
  }
  return cellData;
}

export function addOralReportData(
  oralChemoSection: ReportTableOralChemoSection,
  report: TableReportItem,
  missedDosesReasonsMap: DataMap<MissedDosesReasons>,
  remainingDoseOptions: DataMap<RemainingDosesOption>
) {
  const { oral } = report.answer;
  const newCycleDate = oral?.newCycleDate;
  const reportedOnDifferentDay = newCycleDate && !isSameDay(newCycleDate, report.createdAt);

  let dataCell = getNewCycleOralCellData(reportedOnDifferentDay, oral);
  oralChemoSection.NewCycle.push(dataCell);

  if (report.answer.version === OralReportVersion.V2) {
    const oralReportV2 = oral as OralReportV2;
    dataCell = getOralCellData(oralReportV2?.[OralQuestionTypeV2.ActiveMedication]);
    oralChemoSection.ActiveMedication.push(dataCell);
    dataCell = getOralCellData(
      oralReportV2?.[OralQuestionTypeV2.DosesRemaining],
      remainingDoseOptions.get(oralReportV2?.[OralQuestionTypeV2.DosesRemaining]?.value)?.text
    );
    oralChemoSection.DosesRemaining.push(dataCell);
    dataCell = getOralCellData(oralReportV2?.[OralQuestionTypeV2.RegimenAdherence]);
    oralChemoSection.RegimenAdherence.push(dataCell);
    dataCell = getOralCellData(oralReportV2?.[OralQuestionTypeV2.OtherMedicationChange]);
    oralChemoSection.ChangeInOtherMeds.push(dataCell);
  } else {
    const oralReportV1 = oral as OralReportV1;
    dataCell = getOralCellData(oralReportV1?.medReceived);
    oralChemoSection.MedReceived.push(dataCell);
    dataCell = getOralCellData(oralReportV1?.regimenAsPrescribed);
    oralChemoSection.RegimenAdherence.push(dataCell);
    dataCell = getOralCellData(oralReportV1?.changesInOtherMeds);
    oralChemoSection.ChangeInOtherMeds.push(dataCell);
  }

  dataCell = getOralMissedDosesCellData(oral?.missedDoses, missedDosesReasonsMap);
  oralChemoSection.MissedDose.push(dataCell);
}

// if at least one report has oral data - oral section will be displayed
export function displayOralSection(oralChemoSection: ReportTableOralChemoSection) {
  return (
    oralChemoSection.ChangeInOtherMeds.some((dataCell) => dataCell !== null) ||
    oralChemoSection.MedReceived.some((dataCell) => dataCell !== null) ||
    oralChemoSection.MissedDose.some((dataCell) => dataCell !== null) ||
    oralChemoSection.NewCycle.some((dataCell) => dataCell !== null) ||
    oralChemoSection.RegimenAdherence.some((dataCell) => dataCell !== null) ||
    oralChemoSection.ActiveMedication.some((dataCell) => dataCell !== null) ||
    oralChemoSection.DosesRemaining.some((dataCell) => dataCell !== null)
  );
}
