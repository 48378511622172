import { FC, ReactNode } from 'react';

import { chunk } from 'lodash/fp';
import { observer } from 'mobx-react';

import { Testable } from 'utils/TypeUtils';

import './StyledFieldForm.scss';

interface IProps extends Testable {
  mainField: ReactNode;
  extraFields: ReactNode[];
}

const FIELDS_PER_ROW = 2;

const StyledFieldsForm: FC<IProps> = ({ mainField, extraFields, testHook }) => {
  const chunks = chunk(FIELDS_PER_ROW, extraFields);
  return (
    <div className="styled-fields-wrapper" data-test-hook={testHook}>
      <div className="field large">{mainField}</div>
      <div className="styled-additional-fields">
        {chunks.map((chunk, index) => {
          return (
            <div className="fields-row" key={`row_${index}`}>
              {chunk.map((field, fieldIndex) => (
                <div className="field" key={`field_${index}_${fieldIndex}`}>
                  {field}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(StyledFieldsForm);
