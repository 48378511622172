import { FC, FocusEvent, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import {
  trackCheckboxInputUsageAnalyticsEvent,
  trackOpenTextInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';
import { ErrorName } from 'errors';
import { observer } from 'mobx-react';

import { FormProvider, useController, useFormContext } from 'react-hook-form';

import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';

import { useStores } from 'mobx/hooks/useStores';
import { HomeCareInstructionActionType } from 'mobx/stores';

import { PatientCommunicationFetcher } from 'fetchers/PatientCommunicationFetcher';

import { fromNow } from 'utils/DateUtils';

import { getFormattedPhoneNumber } from 'utils/PhoneUtils';

import { convertHtmlToMarkdown, pluralize } from 'utils/StringUtils';

import { showToast } from 'utils/UserMessageUtils';

import Patient from 'models/Patient';

import { HOMECARE_INSTRUCTIONS_TEXTAREA_MAX_LENGTH } from 'views/Widgets/CallLogging/CallLogging.constants';

import ExpandableRow from 'views/Widgets/ExpandableRow';
import StyledInput from 'views/Widgets/StyledInput';

import StyledPhoneInput from 'views/Widgets/StyledPhoneInput';

import Icon from 'components/Icons/Icon';
import { OutlinedIconButton } from 'components/UIkit/atoms/Button';
import { FormLabeledCheckbox } from 'components/UIkit/atoms/Checkbox';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  patient: Patient;
}

export interface HomeCareInstructionsFieldValues {
  phone: string;
  text: string;
  messagingConsent: boolean;
  countryCode: string;
}

export const HomeCareInstructionsSection: FC<Props> = observer(({ patient }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { pathwaysStore, callLoggingStore, settingsStore } = useStores();
  const [isExpanded, setIsExpanded] = useState(
    Boolean(pathwaysStore.homeCareInstructionsAnswers.length > 0)
  );
  const isTestInstitution = settingsStore.institutionSettings.isTest;
  const methods = useFormContext<HomeCareInstructionsFieldValues>();
  const { getValues, setValue, resetField, control, formState, handleSubmit, watch } = methods;
  const { field } = useController({
    control,
    name: 'text'
  });
  const [currentText, currentMessagingConsent] = watch(['text', 'messagingConsent']);

  useEffect(
    function autoExpandSectionOnAddHomeCareInstructionsAnswer() {
      if (pathwaysStore.homeCareInstructionsAnswers.length > 0) {
        setIsExpanded(true);
      }
    },
    [pathwaysStore.homeCareInstructionsAnswers]
  );

  const removeMarkdownsFromText = (text: string, titlesToRemove: string[] = []) => {
    return titlesToRemove
      .map((title) => convertHtmlToMarkdown(title))
      .reduce((updatedText, markdown) => updatedText.replace(markdown, ''), text);
  };

  useEffect(
    function handleHomeCareInstructionsPathwayAnswerChanged() {
      const homeCareInstructionsDraftText = getValues('text');

      switch (pathwaysStore.lastHomeCareInstructionsOptionClicked.action) {
        case HomeCareInstructionActionType.Add: {
          const markdown = convertHtmlToMarkdown(
            pathwaysStore.lastHomeCareInstructionsOptionClicked.title
          );

          let newHomeCareInstructionsDraftText = homeCareInstructionsDraftText.trim();

          if (newHomeCareInstructionsDraftText) {
            newHomeCareInstructionsDraftText += '\n\n';
          }

          newHomeCareInstructionsDraftText += markdown;

          setValue('text', newHomeCareInstructionsDraftText.trim());

          break;
        }
        case HomeCareInstructionActionType.Remove: {
          const { titlesToRemove } = pathwaysStore.lastHomeCareInstructionsOptionClicked;

          const newHomeCareInstructionsDraftText = removeMarkdownsFromText(
            homeCareInstructionsDraftText.trim(),
            titlesToRemove
          );

          setValue('text', newHomeCareInstructionsDraftText.trim());
          break;
        }
        case HomeCareInstructionActionType.Replace: {
          const markdown = convertHtmlToMarkdown(
            pathwaysStore.lastHomeCareInstructionsOptionClicked.title
          );

          let newHomeCareInstructionsDraftText = homeCareInstructionsDraftText.trim();

          newHomeCareInstructionsDraftText += markdown;

          const { titlesToRemove } = pathwaysStore.lastHomeCareInstructionsOptionClicked;

          newHomeCareInstructionsDraftText = removeMarkdownsFromText(
            newHomeCareInstructionsDraftText.trim(),
            titlesToRemove
          );

          setValue('text', newHomeCareInstructionsDraftText.trim());
          break;
        }
      }
    },
    [pathwaysStore.lastHomeCareInstructionsOptionClicked, setValue, getValues, pathwaysStore]
  );

  const onSubmit = async (fieldValues: HomeCareInstructionsFieldValues) => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.Send,
      virtual_page: 'call logger',
      patient_id: patient.id
    });

    try {
      setIsLoading(true);

      if (fieldValues.messagingConsent !== patient.hasMessagingConsent) {
        await PatientCommunicationFetcher.updateMessagingConsent(
          patient.id,
          fieldValues.messagingConsent
        );
      }

      showToast({
        message: 'Sending Homecare Instructions'
      });

      const { createdAt, id } = await PatientCommunicationFetcher.sendHomeCareInstructions(
        patient.id,
        {
          text: fieldValues.text,
          phoneNumber: fieldValues.countryCode + fieldValues.phone,
          ...(callLoggingStore.currentCall?.id && { callId: callLoggingStore.currentCall.id })
        }
      );

      const newHomeCareInstructions = [
        ...(callLoggingStore.currentCall.homeCareInstructions || [])
      ];
      newHomeCareInstructions.push({
        id,
        patientId: patient.id,
        createdAt,
        text: fieldValues.text,
        phoneNumber: fieldValues.countryCode + fieldValues.phone,
        ...(callLoggingStore.currentCall?.id && { callId: callLoggingStore.currentCall.id })
      });

      callLoggingStore.updateCurrentCall({
        homeCareInstructions: newHomeCareInstructions
      });

      resetField('text', { defaultValue: '' });
    } catch (error: any) {
      if (error.name === ErrorName.NotMobilePhone) {
        error.ui.title = 'Cannot Send SMS to Landline Phone';
        error.ui.description = 'Please replace the phone number with a mobile phone number.';
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSectionToggle = () => {
    trackSectionUsageAnalyticsEvent({
      action: isExpanded ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
      value: 'Homecare Instructions',
      virtual_page: 'call logger'
    });

    setIsExpanded(!isExpanded);
  };

  const homeCareInstructionsLength = pathwaysStore.homeCareInstructionsAnswers.length;

  return (
    <ExpandableRow
      testHooks={{
        container: patientPageTestSelectors.callLogger.homeCareInstructionsSection.container,
        header: patientPageTestSelectors.callLogger.homeCareInstructionsSection.header
      }}
      isExpanded={isExpanded}
      onToggleExpand={handleSectionToggle}
      title={
        <Text>
          <Text variant="h4">Homecare instructions</Text>

          {homeCareInstructionsLength > 0 && (
            <Text variant="h4" color="secondary">{` — ${homeCareInstructionsLength} ${pluralize(
              'Pathway Response',
              homeCareInstructionsLength
            )}
            Added`}</Text>
          )}
        </Text>
      }
    >
      <Box>
        {callLoggingStore.currentCall.homeCareInstructions?.map((item) => {
          const relativeTime = fromNow(new Date(item.createdAt));
          const formattedPhoneNumber = getFormattedPhoneNumber(item.phoneNumber);

          return (
            <StyledSentHomeCareInstructionsContainer key={item.id}>
              <Text component="div" color="secondary" mb={2}>
                Sent{' '}
                <Text color="secondary" textTransform="capitalize">
                  {relativeTime}
                </Text>{' '}
                to {formattedPhoneNumber}
              </Text>

              <Text variant="form-text" color="secondary">
                {item.text}
              </Text>

              <StyledSeparator />
            </StyledSentHomeCareInstructionsContainer>
          );
        })}

        <FormProvider {...methods}>
          <StyledFormTextAreaField
            placeholder="Start typing homecare instructions here. Relevant responses will be added automatically."
            onChange={field.onChange}
            onBlur={(
              _event: FocusEvent<HTMLInputElement>,
              currentValue: string,
              valueAfterFocus: string
            ) =>
              trackOpenTextInputUsageAnalyticsEvent(
                currentValue,
                valueAfterFocus,
                'Homecare Instructions Text Message',
                { patient_id: patient.id }
              )
            }
            name={field.name}
            value={field.value}
            maxLength={HOMECARE_INSTRUCTIONS_TEXTAREA_MAX_LENGTH}
            type="textarea"
            testHook={patientPageTestSelectors.callLogger.homeCareInstructionsSection.textarea}
          />

          <Box display="flex">
            <Box display="flex" alignItems="center" gap={8}>
              <Box width="410px">
                <FormLabeledCheckbox
                  label={`Patient consents to receive updates via text message${
                    currentMessagingConsent ? ':' : ''
                  }`}
                  name="messagingConsent"
                  required
                  testHook={
                    patientPageTestSelectors.callLogger.homeCareInstructionsSection
                      .messagingConsentCheckbox
                  }
                  onChange={() =>
                    trackCheckboxInputUsageAnalyticsEvent(
                      currentMessagingConsent,
                      'Homecare Instructions Consent'
                    )
                  }
                />
              </Box>

              <StyledFormPhoneInputField
                withCountryFlags={isTestInstitution}
                showPlaceholderOnError
                error={Boolean(formState.errors.phone)}
                disabled={!currentMessagingConsent}
                testHook={
                  patientPageTestSelectors.callLogger.homeCareInstructionsSection.phoneNumberInput
                }
                onBlur={(_event, valueAfterFocus, currentValue) => {
                  trackOpenTextInputUsageAnalyticsEvent(
                    currentValue,
                    valueAfterFocus,
                    'Homecare Instructions Phone Number',
                    { patient_id: patient.id }
                  );
                }}
              />
            </Box>

            <OutlinedIconButton
              ml="auto"
              icon={<Icon.Send />}
              onClick={handleSubmit(onSubmit)}
              hasBoxShadow={false}
              disabled={!Boolean(currentText.trim()) || isLoading}
              testHook={patientPageTestSelectors.callLogger.homeCareInstructionsSection.sendButton}
            >
              Send
            </OutlinedIconButton>
          </Box>
        </FormProvider>
      </Box>
    </ExpandableRow>
  );
});

const StyledSentHomeCareInstructionsContainer = styled(Box)(
  ({ theme }) => css`
    white-space: break-spaces;

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(24)};
    }
  `
);

const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    margin-top: ${theme.spacing(24)};
    margin-bottom: 0;
  `
);

const StyledFormTextAreaField = styled(StyledInput)`
  &.styled-input {
    margin-bottom: 34px;

    .input-area textarea {
      border: none;
      outline: 0;
      padding: 2px;
      min-height: auto;
      field-sizing: content;
      height: fit-content;

      &:focus {
        border: none;
      }
    }
  }
`;

const StyledFormPhoneInputField = styled(StyledPhoneInput)(
  ({ theme }) => css`
    &.phone-input .phone-input-number-container {
      flex: 1;
      border: none;
      border-bottom: 1px solid ${theme.palette.natural.border};
      border-radius: 0;
      height: fit-content;

      .phone-number-text {
        margin: 0;
        height: 23px;
        max-width: 102px;
      }

      &.focused {
        border: none;
        border-bottom: 1px solid ${theme.palette.primary.main};
      }

      &.error,
      &.invalid {
        border: none;
        border-bottom: 1px solid ${theme.palette.error.main};
      }

      .country-container {
        display: flex;
        padding: 0;
        margin-right: ${theme.spacing(4)};
      }

      &.disabled {
        background-color: transparent;
        cursor: initial;
        user-select: initial;
        border-color: ${theme.palette.natural.border};

        .phone-number-text {
          background-color: transparent;
          color: ${theme.palette.text.disabled};
        }

        &.focused {
          border-color: ${theme.palette.natural.border};
        }
      }
    }
  `
);
