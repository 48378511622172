// @ts-strict-ignore

import { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import AbortSignalManager from 'utils/AbortSignalManager';
import { getClinicianFullName, getNameWithCredentials } from 'utils/ClinicianCredentialUtils';

import {
  DATE_ONLY_FORMAT,
  formatDate,
  fromNow,
  longDateTimeFormat,
  removeTimeZone,
  shortDateTimeFormat
} from 'utils/DateUtils';

import { CallbackType } from 'models/CallbackTicket';

import { OperatorTicketSource } from 'models/OperatorTicket';
import QuestionnaireAnswer, { ReportSource } from 'models/QuestionnaireAnswer';
import ScheduledProtocol, { ProtocolType } from 'models/ScheduledProtocol';
import Ticket, { TicketClass, TicketStatus, ticketStatusToString } from 'models/Ticket';
import { TicketHistoryAudit, AuditActions, Clinician } from 'models/TicketHistoryAudit';

import { usePatientModel } from 'components/Patient/usePatientModel';
import { TicketRowDateTooltip } from 'components/Ticket/TicketRow/shared';

interface CreatedByProps {
  ticket: Ticket;
  report?: QuestionnaireAnswer | null;
  protocol?: ScheduledProtocol | null;
}

const protocolTexts = {
  [ProtocolType.phone]: 'via Report Line',
  [ProtocolType.mobile]: 'via App',
  [ProtocolType.chatbot]: 'via SMS Chatbot'
};

const reportSourceToText = {
  [ReportSource.Mobile]: 'via App',
  [ReportSource.Web]: 'via Web',
  [ReportSource.Ivr]: 'via Report Line'
};

const TICKET_HISTORY_REQUEST_LABEL = 'TicketCreatedBy.getTicketHistory';

const TicketCreatedBy = ({ ticket, report, protocol }: CreatedByProps) => {
  const requestIdForTicket = `${TICKET_HISTORY_REQUEST_LABEL}.${ticket.id}`;
  const [error, setError] = useState(null);
  const [history, setHistory] = useState<TicketHistoryAudit[] | null>(null);
  const { ticketsStore, constantsStore } = useStores();
  const { clinicianCredential } = constantsStore;
  const patient = usePatientModel(ticket.patientId);
  const { operatorTicket, resolvedDate } = ticket;
  const protocolText = protocolTexts[protocol?.type] || '';
  const reportSource = reportSourceToText[report?.source] || protocolText;
  const createdAt = new Date(report?.createdAt || ticket.createdAt);
  const formattedCreateTime = ticket.isClosedOrResolved
    ? formatDate(createdAt, shortDateTimeFormat)
    : fromNow(createdAt, { short: true });

  useEffect(
    function abortTicketRequest() {
      return () => {
        AbortSignalManager.abort(requestIdForTicket);
      };
    },
    [requestIdForTicket]
  );

  const getCallbackReportSource = () =>
    ticket.callbackTicket.callbackType === CallbackType.DobMismatchIvr
      ? 'via Report Line'
      : 'via Web';

  const getFormattedCreatingClinician = () => {
    if (ticket.isCallbackRequestTicket) {
      return getCallbackReportSource();
    }

    if (!operatorTicket) {
      return reportSource;
    }

    if (operatorTicket.source === OperatorTicketSource.Patient) {
      return 'via App';
    }

    return `By ${getClinicianFullName(operatorTicket.reportingDoctor)}`;
  };

  const formattedCreatingClinician = getFormattedCreatingClinician();

  const getClinicianFullNameWithCredentials = (clinician: Clinician) => {
    const fullName = getClinicianFullName(clinician);
    const credential = clinicianCredential.get(clinician.credentialId);
    const credentialString = credential ? credential.text : null;
    return clinician ? getNameWithCredentials(fullName, credentialString) : 'Unknown';
  };

  const renderByClinicianSection = (audit: TicketHistoryAudit) => (
    <>
      &nbsp;
      {formatDate(audit.createdAt, longDateTimeFormat)}
      {audit.action === AuditActions.ACTION_CREATE_TICKET &&
      (operatorTicket?.source === OperatorTicketSource.Patient ||
        ticket.class === TicketClass.patient) ? (
        <span> via App</span>
      ) : (
        <>
          <span> by </span>
          {getClinicianFullNameWithCredentials(audit.performedByClinician)}
        </>
      )}
    </>
  );

  const getHistoryLineContent = (audit: TicketHistoryAudit) => {
    let actionTxt = audit.getActionStr();

    if (audit.action === AuditActions.ACTION_REASSIGN_TICKET && audit.newClinician) {
      actionTxt += ` to ${getClinicianFullNameWithCredentials(audit.newClinician)}`;
    }

    if (audit.action === AuditActions.ACTION_TICKET_STATUS_CHANGE) {
      actionTxt += ` ${ticketStatusToString[audit.actionDetails.previous as TicketStatus]} to ${
        ticketStatusToString[audit.actionDetails.updated as TicketStatus]
      }`;
    }

    if (audit.action === AuditActions.ACTION_TASK_RESCHEDULE) {
      actionTxt += ` ${formatDate(
        removeTimeZone(audit.actionDetails.previous),
        DATE_ONLY_FORMAT
      )} to ${formatDate(audit.actionDetails.updated, DATE_ONLY_FORMAT)} at`;
    }

    if (audit.action === AuditActions.ACTION_UNASSIGN_TICKET && audit.oldClinician) {
      actionTxt += ` from ${getClinicianFullNameWithCredentials(audit.oldClinician)}`;
    }

    return (
      <>
        {actionTxt}
        {renderByClinicianSection(audit)}
      </>
    );
  };

  const getHistoryTooltipItems = () =>
    history
      .filter((audit) => audit.hasActionStr())
      .map((audit) => <li key={audit.id}>{getHistoryLineContent(audit)}</li>);

  function getTooltipText() {
    if (error) {
      return error;
    }
    if (!history) {
      return 'loading...';
    }
    let tooltipItems = getHistoryTooltipItems();

    const hasCreationAction = history.find(
      (item) => item.action === AuditActions.ACTION_CREATE_TICKET
    );

    // Add create action if create action doesn't exist
    // For ticket.class === patient we need to replace the creation action from audit (if exists, old tickets don't have it)
    // since we take it from the REPORT and not from the TICKET (so we know the report source: ivr/web/app)
    // This needs to get solved on the server. ticket opened for it
    // https://expain.atlassian.net/browse/EH-5291
    if (!hasCreationAction || ticket.class === TicketClass.patient) {
      const fakeCreateHistoryItem = (
        <li key={0}>
          Created&nbsp;
          {formatDate(createdAt, longDateTimeFormat)}
          &nbsp;
          {formattedCreatingClinician}
        </li>
      );

      if (ticket.class === TicketClass.patient && hasCreationAction) {
        tooltipItems = tooltipItems.slice(1);
      }

      tooltipItems = [fakeCreateHistoryItem, ...tooltipItems];
    }

    // Add resolve action if doesn't exist
    if (
      ticket.isResolved &&
      !history.find((item) => item.action === AuditActions.ACTION_RESOLVE_TICKET)
    ) {
      const fakeResolveHistoryItem = (
        <li key={0}>
          Resolved &nbsp;
          {formatDate(resolvedDate, longDateTimeFormat)}
          &nbsp; by Unknown
        </li>
      );
      tooltipItems = [...tooltipItems, fakeResolveHistoryItem];
    }

    return <ul className="pl-3 m-0">{tooltipItems}</ul>;
  }

  async function getTicketHistory() {
    setError(null);
    setHistory(null);

    try {
      const ticketHistory = await ticketsStore.getTicketHistory(ticket, {
        cancellationKey: requestIdForTicket
      });
      setHistory(ticketHistory);
    } catch (error) {
      setError(error.ui.title);
      throw error;
    }
  }

  return (
    <TicketRowDateTooltip
      tooltipText={getTooltipText()}
      onShow={getTicketHistory}
      onHide={() => AbortSignalManager.abort(requestIdForTicket)}
    >
      <div className="d-flex flex-column">
        {patient.mrn && <div className="bold patient-mrn details-row">MRN: {patient.mrn}</div>}

        <div className="text-truncate">
          Created {formattedCreateTime} {formattedCreatingClinician}
        </div>

        {ticket.isResolved && (
          <div className="text-truncate">
            Resolved {formatDate(resolvedDate, shortDateTimeFormat)}
          </div>
        )}
      </div>
    </TicketRowDateTooltip>
  );
};

export default observer(TicketCreatedBy);
