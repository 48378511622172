import { ActionMeta } from 'react-select';

import { SelectActionMetaName } from 'components/UIkit/atoms/Dropdown/SelectUtils';

import { ISelectOption } from './StyledSelect';

// get last selected option from multi-select
export function getSelectedByAction(actionMeta: ActionMeta<ISelectOption<any>>) {
  return actionMeta?.action === SelectActionMetaName.Select ? actionMeta.option : null;
}
