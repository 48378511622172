// @ts-strict-ignore
import { useLocation } from 'react-router-dom';

export enum HEADER_TAB {
  REPORTS_TAB,
  PATIENTS_TAB,
  INVITE_TAB,
  ACCOUNT_TAB,
  CREATE_TICKETS_TAB,
  CARE_MANAGEMENT_TAB
}

export function useIsActiveTab(tab: number): boolean {
  const activeTab = useActiveTab();
  return activeTab === tab;
}

export function useActiveTab(): number {
  let { pathname } = useLocation();

  pathname = pathname.substring(pathname.indexOf('/') + 1); // remove leading slash (/)
  const secondSlash = pathname.indexOf('/');
  if (secondSlash !== -1) {
    pathname = pathname.substring(0, secondSlash); // get first url fragment
  }

  switch (pathname) {
    case 'patient-list':
    case 'patient':
      return HEADER_TAB.PATIENTS_TAB;
    case 'triage':
    case 'work-queue':
      return HEADER_TAB.REPORTS_TAB;
    case 'invitation':
    case 'activation':
      return HEADER_TAB.INVITE_TAB;
    case 'create-tickets':
      return HEADER_TAB.CREATE_TICKETS_TAB;
    case 'alerts':
    case 'practice':
      return HEADER_TAB.ACCOUNT_TAB;
    case 'care-management':
      return HEADER_TAB.CARE_MANAGEMENT_TAB;
    default:
      return null;
  }
}
