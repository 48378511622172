import { NationalNumber } from 'libphonenumber-js';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import PatientLocation from 'models/PatientLocation';
import PatientProvider from 'models/PatientProvider';

import { ISelectOption } from 'views/Widgets/StyledSelect';

import { LightweightPathwayQuestionAnswer } from 'components/LightweightPathway/LightweightPathway.types';

// contact consts
export const PATIENT_IS_CONTACT_VALUE = '-1';
export const NEW_CONTACT_VALUE = '0';

export interface LightweightPathwaySummary {
  activeQuestionsCount: number;
  pathwayId: string;
  pathwayName: string;
  unansweredQuestions: string[];
}
export interface TicketFormField {
  urgency: { label: string; value: number };
  notes: string;
  providerId: ISelectOption<PatientProvider>;
  locationId: ISelectOption<PatientLocation>;
  assignee: ISelectOption<number> | null;
  categoryId: string;
  ticketSubTypeIds: string[];
  parentId: number | null;
  ticketTypeSelectorValue: TicketSubTypeOption | TicketSubTypeOption[];
  lightweightPathwaySummaries: LightweightPathwaySummary[] | null;
  lightweightPathwaysAnswers: LightweightPathwayQuestionAnswer[];
}

export interface TicketFormType {
  value: string;
  label: string;
  parentId?: number;
}

interface OperatorTicketFormFields {
  phone: NationalNumber;
  callbackName: string;
  contactId: string;
  callbackRelationship: { value: string; label: string };
  callbackHippaAuth: boolean;
  countryCode: string;
}

export interface IOperatorCreateTicketsFormFields extends OperatorTicketFormFields {
  ticketTypes: TicketFormType[];
  tickets: TicketFormField[];
  messagingConsent?: boolean;
}

export interface IOperatorUpdateTicketFormFields extends OperatorTicketFormFields {
  ticket: TicketFormField;
}
