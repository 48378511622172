import { FC } from 'react';

import classNames from 'classnames';

import './Loading.scss';

interface Props {
  primaryColor?: boolean;
  absolute?: boolean; // this is temporary, as the goal is to move this logic out of the component
}
const Loading: FC<Props> = ({ primaryColor = false, absolute = true }) => {
  const classes = classNames('lds-grid', {
    primary: primaryColor,
    'lds-absolute': absolute
  });

  return (
    <div className={classes}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Loading;
