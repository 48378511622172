import PatientEpisode from 'models/PatientEpisode';

export function nextSuggestedEpisodeNumber(
  patientEpisodes: PatientEpisode[],
  episodeId: number
): number {
  const usedNumbers = patientEpisodes
    // filter other episodes / episodes which marked as error
    .filter((episode) => !episode.isMarkedAsError && episode.episodeId === episodeId)
    .map((episode) => episode.episodeNumber);
  // next suggested number would be max used number + 1, while skiping error episodes. for example:
  // for [1, 2, 4] next suggested number would be 5
  return Math.max(0, ...usedNumbers) + 1;
}

export function getActiveEpisodeNumbers(patientEpisodes: PatientEpisode[]): number[] {
  const uniqNumbers = new Set<number>();
  patientEpisodes
    .filter((episode) => !episode.isMarkedAsError && Boolean(episode.episodeNumber))
    .forEach((patientEpisode) => uniqNumbers.add(patientEpisode.episodeNumber));
  return Array.from(uniqNumbers).sort();
}
