// @ts-strict-ignore
import { observer } from 'mobx-react';

import { useForm } from 'react-hook-form';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useProvider } from 'mobx/hooks/useProvidersOptions';
import { useStores } from 'mobx/hooks/useStores';

import { parseDateForInputField, removeTimeZone } from 'utils/DateUtils';

import { showToast } from 'utils/UserMessageUtils';

import { API_URLS } from 'constants/apiUrls';

import Patient from 'models/Patient';

import PatientEpisode from 'models/PatientEpisode';

import { useClinicianFullNameWithCredentials } from 'hooks/useClinicianFullNameWithCredentials';

import {
  CreateEditPatientEpisodeFormFields,
  PatientEpisodeFormFields,
  useEpisodesMetadataOptions
} from 'views/Pages/EpisodesManagement/CreateEditPatientEpisodeFormFields';
import { useManageEpisodesContext } from 'views/Pages/EpisodesManagement/episode-context';

import { useTicketLocationsOptions } from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import './EpisodeModalForm.scss';

function getEpisodeSuffix(episode: PatientEpisode) {
  if (episode?.isHistorical) {
    return ' (Historical)';
  }
  if (episode?.isEnded) {
    return ' (Episode Ended)';
  }
  return '';
}

interface Props {
  isOpen: boolean;
  patient: Patient;
  onSubmit: () => void;
  onCancel: () => void;
  episode: PatientEpisode;
  resetDataAfterClose: () => void;
  isEndPatientEpisodeModalOpen: boolean;
}

export const EditPatientEpisodeModal = ({
  isOpen,
  patient,
  onSubmit,
  onCancel,
  episode,
  resetDataAfterClose,
  isEndPatientEpisodeModalOpen
}: Props) => {
  const { patientEpisodesStore } = useStores();
  const episodeName = patientEpisodesStore.getEpisodeTitle(episode?.id);
  const episodeSuffix = getEpisodeSuffix(episode);
  const title = `${patient.fullName}: Edit Episode - ${episodeName}${episodeSuffix}`;
  const isLoading = useNetworkLoading(API_URLS.UPDATE_PATIENT_EPISODE(patient.id, episode?.id));
  const { endEpisode, openEndPatientEpisodeModal } = useManageEpisodesContext();
  const clinicianName = useClinicianFullNameWithCredentials(
    episode?.owner?.firstName,
    episode?.owner?.lastName,
    episode?.owner?.credentialId
  );
  const episodeOptions = useEpisodesMetadataOptions();
  const locationOptions = useTicketLocationsOptions();
  const initialProvider = useProvider(String(episode?.providerId || ''));

  const methods = useForm<PatientEpisodeFormFields>();
  const getChangesSavedForUserMessage = (patientName: string, episodeId: number) => {
    return `Changes Saved for ${patientName} - ${patientEpisodesStore.getEpisodeTitle(episodeId)}`;
  };

  const onSave = async (values: PatientEpisodeFormFields) => {
    await patientEpisodesStore.updatePatientEpisode(patient.id, {
      id: episode?.id,
      episodeId: values.episode?.value,
      episodeNumber: values.episodeNumber,
      diagnosis: values.diagnosis,
      locationId: values.location?.value?.id || null,
      treatment: values.treatment,
      notes: values.notes,
      ownerId: values.owner?.value || null,
      providerId: values.provider?.value?.id || null,
      startDate: values.startDate,
      endDate: values.endDate
    });
    methods.reset();
    showToast({ message: getChangesSavedForUserMessage(patient.fullName, episode.id) });
    onSubmit();
  };

  const formDefaultValues = {
    startDate: episode?.startDate
      ? parseDateForInputField(removeTimeZone(episode.startDate))
      : null,
    episodeNumber: episode?.episodeNumber,
    endDate: episode?.endDate ? parseDateForInputField(removeTimeZone(episode?.endDate)) : null,
    episode: episodeOptions.find((episodeOption) => episodeOption.value === episode?.episodeId),
    owner: episode?.owner ? { label: clinicianName, value: episode.owner.id } : null,
    diagnosis: episode?.diagnosis,
    treatment: episode?.treatment,
    notes: episode?.notes,
    location: locationOptions.find((location) => location.value.id === episode?.locationId),
    provider: initialProvider
  };

  return (
    <FormModal
      methods={methods}
      defaultValues={formDefaultValues}
      isOpen={isOpen}
      title={title}
      confirmActions={[
        {
          onClick: methods.handleSubmit(onSave),
          text: isLoading ? 'Saving...' : 'Save',
          disabled: isLoading
        }
      ]}
      closeAction={{ onClick: onCancel, disabled: false }}
      secondaryAction={{
        type: 'button',
        text: 'End Episode',
        disabled: isLoading,
        onClick: () => {
          endEpisode(episode);
          openEndPatientEpisodeModal();
        }
      }}
      resetDataAfterClose={!isEndPatientEpisodeModalOpen ? resetDataAfterClose : null}
      isInternalModalOpen={isEndPatientEpisodeModalOpen}
    >
      <CreateEditPatientEpisodeFormFields episode={episode} patient={patient} />
    </FormModal>
  );
};

export default observer(EditPatientEpisodeModal);
