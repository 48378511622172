import { ChangeEvent, FC } from 'react';

import { css, FormControl, FormHelperText, FormLabel, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';

import { RadioButton } from 'components/UIkit/atoms/RadioButton';
import { RadioButtonExternalProps } from 'components/UIkit/atoms/RadioButton/RadioButton';

interface Props {
  name: string;
  options: RadioButtonExternalProps[];
  title?: string;
  subTitle?: string;
  required?: boolean;
  disabled?: boolean;
  isRow?: boolean;
  defaultValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FormRadioGroup: FC<Props> = ({
  name,
  title,
  subTitle,
  options,
  disabled: disabledGroup,
  required,
  isRow = false,
  defaultValue = '',
  onChange
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const error = Boolean(errors[name]);
  // TODO: add Mui FormControl components here

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required
      }}
      defaultValue={defaultValue}
      render={({ field }) => {
        const { onChange: fieldOnChange, ...restField } = field;

        return (
          <FormControl error={error}>
            <StyledRadioGroupContainer>
              {(title || subTitle) && (
                <StyledGroupHeadersContainer>
                  {title && <FormLabel>{title}</FormLabel>}
                  {subTitle && <FormHelperText>{subTitle}</FormHelperText>}
                </StyledGroupHeadersContainer>
              )}

              <RadioGroup
                onChange={(event) => {
                  onChange && onChange(event);
                  field.onChange(event);
                }}
                {...restField}
                row={isRow}
              >
                {options.map(({ disabled, ...option }) => (
                  <StyledOptionRowContainer key={option.id}>
                    <RadioButton disabled={disabledGroup || disabled} {...option} />
                  </StyledOptionRowContainer>
                ))}
              </RadioGroup>
            </StyledRadioGroupContainer>
          </FormControl>
        );
      }}
    />
  );
};

const StyledGroupHeadersContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    opacity: 0.6;
    margin-bottom: ${theme.spacing(20)};
  `
);
const StyledRadioGroupContainer = styled('div')(
  css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `
);

const StyledOptionRowContainer = styled('div')(
  ({ theme }) => css`
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(8)};
    }
  `
);
