import { FC } from 'react';

import StyledContainer from 'views/Widgets/StyledContainer';

import './KeyboardShortcutsInfoPane.scss';

const KeyboardShortcutsInfoPane: FC = () => (
  <StyledContainer className="keyboard-shortcut-info-pane shadowed">
    <div>
      use <span>arrows</span> to switch callback contacts
    </div>
    <div>
      <span>tab</span> key to advance
    </div>
    <div>
      <span>enter</span> to select
    </div>
    <div>
      <span>shift</span> + <span>enter</span> to finish
    </div>
  </StyledContainer>
);

export default KeyboardShortcutsInfoPane;
