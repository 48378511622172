import { FC } from 'react';

import { Box } from '@mui/material';

import { ToastContentProps } from 'react-toastify/dist/types';

import { Text } from 'components/UIkit/atoms/Text';

export interface BaseToastOptions {
  message: string;
  description?: string;
  onClose?: () => void;
}

type Props = ToastContentProps<BaseToastOptions>;

export const BaseToast: FC<Props> = ({ data }) => {
  const { message, description } = data!;

  return (
    <Box p={16} display="flex" justifyContent="space-between" width="100%" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Text variant="body3" color="primary">
          {message}
        </Text>

        {description && (
          <Text mt={2} variant="body2">
            {description}
          </Text>
        )}
      </Box>
    </Box>
  );
};
