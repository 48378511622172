import { FC } from 'react';

interface Props {
  disabled: boolean;
}

export const RemoveCircleIcon: FC<Props> = ({ disabled }) => {
  const color = disabled ? '#7a7a7a' : '#007C66';

  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="0.521973" width="17" height="17" rx="8.5" stroke={color} />
      <path
        d="M5.65657 8.46224L13.3337 8.46262C13.5318 8.45918 13.7164 8.56292 13.8165 8.73397C13.9166 8.90502 13.9166 9.11675 13.8165 9.2878C13.7164 9.45885 13.5318 9.56259 13.3337 9.55915L5.65657 9.55876C5.35749 9.55356 5.11776 9.30962 5.11776 9.0105C5.11776 8.71137 5.35749 8.46744 5.65657 8.46224Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
