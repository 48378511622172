import {
  WorkQueueBulkRequestReportResponse,
  WorkQueueCheckSectionStatusResponse,
  WorkQueueResponse
} from 'fetchers/responses/work-queue.response';

import HttpService from 'services/HttpService';

import { RequestConfig } from 'utils/http.utils';

import { API_URLS } from 'constants/apiUrls';

import { TicketsParser } from 'parsers/TicketsParser';

import {
  ParsedWorkQueueResponse,
  WorkQueueBulkRequestReportBody,
  WorkQueueRequestBody
} from 'views/WorkQueue/WorkQueue.types';

const httpService = new HttpService('Work-Queue', true);

export class WorkQueueFetcher {
  static async fetchWorkQueue(
    requestBody: WorkQueueRequestBody,
    requestConfig?: RequestConfig
  ): Promise<ParsedWorkQueueResponse> {
    return await httpService.post<WorkQueueResponse>({
      url: API_URLS.WORK_QUEUE,
      data: requestBody,
      transformResponse: TicketsParser.parseWorkQueue,
      ...requestConfig
    });
  }

  static async checkSectionStatus(
    requestBody: WorkQueueRequestBody
  ): Promise<WorkQueueCheckSectionStatusResponse> {
    return await httpService.post<WorkQueueCheckSectionStatusResponse>({
      url: API_URLS.WORK_QUEUE_SECTION_STATUS,
      data: requestBody
    });
  }

  static async sendBulkReportRequest(
    requestBody: WorkQueueBulkRequestReportBody
  ): Promise<WorkQueueBulkRequestReportResponse> {
    return await httpService.patch<WorkQueueBulkRequestReportResponse>({
      url: API_URLS.BULK_REQUEST_REPORT,
      data: requestBody
    });
  }
}
