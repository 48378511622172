// @ts-strict-ignore
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { RootStore } from 'mobx/stores';

import ticketTypesFetcher, { TicketTypeCreateUpdateAttrs } from 'fetchers/TicketTypesFetcher';

import Ticket from 'models/Ticket';
import {
  IOperTicketType,
  LanguageId,
  OperatorTicketMap,
  TicketTypeCreationAllowance,
  TicketTypeKind
} from 'models/TicketTypes';

import { TicketTypeNode } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeNode';
import {
  getNodeKey,
  TicketTypeTree
} from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeTree';

type ToggleAllowanceAction = 'enable' | 'disable';

export default class TicketTypesStore {
  @observable
  private operatorTicketTypes: OperatorTicketMap = new Map<number, IOperTicketType>();

  rootStore: RootStore;

  @computed
  get ticketTypesTree(): TicketTypeTree {
    const root = new TicketTypeTree();
    if (
      this.operatorTicketTypes.size > 0 &&
      this.rootStore.stores.constantsStore.symptomsMap.size > 0
    ) {
      root.addCategoriesAndOperatorTypes(this.operatorTicketTypes);
      root.addSymptomTypes(this.rootStore.stores.constantsStore.symptomsMap);
    }
    return root;
  }

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  async fetchOperatorTicketTypes() {
    const ticketTypes = await ticketTypesFetcher.fetchOperatorTicketTypes();
    const operTypesMap = new Map<number, IOperTicketType>();
    ticketTypes.forEach((ticketType: IOperTicketType) => {
      operTypesMap.set(ticketType.id, ticketType);
    });

    runInAction(() => {
      this.operatorTicketTypes = operTypesMap;
    });
  }

  @action
  async createTicketSubType(subtype: TicketTypeCreateUpdateAttrs) {
    await ticketTypesFetcher.createTicketSubType(subtype);
    this.fetchOperatorTicketTypes();
  }

  @action
  async updateTicketTypeOrSubType(id: number, ticketTypeOrSubType: TicketTypeCreateUpdateAttrs) {
    await ticketTypesFetcher.updateTicketTypeOrSubType(id, ticketTypeOrSubType);
    this.fetchOperatorTicketTypes();
  }

  @action
  async deleteTicketSubType(id: number) {
    await ticketTypesFetcher.deleteTicketSubType(id);
    this.fetchOperatorTicketTypes();
  }

  @action
  async toggleTicketTypeOrSubTypeAllowance(id: number, allowance: TicketTypeCreationAllowance) {
    const type = this.operatorTicketTypes.get(id);
    let newDisplaySettings = type.displaySettings ? [...type.displaySettings] : [];
    let action: ToggleAllowanceAction = 'enable';

    if (type.displaySettings?.includes(allowance)) {
      newDisplaySettings = newDisplaySettings.filter((item) => item !== allowance);
      action = 'disable';
    } else if (allowance === TicketTypeCreationAllowance.OPERATOR) {
      newDisplaySettings = [TicketTypeCreationAllowance.OPERATOR];
    } else {
      newDisplaySettings.push(allowance);
    }

    const previousDisplaySettings = [...type.displaySettings];
    type.displaySettings = newDisplaySettings;

    try {
      await ticketTypesFetcher.editAllowance(id, newDisplaySettings);
    } catch (error) {
      type.displaySettings = previousDisplaySettings;
      error.ui.title = 'Something went wrong';
      error.ui.description = `We couldn’t ${action} “${type.name}” for ${allowance}s. Please try again in a moment.`;
      throw error;
    }
  }

  @action
  async toggleCategoryAllowance(id: number) {
    const type = this.operatorTicketTypes.get(id);
    let newDisplaySettings: TicketTypeCreationAllowance[] = [];
    let action: ToggleAllowanceAction = 'disable';

    if (type.displaySettings.length === 0) {
      newDisplaySettings = [
        TicketTypeCreationAllowance.OPERATOR,
        TicketTypeCreationAllowance.PATIENT
      ];
      action = 'enable';
    }

    type.displaySettings = newDisplaySettings;

    try {
      await ticketTypesFetcher.editAllowance(id, newDisplaySettings);
    } catch (error) {
      error.ui.title = 'Something went wrong';
      error.ui.description = `We couldn’t ${action} category “${type.name}”. Please try again in a moment.`;
      throw error;
    } finally {
      this.fetchOperatorTicketTypes();
    }
  }

  isTicketTypesAvailable(ticket: Ticket) {
    if (ticket.operatorTicket && ticket.operatorTicket.kind === TicketTypeKind.other) {
      // verify all operator ticket types are available for use
      return ticket.operatorTicket.subTicketTypeIds.every((subTicketId) =>
        this.operatorTicketTypes.has(subTicketId)
      );
    }
    return true;
  }

  getTicketType = (key: string): TicketTypeNode => {
    if (!this.operatorTicketTypes.size) {
      return;
    }

    const node = this.ticketTypesTree.nodesMap.get(key);
    if (!node) {
      console.warn(`Tried to get type node by key ${key}, but none found.`);
    }
    return node;
  };

  getSymptomTicketTypeById(id: number): TicketTypeNode {
    const key = getNodeKey(id, this.ticketTypesTree.symptomCategory.id);
    return this.ticketTypesTree.nodesMap.get(key);
  }

  getOperatorTicketTypeById = (id: number): TicketTypeNode => {
    if (!id) {
      return null;
    }

    const ticketType = this.operatorTicketTypes.get(id);
    const key = getNodeKey(id, ticketType.parentId);
    return this.ticketTypesTree.nodesMap.get(key);
  };

  getTicketTypeByKind = (id: number, kind: TicketTypeKind): TicketTypeNode => {
    return kind === TicketTypeKind.symptom
      ? this.getSymptomTicketTypeById(id)
      : this.getOperatorTicketTypeById(id);
  };

  getCategoryByParentId = (id: number): TicketTypeNode => {
    if (id === this.ticketTypesTree.symptomCategory.id) {
      return this.ticketTypesTree.symptomCategory;
    } else {
      return this.getOperatorTicketTypeById(id).category;
    }
  };

  ticketSubTypesNames = (ids: string[]) =>
    ids.map((subTypeId) => this.getTicketType(subTypeId)?.name);

  getDisplayNameByLanguageId = (
    ticketId: number,
    languageId: LanguageId,
    isDefault: boolean = false
  ) => {
    if (!ticketId) {
      return null;
    }

    const ticketType = this.getOperatorTicketTypeById(ticketId);
    return ticketType.displayNames?.find(
      (item) => item.languageId === languageId && item.isDefault === isDefault
    );
  };
}
