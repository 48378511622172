import React, { FC, MouseEvent, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { AnalyticEventAction } from 'analytics';
import { trackTabNavigationAnalyticsEvent } from 'analytics/events/tab-navigation';
import { observer } from 'mobx-react';

import { useHistory, useParams } from 'react-router-dom';
import { workQueueTestSelectors } from 'tests/models/pages/work-queue/work-queue-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import AuditService, { AuditAction } from 'fetchers/AuditFetcher';

import DoctorDetails from 'views/Dashboard/DoctorDetails';
import { workQueueTabs, workQueueTabToNameMap } from 'views/WorkQueue/WorkQueue.constants';
import { WorkQueueTab, WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

import { WorkQueueOpenItemsView } from 'views/WorkQueue/WorkQueueOpenItemsView/WorkQueueOpenItemsView';
import { WorkQueueResolvedItemsView } from 'views/WorkQueue/WorkQueueResolvedItemsView/WorkQueueResolvedItemsView';

import RefreshButton from 'components/Buttons/RefreshDataButton';
import FixedLoader from 'components/Loaders/FixedLoader';
import { ExclusiveToggleButton } from 'components/UIkit/atoms/Button';

export const WorkQueuePage: FC = observer(() => {
  const [isManualDataRefresh, setIsManualDataRefresh] = useState(false);
  const { activeTab } = useParams<{ activeTab: WorkQueueTab }>();
  const history = useHistory();
  const { userStore, workQueueStore } = useStores();
  const shouldRedirect = activeTab && !workQueueTabs.includes(activeTab);

  useEffect(
    function redirectToOpenItems() {
      if (shouldRedirect) {
        history.replace('/work-queue');
      }
    },
    [history, shouldRedirect]
  );

  if (shouldRedirect) {
    return <FixedLoader />;
  }

  const handleRefreshClicked = async () => {
    AuditService.addAudit({
      action: AuditAction.REFRESH_DATA_BUTTON_CLICKED,
      actionDetails: { page: 'work_queue' }
    });
    setIsManualDataRefresh(true);

    try {
      await workQueueStore.fetchAndCheckSections('refresh-wq');
    } catch (error) {
      throw error;
    } finally {
      setIsManualDataRefresh(false);
    }
  };

  const handleTabClicked = (_event: MouseEvent<HTMLElement>, newSelection: string | number) => {
    trackTabNavigationAnalyticsEvent({
      action: AnalyticEventAction.TabSelect,
      value: workQueueTabToNameMap[newSelection as WorkQueueTab],
      tab: workQueueTabToNameMap[activeTab]
    });

    if (newSelection === activeTab || (newSelection === WorkQueueTab.OpenItems && !activeTab)) {
      return;
    }

    if (newSelection === WorkQueueTab.OpenItems) {
      history.push('/work-queue/open-items');
      return;
    }

    history.push('/work-queue/resolved-items');
    return;
  };

  const refreshButtonLastUpdatedTime = workQueueStore.autoRefreshLastRetrievedTimestamp
    ? new Date(workQueueStore.autoRefreshLastRetrievedTimestamp as number)
    : null;

  return (
    <div className="dashboard-view animated fadeIn" data-test-hook={workQueueTestSelectors.page}>
      <div className="limited-width">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={40}>
          <DoctorDetails doctor={userStore.currentDoctor} showDepartments={false} />

          <Box display="flex" alignItems="center" gap={20}>
            <ExclusiveToggleButton
              options={[
                {
                  value: WorkQueueTab.OpenItems,
                  label: WorkQueueTabName.OpenItems,
                  testHook: workQueueTestSelectors.tab(WorkQueueTab.OpenItems)
                },
                {
                  value: WorkQueueTab.ResolvedItems,
                  label: WorkQueueTabName.ResolvedItems,
                  testHook: workQueueTestSelectors.tab(WorkQueueTab.ResolvedItems)
                }
              ]}
              value={activeTab || WorkQueueTab.OpenItems}
              onChange={handleTabClicked}
              variant="secondary"
            />

            <RefreshButton
              onClick={handleRefreshClicked}
              lastUpdatedTime={
                activeTab === WorkQueueTab.ResolvedItems ? null : refreshButtonLastUpdatedTime
              }
              isLoading={isManualDataRefresh}
              testHook={workQueueTestSelectors.refreshDataButton}
              isDisabled={activeTab === WorkQueueTab.ResolvedItems}
              width="206px"
              hasBoxShadow={false}
              alignStart
            />
          </Box>
        </Box>

        {activeTab === WorkQueueTab.ResolvedItems ? (
          <WorkQueueResolvedItemsView />
        ) : (
          <WorkQueueOpenItemsView isManualDataRefresh={isManualDataRefresh} />
        )}
      </div>
    </div>
  );
});
