import { css, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { VERSION } from 'constants/config';

import { InternalLink } from 'components/UIkit/atoms/Link';

import './Footer.scss';

export const Footer = () => (
  <footer className="footer pb-3 pt-4">
    <div className="mr-2">
      &copy; {new Date().getFullYear()} Canopy v{VERSION}
    </div>

    <Grid>
      <InternalLink to="/subscription" newTabOnClick variant="secondary" size="small">
        Terms and Conditions
      </InternalLink>

      <StyledVerticalLine>|</StyledVerticalLine>
      <InternalLink to="/privacy" newTabOnClick variant="secondary" size="small">
        Privacy Policy
      </InternalLink>
    </Grid>
  </footer>
);

const StyledVerticalLine = styled('span')(
  ({ theme }) => css`
    padding: 0 ${theme.spacing(8)};
    color: ${theme.palette.secondary.dark};
  `
);
