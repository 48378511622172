// @ts-strict-ignore
import { css } from '@mui/material';

import { styled } from '@mui/material/styles';
import classNames from 'classnames';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { getOutcomeClassByOutcome, getOutcomeTextByString } from 'utils/pathwaysUtils';

import {
  StyledAttentionTodayUrgencyContainer,
  StyledImmediateAttentionUrgencyContainer
} from 'utils/TicketBadgeUtils';

import { PathwayOptionOutcome } from 'models/PathwayTemplates';

import SanitizeHtml from 'views/Widgets/SanitizeHtml';

import Icon from 'components/Icons/Icon';

import { Tooltip } from 'components/Tooltip';

import './PathwayBanner.scss';

export const PathwayTemplateBanner = ({
  show,
  outcome,
  tooltipInfo,
  className,
  children
}: {
  show: boolean;
  outcome: PathwayOptionOutcome;
  className?: string;
  tooltipInfo?: string;
  children: any;
}) => {
  const renderTooltip = () => {
    if (tooltipInfo) {
      return (
        <div className="banner-tooltip">
          <Tooltip
            placement="top"
            label={
              <div className="trigger">
                <span>i</span>
              </div>
            }
          >
            <div className="p-3">
              <SanitizeHtml html={tooltipInfo} />
            </div>
          </Tooltip>
        </div>
      );
    }
    return null;
  };
  const classes = classNames('pathway-banner', getOutcomeClassByOutcome(outcome), className, {
    show,
    hide: !show
  });

  return (
    <div className={`${classes}`}>
      {renderTooltip()}
      {children}
    </div>
  );
};

const StyledIconContainer = styled('div')(
  ({ theme }) => css`
    color: white;
    margin-right: ${theme.spacing(8)};
  `
);

export const DefaultBanner = observer(() => {
  const { pathwaysStore } = useStores();
  const { highestCurrentOutcome } = pathwaysStore;

  return (
    <PathwayTemplateBanner
      className="default"
      outcome={highestCurrentOutcome}
      show={highestCurrentOutcome >= PathwayOptionOutcome.CLINIC}
    >
      <StyledIconContainer>
        {highestCurrentOutcome === PathwayOptionOutcome.CLINIC ? (
          <StyledAttentionTodayUrgencyContainer>
            <Icon.AttentionToday />
          </StyledAttentionTodayUrgencyContainer>
        ) : (
          <StyledImmediateAttentionUrgencyContainer>
            <Icon.ImmediateAttention />
          </StyledImmediateAttentionUrgencyContainer>
        )}
      </StyledIconContainer>
      {getOutcomeTextByString(highestCurrentOutcome)}
    </PathwayTemplateBanner>
  );
});

export const PointBasedBanner = () => {
  const { pathwaysStore } = useStores();
  const { highestCurrentOutcome, currentPathwayInfo, currentPathwayScore } = pathwaysStore;
  const shouldAppear = pathwaysStore.currentPathwayAnsweredQuestions.length > 0;
  const pathwayTemplate = pathwaysStore.getPathwayTemplateById(currentPathwayInfo.id);
  return (
    <PathwayTemplateBanner
      className="point-based"
      tooltipInfo={pathwayTemplate.tooltipInfo}
      show={shouldAppear}
      outcome={highestCurrentOutcome}
    >
      {currentPathwayScore} {`${currentPathwayScore !== 1 ? 'POINTS' : 'POINT'}`}
    </PathwayTemplateBanner>
  );
};
