import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

interface Props {
  children: ReactNode;
  title: string;
}

export const OralReportRow: FC<Props> = ({ title, children }) => (
  <Box display="flex">
    <span className="oral-report-row-title">{title}:&nbsp;</span>
    {children}
  </Box>
);
