import { ChangeEvent } from 'react';

import { observer } from 'mobx-react';

import StyledToggleButton from 'views/Widgets/StyledToggleButton';

import './VisibilityToggleCell.scss';

export const VisibilityToggleCell = observer(
  ({
    onChange,
    isVisible,
    disabled = false
  }: {
    isVisible: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
  }) => {
    return (
      <StyledToggleButton
        label="Show in Canopy"
        className="toggle-label"
        disabled={disabled}
        onChange={onChange}
        checked={isVisible}
      />
    );
  }
);
