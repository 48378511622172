import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { FilledButton, OutlinedButton } from 'components/UIkit/atoms/Button';

import './SaveCancelButtons.scss';

interface Props {
  children: ReactNode;
  onCancel: () => void;
  onSave: () => void;
  isLoading?: boolean;
  disableSave?: boolean;
  onDelete?: () => void;
  className?: string;
}

export const SaveCancelFooter: FC<Props> = ({
  onCancel,
  onSave,
  isLoading,
  onDelete,
  disableSave,
  children,
  className
}) => {
  const classes = classNames('save-cancel-btns m-0 mt-3', className);

  return (
    <div className={classes}>
      {children}
      {onDelete && (
        <OutlinedButton onClick={onDelete} size="medium" color="error">
          Delete
        </OutlinedButton>
      )}
      <OutlinedButton onClick={onCancel} size="medium" ml="auto" mr={16}>
        Cancel
      </OutlinedButton>

      <FilledButton onClick={onSave} disabled={disableSave || isLoading} testHook="submit-btn">
        {isLoading ? 'Saving...' : 'Save'}
      </FilledButton>
    </div>
  );
};
