import { ReactNode } from 'react';

import get from 'lodash/fp/get';
import { CellProps, Column, DefaultSortTypes, Row, SortByFn } from 'react-table';

import { dateSorter } from 'utils/DateUtils';
import { stringCaseInsensitiveCompare } from 'utils/StringUtils';

export enum SortOptions {
  desc = 'desc',
  asc = 'asc'
}

export enum SortTypes {
  basic = 'basic',
  datetime = 'datetime',
  alphanumeric = 'alphanumeric'
}

export type TableCellParams<D extends {}> = CellProps<D>;

export enum PaginationLocation {
  BOTTOM = 'bottom',
  TOP = 'top'
}

export type TableColumnSortFn<D extends NonNullable<unknown>> =
  | SortByFn<D>
  | DefaultSortTypes
  | string;

export type TableColumn<D extends object = {}> = Column<D> & {
  Header?: ReactNode;
  sortType?: TableColumnSortFn<D>;
  disableSortBy?: boolean;
  defaultSort?: SortOptions;
  removeVerticalPadding?: boolean;
  width?: number;
};

export interface BaseTableOptions<D extends object = {}> {
  columns: TableColumn<D>[];
  rowData: D[];
  width?: number;
  height?: number;
  hideHeaders?: boolean;
  emptyTableView?: ReactNode;
  rowAction?: (row: Row<D>) => void;
  rowLink?: (original: D) => string;
  bodyRenderer?: any;
}

export const defaultStringPropertyCompare =
  (propertyName: string) => (rowA: { original: any }, rowB: { original: any }) => {
    return stringCaseInsensitiveCompare(
      get(propertyName, rowA.original),
      get(propertyName, rowB.original)
    );
  };

export const defaultNumberStringPropertyCompare =
  (propertyName: string) => (rowA: { original: any }, rowB: { original: any }) =>
    get(propertyName, rowA.original) > get(propertyName, rowB.original) ? 1 : -1;

export const defaultDatePropertyCompare =
  (propertyName: string) => (rowA: { original: any }, rowB: { original: any }) => {
    return dateSorter(
      new Date(rowA.original[propertyName]),
      new Date(rowB.original[propertyName]),
      true
    );
  };
