import { PathwayType } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

export const pathwayTypeToName: Record<PathwayType, string> = {
  [PathwayType.Symptom]: 'Symptom',
  [PathwayType.CareManagement]: 'Care Management',
  [PathwayType.AccumulatedScore]: 'Accumulated Score',
  [PathwayType.DecreasedScore]: 'Decreasing Score'
};

export const pathwayTypeOptions = [
  {
    value: PathwayType.Symptom,
    label: pathwayTypeToName[PathwayType.Symptom]
  },
  {
    value: PathwayType.CareManagement,
    label: pathwayTypeToName[PathwayType.CareManagement]
  }
];
