export enum SymptomReportableIn {
  All = 'all',
  App = 'app',
  Switchboard = 'switchboard',
  None = 'none'
}

export enum TicketTypeKind {
  symptom = 'symptom',
  other = 'other'
}

export enum TicketTypeCreationAllowance {
  OPERATOR = 'operator',
  PATIENT = 'patient'
}

export enum DisplayNameLanguage {
  English = 1,
  Spanish = 2
}

export type LanguageId = DisplayNameLanguage.English | DisplayNameLanguage.Spanish;

export interface TicketTypeDisplayName {
  name: string;
  instructions: string;
  languageId: LanguageId;
  isDefault: boolean;
}

// This is to replace IDistressCause in ConstantsStore
export interface SymptomType {
  id: number;
  name: string;
  pathwayId: string | null;
  reportableIn: SymptomReportableIn;
  isDeleted: boolean;
  hasActiveTickets: boolean;
}

export interface IOperTicketType {
  id: number;
  institutionId: number;
  name: string;
  parentId: number;
  kind: null | TicketTypeKind.other | TicketTypeKind.symptom;
  isDeleted: boolean;
  displaySettings: TicketTypeCreationAllowance[];
  displayNames: TicketTypeDisplayName[];
  hasActiveTickets: boolean;
}

export type OperatorTicketMap = Map<number, IOperTicketType>;
