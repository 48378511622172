import { FC } from 'react';

import { styled } from '@mui/material';
import { components, SingleValueProps } from 'react-select';

export const PaginatorSelectSingleValue: FC<SingleValueProps> = ({ children, ...props }) => (
  <StyledSingleValue {...props}>{children}</StyledSingleValue>
);

const StyledSingleValue: FC<SingleValueProps> = styled(components.SingleValue, {
  shouldForwardProp: () => true
})`
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  outline: 0;
  z-index: 2;
`;
