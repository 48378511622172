import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Show
  | AnalyticEventAction.Close
  | AnalyticEventAction.Expand
  | AnalyticEventAction.Hide;

interface Properties {
  action: Action;
  successful_invitations: number;
  failed_invitations: number;
  incompatible_invitations: number;
}

export const trackInvitationsToastSummaryAnalyticsEvent = ({
  action,
  successful_invitations,
  failed_invitations,
  incompatible_invitations
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.InvitationsToastSummary,
    properties: {
      action,
      successful_invitations,
      failed_invitations,
      incompatible_invitations
    }
  });
};
