// @ts-strict-ignore
import { FC } from 'react';

import { ErrorName } from 'errors';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import CallReason, { CallTopicDiscussed } from 'models/CallReason';

import ConnectTicketsToCallModal from 'views/Modals/ConnectTicketsToCallModal';

import { usePatientModel } from 'components/Patient/usePatientModel';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

interface Props {
  onSave: () => void;
}

const ConnectTicketsToCallEditModal: FC<Props> = ({ onSave }) => {
  const { callsStore } = useStores();
  const call = callsStore.currentEditedCall;
  const patient = usePatientModel(call.patientId);
  const { openDisconnectDraftPopup } = useTicketOverviewContext();

  const handleSave = async (
    connectedTicketIds: number[],
    callReasons: CallReason[],
    topicsDiscussed: CallTopicDiscussed[],
    shouldResolveAll: boolean
  ) => {
    try {
      await callsStore.updateSavedCall(
        call.copyWith({
          ticketIds: new Set<number>(connectedTicketIds),
          callReasons,
          topicsDiscussed
        }),
        shouldResolveAll,
        patient
      );
      onSave();
    } catch (error) {
      if (error.name === ErrorName.TicketConnectedToDraft) {
        openDisconnectDraftPopup({
          ticketIds: connectedTicketIds,
          callback: async () => {
            await callsStore.updateSavedCall(
              call.copyWith({
                ticketIds: new Set<number>(connectedTicketIds),
                callReasons,
                topicsDiscussed
              }),
              shouldResolveAll,
              patient
            );
            onSave();
          }
        });
      } else throw error;
    }
  };

  return (
    <ConnectTicketsToCallModal
      call={call}
      isOpen
      isEdit
      onClose={callsStore.resetEditedCall}
      onSave={handleSave}
      patient={patient}
    />
  );
};

export default observer(ConnectTicketsToCallEditModal);
