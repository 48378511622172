// @ts-strict-ignore
import { FC, useState } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import AuditService, { AuditAction } from 'fetchers/AuditFetcher';

import { getEarliestDate } from 'utils/DateUtils';

import Patient from 'models/Patient';

import RefreshButton from 'components/Buttons/RefreshDataButton';

import { useRefreshSinglePatientData } from './PatientDetailsCard.shared';

interface Props {
  patient: Patient;
  selectedTab?: string;
}

export const PatientDetailsCardRefresh: FC<Props> = observer(({ patient, selectedTab }) => {
  const [isRefreshInProgress, setIsRefreshInProgress] = useState(false);
  const { patientPageStore, tasksStore } = useStores();
  const refreshDataForSinglePatient = useRefreshSinglePatientData(patient, selectedTab);

  const lastUpdatedTime = getEarliestDate([
    patientPageStore.lastSinglePatientRetrievedTimestamp,
    tasksStore.lastRetrievedTimestamp
  ]);

  const handleRefreshClicked = async () => {
    AuditService.addAudit({
      action: AuditAction.REFRESH_DATA_BUTTON_CLICKED,
      actionDetails: { page: 'patient_page' }
    });
    setIsRefreshInProgress(true);
    try {
      await refreshDataForSinglePatient();
    } finally {
      setIsRefreshInProgress(false);
    }
  };

  return (
    <div className="refresh-btn">
      <RefreshButton
        onClick={handleRefreshClicked}
        lastUpdatedTime={lastUpdatedTime}
        isLoading={isRefreshInProgress}
        testHook="refresh-button"
      />
    </div>
  );
});
