import { FC } from 'react';

import { Grid } from '@mui/material';

import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { usePatientModel } from 'components/Patient/usePatientModel';
import { Modal } from 'components/UIkit/atoms/Modal';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

interface AdjustTimelineProps {
  moveHours: number;
}
const TITLE = 'Adjust Patient Timeline';

const AdjustPatientTimelineFormModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const patient = usePatientModel();
  const methods = useForm<AdjustTimelineProps>();
  const { handleSubmit, register, formState } = methods;
  const { qaStore } = useStores();

  const handleOnSubmit = (fields: AdjustTimelineProps) => {
    qaStore.adjustPatientTimeline(patient.id, fields.moveHours);
    onSubmit();
  };

  return (
    <FormModal
      defaultValues={null}
      methods={methods}
      title={`${patient?.fullName}: ${TITLE}`}
      isOpen={isOpen}
      confirmActions={[{ onClick: handleSubmit(handleOnSubmit), text: 'Adjust', disabled: false }]}
      closeAction={{ onClick: onClose, disabled: false }}
    >
      <Grid container mb={40} spacing={12}>
        <Grid item xs={12}>
          <Text variant="body2">
            Adjusting the patient timeline will change the patient's timeline by the number of
            hours. This will move most of the patient's events backward in time by the number of
            hours.
          </Text>
        </Grid>
        <Grid item xs={6}>
          <RHFStyledInput
            min={1}
            isRequired
            type="number"
            name="moveHours"
            label="Hours"
            placeholder="0"
            register={register}
            error={Boolean(formState.errors.moveHours)}
          />
        </Grid>
      </Grid>
    </FormModal>
  );
};

export const QaAdjustPatientTimelineModal: FC<Props> = ({ isOpen, onSubmit, onClose }) => {
  const patient = usePatientModel();

  return Boolean(patient) ? (
    <AdjustPatientTimelineFormModal isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} />
  ) : (
    <Modal
      isOpen={isOpen}
      title={TITLE}
      closeAction={{
        disabled: false,
        onClick: onClose
      }}
      confirmActions={[
        {
          onClick: onClose,
          text: 'Ok',
          disabled: false
        }
      ]}
    >
      <Text variant="body2">
        Adjust patient timeline must be done on the desired patient's page
      </Text>
    </Modal>
  );
};
