// @ts-strict-ignore
import { action, makeObservable, observable, runInAction } from 'mobx';

import { computedFn } from 'mobx-utils';

import { DataMap } from 'mobx/stores/DataMap';

import ReasonsFetcher from 'fetchers/ReasonsFetcher';

import {
  CM_INELIGIBILITY,
  CM_OPT_OUT,
  CM_QUICK_ADDITION,
  EPISODE_END,
  REASON_TYPE
} from 'constants/reasons.const';

import { QuickAddition } from 'models/QuickAddition';
import { Reason } from 'models/Reason';

export class ReasonsStore {
  @observable
  isInitialized = false;

  @observable
  ineligibilityReasons = new DataMap<Reason>();

  @observable
  optOutReasons = new DataMap<Reason>();

  @observable
  episodeEndReasons = new DataMap<Reason>();

  @observable
  quickAdditionReasons = new DataMap<Reason>();

  @observable
  tempQuickAdditionReasons = new DataMap<Reason>();

  constructor() {
    makeObservable(this);
  }

  getReasonsByType = computedFn((reasonType: REASON_TYPE): Reason[] => {
    switch (reasonType) {
      case CM_INELIGIBILITY:
        return this.ineligibilityReasons.items;
      case CM_OPT_OUT:
        return this.optOutReasons.items;
      case EPISODE_END:
        return this.episodeEndReasons.items;
      case CM_QUICK_ADDITION:
        return this.quickAdditionReasons.items;
      default:
        return [];
    }
  });

  getReasonByIdTypeAndType = computedFn((reasonId: number, reasonType: REASON_TYPE): Reason => {
    if (!reasonId) {
      return null;
    }

    switch (reasonType) {
      case CM_INELIGIBILITY:
        return this.ineligibilityReasons.get(reasonId);
      case CM_OPT_OUT:
        return this.optOutReasons.get(reasonId);
      case EPISODE_END:
        return this.episodeEndReasons.get(reasonId);
      case CM_QUICK_ADDITION:
        return this.quickAdditionReasons.get(reasonId);
      default:
        return null;
    }
  });

  @action
  async fetchCareManagementIneligibilityReasons() {
    const ineligibilityReasons = await ReasonsFetcher.fetchCareManagementIneligibilityReasons();

    runInAction(() => {
      this.ineligibilityReasons.setItems(ineligibilityReasons);
    });
  }

  @action
  async fetchCareManagementOptOutReasons() {
    const optOutReasons = await ReasonsFetcher.fetchCareManagementOptOutReasons();

    runInAction(() => {
      this.optOutReasons.setItems(optOutReasons);
    });
  }

  @action
  async fetchEpisodeEndReasons() {
    const episodeEndReasons = await ReasonsFetcher.fetchEpisodeEndReasons();

    runInAction(() => {
      this.episodeEndReasons.setItems(episodeEndReasons);
    });
  }

  @action
  async fetchQuickAdditionReasons() {
    const quickAdditionReasons = await ReasonsFetcher.fetchQuickAdditionReasons();

    runInAction(() => {
      this.quickAdditionReasons.setItems(quickAdditionReasons);
    });
  }

  @action
  async fetchCareManagementReasons() {
    await Promise.all([
      this.fetchCareManagementIneligibilityReasons(),
      this.fetchCareManagementOptOutReasons(),
      this.fetchEpisodeEndReasons()
    ]);

    if (!this.isInitialized) {
      runInAction(() => {
        this.isInitialized = true;
      });
    }
  }

  @action
  private async fetchReasonsByType(reasonType: REASON_TYPE) {
    switch (reasonType) {
      case CM_INELIGIBILITY:
        return await this.fetchCareManagementIneligibilityReasons();
      case CM_OPT_OUT:
        return await this.fetchCareManagementOptOutReasons();
      case CM_QUICK_ADDITION:
        return await this.fetchQuickAdditionReasons();
    }
  }

  @action
  async createReason(reasonType: REASON_TYPE, title: string, isActive: boolean): Promise<number> {
    let newReasonId;
    switch (reasonType) {
      case CM_INELIGIBILITY:
        newReasonId = await ReasonsFetcher.createCMReason(CM_INELIGIBILITY, title, isActive);
        break;
      case CM_OPT_OUT:
        newReasonId = await ReasonsFetcher.createCMReason(CM_OPT_OUT, title, isActive);
        break;
      case CM_QUICK_ADDITION:
        newReasonId = await ReasonsFetcher.createCMReason(CM_QUICK_ADDITION, title, isActive);
        break;
    }
    await this.fetchReasonsByType(reasonType);
    return newReasonId;
  }

  @action
  async updateReason(reasonType: REASON_TYPE, id: number, title: string) {
    await ReasonsFetcher.updateReason(id, title, reasonType);
    await this.fetchReasonsByType(reasonType);
  }

  @action
  async deleteReason(reasonType: REASON_TYPE, id: number) {
    await ReasonsFetcher.deleteReason(id, reasonType);
    await this.fetchReasonsByType(reasonType);
  }

  getReasonsForQuickAddition(quickAddition: QuickAddition): Reason[] {
    const reasons = this.quickAdditionReasons.items;
    if (quickAddition.reason?.id && !this.quickAdditionReasons.get(quickAddition.reason?.id)) {
      // reason is set on this quickAddition but has been deleted or was not saved for future use
      quickAddition.reason.isEditable = false;
      reasons.push(quickAddition.reason);
    }
    return reasons;
  }
}

export default ReasonsStore;
