import { Row, SortByFn } from 'react-table';

import { v4 as uuid } from 'uuid';

import { PathwayTemplatesQuestion } from 'fetchers/responses/pathways-builder.response';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import { NEW_QUESTION_ID } from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.constants';

export const sortWithNewQuestion =
  (sortFunction: SortByFn<PathwayTemplatesQuestion>) =>
  (
    rowA: Row<PathwayTemplatesQuestion>,
    rowB: Row<PathwayTemplatesQuestion>,
    columnId: string,
    desc?: boolean
  ) => {
    if (rowA.original.id === NEW_QUESTION_ID || rowB.original.id === NEW_QUESTION_ID) {
      return desc ? -1 : 1;
    }

    return sortFunction(rowA, rowB, columnId, desc);
  };

export const getBlankNewQuestion = (): PathwayTemplatesQuestion => ({
  id: NEW_QUESTION_ID,
  title: '',
  type: PathwayQuestionTypes.SINGLE,
  options: [
    {
      id: uuid(),
      title: ''
    }
  ]
});
