// @ts-strict-ignore
import ObjectCopier from 'utils/ObjectCopier';

import { PathwayQuestionTypes, PathwayQuestionOption, BaseOption } from './PathwayTemplates';

export default class CallPathwayAnswer extends ObjectCopier {
  id: string;
  questionType: PathwayQuestionTypes;
  pathwayId: string;
  pathwayName: string;
  questionId: string;
  questionTitle: string;
  categoryId: string;
  value: any;
  deniedOptions?: BaseOption[];
  isPrefilled?: boolean;

  constructor(
    pathwayId: string,
    pathwayName: string,
    questionId: string,
    questionTitle: string,
    questionType: PathwayQuestionTypes,
    categoryId: string,
    value: string | PathwayQuestionOption[],
    deniedOptions?: BaseOption[],
    isPrefilled?: boolean
  ) {
    super();
    this.pathwayId = pathwayId;
    this.pathwayName = pathwayName;
    this.questionId = questionId;
    this.questionTitle = questionTitle;
    this.questionType = questionType;
    this.categoryId = categoryId;
    this.value = value;
    this.deniedOptions = deniedOptions;
    this.isPrefilled = isPrefilled;
  }
}
