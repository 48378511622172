// @ts-strict-ignore
import { FC, useEffect, useState, FocusEvent } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';

import { Testable } from 'utils/TypeUtils';

import * as ValidationUtils from 'utils/ValidationUtils';

import israelFlag from 'img/flags/israel.svg';
import usaFlag from 'img/flags/usa.svg';

import './StyledPhoneInput.scss';

interface StyledPhoneInputProps extends Testable {
  label?: string;
  disabled?: boolean;
  error?: boolean;
  onBlur?: (event: FocusEvent, valueAfterFocus: string, currentValue: string) => void;
  withCountryFlags?: boolean;
  className?: string;
  showPlaceholderOnError?: boolean;
}

export enum COUNTRY_CODES {
  ISRAEL = '+972',
  USA = '+1'
}

const countries: Array<{ name: string; icon: string; code: string }> = [
  { name: 'USA', code: COUNTRY_CODES.USA, icon: usaFlag },
  { name: 'Israel', code: COUNTRY_CODES.ISRAEL, icon: israelFlag }
];
const StyledPhoneInput: FC<StyledPhoneInputProps> = ({
  label,
  disabled,
  error: errorData,
  testHook,
  onBlur,
  withCountryFlags = true,
  className,
  showPlaceholderOnError = false
}: StyledPhoneInputProps) => {
  const { control, watch, trigger, formState } = useFormContext();
  const error = Boolean(errorData);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [valueAfterFocus, setValueAfterFocus] = useState(null);
  const [phone, countryCode] = watch(['phone', 'countryCode']);
  let validPhoneClass;
  if (phone?.length > 0) {
    validPhoneClass = ValidationUtils.isValidPhoneNumber(countryCode + phone) ? 'valid' : 'invalid';
  }

  useEffect(
    function validatePhoneCountryChange() {
      if (formState.isSubmitted) {
        trigger('phone');
      }
    },
    [countryCode, trigger, formState.isSubmitted]
  );

  const getFormattedPhone = (phone: string) => {
    if (!phone) {
      return '';
    }

    if (phone?.length > 6) {
      phone = `${phone.slice(0, 6)} ${phone.slice(6)}`;
    }

    if (phone?.length > 3) {
      phone = `${phone.slice(0, 3)} ${phone.slice(3)}`;
    }

    return phone;
  };

  const toggleCountryDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className={classNames('phone-input', className)}>
      {label && (
        <div
          className={classNames('phone-label', {
            error: error
          })}
        >
          {label}
        </div>
      )}
      <div
        data-test-hook="phone-number"
        className={`phone-input-number-container 
        ${dropdownOpen ? 'open' : 'closed'}
        ${error ? 'error' : ''} 
        ${focused ? 'focused' : ''}  
        ${disabled ? 'disabled' : ''}  
        ${validPhoneClass}`}
      >
        {withCountryFlags && (
          <>
            <Controller
              name="countryCode"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <div
                    className="country-container"
                    onClick={disabled ? null : toggleCountryDropdown}
                  >
                    <img
                      className="country-flag"
                      alt="country flag"
                      src={countries.find((countryObj) => countryObj.code === field.value)?.icon}
                    />
                    <div className="countries-dropdown">
                      {countries.map((countryObj) => (
                        <div
                          key={countryObj.code}
                          className="country-dropdown-item"
                          onClick={() => {
                            field.onChange(countryObj.code);
                          }}
                        >
                          <img className="country-flag" alt="country flag" src={countryObj.icon} />
                          <p className="country-text">{countryObj.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
            />
            <i className="purple-arrow-down" onClick={disabled ? null : toggleCountryDropdown} />
          </>
        )}

        <Controller
          name="phone"
          control={control}
          rules={{
            required: true,
            validate: (phone) => ValidationUtils.isValidPhoneNumber(countryCode + phone)
          }}
          render={({ field }) => {
            return (
              <input
                className="phone-number-text"
                value={getFormattedPhone(field.value)}
                placeholder={error && !showPlaceholderOnError ? '' : '000 000 0000'}
                onChange={(e: any) => {
                  let newValue = e.target.value;
                  if (newValue.length > 12) {
                    return;
                  }
                  newValue = newValue.replace(/ /g, '');
                  return field.onChange(newValue);
                }}
                onFocus={() => {
                  setValueAfterFocus(field.value);
                  setFocused(true);
                }}
                onBlur={(event) => {
                  setFocused(false);
                  onBlur && onBlur(event, valueAfterFocus, field.value);
                }}
                readOnly={!!disabled}
                data-test-hook={testHook}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default observer(StyledPhoneInput);
