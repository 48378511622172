import { PracticeManagerUsersResponse } from 'fetchers/responses/practice-manager-users.response';

import { userTypeToOption } from 'utils/AccountUtils';
import { getFormattedPhoneNumber } from 'utils/PhoneUtils';

import { PracticeManagerUsersParsedResponse } from 'views/Pages/PracticeManagement/ManagePracticeUsersPage/ManagePracticeUsersPage.types';

export class PracticeManagerParser {
  static parseUsers(response: PracticeManagerUsersResponse): PracticeManagerUsersParsedResponse {
    const { items, totalItems, totalPages } = response;

    const formattedItems = items.map((clinician) => {
      const { credentialId, id, phone, email, userStatus, role, createdAt, firstName, lastName } =
        clinician;

      return {
        id: id,
        name: { firstName, lastName, credentialId },
        phone: phone ? getFormattedPhoneNumber(phone) : '-',
        email,
        type: userTypeToOption[role],
        userStatus,
        joinDate: new Date(createdAt)
      };
    });

    return {
      items: formattedItems,
      totalItems,
      totalPages
    };
  }
}
