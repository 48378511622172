import { FC } from 'react';

import { StyledHtmlContentContainer, StyledInlineHtmlContentContainer } from 'utils/html.styles';

interface Props {
  children: string;
  inline?: boolean;
  className?: string;
}

export const HtmlContent: FC<Props> = ({ children, inline = false, className }) =>
  inline ? (
    <StyledInlineHtmlContentContainer
      dangerouslySetInnerHTML={{ __html: children }}
      className={className}
    />
  ) : (
    <StyledHtmlContentContainer
      dangerouslySetInnerHTML={{ __html: children }}
      className={className}
    />
  );
