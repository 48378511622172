import { css, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledError = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isError'
})<{ isError: boolean }>(
  ({ theme, isError }) => css`
    opacity: ${isError ? 1 : 0};
    color: ${isError ? theme.palette.error.dark : theme.palette.text.disabled};
  `
);

export const StyledSectionTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(12)};
  `
);
