import { PatientMinimalDetails } from 'models/PatientMinimalDetails';
import UserModel from 'models/UserModel';

export function getPatientFullName(patientMinimalDetails: PatientMinimalDetails): string {
  const patient = new UserModel(
    patientMinimalDetails.id,
    patientMinimalDetails.firstName,
    patientMinimalDetails.lastName
  );
  return patient.fullName;
}

export function getIntervalOverlapErrorMessage(overlappingPatient: PatientMinimalDetails) {
  return `This interval overlaps with another Call Timer or Care Timer you have with ${getPatientFullName(
    overlappingPatient
  )}`;
}

export function getCallOverlapErrorMessage(overlappingPatient: PatientMinimalDetails) {
  return `This call overlaps with another call you made with ${getPatientFullName(
    overlappingPatient
  )}. Please edit that call, or this one, in order to save.`;
}
