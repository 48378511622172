import { countBy, difference, intersection, isEmpty, isEqual, isNil, map } from 'lodash/fp';

import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { v4 as uuid } from 'uuid';

import { PathwayBuilderFetcher } from 'fetchers/PathwayBuilderFetcher';
import {
  ActivePathwayTemplatesResponse,
  Pathway,
  PathwayTemplateCategoryQuestion,
  PathwayTemplateCategoryQuestionOutcome,
  PathwayTemplates,
  PathwayTemplatesQuestion
} from 'fetchers/responses/pathways-builder.response';

import { BasicDepartmentInfo } from 'models/Department';
import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import { SelectOption } from 'models/SelectOption';

import {
  ACTIVE_PATHWAY_TEMPLATES_LOCAL_STORAGE_KEY,
  questionTypesWithOptions
} from 'views/Pages/PathwayBuilder/PathwayBuilderPage.constants';
import { persistPathwayTemplatesWithLocalStorage } from 'views/Pages/PathwayBuilder/PathwayBuilderPage.utils';
import {
  defaultPathwayQuestionOptionAlert,
  defaultPathwayQuestionOptionSuggestedPathway
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.constants';

import {
  CategoryFormField,
  CategoryQuestionOption,
  DependentQuestionsBlockType,
  MultipleQuestionFormField,
  NewQuestionFormField,
  PathwayEditorFormFields,
  PathwayInstitution,
  PathwayKeyword,
  QuestionFormField,
  SingleQuestionFormField,
  SubQuestionFormField
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import {
  getAlertOption,
  getNewPathway
} from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.utils';
import {
  defaultQuestionBankFilters,
  typeToQuestionTextMap
} from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.constants';

import {
  QuestionBankFilters,
  QuestionForm,
  QuestionInPathwaysMap
} from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.types';
import { CANCER_DIAGNOSIS_QUESTION_ID } from 'views/Patient/PatientMain/PathwaysView.constants';

import { RootStore } from './rootStore';

export class PathwayBuilderStore {
  rootStore: RootStore;

  @observable
  pathwayTemplates: PathwayTemplates | null = null;

  @observable
  updatedAt: string | null = null;

  @observable
  selectedPathwayEditorBlockUuid: string | null = null;

  @observable
  questionBankFilters: QuestionBankFilters = defaultQuestionBankFilters;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setPathways = (pathways: Pathway[]) => {
    this.pathwayTemplates!.pathways = pathways;
    persistPathwayTemplatesWithLocalStorage(this.pathwayTemplates!);
  };

  @action
  setQuestions = (questions: PathwayTemplatesQuestion[]) => {
    this.pathwayTemplates!.questions = questions;
    persistPathwayTemplatesWithLocalStorage(this.pathwayTemplates!);
  };

  @action
  fetchActivePathwaysTemplate = async () => {
    const response = await PathwayBuilderFetcher.fetchActivePathwaysTemplate();

    let pathwayTemplates: PathwayTemplates = response.pathwayTemplates;
    const activePathwayTemplates = localStorage.getItem(ACTIVE_PATHWAY_TEMPLATES_LOCAL_STORAGE_KEY);
    let shouldRemoveChangedQuestionsAndPathwaysFromLocalStorage = false;

    if (activePathwayTemplates) {
      const parsedActivePathwayTemplates: ActivePathwayTemplatesResponse =
        JSON.parse(activePathwayTemplates);

      if (
        new Date(parsedActivePathwayTemplates.updatedAt).getTime() >
        new Date(response.updatedAt).getTime()
      ) {
        pathwayTemplates = parsedActivePathwayTemplates.pathwayTemplates;
      } else {
        shouldRemoveChangedQuestionsAndPathwaysFromLocalStorage = true;
      }
    } else {
      shouldRemoveChangedQuestionsAndPathwaysFromLocalStorage = true;
    }

    runInAction(() => {
      this.pathwayTemplates = pathwayTemplates;
      this.updatedAt = response.updatedAt;
    });

    return shouldRemoveChangedQuestionsAndPathwaysFromLocalStorage;
  };

  @action
  selectPathwayEditorBlock = (blockUuid: string) => {
    this.selectedPathwayEditorBlockUuid = blockUuid;
  };

  @action
  resetSelectedPathwayEditorBlock = () => {
    this.selectedPathwayEditorBlockUuid = null;
  };

  @action
  updateQuestionBankFilters = (filters: Partial<QuestionBankFilters>) => {
    this.questionBankFilters = {
      ...this.questionBankFilters,
      ...filters
    };
  };

  @action
  updateQuestion = (question: QuestionForm, questionId: string) => {
    if (!this.pathwayTemplates) {
      return;
    }

    const originalObservedQuestion = this.getQuestionById(questionId);

    if (!originalObservedQuestion) {
      return;
    }

    const isOriginalHasOptions = questionTypesWithOptions.includes(originalObservedQuestion.type);
    const isEditedHasOptions = questionTypesWithOptions.includes(
      question.type.value as PathwayQuestionTypes
    );

    // Question was Multi or Single and now is Date or TextEntry. need cleanup
    if (isOriginalHasOptions && !isEditedHasOptions) {
      if (originalObservedQuestion.options) {
        const originalOptionIdsToDelete = map('id', originalObservedQuestion.options);

        this.removeAllOutcomesForCategoryQuestionById(questionId);
        this.removeDependentQuestionForOptionIds(originalOptionIdsToDelete);
        delete originalObservedQuestion.options;
      }
    }

    if (isEditedHasOptions && question.options) {
      // getting options that only exist in the original question before edit
      const optionsIdsToRemove = difference(
        map('id', originalObservedQuestion.options),
        map('id', question.options)
      );

      if (optionsIdsToRemove.length) {
        this.removeCategoryQuestionOutcomesByIds(questionId, optionsIdsToRemove);
        this.removeDependentQuestionForOptionIds(optionsIdsToRemove);
      }

      originalObservedQuestion.options = question.options.map((option) => ({
        id: option.id,
        title: option.title,
        ...(option.action && {
          action: option.action
        }),
        ...(!isNil(option.isHomeCareInstructions) && {
          isHomeCareInstructions: option.isHomeCareInstructions
        })
      }));
    }

    originalObservedQuestion.title = question.title;
    originalObservedQuestion.type = question.type.value as PathwayQuestionTypes;

    persistPathwayTemplatesWithLocalStorage(this.pathwayTemplates);
  };

  @action
  removeCategoryQuestionOutcomesByIds(questionId: string, optionIds: string[]) {
    optionIds.forEach((optionId) => {
      // remove all outcomes instances for given optionIds in
      // all category questions with given questionId.
      this.pathways.forEach((pathway) =>
        pathway.categories.forEach((category) =>
          category.questions.forEach((question) => {
            if (
              question.id === questionId &&
              question.outcomes &&
              !isNil(question.outcomes[optionId])
            ) {
              delete question.outcomes[optionId];
            }
            if (question.outcomes && isEmpty(question.outcomes)) {
              delete question.outcomes;
            }
          })
        )
      );
    });
  }

  @action
  removeDependentQuestionForOptionIds = (optionIds: string[]) => {
    optionIds.forEach((optionId) => {
      // update dependsOn in category questions
      this.pathways.forEach((pathway) =>
        pathway.categories.forEach((category) => {
          let questionIdsToRemove: string[] = [];
          category.questions.forEach((question) => {
            if (question.dependsOn) {
              question.dependsOn = question.dependsOn.filter((id) => id !== optionId);
              // if the option is the only one in dependsOn
              // add to questions to be removed
              if (question.dependsOn.length === 0) {
                questionIdsToRemove.push(question.id);
              }
            }
          });

          if (questionIdsToRemove.length) {
            category.questions = category.questions.filter(
              (question) => !questionIdsToRemove.includes(question.id)
            );
          }
        })
      );
    });
  };

  @action
  removeAllOutcomesForCategoryQuestionById = (questionId: string) => {
    this.pathways.forEach((pathway) =>
      pathway.categories.forEach((category) =>
        category.questions.forEach((question) => {
          if (question.id === questionId && question.outcomes) {
            delete question.outcomes;
          }
        })
      )
    );
  };

  @action
  addNewQuestion = (question: QuestionForm) => {
    if (!this.pathwayTemplates) {
      return;
    }

    const isQuestionHasOptions = questionTypesWithOptions.includes(
      question.type.value as PathwayQuestionTypes
    );

    const newQuestionId = uuid();
    const newQuestion = {
      id: newQuestionId,
      title: question.title,
      type: question.type.value as PathwayQuestionTypes,
      ...(isQuestionHasOptions && {
        options: question.options?.map((option) => ({
          id: option.id,
          title: option.title,
          ...(option.action && {
            action: option.action
          }),
          ...(!isNil(option.isHomeCareInstructions) && {
            isHomeCareInstructions: option.isHomeCareInstructions
          })
        }))
      })
    };

    // puts the question first in the question bank
    this.pathwayTemplates.questions.unshift(newQuestion);

    persistPathwayTemplatesWithLocalStorage(this.pathwayTemplates);

    return newQuestionId;
  };

  @computed
  get pathways() {
    return this.pathwayTemplates?.pathways || [];
  }

  @computed
  get questions() {
    return this.pathwayTemplates?.questions || [];
  }

  @computed
  get questionsWithOptions() {
    return this.questions.filter(
      (question) =>
        (question.type === PathwayQuestionTypes.MULTIPLE ||
          question.type === PathwayQuestionTypes.SINGLE) &&
        question.options
    );
  }

  @computed
  get questionCountInPathways() {
    const pathwayTemplateCategoryQuestions: PathwayTemplateCategoryQuestion[] = [];

    this.pathways.forEach((pathway) =>
      pathway.categories.forEach((category) =>
        category.questions.forEach((question) => {
          if (question.id !== CANCER_DIAGNOSIS_QUESTION_ID) {
            pathwayTemplateCategoryQuestions.push(question);
          }
        })
      )
    );

    return countBy('id', pathwayTemplateCategoryQuestions);
  }

  @computed
  get pathwaysForQuestions() {
    const pathwaysInQuestionsMap: QuestionInPathwaysMap = {};

    this.pathways.forEach((pathway) =>
      pathway.categories.forEach((category) =>
        category.questions.forEach((question) => {
          if (question.id !== CANCER_DIAGNOSIS_QUESTION_ID) {
            if (pathwaysInQuestionsMap[question.id]) {
              pathwaysInQuestionsMap[question.id].push({
                pathwayName: pathway.name,
                pathwayId: pathway.id
              });
            } else {
              pathwaysInQuestionsMap[question.id] = [
                { pathwayName: pathway.name, pathwayId: pathway.id }
              ];
            }
          }
        })
      )
    );

    return pathwaysInQuestionsMap;
  }

  @computed
  get optionsForPathwayTypeSelect(): SelectOption<PathwayQuestionTypes>[] {
    return Object.values(PathwayQuestionTypes)
      .map((type) => ({
        value: type,
        label: typeToQuestionTextMap[type]
      }))
      .filter((option) => option.value !== PathwayQuestionTypes.TEXT_ARRAY);
  }

  @computed
  get optionsForPathwayNameSelect(): SelectOption<string>[] {
    return this.pathways.map((pathway) => ({
      value: pathway.id,
      label: pathway.name
    }));
  }

  getQuestionById = (questionId: string) =>
    this.questions.find((question) => question.id === questionId) as PathwayTemplatesQuestion;

  getQuestionWithOptionsById = (questionId: string) => {
    const questionWithOptions = this.questionsWithOptions.find(
      (question) => question.id === questionId
    );

    if (!!questionWithOptions) {
      return questionWithOptions;
    }

    return null;
  };

  getPathwayQuestion = (
    pathwayId: string,
    categoryId: string,
    questionId: string
  ): PathwayTemplateCategoryQuestion => {
    const category = this.getPathwayCategoryById(pathwayId, categoryId);
    return category.questions.find((category) => category.id === questionId)!;
  };

  convertEditorQuestionToServerStructure = (
    editorQuestion: QuestionFormField | SubQuestionFormField,
    pathwayId: string,
    categoryId: string,
    dependentQuestionsBlock: DependentQuestionsBlockType | null = null,
    isNewPathway: boolean = false
  ) => {
    const convertedQuestion: PathwayTemplateCategoryQuestion = {
      id: editorQuestion.questionId,
      isKey: editorQuestion.isKey
    };

    const originalPathwayQuestion: PathwayTemplateCategoryQuestion | Record<string, never> =
      !isNewPathway
        ? this.getPathwayQuestion(pathwayId, categoryId, editorQuestion.questionId)
        : {};

    const questionWithOptions = editorQuestion as
      | SingleQuestionFormField
      | MultipleQuestionFormField;

    //handle question outcomes
    if (!isEmpty(questionWithOptions.options)) {
      const outcomes: Record<string, PathwayTemplateCategoryQuestionOutcome> = {};

      questionWithOptions.options!.forEach((option) => {
        outcomes[option.optionId] = { optionId: option.optionId, outcome: option.alert.value };

        if (!isEmpty(option.suggestedPathway.pathway.value)) {
          outcomes[option.optionId].linkedPathway = {
            id: option.suggestedPathway.pathway.value,
            urgency: option.suggestedPathway.urgency
          };
        }

        //copy "score property"
        if (
          !isEmpty(originalPathwayQuestion) &&
          !isEmpty(originalPathwayQuestion.outcomes) &&
          !isEmpty(
            originalPathwayQuestion.outcomes[option.optionId] &&
              !isEmpty(originalPathwayQuestion.outcomes[option.optionId].score)
          )
        ) {
          outcomes[option.optionId] = {
            ...outcomes[option.optionId],
            score: originalPathwayQuestion.outcomes[option.optionId].score
          };
        }
      });

      convertedQuestion.outcomes = outcomes;
    }

    //handle question dependsOn
    if (dependentQuestionsBlock) {
      convertedQuestion.dependsOn = dependentQuestionsBlock.triggers!.map(
        (trigger) => trigger.value
      );
    }

    //handle question symptoms
    if (editorQuestion.symptoms && !isEmpty(editorQuestion.symptoms)) {
      convertedQuestion.symptomIds = editorQuestion.symptoms.map((symptom) => symptom.value);
    }

    return convertedQuestion;
  };

  getQuestionOptionsForSelect = (questionId: string): CategoryQuestionOption[] | null => {
    const options = this.getQuestionWithOptionsById(questionId)?.options;

    if (options) {
      return options.map((option: any) => ({
        optionId: option.id,
        title: option.title,
        alert: defaultPathwayQuestionOptionAlert,
        suggestedPathway: defaultPathwayQuestionOptionSuggestedPathway
      }));
    }

    return null;
  };

  getPathwayById = (pathwayId: string) =>
    this.pathways.find((pathway: Pathway) => pathway.id === pathwayId);

  getPathwayCategoryById = (pathwayId: string, categoryId: string) => {
    const pathway = this.getPathwayById(pathwayId)!;
    return pathway.categories.find((category) => category.id === categoryId)!;
  };

  getQuestionOption = (questionId: string, optionId: string) => {
    const questionOptions = this.getQuestionWithOptionsById(questionId)?.options;

    return questionOptions?.find((option) => option.id === optionId);
  };

  getEditorDefaultValues = (
    pathwayId: string,
    institutions: BasicDepartmentInfo[],
    isNewPathway?: boolean
  ): PathwayEditorFormFields => {
    const pathway = isNewPathway ? getNewPathway() : this.getPathwayById(pathwayId)!;

    const pathwayCategories = pathway.categories.map((category, categoryIndex) => {
      const formCategoryQuestions: QuestionFormField[] = [];

      category.questions.forEach((categoryQuestion, questionIndex) => {
        //Cancer Diagnosis question should not be part of the form
        if (
          categoryIndex === 0 &&
          categoryQuestion.id === CANCER_DIAGNOSIS_QUESTION_ID &&
          questionIndex === 0
        ) {
          return;
        }

        const questionFromQuestionBank = this.getQuestionById(categoryQuestion.id);

        let questionFormField: Exclude<QuestionFormField, NewQuestionFormField> = {
          questionId: categoryQuestion.id,
          uuid: uuid(),
          title: { value: categoryQuestion.id, label: questionFromQuestionBank.title },
          type: questionFromQuestionBank.type as any,
          isKey: categoryQuestion.isKey,
          symptoms:
            categoryQuestion.symptomIds?.map((symptomId) => ({
              value: symptomId,
              label: this.rootStore.stores.constantsStore.getCauseNameById(symptomId)
            })) || []
        };

        const questionOptions = this.getQuestionWithOptionsById(
          questionFromQuestionBank.id
        )?.options;

        if (questionOptions) {
          questionFormField = questionFormField as unknown as
            | SingleQuestionFormField
            | MultipleQuestionFormField;

          questionFormField.options = questionOptions.map((questionOption) => {
            let questionOptionFormField: CategoryQuestionOption = {
              optionId: questionOption.id,
              title: questionOption.title,
              alert: defaultPathwayQuestionOptionAlert,
              suggestedPathway: defaultPathwayQuestionOptionSuggestedPathway
            };

            if (categoryQuestion.outcomes && categoryQuestion.outcomes[questionOption.id]) {
              const isOptionHasAlert = categoryQuestion.outcomes[questionOption.id].outcome;

              if (isOptionHasAlert) {
                questionOptionFormField = {
                  ...questionOptionFormField,
                  alert: getAlertOption(categoryQuestion.outcomes![questionOption.id].outcome!)
                };
              }

              const isOptionHasSuggestedPathway =
                categoryQuestion.outcomes[questionOption.id].linkedPathway;

              if (isOptionHasSuggestedPathway) {
                const suggestedPathway = this.getPathwayById(
                  categoryQuestion.outcomes[questionOption.id].linkedPathway!.id
                )!;

                questionOptionFormField = {
                  ...questionOptionFormField,
                  suggestedPathway: {
                    pathway: { label: suggestedPathway.name, value: suggestedPathway.id },
                    urgency: categoryQuestion.outcomes[questionOption.id].linkedPathway!.urgency
                  }
                };
              }
            }

            return questionOptionFormField;
          });

          questionFormField.dependentQuestionsBlocks = [];
        }

        if (!categoryQuestion.dependsOn) {
          formCategoryQuestions.push(questionFormField as QuestionFormField);
        } else {
          //find the question (from questions bank) which has the current question depends on option ids
          const matchedQuestion = category.questions.find((question) => {
            if (!Boolean(question.dependsOn)) {
              const questionFromQuestionBank = this.getQuestionById(question.id);

              if (questionFromQuestionBank) {
                const questionOptions = this.getQuestionWithOptionsById(
                  questionFromQuestionBank.id
                )?.options;

                if (!isEmpty(questionOptions)) {
                  const isMatchedQuestion = intersection(
                    questionOptions!.map((questionOption) => questionOption.id),
                    categoryQuestion.dependsOn
                  );

                  if (!isEmpty(isMatchedQuestion)) {
                    return true;
                  }
                }

                return false;
              }
            }

            return false;
          });

          //find the question in our formatted form category questions array
          const questionFromFormCategoryQuestions = formCategoryQuestions.find(
            (question) => question.questionId === matchedQuestion?.id
          ) as SingleQuestionFormField | MultipleQuestionFormField;

          if (questionFromFormCategoryQuestions) {
            const triggers = categoryQuestion.dependsOn.map((dependsOnOptionId) => {
              const option = this.getQuestionOption(
                questionFromFormCategoryQuestions.questionId,
                dependsOnOptionId
              );

              return {
                value: option!.id,
                label: option!.title
              };
            });

            const existBlockWithSameTriggers =
              questionFromFormCategoryQuestions.dependentQuestionsBlocks!.find(
                (dependentQuestionsBlock: any) =>
                  isEqual(dependentQuestionsBlock.triggers, triggers)
              );

            if (existBlockWithSameTriggers) {
              (existBlockWithSameTriggers as DependentQuestionsBlockType).questions.push(
                questionFormField as SubQuestionFormField
              );
            } else {
              questionFromFormCategoryQuestions.dependentQuestionsBlocks!.push({
                uuid: uuid(),
                triggers: triggers,
                questions: [questionFormField as SubQuestionFormField]
              });
            }
          }
        }
      });

      const categoryFormField: CategoryFormField = {
        categoryId: category.id,
        title: category.title,
        questions: formCategoryQuestions
      };

      return categoryFormField;
    });

    let pathwayKeywords: PathwayKeyword[] = [];

    if (pathway.keywords) {
      pathwayKeywords = pathway.keywords.map((keyword) => ({
        label: keyword,
        value: keyword,
        id: uuid()
      }));
    }

    let institutionOptions: PathwayInstitution[] = [];

    if (pathway.institutions) {
      const institutionMap = new Map(
        institutions.map((institution) => [institution.id, institution])
      );
      institutionOptions = pathway.institutions.map((institutionId) => {
        const institution = institutionMap.get(institutionId)!;
        return {
          label: `${institution.name} (${institution.id})`,
          value: institution.id
        };
      });
    }

    return {
      id: pathway.id,
      title: pathway.name,
      categories: pathwayCategories,
      keywords: pathwayKeywords,
      institutions: institutionOptions,
      type: pathway.type
    };
  };
}
