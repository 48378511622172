import { FC } from 'react';

import { css, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { NO_CONTENT_TEXT } from 'utils/TicketType.utils';
import { Testable } from 'utils/TypeUtils';

import { Text } from 'components/UIkit/atoms/Text';

interface Props extends Testable {
  enDisplayName: string | undefined;
  enInstructions: string;
  spInstructions: string;
  spDisplayName: string | undefined;
}

export const TicketTypeContent: FC<Props> = ({
  enDisplayName,
  enInstructions,
  spInstructions,
  spDisplayName,
  testHook
}) => {
  const showInstructionsColumn =
    enInstructions !== NO_CONTENT_TEXT || spInstructions !== NO_CONTENT_TEXT;

  return (
    <StyledGrid container data-test-hook={testHook}>
      <StyledDisplayNameSection container direction="column">
        <Grid item>
          <StyledTitle variant="body1" color="disabled" mb={4}>
            Display Name for Patient App
          </StyledTitle>
        </Grid>

        <Grid item columnGap={2} display="flex">
          <Typography variant="body1">EN:</Typography>
          <StyledTypography variant="body2">{enDisplayName}</StyledTypography>
        </Grid>

        <Grid item columnGap={2} display="flex">
          <Typography variant="body1">SP:</Typography>
          <StyledTypography variant="body2">{spDisplayName}</StyledTypography>
        </Grid>
      </StyledDisplayNameSection>

      {showInstructionsColumn && (
        <StyledInstructionsSection container direction="column">
          <Grid item>
            <StyledTitle variant="body1" color="disabled" mb={4}>
              Instructions for Patient App
            </StyledTitle>
          </Grid>

          <Grid item columnGap={2} display="flex">
            <Typography variant="body1">EN:</Typography>
            <StyledTypography variant="body2">{enInstructions || 'None'}</StyledTypography>
          </Grid>

          <Grid item columnGap={2} display="flex">
            <Typography variant="body1">SP:</Typography>
            <StyledTypography variant="body2">{spInstructions}</StyledTypography>
          </Grid>
        </StyledInstructionsSection>
      )}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  padding: ${({ theme }) =>
    `${theme.spacing(12)} ${theme.spacing(24)} ${theme.spacing(20)} ${theme.spacing(24)}`};
`;

const StyledDisplayNameSection = styled(Grid)`
  width: 40%;
`;

const StyledInstructionsSection = styled(Grid)`
  width: 60%;
`;

const StyledTitle = styled(Text)(
  () => css`
    display: inline-block;
  `
);

const StyledTypography = styled(Typography)`
  width: 90%;
  overflow-wrap: break-word;
`;
