import { FC } from 'react';

import { observer } from 'mobx-react';

import { useTicketNetworkLoading } from 'mobx/hooks/useNetworkLoading';

import { useStores } from 'mobx/hooks/useStores';

import { useTicketCalls } from 'mobx/hooks/useTicketCalls';

import { CallbackType } from 'models/CallbackTicket';
import Ticket from 'models/Ticket';

import { TOGGLE_PARAM } from 'hooks/useToggle';

import { PATIENT_TABS, TAB_QUERY_PARAM } from 'views/Patient/PatientMain/PatientMainView.constants';

import CardStack from 'views/Widgets/CardStack';

import { TicketPatientCallsList } from 'views/Widgets/PatientCallsList';

import { usePatientModel } from 'components/Patient/usePatientModel';
import TicketCommentsAndContact from 'components/Ticket/TicketRow/CommentsAndCallback/TicketCommentsAndContact';
import { useTicketProviderLocation } from 'components/Ticket/TicketRow/shared';
import TicketCreatedBy from 'components/Ticket/TicketRow/TicketCreatedBy';

import TicketRenderer from 'components/Ticket/TicketRow/TicketRenderer';

import { TicketSummaryButton } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton';

import { useTicketToggle } from 'components/Ticket/TicketRow/useTicketToggle';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { InternalLink } from 'components/UIkit/atoms/Link';

interface Props {
  ticket: Ticket;
  withPatientLink: boolean;
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
  className?: string;
  ticketIndex: number;
  ticketSectionCurrentPage?: number;
}

const CallbackRequestTicketRow: FC<Props> = ({
  ticketRefsCallback,
  ticket,
  withPatientLink,
  className,
  ticketIndex,
  ticketSectionCurrentPage = 0
}) => {
  const patient = usePatientModel(ticket.patientId);
  const { ticketActions: Actions, tab, hideActionButtons } = useTicketOverviewContext();
  const { isExpanded, toggle } = useTicketToggle(
    ticket.id,
    false,
    ticketSectionCurrentPage,
    false,
    tab
  );
  const { ticketsStore } = useStores();
  const { calls, isLoadingCalls } = useTicketCalls(ticket, isExpanded);
  const providerLocationText = useTicketProviderLocation(ticket);
  const isActionInProgress = useTicketNetworkLoading(ticket.id);

  const isTicketSelected = ticketsStore.ticketsBulkActionSet.has(ticket.id);

  let ticketTitle = 'Callback Request';
  if (ticket.callbackTicket?.callbackType === CallbackType.NoBirthdayDefined) {
    ticketTitle = 'Invitation Error';
  }
  const getTicketSummaryTitle = () => (
    <>
      <span className="bold title-text">{ticketTitle}</span>
      {providerLocationText && <span className="subtitle-text"> — {providerLocationText}</span>}
    </>
  );

  const patientPageWithOpenedEditModalPath = `/patient/${patient?.id}?${TAB_QUERY_PARAM}=${PATIENT_TABS.OPEN_TICKETS}&${TOGGLE_PARAM}=true`;

  const backwardsCompatibleCallbackRow = (reportType: CallbackType) => {
    // When transferring to actual callback request tickets (instead of the old patient.last_callback_request)
    // we created a script to create tickets for all callback requests that were in the system.
    // these callbacks are missing the dob, so we need to handle them differently.
    let source;
    if (reportType === CallbackType.DobMismatchIvr) {
      source = 'Report Line';
    } else {
      source = 'Web App';
    }
    return (
      <>
        {`Patient was unable to authenticate their birthdate to submit a report on the ${source}. Click `}
        <InternalLink to={patientPageWithOpenedEditModalPath}>here</InternalLink> to view and edit
        their birthdate.
      </>
    );
  };

  const getTicketSummaryDescription = () => {
    switch (ticket.callbackTicket?.callbackType) {
      case CallbackType.DobMismatchWeb:
        if (!ticket.callbackTicket?.callbackDOB) {
          return backwardsCompatibleCallbackRow(CallbackType.DobMismatchWeb);
        }
        return (
          <>
            {`Patient was unable to authenticate their birthdate to submit a report via web. They
            indicated a correct date of ${ticket.callbackTicket.formattedCallbackDob}. Click `}
            <InternalLink to={patientPageWithOpenedEditModalPath}>here</InternalLink> to view and
            edit their birthdate.
          </>
        );

      case CallbackType.DobMismatchIvr:
        if (!ticket.callbackTicket?.callbackDOB) {
          return backwardsCompatibleCallbackRow(CallbackType.DobMismatchIvr);
        }
        return (
          <>
            {`Patient failed to enter their birthdate correctly to authenticate themselves on the Report 
Line. They indicated their four-digit birthdate (mmdd) as ${ticket.callbackTicket.formattedCallbackDob}. Click `}
            <InternalLink to={patientPageWithOpenedEditModalPath}>here</InternalLink> to view and
            edit their birthdate.
          </>
        );

      case CallbackType.NoBirthdayDefined:
        return (
          <>
            Could not request a report from this patient, as they have no birthdate in the system.
            Click <InternalLink to={patientPageWithOpenedEditModalPath}>here</InternalLink> to edit
            their birthdate.
          </>
        );

      case CallbackType.LearnMore:
        return 'Patient indicated that they would like to learn more before consenting to report via web.';

      default:
        return '';
    }
  };

  const getTicketSummary = () => (
    <div>
      {getTicketSummaryTitle()}

      <div>{getTicketSummaryDescription()}</div>

      <TicketSummaryButton
        onClick={toggle}
        isExpanded={isExpanded}
        items={{
          draft: ticket.draftCallCount,
          comment: ticket.commentCount,
          call: ticket.callCount,
          callAttempt: ticket.callAttemptsCount
        }}
      />
    </div>
  );

  return (
    <CardStack
      isSelected={isTicketSelected}
      isOpen={isExpanded}
      classes={{
        root: className,
        closed: 'report-row-closed'
      }}
      numberOfStackedItems={ticket.callsAndDraftsCount}
    >
      <TicketRenderer
        ticketRefsCallback={ticketRefsCallback}
        isLoading={isActionInProgress}
        ticketId={ticket.id}
        withPatientLink={withPatientLink}
        classes={{
          container: 'no-border'
        }}
        patient={patient}
        createdByText={<TicketCreatedBy ticket={ticket} />}
        summary={getTicketSummary()}
        actions={
          <Actions
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />
        }
      >
        {isExpanded && (
          <TicketCommentsAndContact
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />
        )}
      </TicketRenderer>
      {TicketPatientCallsList({
        calls,
        patient,
        isLoadingCalls,
        ticket,
        hideActionButtons
      })}
    </CardStack>
  );
};

export default observer(CallbackRequestTicketRow);
