// @ts-strict-ignore
import React, { FC, useRef, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionsMenuAnalyticsEvent } from 'analytics/events/actions-menu';

import { useHistory } from 'react-router-dom';

import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { fromNow } from 'utils/DateUtils';

import { FEATURES } from 'constants/features';

import { PathwayBasicInfo } from 'models/PathwayTemplates';

import Patient from 'models/Patient';

import { usePatientSmsStatus } from 'hooks/usePatientSmsStatus';

import { useToggleCallLog } from 'hooks/useToggleCallLog';

import { RemoteMonitoringStatus } from 'views/Filters/FilterFields';

import { HybridProtocolDropdown, HybridProtocolOption } from 'views/Widgets/HybridProtocolDropdown';
import RequestReportDropdown from 'views/Widgets/RequestReportDropdown';

import { SendMessageOption } from 'views/Widgets/SendMessageOption';

import CallAndPathwaysDropDown from 'components/Ticket/TicketRow/CallAndPathwaysDropDown';
import { useEditTaskModal } from 'components/Ticket/TicketsContainers/TicketsContainers.shared';
import { useTicketActionCallbacks } from 'components/Ticket/TicketsContainers/useTicketActionCallbacks';
import { Tooltip, TooltipSelect } from 'components/Tooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';
import { ITooltipOption } from 'components/Tooltip/TooltipSelect';
import { OutlinedButton } from 'components/UIkit/atoms/Button';

interface Props {
  patient: Patient;
  onRequestReport: (shouldOpenSmsBlockedPopup: boolean) => void;
  onPhoneClicked: () => void;
  onProtocolSelected: (duration: HybridProtocolOption) => void;
  onSendPatientSmsClicked: (shouldOpenSmsBlockedPopup: boolean) => void;
}

const PatientActionsSelect: FC<Props> = ({
  patient,
  onRequestReport,
  onSendPatientSmsClicked,
  onPhoneClicked,
  onProtocolSelected
}) => {
  const tooltipRef = useRef();
  const { callLoggingStore, settingsStore } = useStores();
  const history = useHistory();
  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);
  const { onEditTask, onDeleteTask } = useTicketActionCallbacks();
  const [taskTicketEditModal, openTaskTicketEditModal] = useEditTaskModal({
    onDeleteTask,
    onEditTask
  });
  const [isCallDropdownOpen, setIsCallDropdownOpen] = useState(false);
  const [isRequestReportOpen, setIsRequestReportOpen] = useState(false);
  const [isHybridProtocolDropdownOpen, setIsHybridProtocolDropdownOpen] = useState(false);
  const isActionsButtonActive = isActionsDropdownOpen || isCallDropdownOpen || isRequestReportOpen;

  const { isSmsOptionDisabled, shouldOpenSmsBlockedPopup } = usePatientSmsStatus();
  const handleLogCallClicked = useToggleCallLog(() => {
    trackActionsMenuAnalyticsEvent({
      action: AnalyticEventAction.Select,
      value: 'Log Call',
      source: 'patient'
    });

    if (settingsStore.hasFeature(FEATURES.ACTIONS_LOG_CALLS_PATHWAYS)) {
      setIsCallDropdownOpen(true);
      setIsActionsDropdownOpen(false);
      return;
    }

    handlePathwaySelected(null);
  });

  const { remoteMonitoringStatus, phone } = patient;

  const enableRequestReport =
    remoteMonitoringStatus === RemoteMonitoringStatus.active ||
    remoteMonitoringStatus === RemoteMonitoringStatus.awaitingActivation;
  const isAwaitingActivation = remoteMonitoringStatus === RemoteMonitoringStatus.awaitingActivation;
  const isOptedOut = remoteMonitoringStatus === RemoteMonitoringStatus.optedOut;

  const handlePathwaySelected = (selectedPathway: PathwayBasicInfo) => {
    setIsCallDropdownOpen(false);
    callLoggingStore.startCallSession({ selectedPathway });
    if (!history.location.pathname.includes('patient')) {
      history.push(`/patient/${patient.id}`);
    }
  };

  const handleActionsButtonClicked = () => {
    trackActionsMenuAnalyticsEvent({ action: AnalyticEventAction.Click, source: 'patient' });
    setIsActionsDropdownOpen((prevState) => !prevState);
    setIsCallDropdownOpen(false);
    setIsRequestReportOpen(false);
    setIsHybridProtocolDropdownOpen(false);
  };

  const renderInvite = () => {
    let lastInviteSentCaption;
    if (patient.remoteMonitoringConsent) {
      lastInviteSentCaption = fromNow(patient.remoteMonitoringConsent);
    }

    return (
      <div className="d-flex flex-column align-items-start">
        <span>Invite to Submit Reports</span>
        {lastInviteSentCaption && (
          <span className="option-subtitle">{`Sent: ${lastInviteSentCaption}`}</span>
        )}
      </div>
    );
  };

  const renderHybridInvite = () => {
    return (
      <div className="d-flex flex-column align-items-start">
        <span>Invite to Submit Reports</span>
        {isOptedOut && <span className="option-subtitle">Patient Opted Out</span>}
        {!isOptedOut && !Boolean(phone) && (
          <span className="option-subtitle">No Phone Number Detected</span>
        )}
      </div>
    );
  };

  const renderRequestReport = () => {
    return (
      <div className="d-flex flex-column align-items-start">
        <span>Request Report</span>
        {isAwaitingActivation && (
          <span className="option-subtitle">Patient Awaiting Activation</span>
        )}
      </div>
    );
  };

  const getOptions = () => {
    const options: ITooltipOption[] = [];

    if (settingsStore.hasFeature(FEATURES.ACTIONS_LOG_CALLS)) {
      options.push({
        text: 'Log Call',
        id: 'logCall',
        onClick: handleLogCallClicked,
        testHook: 'log-call-option'
      });
    }

    if (settingsStore.hasFeature(FEATURES.ACTIONS_SEND_PATIENT_SMS)) {
      options.push({
        text: <SendMessageOption disabled={isSmsOptionDisabled} />,
        id: 'sendPatientSms',
        onClick: () => {
          trackActionsMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            value: 'Send Message',
            source: 'patient'
          });

          onSendPatientSmsClicked(shouldOpenSmsBlockedPopup);
          setIsActionsDropdownOpen(false);
        },
        disabled: isSmsOptionDisabled,
        testHook: 'sendPatientSms'
      });
    }

    if (
      settingsStore.hasFeature(FEATURES.ACTIONS_REQUEST_REPORTS) &&
      enableRequestReport &&
      !patient.hasDrugSpecificProtocol &&
      !patient.hasOralAutomaticProtocol
    ) {
      options.push({
        text: renderRequestReport(),
        id: 'requestReport',
        onClick: () => {
          trackActionsMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            value: 'Request Report',
            source: 'patient'
          });

          if (isAwaitingActivation) {
            return;
          }

          setIsRequestReportOpen(true);
          setIsActionsDropdownOpen(false);
        },
        disabled: isAwaitingActivation,
        testHook: 'requestReport'
      });
    }

    if (
      settingsStore.hasFeature(FEATURES.ACTIONS_INVITE_TO_SUBMIT_REPORTS) &&
      !settingsStore.hasFeature(FEATURES.ACTIONS_HYBRID_PROTOCOL) &&
      !enableRequestReport
    ) {
      options.push({
        text: renderInvite(),
        id: 'invitePatient',
        onClick: () => {
          history.push({
            pathname: '/invitation',
            state: { patientId: patient.id }
          });
        }
      });
    }
    if (
      settingsStore.hasFeature(FEATURES.ACTIONS_HYBRID_PROTOCOL) &&
      settingsStore.hasFeature(FEATURES.AUTO_PATIENT_ENROLLMENT) &&
      settingsStore.hasFeature(FEATURES.REPORT_PROTOCOL) &&
      !enableRequestReport
    ) {
      options.push({
        text: renderHybridInvite(),
        id: 'invitePatient',
        disabled: isOptedOut || !Boolean(phone),
        onClick: () => {
          setIsHybridProtocolDropdownOpen(true);
          setIsActionsDropdownOpen(false);
        }
      });
    }

    if (settingsStore.hasFeature(FEATURES.ACTIONS_CREATE_TICKET)) {
      options.push({
        text: 'Create Ticket',
        id: 'createTicket',
        onClick: () => {
          trackActionsMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            value: 'Create Ticket',
            source: 'patient'
          });
          history.push(`/create-tickets?patientId=${patient.id}`);
        }
      });
    }

    if (settingsStore.hasFeature(FEATURES.ACTIONS_CREATE_TASK)) {
      options.push({
        text: 'Create Task',
        id: 'createTask',
        onClick: () => {
          trackActionsMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            value: 'Create Task',
            source: 'patient'
          });
          openTaskTicketEditModal(patient);
          setIsActionsDropdownOpen(false);
        }
      });
    }

    return options;
  };

  return (
    <>
      {taskTicketEditModal}
      <OutlinedButton
        onClick={handleActionsButtonClicked}
        testHook={patientPageTestSelectors.actionsButton}
        ref={tooltipRef}
        isActive={isActionsButtonActive}
      >
        Actions
      </OutlinedButton>

      <Tooltip
        trigger={TooltipTrigger.CLICK}
        reference={tooltipRef}
        controller={{
          visible: isActionsDropdownOpen,
          onClickOutside: () => setIsActionsDropdownOpen(false)
        }}
      >
        <TooltipSelect options={getOptions()} />
      </Tooltip>

      <HybridProtocolDropdown
        tooltipReference={tooltipRef}
        onProtocolSaved={(duration) => {
          onProtocolSelected(duration);
          setIsHybridProtocolDropdownOpen(false);
        }}
        tooltipController={{
          visible: isHybridProtocolDropdownOpen,
          onClickOutside: () => setIsHybridProtocolDropdownOpen(false)
        }}
      />

      <CallAndPathwaysDropDown
        allowNoPathway
        onSelect={handlePathwaySelected}
        tooltipReference={tooltipRef}
        tooltipController={{
          visible: isCallDropdownOpen,
          onClickOutside: () => setIsCallDropdownOpen(false)
        }}
        patientId={patient.id}
        testHook="pathways-select"
      />

      <RequestReportDropdown
        onSmsClicked={(shouldOpenSmsBlockedPopup: boolean) => {
          setIsRequestReportOpen(false);
          onRequestReport && onRequestReport(shouldOpenSmsBlockedPopup);
        }}
        disabled={isAwaitingActivation}
        onPhoneClicked={() => {
          setIsRequestReportOpen(false);
          onPhoneClicked && onPhoneClicked();
        }}
        patient={patient}
        preferred={patient.mainScheduledProtocol && patient.mainScheduledProtocol.type}
        isMobile={patient.hasMobilePhone}
        tooltipReference={tooltipRef}
        tooltipController={{
          visible: isRequestReportOpen,
          onClickOutside: () => setIsRequestReportOpen(false)
        }}
      />
    </>
  );
};

export default PatientActionsSelect;
