import { FC, MouseEvent } from 'react';

import { css, styled } from '@mui/material';

import { Testable } from 'utils/TypeUtils';

import { TextButton } from 'components/UIkit/atoms/Button';
import { Text } from 'components/UIkit/atoms/Text';

export interface Props extends Testable {
  children: string;
  clickableText: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => any;
}

export const TableNotification: FC<Props> = ({ children, clickableText, onClick }) => (
  <StyledContainer>
    <Text variant="body2" color="disabled">
      {children}
    </Text>
    <TextButton onClick={onClick} variant="secondary">
      {clickableText}
    </TextButton>
  </StyledContainer>
);

const StyledContainer = styled('div')(
  ({ theme }) => css`
    height: 40px;
    padding: ${theme.spacing(12, 20)};
    display: flex;
    align-items: center;
    gap: ${theme.spacing(8)};
    background-color: ${theme.palette.natural.contrastDark};
    margin-top: ${theme.spacing(12)};
    margin-bottom: -${theme.spacing(8)}; // reduce space with table header
  `
);

interface SelectAllNotificationProps {
  allItemsCount: number;
  isAllRowsInTableSelected: boolean;
  selectAllRowsInTable: () => void;
  unselectAll: () => void;
}

export const TableSelectAllNotification: FC<SelectAllNotificationProps> = ({
  allItemsCount,
  isAllRowsInTableSelected,
  selectAllRowsInTable,
  unselectAll
}) => {
  const clickableText = isAllRowsInTableSelected
    ? 'Unselect All'
    : `Select all ${allItemsCount} items in the table`;

  const onClick = isAllRowsInTableSelected ? unselectAll : selectAllRowsInTable;

  const summaryText = isAllRowsInTableSelected
    ? 'All items in table are selected.'
    : 'All items on this page are selected.';

  return (
    <TableNotification onClick={onClick} clickableText={clickableText}>
      {summaryText}
    </TableNotification>
  );
};
