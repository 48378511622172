import { FC, FocusEvent } from 'react';

import { Box, css, styled } from '@mui/material';
import { omit } from 'lodash/fp';

import {
  LightweightPathwayServerStructure,
  LightweightPathwayQuestionServerStructure
} from 'fetchers/responses/pathways.response';

import { Testable } from 'utils/TypeUtils';

import {
  LightweightPathwayDependentQuestionServerStructure,
  LightweightPathwayQuestionAnswer,
  QuestionActionMeta
} from 'components/LightweightPathway/LightweightPathway.types';

import { LightweightPathwayQuestions } from 'components/LightweightPathway/LightweightPathwayQuestions';
import { Text } from 'components/UIkit/atoms/Text';

interface Props extends Testable {
  lightweightPathways: LightweightPathwayServerStructure[];
  answers: LightweightPathwayQuestionAnswer[];
  handleAnswerChanged: (
    newValue: LightweightPathwayQuestionAnswer,
    actionMeta: QuestionActionMeta
  ) => void;
  handleTextQuestionBlur?: (
    event: FocusEvent<HTMLInputElement>,
    currentValue: string,
    valueAfterFocus: string,
    question:
      | LightweightPathwayQuestionServerStructure
      | LightweightPathwayDependentQuestionServerStructure
  ) => void;
}

export const LightweightPathways: FC<Props> = ({
  lightweightPathways,
  answers,
  handleAnswerChanged,
  testHook,
  handleTextQuestionBlur
}) => (
  <StyledLightweightPathwaysQuestionsContainer data-test-hook={testHook}>
    {lightweightPathways.map((pathway, index) => (
      <StyledLightweightPathwayContainer key={pathway.id} px={12} py={20}>
        <Text
          textTransform="uppercase"
          color="secondary"
          variant="body2"
          mb={20}
          display="inline-block"
        >
          {pathway.title} Pathway
        </Text>

        <LightweightPathwayQuestions
          questions={pathway.questions}
          pathway={omit(['questions'], pathway)}
          pathwayIndex={index}
          answers={answers}
          handleAnswerChanged={handleAnswerChanged}
          handleTextQuestionBlur={handleTextQuestionBlur}
        />
      </StyledLightweightPathwayContainer>
    ))}
  </StyledLightweightPathwaysQuestionsContainer>
);

const StyledLightweightPathwaysQuestionsContainer = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.natural.white};
    border: 1px solid ${theme.palette.natural.border};
    border-bottom: 0;
  `
);

const StyledLightweightPathwayContainer = styled(Box)(
  ({ theme }) => css`
    &:not(:first-of-type) {
      border-top: 1px solid ${theme.palette.natural.border};
    }
  `
);
