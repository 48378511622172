import { FC } from 'react';

import { Grid } from '@mui/material';

import {
  DISPLAY_NAME_MAX_LENGTH,
  HIDDEN_ERROR_MESSAGE_TEXT,
  INSTRUCTIONS_TEXT_AREA_MAX_LENGTH
} from 'utils/TicketType.utils';

import {
  StyledError,
  StyledSectionTitle
} from 'views/Pages/PracticeManagement/TicketTypes/Modals/shared';
import { useEditTicketTypeOrSubType } from 'views/Pages/PracticeManagement/TicketTypes/useEditTicketTypeOrSubType';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  onClose: () => void;
  ticketTypeId: number;
  isOpen: boolean;
  resetDataAfterClose: () => void;
}

export const EditTicketTypeModal: FC<Props> = ({
  onClose,
  ticketTypeId,
  isOpen,
  resetDataAfterClose
}) => {
  const {
    methods,
    isSubmitDisabled,
    ticketTypeOrSubType,
    isInstructionsSectionVisible,
    editTicketTypeOrSubType,
    defaultEnglishDisplayName,
    defaultSpanishDisplayName,
    isLoading,
    errors,
    formDefaultValues
  } = useEditTicketTypeOrSubType(ticketTypeId, onClose);

  const { handleSubmit, register } = methods;

  return (
    <FormModal
      methods={methods}
      defaultValues={formDefaultValues}
      isOpen={isOpen}
      title={`Ticket Type: ${ticketTypeOrSubType?.name}`}
      closeAction={{ onClick: onClose, disabled: isLoading }}
      confirmActions={[
        {
          onClick: handleSubmit(editTicketTypeOrSubType),
          text: isLoading ? 'Saving...' : 'Save',
          disabled: isSubmitDisabled
        }
      ]}
      resetDataAfterClose={resetDataAfterClose}
    >
      <StyledSectionTitle variant="h4">Display Name for Patient App</StyledSectionTitle>
      <Grid container columnSpacing={20} mb={isInstructionsSectionVisible ? 24 : 28}>
        <Grid item xs={6}>
          <RHFStyledInput
            label="English"
            name="enDisplayName"
            register={register}
            error={Boolean(errors.enDisplayName)}
            placeholder={defaultEnglishDisplayName?.name}
            maxLength={DISPLAY_NAME_MAX_LENGTH}
          />

          <StyledError isError={Boolean(errors.enDisplayName)} variant="helper-text">
            {errors.enDisplayName?.message || HIDDEN_ERROR_MESSAGE_TEXT}
          </StyledError>
        </Grid>

        <Grid item xs={6}>
          <RHFStyledInput
            label="Spanish"
            name="spDisplayName"
            register={register}
            error={Boolean(errors.spDisplayName)}
            placeholder={defaultSpanishDisplayName?.name}
            maxLength={DISPLAY_NAME_MAX_LENGTH}
          />

          <StyledError isError={Boolean(errors.spDisplayName)} variant="helper-text">
            {errors.spDisplayName?.message || HIDDEN_ERROR_MESSAGE_TEXT}
          </StyledError>
        </Grid>
      </Grid>

      {isInstructionsSectionVisible && (
        <>
          <StyledSectionTitle variant="h4">Instructions for Patient App</StyledSectionTitle>

          <Grid container columnSpacing={20} mb={28}>
            <Grid item xs={6}>
              <RHFStyledInput
                label="English"
                name="enInstructions"
                register={register}
                type="textarea"
                disabled
                maxLength={INSTRUCTIONS_TEXT_AREA_MAX_LENGTH}
              />
            </Grid>

            <Grid item xs={6}>
              <RHFStyledInput
                label="Spanish"
                name="spInstructions"
                register={register}
                type="textarea"
                disabled
                maxLength={INSTRUCTIONS_TEXT_AREA_MAX_LENGTH}
              />
            </Grid>
          </Grid>
        </>
      )}
    </FormModal>
  );
};
