import { FC } from 'react';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { OptionProps } from 'react-select';

import SanitizeHtml from 'views/Widgets/SanitizeHtml';

import { StyledMenuOption } from 'components/UIkit/atoms/Dropdown/Select/ReactSelectOptionComponent';

export const DependentQuestionsBlockTriggersOption: FC<OptionProps<any, boolean>> = ({
  children,
  ...props
}) => (
  <StyledMenuOption {...props}>
    <Box display="flex" flexGrow={1}>
      <Typography variant="form-text">
        <SanitizeHtml html={children as string} wrapperTag="span" />
      </Typography>
    </Box>
  </StyledMenuOption>
);
