import { ReactNode } from 'react';

import { PathwayOptionAction, PathwayQuestionTypes } from 'models/PathwayTemplates';

import {
  EditPathwayQuestionPopupState,
  QuestionBankFilters
} from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.types';

import Icon from 'components/Icons/Icon';

export const typeToQuestionIconMap: Record<PathwayQuestionTypes, ReactNode> = {
  [PathwayQuestionTypes.DATE]: <Icon.Calendar />,
  [PathwayQuestionTypes.MULTIPLE]: <Icon.CheckedCheckbox />,
  [PathwayQuestionTypes.SINGLE]: <Icon.CheckedRadio />,
  [PathwayQuestionTypes.TEXT_ARRAY]: <Icon.FreeText />,
  [PathwayQuestionTypes.TEXT]: <Icon.FreeText />
};

export const typeToQuestionTextMap: Record<PathwayQuestionTypes, string> = {
  [PathwayQuestionTypes.DATE]: 'Date Entry',
  [PathwayQuestionTypes.MULTIPLE]: 'Multi-Select',
  [PathwayQuestionTypes.SINGLE]: 'Single-Select',
  [PathwayQuestionTypes.TEXT_ARRAY]: 'Multi-Text',
  [PathwayQuestionTypes.TEXT]: 'Text Entry'
};

export const defaultQuestionTypeOption = {
  value: PathwayQuestionTypes.SINGLE,
  label: typeToQuestionTextMap[PathwayQuestionTypes.SINGLE]
};

export const defaultQuestionBankFilters: QuestionBankFilters = {
  types: null,
  searchTerm: '',
  pathways: null
};

export enum QuestionBankColumns {
  Title = 'title',
  Type = 'type',
  Actions = 'actions'
}

export const MIN_OPTION_COUNT = 1;

export const NEW_QUESTION_ID = '_NEW_PATHWAY_TEMPLATE_QUESTION';

export const defaultEditQuestionPopupState: EditPathwayQuestionPopupState = {
  pathwayCount: 0,
  questionId: null
};

export const optionActionToLabelMap: Record<PathwayOptionAction, string> = {
  [PathwayOptionAction.DenyAll]: 'Special Response - None of the above',
  [PathwayOptionAction.AcceptAll]: 'Special Response - All of the above'
};
