import { computed, observable } from 'mobx';

import { formatDate } from 'utils/DateUtils';

export enum CallbackType {
  LearnMore = 'learnMore',
  DobMismatchWeb = 'dobMismatchWeb',
  DobMismatchIvr = 'dobMismatchIVR',
  NoBirthdayDefined = 'noBirthdayDefined'
}

export class CallbackTicket {
  callbackType: CallbackType;

  @observable
  callbackDOB?: string;

  constructor(unparsedTicket: any) {
    this.callbackType = unparsedTicket.callbackType;
    this.callbackDOB = unparsedTicket.callbackDOB;
  }

  @computed
  get formattedCallbackDob(): string {
    if (this.callbackDOB) {
      if (this.callbackType === CallbackType.DobMismatchWeb) {
        return formatDate(this.callbackDOB, 'MM/DD/YYYY');
      }
      if (this.callbackType === CallbackType.DobMismatchIvr) {
        return this.callbackDOB;
      }
    }

    return '';
  }
}

export default CallbackTicket;
