import { Box } from '@mui/material';

import { PathwayTemplatesQuestion } from 'fetchers/responses/pathways-builder.response';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import {
  typeToQuestionIconMap,
  typeToQuestionTextMap
} from 'views/Pages/PathwayBuilder/QuestionBankTabView/QuestionBankTabView.constants';

import { TableCellParams } from 'components/Table';
import { Text } from 'components/UIkit/atoms/Text';

export const QuestionTypeCell = (cellParams: TableCellParams<PathwayTemplatesQuestion>) => (
  <Box display="flex" alignItems="center">
    <Box mr={4}>{typeToQuestionIconMap[cellParams.cell.value as PathwayQuestionTypes]}</Box>
    <Text variant="form-text">
      {typeToQuestionTextMap[cellParams.cell.value as PathwayQuestionTypes]}
    </Text>
  </Box>
);
