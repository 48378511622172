// @ts-strict-ignore
import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { AnalyticEventAction } from 'analytics';

import { useStores } from 'mobx/hooks/useStores';

import Patient from 'models/Patient';

import PatientEpisode from 'models/PatientEpisode';

import { useToggle } from 'hooks/useToggle';

import { PatientEpisodeEndModal } from 'views/Modals/PatientEpisodeEndModal';
import EditEpisodeModal from 'views/Pages/EpisodesManagement/EditPatientEpisodeModal';
import { EnrollEpisodeModal } from 'views/Pages/EpisodesManagement/EnrollEpisodeModal';

// eslint-disable-next-line import/order
import { trackEnrollEpisodeModalAnalyticsEvent } from 'analytics/events/enroll-episode-modal';

export const EpisodeEditCreateContext = createContext<{
  openEnrollEpisode: () => void;
  editEpisode: (episode: PatientEpisode) => void;
  endEpisode: (episode: PatientEpisode) => void;
  openEditPatientEpisodeModal: () => void;
  openEndPatientEpisodeModal: () => void;
}>(null);

interface EpisodeEditCreateProviderProps {
  children: ReactNode;
  patient: Patient;
}

export const EpisodeEditCreateProvider: FC<EpisodeEditCreateProviderProps> = ({
  children,
  patient
}) => {
  const { isOpen: isEnrollEpisodeOpen, toggle: toggleIsEnrollEpisodeOpen } = useToggle(false);
  const [isEditPatientEpisodeModalOpen, setIsEditPatientEpisodeModalOpen] = useState(false);
  const [isEndPatientEpisodeModalOpen, setIsEndPatientEpisodeModalOpen] = useState(false);
  const [editedEpisode, setEditedEpisode] = useState<PatientEpisode>(null);
  const [endedEpisode, setEndedEpisode] = useState<PatientEpisode>(null);
  const { patientEpisodesStore } = useStores();

  const closeEditPatientEpisodeModal = () => {
    setIsEditPatientEpisodeModalOpen(false);
  };

  const closeEndPatientEpisodeModal = () => {
    setEndedEpisode(null);
    setIsEndPatientEpisodeModalOpen(false);
  };

  const handleSubmit = () => {
    toggleIsEnrollEpisodeOpen();
    patientEpisodesStore.handleUpdateCreateEpisode(patient.id);
  };

  return (
    <EpisodeEditCreateContext.Provider
      value={{
        openEnrollEpisode: () => {
          trackEnrollEpisodeModalAnalyticsEvent({
            action: AnalyticEventAction.Open,
            patient_id: patient.id
          });
          toggleIsEnrollEpisodeOpen();
        },
        openEditPatientEpisodeModal: () => setIsEditPatientEpisodeModalOpen(true),
        openEndPatientEpisodeModal: () => setIsEndPatientEpisodeModalOpen(true),
        editEpisode: setEditedEpisode,
        endEpisode: setEndedEpisode
      }}
    >
      <EnrollEpisodeModal
        isOpen={isEnrollEpisodeOpen}
        onSubmit={handleSubmit}
        patient={patient}
        onCancel={toggleIsEnrollEpisodeOpen}
      />

      <PatientEpisodeEndModal
        onSubmit={() => {
          closeEndPatientEpisodeModal();
          closeEditPatientEpisodeModal();
        }}
        onCancel={closeEndPatientEpisodeModal}
        isOpen={isEndPatientEpisodeModalOpen}
        patientEpisode={endedEpisode}
      />

      <EditEpisodeModal
        onSubmit={closeEditPatientEpisodeModal}
        episode={editedEpisode}
        onCancel={closeEditPatientEpisodeModal}
        isOpen={isEditPatientEpisodeModalOpen}
        patient={patient}
        resetDataAfterClose={() => setEditedEpisode(null)}
        isEndPatientEpisodeModalOpen={isEndPatientEpisodeModalOpen}
      />
      {children}
    </EpisodeEditCreateContext.Provider>
  );
};

export const useManageEpisodesContext = () => {
  const context = useContext(EpisodeEditCreateContext);
  if (context === undefined) {
    throw new Error('useManageEpisodesContext must be used within a EpisodeEditCreateProvider');
  }
  return context;
};
