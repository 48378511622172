import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Send | AnalyticEventAction.Cancel;
type Value = 'blocked' | undefined;

interface Properties {
  action: Action;
  value?: Value;
}

export const trackSendSmsAnalyticsEvent = ({ action, value, ...optionalProps }: Properties) => {
  AnalyticsService.trackEvent<Properties & { type: 'sms' }>({
    name: AnalyticEventName.SendSms,
    properties: {
      action,
      value,
      ...optionalProps,
      type: 'sms'
    }
  });
};
