import { FC } from 'react';

import { v4 as uuid } from 'uuid';

import { usePathwayEditorSelection } from 'hooks/usePathwayEditorSelection';

import { OutlinedButton } from 'components/UIkit/atoms/Button';

interface Props {
  onClick: (blockUuid: string) => void;
  isDisabled: boolean;
}

export const PathwayAddSubQuestionButton: FC<Props> = ({ onClick, isDisabled }) => {
  const { selectPathwayEditorBlock } = usePathwayEditorSelection();

  const onInsertQuestion = () => {
    const blockUuid = uuid();
    onClick(blockUuid);
    selectPathwayEditorBlock(blockUuid);
  };

  return (
    <OutlinedButton
      mt={20}
      onClick={onInsertQuestion}
      disabled={isDisabled}
      id="pathway-add-sub-question-button"
    >
      Add Another Sub-Question with These Triggers
    </OutlinedButton>
  );
};
