export const GLOBAL_PATIENTS_SEARCH_LIMIT = 20;
export const RECENT_PATIENTS_SEARCH_LIMIT = 10;

export const SUPPORTED_DOB_DATE_QUERY_FORMATS = [
  'M/D/YY',
  'M/D/YYYY',
  'M-D-YY',
  'M-D-YYYY',
  'M.D.YY',
  'M.D.YYYY'
];

export const SEARCH_BUTTONS_WIDTH_WITH_MARGIN = 70;
export const NEW_PATIENT_ID = -1;
