import { FC } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  isOpen: boolean;
  setIsPopupOpen: (isOpen: boolean) => void;
}

export const ItemDeletedPopup: FC<Props> = ({ isOpen, setIsPopupOpen }) => {
  return (
    <MessageDialog
      id={Dialogs.TicketDeletedNotification}
      isOpen={isOpen}
      title="Item Deleted"
      handleClose={() => setIsPopupOpen(false)}
      primaryActionProps={{ text: 'Ok', onClick: () => setIsPopupOpen(false) }}
    >
      This item has been deleted. Tickets could have been
      <br />
      deleted by the user who created them, or by a User Type of “Admin”.
      <br />
      <br />
      Non-Episode Tasks can be deleted by all users.
    </MessageDialog>
  );
};
