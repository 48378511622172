// @ts-strict-ignore
export class TaskTemplate {
  id: number;
  episodeId: number;
  name: string;
  description: string;
  roleId: number;
  monthNumber: number;
  isBillable: boolean | null;

  constructor(taskTemplate: any) {
    Object.assign(this, {
      ...taskTemplate,
      isBillable: Boolean(taskTemplate.isBillable)
    });
  }
}

export default TaskTemplate;
