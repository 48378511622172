import { LightweightPathwayServerStructure } from 'fetchers/responses/pathways.response';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

const httpPathwayService = new HttpService('pathway', true);

export class PathwaysFetcher {
  static async getLightweightPathwaysBySymptomIds(
    symptomIds: number[]
  ): Promise<LightweightPathwayServerStructure[]> {
    return await httpPathwayService.get({
      url: API_URLS.LIGHTWEIGHT_PATHWAY,
      networkLabel: 'lightweight-pathways',
      query: {
        symptomIds
      }
    });
  }
}

export default PathwaysFetcher;
