// @ts-strict-ignore
import { computed } from 'mobx';

// default reasons which should appear for all institutions
export const PatientEpisodeDefaultEndReasons = {
  MARK_AS_ERROR_ID: 1,
  OTHER_ID: 2
};

export class PatientEpisodeEndReason {
  id: number;
  name: string;
  institutionId: number;
  isActive: boolean = true;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  constructor(reason: any) {
    Object.assign(this, reason);
  }

  @computed
  get isSelectable() {
    return this.isActive && !this.deletedAt;
  }
}
