import { FC } from 'react';

import React from 'react';

import { CallsAndCallbackPreferenceColumn } from 'views/Patient/PatientMain/ReportsTable/CallsAndCallbackPreferenceColumn';

import { CallsAndCallbackPreferenceTableRowData } from 'views/Patient/PatientMain/ReportsTable/ReportTable.model';

interface Props {
  text: string;
  callsAndCallbackRow: CallsAndCallbackPreferenceTableRowData;
}

export const CallsAndCallbackPreferenceRow: FC<Props> = ({ text, callsAndCallbackRow }) => (
  <tr>
    <td>{text}</td>
    {callsAndCallbackRow.map((cellData, index) => (
      <CallsAndCallbackPreferenceColumn callsAndCallbackColumn={cellData} key={index} />
    ))}
  </tr>
);
